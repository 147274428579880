import {ModuleWithProviders, NgModule, Optional, SkipSelf, Type} from '@angular/core';
import {AbstractModuleLoader, ScriptModuleLoader} from 'kn-common';
import {Http} from 'kn-http';
import {AbstractPrintBackend} from './backends/abstract-print-backend.service';
import {HypdfPrintBackend} from './backends/hypdf-print-backend.service';
import {BrowserPrintBackend} from './backends/browser-print-backend.service';
import {PlaintextPrintBackend} from './backends/plaintext-print-backend.service';
import {CsvPrintBackend} from './backends/csv-print-backend.service';
import {ContextFetcher} from './context-fetcher.service';
import {ContextProcessor} from './context-processor.service';
import {HtmlRenderer} from './html-renderer.service';
import {PRINT_STATEMENTS_PROVIDERS} from './print-statements/print-statements.providers';
import {PRINT_HTTP_TOKEN} from './print-http.token';
import {PrinterService} from './printer.service';
import {AbstractResolutionStrategy} from './resolution-strategies/abstract-resolution-strategy';
import {DependencyResolveResolutionStrategy} from './resolution-strategies/dependency-resolve-resolution-strategy';

export class PrintOptions {
	public resolutionStrategy: Type<AbstractResolutionStrategy>;
	public backend: Type<AbstractPrintBackend>;
}

@NgModule()
export class KnPrintModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnPrintModule) {
		if (parentModule) {
			throw new Error('KnPrintModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config: Partial<PrintOptions> = {}): ModuleWithProviders<KnPrintModule> {
		return {
			ngModule: KnPrintModule,
			providers: [
				{
					provide: PRINT_HTTP_TOKEN,
					useExisting: Http
				}, {
					provide: AbstractModuleLoader,
					useClass: ScriptModuleLoader
				}, {
					provide: AbstractResolutionStrategy,
					useClass: config.resolutionStrategy || DependencyResolveResolutionStrategy
				}, {
					provide: AbstractPrintBackend,
					useClass: config.backend || HypdfPrintBackend
				},
				PRINT_STATEMENTS_PROVIDERS,
				ContextFetcher,
				ContextProcessor,
				HtmlRenderer,
				PrinterService,
				HypdfPrintBackend,
				BrowserPrintBackend,
				PlaintextPrintBackend,
				CsvPrintBackend
			]
		};
	}
}
