import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {FetchableDatagridBond, ModelBuilder, ContractDescription, ContractModel, Model, Column, DataSource, CellValue} from 'kn-datagrid';
import {MultiplexPool} from 'kn-shared';
import {Utils} from 'kn-utils';

export class DatagridBond<T> extends FetchableDatagridBond<T> {
	private _rowActivateCallback: {(value: T): void } = () => { /* intentionally blank */ };
	private _selectedCallback: {(value: T[]): void } = () => { /* intentionally blank */ };
	private _modelChangeCallback: {(value: Model): void } = () => { /* intentionally blank */ };
	private _expandedChangeCallback: {(value: T[]): void } = () => { /* intentionally blank */ };
	private _dataSourceChangeCallback: {(value: DataSource<T>): void } = () => { /* intentionally blank */ };

	private readonly _initialModel: Model;

	public constructor(
			description: ContractDescription<T>,
			model: ContractModel,
			fetcher: (sorting: Column<T>[]) => Observable<DataSource<T>>) {
		super(description, model, fetcher);
		this._initialModel = ModelBuilder.from(model, this.description);
		this.modelPool.push({ getter: () => this._initialModel });
	}

	public modelPool = new MultiplexPool<Model>();

	public get isInitial() {
		return Utils.equal(this._initialModel, this.modelPool.value);
	}

	public get model() {
		return this.modelPool.value;
	}

	public set model(value: Model) {
		if (this.modelPool != null) {
			this.modelPool.value = value;
		}
	}

	public dataSourceSort(dataSource: DataSource<CellValue>, sortingColumns?: Column<T>[]): DataSource<CellValue> {
		const sorting = sortingColumns || this._getSorting(this.model.columns, this.description);
		return this._dataSourceSort(dataSource, sorting);
	}

	public onRowActivate(value: T) {
		super.onRowActivate(value);
		this._rowActivateCallback(value);
	}

	public onSelectedChange(value: T[]) {
		super.onSelectedChange(value);
		this._selectedCallback(value);
	}

	public onModelChange(value: Model) {
		super.onModelChange(value);
		this._modelChangeCallback(value);
	}

	public onExpandedChange(value: T[]) {
		super.onExpandedChange(value);
		this._expandedChangeCallback(value);
	}

	public onDataSourceChange(value: DataSource<T>) {
		super.onDataSourceChange(value);
		this._dataSourceChangeCallback(value);
	}

	public registerRowActivate(callback: {(value: T): void }) {
		this._rowActivateCallback = callback;
	}

	public registerSelectedCallback(callback: {(value: T[]): void }) {
		this._selectedCallback = callback;
	}

	public registerModelChangeCallback(callback: {(value: Model): void }) {
		this._modelChangeCallback = callback;
	}

	public registerExpandedChangeCallback(callback: {(value: T[]): void }) {
		this._expandedChangeCallback = callback;
	}

	public registerDataSourceChangeCallback(callback: {(value: DataSource<T>): void }) {
		this._dataSourceChangeCallback = callback;
	}

	public setInitial() {
		this.model = Utils.clone(this._initialModel, true);
		this.onModelChange(this.model);
	}

	protected _fetch(sorting: Column<T>[]): Observable<DataSource<T>> {
		return super._fetch(sorting)
			.pipe(Rx.map(next => this.dataSourceSort(next, sorting)));
	}
}
