import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription, PartialObserver} from 'rxjs';
import * as Rx from 'rxjs/operators';

@Injectable()
export class ChangeNotifier {
	private static readonly _debounceTime: number = 100;
	private readonly _subject: Subject<void>;
	private readonly _notifier: Observable<void>;

	public constructor() {
		this._subject = new Subject<void>();
		this._notifier = this._subject.pipe(
			Rx.debounceTime(ChangeNotifier._debounceTime),
			Rx.share()
		);
	}

	public emit() {
		this._subject.next();
	}

	public subscribe(observerOrNext?: PartialObserver<void> | (() => void), error?: () => void, complete?: () => void): Subscription {
		return this._notifier.subscribe(observerOrNext as any, error, complete);
	}
}
