import {NgModule} from '@angular/core';
import {KnExpansion} from './expansion.component';
import {KnExpansionHeader} from './expansion-header.component';
import {KnExpansionContent} from './expansion-content.component';
import {KnExpansionManager} from './expansion-manager.component';

export const KN_EXPANSION_DIRECTIVES = [
	KnExpansion,
	KnExpansionHeader,
	KnExpansionContent,
	KnExpansionManager
];

@NgModule({
	declarations: [KN_EXPANSION_DIRECTIVES],
	exports: [KN_EXPANSION_DIRECTIVES]
})
export class KnExpansionModule { }
