import {BreakableType, NodeBreakables} from '../types';

export abstract class AbstractElementBreaker {
	public abstract isSupported(node: Node): boolean;
	public abstract getBreakables(node: Node): NodeBreakables;

	public extract(node: Node, fromNode: Node, toNode: Node) {
		let state = 0;
		let i = 0;
		while (i < node.childNodes.length) {
			const child = node.childNodes[i];
			switch (state) {
				case 0:
					if (fromNode == null || child === fromNode) {
						state++;
					}
					else if (this._remove(node, child)) {
						continue;
					}
					break;

				case 1:
					if (child === toNode) {
						state++;
					}
					break;

				case 2:
					if (this._remove(node, child)) {
						continue;
					}
					break;
			}
			i++;
		}
	}

	protected _remove(parent: Node, node: Node) {
		parent.removeChild(node);
		return true;
	}

	protected _cssBreakToBreakable(...values: string[]) {
		for (const value of values) {
			switch (value) {
				case 'always':
				case 'page':
					return BreakableType.Always;
				case 'avoid':
				case 'avoid-page':
					return BreakableType.Avoid;
			}
		}
		return BreakableType.Auto;
	}
}
