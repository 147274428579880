import {NgModule} from '@angular/core';
import {KnDirectivesModule} from 'kn-directives';
import {KnModal} from './modal.component';

const KN_MODAL_DIRECTIVES = [
	KnModal
];

@NgModule({
	imports: [KnDirectivesModule],
	declarations: [KN_MODAL_DIRECTIVES],
	exports: [KN_MODAL_DIRECTIVES]
})
export class SharedModule { }
