import {Utils} from 'kn-utils';
import {UserSettingLinkPropertyMetadata} from './metadata/user-setting-link-property.decorator';
import {UserSettingProxyMetadata} from './metadata/user-setting-proxy.decorator';
import {UserSettingMetadata} from './metadata/user-setting.decorator';
import {UserSettingsFactory} from './user-settings-factory.service';
import {PropertyAccessor} from './property-accessor';
import {AbstractUserSettingsService} from './abstract-user-settings.service';
import {SettingWrapper} from './setting-wrapper';

export class ComponentUserSettingsService extends AbstractUserSettingsService {
	public constructor(factory: UserSettingsFactory, origin: any) {
		super(factory, origin);

		const propertyMetadata = Utils.reflector.propMetadata(this.origin);
		for (const key in propertyMetadata) {
			if (propertyMetadata.hasOwnProperty(key)) {
				for (const metadata of propertyMetadata[key]) {
					if (metadata instanceof UserSettingProxyMetadata) {
						this._linkProxy(metadata);
					}
					else if (metadata instanceof UserSettingMetadata) {
						this._linkPropertyAccessor(metadata);
					}
				}
			}
		}

		const annotations = Utils.reflector.annotations(this.origin);
		for (const annotation of annotations) {
			if (annotation instanceof UserSettingLinkPropertyMetadata) {
				this._linkPropertyAccessor(annotation);
			}
		}

		if (factory.config.autoFetch) {
			this.fetch();
		}
	}

	private _linkProxy(proxy: UserSettingProxyMetadata) {
		const container = this._createPropertyContainer(proxy.key, proxy.version, proxy.discriminatorArg);
		container.accessor = new SettingWrapper<any>(
			proxy.key,
			proxy.version,
			proxy.discriminatorArg,
			container.item.value,
			next => {
				container.item.value = next;
				this._changesSubject$.next({ [proxy.key]: next });
			}
		);
		this._properties.push(container);
	}

	private _linkPropertyAccessor(property: PropertyAccessor<any>) {
		const container = this._createPropertyContainer(property.key, property.version, property.discriminatorArg);
		container.accessor = property;
		const subscription = property.valueChange
			.subscribe((next: any) => {
				property.value = next;
				container.item.value = next;
				this._changesSubject$.next({ [property.key]: next });
			});
		this._disposables.push(() => subscription.unsubscribe());
		this._properties.push(container);
	}

	public relink() {
		for (const property of this._properties) {
			this._update(property, property.alternatives.concat(property.item));
		}
	}
}
