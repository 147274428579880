export abstract class AbstractNodeIterator implements NodeIterator /*, NodeFilter */ {
	private _iteratorLazy: NodeIterator;
	private _currentNode: Node;

	public constructor(private readonly _root: Node) { }

	protected get _iterator(): NodeIterator {
		if (this._iteratorLazy == null) {
			this._iteratorLazy = document.createNodeIterator(this._root, this._whatToShow(), this);
		}
		return this._iteratorLazy;
	}

	public acceptNode(node: Node): number {
		return NodeFilter.FILTER_ACCEPT;
	}

	protected _whatToShow(): number {
		return NodeFilter.SHOW_ALL;
	}

	public get filter(): NodeFilter {
		return this._iterator.filter;
	}

	public get pointerBeforeReferenceNode(): boolean {
		return false;
	}

	/** @deprecated */
	public get expandEntityReferences(): boolean {
		return false;
	}

	public get referenceNode(): Node {
		return this._iterator.referenceNode;
	}

	public get root(): Node {
		return this._iterator.root;
	}

	public get whatToShow(): number {
		return this._iterator.whatToShow;
	}

	public get currentNode(): Node {
		return this._currentNode;
	}

	/** @deprecated */
	public detach(): void {
		this._iterator.detach();
	}

	public nextNode(): Node {
		const node = this._iterator.nextNode();
		if (node != null) {
			this._currentNode = node;
		}
		return node;
	}

	public previousNode(): Node {
		const node = this._iterator.previousNode();
		if (node != null) {
			this._currentNode = node;
		}
		return node;
	}
}
