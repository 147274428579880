import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Http, Response, Headers, RequestOptions, Uri, UriTemplate, UriContext} from 'kn-http';
import {AbstractConcreteFetcher, ChangeAction} from 'kn-rest';

export interface FileInfo {
	name: string;
	length: number;
	lastModified: string;
}

export interface File {
	path: string;
	contentType?: string;
	data: Blob;
}

export class FileFetcher extends AbstractConcreteFetcher<any> {
	protected _uri: Uri;

	public constructor(
			protected _http: Http,
			uriTemplate: string,
			table: string,
			protected _pathProperty = 'path') {
		super(table);
		this._uri = new Uri(new UriTemplate(uriTemplate));
	}

	public query(context?: UriContext): Observable<FileInfo[]> {
		return this._get(null, context);
	}

	public get(path: string, context?: UriContext): Observable<FileInfo[] | File> {
		return this._get(path, context);
	}

	public head(path: string, context?: UriContext): Observable<Response> {
		return this._head(path, context);
	}

	public save(item: File, context?: UriContext): Observable<Response> {
		return this._postOrPut(item, context);
	}

	public remove(context: UriContext): Observable<Response>;
	public remove(path: string, context?: UriContext): Observable<Response>;
	public remove(pathOrContext: string | UriContext, context?: UriContext): Observable<Response> {
		let path = null as string;
		if (Utils.isObject(pathOrContext)) {
			context = Object.assign({}, pathOrContext as UriContext, context);
		}
		else {
			path = pathOrContext as string;
		}
		return this._remove(path, context);
	}

	public exists(path: string): Observable<boolean> {
		return this._http.head(this._buildUri(path, null)).pipe(
			Rx.catchError((err: Response) =>
				err.status === 404 ? observableOf(false) : observableThrowError(err)),
			Rx.map(next => !!next)
		);
	}

	public static isDirectory(value: string | FileInfo) {
		let path: string;
		if (Utils.isObject(value) && value.hasOwnProperty('name')) {
			path = (value as FileInfo).name;
		}
		else if (value != null) {
			path = `${value}`;
		}
		return !path || path.endsWith('/');
	}

	protected _get<U extends FileInfo[] | File>(path: string, context?: UriContext): Observable<U> {
		if (FileFetcher.isDirectory(path)) {
			return this._queryFileInfo(path, context) as Observable<U>;
		}
		return this._getFile(path, context) as Observable<U>;
	}

	protected _head(path: string, context?: UriContext): Observable<Response> {
		return this._http.get(this._buildUri(path, context));
	}

	protected _queryFileInfo(path: string, context: UriContext): Observable<FileInfo[]> {
		return this._http.get(this._buildUri(path, context))
			.pipe(Rx.map(x => x.body as FileInfo[]));
	}

	protected _getFile(path: string, context: UriContext): Observable<File> {
		const options = { responseType: 'blob' };
		return this._http.get(this._buildUri(path, context), options).pipe(
			Rx.map(x => {
				return {
					path: path,
					contentType: x.headers.get('Content-Type'),
					data: x.body
				} as File;
			})
		);
	}

	protected _postOrPut(item: File, context: UriContext): Observable<Response> {
		const url = this._buildUri(item.path, context);
		const body = item.data;
		const options = {} as RequestOptions;
		if (item.contentType != null) {
			options.headers = new Headers({ 'Content-Type': item.contentType });
		}
		return this.exists(item.path).pipe(
			Rx.map(next => next ? this._http.put : this._http.post),
			Rx.switchMap(postOrPut => postOrPut.bind(this)(url, body, options) as Observable<Response>)
		);
	}

	protected _delete(path: string, context: UriContext): Observable<Response> {
		return this._http.delete(this._buildUri(path, context))
			.pipe(Rx.tap(() => this._emitChange(path, ChangeAction.Deleted)));
	}

	protected _buildUri(path: string, context: UriContext) {
		const pathContext = path != null ? { [this._pathProperty]: path } : null;
		return this._uri.build(Object.assign({}, context, pathContext));
	}
}
