import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnSpinner} from './spinner.component';

export const KN_SPINNER_DIRECTIVES = [
	KnSpinner
];

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [KN_SPINNER_DIRECTIVES],
	exports: [KN_SPINNER_DIRECTIVES]
})
export class KnSpinnerModule { }
