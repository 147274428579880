import {NgModule, Optional, SkipSelf, ModuleWithProviders} from '@angular/core';
import {Utils} from 'kn-utils';
import {AccessControls} from 'kn-user';
import {RestAutorizationConfig, RestContextSource} from './rest-autorization.config';
import {RestAccessControlFactory} from './access-control/rest-access-control';
import {RestPermissionsConfiguratorGuard} from './rest-permissions-configurator.guard';
import {RouteAccessGuard} from './route-access.guard';

export class RestAutorizationOptions extends RestAutorizationConfig {
	public databaseUriKey?: string;
}

export function provideRestAutorizationConfig(config: RestAutorizationOptions) {
	const databaseUriKey = config.databaseUriKey || 'database';
	return Utils.object.defaults(config, {
		contextDelegates: [
			{
				[databaseUriKey]: {
					source: RestContextSource.UriContextService,
					default: 'master'
				}
			}
		]
	});
}

export function provideAccessControlFactories() {
	return [new RestAccessControlFactory()];
}

@NgModule()
export class KnRestAutorizationModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnRestAutorizationModule) {
		if (parentModule) {
			throw new Error('KnRestAutorizationModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config?: Partial<RestAutorizationOptions>): ModuleWithProviders<KnRestAutorizationModule> {
		return {
			ngModule: KnRestAutorizationModule,
			providers: [
				{
					provide: RestAutorizationOptions, useValue: config ? config : {}
				}, {
					provide: RestAutorizationConfig,
					useFactory: provideRestAutorizationConfig,
					deps: [RestAutorizationOptions]
				},
				AccessControls.extend(provideAccessControlFactories),
				RestPermissionsConfiguratorGuard,
				RouteAccessGuard
			]
		};
	}
}
