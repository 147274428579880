import {Injectable, TemplateRef} from '@angular/core';
import {FormGroup, ValidatorFn} from '@angular/forms';
import {Observable, of as observableOf} from 'rxjs';
import {Validator, AsyncValidator} from 'kn-forms';
import {UserModel} from './user-model';
import * as Model from 'common-web/model';

export interface RestrictionsTemplateContext {
	$implicit: FormGroup;
	data: { [key: string]: any };
}

@Injectable()
export class UserRoleRestrictionsService {
	public getValidators(): { [key: string]: Validator | AsyncValidator } {
		return {};
	}

	public buildControlGroup(validators: { [name: string]: ValidatorFn } | any): FormGroup {
		return null;
	}

	public sanityModel(model: UserModel): UserModel {
		return model;
	}

	public getKeys(database: Model.Database): string[] {
		return [];
	}

	public fetchData(database: Model.Database): Observable<{ [key: string]: any }> {
		return observableOf(null);
	}

	public getTemplate(database: Model.Database): TemplateRef<RestrictionsTemplateContext> {
		return null;
	}
}
