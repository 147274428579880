import {Injectable, EventEmitter} from '@angular/core';
import {Description, SelectionMode, RowItem} from '../types';
import {Node} from '../model/node';
import {Utils} from 'kn-utils';

@Injectable()
export class SelectionService {
	public key: string = 'selected';
	public rowKey: string = 'rowSelected';
	public selectedChange = new EventEmitter<RowItem[]>();

	public getSelected(tree: Node<RowItem>): RowItem[] {
		return this._getSelectedRows(tree);
	}

	public setSelected(value: RowItem[], tree: Node<RowItem>): Node<RowItem>[] {
		return this._setSelectedRows(tree, value);
	}

	public toggleSelect(node: Node<RowItem>, description: Description<RowItem>) {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
		this.setSelect(node, this.isSelected(node) === false, description);
	}

	public isSelectable(node: Node<RowItem>, description: Description<RowItem>): boolean {
		return this._isSelectable(node, description);
	}

	public isSelected(node: Node<RowItem>): boolean {
		if (node.isLeaf()) {
			const value = node.data.get(this.key);
			return value != null && value !== false;
		}
		let selected: boolean;
		for (const child of node.children) {
			const childSelection = this.isSelected(child);
			if (childSelection == null) {
				return null;
			}
			if (selected == null) {
				selected = childSelection;
			}
			if (selected !== childSelection) {
				return null;
			}
		}
		return selected;
	}

	public setSelect(node: Node<RowItem>, selected: boolean, description: Description<RowItem>) {
		let changed: boolean;
		switch (description.selectionMode) {
			case SelectionMode.Single:
			case SelectionMode.Browse:
				if (description.selectionMode === SelectionMode.Browse && !node.isLeaf()) {
					break;
				}
				changed = this._setSelect(node.getRoot(), false, description);
				if (node.isLeaf()) {
					selected = selected || description.selectionMode === SelectionMode.Browse;
					changed = this._setSelect(node, selected, description) || changed;
				}
				break;

			case SelectionMode.Multiple:
				changed = this._setSelect(node, selected, description);
				break;

			default:
				changed = this._setSelect(node.getRoot(), false, description);
				break;
		}

		if (changed) {
			this.selectedChange.emit(this.getSelected(node.getRoot()));
		}
	}

	private _getSelectedRows(node: Node<RowItem>): RowItem[] {
		if (node.children.length === 0) {
			return [];
		}
		if (node.children[0].isLeaf()) {
			return node.children.filter(x => x.data.get(this.key)).map(x => x.item);
		}
		return node.children.reduce((acc, x) => acc.concat(this._getSelectedRows(x)), []);
	}

	private _setSelectedRows(node: Node<RowItem>, rows: RowItem[]): Node<RowItem>[] {
		let changedList: Node<RowItem>[] = [];
		if (node.children.length === 0) {
			return changedList;
		}
		if (node.children[0].isLeaf()) {
			for (const child of node.children) {
				const rowSelected = child.data.get(this.rowKey) || false;
				let selected;
				if (rows.indexOf(child.item) === -1) {
					selected = false;
					child.data.set(this.key, false);
				}
				else {
					selected = true;
					child.data.set(this.key, true);
				}
				if (rowSelected !== selected) {
					child.data.set(this.rowKey, selected);
					changedList.push(child);
				}
			}
		}
		changedList = node.children.reduce((list, child) => {
			const changed = this._setSelectedRows(child, rows);
			if (changed.length > 0) {
				list.push(child, ...changed);
			}
			return list;
		}, changedList);
		return changedList;
	}

	private _isSelectable(node: Node<RowItem>, description: Description<RowItem>): boolean {
		const selectable = description.rows.selectable;
		if (!Utils.isFunction(selectable)) {
			return !!selectable;
		}
		if (node.isLeaf()) {
			const selectableFunctor = selectable as (item: RowItem) => boolean;
			return selectableFunctor(node.item);
		}
		return node.children.some(x => this._isSelectable(x, description)) != null;
	}

	private _setSelect(node: Node<RowItem>, selected: boolean, description: Description<RowItem>) {
		if (!this._isSelectable(node, description)) {
			return false;
		}
		let changed = false;
		if (node.isLeaf()) {
			const value = node.data.get(this.key);
			changed = (value != null && value !== false) !== selected;
			node.data.set(this.key, selected);
		}
		else {
			for (const child of node.children) {
				changed = this._setSelect(child, selected, description) || changed;
			}
		}
		return changed;
	}
}
