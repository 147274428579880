import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnDatagridModule} from 'kn-datagrid';
import {KnQueryFilterModule} from 'kn-query-filter';

import {KnExportButton} from './widgets/export-button/export-button.component';
import {KnGridExporter} from './widgets/grid-exporter.component';
import {KnXlsxExportDirective} from './widgets/xlsx-export.directive';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnDatagridModule,
		KnQueryFilterModule,
		KnCommonModule,
		KnComponentsModule
	],
	declarations: [KnGridExporter, KnExportButton, KnXlsxExportDirective],
	exports: [KnGridExporter, KnExportButton, KnXlsxExportDirective]
})
export class KnGridPanelExportModule { }
