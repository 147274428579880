<ng-container *ngIf="isLeaf">
	<ng-container *ngFor="let action of actions">
		<ng-container *knCan="action.permission the 'item'">
			<a href="javascript:void(0)"
					*ngIf="evaluate(action.visible)"
					[title]="action.title"
					(click)="execute($event, evaluate(action.command))">
				<i class="material-icons">{{ action.icon }}</i>
			</a>
		</ng-container>
	</ng-container>
</ng-container>
