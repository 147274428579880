import {Component, ViewEncapsulation, Input, Output, HostBinding, EventEmitter, ElementRef} from '@angular/core';
import {of} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {ScrollToView} from '../scrollToView';

@Component({
	selector: 'kn-expansion',
	template: '<ng-content></ng-content>',
	styles: ['kn-expansion { display: block; }'],
	encapsulation: ViewEncapsulation.None
})
export class KnExpansion {
	@Output() public expandedChange = new EventEmitter<boolean>();

	@Input() public scrollToExpanded: boolean;

	@Input() public set expanded(value: boolean) {
		if (this._expanded !== value) {
			this._expanded = value;
			this.expandedChange.emit(value);
			if (this.expanded && this.scrollToExpanded) {
				this.scrollToView();
			}
		}
	}

	public get expanded(): boolean {
		return this._expanded;
	}

	public constructor(private readonly element: ElementRef) {}

	public expand(scrollToView: boolean = false) {
		this.expanded = true;
		if (!this.scrollToExpanded && scrollToView) {
			this.scrollToView();
		}
	}

	public collapse() {
		this.expanded = false;
	}

	public scrollToView() {
		if (this.element) {
			of(this.element).pipe(
				Rx.delay(100)
			).subscribe(x => ScrollToView.animateScrollTo(x));
		}
	}

	@HostBinding('class.expanded')
	private _expanded: boolean = false;
}
