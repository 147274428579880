import {Component, Optional, Inject, Input, Output, EventEmitter} from '@angular/core';
import {Utils} from 'kn-utils';
import {DatagridBond} from 'kn-datagrid';
import {ActionCommand} from '../../../abstract-grid-view.component';
import {GRID_ACTIONS_TOKEN} from './grid-actions.token';
import {GridAction, GridActionCallable} from './grid-action.type';

@Component({
	selector: 'kn-grid-actions',
	templateUrl: 'grid-actions.html'
})
export class KnGridActions {
	public actions: GridAction<any>[];

	@Input() public params: any[];
	@Input() public bond: DatagridBond<any>;
	@Output() public command = new EventEmitter<ActionCommand<any>>();

	public constructor(@Optional() @Inject(GRID_ACTIONS_TOKEN) actions: GridAction<any>[][]) {
		this.actions = Utils.array.flatten(actions || []).sort((a, b) => a.order - b.order);
	}

	public evaluate<T, U>(callable: GridActionCallable<T, U>): U {
		return callable(this.bond, ...(this.params || []));
	}
}
