import {Injectable, Optional} from '@angular/core';
import {Utils} from 'kn-utils';
import {AbstractStorageService} from 'kn-storage';
import {AuthObject} from './types';

export class JwtAuthStoreConfig {
	public key?: string;
	public tokenGetter?: (auth: AuthObject) => string;
}

@Injectable()
export class JwtAuthStore {
	private readonly _options: JwtAuthStoreConfig;

	public constructor(
			private readonly _backend: AbstractStorageService,
			@Optional() options?: JwtAuthStoreConfig) {
		this._options = Utils.object.defaults({}, options, {
			key: 'auth',
			tokenGetter: auth => auth && auth.hasOwnProperty('token') && auth['token']
		} as JwtAuthStoreConfig);
	}

	public get(): AuthObject {
		return this._backend.getItem(this._options.key);
	}

	public save(auth: AuthObject) {
		this._backend.setItem(this._options.key, auth);
	}

	public remove() {
		this._backend.removeItem(this._options.key);
	}

	public getToken(): string {
		return this._options.tokenGetter(this.get());
	}
}
