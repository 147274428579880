import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'find' })
export class FindPipe implements PipeTransform {
	public transform(value: any[], needle: { [key: string]: string }): any[] {
		if (!Array.isArray(value)) {
			return null;
		}
		const needleKeys = Object.keys(needle);
		return value.find(item =>
			needleKeys.reduce((prev, key) => prev && item[key] === needle[key], true));
	}
}
