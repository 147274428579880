import {Input, Directive, Injectable, Inject, SkipSelf} from '@angular/core';
import {Router, UrlHandlingStrategy, RouteReuseStrategy, RouterState, Routes, NavigationExtras, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class PrefixRouter {
	public prefix: any[] = [];
	public target: string;

	public constructor(@SkipSelf() private readonly _router: Router) {
		/* empty */
	}

	public get errorHandler(): any { return this._router.errorHandler; }
	public set errorHandler(a: any) { this._router.errorHandler = a; }
	public get navigated(): boolean { return this._router.navigated; }
	public set navigated(a: boolean) { this._router.navigated = a; }
	public get urlHandlingStrategy(): UrlHandlingStrategy { return this._router.urlHandlingStrategy; }
	public set urlHandlingStrategy(a: UrlHandlingStrategy) { this._router.urlHandlingStrategy = a; }
	public get routeReuseStrategy(): RouteReuseStrategy { return this._router.routeReuseStrategy; }
	public set routeReuseStrategy(a: RouteReuseStrategy) { this._router.routeReuseStrategy = a; }

	public setUpLocationChangeListener() {
		this._router.setUpLocationChangeListener();
	}

	public get routerState(): RouterState { return this._router.routerState; }
	public get url(): string { return this._router.url; }
	public get events(): Observable<Event> { return this._router.events as any; }

	public resetConfig(config: Routes) {
		this._router.resetConfig(config);
	}

	public ngOnDestroy() {
		/* empty */
	}

	public dispose() {
		/* empty */
	}

	private _fixRelativeRoute(commands: any[], extras: NavigationExtras) {
		while (commands.length && commands[0] === '..') {
			if (!extras.relativeTo || extras.relativeTo.parent == null) {
				break;
			}
			commands.splice(0, 1);
			extras.relativeTo = extras.relativeTo.parent;
		}
	}

	public createUrlTree(commands: any[], navigationExtras?: NavigationExtras) {
		navigationExtras = Object.assign({}, navigationExtras || {});
		const updatedCommands = navigationExtras.relativeTo ? this.prefix.concat(commands) : commands.slice();
		this._fixRelativeRoute(updatedCommands, navigationExtras);
		return this._router.createUrlTree(updatedCommands, navigationExtras);
	}

	public async navigateByUrl(url: string | UrlTree, extras?: NavigationExtras) {
		if (!this.target) {
			return this._router.navigateByUrl(url, extras);
		}
		const urlStr = url instanceof UrlTree ? this.serializeUrl(url) : url;
		const child = window.open(urlStr, this.target);
		return Promise.resolve(child ? true : false);
	}

	public async navigate(commands: any[], extras?: NavigationExtras) {
		return this.navigateByUrl(this.createUrlTree(commands, extras), extras);
	}
	public serializeUrl(url: UrlTree) {
		return this._router.serializeUrl(url);
	}
	public parseUrl(url: string) {
		return this._router.parseUrl(url);
	}
	public isActive(url: string | UrlTree, exact: boolean) {
		return this._router.isActive(url, exact);
	}
}

@Directive({
	selector: '[knRouterPrefix]',
	providers: [{
		provide: Router,
		useClass: PrefixRouter
	}]
})
export class KnRouterPrefixDirective {
	@Input() public set knRouterPrefix(prefix: any[]) {
		this._prefixRouter.prefix = prefix || [];
	}

	@Input() public set knRouterPrefixTarget(target: string) {
		this._prefixRouter.target = target;
	}

	public constructor(@Inject(Router) private readonly _prefixRouter: PrefixRouter) {
		/* empty */
	}
}
