import {Injectable} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import {HttpBackend} from './http-backend';
import {Request} from '../request';
import {Response} from '../response';
import {Headers} from '../headers';
import {Uri} from '../../uri/uri';
import {ResponseState, ResponseOptions} from '../types';

class BrowserXhrHttpConnection {
	private _responseCache: {
		closed: boolean,
		uri: string | Uri,
		headers: Headers
	};

	public constructor(
			private readonly _xhr: XMLHttpRequest,
			private readonly _request: Request) {
		this._responseCache = {
			closed: false,
			uri: this._request.uri,
			headers: null
		};
	}

	public establish(): Observable<Response> {
		return new Observable((observer: Observer<Response>) => {
			this._xhr.open(this._request.method.toUpperCase(), this._request.uri.toString());

			if (this._request.withCredentials) {
				this._xhr.withCredentials = true;
			}

			this._setHeaders(this._xhr, this._request);

			if (this._request.responseType) {
				this._xhr.responseType = this._request.responseType as XMLHttpRequestResponseType;
			}

			const loadHandler = () => {
				const response = this._createFinishedResponse();
				if (this._isSuccess(response.status)) {
					observer.next(response);
					observer.complete();
				}
				else {
					observer.error(response);
				}
			};
			const errorHandler = (error: ErrorEvent) => {
				observer.error(this._createErrorResponse(error));
			};
			const uploadProgressHandler = (event: ProgressEvent) => {
				observer.next(this._createUploadResponse(event));
			};
			const downloadProgressHandler = (event: ProgressEvent) => {
				observer.next(this._createDownloadResponse(event));
			};

			this._xhr.addEventListener('load', loadHandler);
			this._xhr.addEventListener('error', errorHandler);
			if (this._request.reportProgress) {
				this._xhr.addEventListener('progress', downloadProgressHandler);
				if (this._request.body != null && this._xhr.upload) {
					this._xhr.upload.addEventListener('progress', uploadProgressHandler);
				}
			}

			const teardown = () => {
				this._xhr.removeEventListener('load', loadHandler);
				this._xhr.removeEventListener('error', errorHandler);
				if (this._request.reportProgress) {
					this._xhr.removeEventListener('progress', downloadProgressHandler);
					if (this._request.body !== null && this._xhr.upload) {
						this._xhr.upload.removeEventListener('progress', uploadProgressHandler);
					}
				}
				this._xhr.abort();
			};

			this._xhr.send(this._request.body);

			if (this._request.reportProgress) {
				const initialResponse = new Response(this._request.uri, null, null, {
					state: ResponseState.Initiated,
					loaded: null,
					total: null
				});
				observer.next(initialResponse);
			}

			return teardown;
		});
	}

	private _createFinishedResponse() {
		let status = this._xhr.status;
		const body = status !== 204 ? this._xhr.response : null;

		// normalize potential bug from CORS
		if (status === 0) {
			status = !!body ? 200 : 0;
		}

		const { uri, headers } = this._getUriAndHeaders();
		const options = {
			headers: headers,
			status: status,
			statusText: this._xhr.statusText
		} as ResponseOptions;
		return new Response(uri, body, options);
	}

	private _createErrorResponse(error: ErrorEvent) {
		const { uri, headers } = this._getUriAndHeaders();
		const options = {
			headers: headers,
			status: this._xhr.status || 0,
			statusText: this._xhr.statusText
		} as ResponseOptions;
		return new Response(uri, null, options, {
			state: ResponseState.Error,
			loaded: null,
			total: null
		});
	}

	private _createUploadResponse(event: ProgressEvent) {
		return new Response(this._request.uri, null, null, {
			state: ResponseState.Upload,
			loaded: event.loaded,
			total: event.lengthComputable ? event.total : null
		});
	}

	private _createDownloadResponse(event: ProgressEvent) {
		const { uri, headers } = this._getUriAndHeaders();
		const options = {
			headers: headers,
			status: this._xhr.status,
			statusText: this._xhr.statusText
		} as ResponseOptions;
		return new Response(uri, this._xhr.response, options, {
			state: ResponseState.Download,
			loaded: event.loaded,
			total: event.lengthComputable ? event.total : null
		});
	}

	private _getResponseUrl(xhr: XMLHttpRequest) {
		if ('responseURL' in xhr && xhr.responseURL) {
			return xhr.responseURL;
		}
		if (/^X-Request-URL:/m.test(xhr.getAllResponseHeaders())) {
			return xhr.getResponseHeader('X-Request-URL');
		}
		return null;
	}

	private _setHeaders(xhr: XMLHttpRequest, request: Request) {
		for (const name of request.headers.keys()) {
			request.headers.getAll(name).forEach(x => xhr.setRequestHeader(name, x));
		}
		if (!request.headers.has('Accept')) {
			xhr.setRequestHeader('Accept', 'application/json, text/plain, */*');
		}
		if (!request.headers.has('Content-Type')) {
			const detectedType = this._inferContentType(request.body);
			if (detectedType !== null) {
				xhr.setRequestHeader('Content-Type', detectedType);
			}
		}
	}

	private _inferContentType(body: any) {
		if (body === null) {
			return null;
		}
		if (typeof FormData !== 'undefined' && body instanceof FormData) {
			return null;
		}
		if (typeof Blob !== 'undefined' && body instanceof Blob) {
			return body.type || null;
		}
		if (typeof ArrayBuffer !== 'undefined' && body instanceof ArrayBuffer) {
			return null;
		}
		if (typeof body === 'string') {
			return 'text/plain';
		}
		if (typeof body === 'object' || Array.isArray(body)) {
			return 'application/json';
		}
		return null;
	}

	private _getUriAndHeaders() {
		if (!this._responseCache.closed) {
			this._responseCache = {
				closed: true,
				uri: this._getResponseUrl(this._xhr) || this._responseCache.uri,
				headers: new Headers(this._xhr.getAllResponseHeaders())
			};
		}
		return { uri: this._responseCache.uri, headers: this._responseCache.headers };
	}

	private _isSuccess(status: number) {
		return status >= 200 && status < 300;
	}
}

@Injectable()
export class BrowserXhrHttpBackend extends HttpBackend {
	public handle(request: Request): Observable<Response> {
		const connection = new BrowserXhrHttpConnection(this._createXhf(), request);
		return connection.establish();
	}

	private _createXhf() {
		return new XMLHttpRequest();
	}
}
