import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DateTime} from '../types';

@Component({
	selector: 'kn-date',
	templateUrl: 'date.html'
})
export class KnDate implements OnInit {
	@Input() public date: DateTime;
	@Output() public dateChange = new EventEmitter<DateTime>();
	@Output() public accept = new EventEmitter<DateTime>();

	public viewDate: DateTime;

	public ngOnInit() {
		const today = new Date();
		if (this.viewDate == null) {
			this.viewDate = {
				year: this.date != null ? this.date.year : today.getFullYear(),
				month: this.date != null ? this.date.month : today.getMonth()
			};
		}
	}

	public prevMonth() {
		if (--this.viewDate.month < 0) {
			this.viewDate.year--;
			this.viewDate.month = 11;
		}
		this.viewDate = Object.assign({}, this.viewDate);
	}

	public nextMonth() {
		if (++this.viewDate.month > 11) {
			this.viewDate.year++;
			this.viewDate.month = 0;
		}
		this.viewDate = Object.assign({}, this.viewDate);
	}

	public acceptDate() {
		this.dateChange.next(this.date);
	}
}
