import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {Validator, AsyncValidator} from './types';
import {Utils} from 'kn-utils';

type ValidatorEntry = { validator: Validator | AsyncValidator, message: string };
export type ValidationResult = { [key: string]: any; } | Promise<{ [key: string]: any; }>;
export type ValidatorFn = (control: AbstractControl) => ValidationResult;

@Injectable()
export class ValidationService {
	private _validatorsEntries: { [key: string]: ValidatorEntry } = {};
	private _validators: { [name: string]: ValidatorFn };

	public getErrorMessage(key: string) {
		if (this._validatorsEntries.hasOwnProperty(key)) {
			return this._validatorsEntries[key].message;
		}
		return null;
	}

	public add(name: string, validator: Validator | AsyncValidator, message?: string): void {
		this._validatorsEntries[name] = {
			validator: validator,
			message: message
		} as ValidatorEntry;
		this._validators = null;
	}

	public remove(name: string): void {
		delete this._validatorsEntries[name];
		this._validators = null;
	}

	public clear(): void {
		this._validatorsEntries = {};
		this._validators = null;
	}

	public get validators(): { [name: string]: ValidatorFn } | any {
		if (this._validators == null) {
			this._validators = this._buildValidators();
		}
		return this._validators;
	}

	private _buildValidators(): { [name: string]: ValidatorFn } {
		const validators: { [name: string]: ValidatorFn } = {};
		for (const name in this._validatorsEntries) {
			if (this._validatorsEntries.hasOwnProperty(name)) {
				validators[name] = (...args: any[]) => {
					return (control: AbstractControl) => {
						return this._executeValidator(name, control, args);
					};
				};
			}
		}
		return validators;
	}

	private _executeValidator(name: string, control: AbstractControl, args: any[]) {
		const result = this._validatorsEntries[name].validator.validate(control, ...args);
		return this._unfoldResult(name, result);
	}

	private _unfoldResult(name: string, result: boolean | Promise<boolean>): ValidationResult {
		if (Utils.isPromise(result)) {
			return new Promise<{ [key: string]: any; }>((resolve, reject) => {
				(result as Promise<boolean>).then(
					value => resolve(value ? null : { [name]: true }),
					reason => reject(reason));
			});
		}
		return result ? null : { [name]: true };
	}
}
