<div class="selector" [ngSwitch]="selected">
	<a *ngSwitchCase="0" href="javascript:void(0)" (click)="selectAll()">
		<i class="material-icons">check_box_outline_blank</i>
		<ng-container i18n>No selection</ng-container>
	</a>
	<a *ngSwitchCase="total" href="javascript:void(0)" (click)="deselectAll()">
		<i class="material-icons">check_box_blank</i>
		<ng-container i18n>{{ selected }} selected</ng-container>
	</a>
	<a *ngSwitchDefault href="javascript:void(0)" (click)="deselectAll()">
		<i class="material-icons">indeterminate_check_box</i>
		<ng-container i18n>{{ selected }} selected</ng-container>
	</a>
</div>
