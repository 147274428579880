import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {PrintTemplatesConfig} from './print-templates.config';
import * as Model from 'common-web/model';

@Injectable()
export class PrintTemplatesResourceService extends Resource<Model.PrintTemplate> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: PrintTemplatesConfig) {
		super(rest, notifier, config.printTemplatesTable);
	}
}
