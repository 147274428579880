import {Component, Input} from '@angular/core';
import {DatagridBond, RowItem} from 'kn-datagrid';
import {Utils} from 'kn-utils';

@Component({
	selector: 'kn-grid-selector',
	templateUrl: 'grid-selector.html'
})
export class KnGridSelector {
	@Input() public bond: DatagridBond<any>;

	public get total() {
		return (this.bond.dataSource && this.bond.dataSource.length) || 1;
	}

	public get selected() {
		return (this.bond.selected && this.bond.selected.length) || 0;
	}

	public selectAll() {
		const selectable = this.bond.description.rows.selectable;
		const selectableFunctor: { (item: RowItem): boolean } = Utils.isFunction(selectable)
				? selectable as (item: RowItem) => boolean
				: () => selectable as boolean;
		const visible = this.bond.description.rows.visible;
		const visibleFunctor: { (item: RowItem): boolean } = Utils.isFunction(visible)
				? visible as (item: RowItem) => boolean
				: () => visible as boolean;
		this.bond.onSelectedChange(this.bond.dataSource.filter(x => selectableFunctor(x) && visibleFunctor(x)));
	}

	public deselectAll() {
		this.bond.onSelectedChange([]);
	}
}
