import {BreakableAnnotation} from '../types';
import {AbstractNodeIterator} from '../../utils/abstract-node-iterator';
import {BreakableAnnotationFactory} from './breakable-annotation-factory';

export class BreakableAnnotationIterator extends AbstractNodeIterator {
	public acceptNode(node: Node): number {
		const accept = BreakableAnnotationFactory.test((node as Comment).textContent);
		return accept ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
	}

	protected _whatToShow(): number {
		return NodeFilter.SHOW_COMMENT;
	}

	public get currentAnnotation(): BreakableAnnotation {
		if (this.currentNode == null) {
			return null;
		}
		return BreakableAnnotationFactory.parse((this.currentNode as Comment).textContent);
	}
}
