import {Injector, ElementRef} from '@angular/core';
import {ElementRenderer, ElementRendererFactory, CellValue, CellContext} from 'kn-datagrid';

export class EmailRendererFactory extends ElementRendererFactory {
	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef): ElementRenderer<T> {
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			return value ? `<a href="mailto:${value}">${value}</span>` : '';
		};
	}
}
