import {AbstractAccessControl} from '../access-control/abstract-access-control';
import {AbstractPolicy} from './abstract-policy';

export class Policy extends AbstractPolicy {
	public resource: string;
	public accessControl: AbstractAccessControl;
	public parent: Policy;
	public children: Policy[] = [];

	public static root() {
		return new Policy();
	}

	public static child(resource: string, accessControl: AbstractAccessControl, parent: Policy) {
		const node = new Policy();
		node.resource = resource;
		node.accessControl = accessControl;
		node.parent = parent;
		return node;
	}
}
