import {Inject, LOCALE_ID, Component, Input, Output, EventEmitter} from '@angular/core';
import {DatePipe} from 'kn-common';
import {NotSupportedError} from 'kn-shared';
import {DisplayedViewIndex} from '../views/time-view.component';
import {DateTime} from '../../types';

@Component({
	selector: 'kn-time-header',
	templateUrl: 'time-header.html'
})
export class KnTimeHeader {
	private readonly _datePipe: DatePipe;

	@Input() public time: DateTime;
	@Output() public selectView = new EventEmitter<DisplayedViewIndex>();

	public constructor(@Inject(LOCALE_ID) locale: string) {
		this._datePipe = new DatePipe(locale);
	}

	public getTimePart(part: string) {
		const date = new Date(0);
		if (this.time != null && this.time.hour != null) {
			date.setHours(this.time.hour);
		}
		if (this.time != null && this.time.minute != null) {
			date.setMinutes(this.time.minute);
		}
		const dateString = this._datePipe.transform(date, 'jjmm');
		const parts = dateString.match(/^(.*[^\d])?(\d+)([:.]+)(\d+)([^\d].*)?$/);
		switch (part) {
			case 'prefix':
				return parts[1];
			case 'hours':
				return (this.time != null && this.time.hour != null) ? parts[2] : '--';
			case 'separator':
				return parts[3];
			case 'minutes':
				return (this.time != null && this.time.minute != null) ? parts[4] : '--';
			case 'sufix':
				return parts[5];
		}
		throw new NotSupportedError('Unsuported part name.');
	}
}
