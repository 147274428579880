<kn-sticky>
	<div class="form-container heading">
		<div class="content">
			<h1 i18n>Devices</h1>
		</div>
	</div>
</kn-sticky>
<div class="form-container">
	<div class="form-layout">
		<div class="form-grid">
			<div *ngFor="let devicesOnSite of devicesOnSites">
				<h2>{{ devicesOnSite.site.name }}</h2>
				<p class="address">{{ formatAddress(devicesOnSite.site) }}</p>
				<ul *ngIf="devicesOnSite.devices.length; else noDevicePlaceholder" class="devices-tiles">
					<li *ngFor="let device of devicesOnSite.devices">
						<button class="device-tile"
								[class.live]='device.connection?.live'
								[disabled]='!canConnect(device)'
								type="button"
								(click)="navigate(device)">
							<div class="device-tile-header">
								<!-- missing type -->
								<h3 class="name">{{ device.name }}</h3>
								<pre class="mac-address">{{ device.macAddress }}</pre>
							</div>
							<div class="device-tile-content" *ngIf="device.description || device.adminEmail">
								<span class="description">{{ device.description }}</span>
								<a [attr.href]="'mailto:' + device.adminEmail" *ngIf="device.adminEmail">{{ device.adminEmail }}</a>
							</div>
						</button>
					</li>
				</ul>
				<ng-template #noDevicePlaceholder><p class="no-device">No device defined</p></ng-template>
			</div>
		</div>
	</div>
</div>
