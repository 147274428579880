import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'date' })
export class DatePipe implements PipeTransform {
	private static readonly _formatRegExp: RegExp = /(G+|y+|M+|d+|E+|j+|H+|h+|m+|s+|z+|Z+)(.*)/;
	private static readonly _formatersCache: Map<string, Intl.DateTimeFormat> = new Map<string, Intl.DateTimeFormat>();

	private static readonly _aliases: { [key: string]: string } = {
		'medium': 'yMMMdjms',
		'short': 'yMdjm',
		'fullDate': 'yMMMMEEEEd',
		'longDate': 'yMMMMd',
		'mediumDate': 'yMMMd',
		'shortDate': 'yMd',
		'mediumTime': 'jms',
		'shortTime': 'jm'
	};

	private static readonly _optionsLookup: { [key: string]: Intl.DateTimeFormatOptions } = {
		'G': { era: 'short' },
		'GGGG': { era: 'long' },
		'y': { year: 'numeric' },
		'yy': { year: '2-digit' },
		'M': { month: 'numeric' },
		'MM': { month: '2-digit' },
		'MMM': { month: 'short' },
		'MMMM': { month: 'long' },
		'd': { day: 'numeric' },
		'dd': { day: '2-digit' },
		'EEE': { weekday: 'short' },
		'EEEE': { weekday: 'long' },
		'j': { hour: 'numeric', hour12: undefined as boolean },
		'jj': { hour: '2-digit', hour12: undefined as boolean },
		'h': { hour: 'numeric', hour12: true },
		'hh': { hour: '2-digit', hour12: true },
		'H': { hour: 'numeric', hour12: false },
		'HH': { hour: '2-digit', hour12: false },
		'm': { minute: 'numeric' },
		'mm': { minute: '2-digit' },
		's': { second: 'numeric' },
		'ss': { second: '2-digit' },
		'z': { timeZoneName: 'short' },
		'Z': { timeZoneName: 'long' }
	};

	public constructor(@Inject(LOCALE_ID) private readonly _locale: string) { }

	public transform(value: Date | string | number, format: string = 'mediumDate', timeZone?: string, locales?: string | string[]): string {
		if (value == null) {
			return null;
		}
		const date = (Utils.isString(value)) ? Utils.date.fromIso8601(value as string) : (value as Date | number);
		if (DatePipe._aliases.hasOwnProperty(format)) {
			format = DatePipe._aliases[format];
		}
		locales = locales || this._locale;
		return DatePipe._getOrCreateFormater(format, timeZone, locales).format(date);
	}

	private static _getOrCreateFormater(format: string, timeZone: string, locales: string | string[]): Intl.DateTimeFormat {
		const key = [format, timeZone, locales].join(':');
		if (!DatePipe._formatersCache.has(key)) {
			const formater = DatePipe._createFormater(format, timeZone, locales);
			DatePipe._formatersCache.set(key, formater);
		}
		return DatePipe._formatersCache.get(key);
	}

	private static _createFormater(format: string, timeZone: string, locales: string | string[]): Intl.DateTimeFormat {
		const options: Intl.DateTimeFormatOptions = { timeZone };
		let formatSegment = format;
		while (formatSegment) {
			const match = formatSegment.match(DatePipe._formatRegExp);
			if (!match || !DatePipe._optionsLookup.hasOwnProperty(match[1])) {
				throw new Error('Invalid format.');
			}
			Object.assign(options, DatePipe._optionsLookup[match[1]]);
			formatSegment = match[2];
		}
		return new Intl.DateTimeFormat(locales, options);
	}
}
