import {Injectable} from '@angular/core';
import {Model, Description, DataSource, Column, RowItem, CellContext} from '../types';
import {Node} from '../model/node';
import {ValueResolveUtils} from '../internal-utils';
import {NodeTreeBuilderFactory} from './note-tree-builder-factory.service';
import {NodeTreeBuilder} from './builders/node-tree-builder';
import {ColumnsBuilderFactory} from './columns-builder-factory.service';
import {ColumnsBuilder} from './builders/columns-builder';

export interface Spreadsheet {
	columns: SpreadsheetColumn[];
	data: SpreadsheetData[];
}

export interface SpreadsheetColumn {
	id: string;
	group: boolean;
	label: string;
}

export interface SpreadsheetData {
	values: any[];
	cells: string[];
	children: SpreadsheetData[];
}

@Injectable()
export class SpreadsheetBuilder {
	private readonly _nodeTreebuilder: NodeTreeBuilder<RowItem>;
	private readonly _columnsBuilder: ColumnsBuilder<RowItem>;

	public constructor(
			nodeTreeBuilderFactory: NodeTreeBuilderFactory,
			columnsBuilderFactory: ColumnsBuilderFactory) {
		this._nodeTreebuilder = nodeTreeBuilderFactory.create<RowItem>();
		this._columnsBuilder = columnsBuilderFactory.create<RowItem>();
	}

	public build(dataSource: DataSource<RowItem>, exportTarget: string, model: Model, description: Description<RowItem>): Spreadsheet {
		const columns = this._columnsBuilder.build(description, model);
		const visibleColumns = this._columnsBuilder.filterVisibleColumns(columns);
		const filteredColumns = visibleColumns.filter(x => x.description.exportable);
		const root = this._nodeTreebuilder.build(columns, dataSource);
		const nodes = root.children;

		const spreadsheet: Spreadsheet = {
			columns: filteredColumns.map(column => ({
				id: column.id,
				group: column.model.group,
				label: ValueResolveUtils.resolveLabel(column)
			})),
			data: []
		};

		for (const node of nodes) {
			spreadsheet.data.push(this._exportNode(node, filteredColumns, exportTarget));
		}

		return spreadsheet;
	}

	private _exportNode(node: Node<RowItem>, columns: Column<RowItem>[], exportTarget: string): SpreadsheetData {
		const data: SpreadsheetData = {
			values: [],
			cells: [],
			children: []
		};
		for (const column of columns) {
			const cell = this._renderCell(node, column, exportTarget);
			data.values.push(cell.value);
			data.cells.push(cell.rendered as string);
		}
		for (const child of node.children) {
			data.children.push(this._exportNode(child, columns, exportTarget));
		}
		return data;
	}

	private _renderCell(node: Node<RowItem>, column: Column<RowItem>, exportTarget: string): any {
		const value = column.description.accessor(node.item, column);
		const context: CellContext<RowItem> = { node, column };
		return {
			value,
			rendered: column.description.renderer(value, context, exportTarget)
		};
	}
}
