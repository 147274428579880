import {Description, RowItem, Model, DataSource, Column} from '../types';
import {DescriptionBuilder} from '../description-builder';
import {ModelBuilder} from '../model-builder';
import {Utils} from 'kn-utils';
import {AbstractConcreteDatagridBond} from './abstract-concrete-datagrid-bond';

export class DatagridBond<T extends RowItem> extends AbstractConcreteDatagridBond<T> {
	private _lastSorting: Column<T>[];
	private readonly _dataSource: DataSource<T>;

	public loading: boolean = false;
	public description: Description<T>;
	public model: Model;
	public dataSource: DataSource<T>;
	public expanded: T[];
	public selected: T[];

	public constructor(value: DataSource<T>) {
		super();
		const columns = Utils.array.unique(value.map(x => Object.keys(x)).reduce((acc, x) => acc.concat(x), []));
		this.description = DescriptionBuilder.from({
			columns: columns.map(x => ({ id: x }))
		});
		this.model = ModelBuilder.from({}, this.description);
		this.dataSource = value;
		this._dataSource = value;
		this._lastSorting = [];
	}

	public onModelChange(value: Model) {
		this.model = value;
		const sorting = this._getSorting(this.model.columns, this.description);
		if (sorting.length === 0) {
			this.dataSource = this._dataSource;
		}
		else if (sorting.length !== this._lastSorting.length
				|| sorting.some((x, index) => x !== this._lastSorting[index])) {
			this.dataSource = this._dataSourceSort(this._dataSource.slice(0), sorting);
		}
		this._lastSorting = sorting;
	}

	public onDataSourceChange(value: DataSource<T>) { /* intentionally empty */ }
	public onExpandedChange(value: T[]) { /* intentionally empty */ }
	public onSelectedChange(value: T[]) { /* intentionally empty */ }
	public onRowSelect(value: T) { /* intentionally empty */ }
	public onRowActivate(value: T) { /* intentionally empty */ }

	public static fromArray<T extends RowItem>(value: DataSource<T>) {
		return new DatagridBond<T>(value);
	}
}
