import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Description, Model, DataSource} from 'kn-datagrid';
import {Utils} from 'kn-utils';
import {GridExporterService} from '../../../exporters/grid-exporter.service';

@Component({
	selector: 'kn-export-button',
	templateUrl: 'export-button.html'
})
export class KnExportButton implements OnChanges {
	public exporters: { type: string, name: string }[];

	@Input() public view: string;
	@Input() public description: Description<any>;
	@Input() public model: Model;
	@Input() public dataSource: DataSource<any>;
	@Input() public portalClass: string;
	@Input() public filter: string;
	public loading: boolean;

	public constructor(private _exporter: GridExporterService) {
		this.refilterExporters();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('filter' in changes) {
			this.refilterExporters();
		}
	}

	public async export(type: string) {
		this.loading = true;
		const result = this._exporter.export(this.description, this.model, this.dataSource, {
			type: type,
			saveAs: `${this.view}-${Utils.date.toIso8601(new Date(), 'date')}.${type}`
		});
		result.then(() => this.loading = false);
		result.catch(() => this.loading = false);
		return result;
	}

	private refilterExporters() {
		const filterValues = this.filter == null
			? []
			: this.filter.split(',')
				.filter(x => x == null || x.length > 0)
				.map(x => x.toUpperCase());
		this.exporters = this._exporter.getSupportedTypes()
			.map(x => ({ type: x, name: x.toUpperCase() }))
			.filter(x => filterValues.length === 0 || filterValues.some(f => f === x.name));
	}
}
