export class MathUtils {
	public static permutations(n: number, k?: number): IterableIterator<number[]>;
	public static permutations<T>(array: T[], k?: number): IterableIterator<number[]>;
	public static * permutations<T>(arrayOrN: T[] | number, k?: number): IterableIterator<T[]> {
		const array = MathUtils._toArray(arrayOrN);
		k = k != null ? k : array.length;
		const c = Array(array.length).fill(0);
		yield array.slice(0, k);

		let i = 1;
		while (i < array.length) {
			if (c[i] < i) {
				const index = i % 2 && c[i];
				[array[i], array[index]] = [array[index], array[i]];
				c[i]++;
				i = 1;
				yield array.slice(0, k);
			}
			else {
				c[i] = 0;
				i++;
			}
		}
	}

	public static combinations(n: number, k?: number): IterableIterator<number[]>;
	public static combinations<T>(array: T[], k?: number): IterableIterator<number[]>;
	public static * combinations<T>(arrayOrN: T[] | number, k?: number): IterableIterator<T[]> {
		const array = MathUtils._toArray(arrayOrN);
		k = k != null ? k : array.length;
		const keys = Array(k).fill(-1);
		let index = 0;
		while (index >= 0) {
			if (keys[index] < array.length - (k - index)) {
				const key = keys[index] - index + 1;
				while (index < k) {
					keys[index] = key + index;
					index++;
				}
				yield keys.map(c => array[c]);
			}
			else {
				index--;
			}
		}
	}

	public static round(n: number, d: number): number {
		let part = 0;
		if (('' + n).indexOf('e') < 0) {
			part = Math.round(Number(n + 'e+' + d));
		}
		else {
			const arr = ('' + n).split('e');
			let sig = '';
			if (Number(arr[1]) + d > 0) {
				sig = '+';
			}
			part = Math.round(Number(arr[0] + 'e' + sig + (Number(arr[1]) + d)));
		}
		return Number(part + 'e-' + d);
	}

	private static _toArray<T>(arrayOrN: T[] | number) {
		return (Array.isArray(arrayOrN) ? arrayOrN : Array.from(Array(arrayOrN).keys())) as T[];
	}
}
