import {Component, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import * as Rx from 'rxjs/operators';
import {ViewTemplateEditService} from './view-template-edit.service';
import {ValidationService, AsyncCheckValidator} from 'kn-forms';
import {AbstractStoreEditComponent} from 'common-web/forms';
import {ViewTemplateModel} from './view-template-model';
import * as CommonModel from 'common-web/model';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';
import {UsersResourceService} from '../../services/users/users-resource.service';

@Component({
	selector: 'app-view-template-edit',
	templateUrl: 'view-template-edit.html',
	providers: [ValidationService]
})
export class ViewTemplateEditComponent extends AbstractStoreEditComponent<ViewTemplateModel> {
	public users: CommonModel.User[];
	public views: { label: string, value: string }[];
	public viewName: string;

	public constructor(
			injector: Injector,
			viewTemplateEditService: ViewTemplateEditService,
			protected readonly _viewTemplatesResource: ViewTemplatesResourceService,
			protected readonly _usersResource: UsersResourceService) {
		super(injector, viewTemplateEditService);

		const nameChecker = (value: string) => {
			let query = {
				$name: this.form.get(['viewTemplate', 'name']).value,
				$view: this.form.get(['viewTemplate', 'view']).value,
				$userUid: this.form.get(['viewTemplate', 'userUid']).value,
				count: null as number
			};
			if (this.isEditMode()) {
				query = Object.assign(query, { '$id!': this.form.get(['viewTemplate', 'id']).value });
			}
			return this._viewTemplatesResource.query({ query })
				.pipe(Rx.map(next => (next as any as CommonModel.CountResponse).count === 0));
		};
		const nameValidator = new AsyncCheckValidator<string>(nameChecker);
		this._validation.add('name', nameValidator, this._i18n.t('Name must be unique', 'Duplicate'));
	}

	protected _load() {
		super._load();

		const subscriptions = [
			this._fetch(this._usersResource, {query: {only: ['uid', 'fullName'], sort: 'fullName' }}).subscribe(next => this.users = next),
			this._fetch(this._viewTemplatesResource, {query: { only: 'view', distinct: 'view' }})
				.subscribe(next => this.views = next.map(x => ({ label: this._i18n.t(x.view), value: x.view })))
		];
		subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
	}

	protected _retriveIndexer() {
		const id = this._route.snapshot.params['id'];
		return id && { query: { $id: id } };
	}

	protected _populateFormValues(form: FormGroup, model: ViewTemplateModel) {
		super._populateFormValues(form, model);
		if (model && model.viewTemplate) {
			this.viewName = this._i18n.t(model.viewTemplate.view);
		}
	}

	protected _buildControlGroup(key: string): FormGroup {
		switch (key) {
			case 'viewTemplate':
				return this._buildViewTemplateControlGroup();
		}
		throw new Error('Unknown model control group.');
	}

	protected _buildViewTemplateControlGroup() {
		const validators = this._validation.validators;
		const result = this._formBuilder.group({
			id: [undefined],
			userUid: ['', [], validators.name()],
			view: ['', [validators.required()], validators.name()],
			version: ['', [validators.maxLength(16)]],
			name: ['', [validators.maxLength(40), validators.required()], validators.name()],
			value: ['', [validators.maxLength(16384)]],
			hidden: [false]
		});
		const nameInput = result.get('name');
		const userUidInput = result.get('userUid');
		const viewInput = result.get('view');
		nameInput.statusChanges.subscribe(() => {
			if (nameInput.status !== userUidInput.status) {
				userUidInput.updateValueAndValidity({emitEvent: false});
			}
			if (nameInput.status !== viewInput.status) {
				viewInput.updateValueAndValidity({emitEvent: false});
			}
		});
		userUidInput.statusChanges.subscribe(() => {
			if (userUidInput.status !== nameInput.status) {
				nameInput.updateValueAndValidity({emitEvent: false});
			}
			if (userUidInput.status !== viewInput.status) {
				viewInput.updateValueAndValidity({emitEvent: false});
			}
		});
		viewInput.statusChanges.subscribe(() => {
			if (viewInput.status !== nameInput.status) {
				nameInput.updateValueAndValidity({emitEvent: false});
			}
			if (viewInput.status !== userUidInput.status) {
				userUidInput.updateValueAndValidity({emitEvent: false});
			}
		});
		return result;
	}
}
