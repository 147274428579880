import {AbstractPreprocessor} from './abstract-preprocessor';

export abstract class AbstractIterablePreprocessor extends AbstractPreprocessor /* implements NodeFilter */ {
	public process(node: Node): Node {
		const iterator = document.createNodeIterator(node, this.whatToShow(), this);
		let child: Node;
		// eslint-disable-next-line no-cond-assign
		while (child = iterator.nextNode()) {
			this._processNode(child);
		}
		return node;
	}

	public whatToShow(): number {
		return NodeFilter.SHOW_ALL;
	}

	public acceptNode(node: Node): number {
		return NodeFilter.FILTER_ACCEPT;
	}

	protected abstract _processNode(node: Node): void;
}
