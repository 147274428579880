import {Observable, of as observableOf, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {AbstractResource} from 'kn-rest';
import {UriContext} from 'kn-http';
import {DefaultFilterSerializer, AbstractFilterSerializer, BooleanOperator, Model as FilterModel} from 'kn-query-filter';
import {RemoteAutocompletition, RemoteAutocompleteBondConfig} from 'kn-forms';
import {Utils} from 'kn-utils';

function buildModel<T>(
		modelOrProperties: ((value: T) => FilterModel) | string[] | string,
		operator: string): (value: T) => FilterModel {
	if (Array.isArray(modelOrProperties)) {
		return (value: T) => ({
			operator: BooleanOperator.Or,
			children: modelOrProperties.map(x => ({ id: x, operator: operator, value: value }))
		});
	}
	else if (Utils.isString(modelOrProperties)) {
		return (value: T) => ({
			operator: BooleanOperator.And,
			children: [{ id: modelOrProperties as string, operator: operator, value: value }]
		});
	}
	return modelOrProperties as (value: T) => FilterModel;
}

export function autocompletitionFactory<T>(
		resource: AbstractResource<T>,
		queryModelOrProperties: ((query: string) => FilterModel) | string[] | string,
		valueModelOrProperties: ((value: any) => FilterModel) | string = 'id',
		query?: { [key: string]: any },
		config?: RemoteAutocompleteBondConfig,
		serializer?: AbstractFilterSerializer<string>) {
	let valueCache: { value: any, response: any };
	const serialize = (serializer || DefaultFilterSerializer).serialize;

	const queryModel = buildModel(queryModelOrProperties, 'contains');
	const valueModel = buildModel(valueModelOrProperties, 'eq');

	return new RemoteAutocompletition<T>(context => {
		const requests$: Observable<T[]>[] = [];
		if (context.value != null && valueModel != null) {
			if (valueCache != null && Utils.equal(valueCache.value, context.value)) {
				requests$.push(observableOf(valueCache.response));
			}
			else {
				let request$ = resource.query({
					query: Object.assign({ q: serialize(valueModel(context.value)) }, query)
				});
				request$ = request$
					.pipe(Rx.tap(next => valueCache = { value: context.value, response: next }));
				requests$.push(request$);
			}
		}
		if (queryModel != null) {
			const ctx: UriContext = { query: { limit: context.limit, ...query } };
			if (context.query != null) {
				ctx.query.q = serialize(queryModel(context.query));
			}
			const request$ = resource.query(ctx);
			requests$.push(request$);
		}
		if (requests$.length === 0) {
			return observableOf([]);
		}
		const unique = (items: T[]) => {
			const result: T[] = [];
			for (const item of items) {
				if (!result.some(x => Utils.equal(x, item))) {
					result.push(item);
				}
			}
			return result;
		};
		return observableCombineLatest(requests$)
			.pipe(Rx.map(next => unique(Utils.array.flatten(next, true))));
	}, config);
}
