import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import * as Model from '../../model/customer-database.types';

@Injectable()
export class ReportsResourceService extends Resource<Model.ReportEntry> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(rest, notifier, 'Reports');
	}
}
