import {Routes, RouterModule} from '@angular/router';
import {ComponentDeactivateGuard} from 'kn-modal';
import {DatabasesViewComponent} from './databases-view.component';
import {DatabaseEditComponent} from './database-edit.component';

const routes: Routes = [
	{
		path: '',
		component: DatabasesViewComponent
	}, {
		path: ':id/:action',
		component: DatabaseEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}, {
		path: 'new',
		component: DatabaseEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}
];

export const routing = RouterModule.forChild(routes);
