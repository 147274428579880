import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http, Response, Uri, UriTemplate, UriContext} from 'kn-http';
import {AbstractConcreteFetcher} from './abstract-concrete-fetcher';
import {ChangeAction} from '../change-notification/types';
import {IndexerResolver} from './indexable/indexer-resolver';
import {Indexer} from '../types';

export class IndexableFetcher<T extends { [key: string]: any }> extends AbstractConcreteFetcher<T> {
	protected _uri: Uri;

	public constructor(
			protected _http: Http,
			uriTemplate: string,
			table: string,
			protected _indexerResolver: IndexerResolver<T>) {
		super(table);
		this._uri = new Uri(new UriTemplate(uriTemplate));
	}

	public expandUri(indexer: Indexer, context: UriContext) {
		let indexerContext: UriContext;
		if (indexer != null) {
			indexerContext = this._indexerResolver.getContextFromIndexer(indexer);
		}
		return this._uri.expand(Object.assign({}, context, indexerContext));
	}

	public get http(): Http {
		return this._http;
	}

	protected _get(indexer: Indexer, context: UriContext): Observable<T | T[]>;
	protected _get<U extends T | T[]>(indexer: Indexer, context: UriContext): Observable<U> {
		return this._http.get(this._buildUri(indexer, context))
			.pipe(Rx.map(x => x.body as U));
	}

	protected _head(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._http.head(this._buildUri(indexer, context));
	}

	protected _postOrPut(item: T, context: UriContext): Observable<Response> {
		const postOrPut = this._indexerResolver.getIndexer(item) == null ? this._post : this._put;
		return postOrPut.bind(this)(item, context);
	}

	protected _post(item: T, context: UriContext): Observable<Response> {
		const body = JSON.stringify(item);
		return this._http.post(this._buildUri(null, context), body)
			.pipe(Rx.tap(next => {
				const indexer = this._indexerResolver.getIndexerFromResponse(next);
				this._indexerResolver.setIndexer(item, indexer);
				this._emitChange(indexer, ChangeAction.Added);
			}));
	}

	protected _put(item: T, context: UriContext): Observable<Response> {
		const body = JSON.stringify(item);
		const indexer = this._indexerResolver.getIndexer(item);
		return this._http.put(this._buildUri(indexer, context, true), body)
			.pipe(Rx.tap(() => this._emitChange(indexer, ChangeAction.Modified)));
	}

	protected _delete(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._http.delete(this._buildUri(indexer, context))
			.pipe(Rx.tap(() => this._emitChange(indexer, ChangeAction.Deleted)));
	}

	protected _buildUri(indexer: Indexer, context: UriContext, strictIndexer: boolean = false) {
		let indexerContext: UriContext;
		if (strictIndexer || indexer != null) {
			indexerContext = this._indexerResolver.getContextFromIndexer(indexer);
		}
		return this._uri.build(Object.assign({}, context, indexerContext));
	}
}
