import {coerceBoolean} from '../coercion/coerce-boolean';

export function BooleanField() {
	return (target: any, key: string) => {
		const defaultValue = target[key];
		const localKey = `__kn_private_symbol_${key}`;
		target[localKey] = defaultValue;

		/* eslint-disable no-invalid-this */
		Object.defineProperty(target, key, {
			get: function() {
				return this[localKey];
			},
			set: function(value: any) {
				this[localKey] = coerceBoolean(value);
			}
		});
		// tslint:restore:no-invalid-this
	};
}
