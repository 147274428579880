import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';

export interface AnimationOptions {
	start?: number | number[];
	end?: number | number[];
	duration?: number;
	timingFunction?: (k: number) => number;
}

export interface AnimationContext {
	startTime: number;
	start: number[];
	end: number[];
	duration: number;
	timingFunction: (k: number) => number;
	action: (...values: number[]) => void;
	frame: number;
}

export type Disposable = Function;

@Injectable()
export class AnimationService {
	public run(action: (...values: number[]) => void, options?: AnimationOptions): Disposable {
		let start: number[] = null;
		let end: number[] = null;
		if (options.end != null) {
			end = Utils.array.box(options.end);
		}
		if (options.start != null) {
			start = Utils.array.box(options.start);
		}
		if (start == null && end != null) {
			start = end.map(x => 0);
		}
		if (start != null && end == null) {
			end = start.map(x => 1);
		}
		if (start == null && end == null) {
			start = [0];
			end = [1];
		}
		if (start.length !== end.length) {
			throw new Error('Start and end vector has different lengths.');
		}

		const context: AnimationContext = {
			startTime: window.performance.now(),
			start: start,
			end: end,
			duration: options.duration != null ? options.duration : 300,
			timingFunction: options.timingFunction != null ? options.timingFunction : AnimationService.ease,
			action: action,
			frame: 0
		};

		this._animationStep(context);
		return this._cancel.bind(this, context);
	}

	private _animationStep(context: AnimationContext) {
		context.frame = window.requestAnimationFrame(this._animationStep.bind(this, context));
		let elapsed = (window.performance.now() - context.startTime) / context.duration;
		elapsed = elapsed > 1 ? 1 : elapsed;
		const current = this._interpolate(context, elapsed);
		context.action.apply(null, current);
		if (this._isFinished(context, current)) {
			this._cancel(context);
			return;
		}
	}

	private _cancel(context: AnimationContext) {
		context.action.apply(null, context.end);
		context.frame && window.cancelAnimationFrame(context.frame);
	}

	private _interpolate(context: AnimationContext, elapsed: number): number[] {
		const value = context.timingFunction(elapsed);
		const current: number[] = [];

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < context.end.length; i++) {
			current.push(context.start[i] + (context.end[i] - context.start[i]) * value);
		}

		return current;
	}

	private _isFinished(context: AnimationContext, current: number[]): boolean {
		return context.end.every((x, index) => x === current[index]);
	}

	public static ease(k: number) {
		return 0.5 * (1 - Math.cos(Math.PI * k));
	}

	public static linear(k: number) {
		return k;
	}
}
