import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnTextarea} from './textarea.component';

const KN_TEXTAREA_DIRECTIVES = [
	KnTextarea
];

@NgModule({
	imports: [CommonModule, FormsModule, KnDirectivesModule],
	declarations: [KN_TEXTAREA_DIRECTIVES],
	exports: [KN_TEXTAREA_DIRECTIVES]
})
export class KnTextareaModule { }
