import {Injectable} from '@angular/core';
import {ColumnsBuilder} from './builders/columns-builder';
import {RowItem} from '../types';

@Injectable()
export class ColumnsBuilderFactory {
	public create<T extends RowItem>() {
		return new ColumnsBuilder<T>();
	}
}
