<kn-menu *ngIf="filteredPrintTemplates.length" [ngClass]="{'disabled': loading}" (expandedChange)="onExpandedChange($event)">
	<kn-menu-activator>
		<i class="material-icons">{{ isFile ? 'file_download' : 'print' }}</i>
		<i class="material-icons drop-down">arrow_drop_down</i>
		<kn-spinner [show]="loading"></kn-spinner>
	</kn-menu-activator>
	<kn-menu-content class="kn-print-button-content kn-grid-button-content">
		<ul>
			<li *ngFor="let printTemplate of filteredPrintTemplates" (click)="print(printTemplate.id)">
				<span>{{ printTemplate.name }}</span>
			</li>
			<ng-content></ng-content>
		</ul>
	</kn-menu-content>
</kn-menu>
