import {Routes, RouterModule} from '@angular/router';
import {PreloadUserSettingsGuard} from 'kn-user-settings';
import {ComponentDeactivateGuard} from 'kn-modal';
import {ViewTemplatesViewComponent} from './view-templates-view.component';
import {ViewTemplateEditComponent} from './view-template-edit.component';

const routes: Routes = [
	{
		path: '',
		component: ViewTemplatesViewComponent,
		canActivate: [PreloadUserSettingsGuard]
	}, {
		path: ':id/:action',
		component: ViewTemplateEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}, {
		path: 'new',
		component: ViewTemplateEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}
];

export const routing = RouterModule.forChild(routes);
