import {Injectable} from '@angular/core';
import {Observable, of as observableOf} from 'rxjs';
import {AbstractDiscriminatorProvider} from './abstract-discriminator-provider';

@Injectable()
export class NullDiscriminatorProvider extends AbstractDiscriminatorProvider {
	public discriminatorChange: Observable<string> = observableOf('');

	public discriminator(key?: string, version?: string, arg?: any): string {
		return '';
	}
}
