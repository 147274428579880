import {Inject, Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges, ViewChild, Output, EventEmitter} from '@angular/core';
import * as Rx from 'rxjs/operators';
import {ToastService} from 'kn-modal';
import {KnMenu} from 'kn-components';
import {PrinterService, PrintContext} from 'kn-print';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {PrintTemplate} from './internal-types';
import {PrintBaseConfiguration} from '../../../types';
import {expandPrintConfiguration} from '../../../expand-print-configuration';
import {PRINT_BASE_CONFIGURATION_TOKEN} from '../../../print-base-configuration.token';
import {PrintTemplatesResourceService} from '../../../services/print-templates-resource.service';
import {GridPrintRendererFilter} from '../types';

@Component({
	selector: 'kn-print-button',
	templateUrl: 'print-button.html'
})
export class KnPrintButton implements OnInit, OnChanges, OnDestroy {
	private readonly _disposables: Function[] = [];
	private _printTemplates: PrintTemplate[] = [];
	private _printingTemplateTypes: string[] = [];
	public filteredPrintTemplates: PrintTemplate[] = [];

	@Input() public prefix: string;
	@Input() public views: string[];
	@Input() public context: PrintContext | { (): PrintContext };
	@Input() public filter: GridPrintRendererFilter = GridPrintRendererFilter.All;

	@Output() public expandedChange = new EventEmitter<boolean>();

	@ViewChild(KnMenu, { static: false })
	public menu: KnMenu;

	public loading: boolean;

	public get isFile(): boolean {
		return this.filter === GridPrintRendererFilter.File;
	}

	public get isPrint(): boolean {
		return this.filter === GridPrintRendererFilter.Print;
	}

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _toast: ToastService,
			private readonly _printer: PrinterService,
			private readonly _printTemplatesResource: PrintTemplatesResourceService,
			@Inject(PRINT_BASE_CONFIGURATION_TOKEN) private readonly _printBaseConfiguration: PrintBaseConfiguration) {
	}

	public ngOnInit() {
		const query = { '$view^': this.prefix, 'only': ['id', 'name', 'view', 'type'], 'sort': ['view', 'name'] };
		const subscription = this._printTemplatesResource.query({ query })
			.pipe(
				Rx.map(next => next
					.map(x => ({ id: x.id, name: x.name, view: x.view, type: x.type } as PrintTemplate)))
			)
			.subscribe(
				next => {
					this._printTemplates = next;
					this._refilterPrintTemplates();
				},
				error => this._toast.show(this._i18n.t('Loading list of print templates failed.'), error.statusText)
			);
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('views' in changes || 'filter' in changes) {
			this._refilterPrintTemplates();
		}
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public onExpandedChange(value: boolean) {
		this.expandedChange.emit(value);
	}

	public collapse() {
		this.menu?.collapse();
	}

	public print(id: number) {
		const printTemplate = this._printTemplates.find(x => x.id === id);
		if (printTemplate == null) {
			return;
		}
		const dateStr = Utils.date.toIso8601(new Date(), 'date');
		let fileName = `${printTemplate.view}-${dateStr}`;
		let context = this.context as PrintContext;
		if (Utils.isFunction(this.context)) {
			context = (this.context as () => PrintContext)();
		}
		this._printTemplatesResource.get(id)
			.pipe(
				Rx.tap(() => this.loading = true),
				Rx.map(next => {
					const cfg = JSON.parse(next.configuration || '{}');
					const template = Object.assign(next, {
						configuration: expandPrintConfiguration(this._printBaseConfiguration, cfg)
					}) as PrintTemplate;
					if (template.configuration.fileName != null) {
						const datedContext = Object.assign({ dateStr }, context);
						fileName = Utils.text.interpolate(template.configuration.fileName, datedContext);
					}
					return template;
				}),
				Rx.switchMap(next => this._printer.print(next.type, next.template, context, next.configuration, { saveAs: this._printer.getFileName(next.type, fileName) }))
			)
			.subscribe(
				() => { /* intended empty*/ },
				error => {
					this.loading = false;
					this._toast.show(this._i18n.t('Print failed.'), error.statusText || error);
				},
				() => this.loading = false
			);
		this.menu.collapse();
	}

	private _refilterPrintTemplates() {
		if (this.views == null) {
			this.filteredPrintTemplates = [];
		}
		this._printingTemplateTypes = this._printer.backends.filter(x => x.print).map(x => x.type);
		this.filteredPrintTemplates = this._printTemplates
			.filter(x => this.views.indexOf(x.view) !== -1 && this._isMatchingNature(x.type));
	}

	private _isMatchingNature(type: string): boolean {
		if (this.filter === GridPrintRendererFilter.All) {
			return true;
		}
		const isPrint = this._printingTemplateTypes.indexOf(type.toLowerCase()) !== -1;
		return isPrint && this.isPrint
			|| !isPrint && this.isFile;
	}
}
