import {RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle} from '@angular/router';
import {DashboardComponent} from './views/customer/dashboard/dashboard.component';

export class AppRouteReuseStrategy extends RouteReuseStrategy {
	public shouldDetach(route: ActivatedRouteSnapshot) {
		return false;
	}

	public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle) {
		return;
	}

	public shouldAttach(route: ActivatedRouteSnapshot) {
		return false;
	}

	public retrieve(route: ActivatedRouteSnapshot) {
		return null as DetachedRouteHandle;
	}

	public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) {
		if (future.component === DashboardComponent) {
			return false;
		}
		return future.routeConfig === curr.routeConfig;
	}
}
