import {NgModule, ModuleWithProviders} from '@angular/core';
import {QueryFilterExpanderService} from './query-filter-expander.service';

@NgModule()
export class KnQueryFilterExpanderModule {
	public static forRoot(): ModuleWithProviders<KnQueryFilterExpanderModule> {
		return {
			ngModule: KnQueryFilterExpanderModule,
			providers: [
				QueryFilterExpanderService
			]
		};
	}
}
