import {Utils} from 'kn-utils';
import {UriContext} from './types';

export function mergeContext(...contexts: UriContext[]): UriContext {
	const result = {} as UriContext;
	for (const context of contexts) {
		mergeObject(result, context);
	}
	return result;
}

function mergeObject(to: UriContext, from: UriContext) {
	for (const key in from) {
		if (!from.hasOwnProperty(key)) {
			continue;
		}
		const value = from[key];
		if (Utils.isObject(value) && !Array.isArray(value)) {
			if (!to.hasOwnProperty(key)) {
				to[key] = {};
			}
			mergeObject(to[key], value);
		}
		else {
			if (Array.isArray(to[key])) {
				if (Array.isArray(value)) {
					to[key].push(...value);
				}
				else {
					to[key].push(value);
				}
			}
			else {
				if (Array.isArray(value)) {
					if (to.hasOwnProperty(key)) {
						to[key] = [to[key], ...value];
					}
					else {
						to[key] = value.slice();
					}
				}
				else if (to.hasOwnProperty(key)) {
					to[key] = [to[key], value];
				}
				else {
					to[key] = value;
				}
			}
		}
	}
}
