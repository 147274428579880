import {Injectable, Inject, Optional} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {HttpWrapper, Http, Request, Response, Uri, UriPath, UriQuery} from 'kn-http';
import {Utils} from 'kn-utils';
import {CacheProvider} from './cache.provider';

export class CachedHttpOptions { }

@Injectable()
export class CachedHttp extends HttpWrapper {
	private readonly _activeRequests = new Map<string, Observable<Response>>();

	public constructor(
			@Inject(Http) http: Http,
			private readonly _cache: CacheProvider,
			@Optional() options?: CachedHttpOptions) {
		super(http);
	}

	protected _executeRequest(request: Request): Observable<Response> {
		const method = request.method.toLowerCase();
		if (method !== 'get') {
			if (method !== 'head') {
				this.pruneUri(Uri.from(request.uri));
			}
			return super._executeRequest(request);
		}
		const url = Uri.build(request.uri);
		return new Observable((observer: Observer<Response>) => {
			const cachedResult = this._cache.get(url) as Response;
			if (cachedResult != null) {
				observer.next(Utils.clone(cachedResult, true));
				observer.complete();
				return () => { /* empty */};
			}
			const subscription = this._getOrCreateRequest(request, url).subscribe(
				next => observer.next(next),
				error => observer.error(error),
				() => observer.complete());
			const teardown = () => {
				subscription.unsubscribe();
			};
			return teardown;
		});
	}

	private _getOrCreateRequest(request: Request, url: string): Observable<Response> {
		if (this._activeRequests.has(url)) {
			return this._activeRequests.get(url);
		}
		let done = false;
		const request$ = super._executeRequest(request).pipe(
			Rx.tap(next => {
				this._cache.put(url, next);
				this._activeRequests.delete(url);
				done = true;
			}),
			Rx.publishReplay(1),
			Rx.refCount(),
			Rx.map(next => Utils.clone(next, true))
		);
		if (!done) {
			this._activeRequests.set(url, request$);
		}
		return request$;
	}

	public pruneUri(uri: Uri) {
		uri.query = new UriQuery();
		uri.fragment = '';
		const prefix = uri.toString();
		const exactPaths: string[] = [];
		const parameters = uri.path.parameters;
		while (parameters.length > 0) {
			uri.path = new UriPath(parameters);
			exactPaths.push(uri.toString());
			parameters.pop();
		}
		this._cache.keys()
			.filter(x => exactPaths.indexOf(x.split('?', 1)[0]) !== -1 || x.startsWith(prefix))
			.forEach(x => this._cache.remove(x));
	}

	public pruneUriAll() {
		this._cache.clear();
		this._activeRequests.clear();
	}
}
