export const MsTimeConsts = {
	Seconds: 1000, // ms_in_s
	Minutes: 60 * 1000, // ms_in_minute
	Hours: 60 * 60 * 1000, // ms_in_hour
	Days: 24 * 60 * 60 * 1000, // ms_in_day
	Weeks: 7 * 24 * 60 * 60 * 1000, // ms_in_week
	Months: 4 * 7 * 24 * 60 * 60 * 1000, // ms_in_month
	Years: 365 * 24 * 60 * 60 * 1000 // ms_in_year
};

export type DateRangeUnitType =
	'seconds' |
	'minutes' |
	'hours' |
	'days' |
	'weeks' |
	'months' |
	'years';

export const DateRangeUnitType = {
	Seconds: 'seconds' as DateRangeUnitType,
	Minutes: 'minutes' as DateRangeUnitType,
	Hours: 'hours' as DateRangeUnitType,
	Days: 'days' as DateRangeUnitType,
	Weeks: 'weeks' as DateRangeUnitType,
	Months: 'months' as DateRangeUnitType,
	Years: 'years' as DateRangeUnitType
};
