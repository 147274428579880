import {DefaultFontResolver} from './default-font-resolver';
import {CssFontStyle, CssFontVariant, CssFontStretch, CssFontFace} from '../../css-font.type';
import {SandboxedDocument} from '../../sandboxed-document';
import {DocumentQueries} from '../../document-queries';
import {DefaultXhrFetcher} from '../../utils/default-xhr-fetcher';

export interface RegistrationFontResolverOptions {
	fetcher?: (uri: string, binary: boolean) => Promise<ArrayBuffer | string>;
}

export class RegistrationFontResolver extends DefaultFontResolver {
	private readonly _options: RegistrationFontResolverOptions;
	private readonly _fonts: { uri: string, fontFace: CssFontFace }[] = [];

	public constructor(
			private readonly _doc: PDFKit.PDFDocument,
			options?: RegistrationFontResolverOptions) {
		super();
		this._options = Object.assign({
			fetcher: DefaultXhrFetcher.fetch
		}, options);
	}

	public resolve(font: CssFontFace): string {
		let candidates = this._fonts;
		candidates = this._constrictByFamily(candidates, font.fontFamily);
		candidates = this._constrictByWeight(candidates, font.fontWeight);
		candidates = this._constrictByStyle(candidates, font.fontStyle);
		candidates = this._constrictByVariant(candidates, font.fontVariant);
		candidates = this._constrictByStretch(candidates, font.fontStretch);
		return candidates.length === 0 ? super.resolve(font) : candidates[0].uri;
	}

	public async register(uri: string, fontFace: CssFontFace): Promise<string> {
		this._fonts.push({ uri, fontFace });
		const data = await this._options.fetcher(uri, true) as ArrayBuffer;
		this._doc.registerFont(uri, data as any);
		return uri;
	}

	public async registerFromDocument(sandbox: SandboxedDocument): Promise<string[]> {
		const fontFaces = await DocumentQueries.getFontFaces(sandbox);
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		return Promise.all(fontFaces.map(font => this.register(font.uri, font.fontFace)));
	}

	private _constrictByFamily(fonts: { uri: string, fontFace: CssFontFace }[], families: string[]) {
		return fonts.filter(font => {
			for (const family of font.fontFace.fontFamily) {
				if (families.indexOf(family) !== -1) {
					return true;
				}
			}
			return false;
		});
	}

	private _constrictByWeight(fonts: { uri: string, fontFace: CssFontFace }[], weight: number) {
		const weights = fonts.map(font => font.fontFace.fontWeight);
		const targetWeight = this._getNearest(weights, weight, 400);
		return fonts.filter(font => font.fontFace.fontWeight === targetWeight);
	}

	private _constrictByStyle(fonts: { uri: string, fontFace: CssFontFace }[], style: CssFontStyle) {
		const styles = fonts.map(font => font.fontFace.fontStyle);
		const targetStyle = this._getNearest(styles, style, CssFontStyle.Normal);
		return fonts.filter(font => font.fontFace.fontStyle === targetStyle);
	}

	private _constrictByVariant(fonts: { uri: string, fontFace: CssFontFace }[], variant: CssFontVariant) {
		const variants = fonts.map(font => font.fontFace.fontVariant);
		const targetVariant = this._getNearest(variants, variant, CssFontVariant.Normal);
		return fonts.filter(font => font.fontFace.fontVariant === targetVariant);
	}

	private _constrictByStretch(fonts: { uri: string, fontFace: CssFontFace }[], stretch: CssFontStretch) {
		const stretches = fonts.map(font => font.fontFace.fontStretch);
		const targetStretch = this._getNearest(stretches, stretch, CssFontStretch.Normal);
		return fonts.filter(font => font.fontFace.fontStretch === targetStretch);
	}

	private _getNearest<T extends number>(candidates: T[], target: T, breakingPoint: T) {
		return candidates.length === 0 ? null : candidates.reduce((a, b) => {
			const diffA = Math.abs((a as number) - (target as number));
			const diffB = Math.abs((b as number) - (target as number));
			if (diffA === diffB) {
				if (target > breakingPoint) {
					return a > b ? a : b;
				}
				return a < b ? a : b;
			}
			return diffA < diffB ? a : b;
		});
	}
}
