import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnFormsModule} from 'kn-forms';
import {ComponentDeactivateGuard} from 'kn-modal';

import {KnAuditDisplayModule} from 'common-web/forms';
import {KnGridPanelModule, GRID_ACTIONS_TOKEN, provideDefaultGridActions} from 'common-web/grid';
import {KnGridPanelExportModule} from 'common-web/export';
import {KnDatagridModule} from 'kn-datagrid';

import {DatabaseEditService} from './database-edit.service';
import {DatabasesViewComponent} from './databases-view.component';
import {DatabaseEditComponent} from './database-edit.component';
import {DatabasesGridData} from './databases-grid-data.service';
import {DatabasesViewConfig} from './databases-view.config';

import {routing} from './databases-view.routes';
import {authorization} from './databases-view.permissions';

export class DatabasesViewOptions extends DatabasesViewConfig { }

const isLocalhost = ['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1;
export function provideDatabasesViewConfig(config: DatabasesViewOptions) {
	return Utils.object.defaults(config, {
		databaseName: 'database',
		defaultProvider: isLocalhost ? 'SQLite' : 'PostgreSQL',
		defaultDatabaseType: 'database',
		databaseTypes: [{ label: 'Database', value: 'database' }]
	});
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KnCommonModule.forChild(),
		KnComponentsModule,
		KnFormsModule.forChild(),
		KnDatagridModule,
		KnGridPanelModule,
		KnGridPanelExportModule,
		KnAuditDisplayModule,
		routing,
		authorization
	],
	declarations: [
		DatabasesViewComponent,
		DatabaseEditComponent
	],
	providers: [
		ComponentDeactivateGuard
	]
})
export class KnDatabasesViewModule {
	public static forChild(config?: Partial<DatabasesViewOptions>): ModuleWithProviders<KnDatabasesViewModule> {
		return {
			ngModule: KnDatabasesViewModule,
			providers: [
				{
					provide: DatabasesViewOptions, useValue: config ? config : {}
				}, {
					provide: DatabasesViewConfig,
					useFactory: provideDatabasesViewConfig,
					deps: [DatabasesViewOptions]
				}, {
					provide: GRID_ACTIONS_TOKEN,
					multi: true,
					useFactory: provideDefaultGridActions,
					deps: [I18nService]
				},
				DatabasesGridData,
				DatabaseEditService
			]
		};
	}
}
