import {NgModule} from '@angular/core';
import {KnAutofocus} from './autofocus.directive';
import {KnBackgroundFocusable} from './background-focusable.directive';
import {KnCollapse} from './collapse.directive';
import {KnDeepBlur} from './deep-blur.directive';
import {KnFileDrop} from './file-drop.directive';
import {KnRippleModule} from './ripple/ripple.module';

export const KN_DIRECTIVES = [
	KnAutofocus,
	KnBackgroundFocusable,
	KnCollapse,
	KnDeepBlur,
	KnFileDrop
];

@NgModule({
	imports: [KnRippleModule],
	declarations: [KN_DIRECTIVES],
	exports: [KnRippleModule, KN_DIRECTIVES]
})
export class KnDirectivesModule { }
