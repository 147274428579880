import {AbstractCloner, BlendModel, BlendSequence, Mirror} from 'common-web/rest';
import {ViewTemplate, PrintTemplate} from 'common-web/model';

export abstract class AbstractTemplatesClonerService<T extends ViewTemplate | PrintTemplate> extends AbstractCloner {
	public constructor(mirror: Mirror) {
		super([mirror]);
	}

	protected _blend(model: BlendModel): BlendSequence {
		return [
			{ key: this._mirrors[0].key, blender: this._templateBlender.bind(this, model) }
		];
	}

	private _templateBlender(model: BlendModel) {
		const { target, source } = this._partition<T>(model, this._mirrors[0].key);
		const createFunctor = (item: T) =>
			!target.some(x => x.view === item.view && x.name === item.name);
		return this._createDiff<T>({
			entitiesToCreate: source.filter(createFunctor)
		});
	}
}
