import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http} from 'kn-http';
import {Country} from './country.type';
import {CountriesConfig} from './countries.config';
import {COUNTRIES_HTTP_TOKEN} from './countries-http.token';

@Injectable()
export class CountriesStore {
	public constructor(
			@Inject(COUNTRIES_HTTP_TOKEN) private readonly _http: Http,
			private readonly _config: CountriesConfig) {
	}

	public query(): Observable<Country[]> {
		return this._http.get(this._config.countriesUrl)
			.pipe(Rx.map(next => next.body as Country[]));
	}
}
