import {Component, Input, Output, EventEmitter, ViewChild, Injector, OnInit, OnChanges, SimpleChange, ElementRef} from '@angular/core';
import {KnMenu} from 'kn-components';
import {AbstractViewTemplateSelector} from '../../../components/view-template-selector/abstract-view-template-selector.component';
import {ViewTemplateItem} from '../../../services/view-template-manager/types';
import {User} from 'common-web/model';
import {UsersResourceService} from '../../../services/users/users-resource.service';

@Component({
	selector: 'kn-preset-selector',
	templateUrl: 'preset-selector.html'
})
export class KnPresetSelector extends AbstractViewTemplateSelector implements OnInit, OnChanges {
	protected _usersResource: UsersResourceService;

	@Input() public queryParam: string;
	@Input() public view: string;
	@Input() public version: string;
	@Input() public template: any;
	@Output() public templateChange: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild(KnMenu, { static: true })
	public menu: KnMenu;

	@ViewChild('nameInput', { static: false })
	public nameInput: ElementRef;

	public users: User[] = [];

	public constructor(injector: Injector) {
		super(injector);
		this._usersResource = injector.get(UsersResourceService);
	}

	public ngOnInit() {
		super.ngOnInit();

		const subscriptions = [
			this._usersResource.query({ query: { only: ['uid', 'fullName'], sort: 'fullName' } }).subscribe(next => this.users = next),
			this.menu.expandedChange.subscribe((next: boolean) => {
				if (!this.isSaveMode && this.preambules.length === 0) {
					this.menu.collapse();
				}
				if (!next) {
					this.isSaveMode = false;
				}
			})
		];
		subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
	}

	public ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if ('template' in changes) {
			this._updateModified();
		}
	}

	protected _setSelected(template: ViewTemplateItem<any>) {
		super._setSelected(template);
		this.templateChange.emit(template && template.value);
	}

	public enterSaveMode(event: Event) {
		if (this.canSave(this.selected && this.selected.userUid)) {
			this.saveUserUid = this.selected && this.selected.userUid;
		}
		else {
			this.saveUserUid = this.myUid;
		}
		this.isSaveMode = true;
		this.menu.expand();
		event.stopPropagation();
		setTimeout(() => this.nameInput.nativeElement.select());
	}

	public deselectTemplate(event: Event) {
		super.cancel();
		event.stopPropagation();
	}

	public select(id: number) {
		super.select(id);
		this.menu.collapse();
	}

	public remove(id: number) {
		super.remove(id);
		this.menu.collapse();
	}

	public save(idOrName: { id?: number, name?: string, userUid?: string }) {
		if (!idOrName.name || idOrName.name.length < 3) {
			this._toast.show(this._i18n.t('Minimum length of the template name is {{ length }} characters.', { length: 3 }));
		}
		else if (idOrName.name && idOrName.name.length > 40) {
			this._toast.show(this._i18n.t('Maximum length of the template name is {{ length }} characters.', { length: 40 }));
		}
		else {
			super.save(idOrName);
		}
	}
}
