import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter,
	HostBinding, Injector, Input, OnDestroy, Output, Provider, ViewChild, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {saveAs} from 'file-saver';
import {BooleanField} from 'kn-common';
import {Response} from 'kn-http';
import {Resource, RestService} from 'kn-rest';
import {Utils} from 'kn-utils';
import {Observable, of as observableOf} from 'rxjs';
import {FILE_STORAGE_INPUT_RESOURCE} from './file-storage-input.module';
import * as CommonWebModel from 'common-web/model';
import * as Rx from 'rxjs/operators';

export const KN_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnFileStorageInputComponent), // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
	multi: true
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-file-storage-input',
	templateUrl: 'file-storage-input.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(click)' : '!focused && focus()'
	},
	providers: [
		KN_INPUT_CONTROL_VALUE_ACCESSOR
	]
})
export class KnFileStorageInputComponent implements OnDestroy, ControlValueAccessor {
	private readonly _disposables: Function[] = [];
	private readonly _resource: Resource<CommonWebModel.FileStorage>;
	private _value: number = null;
	private _files: FileList = null;

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	public get inputId(): string {
		return `${this.id}-input`;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-file-storage-input-${nextUniqueId++}`;

	@Input() @BooleanField() public autofocus: boolean = false;
	@Input() public placeholder: string = null;
	@Input() @BooleanField() public required: boolean = false;
	@Input() public tabindex: number = null;
	@Input() public name: string = null;
	@Input() public accept: string = null;
	public get multiple() {
		return false;
	}
	@Input() @BooleanField() public override: boolean = false;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<number>();

	@ViewChild('openButton', { static: true })
	public _buttonElement: ElementRef;

	public file: CommonWebModel.FileStorage = null;

	/* This should be FileStorage id */
	public get value(): number {
		return this._value;
	}

	@Input() public set value(v: number) {
		if (v !== this._value) {
			this._value = v;
			this._onChangeCallback(v);
			this._reloadFile();
		}
	}

	public constructor(
			rest: RestService,
			injector: Injector,
			protected _cdr: ChangeDetectorRef) {
		/* Injecting it directly in constructor parameters does not work */
		this._resource = injector.get(FILE_STORAGE_INPUT_RESOURCE);
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public focus() {
		this._buttonElement.nativeElement.focus();
	}

	public select() {
		this._buttonElement.nativeElement.select();
	}

	/** @internal */
	public handleChange(event: Event) {
		this._files = (event.target as HTMLInputElement).files;
		if (!this.override) {
			this._value = null;
		}
		this.file = null;
		if (this._files.length !== 1) {
			this._files = null;
		}
		else {
			this.file = {
				name: Utils.text.latinise(this._files[0].name),
				data: this._files[0] as Blob
			} as CommonWebModel.FileStorage;
		}
		this.valueChange.emit(this._value);
		this.changeEvent.emit();
		this._onTouchedCallback();
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._value = value;
		this.changeEvent.emit();
		this._reloadFile();
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this._cdr.markForCheck();
	}

	private _reloadFile() {
		if (!this._value) {
			this.file = null;
			this._cdr.markForCheck();
			return;
		}
		const subscription = this._resource.head(this._value).subscribe(response => {
			const contentDisposition = response.headers.get('Content-Disposition');
			this.file = {
				id: this._value,
				name: contentDisposition.match(/^attachment; filename="(.+?)"$/)[1]
			} as CommonWebModel.FileStorage;
			this._cdr.markForCheck();
		});
		this._disposables.push(() => subscription.unsubscribe());
	}

	public retrieveFile() {
		if (this.file == null) {
			return;
		}
		const subscription = this._resource.get(this._value).subscribe(file => {
			saveAs(file.data, file.name);
		});
		this._disposables.push(() => subscription.unsubscribe());
	}

	public save(): Observable<Response> {
		if (this.file == null) {
			return observableOf(null);
		}
		if (this.file.id) {
			return observableOf(new Response('not-modified', null, { status: 304 }, null));
		}
		this.file.id = this._value;
		return this._resource.save(this.file).pipe(Rx.tap(() => {
			this.value = this.file.id;
		}));
	}

	public delete(): Observable<Response> {
		if (!this._value) {
			return observableOf(null);
		}
		return this._resource.remove(this._value).pipe(Rx.tap(() => {
			this.value = null;
		}));
	}
}
