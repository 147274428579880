import {Injectable, Injector} from '@angular/core';
import * as Rx from 'rxjs/operators';
import {SelectionMode, RenderMode, Sorting, Column} from 'kn-datagrid';
import {AbstractGridData, DataDescription, DataModel, KnInGridActions, defaultInGridActions} from 'common-web/grid';
import * as CommonModel from 'common-web/model';
import {UsersResourceService} from '../../services/users/users-resource.service';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';

@Injectable()
export class ViewTemplatesGridData extends AbstractGridData<CommonModel.ViewTemplate> {
	private _users: CommonModel.User[];

	public constructor(
		injector: Injector,
		private readonly _usersResource: UsersResourceService,
		private readonly _viewsResource: ViewTemplatesResourceService) {
		super(injector);

		const users$ = _usersResource.query({
			query: { only: ['uid', 'fullName'], sort: 'fullName' }
		});
		this._registerResolve(users$.pipe(Rx.tap(next => this._users = next)));
	}

	protected _createDescription(): DataDescription<CommonModel.ViewTemplate> {
		return {
			gridRenderMode: RenderMode.Static,
			gridSelectionMode: SelectionMode.Multiple,
			gridMutalSorting: false,
			gridRowsReordering: true,
			gridColumnsReordering: true,
			rows: {
				gridSelectable: true,
				gridClasses: this._getOrEvaluateRowClasses.bind(this)
			},
			columns: [
				{
					id: '@selector'
				}, {
					id: 'id',
					label: this._i18n.t('ID'),
					gridSortable: false,
					filterType: 'number'
				}, {
					id: 'uid',
					label: this._i18n.t('Link'),
					gridSortable: false,
					filterType: 'string'
				}, {
					id: 'name',
					label: this._i18n.t('Name'),
					gridClasses: ['strong'],
					filterType: 'string'
				}, {
					id: 'version',
					label: this._i18n.t('Version'),
					filterType: 'string'
				}, {
					id: 'view',
					label: this._i18n.t('View'),
					filterType: 'identifier',
					filterOptions: this.optionsFromResource(this._viewsResource, x => this._i18n.t(x.view), 'view', { query: { only: 'view', distinct: 'view' }}, false),
					gridAccessor: x => this._i18n.t(x.view),
					gridGroupable: true
				}, {
					id: 'userUid',
					label: this._i18n.t('Owner'),
					filterType: 'identifier',
					filterOptions: this.optionsFromResource(this._usersResource, 'fullName', 'uid', { query: { sort: 'fullName' }}),
					gridAccessor: this._userNameAccessor.bind(this),
					gridClasses: x => x && x.userUid == null ? 'italic' : '',
					gridGroupable: true
				}, {
					id: 'hidden',
					label: this._i18n.t('Hidden'),
					gridClasses: ['center'],
					gridRenderer: this.renderers.bool(),
					filterType: 'bool',
					gridGroupable: true
				}, {
					id: 'lastModified',
					label: this._i18n.t('Last modified'),
					gridGroupable: false,
					gridRenderer: this.renderers.date('dMyjjmm'),
					filterType: 'date',
					filterOptions: this._queryExpander.buildDateOptions('1d', '7d', '1m', '3m', '1y')
				}, {
					id: 'modifiedBy',
					label: this._i18n.t('Modified by'),
					gridGroupable: true,
					filterType: 'string',
					gridAccessor: this._userNameAccessor.bind(this)
				}, {
					id: 'serverSyncTime',
					label: this._i18n.t('Server sync time'),
					gridGroupable: false,
					gridRenderer: this.renderers.date('dMyjjmm'),
					filterType: 'date',
					filterOptions: this._queryExpander.buildDateOptions('1d', '7d', '1m', '3m', '1y')
				}, {
					id: 'syncId',
					label: this._i18n.t('Sync GUID'),
					gridGroupable: true,
					filterType: 'string'
				}, {
					id: '@tools',
					name: this._i18n.t('Tools'),
					gridLabel: '',
					gridResizable: false,
					gridSortable: false,
					gridClasses: ['center tools'],
					gridRenderer: this.renderers.component(KnInGridActions, KnInGridActions.mapping(this.context, defaultInGridActions(this._i18n))),
					gridExportable: false
				}
			],
			sections: []
		};
	}

	protected _createModel(): DataModel {
		return {
			columns: [
				{
					id: '@selector',
					gridWidth: 50,
					gridVisible: true
				}, {
					id: '@tools',
					gridWidth: 95,
					gridVisible: true
				}, {
					id: 'view',
					gridWidth: 200,
					gridSort: Sorting.Ascending,
					gridVisible: true
				}, {
					id: 'name',
					gridSort: Sorting.Ascending,
					gridVisible: true
				}, {
					id: 'userUid',
					gridWidth: 250,
					gridVisible: true
				}, {
					id: 'hidden',
					gridWidth: 80,
					gridVisible: false
				}, {
					id: 'lastModified',
					gridWidth: 200,
					gridVisible: true
				}, {
					id: 'modifiedBy',
					gridWidth: 200,
					gridVisible: true
				}, {
					id: 'version',
					gridVisible: false
				}, {
					id: 'uid',
					gridVisible: false
				}, {
					id: 'id',
					gridVisible: false
				}, {
					id: 'serverSyncTime',
					gridVisible: false
				}, {
					id: 'syncId',
					gridVisible: false
				}
			]
		};
	}

	private _userNameAccessor(item: CommonModel.ViewTemplate, column: Column<CommonModel.ViewTemplate>) {
		const user = item.hasOwnProperty(column.id) && this._users.find(x => x.uid === item[column.id]);
		return user ? user.fullName : (item[column.id] == null ? this._i18n.t('global') : item[column.id]);
	}
}
