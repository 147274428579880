export function normalizePath(path: string | string[]): string[] {
	path = path || [];
	const normalizedPath = Array.isArray(path) ? path : path.split('/');
	if (normalizedPath[0] === '') {
		normalizedPath[0] = '/';
	}
	return normalizedPath;
}

export function splitPath(path: string[]): [string[], string] {
	if (['.', '..', '/'].indexOf(path[path.length - 1]) !== -1) {
		return [path, null];
	}
	return [path.slice(0, path.length - 1), path[path.length - 1]];
}
