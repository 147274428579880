import {Observable} from 'rxjs';
import {PrintConfiguration} from '../types';

export interface AbstractPrintBackendOptions { }

export abstract class AbstractPrintBackend {
	public abstract get name(): string;
	public abstract print(html: string, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any>;
	public abstract printPreview(html: string, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any>;
}
