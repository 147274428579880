<kn-draggable-handler class="top" *ngIf="topHandler"
	[positionY]="-height" (positionYChange)="setHeight(-$event)"
	[minY]="-maxHeight" [maxY]="-minHeight"></kn-draggable-handler>
<kn-draggable-handler class="left" *ngIf="leftHandler"
	[positionX]="-width" (positionXChange)="setWidth(-$event)"
	[minX]="-maxWidth" [maxX]="-minWidth"></kn-draggable-handler>
<div class="content-box" [style.width.px]="width" [style.height.px]="height">
	<ng-content></ng-content>
</div>
<kn-draggable-handler class="right" *ngIf="rightHandler"
	[positionX]="width" (positionXChange)="setWidth($event)"
	[minX]="minWidth" [maxX]="maxWidth"></kn-draggable-handler>
<kn-draggable-handler class="bottom" *ngIf="bottomHandler"
	[positionY]="height" (positionYChange)="setHeight($event)"
	[minY]="minHeight" [maxY]="maxHeight"></kn-draggable-handler>
