import {DatagridBond} from 'kn-datagrid';
import {ActionCommand} from '../../../abstract-grid-view.component';

export type GridActionCallable<T, U> = (bond: DatagridBond<T>, ...params: any[]) => U;

export class GridAction<T> {
	public title: string;
	public order: number;
	public icon: string;
	public permission: string;
	public visible: GridActionCallable<T, boolean>;
	public command: GridActionCallable<T, ActionCommand<T>>;
}
