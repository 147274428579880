import {Component, ViewEncapsulation, HostBinding, Input, Output, OnChanges, SimpleChanges, EventEmitter, ViewChild, ChangeDetectorRef, ElementRef, AfterViewInit} from '@angular/core';
import {DateRangeUnitType, MsTimeConsts} from './types';
import {BooleanField} from 'kn-common';
import {Utils} from 'kn-utils';
import {DatepickerType} from '../datepicker/datepicker.component';
import {KnDateInput} from '../dateinput/dateinput.component';

export type DateRangeValue = {
	beginDate: string;
	beginIsNow: boolean;
	endDate: string;
	endIsNow: boolean;
	relativeDiff: number;
};

export type DateRangeType =
	'fromDate' |
	'toDate' |
	'fixRange';

export const DateRangeType = {
	FromDate: 'fromDate' as DateRangeType,
	ToDate: 'toDate' as DateRangeType,
	FixRange: 'fixRange' as DateRangeType
};

@Component({
	selector: 'kn-date-range',
	templateUrl: 'daterange.html',
	styleUrls: ['daterange.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnDateRange implements OnChanges, AfterViewInit {
	@Input() public timeBackSuggestions: string[];
	@Input() public type: DateRangeType;
	@Input() @BooleanField() public nowAllowed: boolean = false;
	@Input() public inputType: DatepickerType;
	@Input() public relativeDiffType: DateRangeUnitType;
	@Input() public range: DateRangeValue;
	@Input() @BooleanField() public required: boolean = false;
	@Input() @BooleanField() public nowLockFrom: boolean = false;
	@Input() @BooleanField() public nowLockTo: boolean = false;
	@Input() @BooleanField() public hideFromNow: boolean = false;
	@Input() @BooleanField() public hideToNow: boolean = false;
	@Output() public rangeChange = new EventEmitter<DateRangeValue>();
	@Input() @BooleanField() public hideEmptyLabel: boolean = false;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@ViewChild('fromInput', { static: false })
	public _fromInput: KnDateInput;
	@ViewChild('toInput', { static: false })
	public _toInput: KnDateInput;

	@ViewChild('knLabelContent', { static: true })
	private readonly _knLabel: ElementRef;

	public hasKnLabel: boolean = true;

	public relativeDiff: number;
	public beginDate: string = KnDateInput.EmptyValue;
	public endDate: string = KnDateInput.EmptyValue;

	public constructor(private readonly _cdr: ChangeDetectorRef) {
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('range' in changes) {
			if (this.range == null) {
				this.beginDate = KnDateInput.EmptyValue;
				this.endDate = KnDateInput.EmptyValue;
				this.relativeDiff = 0;
			}
			else {
				this.beginDate = this.range.beginIsNow ? KnDateInput.NowValue : this.range.beginDate;
				this.endDate = this.range.endIsNow ? KnDateInput.NowValue : this.range.endDate;
				this.relativeDiff = KnDateRange.getTimeFromMs(this.range.relativeDiff, this.relativeDiffType);
			}
		}
	}

	public ngAfterViewInit() {
		this.hasKnLabel = !this.hideEmptyLabel || this._knLabel && this._knLabel.nativeElement && this._knLabel.nativeElement.children.length > (this.required ? 1 : 0);
		this._cdr.detectChanges();
	}

	// public get nowLockFrom(): boolean {
	// 	return this._fromInput != null ? this._fromInput.nowDateLock : false;
	// }

	// public get nowLockTo(): boolean {
	// 	return this._toInput != null ? this._toInput.nowDateLock : false;
	// }

	// @Input() public set nowLockFrom(value: boolean) {
	// 	if (this._fromInput != null && this._fromInput.nowDateLock != value) {
	// 		this._fromInput.nowDateLock = value;
	// 	}
	// }

	// @Input() public set nowLockTo(value: boolean) {
	// 	if (this._toInput != null && this._toInput.nowDateLock != value) {
	// 		this._toInput.nowDateLock = value;
	// 	}
	// }

	public handleTimeBackChange(value: number) {
		this.range.relativeDiff = KnDateRange.getMsFromTime(value, this.relativeDiffType);
		this.rangeChange.emit({
			beginDate: Utils.clone(this.range.beginDate),
			endDate: Utils.clone(this.range.endDate),
			relativeDiff: this.range.relativeDiff,
			beginIsNow: this._fromInput.nowDateLock,
			endIsNow: this._toInput.nowDateLock
		});
	}

	public handleEndDateChange(value: string) {
		this.range.endDate = value;
		// if (this._fromInput && !this._fromInput.nowDateLock && this.range.beginDate != null && this.range.endDate < this.range.beginDate) {
		// 	this.range.beginDate = Utils.clone(this.range.endDate);
		// }
		this.rangeChange.emit({
			beginDate: Utils.clone(this.range.beginDate),
			endDate: Utils.clone(this.range.endDate),
			relativeDiff: this.range.relativeDiff,
			beginIsNow: this._fromInput.nowDateLock,
			endIsNow: this._toInput.nowDateLock
		});
	}

	public handleBeginDateChange(value: string) {
		this.range.beginDate = value;
		// if (this._toInput && !this._toInput.nowDateLock && this.range.endDate != null && this.range.beginDate > this.range.endDate) {
		// 	this.range.endDate = Utils.clone(this.range.beginDate);
		// }
		this.rangeChange.emit({
			beginDate: Utils.clone(this.range.beginDate),
			endDate: Utils.clone(this.range.endDate),
			relativeDiff: this.range.relativeDiff,
			beginIsNow: this._fromInput.nowDateLock,
			endIsNow: this._toInput.nowDateLock
		});
	}

	public static getMsFromTime(value: number, type: DateRangeUnitType): number {
		return value * this.getMs(type);
	}

	public static getTimeFromMs(value: number, type: DateRangeUnitType): number {
		return value / this.getMs(type);
	}

	public static getMs(type: DateRangeUnitType): number {
		switch (type) {
			case DateRangeUnitType.Seconds:
				return MsTimeConsts.Years;
			case DateRangeUnitType.Minutes:
				return MsTimeConsts.Minutes;
			case DateRangeUnitType.Hours:
				return MsTimeConsts.Hours;
			case DateRangeUnitType.Days:
				return MsTimeConsts.Days;
			case DateRangeUnitType.Weeks:
				return MsTimeConsts.Weeks;
			case DateRangeUnitType.Months:
				return MsTimeConsts.Months;
			case DateRangeUnitType.Years:
				return MsTimeConsts.Years;
		}
		return 1;
	}
}
