import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {RolesConfig} from './roles.config';
import {RolesResourceService} from './roles-resource.service';
import {PermissionsResourceService} from './permissions-resource.service';
import {PermissionActionsResourceService} from './permission-actions-resource.service';

export class RolesOptions extends RolesConfig { }

export const ROLES_FORROOT_GUARD = new InjectionToken<void>('ROLES_FORROOT_GUARD');

export function provideRolesConfig(config: RolesOptions) {
	return Utils.object.defaults(config, {
		rolesTable: 'Roles',
		permissionsTable: 'PermissionInfos',
		permissionActionsTable: 'ActionInfos'
	});
}

@NgModule({})
export class KnRolesModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(ROLES_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<RolesOptions>): ModuleWithProviders<KnRolesModule> {
		return {
			ngModule: KnRolesModule,
			providers: [
				...forRootGuardFactory('KnRolesModule', ROLES_FORROOT_GUARD, RolesResourceService),
				{
					provide: RolesOptions, useValue: config ? config : {}
				}, {
					provide: RolesConfig,
					useFactory: provideRolesConfig,
					deps: [RolesOptions]
				},
				RolesResourceService,
				PermissionsResourceService,
				PermissionActionsResourceService
			]
		};
	}
}
