import {Injector, ElementRef} from '@angular/core';
import {ElementRenderer, ElementRendererFactory, CellValue, CellContext} from 'kn-datagrid';

export class TitleDecorationRendererFactory extends ElementRendererFactory {
	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, titles: { [key: string]: any }): ElementRenderer<T> {
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			let html = value;
			if (titles.hasOwnProperty(value)) {
				html = `<span title="${titles[value]}">${value}</span>`;
			}
			return html;
		};
	}
}
