import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {PrintTemplatesConfig} from './print-templates.config';
import {FileInfo, File} from 'common-web/rest';

@Injectable()
export class PrintAssetsResourceService extends Resource<FileInfo[] | File> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: PrintTemplatesConfig) {
		super(rest, notifier, config.printAssetsTable);
	}
}
