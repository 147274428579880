import {Component, Input, Output, OnInit, OnDestroy, HostListener, EventEmitter, ElementRef} from '@angular/core';
import {Subscription, timer as observableTimer} from 'rxjs';

export interface MinuteDescriptor {
	position: {
		x: number;
		y: number;
	};
	major?: boolean;
	name: string;
	value: number;
}

@Component({
	selector: 'kn-hour-view',
	templateUrl: 'hour-view.html'
})
export class KnHourView implements OnInit, OnDestroy {
	private _subscription: Subscription;

	@Input() public minute: number;
	@Output() public minuteChange = new EventEmitter<number>();
	@Output() public accept = new EventEmitter<number>();

	public hourMinutes = {
		labels: [] as MinuteDescriptor[],
		ticks: [] as MinuteDescriptor[]
	};
	public nowMinute: number;

	@HostListener('wheel', ['$event'])
	public wheelHandler(event: WheelEvent) {
		if (this.minute == null) {
			this.minute = 0;
		}
		if (event.deltaY > 0) {
			this.changeMinute(this.minute + (this.minute >= 59 ? -59 : 1));
		}
		else if (event.deltaY < 0) {
			this.changeMinute(this.minute + (this.minute <= 0 ? 59 : -1));
		}
		this._element.nativeElement.focus();
		event.preventDefault();
	}

	@HostListener('keydown.enter', ['$event'])
	public enterHandler(event: KeyboardEvent) {
		if (this.minute != null) {
			this.accept.emit(this.minute);
		}
		event.preventDefault();
	}

	public constructor(private readonly _element: ElementRef) { }

	public ngOnInit() {
		this._update();
		this._subscription = observableTimer(0, 1000)
			.subscribe(() => this.nowMinute = (new Date()).getMinutes());
	}

	public ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	public changeMinute(value: number) {
		this.minute = value;
		this.minuteChange.emit(this.minute);
	}

	public selectMinute(value: number) {
		this.changeMinute(value);
		this.accept.emit(this.minute);
	}

	private _update() {
		this.hourMinutes.labels = [];
		this.hourMinutes.ticks = [];
		for (let i = 0; i < 60; i++) {
			const major = i % 5 === 0;
			if (major) {
				this.hourMinutes.labels.push({
					position: {
						x: 50 + 32 * Math.sin(Math.PI * i / 30),
						y: 50 - 32 * Math.cos(Math.PI * i / 30)
					},
					name: i + '',
					value: i
				});
			}
			this.hourMinutes.ticks.push({
				position: {
					x: 50 + 50 * Math.sin(Math.PI * i / 30),
					y: 50 - 50 * Math.cos(Math.PI * i / 30)
				},
				major: major,
				name: i + '',
				value: i
			});
		}
	}
}
