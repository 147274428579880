import {AbstractImageResolver} from './abstract-image-resolver';

export class DefaultImageResolver extends AbstractImageResolver {
	public resolve(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement): ArrayBuffer | string {
		if (image instanceof HTMLImageElement) {
			return image.src;
		}
		if (image instanceof HTMLCanvasElement) {
			return image.toDataURL('image/png');
		}
		throw new Error('Image cannot be resolved: unsupported type.');
	}
}
