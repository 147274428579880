import {Injectable, Inject, Optional, Injector, NgModule} from '@angular/core';
import {AbstractModuleLoader} from 'kn-common';
import {Utils} from 'kn-utils';
import {PrintContext, PrintConfiguration, PrintRendererStatement, PrintRendererFilter, PrintRendererHelper, PrintRendererTemplate} from './types';
import {PRINT_STATEMENT_TOKEN} from './print-statement.token';
import {PrintStatementMetadata} from './metadata/print-statement.decorator';
import * as Sqrl from 'squirrelly';

@Injectable()
export class HtmlRenderer {
	private _templateEngine: (template: string, data: object, env?: object, cb?: any) => any;
	private readonly _defaults: { [key: string]: any };
	private readonly _overrides: { [key: string]: any };

	public constructor(@Optional() @Inject(PRINT_STATEMENT_TOKEN) statements: PrintRendererStatement[]) {
		this._defaults = {
			autoTrim: [false, false],
			useWith: true,
			defaultFilter: 'empty'
		};
		this._overrides = {
			storage: Utils.clone(Sqrl.defaultConfig.storage, true),
			async: true,
			cache: false
		};
		this._registerStatements(this._overrides.storage, statements || []);
	}

	public async render(template: string, context: PrintContext, config?: PrintConfiguration): Promise<string> {
		return Sqrl.render(
			template,
			context,
			{ ...this._defaults, ...config.renderer, ...this._overrides }
		);
	}

	private _registerStatements(storage: any, statements: PrintRendererStatement[]) {
		for (const statement of statements) {
			const key = Utils.reflector.annotations(statement.constructor)
				.find(x => x instanceof PrintStatementMetadata)?.key;
			if (key == null) {
				continue;
			}
			if (Utils.reflector.hasHook('transform', statement)) {
				const filter = statement as PrintRendererFilter;
				storage.filters.define(key, filter.transform.bind(filter));
			}
			if (Utils.reflector.hasHook('exec', statement)) {
				const helper = statement as PrintRendererHelper;
				storage.helpers.define(key, helper.exec.bind(helper));
			}
			if (Utils.reflector.hasHook('render', statement)) {
				const template = statement as PrintRendererTemplate;
				storage.templates.define(key, template.render.bind(template));
			}
		}
	}
}
