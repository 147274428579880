import {Observable} from 'rxjs';
import {Policy} from './authorization/policy';
import {Authentication} from './authentication/authentication.type';
import {Authorization} from './authorization/authorization.type';
import {AccessRestriction} from './access-control/abstract-access-control';

export class UserService implements Authentication, Authorization {
	private readonly _scope: string[] = [];

	public constructor(
			private readonly _authentication: Authentication,
			private readonly _authorization: Authorization,
			protected _parent: UserService = null) {
		const policy = this._authorization.getPolicy();
		this._scope = policy.scope;
		if (policy.resource) {
			this._scope.push(policy.resource);
		}
	}

	public get authentication(): Authentication {
		return this._authentication;
	}

	public get authorization(): Authorization {
		return this._authorization;
	}

	public isAuthenticated() {
		return this._authentication.isAuthenticated();
	}

	public getUser<T extends any>() {
		return this._authentication.getUser<T>();
	}

	public signUp(creditials: any): Observable<void> {
		return this._authentication.signUp(creditials);
	}

	public signIn(creditials: any): Observable<void> {
		return this._authentication.signIn(creditials);
	}

	public signOut() {
		return this._authentication.signOut();
	}

	public get isRoot() {
		return this._parent == null;
	}

	public root() {
		if (this.isRoot) {
			return this;
		}
		let parent = this._parent;
		while (!parent.isRoot) {
			parent = parent._parent;
		}
		return parent;
	}

	public fork(scope: string | string[]): UserService {
		const authorization = this._authorization.fork(scope);
		return new UserService(this._authentication, authorization, this);
	}

	public getPolicy(resource?: string | string[]): Policy {
		return this._authorization.getPolicy(resource);
	}

	public can(action: string, resource?: string | string[], restrictions?: AccessRestriction): boolean {
		return this._authorization.can(action, resource, restrictions);
	}

	public authorize(action: string, resource?: string | string[], restrictions?: AccessRestriction): void {
		this._authorization.authorize(action, resource, restrictions);
	}

	public get scope(): string[] {
		return this._scope;
	}
}
