<div class="picker-relative-container" [class.format-12-hours]="hoursInPeriod === 12">
	<ul>
		<li *ngFor="let dayHour of dayHours"
			[style.left.%]="dayHour.position.x"
			[style.top.%]="dayHour.position.y">
			<a href="javascript:void(0)"
				[class.now]="dayHour.value + hourOffset === nowHour"
				[class.selected]="dayHour.value + hourOffset === hour"
				(click)="selectHour(dayHour.value + hourOffset)">{{ dayHour.name }}</a>
		</li>
	</ul>
	<div class="center"></div>
	<div class="gauge"
		*ngIf="hour != null"
		[style.width.%]="hour < 12 && hoursInPeriod !== 12 ? 32 : 50"
		[style.transform]="'rotate(' + (30 * hour - 90) + 'deg)'"></div>
</div>
<div class="period-selector" *ngIf="hoursInPeriod === 12">
	<a href="javascript:void(0)" class="am"
		[class.now]="nowHour < 12"
		[class.selected]="hour < 12"
		(click)="selectPeriod(0)">AM</a>
	<a href="javascript:void(0)" class="pm"
		[class.now]="nowHour >= 12"
		[class.selected]="hour >= 12"
		(click)="selectPeriod(1)">PM</a>
</div>
