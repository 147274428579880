import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {UriContext} from 'kn-http';
import {AbstractResource} from 'kn-rest';
import {EntityStore} from './entity-store';
import {IndexerRetriver, QueryContext} from './types';
import * as Model from 'common-web/model';

export class EntitiesStore<T extends Model.EntityBase> extends EntityStore<T> {
	public constructor(
			protected _resource: AbstractResource<T>,
			indexerRetriver: IndexerRetriver<T> | string,
			context?: UriContext) {
		super(_resource, indexerRetriver, context);
	}

	public getByQuery(query: QueryContext<string>): Observable<T[]> {
		const queryKey = Object.keys(query)[0];
		const context = Object.assign({ [queryKey]: {} as any }, this._context);
		Object.assign(context[queryKey], query[queryKey]);
		return this._query(context).pipe(
			Rx.map(next => {
				if (next == null) {
					throw Error('Cannot select items by UID.');
				}
				return next;
			})
		);
	}
}
