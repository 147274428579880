import {AbstractOptionComparer} from './abstract-option-comparer';

export class NullOptionComparer<T> extends AbstractOptionComparer<T> {
	public constructor(accessor?: (item: T) => string) {
		super(accessor);
	}

	protected _compare(value: string, query: string, strict: boolean): boolean {
		return true;
	}
}
