import {Directive, Input, Output, EventEmitter, HostListener, HostBinding} from '@angular/core';
import {RowItem, Description} from '../types';
import {Node} from '../model/node';
import {ExpansionService} from '../services/expansion.service';
import {SelectionService} from '../services/selection.service';
import {KnTableBody} from '../parts/table-body.component';
import {ChangeNotifier} from '../services/change-notifier.service';
import {ReorderRowData} from './reordable-rows-container.directive';

@Directive({
	selector: '[knKeysControl]'
})
export class KnKeysControl {
	@Input() public description: Description<RowItem>;
	@Input() public node: Node<RowItem>;
	@Output() public rowActivate = new EventEmitter<RowItem>();
	@Output() public reorderRows = new EventEmitter<ReorderRowData>();

	public constructor(
			private readonly _changeNotifier: ChangeNotifier,
			private readonly _tableBody: KnTableBody,
			private readonly _expansion: ExpansionService,
			private readonly _selection: SelectionService) { }

	@HostBinding('tabindex')
	public tabindex = 0;

	@HostListener('keydown', ['$event'])
	public keydownHandler(event: KeyboardEvent) {
		switch (event.key || (event as any).code) {
			case 'ArrowUp':
				event.ctrlKey ? this._reorderMarked(-1) : this._moveMarkUp();
				break;
			case 'ArrowDown':
				event.ctrlKey ? this._reorderMarked(1) : this._moveMarkDown();
				break;
			case 'ArrowLeft':
				this._setExpandMarked(false);
				break;
			case 'ArrowRight':
				this._setExpandMarked(true);
				break;
			case 'Space':
			case ' ':
				this._toggleSelectMarked();
				break;
			case 'Enter':
				this._activateMarked();
				break;
			default:
				return;
		}
		event.preventDefault();
	}

	private _moveMarkUp() {
		const rows = this._tableBody.rows.toArray();
		const index = rows.findIndex(x => x.marked);
		if (index === -1) {
			rows[rows.length - 1].marked = true;
			rows[rows.length - 1].scrollIntoView();
		}
		else if (index > 0) {
			rows[index].marked = false;
			rows[index - 1].marked = true;
			rows[index - 1].scrollIntoView();
		}
	}

	private _moveMarkDown() {
		const rows = this._tableBody.rows.toArray();
		const index = rows.findIndex(x => x.marked);
		if (index === -1) {
			rows[0].marked = true;
			rows[0].scrollIntoView();
		}
		else if (index < rows.length - 1) {
			rows[index].marked = false;
			rows[index + 1].marked = true;
			rows[index + 1].scrollIntoView();
		}
	}

	private _setExpandMarked(expanded: boolean) {
		const rows = this._tableBody.rows.toArray();
		const row = rows.find(x => x.marked);
		if (row != null) {
			this._expansion.setExpand(row.node, expanded);
		}
	}

	private _toggleSelectMarked() {
		const row = this._tableBody.rows.toArray().find(x => x.marked);
		if (row != null) {
			this._selection.toggleSelect(row.node, this.description);
			this._changeNotifier.emit();
		}
	}

	private _activateMarked() {
		const row = this._tableBody.rows.toArray().find(x => x.marked);
		if (row != null) {
			this.rowActivate.emit(row.node.item);
		}
	}

	private _reorderMarked(offset: number) {
		if (!this.description.rowsReordering) {
			return;
		}

		const rows = this._tableBody.rows.toArray();
		const row = rows.find(x => x.marked);
		if (row == null) {
			return;
		}

		let subjects = [row];
		const siblings = row.node.parent.children;
		const validTargets = rows.filter(x => siblings.indexOf(x.node) !== -1);

		if (this._selection.isSelected(row.node)) {
			subjects = validTargets.filter(x => this._selection.isSelected(x.node));
		}

		if (validTargets.indexOf(subjects[0]) + offset < 0
				|| validTargets.indexOf(subjects[subjects.length - 1]) + offset >= siblings.length) {
			return;
		}

		this.reorderRows.emit({
			subjects: subjects.map(x => x.node.item),
			offset: offset,
			modified: true,
			done: true
		});
	}
}
