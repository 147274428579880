import {Injector, Directive, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {Response} from 'kn-http';
import {ToastService, ConfirmationService} from 'kn-modal';
import {User} from 'common-web/model';
import {Formatters} from 'common-web/rest';
import {UserService} from 'kn-user';
import {ViewTemplateItem, ViewTemplatePreambule} from '../../services/view-template-manager/types';
import {ViewTemplateManagerService} from '../../services/view-template-manager/view-template-manager.service';
import {ViewTemplateProjection} from '../../services/view-template-manager/view-template-projection';

@Directive()
export abstract class AbstractViewTemplateSelector implements OnInit, OnDestroy {
	private readonly _slugifier = Formatters.slugify(128, true);
	private _loadSubscription: Subscription;
	protected _disposables: Function[] = [];
	protected _projection: ViewTemplateProjection<any>;

	protected _i18n: I18nService;
	protected _route: ActivatedRoute;
	protected _router: Router;
	protected _toast: ToastService;
	protected _confirmation: ConfirmationService;
	protected _manager: ViewTemplateManagerService;
	protected _userService: UserService;

	public preambules: ViewTemplatePreambule[] = [];
	public selected: ViewTemplateItem<any>;
	public modified: boolean;
	public isSaveMode: boolean = false;
	public saveUserUid: string;

	public abstract get queryParam(): string;
	public abstract get view(): string;
	public abstract get version(): string;
	public abstract get template(): any;

	public get visiblePreambules(): ViewTemplatePreambule[] {
		return this.preambules.filter(next => !next.hidden);
	}

	public constructor(injector: Injector) {
		this._i18n = injector.get(I18nService);
		this._route = injector.get(ActivatedRoute);
		this._router = injector.get(Router);
		this._toast = injector.get(ToastService);
		this._confirmation = injector.get(ConfirmationService);
		this._manager = injector.get(ViewTemplateManagerService);
		this._userService = injector.get(UserService);
	}

	public get myUid(): string {
		return this._userService.getUser<User>().uid;
	}

	public canCreate(): boolean {
		return this._userService.can('create', 'viewTemplate');
	}

	public canAdmin(): boolean {
		return this._userService.can('admin', 'viewTemplate');
	}

	public canRemove(userUid?: string): boolean {
		return this._userService.can('delete', 'viewTemplate') && (userUid === this.myUid || this.canAdmin());
	}

	public canSave(userUid?: string): boolean {
		return this._userService.can('update', 'viewTemplate') && (userUid === this.myUid || this.canAdmin());
	}

	public ngOnInit() {
		const { view, version } = this._normalizeViewAndVersion(this.view, this.version);
		this._projection = this._manager.project(view, version);
		const subscription = this._projection.list()
			.subscribe(
				next => this._setPreambules(next),
				error => this._toast.show(
					this._i18n.t('Loading list of view templates failed.'),
					error.statusText));
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnDestroy() {
		this._loadSubscription && this._loadSubscription.unsubscribe();
		this._disposables.forEach(x => x());
	}

	protected _updateModified() {
		this.modified = (this.template != null && this.selected == null)
			|| (this.selected != null && !Utils.equal(this.template, this.selected.value));
	}

	protected _setPreambules(preambules: ViewTemplatePreambule[]) {
		this.preambules = preambules || [];
		this.queryParam && this._subscribeQueryParams();
	}

	protected _setSelected(template: ViewTemplateItem<any>) {
		this.selected = template;
		this.queryParam && this._updateQueryParam();
		this.modified = false;
	}

	private _normalizeViewAndVersion(view: string, version?: string) {
		if (version == null) {
			const index = view.lastIndexOf('@');
			if (index !== -1) {
				return {
					view: view.substr(0, index),
					version: view.substr(index + 1)
				};
			}
		}
		return {
			view: view,
			version: version
		};
	}

	public select(id: number) {
		if (this.isSaveMode) {
			const newName = this.preambules.find(x => x.id === id);
			if (this.selected && this.selected.id) {
				if (newName) {
					this._save({ id: this.selected.id, name: newName.name, userUid: this.saveUserUid });
				}
				else {
					this._save({ id: this.selected.id, userUid: this.saveUserUid });
				}
			}
			else {
				this._save({ name: newName && newName.name, userUid: this.saveUserUid });
			}
			this.isSaveMode = false;
		}
		else {
			this._load(id);
		}
	}

	public cancel() {
		if (this.isSaveMode) {
			this.isSaveMode = false;
		}
		else {
			this._setSelected(null);
		}
	}

	public save(idOrName: { id?: number, name?: string, userUid?: string }) {
		this._save(idOrName);
		this.isSaveMode = false;
	}

	public remove(id: number) {
		this._remove(id);
	}

	private _load(id: number) {
		this._loadSubscription && this._loadSubscription.unsubscribe();
		this._loadSubscription = this._projection.load(id)
			.subscribe(
				next => this._setSelected(next),
				error => this._toast.show(
					this._i18n.t('Loading view template failed.'),
					error.statusText));
	}

	// eslint-disable-next-line complexity
	private async _save(idOrName: { id?: number, name?: string, userUid?: string }) {
		let index = -1;
		let hasName = false;
		let nameColision;
		let nameColisionIndex;
		const userUid = this.canSave(idOrName.userUid) ? idOrName.userUid : this.myUid;
		if (idOrName.hasOwnProperty('id')) {
			index = this.preambules.findIndex(x => x.id === idOrName.id && x.userUid === userUid);
		}
		if (idOrName.hasOwnProperty('name')) {
			hasName = true;
			if (!idOrName.hasOwnProperty('id')) {
				index = this.preambules.findIndex(x => x.name === idOrName.name && x.userUid === userUid);
			}
			nameColisionIndex = this.preambules.findIndex(x => x.name === idOrName.name && x.userUid === userUid);
			nameColision = index !== -1 && nameColisionIndex !== -1 && nameColisionIndex !== index;
		}
		let response$: Observable<Response>;
		let template: ViewTemplateItem<any>;
		let saveAsNew = index === -1 || this.preambules[index].userUid !== userUid;
		if (index !== -1 && hasName && (this.preambules[index].name !== idOrName.name || nameColisionIndex !== -1)) {
			let res = {};
			if (this.preambules[index].name !== idOrName.name) {
				res = await this._confirmOverwrite(this.preambules[index].name, idOrName.name, nameColision);
			}
			else {
				res = await this._confirmOverwrite('', this.preambules[index].name, true);
			}
			if (res === 'new') {
				saveAsNew = true;
			}
			if (res === 'overwrite' && nameColision) {
				index = nameColisionIndex;
			}
			if (res !== 'overwrite') {
				index = -1;
			}
		}
		if (index !== -1) {
			// overwrite
			template = {
				id: this.preambules[index].id,
				name: hasName ? idOrName.name : this.preambules[index].name,
				value: Utils.clone(this.template, true),
				userUid: this.preambules[index].userUid,
				hidden: this.preambules[index].hidden
			};
			response$ = this._projection.save(template).pipe(
				Rx.tap(() => {
					this.preambules[index].name = template.name;
					Utils.array.sort(this.preambules, ['-userUid', 'name']);
				})
			);
		}
		else if (saveAsNew) {
			// new
			template = {
				name: idOrName.name,
				value: Utils.clone(this.template, true),
				hidden: false,
				userUid: userUid
			};
			response$ = this._projection.save(template).pipe(
				Rx.tap(() => {
					this.preambules.push({ id: template.id, name: template.name, hidden: template.hidden, userUid: template.userUid });
					Utils.array.sort(this.preambules, ['-userUid', 'name']);
				})
			);
		}
		response$ && response$.subscribe(
			() => this._setSelected(template),
			error => this._toast.show(this._i18n.t('Saving view template failed.'), error.statusText));
	}

	private async _remove(id: number) {
		const index = this.preambules.findIndex(x => x.id === id);
		if (index !== -1 && await this._confirmRemove(this.preambules[index].name)) {
			this._projection.remove(id).subscribe(
				() => {
					this.modified = true;
					this.preambules.splice(index, 1);
					if (this.selected && this.selected.id === id) {
						this._setSelected(null);
					}
				},
				error => this._toast.show(
					this._i18n.t('Remove view template failed.'),
					error.statusText));
		}
	}

	private async _confirmRemove(name: string) {
		const result = await this._confirmation.show(
			this._i18n.t('Remove {{ name }}?', { name }),
			this._i18n.t('Are you sure?\nRemoving is inreversible operation.'), [
				{ name: this._i18n.t('Delete'), classes: ['danger'], result: 'delete' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'delete';
	}

	private async _confirmOverwrite(name: string, newName: string, newExists: boolean) {
		const buttons = [
			{ name: this._i18n.t('Save dialog|template', 'Yes, overwrite'), classes: ['danger'], result: 'overwrite' },
			{ name: this._i18n.t('Cancel') }
		];
		let msg;
		if (!newExists) {
			buttons.unshift({ name: this._i18n.t('Save dialog|template', 'No, save as new'), classes: ['primary'], result: 'new' });
			msg = this._i18n.t('Overwrite `{{ name }}` to `{{ newName }}`?', { name, newName });
		}
		else {
			msg = this._i18n.t('Overwrite `{{ newName }}`?', { newName });
		}
		const result = await this._confirmation.show(
			msg,
			this._i18n.t('Old template will be lost.'),
			buttons
		);
		return result || 'cancel';
	}

	private _subscribeQueryParams() {
		const subscription = this._route.queryParams
			.pipe(
				Rx.filter(next => next.hasOwnProperty(this.queryParam)),
				Rx.map(next => {
					for (const preambule of this.preambules) {
						if (this._viewQueryParam(preambule.name, preambule.userUid) === next[this.queryParam]) {
							return preambule.id;
						}
					}
					return null;
				}),
				Rx.filter(next => next && (this.selected == null || this.selected.id !== next))
			)
			.subscribe(next => {
				this.cancel();
				this.select(next);
			});
		this._disposables.push(() => subscription.unsubscribe());
	}

	private _updateQueryParam() {
		const queryParams = Utils.clone(this._route.snapshot.queryParams);
		if (this.selected == null) {
			delete queryParams[this.queryParam];
		}
		else {
			queryParams[this.queryParam] = this._viewQueryParam(this.selected.name, this.selected.userUid);
		}
		this._router.navigate(['.'], {
			relativeTo: this._route,
			queryParams: queryParams,
			replaceUrl: true
		});
	}

	private _viewQueryParam(name: string, userUid: string) {
		return this._slugifier(name + ' ' + (userUid || ''));
	}
}
