export class AppMeta {
	public name: string;
	public description: string;
	public version: string;
	public date: string;
	public build: number;
}

export const appMeta: AppMeta = {
	name: 'storisto-web',
	description: 'Storisto web frontend for EAS systems.',
	version: '0.1.0',
	date: '2020-08-05T11:16:28.392Z',
	build: 24
};
