import {Directive, Injector, HostBinding, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, FormArray, FormControl, AbstractControl} from '@angular/forms';
import {Observable, Subscription, merge as observableMerge, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response} from 'kn-http';
import {UserService} from 'kn-user';
import {ChangeEntry, Indexer} from 'kn-rest';
import {ActiveMonitor} from 'kn-shared';
import {AbstractGraphStore, QueryContext} from 'common-web/rest';
import {AbstractEditComponent} from './abstract-edit.component';
import {EditMode} from './types';
import * as Model from 'common-web/model';

@Directive()
export abstract class AbstractStoreEditComponent<T extends { [key: string]: (Model.EntityBase | Model.EntityBase[]) }> extends AbstractEditComponent implements OnInit, OnDestroy {
	public name: string;
	public audit: Model.IAuditableEntity;
	public outdated: boolean = false;
	public savable: boolean = false;
	public deletable: boolean = false;

	@HostBinding('class.loading')
	public get loading(): boolean {
		return this._loadingMonitor.active;
	}

	private _indexer: Indexer | QueryContext<string>;
	protected _user: UserService;
	protected _route: ActivatedRoute;
	protected _changesListenerSubscription: Subscription;
	protected _loadingMonitor = new ActiveMonitor(5, 500);

	public constructor(injector: Injector, private readonly _store: AbstractGraphStore<T>) {
		super(injector);
		this._user = injector.get(UserService);
		this._route = injector.get(ActivatedRoute);
	}

	public ngOnInit() {
		super.ngOnInit();
		this._registerChangesListener();
	}

	public ngOnDestroy() {
		this._unregisterChangesListener();
		super.ngOnDestroy();
	}

	protected _init() {
		this._indexer = this._retriveIndexer();
		switch (this._retriveAction() || 'new') {
			case 'edit':
				this._mode = this._indexer ? EditMode.Edit : EditMode.New;
				break;
			default:
				this._mode = this._indexer ? EditMode.NewAsCopy : EditMode.New;
				break;
		}
	}

	protected _load() {
		this.disableControl(this.form, false);
		if (this._indexer) {
			const subscription = this._fetchModel(this._indexer)
				.pipe(Rx.map(next => this._sanityModel(next)))
				.lift(this._loadingMonitor.operator())
				.subscribe(
					next => {
						this._updateView(next);
						if (next != null) {
							this._indexer = this._store.retriveIndexer(next);
							this.name = this._extractName(next[this._store.key] as Model.EntityBase);
							this.audit = this._extractAudit(next[this._store.key] as Model.EntityBase);
							this._populateFormValues(this.form, next);
						}
						else {
							this._indexer = null;
							this.name = null;
							this.audit = null;
							this.form.reset(this._defaultValues);
						}
						this._updateAccess();
						this._updateDisable();
					},
					error => this._toast.show(this._i18n.t('Loading failed.'), this._retriveErrorMessage(error)));
			this._disposables.push(() => subscription.unsubscribe());
		}
		else {
			this._updateView();
			this._updateAccess();
			this._updateDisable();
		}
	}

	protected _reload() {
		super._reload();
		this.outdated = false;
	}

	protected _retriveMonitoredChanges(): Observable<ChangeEntry>[] {
		return this.isEditMode() ? [this._store.changes] : [];
	}

	protected _registerChangesListener() {
		const monitoredChanges = this._retriveMonitoredChanges();
		if (monitoredChanges.length > 0) {
			this._changesListenerSubscription = observableMerge(...monitoredChanges)
				.pipe(Rx.filter(next => next.indexer == this._indexer)) // eslint-disable-line eqeqeq
				.subscribe(async next => {
					this.outdated = true;
					if (await this._confirmReload(next)) {
						this._reload();
					}
				});
		}
	}

	protected _updateView(model?: T) { /* intentionally empty */ }

	protected _updateAccess() {
		const action = this.isEditMode() ? 'update' : 'create';
		this.savable = this._user.can(action, this._store.key);
		if (this.savable) {
			this.form.disabled && this.enableControl(this.form, false);
			for (const key of this._store.dependentKeys) {
				const formControl = this.form.controls[key];
				if (this._user.can(action, key)) {
					formControl.disabled && this.enableControl(formControl, false);
				}
				else {
					formControl.enabled && this.disableControl(formControl, false);
				}
			}
		}
		else {
			this.form.enabled && this.disableControl(this.form, false);
		}

		this.deletable = true;
		for (const key of [this._store.key].concat(this._store.dependentKeys)) {
			if (!this._user.can('delete', key)) {
				this.deletable = false;
			}
		}
	}

	protected _updateDisable() {
		this._applyResistiveDisable();
	}

	private async _confirmReload(next: ChangeEntry) {
		const result = await this._confirmation.show(
			this._i18n.t('Data changed.'),
			this._i18n.t('Table {{ table }} has been modified.\nCan I reload this form?', { table: next.table }), [
				{ name: this._i18n.t('Reload'), classes: ['primary'], result: 'reload' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'reload';
	}

	protected _unregisterChangesListener() {
		this._changesListenerSubscription && this._changesListenerSubscription.unsubscribe();
	}

	protected _buildForm(): FormGroup {
		const formModel: { [key: string]: AbstractControl } = {
			[this._store.key]: this._buildControlGroup(this._store.key)
		};
		for (const key of this._store.dependentKeys) {
			formModel[key] = new FormArray([]);
		}
		return this._formBuilder.group(formModel);
	}

	protected abstract _buildControlGroup(key: string): FormGroup;

	protected _extractName(item: Model.EntityBase): string {
		return item['name'];
	}

	protected _extractAudit(item: Model.EntityBase): Model.IAuditableEntity {
		if (item.hasOwnProperty('lastModified') || item.hasOwnProperty('modifiedBy')) {
			return {
				lastModified: item['lastModified'],
				modifiedBy: item['modifiedBy']
			};
		}
		return null;
	}

	protected _fetchModel(indexerOrQuery: Indexer | QueryContext<string>): Observable<T> {
		return this._store.get(indexerOrQuery) as Observable<T>;
	}

	protected _saveModel(model: T): Observable<{ [key: string]: Response | Response[] }> {
		return this._store.save(model);
	}

	protected _deleteModel(indexer: Indexer): Observable<{ [key: string]: Response }> {
		return this._store.remove(indexer);
	}

	protected _sanityModel(model: T) {
		return model;
	}

	protected _populateFormValues(form: FormGroup, model: T) {
		const formGroup = form.controls[this._store.key] as FormGroup;
		this._setFormGroup(formGroup, model[this._store.key] as Model.EntityBase);
		for (const key of this._store.dependentKeys) {
			this._setFormArray(key, form.controls[key] as FormArray, (model[key] || []) as any[]);
		}
	}

	protected _setFormGroup(formGroup: FormGroup, entity: Model.EntityBase) {
		for (const key in formGroup.controls) {
			if (formGroup.controls.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
				const control = formGroup.controls[key];
				if (control instanceof FormGroup && Utils.isObject(entity[key])) {
					this._setFormGroup(control, entity[key]);
				}
				else if (control instanceof FormArray && Array.isArray(entity[key])) {
					this._setFormArray(key, control, entity[key] as any[]);
				}
				else if (control instanceof FormControl) {
					control.setValue(entity[key]);
				}
			}
		}
	}

	protected _setFormArray(key: string, controlsArray: FormArray, entitiesArray: Model.EntityBase[]) {
		for (let i = controlsArray.length - 1; i >= entitiesArray.length; i--) {
			this.removeControl(controlsArray, i);
		}
		for (let i = 0; i < entitiesArray.length; i++) {
			let controlGroup: FormGroup;
			if (i === controlsArray.length) {
				controlGroup = this._buildControlGroup(key);
				this.addControl(controlsArray, controlGroup);
			}
			else {
				controlGroup = controlsArray.at(i) as FormGroup;
			}
			if (controlGroup instanceof FormControl) {
				(controlGroup as FormControl).setValue(entitiesArray[i]);
			}
			else {
				this._setFormGroup(controlGroup, entitiesArray[i]);
			}
		}
	}

	protected _formToModel(form: FormGroup, raw?: boolean): T {
		const keyGroup = (form.controls[this._store.key] as FormGroup);
		const model: T = {
			[this._store.key]: raw ? keyGroup.getRawValue() : keyGroup.value
		} as T;
		for (const key of this._store.dependentKeys) {
			const dependentForm = form.controls[key] as FormArray;
			const dependentModel: Model.EntityBase[] = [];
			for (let i = 0; i < dependentForm.length; i++) {
				const dependentGroup = dependentForm.at(i) as FormGroup;
				dependentModel.push(raw ? dependentGroup.getRawValue() : dependentGroup.value);
			}
			(model as any)[key] = dependentModel;
		}
		return model;
	}

	protected _prepareModelToSave(model: T): T {
		if ((model[this._store.key] as Model.EntityBase).id == null || !this.isEditMode()) {
			delete (model[this._store.key] as Model.EntityBase).id;
		}
		for (const key of this._store.dependentKeys) {
			const dependentModel = model[key] as Model.EntityBase[];
			for (const item of dependentModel) {
				if (item.id == null || !this.isEditMode()) {
					delete item.id;
				}
			}
		}
		return model;
	}

	protected _retriveIndexer(): Indexer | QueryContext<string> {
		const uid = this._route.snapshot.params['id'];
		return uid && { query: { $uid: uid } };
	}

	protected _retriveAction() {
		return this._route.snapshot.params['action'];
	}

	public save(): void {
		const saver$ = observableOf(this._formToModel(this.form)).pipe(
			Rx.map(next => this._sanityModel(next)),
			Rx.map(next => this._prepareModelToSave(next)),
			Rx.tap(() => this._unregisterChangesListener()),
			Rx.switchMap(next => this._saveModel(next).pipe(Rx.map(() => next)))
		);

		/*
		 * No unsubscribe because save must be completed even if user navigates elsewhere early.
		 */
		saver$.subscribe(
			next => {
				this.name = this._extractName(next[this._store.key] as Model.EntityBase) || this.name;
			},
			error => this._toast.show(this._i18n.t('Saving failed.'), this._retriveErrorMessage(error)),
			() => {
				this._toast.show(this._i18n.t('Item saved.'), this._i18n.t('{{ item }} has been successfully saved!', { item: this.name }));
				this.outdated = false;
				this.navigateBack(false);
			});
	}

	public async delete() {
		if (this.isEditMode() && await this._confirmDelete(this.name)) {
			this._unregisterChangesListener();

			/*
			 * No unsubscribe because delete must be completed even if user navigates
			 * elsewhere early.
			 */
			this._deleteModel(this._indexer as Indexer).subscribe(
				() => { /* intentionaly empty */ },
				error => this._toast.show(this._i18n.t('Remove failed.'), this._retriveErrorMessage(error)),
				() => {
					this._toast.show(this._i18n.t('Item removed.'), this._i18n.t('{{ item }} has been successfully removed!', { item: this.name }));
					this.outdated = false;
					this.navigateBack(false);
				});
		}
	}

	protected async _confirmDelete(name: string) {
		const result = await this._confirmation.show(
			this._i18n.t('Delete {{ name }}?', { name }),
			this._i18n.t('Are you sure?\nRemoving is inreversible operation.'), [
				{ name: this._i18n.t('Delete'), classes: ['danger'], result: 'delete' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'delete';
	}
}
