import {Component, Injector} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {DatabaseEditService} from './database-edit.service';
import {ValidationService, AsyncCheckValidator} from 'kn-forms';
import {OwnershipUtils} from 'common-web';
import {AbstractStoreEditComponent} from 'common-web/forms';
import {Formatters} from 'common-web/rest';
import {DatabaseModel} from './database-model';
import {DatabasesViewConfig} from './databases-view.config';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import * as Model from 'common-web/model';

@Component({
	selector: 'app-database-edit',
	templateUrl: 'database-edit.html',
	providers: [ValidationService]
})
export class DatabaseEditComponent extends AbstractStoreEditComponent<DatabaseModel> {
	private static readonly _uidPattern = /^[a-z0-9\s._-]*$/;

	public prefixOwnership: string;
	public databaseTypes = this._config.databaseTypes;
	public databaseProviders = [
		'SQLite',
		'PostgreSQL'
	];

	public constructor(
			injector: Injector,
			databaseEditService: DatabaseEditService,
			private readonly _companiesResource: DatabasesResourceService,
			private readonly _config: DatabasesViewConfig) {
		super(injector, databaseEditService);

		this.prefixOwnership = this._user.getUser<Model.User>().ownership;

		const checker = (value: string) => this._companiesResource
			.query({ query: { $uid: value, count: null } })
			.pipe(Rx.map(next => (next as any as Model.CountResponse).count === 0));
		const uidValidator = new AsyncCheckValidator<string>(checker);
		this._validation.add('uid', uidValidator, this._i18n.t('Item duplicity error', 'Duplicate'));
	}

	public get databaseName() {
		return this._config.databaseName;
	}

	protected _load() {
		super._load();

		if (!this.isEditMode()) {
			const subscriptions = [
				this._connectUid(),
				this._connectConnectionString()
			];
			subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
		}
	}

	protected _buildControlGroup(key: string): FormGroup {
		switch (key) {
			case 'database':
				return this._buildDatabaseControlGroup();
		}
		throw new Error('Unknown model control group.');
	}

	protected _populateFormValues(form: FormGroup, model: DatabaseModel) {
		model.database.ownership = OwnershipUtils.toRelative(this.prefixOwnership, model.database.ownership);
		super._populateFormValues(form, model);
	}

	protected _prepareModelToSave(model: DatabaseModel): DatabaseModel {
		model.database.ownership = OwnershipUtils.toAbsolute(this.prefixOwnership, model.database.ownership);
		return super._prepareModelToSave(model);
	}

	protected _buildDatabaseControlGroup() {
		const validators = this._validation.validators;
		return this._formBuilder.group({
			id: [undefined],
			connectionString: ['', [validators.required(), validators.maxLength(250)]],
			description: ['', validators.maxLength(200)],
			name: ['', [validators.required(), validators.maxLength(50)]],
			ownership: ['', validators.maxLength(100)],
			provider: [this._config.defaultProvider, [validators.required(), validators.maxLength(15)]],
			type: [this._config.defaultDatabaseType, validators.required()],
			uid: ['', [validators.required(), validators.maxLength(40), validators.pattern(DatabaseEditComponent._uidPattern)], this.isEditMode() ? null : validators.uid()]
		});
	}

	private _connectUid() {
		const formater = Formatters.slugify(40, true);
		const databaseGroup = this.form.get('database') as FormGroup;
		return databaseGroup.get('name').valueChanges
			.pipe(
				Rx.filter(() => databaseGroup.get('name').dirty && databaseGroup.get('uid').pristine),
				Rx.distinctUntilChanged()
			)
			.subscribe(next => databaseGroup.get('uid').setValue(formater(next)));
	}

	private _connectConnectionString() {
		const databaseGroup = this.form.get('database') as FormGroup;
		const uid$ = databaseGroup.get('uid').valueChanges;
		const provider$ = databaseGroup.get('provider').valueChanges.pipe(
			Rx.publishBehavior(databaseGroup.get('provider').value),
			Rx.refCount()
		);
		return observableCombineLatest(uid$, provider$)
			.pipe(
				Rx.filter(() => databaseGroup.get('connectionString').pristine),
				Rx.distinctUntilChanged()
			)
			.subscribe(next => {
				if (!next[0]) {
					databaseGroup.get('connectionString').setValue('');
					return;
				}
				let connectionString: string;
				switch (next[1]) {
					case 'SQLite':
						connectionString = `Data source=${next[0]}.sqlite`;
						break;
					case 'PostgreSQL':
						if (this._config.defaultDatabasePrefix) {
							connectionString = `Database=${this._config.defaultDatabasePrefix}-${next[0]}`;
						}
						else {
							connectionString = `Database=${next[0]}`;
						}
						break;
					default:
						return;
				}
				databaseGroup.get('connectionString').setValue(connectionString);
			});
	}
}
