import {Component, ViewEncapsulation, Input, Output, EventEmitter, HostListener, ElementRef, ChangeDetectionStrategy, NgZone, Inject, HostBinding, OnDestroy} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {ColumnModel} from '../types';

@Component({
	selector: 'kn-resizable-handler',
	template: '',
	styleUrls: ['resizable-handler.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnResizableHandler implements OnDestroy {
	@Input() public column: ColumnModel;
	@Output() public columnChange: EventEmitter<ColumnModel> = new EventEmitter<ColumnModel>();

	private _dragging: any = null;
	private _eventsRemoverOutsideAngular: { (): void };

	@HostBinding('class.resizing')
	public get resizing(): boolean {
		return this._dragging;
	}

	public constructor(
			private readonly _zone: NgZone,
			private readonly _element: ElementRef,
			@Inject(DOCUMENT) doc: any /* Document */) {
		this._zone.runOutsideAngular(() => {
			const resizeMoveListener = this._resizeMoveHandler.bind(this);
			doc.addEventListener('mousemove', resizeMoveListener);
			doc.addEventListener('touchmove', resizeMoveListener);
			this._eventsRemoverOutsideAngular = () => {
				doc.removeEventListener('mousemove', resizeMoveListener);
				doc.removeEventListener('touchmove', resizeMoveListener);
			};
		});
	}

	public ngOnDestroy() {
		this._zone.runOutsideAngular(() => this._eventsRemoverOutsideAngular());
	}

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public resizeStartHandler(event: MouseEvent | TouchEvent) {
		this._dragging = {
			offset: (event as MouseEvent).screenX || (event as TouchEvent).targetTouches[0].screenX,
			size: this.column.width
				|| this._element.nativeElement.parentElement.getBoundingClientRect().width
		};
		event.preventDefault();
	}

	private _resizeMoveHandler(event: MouseEvent | TouchEvent) {
		if (this._dragging) {
			let size = this._dragging.size - this._dragging.offset
				+ ((event as MouseEvent).screenX || (event as TouchEvent).targetTouches[0].screenX);
			if (size < 30) {
				size = 30;
			}
			else if (size > 1000) {
				size = 1000;
			}
			this.column.width = size;
			this.columnChange.emit(this.column);
			event.preventDefault();
		}
	}

	@HostListener('document:mouseup')
	@HostListener('document:touchend')
	public resizeEndHandler() {
		this._dragging = undefined;
	}
}
