<label [attr.for]="inputId">
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required">*</span>
</label>

<div class="kn-input-container"
		knRipple [knRippleDisabled]="disabled" 
		(knFileDrop)="handleChange($event)"
		i18n-title title="Open file or drag here">
	<div class="kn-input-prefix">
		<ng-content select="[kn-prefix]"></ng-content>
	</div>
	<button #openButton
		type="button"
		class="form-button"
		(click)="input.click()"
		[autofocus]="autofocus"
		[disabled]="disabled"
		[attr.tabindex]="tabindex"><i class="material-icons">folder</i></button>
	<input #input
		hidden
		type="file"
		[id]="inputId"
		[disabled]="disabled"
		[required]="required"
		[readonly]="readonly"
		[attr.name]="name"
		[attr.accept]="accept"
		[attr.multiple]="multiple"
		(change)="handleChange($event)">
	<button type="button" class="form-button" (click)="retrieveFile()">{{ file?.name || placeholder }}</button>
	<div class="kn-input-suffix">
		<ng-content select="[kn-suffix]"></ng-content>
	</div>
</div>

<div class="kn-input-underline"></div>
<ng-content></ng-content>
<ng-content select="[kn-hint]"></ng-content>
