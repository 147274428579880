import {Uri} from '../uri/uri';
import {Headers} from './headers';
import {ResponseOptions, ResponseState, ProgressEvent} from './types';

export class Response implements ResponseOptions, ProgressEvent {
	public readonly state: ResponseState;
	public readonly uri: Uri;
	public readonly headers: Headers;
	public readonly loaded: number;
	public readonly total?: number;
	public readonly status: number;
	public readonly statusText: string;
	public readonly body: any;

	public constructor(uri: string | Uri, body: any, options?: Partial<ResponseOptions>, progress?: Partial<ProgressEvent>) {
		this.uri = uri && Uri.from(uri);
		options = options || {};
		this.headers = options.headers || new Headers();
		this.status = options.status;
		this.statusText = options.statusText;
		this.body = body;
		progress = progress || {};
		this.state = progress.state || ResponseState.Finished;
		this.loaded = progress.loaded;
		this.total = progress.total;
	}
}
