import {Injectable} from '@angular/core';
import {AbstractDiscriminateStrategy} from './abstract-discriminate-strategy';
import {SettingsItem} from '../types';

@Injectable()
export class PrefixDiscriminateStrategy extends AbstractDiscriminateStrategy {
	public support(key: string, discriminator: string, discriminatorArg: any): boolean {
		return true;
	}

	public discriminate(key: string, discriminator: string = '', candidates: SettingsItem[], discriminatorArg: any): SettingsItem {
		let item: SettingsItem;
		let score: number;
		for (const candidate of candidates) {
			const candidateScore = this._calcScore(candidate.discriminator || '', discriminator);
			if (item == null || score < candidateScore) {
				item = candidate;
				score = candidateScore;
			}
		}
		return item;
	}

	private _calcScore(candidateDiscriminator: string, targetDiscriminator: string) {
		let score = 0;
		let same = true;
		for (let i = 0; i < candidateDiscriminator.length; i++) {
			if (!same || candidateDiscriminator[i] !== targetDiscriminator[i]) {
				same = false;
				score--;
			}
			else {
				score++;
			}
		}
		return score;
	}
}
