import {KnAuthorizationModule, Permissions} from 'kn-user';
import {RestAccessControl} from '../../services/authorization/access-control/rest-access-control';

export function provideLoginCrudMapping() {
	return RestAccessControl.crud('User');
}

export const permissions: Permissions = [
	{
		resource: 'item',
		accessControl: 'krest',
		mapping: {
			new: [{ 'User': 'C', 'Admin': 'C', 'Database': 'R' }],
			copy: [{ 'User': 'CR', 'Admin': 'CR', 'Database': 'R' }],
			edit: [{ 'User': 'R', 'Admin': 'R', 'Database': 'R' }],
			remove: [{ 'User': 'D', 'Admin': 'D', 'Database': 'R' }]
		}
	}, {
		resource: 'user',
		accessControl: 'krest',
		mapping: provideLoginCrudMapping
	}, {
		resource: 'databasesUserRoles',
		accessControl: 'krest',
		mapping: provideLoginCrudMapping
	}
];

export const authorization = KnAuthorizationModule.forChild(permissions, 'users');
