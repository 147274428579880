import {Component, ViewEncapsulation, Input, Output, HostBinding, HostListener, EventEmitter, ElementRef} from '@angular/core';

@Component({
	selector: 'kn-menu',
	template: '<ng-content></ng-content>',
	styles: ['kn-menu { display: block; }'],
	encapsulation: ViewEncapsulation.None
})
export class KnMenu {
	private _preventCollapse: boolean = false;

	@Output() public expandedChange = new EventEmitter<boolean>();

	@Input() public set expanded(value: boolean) {
		if (this._expanded !== value) {
			this._expanded = value;
			this.expandedChange.emit(value);
		}
	}

	public get expanded(): boolean {
		return this._expanded;
	}

	public constructor(public readonly elementRef: ElementRef) {
	}

	public expand() {
		this.expanded = true;
	}

	public collapse() {
		this.expanded = false;
	}

	public preventCollapse() {
		this._preventCollapse = true;
	}

	@HostBinding('class.expanded')
	private _expanded: boolean = false;

	@HostListener('document:click')
	public documentClickHandler() {
		if (!this._preventCollapse) {
			this.collapse();
		}
		this._preventCollapse = false;
	}
}
