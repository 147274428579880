import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild, ViewEncapsulation} from '@angular/core';
import {Utils} from 'kn-utils';
import {Subject, combineLatest as observableCombineLatest} from 'rxjs';
import {ReorderRowData} from '../features/reordable-rows-container.directive';
import {Node} from '../model/node';
import {ChangeNotifier} from '../services/change-notifier.service';
import {ColumnsBuilderFactory} from '../services/columns-builder-factory.service';
import {ExpansionService} from '../services/expansion.service';
import {GroupIdsCache} from '../services/group-ids-cache.service';
import {NodeTreeBuilderFactory} from '../services/note-tree-builder-factory.service';
import {SelectionService} from '../services/selection.service';
import {Column, DataSource, Description, Model, ModelChange, RowItem, Sorting} from '../types';
import {KnTableBody} from './table-body.component';
import * as Rx from 'rxjs/operators';

@Component({
	selector: 'table[knTable]',
	templateUrl: 'table.html',
	styleUrls: ['table.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		ChangeNotifier,
		ExpansionService,
		SelectionService
	]
})
export class KnTable implements OnChanges {
	private readonly _descriptionSubject$: Subject<Description<RowItem>>;
	private readonly _modelSubject$: Subject<Model>;
	private readonly _dataSourceSubject$: Subject<DataSource<RowItem>>;

	public constructor(
			nodeTreeBuilderFactory: NodeTreeBuilderFactory,
			columnsBuilderFactory: ColumnsBuilderFactory,
			private readonly _changeDetector: ChangeDetectorRef,
			private readonly _groupIdsCache: GroupIdsCache,
			private readonly _changeNotifier: ChangeNotifier,
			private readonly _expansion: ExpansionService,
			private readonly _selection: SelectionService) {
		this._descriptionSubject$ = new Subject<Description<RowItem>>();
		this._modelSubject$ = new Subject<Model>();
		this._dataSourceSubject$ = new Subject<DataSource<RowItem>>();

		const nodeTreeBuilder = nodeTreeBuilderFactory.create();
		const columnsBuilder = columnsBuilderFactory.create();

		const columns$ = observableCombineLatest(this._descriptionSubject$, this._modelSubject$)
			.pipe(Rx.map(next => columnsBuilder.build(next[0], next[1])));

		const visibleColumns$ = columns$
			.pipe(Rx.map(next => columnsBuilder.filterVisibleColumns(next)));

		const tree$ = observableCombineLatest(columns$, this._dataSourceSubject$)
			.pipe(Rx.map(next => nodeTreeBuilder.build(next[0], next[1] || [])));

		columns$.subscribe(next => this.columns = next);
		visibleColumns$.subscribe(next => this.visibleColumns = next);
		tree$.subscribe(next => {
			this.tree = Utils.clone(next);
			this.tree.invalidateCache();
			this._groupIdsCache.calculate(this.tree, this.columns);
		});

		this._expansion.refreshRows.subscribe(() => this.tree = Utils.clone(this.tree));
	}

	@Input() public description: Description<RowItem>;

	@Input() public model: Model;
	@Output() public modelChange: EventEmitter<Model> = new EventEmitter<Model>();
	@Input() public dataSource: DataSource<RowItem>;
	@Output() public dataSourceChange: EventEmitter<DataSource<RowItem>> = new EventEmitter<DataSource<RowItem>>();

	@Input() public set expanded(value: RowItem[]) {
		if (this.tree != null) {
			this._expansion.setExpanded(value, this.tree);
		}
	}

	@Output() public get expandedChange(): EventEmitter<RowItem[]> {
		return this._expansion.expandedChange;
	}

	@ViewChild(KnTableBody, { static: true })
	public body: KnTableBody;

	@Input() public set selected(value: RowItem[]) {
		if (this.tree != null) {
			const changed = this._selection.setSelected(value, this.tree);
			this.body.refreshNodes(changed);
		}
	}

	@Output() public get selectedChange(): EventEmitter<RowItem[]> {
		return this._selection.selectedChange;
	}

	@Output() public rowSelect: EventEmitter<RowItem> = new EventEmitter<RowItem>();
	@Output() public rowActivate: EventEmitter<RowItem> = new EventEmitter<RowItem>();

	public tree: Node<RowItem> = Node.root<RowItem>();
	public columns: Column<RowItem>[];
	public visibleColumns: Column<RowItem>[];

	public onModelEvent(event: ModelChange): void {
		this._modelSubject$.next(this.model);
		this.modelChange.emit(this.model);
	}

	public ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if ('description' in changes) {
			this._descriptionSubject$.next(this.description);
		}
		if ('model' in changes) {
			this._modelSubject$.next(this.model);
		}
		if ('dataSource' in changes) {
			this._dataSourceSubject$.next(this.dataSource);
		}
		this._changeNotifier.emit();
	}

	public refreshRows(rebuildTree?: boolean, refreshItems?: RowItem[]) {
		if (this.tree != null) {
			if (rebuildTree) {
				this._dataSourceSubject$.next(this.dataSource);
			}
			else {
				this.tree.invalidateCache();
				this.tree = Utils.clone(this.tree);
				this._groupIdsCache.calculate(this.tree, this.columns);
			}
			if (refreshItems != null) {
				this.body.refreshRowItems(refreshItems);
			}
			this._changeNotifier.emit();
		}
	}

	public reorderRows(event: ReorderRowData) {
		const dataSource = this.dataSource;
		if (event.offset) {
			const indexes = event.subjects.map(x => dataSource.indexOf(x));
			if (event.offset > 0) {
				indexes.reverse();
			}
			for (const index of indexes) {
				dataSource.splice(index + event.offset, 0, dataSource.splice(index, 1)[0]);
			}
			this._dataSourceSubject$.next(dataSource);
			this._changeDetector.detectChanges();
		}
		if (event.done && event.modified) {
			let modelChanged = false;
			for (const column of this.model.columns) {
				if (!column.group && column.sort !== Sorting.None) {
					column.sort = Sorting.None;
					modelChanged = true;
				}
			}

			if (modelChanged) {
				this._modelSubject$.next(this.model);
			}

			this.dataSource = dataSource;
			this.dataSourceChange.emit(this.dataSource);
		}
	}

	public reorderColumn(fromIndex: number, toIndex: number) {
		this.model.columns.splice(toIndex, 0, this.model.columns.splice(fromIndex, 1)[0]);
		this._modelSubject$.next(this.model);
		this.modelChange.emit(this.model);
	}

	public commitModel(): void {
		// TODO: refactor/move to another position/...?
		let offset: number = null;
		for (let i = 1; i < this.model.columns.length; i++) {
			if (!this.model.columns[i].group) {
				if (this.model.columns[i - 1].group && offset === null) {
					offset = i;
				}
			}
			else if (offset !== null) {
				this.model.columns.splice(offset++, 0, this.model.columns.splice(i--, 1)[0]);
			}
		}
		this._modelSubject$.next(this.model);
		this.modelChange.emit(this.model);
	}

	public scrollToView(item: RowItem) {
		this.body.scrollToView(item);
	}
}
