import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {DatabasesConfig} from './databases.config';
import {DatabasesResourceService} from './databases-resource.service';
import {BackupDatabaseService} from './backup-database.service';

export class DatabasesOptions extends DatabasesConfig { }

export const DATABASES_FORROOT_GUARD = new InjectionToken<void>('DATABASES_FORROOT_GUARD');

export function provideDatabasesConfig(config: DatabasesOptions) {
	return Utils.object.defaults(config, {
		table: 'Databases',
		backupRestoreTable: 'BackupRestore',
		uriKey: 'database'
	});
}

@NgModule({})
export class KnDatabasesModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(DATABASES_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<DatabasesOptions>): ModuleWithProviders<KnDatabasesModule> {
		return {
			ngModule: KnDatabasesModule,
			providers: [
				...forRootGuardFactory('KnDatabasesModule', DATABASES_FORROOT_GUARD, DatabasesResourceService),
				{
					provide: DatabasesOptions, useValue: config ? config : {}
				}, {
					provide: DatabasesConfig,
					useFactory: provideDatabasesConfig,
					deps: [DatabasesOptions]
				},
				DatabasesResourceService,
				BackupDatabaseService
			]
		};
	}
}
