import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Response} from 'kn-http';
import {RestService, AbstractFetcher} from 'kn-rest';
import {DatabasesConfig} from './databases.config';

@Injectable()
export class BackupDatabaseService {
	protected _fetcher: AbstractFetcher<File>;

	public constructor(
			rest: RestService,
			private readonly _config: DatabasesConfig) {
		this._fetcher = rest.for<File>(this._config.backupRestoreTable);
	}

	public backup(databaseUid: string, gzip: boolean = false): Observable<File> {
		return this._fetcher.get(databaseUid, { gzip });
	}

	public restore(databaseUid: string, file: File): Observable<Response> {
		return this._fetcher.save(file, { [this._config.uriKey]: databaseUid });
	}
}
