import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Response} from 'kn-http';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {AbstractTemplatesClonerService} from './abstract-templates-cloner.service';
import {DefaultLoader} from './default-loader.type';
import {ViewTemplate} from 'common-web/model';
import {ViewTemplatesResourceService} from '../services/view-templates/view-templates-resource.service';

@Injectable()
export class ViewTemplatesDefaultLoaderService extends AbstractTemplatesClonerService<ViewTemplate> implements DefaultLoader {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			viewTemplatesResource: ViewTemplatesResourceService) {
		super({
			key: 'viewTemplate',
			target: { resource: viewTemplatesResource, query: { only: ['view', 'name'] } },
			source: { resource: new Resource<ViewTemplate>(rest, notifier, 'DefaultsData') }
		});
	}

	public loadDefaults(targetPrecursor: any): Observable<{ [key: string]: Response }> {
		return this.clone(targetPrecursor, { path: 'view-templates.json' });
	}
}
