import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService} from 'kn-rest';
import {UidResource, Formatters} from 'common-web/rest';
import * as Model from '../../model/customer-database.types';

@Injectable()
export class SitesResourceService extends UidResource<Model.Site> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(rest, notifier, 'Sites', {
			rules: [
				{ path: 'name', formatter: Formatters.slugify(40) }
			]
		});
	}
}
