import {ComponentRef} from '@angular/core';
import {CellValue, CellContext, ComponentRendererFactory, ComponentMappingParameters, ComponentRenderBinderRef} from 'kn-datagrid';
import {Utils} from 'kn-utils';

export interface DefaultTarget {
	renderDefaultTarget(): any;
}

export class LookupComponentRendererFactory extends ComponentRendererFactory {
	private readonly _lookup: { value: CellValue, columnId: string, component: ComponentRef<any> }[] = [];

	protected _connectComponent<C, T>(componentRef: ComponentRef<C>, params: ComponentMappingParameters<C, T>): ComponentRenderBinderRef<C, T> {
		const binder = super._connectComponent(componentRef, params);

		let item = this._lookup.find(x => x.value === params.value && x.columnId === params.context.column.id);
		if (item) {
			item.component = componentRef;
		}
		else {
			item = {
				value: params.value,
				columnId: params.context.column.id,
				component: componentRef
			};
			this._lookup.push(item);
		}
		componentRef.onDestroy(() => {
			const index = this._lookup.indexOf(item);
			if (index !== -1) {
				this._lookup.splice(index, 1);
			}
		});
		return binder;
	}

	protected _defaultRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		const item = this._lookup.find(x => x.value === value && x.columnId === context.column.id);
		const componentInstance = item && item.component.instance;
		if (componentInstance) {
			if (Utils.reflector.hasHook('renderDefaultTarget', componentInstance)) {
				return (componentInstance as DefaultTarget).renderDefaultTarget();
			}
		}
		return value;
	}
}
