import {Injectable} from '@angular/core';
import {Resource, RestService, RestChangeNotifierService} from 'kn-rest';
import * as Model from '../../model/web.types';

@Injectable()
export class ConnectionsResourceService extends Resource<Model.Connection> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(rest, notifier, 'Connections');
	}
}
