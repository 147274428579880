import {Component, ViewEncapsulation, Input, HostBinding, HostListener} from '@angular/core';
import {CellContext, RowItem} from '../../types';
import {RowsNumbering} from '../../services/rows-numbering.service';
import {SelectionService} from '../../services/selection.service';

@Component({
	selector: 'kn-numbered-selector',
	templateUrl: 'numbered-selector.html',
	styleUrls: ['numbered-selector.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnNumberedSelector {
	public constructor(
			private readonly _rowsNumbering: RowsNumbering,
			private readonly _selection: SelectionService) {
	}

	@Input() public context: CellContext<RowItem> = null;

	public get rowNumber(): number {
		return this._rowsNumbering.calculate(this.context.node).getForNode(this.context.node);
	}

	@HostBinding('class.force-display-selector')
	public get forceDisplaySelector(): boolean {
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
		return this.selected !== false;
	}

	public get selected(): boolean {
		return this._selection.isSelected(this.context.node);
	}

	public set selected(value: boolean) {
		// FIXME
		// this._selection.setSelect(this.context.node, value);
	}

	@HostListener('click', ['$event'])
	public clickHandler(event: Event) {
		// FIXME
		// this._selection.toggleSelect(this.context.node);
		// event.stopPropagation();
	}
}
