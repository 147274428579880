import {CoreUtils} from './core-utils';
import {TextUtils} from './text-utils';
import {ObjectUtils} from './object-utils';
import {ArrayUtils} from './array-utils';
import {DateUtils} from './date-utils';
import {MathUtils} from './math-utils';
import {TimespanUtils} from './timespan-utils';
import {ReflectorUtils} from './reflector-utils';

export class Utils extends CoreUtils {
	public static text = TextUtils;
	public static object = ObjectUtils;
	public static array = ArrayUtils;
	public static date = DateUtils;
	public static math = MathUtils;
	public static timespan = TimespanUtils;
	public static reflector = ReflectorUtils;
}
