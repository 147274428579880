import {AbstractAccessControl, AbstractAccessControlFactory, AccessRestriction} from './abstract-access-control';

export type AccessAction = {
	name: string;
	restrictions: { [key: string]: any[] };
};

export class StaticAccessControl extends AbstractAccessControl {
	public constructor(protected _actions: AccessAction[], private readonly _authenticated: boolean = true) {
		super();
	}

	public can(action: string, restrictions?: AccessRestriction) {
		return this._authenticated && this._actions.some(next => next.name === action && (restrictions === null || this._canRestrictions(next, restrictions)));
	}

	private _canRestrictions(action: AccessAction, restrictions: AccessRestriction) {
		for (const restrictionKey in restrictions) {
			if (restrictions.hasOwnProperty(restrictionKey) && action.restrictions.hasOwnProperty(restrictionKey)) {
				if (action.restrictions[restrictionKey].indexOf(null) !== -1) {
					continue;
				}
				if (Array.isArray(restrictions[restrictionKey])) {
					for (const key of restrictions[restrictionKey]) {
						if (action.restrictions[restrictionKey].indexOf(key) === -1) {
							return false;
						}
					}
				}
				else {
					if (action.restrictions[restrictionKey].indexOf(restrictions[restrictionKey]) === -1) {
						return false;
					}
				}
			}
		}
		return true;
	}
}

export class StaticAccessControlFactory extends AbstractAccessControlFactory {
	public get name() {
		return 'null';
	}

	public create(context: { [key: string]: any }, actions: AccessAction[]) {
		return new StaticAccessControl(actions, !!context.authenticated);
	}
}
