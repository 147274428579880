<div class="signin-user" *ngIf="isAuthenticated">
	<ng-container i18n>You are logged in as</ng-container>
	<em>{{ user?.fullName }}</em>
	<a href="javascript:void(0)" (click)="signOut($event)" i18n>Sign out</a>
</div>
<div class="signin-box">
	<img src="assets/img/logo-loading.svg" alt="" class="logo">
	<div class="box">
		<form (ngSubmit)="signIn()" [formGroup]="form" *knCollapse="!isAuthenticated" novalidate>
			<div class="form-input">
				<kn-input type="text" formControlName="username" required class="form-input">
					<span kn-label i18n>Name</span>
				</kn-input>
			</div>
			<div class="form-input">
				<kn-input type="password" formControlName="password" required class="form-input">
					<span kn-label i18n>Password</span>
				</kn-input>
			</div>
			<button type="submit" class="form-button" [disabled]="!form.valid" i18n>Sign in</button>
		</form>
		<div *knCollapse="isAuthenticated">
			<div class="database-selection">
				<kn-database-selector (selected)="databaseSelected($event.uid)"></kn-database-selector>
				<a routerLink="/management" i18n>Management →</a>
			</div>
		</div>
	</div>
	<p class="version">
		<ng-container i18n>version:</ng-container>
		<strong>{{ meta.version }}-{{ meta.build | decimal: '4.':false }}</strong>
		(<strong>{{ meta.date | date: 'dMyjjmm' }}</strong>)
	</p>
</div>

<footer>
	<p>
		<strong>Storisto</strong>
		<ng-container i18n>&copy; 2021 Kupson s.r.o., all rights reserved.</ng-container>
	</p>
	<a href="http://www.kupson.cz" class="company-logo"><img src="assets/img/kupson-logo.svg"></a>
</footer>
