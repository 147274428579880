import {NgModule, Optional, SkipSelf, ModuleWithProviders} from '@angular/core';
import {UriContext} from 'kn-http';
import {AbstractConcreteFetcher} from './fetchers/abstract-concrete-fetcher';
import {UriContextService} from './uri-context.service';
import {RestService} from './rest.service';
import {UriContextGuard} from './uri-context.guard';
import {RestChangeNotifierService} from './change-notification/rest-changes-notifier.service';
import {FETCHERS_TOKEN} from './fetchers.token';
import {AMBIENT_CONTEXT_TOKEN} from './ambient-context.token';

export class RestOptions {
	public context: UriContext;
	public fetchers: AbstractConcreteFetcher<any>[];
}

export function provideFetchers(config: RestOptions) {
	return config.fetchers;
}

export function provideAmbientContext(config: RestOptions) {
	return config.context;
}

export function provideUriContextService(context: UriContext) {
	return new UriContextService(context);
}

export function provideRestService(uriContext: UriContextService, changeNotifier: RestChangeNotifierService, fetchers: AbstractConcreteFetcher<any>[]) {
	return new RestService(uriContext, changeNotifier, fetchers);
}

@NgModule()
export class KnRestModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnRestModule) {
		if (parentModule) {
			throw new Error('KnRestModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config?: Partial<RestOptions>): ModuleWithProviders<KnRestModule> {
		return {
			ngModule: KnRestModule,
			providers: [
				UriContextGuard,
				RestChangeNotifierService,
				{
					provide: RestOptions, useValue: config ? config : {}
				}, {
					provide: FETCHERS_TOKEN,
					useFactory: provideFetchers,
					deps: [RestOptions]
				}, {
					provide: AMBIENT_CONTEXT_TOKEN,
					useFactory: provideAmbientContext,
					deps: [RestOptions]
				}, {
					provide: UriContextService,
					useFactory: provideUriContextService,
					deps: [AMBIENT_CONTEXT_TOKEN]
				}, {
					provide: RestService,
					useFactory: provideRestService,
					deps: [UriContextService, RestChangeNotifierService, FETCHERS_TOKEN]
				}
			]
		};
	}
}
