export class AppConfig {
	public apiUrl: string;
	public dataUrl: string;
	public devicesUrl: string;
	public signinPath: string;
	public defaultDatabasePrefix: string;
	public defaultProvider: string;
	public defaultCountryCode: string;
}

const baseUrl = window.location.hostname + (window.location.port ? ':' + window.location.port : '');
const isLocalhost = ['localhost', '127.0.0.1'].indexOf(window.location.hostname) !== -1;
export const appConfig: AppConfig = {
	apiUrl: '//' + baseUrl + '/api',
	dataUrl: '//' + baseUrl + '/assets/data',
	devicesUrl: '//' + baseUrl + '/devices',
	signinPath: 'signin',
	defaultDatabasePrefix: 'storisto',
	defaultProvider: isLocalhost ? 'SQLite' : 'PostgreSQL',
	defaultCountryCode: 'CZE'
};
