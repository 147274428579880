import {AbstractElementBreaker} from './abstract-element-breaker';
import {BreakableType, NodeBreakables} from '../types';

enum RowspanState {
	None,
	Begin,
	End,
	Inside
}

export class TableElementBreaker extends AbstractElementBreaker {
	private readonly _rowspanCache = new WeakMap<HTMLTableRowElement, RowspanState>();

	private static readonly _tableDisplayProperties = [
		'table',
		'table-cell',
		'table-column',
		'table-column-group',
		'table-footer-group',
		'table-header-group',
		'table-row',
		'table-row-group',
		'table-caption'
	];

	public isSupported(node: Node): boolean {
		if (node.nodeType !== Node.ELEMENT_NODE) {
			return false;
		}
		const display = window.getComputedStyle(node as HTMLElement).display;
		return TableElementBreaker._tableDisplayProperties.indexOf(display) !== -1;
	}

	public getBreakables(node: Node): NodeBreakables {
		const breakables = {
			before: BreakableType.Avoid,
			inside: BreakableType.Avoid,
			after: BreakableType.Avoid
		};

		switch (node.nodeType) {
			case Node.ELEMENT_NODE:
				const style = window.getComputedStyle(node as HTMLElement);
				switch (style.display) {
					case 'table':
					case 'table-row-group':
						breakables.inside = BreakableType.Auto;
						breakables.before = this._cssBreakToBreakable(style.pageBreakBefore, style.breakBefore);
						breakables.after = this._cssBreakToBreakable(style.pageBreakAfter, style.breakAfter);
						break;

					case 'table-row':
						switch (this._getRowspanState(node)) {
							case RowspanState.None:
								breakables.before = this._cssBreakToBreakable(style.pageBreakBefore, style.breakBefore);
								breakables.after = this._cssBreakToBreakable(style.pageBreakAfter, style.breakAfter);
								break;
							case RowspanState.Begin:
								breakables.before = this._cssBreakToBreakable(style.pageBreakBefore, style.breakBefore);
								break;
							case RowspanState.End:
								breakables.after = this._cssBreakToBreakable(style.pageBreakAfter, style.breakAfter);
								break;
						}
						break;
				}
				break;

			case Node.TEXT_NODE:
				break;

			default:
				return null;
		}

		return breakables;
	}

	private _getRowspanState(node: Node): RowspanState {
		if (node.nodeType !== Node.ELEMENT_NODE
					|| window.getComputedStyle(node as HTMLElement).display !== 'table-row') {
			return RowspanState.None;
		}
		if (!this._rowspanCache.has(node as HTMLTableRowElement)) {
			this._calculateRowspans(node.parentNode);
		}
		return this._rowspanCache.get(node as HTMLTableRowElement);
	}

	private _calculateRowspans(node: Node) {
		let rowspanCounter = 0;
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < node.childNodes.length; i++) {
			const child = node.childNodes[i];
			if (child.nodeType === Node.ELEMENT_NODE
					&& window.getComputedStyle(child as HTMLElement).display === 'table-row') {
				const rowspan = this._getRowspan(child);
				if (rowspan === 1) {
					this._rowspanCache.set(child as HTMLTableRowElement, RowspanState.None);
				}
				else if (rowspan > 1) {
					this._rowspanCache.set(child as HTMLTableRowElement, RowspanState.Begin);
				}
				else if (rowspanCounter === 1) {
					this._rowspanCache.set(child as HTMLTableRowElement, RowspanState.End);
				}
				else if (rowspanCounter > 1) {
					this._rowspanCache.set(child as HTMLTableRowElement, RowspanState.Inside);
				}
				else {
					this._rowspanCache.set(child as HTMLTableRowElement, RowspanState.None);
				}
				if (rowspan >= 1) {
					rowspanCounter = rowspan;
				}
				if (rowspanCounter > 0) {
					rowspanCounter--;
				}
			}
		}
	}

	private _getRowspan(node: Node): number {
		let rowspan = 0;
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < node.childNodes.length; i++) {
			const child = node.childNodes[i];
			if (child.nodeType === Node.ELEMENT_NODE
					&& window.getComputedStyle(child as HTMLElement).display === 'table-cell') {
				const attr = (child as Element).attributes.getNamedItem('rowspan');
				const currentRowspan = attr == null ? 0 : +attr.value;
				if (rowspan < currentRowspan) {
					rowspan = currentRowspan;
				}
			}
		}
		return rowspan;
	}

	protected _remove(parent: Node, node: Node) {
		if (node.nodeType === Node.ELEMENT_NODE) {
			const style = window.getComputedStyle(node as HTMLElement);
			if (['table-footer-group', 'table-header-group'].indexOf(style.display) !== -1) {
				return false;
			}
		}
		parent.removeChild(node);
		return true;
	}
}
