import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'filter' })
export class FilterPipe implements PipeTransform {
	public transform(value: any[], filter: { [key: string]: string }): any[] {
		if (value == null) {
			return null;
		}

		const filterKeys = Object.keys(filter);
		return value.filter(item =>
			filterKeys.reduce((prev, key) => prev && Utils.object.get(item, key) === filter[key], true));
	}
}
