import {Injector, LOCALE_ID} from '@angular/core';
import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {ContractDescription as GridDescription, ContractModel as GridModel, Renderers, RendererFactory, EnumRendererFactory, Aggregators} from 'kn-datagrid';
import {ContractDescription as FilterDescription, ContractModel as FilterModel, BooleanOperator as FilterBooleanOperator} from 'kn-query-filter';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {AbstractResource} from 'kn-rest';
import {AbstractTagger} from './taggers/abstract-tagger';
import {DecimalRendererFactory} from './renderers/decimal-renderer-factory';
import {DateRendererFactory} from './renderers/date-renderer-factory';
import {BoolRendererFactory} from './renderers/bool-renderer-factory';
import {EmailRendererFactory} from './renderers/email-renderer-factory';
import {TagsRendererFactory} from './renderers/tags-renderer-factory';
import {HslTagsRendererFactory} from './renderers/hsl-tags-renderer-factory';
import {TitleDecorationRendererFactory} from './renderers/title-decoration-renderer-factory';
import {LookupComponentRendererFactory} from './renderers/lookup-component-renderer-factory';
import {DataDescription, DataModel, Context} from './types';
import {QueryFilterExpanderService} from './query-filter-expander.service';

export type _boolI18N =
	/* i18n */ 'true' |
	/* i18n */ 'false';

export abstract class AbstractGridData<T extends {}> {
	protected _i18n: I18nService;
	protected _queryExpander: QueryFilterExpanderService;

	private readonly _resolve: Observable<void>[] = [];
	private readonly _aggregators = new Aggregators<T>();
	private readonly _renderersFactory = new Renderers();
	private readonly _taggers: AbstractTagger<T>[] = [];
	private readonly _rowClassesCache = new WeakMap<T, string[]>();

	private static readonly _datagridPrefix = 'grid';
	private static readonly _queryFilterPrefix = 'filter';

	public context: Context<T>;

	public constructor(injector: Injector) {
		this._i18n = injector.get(I18nService);
		this._queryExpander = injector.get(QueryFilterExpanderService);

		const decimal = new DecimalRendererFactory(injector.get(LOCALE_ID));
		this._registerRenderer('decimal', decimal);

		const date = new DateRendererFactory(injector.get(LOCALE_ID));
		this._registerRenderer('date', date);

		const bool = new BoolRendererFactory(
			'<img src="/assets/img/checkbox-checked.svg" title="{{ title }}">',
			'<img src="/assets/img/checkbox.svg" title="{{ title }}">',
			'<img src="/assets/img/checkbox-indeterminate.svg" title="{{ title }}">');
		bool.register('print', value => this._i18n.t(value == null ? '-' : value.toString()));
		this._registerRenderer('bool', bool);

		const enumeration = new EnumRendererFactory();
		enumeration.register('export:csv', value => value);
		enumeration.register('export:xlsx', value => value);
		enumeration.register('print', value => this._i18n.t(value as string));
		this._registerRenderer('enum', enumeration);

		const email = new EmailRendererFactory();
		this._registerRenderer('email', email);

		const tags = new TagsRendererFactory();
		this._registerRenderer('tags', tags);

		const hslTags = new HslTagsRendererFactory();
		this._registerRenderer('hslTags', hslTags);

		const titleDecoration = new TitleDecorationRendererFactory();
		this._registerRenderer('titleDecoration', titleDecoration);

		const component = new LookupComponentRendererFactory();
		this._registerRenderer('component', component);

		this.context = { executeCommand: async() => Promise.resolve(false) };
	}

	protected abstract _createDescription(): DataDescription<T>;
	protected abstract _createModel(): DataModel;

	public get renderers() {
		return this._renderersFactory.build();
	}

	public get aggregators() {
		return this._aggregators;
	}

	protected _registerResolve(resolve: Observable<any>) {
		this._resolve.push(resolve.pipe(Rx.mapTo(undefined)));
	}

	protected _registerRenderer(name: string, factory: RendererFactory) {
		this._renderersFactory.add(name, factory);
	}

	protected _registerRowTagger(tagger: AbstractTagger<T>) {
		this._taggers.push(tagger);
	}

	public get datagridDescription(): GridDescription<T> {
		return this._extractDatagridDescription();
	}

	public get datagridModel(): GridModel {
		return this._extractDatagridModel();
	}

	public get queryFilterDescription(): FilterDescription {
		return this._extractQueryFilterDescription();
	}

	public get queryFilterModel(): FilterModel {
		return this._extractQueryFilterModel();
	}

	public get queryBackgroundFilterModel(): FilterModel {
		return this._extractQueryFilterModel(true);
	}

	public get resolve(): Observable<void>[] {
		return this._resolve;
	}

	protected _extractDatagridDescription(): GridDescription<T> {
		const compound = (item: { [key: string]: any }) => this._compoundProperties(
				item, AbstractGridData._datagridPrefix, AbstractGridData._queryFilterPrefix);
		const description = this._createDescription();
		return {
			renderMode: description.gridRenderMode,
			selectionMode: description.gridSelectionMode,
			mutalSorting: description.gridMutalSorting,
			rowsReordering: description.gridRowsReordering,
			columnsReordering: description.gridColumnsReordering,
			rows: compound(description.rows),
			columns: description.columns.map(compound),
			sections: description.sections && description.sections.map(compound)
		} as GridDescription<T>;
	}

	protected _extractDatagridModel(): GridModel {
		const compound = (item: { [key: string]: any }) => this._compoundProperties(
				item, AbstractGridData._datagridPrefix, AbstractGridData._queryFilterPrefix);
		const model = this._createModel();
		return {
			columns: model.columns.map(compound),
			sections: model.sections && model.sections.map(compound)
		} as GridModel;
	}

	protected _extractQueryFilterDescription(): FilterDescription {
		const compound = (item: { [key: string]: any }) => this._compoundProperties(
				item, AbstractGridData._queryFilterPrefix, AbstractGridData._datagridPrefix);
		const description = this._createDescription();
		return {
			options: description.columns.map(compound).filter(x => x.hasOwnProperty('type'))
		} as FilterDescription;
	}

	protected _extractQueryFilterModel(background: boolean = false): FilterModel {
		const compound = (item: { [key: string]: any }) => this._compoundProperties(
				item, AbstractGridData._queryFilterPrefix, AbstractGridData._datagridPrefix);
		const model = this._createModel();
		const children = model.columns
			.map(compound)
			.filter(x => x.hasOwnProperty('operator') && (x['background'] || false) === background);
		children.forEach(x => delete x['background']);
		return {
			operator: FilterBooleanOperator.And,
			children: children
		} as FilterModel;
	}

	private _compoundProperties(src: { [key: string]: any }, mergePrefix: string, ignorePrefix: string) {
		const target: { [key: string]: any } = {};
		for (const key in src) {
			if (src.hasOwnProperty(key)) {
				if (key.indexOf(mergePrefix) === 0) {
					const targetKey = key[mergePrefix.length].toLowerCase()
							+ key.substr(mergePrefix.length + 1);
					target[targetKey] = src[key];
				}
				else if (key.indexOf(ignorePrefix) !== 0) {
					target[key] = src[key];
				}
			}
		}
		if (target.hasOwnProperty('children')) {
			target['children'] = target['children'].map((x: { [key: string]: any }) =>
				this._compoundProperties(x, mergePrefix, ignorePrefix));
		}
		return target;
	}

	protected _getOrEvaluateRowClasses(item: T) {
		if (!this._rowClassesCache.has(item)) {
			this._rowClassesCache.set(item, this._evaluateRowClasses(item));
		}
		return this._rowClassesCache.get(item);
	}

	private _evaluateRowClasses(item: T) {
		return this._taggers.reduce((acc, x) => acc.concat(x.evaluate(item)), []);
	}

	public optionsFromResource<U>(resource: AbstractResource<U>, labelProperty: keyof U | { (item: U): string }, valueProperty: keyof U, context?: { [key: string]: any }, allowNull: boolean = true): Observable<{ label: string, value: string | number }[]> {
		context = context || {};
		if (Utils.isString(labelProperty)) {
			Utils.object.initStructure(context, { query: { only: [] } });
			context['query']['only'] = [labelProperty, valueProperty];
		}
		return resource.query(context).pipe(
			Rx.map(next => next.map(x => {
				const label = (Utils.isString(labelProperty)
						? x[labelProperty as keyof U]
						: (labelProperty as (item: U) => string)(x)) as string;
				const value = x[valueProperty];
				return { label, value: Utils.isNumber(value) ? value as any as number : `${value}` };
			})),
			Rx.tap(next => {
				if (allowNull && next.every(x => x.value != null)) {
					next = next || [];
					next.unshift({ label: this._i18n.t('Unassigned'), value: null });
				}
			}),
			Rx.publishReplay(1),
			Rx.refCount()
		);
	}

	public optionsFromEnum<U extends {} | string[]>(enumeration: U): { label: string, value: string }[] {
		const keys: string[] = (Array.isArray(enumeration) ? enumeration : Object.values(enumeration));
		return keys.map(x => ({ label: this._i18n.t(x), value: x }));
	}
}
