import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Utils} from 'kn-utils';
import {DatagridBond, Column, RowItem, CellContext} from 'kn-datagrid';

@Component({
	selector: 'kn-grid-search',
	template: '<input type="search" i18n-placeholder placeholder="Search" [class.active]="searchText" [ngModel]="searchText" (ngModelChange)="search($event)">'
})
export class KnGridSearch implements OnChanges {
	public searchText = '';
	private _query = '';

	@Input() public bond: DatagridBond<any>;

	public ngOnChanges(changes: SimpleChanges) {
		if ('bond' in changes) {
			const columns = this.bond.model.columns.map(x => {
				return {
					id: x.id,
					model: x,
					description: this.bond.description.columns.find(c => c.id === x.id),
					section: null
				} as Column<RowItem>;
			}).filter(c => (c.description as any).exportable !== false);

			// FIXME: https://gitlab.kupson.cz/software-web/avp-portal-web/issues/20
			this.bond.description.rows.visible = x => {
				if (this._query == null || this._query === '') {
					return true;
				}
				for (const column of columns.filter(c => c.model.visible)) {
					const value = column.description.accessor(x, column);
					const cellContext = {
						node: null,
						column: column
					} as CellContext<RowItem>;
					const cellText = column.description.renderer(value, cellContext, 'search');
					if (Utils.text.latinise(((cellText || value) + '').toLowerCase()).indexOf(this._query) !== -1) {
						return true;
					}
				}
				return false;
			};
		}
	}

	public search(query: string) {
		this.searchText = query;
		this._query = query === '' ? null : Utils.text.latinise(query.toLowerCase());
		this.bond.refreshRows();
	}
}
