import {Observable, combineLatest as observableCombineLatest, merge as observableMerge, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Response, UriContext} from 'kn-http';
import {ChangeEntry} from 'kn-rest';
import {AbstractDependentEntitiesStore, EntityUtils} from 'common-web/rest';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import {UserRolesResourceService} from '../../services/users/user-roles-resource.service';
import {UsersViewConfig} from './users-view.config';
import * as CommonModel from '../../model/common-database.types';
import * as WebModel from '../../model/web.types';

export class DatabasesUserRolesStore extends AbstractDependentEntitiesStore<WebModel.DatabaseUserRoles> {
	public constructor(
			private readonly _databasesResource: DatabasesResourceService,
			private readonly _userRolesResource: UserRolesResourceService,
			private readonly _config: UsersViewConfig) {
		super('userUid');
	}

	public get changes(): Observable<ChangeEntry> {
		return EntityUtils.mergeChanges(this._databasesResource, this._userRolesResource);
	}

	public save(ancestorUid: string, items: WebModel.DatabaseUserRoles[]): Observable<Response[]> {
		items.forEach(x => x.userRoles.forEach(y => y.userUid = ancestorUid));
		return super.save(ancestorUid, items);
	}

	protected _query(context: UriContext) {
		return this._databasesResource.query({ query: { only: ['id', 'uid'] } }).pipe(
			Rx.map(next => [{ id: null as number, uid: 'master' }].concat(next)),
			Rx.switchMap(next => {
				const fetchers$ = next.map(x => {
					const ctx = Object.assign({ [this._config.databaseUriKey]: x.uid }, context);
					const userRoles$ = this._userRolesResource.query(ctx).pipe(this._catchForbidden());
					return observableCombineLatest(observableOf(x.id), userRoles$);
				});
				return observableMerge(...fetchers$);
			}),
			Rx.filter(next => next[1].length !== 0),
			Rx.reduce<[number, CommonModel.UserRole[]], WebModel.DatabaseUserRoles[]>(
				(acc, next) => acc.concat({ id: null, databaseId: next[0], userRoles: next[1] }),
				[]
			)
		);
	}

	protected _save(items: WebModel.DatabaseUserRoles[], context: UriContext) {
		return this._databasesResource.query({ query: { only: ['id', 'uid'] } }).pipe(
			Rx.map(next => [{ id: null, uid: 'master' }].concat(next)),
			Rx.switchMap<{ id: number, uid: string }[], Response[][]>(next => {
				const savers$ = next.map(db => {
					const item = items.find(x => this._idEquals(x.databaseId, db.id));
					const ctx = Object.assign({ [this._config.databaseUriKey]: db.uid }, context);
					if (item != null) {
						return this._saveUserRoles(item.userRoles, ctx);
					}
					return this._userRolesResource.remove(ctx).pipe(this._catchForbidden()) as Observable<Response[]>;
				});
				return observableCombineLatest(savers$) as any; // FIXME: types
			})
		) as any as Observable<Response[]>; // FIXME: types
	}

	protected _remove(context: UriContext) {
		return this._databasesResource.query({ query: { select: 'uid' } }).pipe(
			Rx.map(next => [
				Object.assign({ [this._config.databaseUriKey]: 'master' }, context),
				...next.map(x => Object.assign({ [this._config.databaseUriKey]: x }, context))
			]),
			Rx.switchMap(next => {
				const removers$ = next.map(x => this._userRolesResource.remove(x));
				return observableMerge(...removers$).pipe(Rx.last());
			})
		);
	}

	private _saveUserRoles(items: CommonModel.UserRole[], context: UriContext) {
		const managedReferences = Object.keys(this._userRolesResource.getReferences());
		const supplements = items.map(x => EntityUtils.exceptReferences(x, managedReferences));
		const savingContext = Object.assign({}, context);
		delete savingContext['query'];
		return this._userRolesResource.query(context).pipe(
			this._catchForbidden(),
			Rx.switchMap(x => {
				const diff = EntityUtils.calculateDiff(x, items);
				return EntityUtils.saveDiff(this._userRolesResource, diff, savingContext);
			}),
			Rx.toArray(),
			Rx.finalize(() => items.forEach((x, i) => Object.assign(x, supplements[i])))
		);
	}

	private _idEquals(a: number, b: number) {
		return a === b || (a == null && b == null);
	}

	private _catchForbidden() {
		return Rx.catchError(error => {
			if (error.status === 403) {
				return [];
			}
			throw error;
		});
	}
}
