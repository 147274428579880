import {Component, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import {KnModal} from '../modal.component';

export type ConfirmationResult = any;

export interface ButtonDescriptor {
	name: string;
	classes?: string[];
	result?: ConfirmationResult;
}

@Component({
	selector: 'kn-confirmation',
	template: `
	<kn-modal class="confirmation-dialog" (closed)="close()">
		<div class="content">
			<strong>{{ message }}</strong>
			<span *ngIf="details">{{ details }}</span>
		</div>
		<div class="buttons">
			<button type="button" *ngFor="let button of buttons"
				[attr.class]="button?.classes | join: ' '"
				(click)="confirm(button.result)">{{ button.name }}</button>
		</div>
	</kn-modal>`
})
export class KnConfirmation {
	@Input() public message: string;
	@Input() public details: string;
	@Input() public buttons: ButtonDescriptor[];
	@Output() public choose: EventEmitter<ConfirmationResult> = new EventEmitter<ConfirmationResult>();

	@ViewChild(KnModal, { static: true })
	private readonly _modal: KnModal;

	public get isOpen(): boolean {
		return this._modal.isOpen;
	}

	public set isOpen(value: boolean) {
		this._modal.isOpen = value;
	}

	public open(): void {
		this._modal.open();
	}

	public close(): void {
		this._close(undefined);
	}

	public confirm(result: ConfirmationResult) {
		this._close(result);
	}

	private _close(result: ConfirmationResult) {
		this.choose.emit(result);
		if (this.isOpen) {
			this._modal.close();
		}
	}
}
