import {Injector, ElementRef} from '@angular/core';
import {ElementRenderer, ElementRendererFactory, CellContext} from 'kn-datagrid';
import {Utils} from 'kn-utils';

export class TagsRendererFactory extends ElementRendererFactory {
	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, classes?: string | string[]): ElementRenderer<T> {
		return (element: ElementRef, value: string[], context: CellContext<any>, firstChange: boolean): string => {
			const classAttr = this._classesToAttr(classes);
			const tags = value.map(x => ({ value: x, attrs: [classAttr] }));
			return this._render(tags);
		};
	}

	protected _render(tags: { value: string, attrs: string[] }[]): any {
		const html = tags
			.map(x => [x.value, x.attrs.filter(attr => attr).map(attr => ' ' + attr).join('')])
			.map(x => `<span${x[1]}>${x[0]}</span>`)
			.join('');
		return html;
	}

	protected _classesToAttr(classes: string | string[]) {
		if (classes == null || classes.length === 0) {
			return '';
		}
		return 'class="' + Utils.array.box(classes).join(' ') + '"';
	}
}
