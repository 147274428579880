<label [attr.for]="inputId" #knLabelContent>
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required && hasKnLabel">*</span>
</label>

<div class="kn-input-container" knRipple [knRippleDisabled]="disabled">
	<div class="kn-input-prefix">
		<ng-content select="[kn-prefix]"></ng-content>
	</div>

	<input #input
			[id]="inputId"
			[type]="type"
			[attr.placeholder]="placeholder"
			[autofocus]="autofocus"
			[disabled]="disabled"
			[readonly]="readonly"
			[required]="required"
			[spellcheck]="spellcheck"
			[attr.autocomplete]="autocomplete"
			[attr.list]="list"
			[attr.max]="max"
			[attr.maxlength]="maxlength"
			[attr.min]="min"
			[attr.minlength]="minlength"
			[attr.step]="step"
			[attr.tabindex]="tabindex"
			[attr.name]="name"
			[(ngModel)]="valueInternal"
			(input)="handleFilterInput(input.value)"
			(change)="handleChange($event)"
			(keyup)="handleKeyup($event)"
			(keydown)="handleKeydown($event)">

	<div class="kn-input-suffix">
		<ng-content select="[kn-suffix]"></ng-content>
	</div>
</div>

<div class="kn-input-underline"></div>

<kn-options-container *ngIf="renderContainer" [hints]="hints">
	<ng-content select="kn-optgroup, kn-option"></ng-content>
</kn-options-container>

<ng-content></ng-content>

<ng-content select="[kn-hint]"></ng-content>
