import {Observable, Subject, ReplaySubject, of as observableOf, interval as observableInterval} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {AbstractStoreBackend} from './backends/abstract-store-backend';
import {Utils} from 'kn-utils';
import {UserSettingsFactory} from './user-settings-factory.service';
import {PropertyAccessor} from './property-accessor';
import {SettingsData, SettingsValues, SettingsItem} from './types';
import {indexOfSettingsItem} from './utils';

export type PropertyContainer = {
	accessor: PropertyAccessor<any>;
	item: SettingsItem;
	alternatives: SettingsItem[];
};

export abstract class AbstractUserSettingsService {
	private readonly _saved: SettingsData = {};
	protected _properties: PropertyContainer[] = [];
	protected _disposables: { (): void }[] = [];
	protected _changesSubject$ = new ReplaySubject<SettingsValues>(1);
	protected _changes$ = this._changesSubject$.asObservable();
	protected _flushSubject$ = new Subject<void>();

	public constructor(protected _factory: UserSettingsFactory, private readonly _origin: any) {
		const flushSubscription = this._flushSubject$
			.pipe(
				Rx.debounceTime(this._factory.config.debounceTime),
				Rx.mergeMap(() => this.flush().pipe(
					Rx.catchError(err => observableOf(err)),
					Rx.retry(this._factory.config.retry)
				))
			)
			.subscribe();
		this._disposables.push(() => flushSubscription.unsubscribe());

		if (this._factory.config.autoFlush) {
			this._changesSubject$.pipe(Rx.mapTo(null)).subscribe(this._flushSubject$);
		}

		if (this._factory.config.flushInterval) {
			const intervalSubscription = observableInterval(this._factory.config.flushInterval)
				.pipe(Rx.mapTo(null))
				.subscribe(this._flushSubject$);
			this._disposables.push(() => intervalSubscription.unsubscribe());
		}

		const discriminatorProvider = this._factory.getDiscriminatorProvider();
		const discriminatorSubscription = discriminatorProvider.discriminatorChange
			.subscribe(() => this.relink());
		this._disposables.push(() => discriminatorSubscription.unsubscribe());
	}

	public get origin() {
		return this._origin;
	}

	public get changes(): Observable<SettingsValues> {
		return this._changes$;
	}

	public relink() { /* intentionally empty */ }

	public get<T>(key: string): PropertyAccessor<T> {
		const container = this._properties.find(x => x.accessor.key === key);
		return container && container.accessor;
	}

	public fetch(): Observable<void> {
		const fetch$ = this._fetch(this._properties.map(x => x.accessor.key)).pipe(
			Rx.tap(next => {
				for (const key in next) {
					if (next.hasOwnProperty(key)) {
						const property = this._properties.find(x => x.accessor.key === key);
						property && this._update(property, next[key]);
					}
				}
			}),
			Rx.tap(next => this._changesSubject$.next(next)),
			Rx.mapTo(null)
		);
		const sharedFetch$ = Rx.publish<void>()(fetch$);
		sharedFetch$.connect();
		return sharedFetch$;
	}

	public flush(): Observable<void> {
		const data = this._properties.map(x => ({ [x.accessor.key]: [x.item] }))
			.reduce((acc, x) => Object.assign(acc, x), {});
		const flush$ = this._flush(data).pipe(Rx.mapTo(null));
		const sharedFetch$ = Rx.publish<void>()(flush$);
		sharedFetch$.connect();
		return sharedFetch$;
	}

	protected _fetch(keys: string[]): Observable<SettingsData> {
		let fetcher$ = observableOf<SettingsData>({});
		for (const backend of this._factory.backends) {
			const scopedBackend = backend;
			fetcher$ = fetcher$
				.pipe(Rx.switchMap(next => this._fetchFromBackend(keys, scopedBackend, next)));
		}
		return fetcher$
			.pipe(Rx.tap(next => Object.assign(this._saved, Utils.clone(next, true))));
	}

	private _fetchFromBackend(keys: string[], backend: AbstractStoreBackend, data: SettingsData) {
		const loadedKeys = Object.keys(data);
		const missingKeys = keys.filter(x => loadedKeys.indexOf(x) === -1);
		return backend.load(missingKeys).pipe(Rx.map(next => Object.assign(data, next)));
	}

	protected _flush(data: SettingsData): Observable<SettingsData> {
		const unsaved: SettingsData = {};
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (this._saved[key] == null) {
					unsaved[key] = data[key];
					continue;
				}
				for (const item of data[key]) {
					const index = indexOfSettingsItem(this._saved[key], item);
					if (index !== -1
							&& (!Utils.equal(this._saved[key][index].value, item.value)
							|| this._saved[key][index].version !== item.version)) {
						const dataToSave = Object.assign(item, { id: this._saved[key][index].id });
						if (unsaved[key] == null) {
							unsaved[key] = [dataToSave];
						}
						else {
							unsaved[key].push(dataToSave);
						}
					}
				}
			}
		}
		return this._save(unsaved);
	}

	private _save(data: SettingsData) {
		if (!Object.keys(data).length) {
			return observableOf({});
		}
		let saver$ = observableOf<SettingsData>(data);
		for (let i = this._factory.backends.length - 1; i >= 0; i--) {
			saver$ = saver$.pipe(Rx.switchMap(next => this._factory.backends[i].save(next)));
		}
		return saver$.pipe(
			Rx.tap((next: SettingsData) => {
				for (const key in next) {
					if (next.hasOwnProperty(key)) {
						this._saved[key] = Utils.clone(next[key], true);
					}
				}
			}),
			Rx.map(next => {
				const saved: SettingsData = {};
				const unsaved = data;
				for (const key in next) {
					if (next.hasOwnProperty(key)) {
						delete unsaved[key];
						saved[key] = next[key];
					}
				}
				if (Object.keys(unsaved).length > 0) {
					throw unsaved;
				}
				return saved;
			})
		);
	}

	private _addNewSettingsItem(key: string, item: SettingsItem) {
		if (this._saved[key] == null) {
			Object.assign(this._saved, { [key]: [Utils.clone(item, true)] } );
			return;
		}
		const setIdx = indexOfSettingsItem(this._saved[key], item);
		if (setIdx === -1) {
			this._saved[key].push(Utils.clone(item, true));
		}
		else {
			this._saved[key][setIdx] = item;
		}
	}

	protected _discriminate(key: string, discriminator: string, candidates: SettingsItem[], discriminatorArg: string) {
		const strategy = this._factory.getDiscriminateStrategy(key, discriminator, discriminatorArg);
		return strategy.discriminate(key, discriminator, candidates, discriminatorArg);
	}

	protected _migrate(key: string, targetVersion: string, item: SettingsItem, discriminator: string) {
		const strategy = this._factory.getMigrationStrategy(key, targetVersion);
		const target = strategy.migrate(key, targetVersion, item);
		return Object.assign(item || {} as SettingsItem, {
			version: targetVersion,
			discriminator: (target && target.discriminator) || (item && item.discriminator) || discriminator,
			value: target && target.value
		});
	}

	protected _update(property: PropertyContainer, items: SettingsItem[]) {
		const discriminator = this._factory.getDiscriminatorProvider().discriminator(property.accessor.key, property.accessor.version, property.accessor.discriminatorArg);
		let activeItem = this._discriminate(property.accessor.key, discriminator, items, property.accessor.discriminatorArg);
		activeItem = this._migrate(property.accessor.key, property.accessor.version, activeItem, discriminator);
		const index = indexOfSettingsItem(items, activeItem);
		if (index !== -1) {
			property.item = items.splice(index, 1)[0];
		}
		else {
			this._addNewSettingsItem(property.accessor.key, activeItem);
			property.item = activeItem;
		}
		property.alternatives = items;
		property.accessor.value = property.item.value;
	}

	protected _createPropertyContainer(key?: string, version?: string, discriminatorArg?: any) {
		return {
			accessor: null,
			item: {
				id: null,
				value: null,
				discriminator: this._factory.getDiscriminatorProvider().discriminator(key, version, discriminatorArg),
				version: null
			},
			alternatives: []
		} as PropertyContainer;
	}

	public dispose() {
		this._disposables.forEach(x => x());
		if (this._factory != null) {
			const factory = this._factory;
			this._factory = null;
			factory.unlink(this);
		}
	}
}
