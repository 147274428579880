import {Injectable} from '@angular/core';
import {Observable, Subscription, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {AbstractModuleLoader} from 'kn-common';
import {saveAs} from 'file-saver';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {PrintConfiguration, CsvBackendConfiguration} from '../types';

export interface CsvPrintBackendOptions extends AbstractPrintBackendOptions {
	saveAs?: string;
}

@Injectable()
export class CsvPrintBackend extends AbstractPrintBackend {
	private static readonly _htmlCommentsRegExp = /<!--[\s\S]*?-->/g;
	private static readonly _emptyLineRegExp = /^\s*[\r\n]/gm;
	private static readonly _htmlDecomposingRegExp = /^\s*(<!doctype\s+[^>]+>)?\s*<html>\s*(?:<head>([\s\S]*)<\/head>)?\s*(?:<body>\s*(?:<table>(\s*(?:<thead>([\s\S]*)<\/thead>)?\s*(?:<tbody>([\s\S]*)<\/tbody>)?\s*)<\/table>)?\s*<\/body>)?\s*<\/html>\s*$/i;
	private static readonly _thMatch = /<th>([\s\S]*?)<\/th>/gi;
	private static readonly _trMatch = /<tr>([\s\S]*?)<\/tr>/gi;
	private static readonly _tdMatch = /<td>([\s\S]*?)<\/td>/gi;
	private static readonly _tagClean = /<\/?[^>]*>/g;

	private readonly _subscription: Subscription;

	public constructor(moduleLoader: AbstractModuleLoader) {
		super();
/*
		// tslint:disable:promise-function-async
		const options: HyPdfOptions = {
			fetcher: this._fetch.bind(this)
		};

		const load = (key: string, x: string) => {
			return moduleLoader.import<any>(x).then((dep: any) => ({ [key]: dep }));
		};

		const hypdfProvider$ = Observable.from([
				['pdfkit', 'pdfkit'],
				['blobstream', 'blob-stream'],
				['html2canvas', 'html2canvas']
			])
			.mergeMap(x => load(x[0], x[1]))
			.reduce((acc, value) => Object.assign(acc, value), {})
			.map(x => new HyPdf(Object.assign(options, x)))
			.publishReplay(1);
		// tslint:enable:promise-function-async

		this._hypdfProviderFunctor$ = () => {
			this._subscription = this._subscription || hypdfProvider$.connect();
			return hypdfProvider$;
		};
*/
	}

	public get name(): string {
		return 'csv';
	}

	public print(text: string, config?: PrintConfiguration, options?: CsvPrintBackendOptions): Observable<any> {
		return observableOf(this.convertHtml(text, config))
			.pipe(
				Rx.map(next => new Blob([next], { type: 'text/plain;charset=utf-8;' })),
				Rx.tap(blob => this._save(blob, options))
			);
	}

	public printPreview(text: string, config?: PrintConfiguration, options?: CsvPrintBackendOptions): Observable<any> {
		return this.print(text, config, options);
	}

	public destroy() {
		this._subscription && this._subscription.unsubscribe();
	}
/*
	private async _fetch(uri: string, binary: boolean): Promise<ArrayBuffer | string> {
		const options = {
			responseType: binary ? 'arraybuffer' : 'text'
		};
		return this._http.get(uri, options)
			.map(next => next.body)
			.toPromise();
	}
*/
	private _save(data: Blob, options?: CsvPrintBackendOptions) {
		if (options != null && options.saveAs != null) {
			saveAs(data, options.saveAs);
		}
	}

	private convertHtml(html: string, config: PrintConfiguration): string {
		const separator = (config.backend as CsvBackendConfiguration)?.delimiter || ';';
		const textenc = (config.backend as CsvBackendConfiguration)?.quote || '"';
		const textencRegExp = new RegExp(textenc, 'g');

		html = this._cleanComments(html).replace('<br/>', separator);
		let matches = html.match(CsvPrintBackend._htmlDecomposingRegExp);
		if (matches == null) {
			matches = Array(4).concat(html, html);
		}
		const theader = matches[4] || '';
		const trows = matches[5] || '';
		const dom = new DOMParser();

		const cols = (theader.match(CsvPrintBackend._thMatch) || []).map(x => dom.parseFromString(x.replace(CsvPrintBackend._tagClean, ''), 'text/html').body.textContent);
		const cells = (trows.match(CsvPrintBackend._trMatch) || []).map(
			y => y.match(CsvPrintBackend._tdMatch).map(
				x => dom.parseFromString(x.replace(CsvPrintBackend._tagClean, ''), 'text/html').body.textContent
			)
		);

		let result = '';
		if (cols.length) {
			result += cols.map(x => this.encText(x, separator, textenc, textencRegExp)).join(separator) + '\n';
		}
		result += cells.map(x => x.map(c => this.encText(c, separator, textenc, textencRegExp)).join(separator)).join('\n') + '\n';

		return result;
	}

	protected encText(text: string, separator: string, encChar: string, encRegExp: RegExp): string {
		if (text.indexOf(encChar) >= 0) {
			text = encChar + text.replace(encRegExp, encChar + encChar) + encChar;
		}
		else if (text.indexOf(separator) >= 0) {
			text = encChar + text + encChar;
		}
		return text;
	}

	protected _cleanComments(html: string): string {
		return html.replace(CsvPrintBackend._htmlCommentsRegExp, '').replace(CsvPrintBackend._emptyLineRegExp, '');
	}
}
