import {Inject, LOCALE_ID, Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {Description, Model, OptionDescription, EnumOptionDescription, FilterNode, Filter, FilterValue} from 'kn-query-filter';
import {DatePipe, DecimalPipe, BooleanField} from 'kn-common';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {KnMenu} from 'kn-components';

type Presenter = (filter: Filter<FilterValue>, option: OptionDescription<FilterValue>) => string | Promise<string>;

@Component({
	selector: 'kn-grid-query-filter',
	template: `
		<kn-menu (expandedChange)="onExpandedChange($event)" #menu>
			<kn-menu-activator>
				<a href="javascript:void(0)" *ngIf="useDefault && !isDefault(model)" (click)="reset($event)">
					<i i18n-title title="Reset" class="material-icons">close</i>
				</a>
				<span><i class="material-icons">filter_list</i> <span [innerHTML]="title"></span></span>
			</kn-menu-activator>
			<kn-menu-content #menuContent class="kn-grid-query-content kn-grid-menu-content">
				<kn-query-filter *ngIf="!menuContent.hidden"
						[description]="description"
						[model]="liveModel">
					<div class="footer">
					<div class="left">
						<button (click)="setAsDefault()" *ngIf="useDefault && !isDefault(liveModel)" i18n>Set as default</button>
					</div>
					<button (click)="apply()" i18n>Apply</button>
					</div>
				</kn-query-filter>
			</kn-menu-content>
		</kn-menu>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnGridQueryFilter implements OnChanges {
	private readonly _datePipe: DatePipe;
	private readonly _decimalPipe: DecimalPipe;

	private readonly _presenters = new Map<string, Presenter>([
		[
			'date-between',
			f => this._formatList(f.value.map((x: Date) => this._datePipe.transform(x, 'yMd')))
		], [
			'combobox',
			async(f, o) => '<em>' + await KnGridQueryFilter._getOptionLabel(f, o as EnumOptionDescription) + '</em>'
		], [
			'string-input',
			f => '<em>' + f.value + '</em>'
		], [
			'number-input',
			f => '<em>' + this._decimalPipe.transform(f.value, '1.0-3') + '</em>'
		], [
			'number-between',
			f => this._formatList(f.value.map((x: number) => this._decimalPipe.transform(x, '1.0-3')))
		], [
			'checkbox',
			f => '<em>' + (f.value ? this._i18n.t('on') : this._i18n.t('off')) + '</em>'
		]
	] as [string, Presenter][]);

	@Input() public description: Description;
	@Input() public model: Model;
	@Input() @BooleanField() public useDefault: boolean;
	@Input() public defaultModel: Model;
	@Output() public modelChange = new EventEmitter<Model>();
	@Output() public defaultModelChange = new EventEmitter<Model>();

	public liveModel: Model;
	public title: SafeHtml;

	@ViewChild('menu', { static: true })
	protected menu: KnMenu;

	public constructor(
			@Inject(LOCALE_ID) locale: string,
			private readonly _i18n: I18nService,
			private readonly _sanitizer: DomSanitizer,
			private readonly _cdr: ChangeDetectorRef) {
		this._updateTitle();
		this._datePipe = new DatePipe(locale);
		this._decimalPipe = new DecimalPipe(locale);
	}

	public onExpandedChange(value: boolean) {
		if (value) {
			this.liveModel = Utils.clone(this.model, true);
		}
	}

	public apply() {
		this.model = Utils.clone(this.liveModel, true);
		this._updateTitle();
		this.modelChange.emit(this.model);
		this.menu.collapse();
	}

	public setAsDefault() {
		this.defaultModel = Utils.clone(this.liveModel, true);
		this.defaultModelChange.emit(this.defaultModel);
		this.apply();
	}

	public reset(event: Event) {
		this.liveModel = Utils.clone(this.defaultModel, true);
		this.apply();
		event.stopPropagation();
	}

	public isDefault(model: Model) {
		return Utils.equal(model, this.defaultModel);
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('model' in changes) {
			this._updateTitle();
		}
	}

	private async _updateTitle() {
		this.title = this._sanitizer.bypassSecurityTrustHtml(await this._makeTitle());
		this._cdr.markForCheck();
	}

	private async _makeTitle() {
		const filters: Filter<FilterValue>[] = [];
		this.model && this._forEachFilter(this.model, x => filters.push(x));

		if (filters.length === 0) {
			return this._i18n.t('No filters');
		}

		if (filters.length === 1) {
			return this._createFilterTitle(filters[0]);
		}

		const firstLabel = await this._createFilterTitle(filters[0]);
		const names = Utils.array.unique(filters.map(x => this._getFilterName(x))).slice(1);
		if (names.length === 0) {
			if (filters.length === 2) {
				return this._i18n.t('{{ firstLabel }}, {{ secondLabel }}', { firstLabel, secondLabel: await this._createFilterTitle(filters[1], true) });
			}
			return this._i18n.t('{{ firstLabel }}, ...', { firstLabel });
		}
		if (names.length <= 2) {
			return this._i18n.t('{{ firstLabel }} by {{ filtersList }}', { firstLabel, filtersList: this._formatList(names) });
		}

		return this._i18n.t('{{ firstLabel }} by {{ filters }} filters', { firstLabel, filters: `<em>${filters.length - 1}</em>` });
	}

	private _formatList(values: string[]) {
		return this._i18n.join(values.map(x => '<em>' + x + '</em>'));
	}

	private _forEachFilter(node: FilterNode<FilterValue> | Filter<FilterValue>, visitor: (filter: Filter<FilterValue>) => void): void {
		if (node.hasOwnProperty('children')) {
			(node as FilterNode<FilterValue>).children.forEach(x => this._forEachFilter(x, visitor));
		}
		else {
			visitor(node as Filter<FilterValue>);
		}
	}

	private _getFilterName(filter: Filter<FilterValue>) {
		return this._obtainFilterName(this._getFilterOption(filter));
	}

	private _obtainFilterName(option: OptionDescription<FilterValue>) {
		if (Utils.isFunction(option.label)) {
			return (option.label as (option: OptionDescription<FilterValue>) => string)(option);
		}
		return option.label as string;
	}

	private async _createFilterTitle(filter: Filter<FilterValue>, onlyValue: boolean = false) {
		const option = this._getFilterOption(filter);
		const typeSetting = this.description.typeSettings
			.find(x => x.id === option.type);
		if (typeSetting == null) {
			throw new Error('Cannot find type setting for filter.');
		}

		const operator = typeSetting.operators
			.find(x => x.id === filter.operator);
		if (operator == null) {
			throw new Error('Cannot find operator in type setting for filter.');
		}

		const presenter = this._presenters.get(option.presenter || operator.presenter);
		if (presenter == null) {
			throw new Error('Filter presenter not exist.');
		}

		return (!onlyValue ? (this._obtainFilterName(option) + ' ' + operator.label + ' ') : '') + await presenter(filter, option);
	}

	private _getFilterOption(filter: Filter<FilterValue>) {
		const option = this.description.options.find(x => x.id === filter.id);
		if (option == null) {
			throw new Error('Cannot find appropriate description for filter.');
		}
		return option;
	}

	// TODO: resolve if option.options is not flat array
	private static async _getOptionLabel(filter: Filter<FilterValue>, option: EnumOptionDescription): Promise<string> {
		let enumOption: { label: string; value: string; };
		if (Array.isArray(option.options)) {
			enumOption = (option.options as { label: string; value: string; }[])
				.find(x => x.value === filter.value);
		}
		else if (Utils.isObservable(option.options)) {
			return (option.options as Observable<{ label: string; value: string; }[]>)
				.toPromise().then(x => {
					// eslint-disable-next-line eqeqeq
					const r = x.find(e => e.value == filter.value);
					return r && r.label || filter.value;
				});
		}
		return enumOption && enumOption.label || filter.value;
	}
}
