import {Component, ViewEncapsulation, Optional, EventEmitter, Input, Output, OnInit, OnDestroy, HostListener, ElementRef} from '@angular/core';
import {NotSupportedError} from 'kn-shared';
import {Utils} from 'kn-utils';
import {DateTime} from './types';
import {KnInput} from '../input/input.component';

export type DatepickerType =
	'datetime-local' |
	'datetime' |
	'date' |
	'time';

export const DatepickerType = {
	DatetimeLocal: 'datetime-local' as DatepickerType,
	Datetime: 'datetime' as DatepickerType,
	Date: 'date' as DatepickerType,
	Time: 'time' as DatepickerType
};

@Component({
	selector: 'kn-datepicker',
	templateUrl: 'datepicker.html',
	styleUrls: ['datepicker.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnDatepicker implements OnInit, OnDestroy {
	private _dateTimeCache: { iso8601: string, dateTime: DateTime };
	private _disposables: Function[] = [];
	private _container: HTMLElement;

	@Input() public type: DatepickerType = 'datetime-local';
	@Input() public value: string;
	@Output() public valueChange: EventEmitter<string> = new EventEmitter<string>();

	public isOpen: boolean = false;

	public get dateTime() {
		if (this._dateTimeCache == null || this._dateTimeCache.iso8601 !== this.value) {
			this._dateTimeCache = {
				dateTime: this._fromIso8601(this.value),
				iso8601: this.value
			};
		}
		return this._dateTimeCache.dateTime;
	}

	@HostListener('document:click', ['$event'])
	public documentClickHandler(event: Event) {
		if (!this._container.contains(event.target as Element)) {
			this.close();
		}
	}

	public constructor(
			@Optional() private readonly _input: KnInput,
			private readonly _element: ElementRef) {
	}

	public ngOnInit() {
		this._container = this._element.nativeElement;
		if (this._input != null) {
			this.type = this._input.type as DatepickerType;
			const subscriptions = [
				this._input.keydown.subscribe((next: KeyboardEvent) => this._processKey(next)),
				this._input.valueChange.subscribe((next: string) => this.handleValueChange(next)),
				this._input.focusEvent.subscribe(() => this.open()),
				this._input.blurEvent.subscribe(() => this.close()),
				this._input.changeEvent.subscribe(() => this.handleChange())
			];
			this._disposables = subscriptions.map(x => () => x.unsubscribe());
			this._disposables.push(this._input.registerChild(this._element));
			this.value = this._input.value;

			// FIXME
			this._container = this._element.nativeElement.parentNode.parentNode;
		}
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	/** @internal */
	public handleChange() {
		this.handleValueChange(this._input.value);
	}

	public handleValueChange(textDate: string) {
		this.close();
		this.value = textDate;
		if (this._input) {
			this._input.value = textDate;
		}
		this.valueChange.next(textDate);
	}

	public handleDateTimeChange(date: DateTime) {
		if (this._input) {
			this._input.focus();
		}
		this.handleValueChange(this._toIso8601(date));
	}

	public open() {
		this.isOpen = true;
	}

	public close() {
		this.isOpen = false;
	}

	private _toIso8601(value: DateTime) {
		const pad = (n: string, width: number) =>
			n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;

		const dateFunctor = (x: DateTime) => pad('' + x.year, 4)
				+ '-' + pad('' + (x.month + 1), 2)
				+ '-' + pad('' + (x.day + 1), 2);

		const timeFunctor = (x: DateTime) => pad('' + x.hour, 2)
				+ ':' + pad('' + x.minute, 2);

		const zoneFunctor = (x: DateTime) => {
			if (x.timezone == null) {
				return Utils.date.getLocalTimezone();
			}
			else if (x.timezone === 0) {
				return 'Z';
			}
			else {
				const absOffset = Math.abs(x.timezone);
				return (x.timezone >= 0 ? '+' : '-')
				+ pad('' + Math.floor(absOffset / 60), 2)
				+ ':' + pad('' + (absOffset % 60), 2);
			}
		};

		switch (this.type) {
			case DatepickerType.DatetimeLocal:
				return dateFunctor(value) + 'T' + timeFunctor(value);
			case DatepickerType.Datetime:
				return dateFunctor(value) + 'T' + timeFunctor(value) + zoneFunctor(value);
			case DatepickerType.Date:
				return dateFunctor(value);
			case DatepickerType.Time:
				return timeFunctor(value);
			default:
				throw new NotSupportedError('Unsupported type.');
		}
	}

	private _fromIso8601(value: string) {
		if (value == null || value.length === 0) {
			return null;
		}

		const dateFunctor = (date: DateTime, val: string) => {
			const parts = val.split('-').map(x => parseInt(x, 10));
			date.year = parts[0];
			date.month = parts[1] - 1;
			date.day = parts[2] - 1;
			return date;
		};

		const timeFunctor = (date: DateTime, val: string) => {
			const parts = val.split(/[:.]/).map(x => parseInt(x, 10));
			date.hour = parts[0] || 0;
			date.minute = parts[1] || 0;
			return date;
		};

		const dateTime = {} as DateTime;
		switch (this.type) {
			case DatepickerType.DatetimeLocal:
				const parts = value.split('T');
				dateFunctor(dateTime, parts[0]);
				timeFunctor(dateTime, parts[1]);
				break;
			case DatepickerType.Datetime:
				const dateValue = Utils.date.fromIso8601(value);
				dateTime.year = dateValue.getFullYear();
				dateTime.month = dateValue.getMonth();
				dateTime.day = dateValue.getDay();
				dateTime.hour = dateValue.getHours();
				dateTime.minute = dateValue.getMinutes();
				dateTime.timezone = dateValue.getTimezoneOffset();
				break;
			case DatepickerType.Date:
				dateFunctor(dateTime, value);
				break;
			case DatepickerType.Time:
				timeFunctor(dateTime, value);
				break;
			default:
				throw new NotSupportedError('Unsupported type.');
		}

		return dateTime;
	}

	private _processKey(event: KeyboardEvent) {
		switch (event.key || (event as any).code) {
			case 'ArrowDown':
				this.open();
				return;
			case 'Escape':
				this.close();
				return;
		}
	}
}
