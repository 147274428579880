import {AbstractAccessControl, AbstractAccessControlFactory, AccessRestriction} from './abstract-access-control';

export class DenyAccessControl extends AbstractAccessControl {
	public can(action: string, restrictions?: AccessRestriction) {
		return false;
	}
}

export class DenyAccessControlFactory extends AbstractAccessControlFactory {
	public get name() {
		return 'deny';
	}

	public create(context: { [key: string]: any }) {
		return new DenyAccessControl();
	}
}
