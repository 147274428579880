import {RouterModule, Routes} from '@angular/router';
import {JwtAuthGuard} from 'kn-jwt-auth';
import {UriContextGuard} from 'kn-rest';
import {RestPermissionsConfiguratorGuard} from 'common-web/app';
import {SigninComponent} from './views/signin/signin.component';
import {RouteNotFoundComponent} from './views/route-not-found.component';

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'signin',
		pathMatch: 'full'
	}, {
		path: '',
		canActivate: [JwtAuthGuard, RestPermissionsConfiguratorGuard],
		loadChildren: () => import('./views/master/master.module').then(x => x.MasterModule)
	}, {
		path: 'signin',
		component: SigninComponent
	}, {
		path: ':customer',
		canActivate: [JwtAuthGuard, UriContextGuard, RestPermissionsConfiguratorGuard],
		canDeactivate: [UriContextGuard],
		loadChildren: () => import('./views/customer/customer.module').then(x => x.CustomerModule),
	}, {
		path: '**',
		component: RouteNotFoundComponent
	}
];

export const routing = RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' });
