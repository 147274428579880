import {Utils} from 'kn-utils';
import {Model, Description, BooleanOperator, Filter, FilterNode, FilterValue} from './types';
import {ContractModel} from './contract.types';
import {FilterNodeUtils} from './internal-utils';

export class ModelBuilder {
	public static from(model: ContractModel, description: Description): Model {
		if (!model || !description || !FilterNodeUtils.isGroup(model as FilterNode<FilterValue>)) {
			model = {};
			ModelBuilder._initStructure(model);
			return model as Model;
		}
		ModelBuilder._initStructure(model);
		ModelBuilder._consolidateOptionsWithDescriptions(model, description);
		return model as Model;
	}

	public static ensureFixed(model: Model, defaultModel: ContractModel): Model {
		if (!model || !defaultModel || !FilterNodeUtils.isGroup(model as FilterNode<FilterValue>)) {
			return model;
		}
		const fixed = defaultModel.children.filter(x => x.fixed === true) as Filter<any>[];
		const childrenArray = model.children as Filter<any>[];
		for (let fix of fixed) {
			if (childrenArray.some(x => x.id === fix.id)) {
				continue;
			}
			childrenArray.unshift(fix);
		}
		return model;
	}

	public static simplify(model: Model) {
		model.children = model.children
			.map(x => ModelBuilder._simplifyNode(x, model.operator))
			.reduce((acc, x) => acc.concat(Utils.array.box(x)), [] as any[])
			.filter(x => x != null);
		return model;
	}

	public static clean(model: Model) {
		ModelBuilder._cleanNode(model);
		return model;
	}

	private static _initStructure(model: ContractModel) {
		const structure = {
			operator: BooleanOperator.And,
			children: []
		} as Model;

		Utils.object.initStructure(model, structure);
	}

	private static _consolidateOptionsWithDescriptions(model: ContractModel, description: Description) {
		const ids = description.options.map(x => x.id);
		FilterNodeUtils.forEachNode(model as FilterNode<FilterValue>, node => {
			const filterNode = node as FilterNode<FilterValue>;
			if (FilterNodeUtils.isGroup(filterNode)) {
				filterNode.children = filterNode.children
					.filter(x => x != null && (FilterNodeUtils.isGroup(x) || ids.indexOf((x as Filter<FilterValue>).id) !== -1));
			}
		});
	}

	private static _simplifyNode<T>(node: FilterNode<T> | Filter<T>, parentOperator: BooleanOperator): (FilterNode<T> | Filter<T>)[] | FilterNode<T> | Filter<T> {
		if (FilterNodeUtils.isGroup(node)) {
			const groupNode = node as FilterNode<T>;
			groupNode.children = groupNode.children
				.map(x => ModelBuilder._simplifyNode(x, groupNode.operator))
				.reduce((acc, x) => acc.concat(Utils.array.box(x)), [] as any[])
				.filter(x => x != null);
			if (groupNode.children.length <= 1) {
				return groupNode.children[0];
			}
			else if (groupNode.operator === parentOperator) {
				return groupNode.children;
			}
		}
		return node;
	}

	private static _cleanNode<T>(node: FilterNode<T>) {
		for (let i = node.children.length - 1; i >= 0; i--) {
			if (FilterNodeUtils.isGroup(node.children[i])) {
				const group = node.children[i] as FilterNode<T>;
				ModelBuilder._cleanNode(group);
				if (group.children.length === 0) {
					node.children.splice(i, 1);
				}
			}
		}
	}
}
