import {EmbeddedViewRef, SimpleChanges} from '@angular/core';
import {CellContext, RowItem, CellValue} from '../../types';
import {RenderBinderRef} from './render-binder-ref';

export class TemplateRendererContext<T extends RowItem> {
	public constructor(public $implicit: CellValue, public context: CellContext<T>) { }
}

export class TemplateRenderBinderRef<T extends RowItem> extends RenderBinderRef<T> {
	public constructor(
			private readonly _embeddedView: EmbeddedViewRef<TemplateRendererContext<T>>) {
		super();
	}

	public get embeddedView(): EmbeddedViewRef<TemplateRendererContext<T>> {
		return this._embeddedView;
	}

	public updateBindings(value: CellValue, context: CellContext<T>) {
		this.embeddedView.context.$implicit = value;
		this.embeddedView.context.context = context;
		return {} as SimpleChanges;
	}
}
