import {Component, EventEmitter, Input, Output, ElementRef, OnInit} from '@angular/core';

@Component({
	selector: 'kn-resizable',
	templateUrl: 'resizable.html',
	styles: [`
		:host {
			position: relative;
		}

		:host > .content-box {
			width: 100%;
			height: 100%;
		}

		kn-draggable-handler {
			display: block;
			position: absolute;
		}

		kn-draggable-handler.top {
			cursor: ns-resize;
			width: 100%;
			height: 20px;
			top: -10px;
			left: 0;
		}

		kn-draggable-handler.bottom {
			cursor: ns-resize;
			width: 100%;
			height: 20px;
			left: 0;
			bottom: -10px;
		}

		kn-draggable-handler.left {
			cursor: ew-resize;
			width: 20px;
			height: 100%;
			top: 0;
			left: -10px;
		}

		kn-draggable-handler.right {
			cursor: ew-resize;
			width: 20px;
			height: 100%;
			top: 0;
			right: -10px;
		}
	`]
})
export class KnResizableComponent implements OnInit {
	@Input() public minWidth: number;
	@Input() public maxWidth: number;
	@Input() public minHeight: number;
	@Input() public maxHeight: number;
	@Input() public width: number;
	@Input() public height: number;
	@Output() public widthChange = new EventEmitter<number>();
	@Output() public heightChange = new EventEmitter<number>();

	@Input() public set handlers(value: string | string[]) {
		let sides = value;
		if (!Array.isArray(value)) {
			sides = value.split(/[\s,]+/);
		}
		this.topHandler = sides.indexOf('top') !== -1;
		this.bottomHandler = sides.indexOf('bottom') !== -1;
		this.leftHandler = sides.indexOf('left') !== -1;
		this.rightHandler = sides.indexOf('right') !== -1;
	}

	public topHandler = false;
	public bottomHandler = false;
	public leftHandler = false;
	public rightHandler = false;

	public setWidth(value: number) {
		this.width = value;
		this.widthChange.emit(this.width);
	}

	public setHeight(value: number) {
		this.height = value;
		this.heightChange.emit(this.height);
	}

	public constructor(private readonly _element: ElementRef) { }

	public ngOnInit() {
		if (this.height == null && (this.topHandler || this.bottomHandler)) {
			this.height = this._element.nativeElement.clientHeight;
		}
		if (this.width == null && (this.leftHandler || this.rightHandler)) {
			this.width = this._element.nativeElement.clientWidth;
		}
	}
}
