import {Injectable, Injector} from '@angular/core';
import {SelectionMode, RenderMode, Sorting} from 'kn-datagrid';
import {UserService} from 'kn-user';
import {AbstractGridData, DataDescription, DataModel, OwnershipRendererFactory} from 'common-web/grid';
import * as Model from 'common-web/model';

@Injectable()
export class DatabasesGridData extends AbstractGridData<Model.Database> {
	private readonly _prefixOwnership: string;

	public constructor(injector: Injector, user: UserService) {
		super(injector);
		this._prefixOwnership = user.getUser<Model.User>().ownership;
		this._registerRenderer('ownership', new OwnershipRendererFactory());
	}

	protected _createDescription(): DataDescription<Model.Database> {
		return {
			gridRenderMode: RenderMode.Static,
			gridSelectionMode: SelectionMode.Multiple,
			gridMutalSorting: false,
			gridRowsReordering: true,
			gridColumnsReordering: true,
			rows: {
				gridSelectable: true,
				gridClasses: this._getOrEvaluateRowClasses.bind(this)
			},
			columns: [
				{
					id: '@selector'
				}, {
					id: 'id',
					label: this._i18n.t('ID'),
					gridSortable: false,
					filterType: 'number'
				}, {
					id: 'uid',
					label: this._i18n.t('Link'),
					filterType: 'string'
				}, {
					id: 'connectionString',
					label: this._i18n.t('Connection string'),
					filterType: 'string'
				}, {
					id: 'description',
					label: this._i18n.t('Description'),
					filterType: 'string'
				}, {
					id: 'name',
					label: this._i18n.t('Name'),
					gridClasses: ['strong'],
					filterType: 'string'
				}, {
					id: 'ownership',
					label: this._i18n.t('Ownership'),
					gridRenderer: this.renderers.ownership(this._prefixOwnership),
					filterType: 'string'
				}, {
					id: 'provider',
					label: this._i18n.t('Provider'),
					filterType: 'string'
				}, {
					id: 'type',
					label: this._i18n.t('Type'),
					gridGroupable: true,
					filterType: 'string'
				}, {
					id: 'lastModified',
					label: this._i18n.t('Last Modified'),
					gridGroupable: false,
					gridRenderer: this.renderers.date('dMyjjmm'),
					filterType: 'date',
					filterOptions: this._queryExpander.buildDateOptions('1d', '7d', '1m', '3m', '1y', '1D', '7D', '1M', '3M', '1Y')
				}, {
					id: 'modifiedBy',
					label: this._i18n.t('Modified By'),
					gridGroupable: true,
					filterType: 'string'
				}
			],
			sections: []
		};
	}

	protected _createModel(): DataModel {
		return {
			columns: [
				{
					id: '@selector',
					gridWidth: 50
				}, {
					id: 'name',
					gridSort: Sorting.Ascending,
					gridWidth: 250
				}, {
					id: 'description'
				}, {
					id: 'ownership',
					gridWidth: 400
				}, {
					id: 'connectionString',
					gridVisible: false
				}, {
					id: 'provider',
					gridVisible: false
				}, {
					id: 'type',
					gridVisible: false
				}, {
					id: 'uid',
					gridWidth: 100
				}, {
					id: 'id',
					gridVisible: false
				}, {
					id: 'lastModified',
					gridVisible: false
				}, {
					id: 'modifiedBy',
					gridVisible: false
				}
			]
		};
	}
}
