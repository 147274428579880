import {ComponentRef, Type} from '@angular/core';
import {OverlayService} from './overlay.service';

export abstract class AbstractOverlayService<T> {
	protected _component: ComponentRef<T>;

	public constructor(protected _overlay: OverlayService, componentType: Type<T>) {
		this._component = this._overlay.create(componentType);
	}
}
