import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {ViewTemplatesConfig} from './view-templates.config';
import {ViewTemplatesResourceService} from './view-templates-resource.service';

export class ViewTemplatesOptions extends ViewTemplatesConfig { }

export const VIEW_TEMPLATES_FORROOT_GUARD = new InjectionToken<void>('VIEW_TEMPLATES_FORROOT_GUARD');

export function provideViewTemplatesConfig(config: ViewTemplatesOptions) {
	return Utils.object.defaults(config, {
		viewTemplatesTable: 'ViewTemplates'
	});
}

@NgModule({})
export class KnViewTemplatesModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(VIEW_TEMPLATES_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<ViewTemplatesOptions>): ModuleWithProviders<KnViewTemplatesModule> {
		return {
			ngModule: KnViewTemplatesModule,
			providers: [
				...forRootGuardFactory('KnViewTemplatesModule', VIEW_TEMPLATES_FORROOT_GUARD, ViewTemplatesResourceService),
				{
					provide: ViewTemplatesOptions, useValue: config ? config : {}
				}, {
					provide: ViewTemplatesConfig,
					useFactory: provideViewTemplatesConfig,
					deps: [ViewTemplatesOptions]
				},
				ViewTemplatesResourceService
			]
		};
	}
}
