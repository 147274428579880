import {AbstractFilterPreprocessor} from './abstract-filter-preprocessor';

export class WhiteTextNodesFilterPreprocessor extends AbstractFilterPreprocessor {
	private static readonly _generateInlineProperties = [
		'inline',
		'inline-block',
		'inline-table',
		'inline-list-item',
		'inline-flex'
	];

	public whatToShow(): number {
		return NodeFilter.SHOW_TEXT;
	}

	protected _isFiltered(node: Node): boolean {
		if (/\S/.test((node as Text).textContent)) {
			return false;
		}

		const siblings = [node.previousSibling, node.nextSibling];
		if (siblings.some(x => x == null || x.nodeType !== Node.ELEMENT_NODE)) {
			return true;
		}

		for (const sibling of siblings) {
			const style = window.getComputedStyle(sibling as HTMLElement);
			if (WhiteTextNodesFilterPreprocessor._generateInlineProperties.indexOf(style.display) !== -1) {
				return false;
			}
		}

		return true;
	}
}
