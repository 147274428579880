import {AbstractStorageService} from './abstract-storage.service';

export function AbstractStorage(storage: AbstractStorageService, key?: string) {
	return (target: any, name?: string): void => {
		key = key || name;
		Object.defineProperty(target, name, {
			get: () => storage.getItem(key),
			set: value => storage.setItem(key, value),
			enumerable: true,
			configurable: true
		});
	};
}
