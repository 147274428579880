<div class="title">
	<h2>{{ database.name }}</h2>
	<a class="side-action" i18n-title title="Expand/Collapse" href="javascript:void(0)" (click)="isVisible ? collapse() : expand()">
		<i class="material-icons">{{ isVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
	</a>
</div>

<div class="content" *ngIf="isVisible">
	<div class="column">
		<kn-roles-table
				[permissionActions]="permissionActions"
				[permissions]="permissions"
				[(roles)]="roles"
				(statusChange)="handleStatus($event)"></kn-roles-table>
	</div>
</div>
