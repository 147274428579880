<kn-sticky>
	<div class="form-container heading">
		<div class="content">
			<h1 i18n>Roles</h1>
			<div class="form-buttons" *knCan="'edit' the 'item'">
				<button type="button" [disabled]="!isSavable" (click)="save()" i18n>Save</button>
			</div>
		</div>
	</div>
</kn-sticky>
<div class="form-container">
	<div class="form-layout">
		<div class="form-grid">
			<div class="form-expanssion">
				<kn-database-roles class="form-box" *ngFor="let database of databases"
						[database]="database"
						(savableChange)="refreshSavable()"></kn-database-roles>
			</div>
		</div>
	</div>
</div>
