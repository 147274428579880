import {OnInit, DoCheck, AfterViewInit, Component, Input, Injector, Renderer2, ElementRef, ViewContainerRef, ViewChild, ChangeDetectionStrategy, SimpleChanges, Type} from '@angular/core';
import {ChangeNotifier} from '../services/change-notifier.service';
import {Column, CellContext, RowItem, CellValue} from '../types';
import {ElementRenderBinderRef} from '../renderers/binders/element-render-binder-ref';
import {Node} from '../model/node';
import {RenderAs, RenderingType} from '../renderers/render-as';
import {RenderBinderRef} from '../renderers/binders/render-binder-ref';
import {RendererFactory} from '../renderers/renderer-factory';

@Component({
	selector: 'kn-cell-renderer',
	template: '<ng-template #target></ng-template>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnCellRenderer implements OnInit, AfterViewInit, DoCheck {
	private _renderAs: RenderAs;
	private _binder: RenderBinderRef<RowItem>;
	private _context: CellContext<RowItem>;

	@Input() public node: Node<RowItem>;
	@Input() public column: Column<RowItem>;

	@ViewChild('target', { static: true, read: ViewContainerRef })
	public viewTarget: ViewContainerRef;

	public constructor(
			private readonly _injector: Injector,
			private readonly _element: ElementRef,
			private readonly _changeNotifier: ChangeNotifier) {
	}

	public ngOnInit() {
		const value = this.column.description.renderer(this._getValue(), this._getContext(), RendererFactory.mainTarget);
		if (value instanceof RenderAs) {
			this._renderAs = value;
			if (this._renderAs.type === RenderingType.Element) {
				this._binder = this._renderAs.element(this._injector, this._element);
				this._changeNotifier.emit();
			}
		}
		else {
			const renderer = this._injector.get(Renderer2 as Type<Renderer2>);
			this._binder = new ElementRenderBinderRef(renderer, this._element, 'textContent', (elementRef, x, context, firstChange) => {
				return this.column.description.renderer(x, context, RendererFactory.mainTarget);
			});
			this._changeNotifier.emit();
		}
	}

	public ngAfterViewInit() {
		if (this._renderAs != null) {
			switch (this._renderAs.type) {
				case RenderingType.Template:
					this.viewTarget.clear();
					this._binder = this._renderAs.template(this.viewTarget);
					this._changeNotifier.emit();
					break;
				case RenderingType.Component:
					this.viewTarget.clear();
					this._binder = this._renderAs.component(this.viewTarget);
					this._changeNotifier.emit();
					break;
			}
		}
	}

	public ngDoCheck() {
		if (this._binder == null) {
			return;
		}
		const changes: SimpleChanges = this._binder.updateBindings(this._getValue(), this._getContext());
		if (this._binder.changeDetectorRef != null && Object.keys(changes).length > 0) {
			this._binder.changeDetectorRef.markForCheck();
		}
	}

	private _getValue(): CellValue {
		return this.column.description.accessor(this.node.item, this.column);
	}

	private _getContext(): CellContext<RowItem> {
		if (this._context == null || this._context.node !== this.node || this._context.column !== this.column) {
			this._context = { node: this.node, column: this.column };
		}
		return this._context;
	}
}
