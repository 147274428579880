import {NgModule, ModuleWithProviders} from '@angular/core';
import {KnRipple} from './ripple.directive';
import {RippleGlobalConfig} from './ripple.types';

export class RippleGlobalOptions extends RippleGlobalConfig { }

const KN_RIPPLE_DIRECTIVES = [
	KnRipple
];

@NgModule({
	declarations: [KN_RIPPLE_DIRECTIVES],
	exports: [KN_RIPPLE_DIRECTIVES]
})
export class KnRippleModule {
	public static forRoot(config?: Partial<RippleGlobalOptions>): ModuleWithProviders<KnRippleModule> {
		return {
			ngModule: KnRippleModule,
			providers: [
				{
					provide: RippleGlobalOptions, useValue: config ? config : {}
				}
			]
		};
	}
}
