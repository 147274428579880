import {Injectable, Optional} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Authentication} from 'kn-user';
import {Response} from 'kn-http';
import {JwtAuthStore} from './jwt-auth-store.service';
import {JwtAuthHttp} from './jwt-auth-http.service';
import {AbstractRenewStrategy} from './renew-strategies/abstract-renew-strategy';
import {isTokenExpired} from './jwt-utils';
import {AuthError} from './auth.error';
import {AuthObject} from './types';

export class JwtAuthConfig {
	public signinUrl?: string;
	public signupUrl?: string;
	public userGetter?: (auth: AuthObject) => any;
}

@Injectable()
export class JwtAuth implements Authentication {
	private _renewMonitorTeardown: Function;
	private readonly _options: JwtAuthConfig;

	public constructor(
			public http: JwtAuthHttp,
			private readonly _store: JwtAuthStore,
			private readonly _renewStrategy: AbstractRenewStrategy,
			@Optional() options?: JwtAuthConfig) {
		this._options = Utils.object.defaults({}, options, {
			signinUrl: '/api/signin',
			signupUrl: '/api/signup',
			userGetter: (auth: AuthObject) => auth && auth.user
		} as JwtAuthConfig);

		if (this.isAuthenticated()) {
			this._instantiateRenewMonitor();
		}
	}

	public isAuthenticated() {
		const token = this._store.getToken();
		return (token && !isTokenExpired(token));
	}

	public renewToken() {
		if (!this.isAuthenticated()) {
			throw new AuthError('Not authenticated.');
		}
		return this._sign(this._options.signinUrl);
	}

	public getAuth<T extends AuthObject>(): T {
		return this._store.get() as T;
	}

	public getUser<T extends {}>(): T {
		return this._options.userGetter(this.getAuth()) as T;
	}

	public signUp(creditials: any): Observable<Response> {
		return this._sign(this._options.signupUrl, creditials);
	}

	public signIn(creditials: any): Observable<Response> {
		return this._sign(this._options.signinUrl, creditials);
	}

	public signOut() {
		this._teardownRenewMonitor();
		this._store.remove();
	}

	private _sign(url: string, creditials?: any): Observable<Response> {
		this._teardownRenewMonitor();
		const request$ = creditials
			? this.http.post(url, JSON.stringify(creditials))
			: this.http.get(url);
		return request$.pipe(Rx.tap(next => this._interceptSignResponse(next)));
	}

	private _interceptSignResponse(response: Response) {
		this._store.save(response.body);
		if (this.isAuthenticated()) {
			this._instantiateRenewMonitor();
		}
	}

	private _instantiateRenewMonitor() {
		const token = this._store.getToken();
		let subscription: Subscription;
		const renewer = () => {
			subscription = this.renewToken().subscribe();
		};
		const renewMonitor = this._renewStrategy.monitor(token, renewer);
		this._renewMonitorTeardown = () => {
			subscription && subscription.unsubscribe();
			renewMonitor && renewMonitor.teardown();
		};
	}

	private _teardownRenewMonitor() {
		this._renewMonitorTeardown && this._renewMonitorTeardown();
		this._renewMonitorTeardown = null;
	}
}
