import {Injectable, Inject} from '@angular/core';
import {Observable, ConnectableObservable, Subscription, from as obsevableFrom} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {HyPdf, HyPdfOptions} from 'hypdf-printer';
import {Http} from 'kn-http';
import {AbstractModuleLoader} from 'kn-common';
import {saveAs} from 'file-saver';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {PRINT_HTTP_TOKEN} from '../print-http.token';
import {PrintConfiguration} from '../types';

export interface HypdfPrintBackendOptions extends AbstractPrintBackendOptions {
	saveAs?: string;
}

@Injectable()
export class HypdfPrintBackend extends AbstractPrintBackend {
	// eslint-disable-next-line @typescript-eslint/prefer-readonly
	private _hypdfProviderFunctor$: () => Observable<HyPdf>;
	private _subscription: Subscription;

	public constructor(
			@Inject(PRINT_HTTP_TOKEN) private readonly _http: Http,
			moduleLoader: AbstractModuleLoader) {
		super();

		/* eslint-disable @typescript-eslint/promise-function-async */
		const options: HyPdfOptions = {
			fetcher: this._fetch.bind(this)
		};

		const load = (key: string, module: string, options: any) => {
			return moduleLoader.import<any>(module, options)
				.then((dep: any) => ({ [key]: dep }));
		};

		const hypdfProvider$ = obsevableFrom([
				['pdfkit', './assets/js/pdfkit.js', { name: 'PDFDocument' }],
				['blobstream', './assets/js/blob-stream.js', { name: 'blobStream' }],
				['html2canvas', './assets/js/html2canvas.js', { name: 'html2canvas' }]
			] as [string, string, any][])
			.pipe(
				Rx.mergeMap(x => load(...x)),
				Rx.reduce((acc, value) => Object.assign(acc, value), {}),
				Rx.map(x => new HyPdf(Object.assign(options, x))),
				Rx.publishReplay(1)
			) as ConnectableObservable<HyPdf>;
		/* eslint-enable @typescript-eslint/promise-function-async */

		this._hypdfProviderFunctor$ = () => {
			this._subscription = this._subscription || hypdfProvider$.connect();
			return hypdfProvider$;
		};
	}

	public get name(): string {
		return 'pdf';
	}

	public print(html: string, config?: PrintConfiguration, options?: HypdfPrintBackendOptions): Observable<any> {
		return this._hypdfProviderFunctor$().pipe(
			Rx.switchMap(printer => obsevableFrom(printer.print(html))),
			Rx.tap(blob => this._save(blob, options))
		);
	}

	public printPreview(html: string, config?: PrintConfiguration, options?: HypdfPrintBackendOptions): Observable<any> {
		return this.print(html, config, options);
	}

	public destroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	private async _fetch(uri: string, binary: boolean): Promise<ArrayBuffer | string> {
		const options = {
			responseType: binary ? 'arraybuffer' : 'text'
		};
		return this._http.get(uri, options)
			.pipe(Rx.map(next => next.body))
			.toPromise();
	}

	private _save(data: Blob, options?: HypdfPrintBackendOptions) {
		if (options != null && options.saveAs != null) {
			saveAs(data, options.saveAs);
		}
	}
}
