import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {RestService, RestChangeNotifierService, AbstractTransformableResource, AbstractResource} from 'kn-rest';
import {UidResource, Formatters} from 'common-web/rest';
import * as Model from '../../model/customer-database.types';
import * as WebModel from '../../model/web.types';

@Injectable()
export class DevicesResourceService extends AbstractTransformableResource<Model.Device, WebModel.Device> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(new UidResource(rest, notifier, 'Devices', {
			rules: [
				{ path: 'macAddress', formatter: Formatters.slugify(40) }
			]
		}));
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return Object.assign(super.getReferences(), {
			syncSettings: null
		});
	}

	protected _materialize(item: Model.Device): WebModel.Device {
		if (item && item.hasOwnProperty('syncSettingsJson')) {
			item.syncSettings = JSON.parse(item.syncSettingsJson || null);
		}
		return item;
	}

	protected _dematerialize(item: WebModel.Device): Model.Device {
		const result = Utils.clone(item);
		if (result.syncSettings) {
			const originalSyncSettings = result.syncSettingsJson && JSON.parse(result.syncSettingsJson);
			if (!Utils.equal(result.syncSettings, originalSyncSettings)) {
				result.syncSettingsJson = JSON.stringify(result.syncSettings);
			}
		}
		delete result.syncSettings;
		return result;
	}
}
