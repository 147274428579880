import {Utils} from 'kn-utils';
import {Contract, ContractModel} from './contract.types';
import {Model, ColumnModel, Description, Sorting, SectionModel, SectionDescription} from './types';
import {SectionUtils} from './internal-utils';

export class ModelBuilder {
	public static from<T>(model: ContractModel, description: Description<T>): Model {
		model = Utils.clone(model, true);
		if (!model || !description) {
			model = {};
			ModelBuilder._initStructure(model);
			return model as Model;
		}
		ModelBuilder._initStructure(model);
		ModelBuilder._consolidateColumnsWithDescriptions(model, description);
		ModelBuilder._setColumnsDefaults(model);
		ModelBuilder._consolidateSectionsWithDescriptions(model, description);
		ModelBuilder._setSectionsDefaults(model);
		return model as Model;
	}

	private static _initStructure(model: ContractModel) {
		const structure = {
			columns: [],
			sections: []
		} as Model;

		Utils.object.initStructure(model, structure);
	}

	private static _consolidateColumnsWithDescriptions<T>(model: ContractModel, description: Description<T>) {
		const missingsColumnsVisible = model.columns.length === 0;
		const columnsIds = description.columns.map(x => x.id);
		for (let i = model.columns.length; i > 0; i--) {
			if (!model.columns[i] || columnsIds.indexOf(model.columns[i].id) === -1) {
				model.columns.splice(i, 1);
			}
		}

		const modelIds = model.columns.map(x => x.id);
		const missingsColumns = Utils.array.difference(columnsIds, modelIds)
				.map(x => ({ id: x, visible: missingsColumnsVisible } as Partial<ContractModel>));
		Array.prototype.push.apply(model.columns, missingsColumns);
	}

	private static _setColumnsDefaults(model: ContractModel) {
		for (const column of model.columns) {
			ModelBuilder._setColumnDefaults(column);
		}
	}

	private static _setColumnDefaults(node: Contract<ColumnModel>) {
		const defaults = {
			width: null,
			visible: true,
			sort: Sorting.None,
			group: false
		} as Partial<ColumnModel>;

		Utils.object.defaults(node, defaults);
	}

	private static _consolidateSectionsWithDescriptions<T>(model: ContractModel, description: Description<T>) {
		ModelBuilder._filterSectionsWithoutDescriptionCounterpart(model.sections, description.sections);
		ModelBuilder._completeSectionsByDescription(model.sections, description.sections);
	}

	private static _filterSectionsWithoutDescriptionCounterpart<T>(sectionModels: Contract<SectionModel>[], sectionDescriptions: SectionDescription<T>[]) {
		for (let i = sectionModels.length - 1; i >= 0; i--) {
			const columnDescription = sectionDescriptions.find(x => x.id === sectionModels[i].id);
			const remove = !sectionModels[i] || !columnDescription;
			if (remove) {
				sectionModels.splice(i, 1);
			}
			else {
				ModelBuilder._filterSectionsWithoutDescriptionCounterpart(
					sectionModels[i].children || [],
					columnDescription.children);
			}
		}
	}

	private static _completeSectionsByDescription<T>(sectionModels: Contract<SectionModel>[], sectionDescriptions: SectionDescription<T>[]) {
		for (const columnDescription of sectionDescriptions) {
			let columnModel = sectionModels.find(x => x.id === columnDescription.id);
			if (!columnModel) {
				columnModel = {
					id: columnDescription.id,
					children: []
				} as Partial<ColumnModel>;
				sectionModels.push(columnModel);
			}
			if (columnModel.children == null) {
				columnModel.children = [];
			}
			ModelBuilder._completeSectionsByDescription(
				columnModel.children,
				columnDescription.children);
		}
	}

	private static _setSectionsDefaults(model: ContractModel) {
		SectionUtils.forEachNode(model.sections, node => {
			ModelBuilder._setSectionDefaults(node);
		});
	}

	private static _setSectionDefaults(node: Contract<ColumnModel>) {
		const defaults = {
			visible: true
		} as Partial<ColumnModel>;

		Utils.object.defaults(node, defaults);
	}
}
