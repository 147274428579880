import {ViewContainerRef, Injector, ElementRef} from '@angular/core';
import {RenderBinderRef} from './binders/render-binder-ref';
import {RowItem} from '../types';

export enum RenderingType {
	Element,
	Template,
	Component
}

export class RenderAs {
	private readonly _content: any;
	private readonly _type: RenderingType;

	public constructor(content: any, type?: RenderingType) {
		this._content = content;
		this._type = type == null ? RenderingType.Element : type;
	}

	public get type(): RenderingType {
		return this._type;
	}

	public get content(): any {
		return this._content;
	}

	public get element(): (injector: Injector, elementRef: ElementRef) => RenderBinderRef<RowItem> {
		return (this._type === RenderingType.Element) ? this._content : undefined;
	}

	public get template(): (viewContainerRef: ViewContainerRef) => RenderBinderRef<RowItem> {
		return (this._type === RenderingType.Template) ? this._content : undefined;
	}

	public get component(): (viewContainerRef: ViewContainerRef) => RenderBinderRef<RowItem> {
		return (this._type === RenderingType.Component) ? this._content : undefined;
	}

	public static element(content: (injector: Injector, elementRef: ElementRef) => RenderBinderRef<RowItem>): RenderAs {
		return new RenderAs(content, RenderingType.Element);
	}

	public static template(content: (viewContainerRef: ViewContainerRef) => RenderBinderRef<RowItem>): RenderAs {
		return new RenderAs(content, RenderingType.Template);
	}

	public static component(content: (viewContainerRef: ViewContainerRef) => RenderBinderRef<RowItem>): RenderAs {
		return new RenderAs(content, RenderingType.Component);
	}
}
