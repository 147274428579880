import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('empty')
export class EmptyOnNullFilter implements PrintRendererFilter {
	public transform(value: any): string {
		return (Array.isArray(value) && !value.length) ? '' : (value ?? '');
	}
}
