import {StaticProvider, TRANSLATIONS, TRANSLATIONS_FORMAT} from '@angular/core';
import {Utils} from 'kn-utils';
import {I18nCatalog} from './types';
import {I18nService} from './i18n.service';
import {NotSupportedError} from '../not-supported.error';

export class I18n {
	private _catalog: I18nCatalog;
	private static readonly _i18nInstances: { [locale: string]: I18n } = {};

	public static appLocale = (typeof $localize !== "undefined" && $localize.locale) || window.navigator.language;

	public static async load(options?: { locale?: string, path?: string, type?: string }): Promise<I18n> {
		options = Utils.object.defaults({}, options, {
			locale: I18n.appLocale,
			path: '/assets/locale',
			type: 'xlf'
		});

		let translations = await this._loadTranslations(options.locale, options.path, options.type);

		// HACK: protection from parsing index.html
		if (translations && translations.indexOf('<html>') !== -1) {
			translations = null;
		}

		const i18n = new I18n(options.locale, translations, options.type);
		this._i18nInstances[options.locale] = i18n;
		return i18n;
	}

	public static get(locale?: string): I18n {
		locale = locale || I18n.appLocale;
		if (this._i18nInstances.hasOwnProperty(locale)) {
			return this._i18nInstances[locale];
		}
		return null;
	}

	private static async _fetch(uri: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('GET', uri, true);
			request.responseType = 'text';
			request.onload = () => resolve(request.responseText);
			request.onerror = x => reject(x);
			request.send(null);
		});
	}

	private static async _loadTranslations(locale: string, path: string, type: string): Promise<string> {
		if (!locale || ['en', 'en-US'].indexOf(locale) !== -1) {
			return null;
		}
		const language = (locale.match(/^(\w+)/) || [null, locale])[1];
		const translationFile = `${path}/messages.${language}.${type}`;
		return I18n._fetch(translationFile);
	}

	public constructor(
			private readonly _locale: string,
			private readonly _translations: string,
			private readonly _type: string) {
	}

	public getCompilerProviders(): StaticProvider[] {
		if (this._translations == null) {
			return [];
		}
		return [
			{ provide: TRANSLATIONS, useValue: this._translations },
			{ provide: TRANSLATIONS_FORMAT, useValue: this._type }
		];
	}

	public getCatalog(): I18nCatalog {
		if (this._translations == null) {
			return {};
		}
		if (this._catalog == null) {
			switch (this._type) {
				case 'xlf':
					this._catalog = this._parseXliff(this._translations);
					break;
				default:
					throw new NotSupportedError('Not supported type.');
			}
		}
		return this._catalog;
	}

	public getService(): I18nService {
		return new I18nService(this.getCatalog());
	}

	private _parseXliff(xliff: string): I18nCatalog {
		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xliff, 'text/xml');
		const ns = 'urn:oasis:names:tc:xliff:document:1.2';
		const xmlTransUnits = xmlDoc.getElementsByTagNameNS(ns, 'trans-unit');

		const catalog: I18nCatalog = {};

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < xmlTransUnits.length; i++) {
			const xmlTransUnit = xmlTransUnits[i].children;
			let meaning: string;
			let source: string;
			let target: string;

			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let j = 0; j < xmlTransUnit.length; j++) {
				const node = xmlTransUnit[j];
				switch (node.localName) {
					case 'source':
						source = this._replacePlaceholders(node.innerHTML || node.textContent);
						break;
					case 'target':
						target = this._replacePlaceholders(node.innerHTML || node.textContent);
						break;
					case 'note':
						if (node.getAttribute('from') === 'meaning') {
							meaning = node.textContent;
						}
						break;
				}
			}

			if (!this._isEmpty(source) /*&& !this._isEmpty(target)*/) {
				catalog[`${source}[${meaning || ''}]`] = target;
			}
		}

		return catalog;
	}

	private _isEmpty(value: string) {
		return value == null || value.length === 0;
	}

	private _replacePlaceholders(msg: string) {
		return msg.replace(/<x\s+.*?id="(\w+)".*?\/>/g, this._translatePlaceholder);
	}

	private _translatePlaceholder(tag: string, id: string) {
		const [mode, type, name] = id.split('_', 3).map(x => x.toLowerCase());
		if (['start', 'close'].indexOf(mode) !== -1 && type === 'tag' && name) {
			return mode === 'close' ? `</${name}>` : `<${name}>`;
		}
		return tag;
	}
}
