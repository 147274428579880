<div [ngSwitch]="accessible">
	<p class="message" *ngIf="message"><i class="material-icons">warning</i> {{ message }}</p>
	<div class="inaccessible" *ngSwitchCase="!!0">
		<p i18n class="message">The <em>KCuni bridge</em> is not running or is inaccessible.</p>
		<p i18n>Check that the program is running and there is no problem with the certificate. Please consult the documentation.</p>
	</div>
	<div class="accessible" *ngSwitchCase="!!1">
		<p><ng-container i18n>Version:</ng-container> <strong>{{ version?.version }}</strong> (<strong>{{ version?.date | date: 'yMd' }}</strong>)</p>
		<div class="settings">
			<kn-input type="text" [(value)]="portName">
				<kn-optgroup #ports="knAutocomplete" [knAutocomplete]="portsAutocompletion">
					<kn-option *ngFor="let port of ports.suggestions" [value]="port">{{ port }}</kn-option>
				</kn-optgroup>
				<span kn-label i18n>Port</span>
			</kn-input>
			<button type="button" title="Save" [disabled]="settings?.portName === portName" (click)="save()">
				<i class="material-icons">done</i>
			</button>
		</div>
	</div>
	<div class="loading" *ngSwitchDefault>
		<p i18n>Loading...</p>
	</div>
	<a class="doc-link" [attr.href]="docUrl" target="_blank" i18n>KCuni bridge manual</a>
	<a class="doc-link app-link" [attr.href]="appUrl" target="_blank" i18n>KCuni bridge sw</a>
</div>
