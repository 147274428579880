import {Utils} from 'kn-utils';
import {AuthError} from './auth.error';

function _decodeBase64Url(base64Url: string): string {
	if (base64Url.length % 4 === 2) {
		base64Url = base64Url + '==';
	}
	else if (base64Url.length % 4 === 3) {
		base64Url = base64Url + '=';
	}
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	return window.atob(base64);
}

function _getTokenDate(tokenOrPayload: string | any, key: string): Date {
	let decoded: any = tokenOrPayload;
	if (Utils.isString(tokenOrPayload)) {
		decoded = decodeToken(tokenOrPayload as string);
	}
	if (!decoded[key]) {
		return null;
	}
	const date = new Date(0);
	date.setUTCSeconds(decoded[key]);
	return date;
}

export function decodeToken(token: string): any {
	const parts = token.split('.');
	if (parts.length !== 3) {
		throw new AuthError('JWT must have 3 parts.');
	}
	const decoded = _decodeBase64Url(parts[1]);
	if (!decoded) {
		throw new AuthError('Cannot decode the token.');
	}
	return JSON.parse(decoded);
}

export function getTokenIssueDate(tokenOrPayload: string | any): Date {
	return _getTokenDate(tokenOrPayload, 'iat');
}

export function getTokenExpirationDate(tokenOrPayload: string | any): Date {
	return _getTokenDate(tokenOrPayload, 'exp');
}

export function isTokenExpired(tokenOrPayload: string | any): boolean {
	const date = getTokenExpirationDate(tokenOrPayload);
	return date ? (date <= new Date()) : false;
}
