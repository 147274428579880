import {Utils} from 'kn-utils';
import {OptionDescription, Model, Description, Filter, FilterValue} from '../types';
import {ContractWithId} from '../contract.types';
import {FilterNodeUtils} from '../internal-utils';

export abstract class AbstractFilterSerializer<T> {
	public constructor(protected _description: Description) { }

	public abstract serialize(model: Model): T;
	public abstract deserialize(query: T): Model;

	protected _getOptionDescription(id: string): OptionDescription<FilterValue> {
		const optionDescription = this._description.options.find(x => x.id === id);
		if (optionDescription == null) {
			throw new Error(`There is no description for option '${id}'.`);
		}
		return optionDescription;
	}

	protected _serializeValue(value: FilterValue, type: string): string | string[] {
		const stringify = (x: any) => {
			if (x == null) {
				return '';
			}
			return ((x instanceof Date) ? Utils.date.toIso8601(x) : `${x}`);
		};
		return Array.isArray(value) ? value.map(stringify) : stringify(value);
	}

	protected _deserializeValue(value: string | string[], type: string): FilterValue {
		const destringify = (x: string) => {
			if (x.startsWith('::')) {
				return x;
			}
			switch (type) {
				case 'date':
					return Utils.date.fromIso8601(x);
				case 'number':
					return +x;
				case 'identifier':
					return x === '' ? null : +x;
				case 'bool':
					return !(/^(false|0)$/i).test(x) && !!x;
				default:
					return x;
			}
		};
		return Array.isArray(value) ? value.map(destringify) : destringify(value);
	}

	protected static _deriveOptionsFromModel(model: Model) {
		const options: ContractWithId<OptionDescription<FilterValue>>[] = [];
		FilterNodeUtils.forEachNode(model, node => {
			if (!FilterNodeUtils.isGroup(node)) {
				const filter = node as Filter<FilterValue>;
				const option = options.find(x => x.id === filter.id);
				const type = AbstractFilterSerializer._deriveTypeFromValue(filter.value);
				if (option == null) {
					options.push({ id: filter.id, type: type });
				}
				else {
					option.type = AbstractFilterSerializer._interpolateTypes(option.type, type);
				}
			}
		});
		return options;
	}

	private static _deriveTypeFromValue(value: FilterValue) {
		if (typeof(value) === 'boolean') {
			return 'boolean';
		}
		if (Utils.isDate(value)) {
			return 'date';
		}
		else if (Utils.isString(value)) {
			return 'string';
		}
		else if (Utils.isNumber(value)) {
			return 'number';
		}
		else if (Utils.isBoolean(value)) {
			return 'bool';
		}
		return typeof(value);
	}

	private static _interpolateTypes(...types: string[]): string {
		for (let i = 1; i < types.length; i++) {
			if (types[0] !== types[i]) {
				return 'object';
			}
		}
		return types[0];
	}
}
