import {forwardRef, Component, ViewEncapsulation, HostBinding, Input, ViewChild, ElementRef, EventEmitter, Output, ChangeDetectorRef, ChangeDetectionStrategy, Provider} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {BooleanField} from 'kn-common';

export const KN_CHECKBOX_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnCheckbox), // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
	multi: true
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-checkbox',
	templateUrl: 'checkbox.html',
	styleUrls: ['checkbox.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [KN_CHECKBOX_CONTROL_VALUE_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnCheckbox implements ControlValueAccessor {
	private _checked: boolean = false;
	private _focused: boolean = false;

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@HostBinding('class.focused')
	public get focused() {
		return this._focused;
	}

	public get inputId(): string {
		return `${this.id}-input`;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-checkbox-${nextUniqueId++}`;

	@Input() @BooleanField() public required: boolean = false;
	@Input() public tabindex: number = null;
	@Input() public type: string = 'text';
	@Input() public name: string = null;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@HostBinding('class.indeterminate')
	@Input() @BooleanField() public indeterminate: boolean = false;

	public get checked(): boolean {
		return this._checked;
	}

	@Input() public set checked(value: boolean) {
		if (value !== this._checked) {
			this._checked = value;
			this._onChangeCallback(value);
			this._cdr.markForCheck();
		}
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public checkedChange = new EventEmitter<boolean>();

	@ViewChild('input', { static: true })
	public _inputElement: ElementRef;

	public constructor(private readonly _cdr: ChangeDetectorRef) { }

	public focus() {
		this._inputElement.nativeElement.focus();
	}

	public toggle() {
		this.indeterminate = false;
		this.checked = !this.checked;
		this.checkedChange.emit(this.checked);
	}

	/** @internal */
	public handleFocus(event: FocusEvent) {
		this._focused = true;
		this.focusEvent.emit(event);
	}

	/** @internal */
	public handleBlur(event: FocusEvent) {
		this._focused = false;
		this._onTouchedCallback();
		this.blurEvent.emit(event);
	}

	/** @internal */
	public handleChange(event: Event) {
		if (!this.disabled) {
			this.toggle();
			this._onTouchedCallback();
			this.changeEvent.emit();
			event.stopPropagation();
		}
	}

	/** @internal */
	public handleClick(event: Event) {
		this.focus();
		if (event.target !== this._inputElement.nativeElement) {
			this._inputElement.nativeElement.click();
		}
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._checked = !!value;
		this.changeEvent.emit();
		this._cdr.markForCheck();
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this._cdr.markForCheck();
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}
}
