import {Component, Input, Output, EventEmitter, SimpleChanges, ChangeDetectionStrategy} from '@angular/core';
import {FormBuilder, FormGroup, FormArray, FormControl} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Utils} from 'kn-utils';
import {UserService} from 'kn-user';
import {I18nService} from 'kn-shared';
import {ValidationService, CheckValidator, RequiredValidator, MaxLengthValidator} from 'kn-forms';
import * as Model from 'common-web/model';
import * as CommonModel from '../../model/common-database.types';

@Component({
	selector: 'kn-roles-table',
	templateUrl: 'roles-table.html',
	providers: [ValidationService],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesTableComponent {
	private _statusChangesSubscription: Subscription;
	private _valueChangesSubscription: Subscription;
	private _init = false;

	@Input() public permissionActions: Model.ActionInfo[];
	@Input() public permissions: Model.PermissionInfo[] = [];
	@Input() public roles: CommonModel.Role[];
	@Output() public rolesChange = new EventEmitter<CommonModel.Role[]>();
	@Output() public statusChange = new EventEmitter<string>();

	public form: FormGroup;
	public default3rdStateActions = 'R';

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _user: UserService,
			private readonly _formBuilder: FormBuilder,
			private readonly _validation: ValidationService) {
		const uniqueRoleNameValidator = new CheckValidator<string>(value => {
			const rolesForm = this.form.get('roles') as FormArray;
			let count = 0;
			for (let i = 0; i < rolesForm.length; i++) {
				count += (rolesForm.get([i, 'name']).value === value) ? 1 : 0;
			}
			return count === 1;
		});

		this._validation.add('required', new RequiredValidator(), this._i18n.t('Required'));
		this._validation.add('maxLength', new MaxLengthValidator(), this._i18n.t('Too long'));
		this._validation.add('uniqueRoleName', uniqueRoleNameValidator, this._i18n.t('Item duplicity error', 'Duplicate'));
	}

	public ngOnInit() {
		this.form = this._buildControlGroup();
		this._statusChangesSubscription = this.form.statusChanges.subscribe(next => this.statusChange.emit(next));
		this._refresh();
		this._init = true;
	}

	public ngOnDestroy() {
		this._statusChangesSubscription && this._statusChangesSubscription.unsubscribe();
		this._unsubscribeValueChanges();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this._init && 'roles' in changes) {
			this._refresh();
		}
	}

	private _subscribeValueChanges() {
		this._valueChangesSubscription = this.form.valueChanges.subscribe(() => {
			if (this._syncFormToModel(this.form)) {
				this.rolesChange.emit(this.roles.slice());
			}
		});
	}

	private _unsubscribeValueChanges() {
		this._valueChangesSubscription && this._valueChangesSubscription.unsubscribe();
	}

	private _refresh() {
		this._unsubscribeValueChanges();
		this._populateFormValues(this.form, this.roles);
		this._subscribeValueChanges();
		this._updateAccess();
	}

	private _updateAccess() {
		if (this._user.can('edit', 'item')) {
			this.form.enable();
		}
		else {
			this.form.disable();
		}
	}

	private _populateFormValues(form: FormGroup, roles: CommonModel.Role[]) {
		const rolesForm = this.form.get('roles') as FormArray;
		for (let i = rolesForm.length - 1; i >= roles.length; i--) {
			rolesForm.removeAt(i);
		}
		for (let i = 0; i < roles.length; i++) {
			let controlGroup: FormGroup;
			if (i === rolesForm.length) {
				controlGroup = this._buildRoleControlGroup();
				rolesForm.push(controlGroup);
			}
			else {
				controlGroup = rolesForm.at(i) as FormGroup;
			}
			for (const key in controlGroup.controls) {
				if (controlGroup.controls.hasOwnProperty(key) && roles[i].hasOwnProperty(key)) {
					(controlGroup.controls[key] as FormControl).setValue(roles[i][key]);
				}
			}
		}
	}

	private _syncFormToModel(form: FormGroup) {
		let changed = false;
		const rolesForm = this.form.get('roles') as FormArray;
		for (let i = 0; i < rolesForm.length; i++) {
			const roleForm = rolesForm.at(i) as FormGroup;
			const role = this.roles.find(x => {
				const key = x.id != null ? 'id' : 'name';
				return x[key] === roleForm.controls[key].value;
			});
			for (const key in roleForm.controls) {
				if (roleForm.controls.hasOwnProperty(key)) {
					if (!Utils.equal(role[key], (roleForm.controls[key] as FormControl).value)) {
						role[key] = (roleForm.controls[key] as FormControl).value;
						changed = true;
					}
				}
			}
		}
		return changed;
	}

	private _buildControlGroup(): FormGroup {
		return this._formBuilder.group({
			roles: this._formBuilder.array([])
		});
	}

	private _buildRoleControlGroup(): FormGroup {
		const validators = this._validation.validators;
		return this._formBuilder.group({
			id: [undefined],
			name: ['', [validators.required(), validators.uniqueRoleName(), validators.maxLength(50)]],
			rolePermissions: [[]]
		});
	}

	public removeRole(index: number) {
		const roleName = this.form.get(['roles', index, 'name']).value;
		const roleIndex = this.roles.findIndex(x => x.name === roleName);
		if (roleIndex !== -1) {
			const roles = this.roles.slice();
			roles.splice(roleIndex, 1);
			this.rolesChange.emit(roles);
		}
	}

	public addRole(name: string) {
		if (!this.isNewRoleValid(name)) {
			return;
		}

		const role = {
			name: name,
			rolePermissions: []
		} as CommonModel.Role;
		this.rolesChange.emit([...this.roles, role]);
	}

	public isNewRoleValid(name: string) {
		if (name == null || name === '') {
			return false;
		}

		const rolesForm = this.form.get('roles') as FormArray;
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < rolesForm.length; i++) {
			if (name === rolesForm.get([0, 'name']).value) {
				return false;
			}
		}
		return true;
	}

	public setRolePermissions(index: number, rolePermissions: Model.RolePermission[]) {
		this.form.get(['roles', index, 'rolePermissions']).setValue(rolePermissions);
	}
}
