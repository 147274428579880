import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {AbstractResource} from './abstract-resource';
import {ChangeEntry} from '../change-notification/types';
import {Indexer} from '../types';

export abstract class AbstractTransformableResource<T, U> extends AbstractResource<U> {
	public constructor(protected _baseResource: AbstractResource<T>) {
		super();
	}

	public get baseResource(): AbstractResource<T> {
		return this._baseResource;
	}

	public get changes(): Observable<ChangeEntry> {
		return this._baseResource.changes;
	}

	public get table(): string {
		return this._baseResource.table;
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return this._baseResource.getReferences();
	}

	public query(context?: UriContext): Observable<U[]> {
		return this._baseResource.query(context)
			.pipe(Rx.map(next => this._boxedTransform<T, U>(next, this._materialize.bind(this)) as U[]));
	}

	public get(indexer: Indexer, context?: UriContext): Observable<U> {
		return this._baseResource.get(indexer, context)
			.pipe(Rx.map(next => this._boxedTransform<T, U>(next, this._materialize.bind(this)) as U));
	}

	public head(indexer: Indexer, context?: UriContext): Observable<Response> {
		return this._baseResource.head(indexer, context);
	}

	public save(item: U, context?: UriContext): Observable<Response> {
		const serializedItem = this._dematerialize(item);
		return this._baseResource.save(serializedItem, context)
			.pipe(Rx.tap(() => this._reflectSaveSideEffects(item, serializedItem)));
	}

	protected _remove(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._baseResource.remove(indexer, context);
	}

	protected _boxedTransform<V, W>(itemOrItems: V | V[], transform: (item: V) => W): W | W[] {
		if (itemOrItems == null) {
			return itemOrItems as any;
		}
		const isArray = Array.isArray(itemOrItems);
		const items = isArray ? (itemOrItems as V[]) : ([itemOrItems] as V[]);
		const results = items.map(transform);
		return isArray ? results : results[0];
	}

	protected _reflectSaveSideEffects(item: U, carrier: T) {
		this._looseCopy(item, this._materialize(carrier));
	}

	protected _looseCopy(item: any, carrier: any) {
		for (const key in carrier) {
			if (carrier.hasOwnProperty(key)
					&& !Utils.isObject(carrier[key]) && !Utils.isObject(item[key])) {
				item[key] = carrier[key];
			}
		}
	}

	protected abstract _materialize(item: T): U;
	protected abstract _dematerialize(item: U): T;
}
