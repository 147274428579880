import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Utils} from 'kn-utils';
import {Observable} from 'rxjs';
import {ValueResolveUtils} from '../internal-utils';
import {Description, Filter, FilterValue, OperatorSettingDescription, OptionDescription} from '../types';

@Component({
	selector: 'li[knFilterItem]',
	templateUrl: 'filter-item.html'
})
export class KnFilterItem implements OnInit, OnDestroy {
	private readonly _disposables: Function[] = [];
	private readonly _optionParameters: { [key: string]: Observable<any> } = {};

	@Input() public description: Description;
	@Input() public item: Filter<FilterValue>;
	@Output() public remove = new EventEmitter<Filter<FilterValue>>();

	public constraints: { [others: string]: any };
	public option: OptionDescription<FilterValue>;
	public operators: OperatorSettingDescription[];

	public toIso8601(date: Date, format?: string): string {
		return Utils.date.toIso8601(date, format);
	}

	public fromIso8601(value: string, ignoreTimezone: boolean = false): Date {
		return Utils.date.fromIso8601(value, ignoreTimezone);
	}

	public ngOnInit() {
		this.option = this.description.options.find(option => option.id === this.item.id);
		this.operators = this.description.typeSettings
			.find(typeSetting => typeSetting.id === this.option.type).operators;

		const subscription = ValueResolveUtils.resolveConstraints(this.option)
			.subscribe(next => this.constraints = next);
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public getOperator() {
		return this.operators.find(operator => operator.id === this.item.operator);
	}

	public selectOperator(operator: string) {
		this.description.changeOperatorValueAdaption(this.item, this.item.operator, operator);
		this.item.operator = operator;
	}

	public get presenter() {
		return this.option.presenter || this.getOperator().presenter;
	}

	public get label() {
		return ValueResolveUtils.resolveLabel(this.option);
	}

	public getOptionParameter(key: keyof OptionDescription<FilterValue>): Observable<any> {
		if (!this._optionParameters.hasOwnProperty(key)) {
			this._optionParameters[key] = ValueResolveUtils.resolveOptionParameter(key, this.option);
		}
		return this._optionParameters[key];
	}
}
