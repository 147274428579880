import {NotSupportedError} from 'kn-shared';
import {PairSegments, encodePairSegments, decodePairSegments} from './utils';

export type MatrixParameters = PairSegments;
export type PathParameters = (string | MatrixParameters)[];

export class UriPath {
	private static readonly _pathRegExp = /^\/?((?=(([\w.~!$&'()*+,;=:-]|%[0-9a-fA-F]{2})+))\2\/?)*$/;

	private _path: string;
	private _parameters: PathParameters;

	public constructor(...uri: (UriPath | PathParameters | string)[]) {
		if (uri.length > 1) {
			uri.map(x => x instanceof UriPath ? x : new UriPath(x)).forEach(x => this._combine(x));
		}
		else if (uri.length === 1 && uri[0] != null) {
			if (uri[0] instanceof UriPath) {
				this._parameters = (uri[0] as UriPath)._parameters;
				this._path = (uri[0] as UriPath)._path;
			}
			else if (uri[0] instanceof Object) {
				this._parameters = uri[0] as PathParameters;
			}
			else if (typeof uri[0] === 'string') {
				this._path = uri[0] as string;
			}
			else {
				throw new NotSupportedError('Unsupported path type.');
			}
		}
	}

	private _combine(uri: UriPath) {
		this.parameters = uri.isRootPath() ? uri.parameters : this.parameters.concat(uri.parameters);
	}

	public get parameters() {
		if (this._parameters == null) {
			this._parameters = UriPath.decode(this._path);
		}
		return this._parameters;
	}

	public set parameters(value: PathParameters) {
		this._parameters = value;
		this._path = null;
	}

	public get path() {
		if (this._path == null && this._parameters != null) {
			this._path = UriPath.encode(this._parameters);
		}
		return this._path;
	}

	public set path(value: string) {
		// note: not strict as RFC 3986
		if (value != null && !UriPath._pathRegExp.test(value)) {
			throw new Error('Invalid path subcomponent.');
		}
		this._path = value;
		this._parameters = null;
	}

	public toString() {
		return this.path;
	}

	public isEmpty() {
		return (this._path == null || this._path.length === 0) && this._parameters == null;
	}

	public isRootPath() {
		if (!this.isEmpty()) {
			return this._path != null ? this._path[0] === '/' : this._parameters[0] === '/';
		}
		return false;
	}

	public static encode(value: PathParameters): string {
		const path: string[] = [];
		for (const segment of value) {
			if (typeof segment === 'string') {
				path.push(encodeURIComponent(segment !== '/' ? segment : ''));
			}
			else {
				path.push(encodePairSegments(segment).join(';'));
			}
		}
		return path.join('/');
	}

	public static decode(value: string): PathParameters {
		const path: PathParameters = [];
		if (value == null || value.length === 0) {
			return path;
		}
		for (const segment of value.split('/')) {
			const parts = segment.split(';');
			if (parts.length === 1) {
				path.push(decodeURIComponent(parts[0] !== '' ? parts[0] : '/'));
			}
			else {
				path.push(decodePairSegments(parts));
			}
		}
		return path;
	}
}
