import {Uri} from './uri';
import {UriPath, PathParameters} from './uri-path';
import {UriQuery, QueryParameters} from './uri-query';

export class UriComponents {
	private static readonly _schemeRegExp = /^[a-zA-Z][a-zA-Z0-9+.-]*$/;
	private static readonly _regnameRegExp = /^([\w.~!$&'()*+,;=:-]|%[0-9a-fA-F]{2})+$/;
	private static readonly _fragmentRegExp = /^([\w.~!$&'()*+,;=:@/?-]|%[0-9a-fA-F]{2})*$/;

	private _scheme: string;
	private _userinfo: string;
	private _host: string;
	private _port: number;
	private _path: UriPath;
	private _query: UriQuery;
	private _fragment: string;

	public constructor(components?: {
			scheme?: string,
			userinfo?: string,
			host?: string,
			port?: number,
			authority?: string,
			path?: UriPath | PathParameters | string,
			query?: UriQuery | QueryParameters | string,
			fragment?: string }) {
		Object.assign(this, components, {
			path: components.path != null ? new UriPath(components.path) : new UriPath(),
			query: components.query != null ? new UriQuery(components.query) : new UriQuery()
		});
	}

	public static parse(uriString: string): UriComponents {
		const mx = uriString.match(/^([a-zA-Z][a-zA-Z0-9+.-]*:)?(\/\/[^/?#]*)?([^?#]*)(\?[^#]*)?(#.*)?$/);
		if (!mx) {
			throw new Error('Invalid URI format');
		}
		return new UriComponents({
			scheme: mx[1] && mx[1].substr(0, mx[1].length - 1),
			authority: mx[2] && mx[2].substr(2),
			path: mx[3],
			query: mx[4] && mx[4].substr(1),
			fragment: mx[5] && mx[5].substr(1)
		});
	}

	public toUri(): Uri {
		return new Uri(this.toString());
	}

	public toString(): string {
		let prefix = '';
		if (this.authority) {
			prefix += (this.scheme ? this.scheme + ':' : '') + '//' + this.authority;
			if (!this.path.isRootPath() && (!this.path.isEmpty() || !this.query.isEmpty() || this.fragment)) {
				prefix += '/';
			}
		}
		return prefix
				+ (!this.path.isEmpty() ? this.path.toString() : '')
				+ (!this.query.isEmpty() ? '?' + this.query.toString() : '')
				+ (this.fragment ? '#' + this.fragment : '');
	}

	public get scheme() {
		return this._scheme;
	}

	public set scheme(value: string) {
		if (value != null && !UriComponents._schemeRegExp.test(value)) {
			throw new Error('Invalid scheme subcomponent.');
		}
		this._scheme = value;
	}

	public get userinfo() {
		return this._userinfo;
	}

	public set userinfo(value: string) {
		if (value != null && !UriComponents._regnameRegExp.test(value)) {
			throw new Error('Invalid userinfo subcomponent.');
		}
		this._userinfo = value;
	}

	public get host() {
		return this._host;
	}

	public set host(value: string) {
		// note: not strict as RFC 3986
		if (value != null && !UriComponents._regnameRegExp.test(value)) {
			throw new Error('Invalid host subcomponent.');
		}
		this._host = value;
	}

	public get port() {
		return this._port;
	}

	public set port(value: number) {
		if (value != null && !Number.isInteger(value)) {
			throw new Error('Invalid port subcomponent.');
		}
		this._port = value;
	}

	public get authority() {
		if (this.host == null) {
			return this.host;
		}
		return (this.userinfo ? this.userinfo + '@' : '')
				+ this.host
				+ (this.port != null ? ':' + this.port : '');
	}

	public set authority(value: string) {
		if (value != null) {
			const mx = value.match(/^((?:[^@]*)@)?(.*?)(?::(\d*))?$/);
			this.userinfo = mx[1];
			this.host = mx[2];
			this.port = mx[3] != null ? +mx[3] : undefined;
		}
	}

	public get path() {
		return this._path;
	}

	public set path(value: UriPath) {
		this._path = value;
	}

	public get query() {
		return this._query;
	}

	public set query(value: UriQuery) {
		this._query = value;
	}

	public get fragment() {
		return this._fragment;
	}

	public set fragment(value: string) {
		if (value != null && !UriComponents._fragmentRegExp.test(value)) {
			throw new Error('Invalid fragment subcomponent.');
		}
		this._fragment = value;
	}
}
