import {Component, HostListener, HostBinding} from '@angular/core';
import {KnExpansion} from './expansion.component';

@Component({
	selector: 'kn-expansion-header',
	template: '<ng-content></ng-content>'
})
export class KnExpansionHeader {
	public constructor(private readonly _expansion: KnExpansion) { }

	@HostListener('click', ['$event'])
	@HostListener('keyup.enter', ['$event'])
	public onActivate(event: Event) {
		this._expansion.expanded = !this._expansion.expanded;
		event.stopPropagation();
	}

	// TODO
	@HostBinding('tabindex')
	public tabindex = 0;
}
