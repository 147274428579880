import {Observable} from 'rxjs';

export interface AutocompleteResult<T> {
	datasource: T[];
	constrainsNotMet: boolean;
	partial: boolean;
	empty: boolean;
}

export type Resolvable<T> = T | Promise<T> | Observable<T>;
export type AutocompleteQueryResult<T> = Resolvable<T[]> | Resolvable<AutocompleteResult<T>>;

export abstract class AbstractAutocompletition<T> {
	public abstract querySearch(value: any, query: string, getter: { (item: T): any }): AutocompleteQueryResult<T>;
}
