<div class="filter">
	<a class="clear" href="javascript:void(0)" (click)="filterQuery()">X</a>
	<input type="text" #queryInput knAutofocus
			[ngModel]="query"
			(ngModelChange)="filterQuery($event)"
			(keydown)="processKey($event)"
			i18n-placeholder placeholder="filter">
	<span class="counter">{{ filteredDatabases.length }}/{{ databases.length }}</span>
</div>
<ul>
	<li *ngFor="let database of filteredDatabases">
		<a href="javascript:void(0)" (click)="select(database)" [class.active]="database == marked">
			<strong class="name">{{ database.name }}</strong>
			<span class="uid">{{ database.uid }}</span>
			<span class="description">{{ database.description }}</span>
		</a>
	</li>
</ul>
