import {Injectable} from '@angular/core';
import {EntityStore, AbstractGraphStore} from 'common-web/rest';
import {UsersResourceService} from '../../services/users/users-resource.service';
import {UserRolesResourceService} from '../../services/users/user-roles-resource.service';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import {UserModel} from './user-model';
import {DatabasesUserRolesStore} from './databases-user-roles-store';
import {UsersViewConfig} from './users-view.config';
import * as Model from 'common-web/model';

@Injectable()
export class UserEditService extends AbstractGraphStore<UserModel> {
	public constructor(
			usersResource: UsersResourceService,
			databasesResource: DatabasesResourceService,
			userRolesResource: UserRolesResourceService,
			config: UsersViewConfig) {
		super();
		this._mappedStore = {
			key: 'user',
			store: new EntityStore<Model.User>(usersResource, 'uid')
		};
		this._mappedDependentStores = [
			{
				key: 'databasesUserRoles',
				store: new DatabasesUserRolesStore(databasesResource, userRolesResource, config)
			}
		];
	}
}
