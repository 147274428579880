import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnSelect} from './select.component';
import {KnOptionsModule} from '../options/options.module';

const KN_SELECT_DIRECTIVES = [
	KnSelect
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnOptionsModule,
		KnDirectivesModule
	],
	declarations: [KN_SELECT_DIRECTIVES],
	exports: [KN_SELECT_DIRECTIVES]
})
export class KnSelectModule { }
