import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {UsersConfig} from './users.config';
import * as CommonModel from '../../model/common-database.types';

@Injectable()
export class UserRolesResourceService extends Resource<CommonModel.UserRole> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: UsersConfig) {
		super(rest, notifier, config.userRolesTable);
	}
}
