import {RemoteAutocompleteBondConfig, RemoteAutocompletition} from 'kn-forms';
import {UriContext} from 'kn-http';
import {AbstractFilterSerializer, BooleanOperator, DefaultFilterSerializer, Model as FilterModel} from 'kn-query-filter';
import {AbstractResource} from 'kn-rest';
import {Utils} from 'kn-utils';
import {Observable, combineLatest as observableCombineLatest, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import * as CommonModel from 'common-web/model';

function buildModel<T>(properties: string[] | string, operator: string): (value: T) => FilterModel {
	if (Array.isArray(properties)) {
		return (value: T) => ({
			operator: BooleanOperator.Or,
			children: properties.map(x => ({ id: x, operator: operator, value: value }))
		});
	}
	else {
		return (value: T) => ({
			operator: BooleanOperator.And,
			children: [{ id: properties, operator: operator, value: value }]
		});
	}
}

export function autocompletitionFactoryWithMaster<T extends CommonModel.IUid, U extends CommonModel.IUid>(
		resource: AbstractResource<T>,
		masterResource: AbstractResource<U>,
		masterContext: UriContext,
		queryProperties: string[] | string,
		valueProperties: string = 'id',
		query?: { [key: string]: any },
		config?: RemoteAutocompleteBondConfig,
		serializer?: AbstractFilterSerializer<string>) {
	let valueCache: { value: any, response: (T | U)[] };
	const serialize = (serializer || DefaultFilterSerializer).serialize;

	const sortProperties = Array.isArray(queryProperties) ? queryProperties : [queryProperties];
	const queryModel = buildModel(queryProperties, 'contains');
	const valueModel = buildModel(valueProperties, 'eq');

	return new RemoteAutocompletition<T | U>(context => {
		const requests$: Observable<(T | U)[]>[] = [];
		if (context.value != null && valueModel != null) {
			const contextValue = context.value;
			const replaceByContextValue = (items: (T | U)[]) => {
				const idx = items.findIndex(x => (x as any)[valueProperties] === contextValue[valueProperties]);
				if (idx !== -1) {
					items[idx] = Object.assign(contextValue, items[idx]);
				}
			};
			if (valueCache != null && Utils.equal(valueCache.value, contextValue)) {
				requests$.push(observableOf(valueCache.response.slice(0)).pipe(
					Rx.tap(next => replaceByContextValue(next))
				));
			}
			else {
				let request$: Observable<(T | U)[]>;
				if (contextValue.$master) {
					request$ = masterResource.query(Object.assign({
						query: Object.assign({ q: serialize(valueModel(contextValue[valueProperties])) }, query)
					}, masterContext));
				}
				else {
					request$ = resource.query({
						query: Object.assign({ q: serialize(valueModel(contextValue[valueProperties])) }, query)
					});
				}
				request$ = request$.pipe(
					Rx.tap(next => {
						valueCache = { value: Object.assign({}, contextValue), response: next.slice(0) };
						replaceByContextValue(next);
					}));
				requests$.push(request$);
			}
		}
		if (context.query != null && queryModel != null) {
			const request$ = resource.query({
				query: Object.assign({
					q: serialize(queryModel(context.query)),
					sort: sortProperties,
					limit: context.limit
				}, query)
			});
			requests$.push(request$);
		}
		if (requests$.length === 0) {
			return observableOf([]);
		}
		const unique = (items: (T | U)[]) => {
			const result: (T | U)[] = [];
			for (const item of items) {
				if (!result.some(x => Utils.equal(x, item))) {
					result.push(item);
				}
			}
			return result;
		};
		return observableCombineLatest(requests$).pipe(
			Rx.map(next => unique(Utils.array.flatten(next, true))),
			Rx.switchMap(next => {
				if (next.length >= context.limit || context.query == null) {
					return observableOf(next);
				}
				const queryWithUids: FilterModel = {
					operator: BooleanOperator.And,
					children: [
						queryModel(context.query),
						{
							operator: BooleanOperator.And,
							children: next.map(x => ({ id: 'uid', operator: 'ne', value: x.uid }))
						}
					]
				};
				return masterResource.query(Object.assign({
					query: Object.assign({
						'q': serialize(queryWithUids),
						'sort': sortProperties,
						'limit': context.limit - next.length
					}, query)
				}, masterContext)).pipe(
					Rx.map(x => x.map(y => Object.assign(y, { $master: true }))),
					Rx.map(additional => [].concat(next, additional))
				);
			}));
	}, config);
}
