import {Observable, merge, combineLatest, of} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response} from 'kn-http';
import {Indexer, ChangeEntry} from 'kn-rest';
import {AbstractEntityStore} from './abstract-entity-store';
import {AbstractDependentEntitiesStore} from './abstract-dependent-entities-store';
import {QueryContext, MappedStore, DependentMappedStore} from './types';
import * as Model from 'common-web/model';

export abstract class AbstractGraphStore<T extends { [key: string]: any }> {
	protected _mappedStore: MappedStore<AbstractEntityStore<Model.EntityBase>>;
	protected _mappedDependentStores: DependentMappedStore<AbstractDependentEntitiesStore<Model.EntityBase>>[] = [];

	public get key(): string {
		return this._mappedStore.key;
	}

	public get dependentKeys(): string[] {
		return this._mappedDependentStores.map(x => x.key);
	}

	public get changes(): Observable<ChangeEntry> {
		return merge(...this._retriveChanges());
	}

	protected _retriveChanges(): Observable<ChangeEntry>[] {
		return [this._mappedStore.store.changes]
			.concat(this._mappedDependentStores.map(x => x.store.changes));
	}

	public get(indexerOrQuery: Indexer | QueryContext<string>): Observable<T | T[]> {
		if (this._isIndexer(indexerOrQuery)) {
			return this._getByIndexer(indexerOrQuery as Indexer);
		}
		return this._getByQuery(indexerOrQuery as QueryContext<string>);
	}

	protected _getByIndexer(indexer: Indexer): Observable<T | T[]> {
		const fetchers$ = [this._mappedStore.store.get(indexer)]
			.concat(this._mappedDependentStores.map(x => x.store.get(indexer)));
		return combineLatest(...fetchers$)
			.pipe(Rx.map(next => this._sortDependents(this._fold(next))));
	}

	protected _getByQuery(query: QueryContext<string>): Observable<T | T[]> {
		return this._mappedStore.store.getByQuery(query).pipe(
			Rx.map(next => {
				const indexer = Utils.array.box(next).map(i => this._mappedStore.store.indexerRetriver(i));
				return [of(next)]
					.concat(this._mappedDependentStores.map(x => x.store.get(indexer)));
			}),
			Rx.switchMap(next => combineLatest(next)),
			Rx.map(next => this._sortDependents(this._fold(next)))
		);
	}

	public save(model: T): Observable<{ [key: string]: Response | Response[] }> {
		const items = this._unfold(model);
		return this._mappedStore.store.saveIfChanged(items[0]).pipe(
			Rx.switchMap(next => {
				this._linkDependents(model);
				const storeResult$: Observable<Response | Response[]> = of(next);
				const indexer = this._mappedStore.store.indexerRetriver(items[0]);
				const dependentSavers$ = this._mappedDependentStores
					.map((x, index) => x.store.save(indexer, items[index + 1]));
				return combineLatest(...[storeResult$, ...dependentSavers$]);
			}),
			Rx.switchMap(next => {
				const resave = next && next.length > 0 && (!next[0].hasOwnProperty('state') && Utils.array.flatten(next.filter((x, i) => i !== 0).map(x => Utils.array.box(x))).length > 0);
				const resave$ = resave ? this._mappedStore.store.save(items[0]) : of(next && next[0]);
				return combineLatest(of(next), resave$).pipe(
					Rx.tap(x => x[0][0] = x[1]),
					Rx.map(x => x[0])
				);
			}),
			Rx.map(next => this._fold(next))
		);
	}

	public remove(indexer: Indexer): Observable<{ [key: string]: Response }> {
		const dependentRemovers$ = this._mappedDependentStores.map(x => x.store.remove(indexer)
			.pipe(Rx.catchError(err => of(err as Response))));

		return combineLatest<Response[]>(...dependentRemovers$).pipe(
			Rx.defaultIfEmpty([] as Response[]),
			Rx.switchMap(next => {
				const storeRemover$ = this._mappedStore.store.remove(indexer);
				const dependentResults$: Observable<Response>[] = next.map(x => of(x));
				return combineLatest(...[storeRemover$, ...dependentResults$]);
			}),
			Rx.map(next => this._fold(next))
		);
	}

	public retriveIndexer(model: T) {
		return this._mappedStore.store.indexerRetriver(model[this._mappedStore.key]);
	}

	protected _isIndexer(value: any) {
		return value && Utils.array.box(value).every(x => Utils.isNumber(x) || Utils.isString(x));
	}

	protected _fold(items: any[]): T {
		const model = { [this._mappedStore.key]: items[0] } as T;
		for (let i = 0; i < items.length - 1; i++) {
			model[this._mappedDependentStores[i].key as keyof(T)] = items[i + 1];
		}
		return model;
	}

	protected _unfold(model: T): any[] {
		const items = [model[this._mappedStore.key]];
		for (const key of this._mappedDependentStores.map(x => x.key)) {
			items.push(model[key]);
		}
		return items;
	}

	protected _sortDependents(model: T): T {
		this._mappedDependentStores.forEach(x =>
			model[x.key as keyof(T)] = Utils.array.sort(model[x.key] as any[], x.sort) as any);
		return model;
	}

	protected _linkDependents(model: T): void {
		const indexer = this._mappedStore.store.indexerRetriver(model[this._mappedStore.key]);
		for (const mappedDependentStore of this._mappedDependentStores) {
			for (const dependentItem of model[mappedDependentStore.key]) {
				const partial = mappedDependentStore.store.ancestorMapper(indexer);
				Object.assign(dependentItem, partial);
			}
		}
	}
}
