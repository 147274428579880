import {Utils} from 'kn-utils';
import {UriContext} from 'kn-http';
import {Resource} from './resource';
import {LiveFeed} from './live-feed';
import {RestService} from '../rest.service';
import {RestChangeNotifierService} from '../change-notification/rest-changes-notifier.service';
import {ChangeEntry} from '../change-notification/types';

export class LiveFeedResource<T> extends Resource<T> {
	private readonly _feeds: LiveFeed<T>[] = [];
	private _collectScheduled: boolean;
	private _activeContext: UriContext;

	public constructor(
			private readonly _rest: RestService,
			notifier: RestChangeNotifierService,
			table: string) {
		super(_rest, notifier, table);
	}

	public liveFeed(context?: UriContext): LiveFeed<T> {
		return this._getOrCreateLiveFeed(context);
	}

	protected _processChanges(change: ChangeEntry) {
		super._processChanges(change);
		if (change.table === this.table) {
			this._refreshAllLiveFeeds();
		}
	}

	private _getLiveFeed<U extends LiveFeed<T>>(context: UriContext): U {
		return this._feeds.find(x => Utils.equal(x.context, context)) as U;
	}

	private _createLiveFeed<U extends LiveFeed<T>>(context: UriContext, feedFactory?: () => U): U {
		const liveFeed = feedFactory != null ? feedFactory() : new LiveFeed<T>(this, context);
		this._feeds.push(liveFeed);
		return liveFeed as U;
	}

	private _getOrCreateLiveFeed<U extends LiveFeed<T>>(context: UriContext, feedFactory?: () => U): U {
		if (!Utils.equal(this._rest.context, this._activeContext)) {
			this._feeds.splice(0, this._feeds.length);
			this._activeContext = this._rest.context;
		}
		else {
			this._scheduledCollectFeeds();
		}
		let livefeed = this._getLiveFeed<U>(context);
		if (livefeed == null) {
			livefeed = this._createLiveFeed<U>(context, feedFactory);
		}
		return livefeed;
	}

	private _refreshAllLiveFeeds() {
		this._collectFeeds();
		this._feeds.forEach(x => x.refresh());
	}

	private _scheduledCollectFeeds() {
		if (this._collectScheduled) {
			return;
		}
		this._collectScheduled = true;
		// this is needed when getting more feeds in loop before subscribe
		setTimeout(() => this._collectFeeds(), 0);
	}

	private _collectFeeds() {
		this._collectScheduled = false;
		for (let i = this._feeds.length - 1; i >= 0; i--) {
			if (!this._feeds[i].isSubscribed) {
				this._feeds.splice(i, 1);
			}
		}
	}
}
