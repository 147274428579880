import {Injectable, ErrorHandler} from '@angular/core';

@Injectable()
export class AppErrorHandler extends ErrorHandler {
	public constructor() {
		super();
	}

	public handleError(error: any) {
		const originalError = error && error.rejection;
		if (originalError && originalError.ngOriginalError != null) {
			super.handleError(originalError.ngOriginalError);
			return;
		}
		super.handleError(error);
	}
}
