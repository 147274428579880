import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnInput} from './input.component';
import {KnOptionsModule} from '../options/options.module';

const KN_INPUT_DIRECTIVES = [
	KnInput
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnOptionsModule,
		KnDirectivesModule
	],
	declarations: [KN_INPUT_DIRECTIVES],
	exports: [KN_INPUT_DIRECTIVES]
})
export class KnInputModule { }
