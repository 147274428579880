import {Injectable, Inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanDeactivate} from '@angular/router';
import {Subscription, NEVER, timer, Subject} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {RestService, RestChangeNotifierService, ChangeEntry, ChangeAction} from 'kn-rest';
import {CHANGE_ENTRIES_TABLE_TOKEN} from './change-entries-table.token';
import {EntityChangeEntry} from './fetchers/entities-changes-fetcher';
import {ChangeEntityCacheService} from './change-entity-cache.service';
import {Database} from 'common-web/model';

@Injectable()
export class ChangesSubscriptionGuard implements CanActivate, CanDeactivate<any> {
	private _subscription: Subscription;
	private _resubs: Subscription[] = [];
	private _changesCounter: number = 0;
	private _changesCounterCounter: number = 0;
	private _connectionCounter: number = 0;
	private _counterSubscription: Subscription;
	private _pingSubscription: Subscription;
	private _pingTimeoutSubscription: Subscription;
	private _changeEntriesSocket: Subject<EntityChangeEntry | EntityChangeEntry[]>;

	private readonly _pingInterval = 5 * 60 * 1000;
	private readonly _pingTimeout = 5 * 1000;

	public constructor(
			@Inject(CHANGE_ENTRIES_TABLE_TOKEN) private readonly _table: string,
			private readonly _restChangeNotifier: RestChangeNotifierService,
			private readonly _restChangeCache: ChangeEntityCacheService,
			private readonly _rest: RestService) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this._counterSubscription = timer(15 * 60 * 1000, 15 * 60 * 1000)
			.subscribe(() => {
				const value = this._changesCounter;
				this._changesCounter = 0;
				const srv = this._rest.for<Database>('Databases');
				if (srv != null) {
					this._resubs.push(srv.query({query: { $id: 0, $logAction: 'entityChangeCounter', $connection: this._connectionCounter, $counter: this._changesCounterCounter++, $value: value }})
						.subscribe(
							() => { /* empty */ },
							() => { /* empty */ },
							() => { /* empty */ }
						));
				}
			});
		this._subscribe();
		return true;
	}

	public canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		this._unsubscribe();
		this._counterSubscription && this._counterSubscription.unsubscribe();
		return true;
	}

	private _unsubscribe() {
		this._restChangeCache.invalidateAll();
		this._subscription && this._subscription.unsubscribe();
		this._resubs.forEach(x => x.unsubscribe());
		this._resubs = [];
		this._pingSubscription && this._pingSubscription.unsubscribe();
		this._pongReceived();
	}

	private _subscribe() {
		this._unsubscribe();
		this._connectionCounter++;
		this._changeEntriesSocket = this._rest.for<EntityChangeEntry>(this._table).websocket();
		this._subscription = this._changeEntriesSocket
		.pipe(Rx.catchError((err: Event) => {
			this._pongReceived();
			if (err.type === 'error') {
				this._initiateWebsocketReopen();
			}
			else {
				//this._subscribe();
			}
			return NEVER;
		}))
		.subscribe(next => {
			if (next && next.hasOwnProperty('msg')) {
				this._pongReceived();
				return;
			}
			(next as EntityChangeEntry[])
			.filter(x => x != null)
			.forEach(x => this._restChangeCache.invalidate(x));
			this._changesCounter += (next as EntityChangeEntry[]).filter(x => x != null).length;
			(next as EntityChangeEntry[])
				.map(x => this._translate(x))
				.filter(x => x != null)
				.forEach(x => this._restChangeNotifier.emit(x));
		});
		this._pingStart();
	}

	private _initiateWebsocketReopen() {
		this._resubs.push(timer(15 * 1000).subscribe(() => this._subscribe()));
	}

	private _pingStart() {
		this._pongReceived();
		this._pingSubscription && this._pingSubscription.unsubscribe();
		this._pingSubscription = timer(this._pingInterval, this._pingInterval).subscribe(() => {
			this._changeEntriesSocket.next({ msg: 'ping' } as any);
			this._pingTimeoutSubscription = this._pingTimeoutSubscription || timer(this._pingTimeout)
				.subscribe(() => {
					const srv = this._rest.for<Database>('Databases');
					if (srv != null) {
						this._resubs.push(srv.query({query: { $id: 0, $logAction: 'entityChangeCounter', $connection: this._connectionCounter, $counter: this._changesCounterCounter, $value: 'pingTimeout' }})
							.subscribe(
								() => { /* empty */ },
								() => { /* empty */ },
								() => { /* empty */ }
							));
					}
					this._initiateWebsocketReopen();
				});
		});
	}

	private _pongReceived() {
		this._pingTimeoutSubscription && this._pingTimeoutSubscription.unsubscribe();
		this._pingTimeoutSubscription = null;
	}

	private _translate(change: EntityChangeEntry): ChangeEntry {
		let action: ChangeAction;
		switch (change.action) {
			case 'Added':
				action = ChangeAction.Added;
				break;
			case 'Modified':
				action = ChangeAction.Modified;
				break;
			case 'Deleted':
				action = ChangeAction.Deleted;
				break;
			default:
				return null;
		}
		return {
			action: action,
			table: change.table,
			indexer: change.id
		};
	}
}
