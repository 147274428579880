import {Utils} from 'kn-utils';
import {SettingsItem, KeySpecifier} from './types';

export function normalizeKey(key: KeySpecifier) {
	if (key == null) {
		return {
			key: null,
			version: null
		};
	}
	if (Utils.isObject(key)) {
		const specifier = key as { key: string, version: string };
		specifier.version = specifier.version;
		return specifier;
	}
	const index = (key as string).lastIndexOf('@');
	if (index === -1) {
		return {
			key: key as string,
			version: null
		};
	}
	return {
		key: (key as string).substr(0, index),
		version: (key as string).substr(index + 1)
	};
}

export function indexOfSettingsItem(items: SettingsItem[], item: SettingsItem) {
	return items == null ? -1 : items.findIndex(x =>
		((x.id != null && x.id === item.id) || x.discriminator === item.discriminator));
}
