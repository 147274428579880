import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Utils} from 'kn-utils';
import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnFormsModule} from 'kn-forms';
import {ComponentDeactivateGuard} from 'kn-modal';

import {KnAuditDisplayModule} from 'common-web/forms';
import {KnGridPanelModule} from 'common-web/grid';
import {KnDatagridModule} from 'kn-datagrid';

import {RolesComponent} from './roles.component';
import {DatabaseRolesComponent} from './database-roles.component';
import {RolesTableComponent} from './roles-table.component';
import {RolesStoreService} from './roles-store.service';
import {PermissionActionsSelectComponent} from './permission-actions-select.component';
import {RolesViewConfig} from './roles-view.config';

import {routing} from './roles-view.routes';
import {authorization} from './roles-view.permissions';

export class RolesViewOptions extends RolesViewConfig { }

export function provideRolesViewConfig(config: RolesViewOptions) {
	return Utils.object.defaults(config, {
		databaseUriKey: 'database'
	});
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KnCommonModule.forChild(),
		KnComponentsModule,
		KnFormsModule.forChild(),
		KnDatagridModule,
		KnGridPanelModule,
		KnAuditDisplayModule,
		routing,
		authorization
	],
	declarations: [
		RolesComponent,
		DatabaseRolesComponent,
		RolesTableComponent,
		PermissionActionsSelectComponent
	],
	providers: [
		ComponentDeactivateGuard
	]
})
export class KnRolesViewModule {
	public static forChild(config?: Partial<RolesViewOptions>): ModuleWithProviders<KnRolesViewModule> {
		return {
			ngModule: KnRolesViewModule,
			providers: [
				{
					provide: RolesViewOptions, useValue: config ? config : {}
				}, {
					provide: RolesViewConfig,
					useFactory: provideRolesViewConfig,
					deps: [RolesViewOptions]
				},
				RolesStoreService
			]
		};
	}
}
