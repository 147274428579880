import {Component, ViewEncapsulation, Input, Output, Inject, forwardRef, OnInit, HostBinding, EventEmitter, ViewChild, Optional, ElementRef} from '@angular/core';
import {BooleanField, coerceBoolean} from 'kn-common';
import {KnRadioGroup} from './radio-group.directive';

let nextUniqueId = 0;

@Component({
	selector: 'kn-radio',
	templateUrl: 'radio.html',
	styleUrls: ['radio.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnRadio implements OnInit {
	private _value: any = null;
	private _checked: boolean = false;
	private _disabled: boolean = false;
	private _focused: boolean = false;

	@HostBinding('class.focused')
	public get focused() {
		return this._focused;
	}

	public get inputId(): string {
		return `${this.id}-input`;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-radio-${nextUniqueId++}`;

	@Input() public tabindex: number = null;
	@Input() public type: string = 'text';
	@Input() public name: string = null;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input()
	public get disabled(): boolean {
		return this._disabled || (this._radioGroup != null && this._radioGroup.disabled);
	}

	public set disabled(value: boolean) {
		this._disabled = coerceBoolean(value);
	}

	public get checked(): boolean {
		return this._checked;
	}

	@Input() public set checked(value: boolean) {
		if (value !== this._checked) {
			this._checked = value;
			if (value && this._radioGroup && this._radioGroup.value !== this.value) {
				this._radioGroup.change(this.value, null);
			}
			else if (!value && this._radioGroup && this._radioGroup.value === this.value) {
				this._radioGroup.change(null, null);
			}
		}
	}

	public get value(): any {
		return this._value;
	}

	@Input() public set value(value: any) {
		if (value !== this._value) {
			this._value = value;
			if (this._radioGroup != null) {
				if (!this.checked) {
					this.checked = this._radioGroup.value === value;
				}
				if (this.checked) {
					this._radioGroup.value = this.value;
				}
			}
		}
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();

	@ViewChild('input', { static: true })
	public _inputElement: ElementRef;

	private readonly _radioGroup: KnRadioGroup;

	public constructor(
			@Optional() @Inject(forwardRef(() => KnRadioGroup)) radioGroup: KnRadioGroup) {
		this._radioGroup = radioGroup;
	}

	public ngOnInit() {
		if (this._radioGroup) {
			this.checked = this._radioGroup.value === this._value;
			this.name = this._radioGroup.name;
		}
	}

	public focus() {
		this._inputElement.nativeElement.focus();
	}

	/** @internal */
	public handleFocus(event: FocusEvent) {
		this._focused = true;
		this.focusEvent.emit(event);
	}

	/** @internal */
	public handleBlur(event: FocusEvent) {
		this._focused = false;
		this.blurEvent.emit(event);
		if (this._radioGroup) {
			this._radioGroup.touch();
		}
	}

	/** @internal */
	public handleChange(event: Event) {
		this.changeEvent.emit();
		this._checked = true;
		if (this._radioGroup) {
			this._radioGroup.change(this.value, event);
			this._radioGroup.touch();
		}
		event.stopPropagation();
	}

	/** @internal */
	public handleClick(event: Event) {
		if (event.target != this._inputElement.nativeElement) {
			this._inputElement.nativeElement.click();
			return;
		}
		this.focus();
	}
}
