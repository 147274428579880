import {NgModule} from '@angular/core';
import {KnMenu} from './menu.component';
import {KnMenuActivator} from './menu-activator.component';
import {KnMenuContent} from './menu-content.component';
import {KnPortalModule} from 'kn-overlay';

export const KN_MENU_DIRECTIVES = [
	KnMenu,
	KnMenuActivator,
	KnMenuContent
];

@NgModule({
	imports: [KnPortalModule, KnPortalModule],
	declarations: [KN_MENU_DIRECTIVES],
	exports: [KN_MENU_DIRECTIVES]
})
export class KnMenuModule { }
