import {Component, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {UriContext} from 'kn-http';
import {UserSetting, UserSettingsFactory} from 'kn-user-settings';
import {Model as GridModel} from 'kn-datagrid';
import {Model as FilterModel} from 'kn-query-filter';
import {SessionStorage} from 'kn-storage';
import {AbstractGridViewComponent} from 'common-web/grid';

import {ViewTemplateEditService} from './view-template-edit.service';
import {ViewTemplatesGridData} from './view-templates-grid-data.service';
import * as CommonModel from 'common-web/model';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';

@Component({
	selector: 'kn-view-templates-view',
	templateUrl: 'view-templates-view.html'
})
export class ViewTemplatesViewComponent extends AbstractGridViewComponent<CommonModel.ViewTemplate> {
	@UserSetting('ViewTemplatesViewComponent.gridSetting@0.1.0') public gridSetting: GridModel;
	@UserSetting('ViewTemplatesViewComponent.defaultFilter@0.1.0') public defaultFilter: FilterModel;
	@SessionStorage('ViewTemplatesViewComponent.sessionFilter') public sessionFilter: FilterModel;
	public view = /* i18n */ 'viewTemplates';

	public constructor(
			injector: Injector,
			userSettingsFactory: UserSettingsFactory,
			private readonly _viewTemplatesResource: ViewTemplatesResourceService,
			gridData: ViewTemplatesGridData,
			viewTemplateEditService: ViewTemplateEditService) {
		super(injector, gridData, viewTemplateEditService);
		userSettingsFactory.linkComponent(this.constructor);
	}

	protected _fetcher(context: UriContext): Observable<(CommonModel.ViewTemplate)[]> {
		return this._viewTemplatesResource.query(context);
	}
}
