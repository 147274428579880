import {Component, Input} from '@angular/core';
import {Description, Model} from './types';

@Component({
	selector: 'kn-query-filter',
	templateUrl: 'query-filter.html'
})
export class KnQueryFilter {
	@Input() public description: Description;
	@Input() public model: Model;
}
