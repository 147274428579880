import {Injectable} from '@angular/core';
import {EntityStore, AbstractGraphStore} from 'common-web/rest';
import {ViewTemplateModel} from './view-template-model';
import * as CommonModel from 'common-web/model';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';

@Injectable()
export class ViewTemplateEditService extends AbstractGraphStore<ViewTemplateModel> {
	public constructor(viewTemplatesResource: ViewTemplatesResourceService) {
		super();
		this._mappedStore = {
			key: 'viewTemplate',
			store: new EntityStore<CommonModel.ViewTemplate>(viewTemplatesResource, 'id')
		};
	}
}
