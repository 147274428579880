import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'timespan' })
export class TimespanPipe implements PipeTransform {
	public transform(value: number | string, format: string = 'invariant'): string {
		if (value == null) {
			return null;
		}
		const timespan = Utils.isString(value)
			? Utils.timespan.parse(value as string) : (value as number);
		return Utils.timespan.toString(timespan, format);
	}
}
