import {Observable, Subject, ReplaySubject} from 'rxjs';
import {UriContext} from 'kn-http';
import {AbstractResource} from './abstract-resource';

export class LiveFeed<T> {
	private readonly _subject$: Subject<T[]>;
	private readonly _replaySubject$: ReplaySubject<T[]>;
	private readonly _feed$: Observable<T[]>;
	private readonly _replayFeed$: Observable<T[]>;
	private _blank = true;
	private _snapshot: T[];

	public constructor(
			protected _resource: AbstractResource<T>,
			protected _context: UriContext) {
		this._subject$ = new Subject<T[]>();
		this._replaySubject$ = new ReplaySubject<T[]>(1);
		this._feed$ = this._subject$.asObservable();
		this._replayFeed$ = this._replaySubject$.asObservable();
	}

	public get context(): UriContext {
		return this._context;
	}

	public get snapshot(): T[] {
		return this._snapshot;
	}

	public get feed(): Observable<T[]> {
		return this._feed$;
	}

	public get eagerFeed(): Observable<T[]> {
		if (this._blank) {
			this.refresh();
		}
		return this._replayFeed$;
	}

	public get isSubscribed() {
		return this._replaySubject$.observers.length > 0 || this._subject$.observers.length > 0;
	}

	public refresh() {
		this._blank = false;
		this._resource.query(this._context)
			.subscribe(
				next => this.updateValue(next),
				err => {
					this._subject$.error(err);
					this._replaySubject$.error(err);
				});
	}

	public updateValue(value: T[]) {
		this._snapshot = value;
		this._subject$.next(value);
		this._replaySubject$.next(value);
	}
}
