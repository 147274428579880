import {Injectable, Inject, Optional} from '@angular/core';
import {Authentication} from './authentication/authentication.type';
import {AuthorizationService} from './authorization/authorization.service';
import {KN_AUTH_SERVICE_TOKEN} from './authentication/auth-service.token';
import {PERMISSIONS} from './permissions.token';
import {UserService} from './user.service';
import {Permissions, Permission} from './configuration.types';

@Injectable()
export class RootUserService extends UserService {
	public constructor(
			@Inject(PERMISSIONS) @Optional() private readonly _permissions: Permissions,
			@Inject(KN_AUTH_SERVICE_TOKEN) authentication: Authentication,
			authorization: AuthorizationService) {
		super(authentication, authorization);
	}

	public get permissions() {
		return this._permissions;
	}

	public mount(scope: string[], permissions: Permissions) {
		let mountingPoint = { children: this._permissions } as Permission;
		for (const segment of scope) {
			mountingPoint = mountingPoint.children.find(x => x.resource === segment);
			if (mountingPoint == null) {
				throw new Error('Cannot mount permissions nodes to not existed scope.');
			}
		}
		mountingPoint.children = permissions;
	}
}
