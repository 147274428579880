import {Observable, PartialObserver, Subject, merge as observableMerge, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {AbstractAutocompletition, AutocompleteResult, AutocompleteQueryResult} from './abstract-autocompletition';

export interface RemoteAutocompleteBondConfig {
	debounceTime?: number;
	limit?: number;
	minLength?: number;
	cachedQueries?: number;
}

type AutocompleteQuery<T> = {
	value: any;
	query: string;
	getter: { (item: T): any };
};

type AutocompleteKeyedResult<T> = {
	key: AutocompleteQuery<T>;
	result: AutocompleteResult<T>;
};

type CacheItem<T> = {
	key: AutocompleteQuery<T>;
	result: AutocompleteResult<T>;
};

export class RemoteAutocompletition<T> extends AbstractAutocompletition<T> {
	private readonly _query$ = new Subject<AutocompleteQuery<T>>();
	private readonly _store$ = new Observable<AutocompleteKeyedResult<T>>();
	private readonly _cache: CacheItem<T>[] = [];

	public constructor(
			private readonly _fetcher: { (context: { [key: string]: any }): Observable<T[]> },
			private readonly _config?: RemoteAutocompleteBondConfig) {
		super();

		this._config = Utils.object.defaults(this._config || {}, {
			debounceTime: 500,
			limit: 7,
			minLength: 1,
			cachedQueries: 3
		});

		const predicate = (value: AutocompleteQuery<T>) => {
			return value.query == null || value.query.length < this._config.minLength;
		};

		const [initial$, query$] = Rx.partition(predicate)(this._query$);

		const initialFetcher$ = initial$.pipe(
			Rx.mergeMap(key => this._initialFetch(key).pipe(Rx.map(result => ({ key, result }))))
		);

		const queryFetcher$ = query$.pipe(
			Rx.debounceTime(this._config.debounceTime),
			Rx.switchMap(key => this._queryFetch(key).pipe(Rx.map(result => ({ key, result }))))
		);

		this._store$ = observableMerge(initialFetcher$, queryFetcher$)
			.pipe(Rx.share()) as Observable<AutocompleteKeyedResult<T>>;
	}

	private _initialFetch(query: AutocompleteQuery<T>) {
		return this._fetcher({ value: query.value, limit: this._config.limit + 1 }).pipe(
			Rx.map(next => {
				const partial = next && next.length > this._config.limit;
				if (partial) {
					next.splice(this._config.limit, 1);
				}
				return ({
					datasource: next,
					constrainsNotMet: false,
					partial: partial,
					empty: query && query.value && next.length === 0
				});
			}));
	}

	private _queryFetch(query: AutocompleteQuery<T>) {
		return observableOf(query).pipe(
			Rx.map(next => {
				let selectedCount = 0;
				if (next.value != null) {
					selectedCount = Array.isArray(next.value) ? next.value.length : 1;
				}
				return Object.assign({}, next, { limit: this._config.limit + selectedCount });
			}),
			Rx.switchMap(
				next => this._fetcher({
					value: next.value,
					query: next.query,
					limit: next.limit + 1
				})
				.pipe(Rx.map(result => Object.assign(next, { result })))
			),
			Rx.map(next => {
				let partial = false;
				const values = Utils.array.box(next.value) || [];
				if (next.result.length > next.limit) {
					partial = true;
					for (let i = next.result.length - 1; i >= 0; i--) {
						if (values.indexOf(next.getter(next.result[i])) === -1) {
							next.result.splice(i, 1);
							break;
						}
					}
				}
				return {
					datasource: next.result,
					constrainsNotMet: false,
					partial: partial,
					empty: next.result.every(x => values.indexOf(next.getter(x)) !== -1)
				};
			})
		);
	}

	private _executeQuery(key: AutocompleteQuery<T>, observer: PartialObserver<AutocompleteResult<T>>) {
		const subscription = this._store$
			.pipe(
				Rx.first(next => next.key === key),
				Rx.map(next => next.result)
			)
			.subscribe(observer);
		this._query$.next(key);
		return subscription;
	}

	public querySearch(value: any, query: string, getter: { (item: T): any }): AutocompleteQueryResult<T> {
		const key = { value, query, getter };
		const cacheItem = this._cache.find(x => Utils.equal(x.key, key));

		if (cacheItem != null) {
			return Promise.resolve(cacheItem.result);
		}

		return new Promise((resolve, reject) => {
			const observer: PartialObserver<AutocompleteResult<T>> = {
				next: result => {
					if (this._cache.length >= this._config.cachedQueries) {
						this._cache.splice(0, 1 + this._cache.length - this._config.cachedQueries);
					}
					this._cache.push({ key, result });
					resolve(result);
				},
				error: x => reject(x)
			};
			this._executeQuery(key, observer);
		}) as Promise<T[]> | Promise<AutocompleteResult<T>>;
	}
}
