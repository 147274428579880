import {Injector, ElementRef} from '@angular/core';
import {ElementRenderer, ElementRendererFactory, CellContext} from 'kn-datagrid';

export class OwnershipRendererFactory extends ElementRendererFactory {
	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, prefix: string): ElementRenderer<T> {
		return (element: ElementRef, value: string, context: CellContext<any>, firstChange: boolean): string => {
			if (value == null) {
				return null;
			}
			if (!value.startsWith(prefix)) {
				prefix = '';
			}
			const prefixSegments = prefix.split('/').filter(x => x);
			const segments = value.substr(prefix.length).split('/').filter(x => x);
			const title = (value && ` title="${value}"`) || '';
			const html = (prefixSegments.length ? '' : '/')
				+ segments.map(x => `<span>${x}</span>`).join('/');
			return `<span class="user-ownership"${title}>${html}</span>`;
		};
	}
}
