import {NgModule, Optional, SkipSelf, ModuleWithProviders} from '@angular/core';
import {MemoryStorageService} from './memory-storage.service';
import {SessionStorageService} from './session-storage.service';
import {LocalStorageService} from './local-storage.service';

@NgModule()
export class KnStorageModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnStorageModule) {
		if (parentModule) {
			throw new Error('KnStorageModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(): ModuleWithProviders<KnStorageModule> {
		return {
			ngModule: KnStorageModule,
			providers: [
				MemoryStorageService,
				SessionStorageService,
				LocalStorageService
			]
		};
	}
}
