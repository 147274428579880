import {ChangeDetectionStrategy, Component, ComponentRef, Input, OnInit, SimpleChanges} from '@angular/core';
import {EntityBase} from 'common-web/model';
import {DefaultTarget} from '../lookup-component-renderer-factory';
import {saveAs} from 'file-saver';
import {CellContext} from 'kn-datagrid';
import {Utils} from 'kn-utils';
import {Observable, isObservable, of} from 'rxjs';

@Component({
	selector: 'kn-file-saver',
	template: '<a href="javascript:void(0)" (click)="save($event)" *ngIf="fileContent" [innerHTML]="clickable"></a>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnFileSaver implements OnInit, DefaultTarget {
	@Input() public clickable: string;
	@Input() public contentType: string;
	@Input() public fileContent: string | Blob | Observable<string | Blob>;
	@Input() public fileName: string;

	public constructor() { /* empty */ }

	public ngOnInit() { /* empty */ }

	private _base64toBlob(base64Data: string, contentType: string) {
		return new Blob(Utils.object.base64Decode(base64Data), { type: contentType });
	}

	public save(event: Event) {
		if (this.fileContent == null) {
			return;
		}
		const fileContent = isObservable(this.fileContent) ? this.fileContent : of(this.fileContent);
		fileContent.subscribe(next => {
			if (!(next instanceof Blob)) {
				next = this._base64toBlob(next, this.contentType);
			}
			saveAs(next, this.fileName || 'file');
		});
		event.stopPropagation();
		event.preventDefault();
	}

	public renderDefaultTarget(): any {
		return this.contentType;
	}

	public static mapping(
			clickable: string,
			contentType: string,
			idProperty?: string,
			fileName?: string | { (value: EntityBase, context: CellContext<EntityBase>): string}) {
		return (component: ComponentRef<KnFileSaver>, value: EntityBase, context: CellContext<EntityBase>): SimpleChanges => {
			component.instance.clickable = clickable;
			component.instance.contentType = contentType;
			if (idProperty) {
				component.instance.fileContent = Utils.object.get(context.node.item, idProperty);
			}
			else {
				component.instance.fileContent = value as any;
			}
			if (fileName) {
				component.instance.fileName = (Utils.isString(fileName)
					? fileName
					: (fileName as (item: EntityBase, context: CellContext<EntityBase>) => string)(value, context)) as string;
			}
			return {};
		};
	}
}
