<!-- <tr>
	<th *ngFor="let column of visibleColumns">
	{{ getSectionsNames(column) }}
	</th>
</tr> -->
<tr knReordable>
	<th *ngFor="let column of visibleColumns"
			knColumnContextMenuActivator
			[style.width.px]="column.model.width"
			[ngClass]="getCellClasses(column)" #contextMenuOrigin>
		<kn-resizable-handler *ngIf="isResizable(column)"
				[column]="column.model"
				(columnChange)="onColumnChange($event)"></kn-resizable-handler>
		<kn-reordable-handler class="header"
				[model]="model"
				[description]="description"
				(reorderColumn)="reorderColumn.emit($event)"
				[column]="column.model"
				[enable]="description.columnsReordering">
			<a class="sortable" *ngIf="isSortable(column)"
					(click)="cycleSortBy(column)"
					[ngClass]="getColumnSortingClass(column)">{{ getLabel(column) }}</a>
			<span *ngIf="!isSortable(column)">{{ getLabel(column) }}</span>
		</kn-reordable-handler>
		<kn-column-context-menu
			[columns]="columns"
			[description]="description"
			[model]="model"
			(modelEvent)="onModelEvent($event)"
			[column]="column"
			[portalOrigin]="contextMenuOrigin"
			(commitModel)="commitModel.emit()"></kn-column-context-menu>
	</th>
</tr>
<tr class="loader-bar">
	<th [attr.colspan]="visibleColumns | property: 'length'"></th>
</tr>
