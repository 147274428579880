import {Component, Input, Output, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy, ViewChildren, QueryList} from '@angular/core';
import {Subscription} from 'rxjs';
import {KnTableRow} from './table-row.directive';
import {Description, RowItem, Column} from '../types';
import {Node} from '../model/node';
import {ChangeNotifier} from '../services/change-notifier.service';
import {SelectionService} from '../services/selection.service';
import {ValueResolveUtils} from '../internal-utils';

@Component({
	selector: 'tbody[knTableBody]',
	templateUrl: 'table-body.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnTableBody implements OnInit, OnDestroy {
	private _subscription: Subscription;

	@Input() public description: Description<RowItem>;
	@Input() public visibleColumns: Column<RowItem>[];
	@Input() public tree: Node<RowItem>;
	@Output() public rowSelect: EventEmitter<RowItem> = new EventEmitter<RowItem>();
	@Output() public rowActivate: EventEmitter<RowItem> = new EventEmitter<RowItem>();

	public constructor(
			private readonly _changeNotifier: ChangeNotifier,
			private readonly _changeDetector: ChangeDetectorRef,
			private readonly _selection: SelectionService) {
	}

	public ngOnInit() {
		this._subscription = this._changeNotifier
				.subscribe(() => this._changeDetector.markForCheck());
	}

	public ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	@ViewChildren(KnTableRow)
	public rows: QueryList<KnTableRow>;

	public isSelectable(node: Node<RowItem>): boolean {
		return this._selection.isSelectable(node, this.description);
	}

	public nodeTrackBy(index: any, node: any): any {
		return node.item || null;
	}

	public getRowClases(node: Node<RowItem>) {
		return ValueResolveUtils.resolveRowClasses(this.description.rows, node.item);
	}

	public getCellClasses(node: Node<RowItem>, column: Column<RowItem>) {
		return ValueResolveUtils.resolveCellClasses<RowItem>(column, node.item);
	}

	public refreshNodes(nodes: Node<RowItem>[]) {
		if (this.rows == null) {
			return;
		}
		this.rows.map((item, index, array) => {
			if (nodes.indexOf(item.node) === -1) {
				return;
			}
			item.refreshCells();
		});
	}

	public refreshRowItems(items: RowItem[]) {
		if (this.rows == null) {
			return;
		}
		this.rows.map((item, index, array) => {
			if (items.indexOf(item.node.item) === -1) {
				return;
			}
			item.refreshCells();
		});
	}

	public scrollToView(item: RowItem) {
		const row = this.rows.find(x => x.node.item === item);
		row && row.scrollIntoView();
	}
}
