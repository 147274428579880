import {AbstractCloner, BlendModel, BlendSequence, Mirror} from 'common-web/rest';
import {User} from 'common-web/model';
import {UserRole, Role} from '../model/common-database.types';

export abstract class AbstractUserRolesClonerService extends AbstractCloner {
	public constructor(mirrors: Mirror[]) {
		super(mirrors);
	}

	protected _blend(model: BlendModel): BlendSequence {
		return [
			{ key: 'roles', blender: this._rolesBlender.bind(this, model) },
			{ key: 'userRoles', blender: this._userRolesBlender.bind(this, model) }
		];
	}

	private _rolesBlender(model: BlendModel) {
		const { target, source } = this._partition<Role>(model, 'roles');
		const createFunctor = (item: Role) => !target.some(x => x.name === item.name);
		const roles = source.filter(createFunctor);
		roles.forEach(role => role.rolePermissions.forEach(x => delete x.id));
		return this._createDiff<Role>({
			entitiesToCreate: roles
		});
	}

	private _userRolesBlender(model: BlendModel) {
		const { target, source } = this._partition<UserRole>(model, 'userRoles');
		const roles = this._partition<Role>(model, 'roles');
		const users = this._partition<User>(model, 'users');

		const diff = this._createDiff<UserRole>();
		for (const item of source) {
			const assigned = {
				role: roles.source.find(x => x.id === item.roleId),
				user: users.source.find(x => x.uid === item.userUid)
			};
			if (assigned.role == null || assigned.user == null) {
				continue;
			}
			const targetAssigned = {
				role: roles.target.find(x => x.name === assigned.role.name),
				user: users.target.find(x => x.uid === assigned.user.uid)
			};
			if (targetAssigned.role == null || targetAssigned.user == null) {
				continue;
			}
			if (target.some(x => x.roleId === targetAssigned.role.id
					&& x.userUid === targetAssigned.user.uid)) {
				continue;
			}
			item.roleId = targetAssigned.role.id;
			item.userUid = targetAssigned.user.uid;
			diff.entitiesToCreate.push(item);
		}
		return diff;
	}
}
