import {Indexer} from '../types';

export enum ChangeAction {
	Added,
	Modified,
	Deleted
}

export interface ChangeEntry {
	action: ChangeAction;
	table: string;
	indexer: Indexer;
}
