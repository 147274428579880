import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ToastService} from 'kn-modal';
import {ActiveMonitor, I18nService} from 'kn-shared';
import {Utils} from 'kn-utils';
import {combineLatest} from 'rxjs';
import {UserService} from 'kn-user';
import {appConfig} from '../../../app.config';
import {ConnectionsResourceService} from '../../../rest-foundation/resources/connections-resource.service';
import {DevicesResourceService} from '../../../rest-foundation/resources/devices-resource.service';
import {SitesResourceService} from '../../../rest-foundation/resources/sites-resource.service';
import * as Model from '../../../model/customer-database.types';
import * as WebModel from '../../../model/web.types';

export interface DevicesOnSite {
	site: Model.Site;
	devices: (Model.Device & { connection: WebModel.Connection })[];
};

@Component({
	selector: 'app-dashboard',
	templateUrl: 'dashboard.html'
})
export class DashboardComponent implements OnInit, OnDestroy {
	protected _loadingMonitor = new ActiveMonitor(5, 500);
	protected _disposables: Function[] = [];

	public devicesOnSites: DevicesOnSite[];

	public constructor(
			@Inject(DOCUMENT) private readonly _document: Document,
			private readonly _i18n: I18nService,
			private readonly _toast: ToastService,
			private readonly _user: UserService,
			private readonly _sitesResource: SitesResourceService,
			private readonly _devicesResource: DevicesResourceService,
			private readonly _connectionsResource: ConnectionsResourceService) {
	}

	public ngOnInit() {
		const sites$ = this._sitesResource.query({ query: { only: ['id', 'name', 'addressLines', 'town', 'zip'] } });
		const devices$ = this._devicesResource.query();
		const connections$ = this._connectionsResource.query();
		const subscription = combineLatest(sites$, devices$, connections$)
			.lift(this._loadingMonitor.operator())
			.subscribe(
				next => this.devicesOnSites = this._buildDevicesOnSites(next[0], next[1], next[2]),
				error => this._toast.show(this._i18n.t('Loading failed.'), this._retriveErrorMessage(error)));
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public navigate(device: Model.Device & { connection: WebModel.Connection }) {
		if (device.connection && device.connection.live) {
			this._document.defaultView.open(
				`${appConfig.devicesUrl}/${device.macAddress}/`,
				'_blank'
			);
		}
	}

	public canConnect(device: Model.Device) {
		return this._user.can('connect', 'item');
	}

	public formatAddress(site: Model.Site){
		if (site.addressLines && site.zip && site.town) {
			return `${site.addressLines}, ${site.zip} ${site.town}`;
		}
		if (site.addressLines && (site.zip || site.town)) {
			return `${site.addressLines}, ${site.zip || site.town}`;
		}
		if (site.addressLines) {
			return site.addressLines;
		}
		return site.zip || site.town;
	}

	protected _retriveErrorMessage(error: any) {
		if (Utils.isString(error.statusText)) {
			return error.statusText as string;
		}
		return error.statusText || error.message || error;
	}

	private _buildDevicesOnSites(sites: Model.Site[], devices: Model.Device[], connections: WebModel.Connection[]) {
		const devicesOnSites = sites.map(site => ({ site, devices: [] }));
		for (const device of devices) {
			const devicesOnSite = devicesOnSites.find(x => x.site.id === device.siteId);
			if (devicesOnSite != null) {
				devicesOnSite.devices.push({
					connection: connections.find(x => x.macAddress === device.macAddress),
					...device
				});
			}
		}
		return devicesOnSites;
	}
}
