import {AbstractElementBreaker} from './breakers/abstract-element-breaker';
import {AbstractPreprocessor} from './preprocessors/abstract-preprocessor';
import {DomPageIterator} from './dom-page-iterator';
import {BreakableAnnotationFactory} from './utils/breakable-annotation-factory';
import {BreakableType, NodeBreakables} from './types';

export class DomPagination {
	public breakers: AbstractElementBreaker[] = [];
	public preprocessors: AbstractPreprocessor[] = [];

	public createPageIterator(doc: Document): DomPageIterator {
		for (const preprocessor of this.preprocessors) {
			doc.body = preprocessor.process(doc.body) as HTMLElement;
		}
		this._annotateBreakPoints(doc.body);
		return new DomPageIterator(doc, this.breakers);
	}

	private _annotateBreakPoints(node: Node, annotationFactory = new BreakableAnnotationFactory()) {
		let prevBreakables: NodeBreakables = null;
		for (let i = 0; i < node.childNodes.length; i++) {
			const child = node.childNodes[i];
			const breakables = this._getBreakables(child);
			if (prevBreakables != null) {
				if (breakables != null) {
					const breakable = this._combineBreakables(prevBreakables.after, breakables.before);
					if (breakable !== BreakableType.Avoid) {
						node.insertBefore(annotationFactory.create(breakable), child);
						i++;
					}
				}
			}
			if (breakables != null) {
				if (breakables.inside !== BreakableType.Avoid) {
					this._annotateBreakPoints(child, annotationFactory);
				}
				prevBreakables = breakables;
			}
		}
	}

	private _getBreakables(node: Node) {
		for (const breaker of this.breakers) {
			if (breaker.isSupported(node)) {
				return breaker.getBreakables(node);
			}
		}
		throw new Error(`No registered breaker for node "${node}".`);
	}

	private _combineBreakables(...breakables: BreakableType[]): BreakableType {
		return breakables.reduce((previousValue, currentValue) => {
			if (previousValue === BreakableType.Always || currentValue === BreakableType.Always) {
				return BreakableType.Always;
			}
			if (previousValue === BreakableType.Avoid || currentValue === BreakableType.Avoid) {
				return BreakableType.Avoid;
			}
			return BreakableType.Auto;
		});
	}
}
