import {AbstractElementBreaker} from './breakers/abstract-element-breaker';
import {BreakableAnnotationIterator} from './utils/breakable-annotation-iterator';
import {BreakableAnnotation, BreakableType} from './types';

export class DomPageIterator {
	private _final: boolean;
	private readonly _root: Node;
	private _anchorAnnotation: BreakableAnnotation;

	public constructor(
			private readonly _doc: Document,
			private readonly _breakers: AbstractElementBreaker[]) {
		this._root = this._doc.body.cloneNode(true);
	}

	public nextPage({ size }: { size: number[] }): boolean {
		if (this._final) {
			return false;
		}
		let final = true;
		const page = this._cloneAndAnchorToDocument(this._root);

		if (this._anchorAnnotation != null) {
			const foreginIterator = new BreakableAnnotationIterator(page);
			while (foreginIterator.nextNode()) {
				if (foreginIterator.currentAnnotation.id === this._anchorAnnotation.id) {
					break;
				}
			}
			this._extract(page, foreginIterator.currentNode, null);
			foreginIterator.currentNode.parentNode.removeChild(foreginIterator.currentNode);
		}

		const breakPointIterator = new BreakableAnnotationIterator(page);
		while (breakPointIterator.nextNode()) {
			if (breakPointIterator.currentAnnotation.breakable === BreakableType.Always) {
				break;
			}
		}

		if (breakPointIterator.currentAnnotation
				&& breakPointIterator.currentAnnotation.breakable === BreakableType.Always) {
			this._extract(page, null, breakPointIterator.currentNode);
			final = false;
		}

		while (true) {
			const epsilon = 5;
			let currentSize = this._getPageSize(page, false);
			if (currentSize[1] - epsilon <= size[1]) {
				currentSize = this._getPageSize(page, true);
				if (currentSize[1] - epsilon <= size[1]) {
					break;
				}
			}

			if (breakPointIterator.previousNode() == null) {
				throw new Error('Too large.');
			}

			this._extract(page, null, breakPointIterator.currentNode);
			final = false;
		}

		this._anchorAnnotation = breakPointIterator.currentAnnotation;

		this._final = final;
		return true;
	}

	private _cloneAndAnchorToDocument(node: Node): Node {
		this._doc.body = node.cloneNode(true) as HTMLElement;
		return this._doc.body;
	}

	private _extract(node: Node, fromNode: Node, toNode: Node) {
		let inside = fromNode == null;
		let i = 0;
		while (i < node.childNodes.length) {
			for (i = 0; i < node.childNodes.length; i++) {
				const child = node.childNodes[i];
				if (!inside && child.contains(fromNode)) {
					this._breakerExtract(node, child, null);
					this._extract(child, fromNode, toNode);
					inside = true;
					break;
				}
				else if (inside && child.contains(toNode)) {
					this._breakerExtract(node, null, child);
					this._extract(child, null, toNode);
					return;
				}
			}
		}
	}

	private _breakerExtract(node: Node, fromNode: Node, toNode: Node) {
		for (const breaker of this._breakers) {
			if (breaker.isSupported(node)) {
				return breaker.extract(node, fromNode, toNode);
			}
		}
		throw new Error(`No registered breaker for node "${node}".`);
	}

	private _getPageSize(root: Node, accurate: boolean = false): number[] {
		let size: number[] = [];
		if (root.nodeType === Node.ELEMENT_NODE) {
			const boundingBox = (root as Element).getBoundingClientRect();
			size = [boundingBox.left + boundingBox.width, boundingBox.top + boundingBox.height];
		}
		else {
			accurate = true;
		}
		if (accurate) {
			const iterator = document.createNodeIterator(root, NodeFilter.SHOW_ELEMENT);
			let node: Node;
			// eslint-disable-next-line no-cond-assign
			while (node = iterator.nextNode()) {
				const boundingBox = (node as Element).getBoundingClientRect();
				if (size[0] < boundingBox.left + boundingBox.width) {
					size[0] = boundingBox.left + boundingBox.width;
				}
				if (size[1] < boundingBox.top + boundingBox.height) {
					size[1] = boundingBox.top + boundingBox.height;
				}
			}
		}
		return size;
	}
}
