import {NgModule} from '@angular/core';
import {KnExpansionModule} from './expansion/expansion.module';
import {KnMenuModule} from './menu/menu.module';
import {KnSpinnerModule} from './spinner/spinner.module';
import {KnStickyModule} from './sticky/sticky.module';
import {KnTabsModule} from './tabs/tabs.module';

const KN_COMPONENTS_MODULES = [
	KnExpansionModule,
	KnMenuModule,
	KnStickyModule,
	KnTabsModule,
	KnSpinnerModule
];

@NgModule({
	exports: [KN_COMPONENTS_MODULES]
})
export class KnComponentsModule { }
