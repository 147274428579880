import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnDatagridModule} from 'kn-datagrid';
import {KnQueryFilterModule} from 'kn-query-filter';
import {KnFormsModule} from 'kn-forms';

import {KnPresetSelector} from './widgets/preset-selector/preset-selector.component';
import {KnGridPreset} from './widgets/grid-preset.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnDatagridModule,
		KnQueryFilterModule,
		KnFormsModule,
		KnCommonModule,
		KnComponentsModule
	],
	declarations: [KnGridPreset, KnPresetSelector],
	exports: [KnGridPreset]
})
export class KnGridPanelPresetModule { }
