import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PrintContext} from 'kn-print';
import {QueryFilterBond, DatagridBond, CompountBonder} from 'common-web/grid';
import {KnPrintButton} from './print-button/print-button.component';
import {GridPrintRendererFilter} from './types';

@Component({
	selector: 'kn-grid-print',
	template: '<kn-print-button [prefix]="prefix" [views]="views" [context]="context" [filter]="filter" (expandedChange)="onExpandedChange($event)"><ng-content></ng-content></kn-print-button>'
})
export class KnGridPrint {
	private _datagrid: DatagridBond<any>;
	private _filter: QueryFilterBond;
	private _context: PrintContext | { (): PrintContext };

	@Input() public prefix: string;
	@Input() public views: string[];
	@Input() public filter: GridPrintRendererFilter = GridPrintRendererFilter.All;
	@Input() public expanded: boolean = false;

	@Output() public expandedChange = new EventEmitter<boolean>();

	@ViewChild(KnPrintButton, { static: true })
	public printButton: KnPrintButton;

	@Input() public set bond(value: DatagridBond<any> | QueryFilterBond | CompountBonder) {
		if (value instanceof DatagridBond) {
			this._datagrid = value;
		}
		else if (value.hasOwnProperty('datagrid')) {
			this._datagrid = (value as any)['datagrid'];
		}
		else {
			this._datagrid = null;
		}

		if (value instanceof QueryFilterBond) {
			this._filter = value;
		}
		else if (value.hasOwnProperty('filter')) {
			this._filter = (value as any)['filter'];
		}
		else {
			this._filter = null;
		}
	}

	public get context() {
		return this._context || this._getContext.bind(this);
	}

	@Input() public set context(value: PrintContext | { (): PrintContext }) {
		this._context = value;
	}

	public onExpandedChange(value: boolean) {
		this.expandedChange.emit(value);
	}

	public collapse() {
		this.printButton.collapse();
	}

	private _getContext() {
		const context: PrintContext = {
			date: new Date()
		};

		if (this._datagrid) {
			Object.assign(context, {
				items: this._datagrid.dataSource,
				selected: this._datagrid.selected,
				datagrid: {
					description: this._datagrid.description,
					model: this._datagrid.model
				}
			});
		}

		if (this._filter) {
			Object.assign(context, {
				filter: {
					description: this._filter.description,
					model: this._filter.model
				}
			});
		}

		return context;
	}
}
