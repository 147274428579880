export enum RippleState {
	FADING_IN,
	VISIBLE,
	FADING_OUT,
	HIDDEN
}

export interface RippleAnimationConfig {
	enterDuration: number;
	exitDuration: number;
}

export interface RippleConfig {
	color: string;
	centered: boolean;
	radius: number;
	persistent: boolean;
	animation: RippleAnimationConfig;
}

export class RippleGlobalConfig {
	public disabled: boolean;
	public animation: RippleAnimationConfig;
}
