import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('lowercase')
export class LowerCaseFilter implements PrintRendererFilter {
	public transform(value: string): string {
		return value == null ? null : `${value}`.toLowerCase();
	}
}
