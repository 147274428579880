import {CoreUtils} from './core-utils';

export class DateUtils {
	private static readonly _iso8601RegExp = /^(\d{4})(?:-(\d{2})(?:-(\d{2})(?:T(\d{2}):(\d{2})(?::(\d{2}))?(?:\.(\d+))?((?:[+-]\d{2}:?\d{2})|Z)?)?)?)?$/i;
	private static _localTimezone: string = undefined;

	/* Be tollerant to string inputs. Some widgets return Date or string without warning */
	public static toIso8601(dateOrStr: Date | string, format: string = null): string {
		if (dateOrStr == null) {
			return null;
		}
		if (CoreUtils.isString(dateOrStr)) {
			dateOrStr = this.fromIso8601(dateOrStr as string);
		}
		const date = dateOrStr as Date;

		const pad = (n: string, width: number) =>
			n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;

		const dateFunctor = () => pad('' + date.getFullYear(), 4)
			+ '-' + pad('' + (date.getMonth() + 1), 2)
			+ '-' + pad('' + date.getDate(), 2);

		const timeFunctor = () => pad('' + date.getHours(), 2)
			+ ':' + pad('' + date.getMinutes(), 2)
			+ ':' + pad('' + date.getSeconds(), 2)
			+ (date.getMilliseconds() ? ('.' + pad('' + date.getMilliseconds(), 3)) : '');

		switch (format) {
			case 'datetime':
			case 'datetime-local':
				return dateFunctor() + 'T' + timeFunctor();
			case 'date':
				return dateFunctor();
			case 'time':
				return timeFunctor();
			case 'full':
				/* falls through */
			default:
				return dateFunctor() + 'T' + timeFunctor() + DateUtils.getTimezone(date);
		}
	}

	public static timezoneToOffset(zone: string): number {
		if (zone == null || zone === 'Z') {
			return 0;
		}
		const length = zone.length;
		const hours = parseInt(zone.substr(0, length - 2 - (zone[length - 3] === ':' ? 1 : 0)), 10);
		const minutes = parseInt(zone.substr(length - 2), 10);
		return -(hours * 60 + (hours >= 0 ? 1 : -1) * minutes);
	}

	public static fromIso8601(value: string, ignoreTimezone: boolean = false): Date {
		if (value == null || value.length === 0) {
			return null;
		}

		const matches = value.match(DateUtils._iso8601RegExp);
		if (matches == null) {
			throw new Error('Passed date is not in valid ISO8601 format.');
		}

		let date = new Date(+matches[1], (+matches[2] || 1) - 1, +matches[3] || 1,
			+matches[4] || 0, +matches[5] || 0, +matches[6] || 0,
			(+('0.' + matches[7]) || 0) * 1000);
		date.setFullYear(+matches[1]);

		if (matches[8] != null) {
			const offset = DateUtils.timezoneToOffset(matches[8]);
			date = new Date(date.getTime() - (date.getTimezoneOffset() - offset) * 60 * 1000);
			if (ignoreTimezone) {
				const newOffset = date.getTimezoneOffset();
				if (offset != newOffset) {
					date = new Date(date.getTime() - (offset - newOffset) * 60 * 1000);
				}
			}
		}
		return date;
	}

	public static getLocalTimezone(): string {
		if (DateUtils._localTimezone == null) {
			DateUtils._localTimezone = DateUtils.getTimezone(new Date());
		}
		return DateUtils._localTimezone;
	}

	public static getTimezone(date: Date): string {
		const offset = -date.getTimezoneOffset() / 60;
		let hoursOffset = Math.abs(offset);
		let minutesOffset = 0;
		if (!Number.isInteger(offset)) {
			hoursOffset = Math.floor(hoursOffset);
			minutesOffset = Math.round((Math.abs(offset) - hoursOffset) * 60);
		}
		let timezone = offset < 0 ? '-' : '+';
		timezone += (hoursOffset < 10 ? '0' : '') + hoursOffset;
		timezone += (minutesOffset < 10 ? '0' : '') + minutesOffset;
		return timezone;
	}
}
