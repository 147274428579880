import {Injectable} from '@angular/core';
import {pipe} from '../utils/pipe';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('with')
export class WithHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		const [value, ...filters] = content.params;
		return content.exec(pipe(config, value, ...filters));
	}
}
