import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {saveAs} from 'file-saver';
import {Http} from 'kn-http';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {ITEXT_ENDPOINT_URI_TOKEN} from './itext-endpoint-uri.token';
import {PRINT_HTTP_TOKEN} from '../print-http.token';
import {PrintConfiguration} from '../types';

export interface ItextPrintBackendOptions extends AbstractPrintBackendOptions {
	saveAs?: string;
}

@Injectable()
export class ItextPrintBackend extends AbstractPrintBackend {
	public constructor(
			@Inject(PRINT_HTTP_TOKEN) private readonly _http: Http,
			@Inject(ITEXT_ENDPOINT_URI_TOKEN) private readonly _uri: string) {
		super();
	}

	public get name(): string {
		return 'itextPdf';
	}

	public print(html: string, config?: PrintConfiguration, options?: ItextPrintBackendOptions): Observable<any> {
		return this._http.put(this._uri, html, { responseType: 'blob' })
			.pipe(Rx.tap(next => this._save(next.body, options)));
	}

	public printPreview(html: string, config?: PrintConfiguration, options?: ItextPrintBackendOptions): Observable<any> {
		return this._http.put(this._uri, html, { responseType: 'html' })
			.pipe(Rx.tap(next => next));
	}

	private _save(data: Blob, options?: ItextPrintBackendOptions) {
		if (options != null && options.saveAs != null) {
			saveAs(data, options.saveAs);
		}
	}
}
