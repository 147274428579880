import {AbstractFilterPreprocessor} from './abstract-filter-preprocessor';

export class InvisibleNodesFilterPreprocessor extends AbstractFilterPreprocessor {
	public whatToShow(): number {
		// eslint-disable-next-line no-bitwise
		return NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_COMMENT;
	}

	protected _isFiltered(node: Node): boolean {
		if (node.nodeType === Node.ELEMENT_NODE) {
			const style = window.getComputedStyle(node as HTMLElement);
			return style.display === 'none';
		}
		return true;
	}
}
