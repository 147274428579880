import {Component, Input, OnChanges, OnDestroy, ChangeDetectionStrategy, SimpleChanges, NgZone, ElementRef} from '@angular/core';
import {Utils} from 'kn-utils';
import {AbstractChartFactory} from './charts/abstract-chart';
import {Charts} from './charts/charts';
import {Data, BaseChart, BaseChartEventsTypes, Options, ResponsiveOptions} from 'chartist';

@Component({
	selector: 'kn-chartist',
	template: '',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnChartistComponent implements OnChanges, OnDestroy {
	private _chart: BaseChart<BaseChartEventsTypes>;

	@Input() public data: Data;
	@Input() public type: 'bar' | 'line' | 'pie' | AbstractChartFactory;
	@Input() public options: Options;
	@Input() public responsiveOptions: ResponsiveOptions<Options>;

	public constructor(
			private readonly _zone: NgZone,
			private readonly _elementRef: ElementRef,
			private readonly _charts: Charts) { }

	public ngOnChanges(changes: SimpleChanges) {
		if ('type' in changes) {
			this._render();
		}
		else {
			this.update();
		}
	}

	public ngOnDestroy() {
		this.detach();
	}

	private _render() {
		this.detach();
		this._zone.runOutsideAngular(() => {
			let factory: AbstractChartFactory;
			if (Utils.isString(this.type)) {
				factory = this._charts.find(this.type as string);
			}
			else {
				factory = this.type as AbstractChartFactory;
			}
			this._chart = factory.create(this._elementRef.nativeElement,
					this.data, this.options, this.responsiveOptions);
		});
	}

	public update() {
		this._zone.runOutsideAngular(() => {
			this._chart && this._chart.update(this.data, this.options);
		});
	}

	public detach() {
		this._chart && this._chart.detach();
		this._chart = null;
	}
}
