import {ElementRef, NgZone, Injectable, OnDestroy} from '@angular/core';
import {fromEvent, Subject, merge} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/** A simple (x, y) coordinate. */
export interface Point {
	x: number;
	y: number;
}

/** Horizontal dimension of a connection point on the perimeter of the origin or overlay element. */
export type HorizontalConnectionPos = 'start' | 'center' | 'end';
export const HorizontalConnectionPos = {
	start: 'start' as HorizontalConnectionPos,
	center: 'center' as HorizontalConnectionPos,
	end: 'end' as HorizontalConnectionPos
};

/** Vertical dimension of a connection point on the perimeter of the origin or overlay element. */
export type VerticalConnectionPos = 'top' | 'center' | 'bottom';
export const VerticalConnectionPos = {
	top: 'top' as VerticalConnectionPos,
	center: 'center' as VerticalConnectionPos,
	bottom: 'bottom' as VerticalConnectionPos
};

export interface ConnectedPosition {
	originX: HorizontalConnectionPos;
	originY: VerticalConnectionPos;

	overlayX: HorizontalConnectionPos;
	overlayY: VerticalConnectionPos;

	offsetX?: number;
	offsetY?: number;
	panelClass?: string | string[];
	panelClassOutsideViewport?: string | string[];
}

export interface ViewportScrollPosition {
	top: number;
	left: number;
}

/** Record of measurements for how an overlay (at a given position) fits into the viewport. */
interface OverlayFit {
	/** Whether the overlay fits completely in the viewport. */
	isCompletelyWithinViewport: boolean;

	/** Whether the overlay fits in the viewport on the y-axis. */
	fitsInViewportVertically: boolean;

	/** Whether the overlay fits in the viewport on the x-axis. */
	fitsInViewportHorizontally: boolean;

	/** The total visible area (in px^2) of the overlay inside the viewport. */
	visibleArea: number;
}

export type FlexibleOrigin = ElementRef | HTMLElement | Point;

interface FallbackPosition {
	position: ConnectedPosition;
	originPoint: Point;
	overlayPoint: Point;
	overlayFit: OverlayFit;
	overlayRect: ClientRect;
}

export class ScrollingVisibility {
	public isOriginClipped: boolean;
	public isOriginOutsideView: boolean;
	public isOverlayClipped: boolean;
	public isOverlayOutsideView: boolean;
}

@Injectable()
export class FlexiblePosition implements OnDestroy {
	/** Amount of space that must be maintained between the overlay and the edge of the viewport. */
	private _viewportMargin = 0;
	/** Default offset for the overlay along the x axis. */
	private _offsetX = 0;
	/** Default offset for the overlay along the y axis. */
	private _offsetY = 0;

	public minWidthOrigin = true;

	private _isPushed: boolean;
	private _hasFlexibleDimensions: boolean;
	private _canPush: boolean = true;
	private _isDisposed: boolean = false;

	protected _originRect: ClientRect;
	protected _overlayRect: ClientRect;
	protected _viewportRect: ClientRect;
	protected _scrollParentScroll: Point;
	protected _overlayViewport: ClientRect;
	protected _userdScrollables: HTMLElement[] = [];
	private _destroyed = new Subject();
	private _changeScrollables = new Subject();

	public get overlayViewport(): ClientRect {
		return this._overlayViewport;
	}

	/** Keeps track of the CSS classes that the position strategy has applied on the overlay panel. */
	private _appliedPanelClasses: string[] = [];

	private _preferredPositions: ConnectedPosition[] = [];
	private _lastPosition: ConnectedPosition;
	public transform: string;

	public constructor(
		protected _origin: FlexibleOrigin,
		protected _overlay: HTMLElement,
		private _document: Document,
		protected ngZone: NgZone) {
	}

	/** Determines whether the overlay uses exact or flexible positioning. */
	private _hasExactPosition() {
		return !this._hasFlexibleDimensions || this._isPushed;
	}

	public applyPosition(positions: ConnectedPosition[]) {
		if (this._isDisposed) {
			return;
		}
		this._clearPanelClasses();

		this._preferredPositions = positions;
		this._originRect = this._getOriginRect(this._origin);

		if (this.minWidthOrigin) {
			this._overlay.style.minWidth = coerceCssPixelValue(this._originRect.width);
		}

		this._overlayRect = this._overlay.getBoundingClientRect();
		this._viewportRect = this._getNarrowedViewportRect();

		if (this._origin instanceof ElementRef) {
			this._overlayViewport = this._origin.nativeElement.getBoundingClientRect();
			this._scrollParentScroll = {
				x: this._origin.nativeElement.offsetParent ? this._origin.nativeElement.offsetParent.scrollLeft : 0,
				y: this._origin.nativeElement.offsetParent ? this._origin.nativeElement.offsetParent.scrollTop : 0
			};
		}
		else if (this._origin instanceof HTMLElement) {
			this._overlayViewport = this._origin.offsetParent ? this._origin.offsetParent.getBoundingClientRect() : null;
			this._scrollParentScroll = {
				x: this._origin.offsetParent ? this._origin.offsetParent.scrollLeft : 0,
				y: this._origin.offsetParent ? this._origin.offsetParent.scrollTop : 0
			};
		}

		const originRect = this._originRect;
		const overlayRect = this._overlayRect;
		const viewportRect = this._viewportRect;
		let fallback: FallbackPosition | undefined;

		for (const pos of positions) {
			// Get the exact (x, y) coordinate for the point-of-origin on the origin element.
			const originPoint = this._getOriginPoint(originRect, pos);

			// From that point-of-origin, get the exact (x, y) coordinate for the top-left corner of the
			// overlay in this position. We use the top-left corner for calculations and later translate
			// this into an appropriate (top, left, bottom, right) style.
			const overlayPoint = this._getOverlayPoint(originPoint, overlayRect, pos);

			// Calculate how well the overlay would fit into the viewport with this point.
			const overlayFit = this._getOverlayFit(overlayPoint, overlayRect, viewportRect, pos);

			// If the overlay, without any further work, fits into the viewport, use this position.
			if (overlayFit.isCompletelyWithinViewport) {
				this._isPushed = false;
				this._applyPosition(pos, originPoint);
				return;
			}

			// // If the overlay has flexible dimensions, we can use this position
			// // so long as there's enough space for the minimum dimensions.
			// if (this._canFitWithFlexibleDimensions(overlayFit, overlayPoint, viewportRect)) {
			// 	// Save positions where the overlay will fit with flexible dimensions. We will use these
			// 	// if none of the positions fit *without* flexible dimensions.
			// 	flexibleFits.push({
			// 		position: pos,
			// 		origin: originPoint,
			// 		overlayRect,
			// 		boundingBoxRect: this._calculateBoundingBoxRect(originPoint, pos)
			// 	});

			// 	continue;
			// }

			if (!fallback || fallback.overlayFit.visibleArea < overlayFit.visibleArea) {
				fallback = { overlayFit, overlayPoint, originPoint, position: pos, overlayRect };
			}
		}

		if (this._canPush) {
			// TODO(jelbourn): after pushing, the opening "direction" of the overlay might not make sense.
			this._isPushed = true;
			this._applyPosition(fallback!.position, fallback!.originPoint);
			return;
		}

		return;
	}

	public ngOnDestroy() {
		this._isDisposed = true;
		this._destroyed.next();
		this._destroyed.complete();
	}

	/**
	 * Applies a computed position to the overlay and emits a position change.
	 * @param position The position preference
	 * @param originPoint The point on the origin element where the overlay is connected.
	 */
	private _applyPosition(position: ConnectedPosition, originPoint: Point) {
		const scrollables: HTMLElement[] = [];
		let el: HTMLElement = (this._origin instanceof ElementRef) ? this._origin.nativeElement : this._origin as HTMLElement;
		while (el.offsetParent != null) {
			scrollables.push(el.offsetParent as HTMLElement);
			el = el.offsetParent as HTMLElement;
		}
		let scrollablesChange = this._userdScrollables.length !== scrollables.length;
		if (!scrollablesChange) {
			scrollablesChange = scrollables.reduce((r, sc, idx) => r || this._userdScrollables[idx] !== sc, scrollablesChange);
		}
		if (scrollablesChange) {
			this._changeScrollables.next();
			this.ngZone.runOutsideAngular(() =>
				scrollables.forEach(scroll => fromEvent(scroll, 'scroll')
					.pipe(takeUntil(merge(this._destroyed, this._changeScrollables)))
					.subscribe(next => this.reapplyLastPosition()))
			);
			this._userdScrollables = scrollables;
		}

		this._setTransformOrigin(position);
		this._setOverlayElementStyles(originPoint, position);
		// this._setBoundingBoxStyles(originPoint, position);

		if (position.panelClass) {
			this._addPanelClasses(position.panelClass);
		}

		if (position.panelClassOutsideViewport && !this._isOriginVisible(this._originRect, this._userdScrollables.map(next => next.getBoundingClientRect()))) {
			this._addPanelClasses(position.panelClassOutsideViewport);
		}
		else {
			this._removePanelClasses(position.panelClassOutsideViewport);
		}

		// Save the last connected position in case the position needs to be re-calculated.
		this._lastPosition = position;

		// Notify that the position has been changed along with its change properties.
		// We only emit if we've got any subscriptions, because the scroll visibility
		// calculcations can be somewhat expensive.
		// if (this._positionChangeSubscriptions > 0) {
		// 	const scrollableViewProperties = this._getScrollVisibility();
		// 	const changeEvent = new ConnectedOverlayPositionChange(position, scrollableViewProperties);
		// 	this._positionChanges.next(changeEvent);
		// }
	}

	private reapplyLastPosition(): void {
		if (!this._isDisposed) {
			this._originRect = this._getOriginRect(this._origin);
			this._overlayRect = this._overlay.getBoundingClientRect();
			this._viewportRect = this._getNarrowedViewportRect();

			const lastPosition = this._lastPosition || this._preferredPositions[0];
			const originPoint = this._getOriginPoint(this._originRect, lastPosition);

			this._applyPosition(lastPosition, originPoint);
		}
	}

	private _getOriginRect(origin: FlexibleOrigin): ClientRect {
		if (origin instanceof ElementRef) {
			return origin.nativeElement.getBoundingClientRect();
		}

		if (origin instanceof HTMLElement) {
			return origin.getBoundingClientRect();
		}

		// If the origin is a point, return a client rect as if it was a 0x0 element at the point.
		return {
			x: origin.x,
			y: origin.y,
			top: origin.y,
			bottom: origin.y,
			left: origin.x,
			right: origin.x,
			height: 0,
			width: 0,
			toJSON: null
		};
	}

	private _getOriginPoint(originRect: ClientRect, pos: ConnectedPosition): Point {
		let x: number;
		if (pos.originX === HorizontalConnectionPos.center) {
			x = originRect.left + (originRect.width / 2);
		} else {
			const startX = originRect.left;
			const endX = originRect.right;
			x = pos.originX === HorizontalConnectionPos.start ? startX : endX;
		}

		let y: number;
		if (pos.originY === VerticalConnectionPos.center) {
			y = originRect.top + (originRect.height / 2);
		} else {
			y = pos.originY === VerticalConnectionPos.top ? originRect.top : originRect.bottom;
		}

		return { x, y };
	}

	private _getNarrowedViewportRect(): ClientRect {
		// We recalculate the viewport rect here ourselves, rather than using the ViewportRuler,
		// because we want to use the `clientWidth` and `clientHeight` as the base. The difference
		// being that the client properties don't include the scrollbar, as opposed to `innerWidth`
		// and `innerHeight` that do. This is necessary, because the overlay container uses
		// 100% `width` and `height` which don't include the scrollbar either.
		const width = this._document.documentElement!.clientWidth;
		const height = this._document.documentElement!.clientHeight;
		const scrollPosition = this.getViewportScrollPosition();

		return {
			x: scrollPosition.left + this._viewportMargin,
			y: scrollPosition.top + this._viewportMargin,
			top: scrollPosition.top + this._viewportMargin,
			left: scrollPosition.left + this._viewportMargin,
			right: scrollPosition.left + width - this._viewportMargin,
			bottom: scrollPosition.top + height - this._viewportMargin,
			width: width - (2 * this._viewportMargin),
			height: height - (2 * this._viewportMargin),
			toJSON: null
		};
	}

	private getViewportScrollPosition(): ViewportScrollPosition {
		// The top-left-corner of the viewport is determined by the scroll position of the document
		// body, normally just (scrollLeft, scrollTop). However, Chrome and Firefox disagree about
		// whether `document.body` or `document.documentElement` is the scrolled element, so reading
		// `scrollTop` and `scrollLeft` is inconsistent. However, using the bounding rect of
		// `document.documentElement` works consistently, where the `top` and `left` values will
		// equal negative the scroll position.
		const documentElement = document.documentElement!;
		const documentRect = documentElement.getBoundingClientRect();

		const top = -documentRect.top || document.body.scrollTop || window.scrollY ||
			documentElement.scrollTop || 0;

		const left = -documentRect.left || document.body.scrollLeft || window.scrollX ||
			documentElement.scrollLeft || 0;

		return { top, left };
	}

	/**
	 * Gets the (x, y) coordinate of the top-left corner of the overlay given a given position and
	 * origin point to which the overlay should be connected.
	 */
	private _getOverlayPoint(
		originPoint: Point,
		overlayRect: ClientRect,
		pos: ConnectedPosition): Point {

		// Calculate the (overlayStartX, overlayStartY), the start of the
		// potential overlay position relative to the origin point.
		let overlayStartX: number;
		if (pos.overlayX === HorizontalConnectionPos.center) {
			overlayStartX = -overlayRect.width / 2;
		} else if (pos.overlayX === HorizontalConnectionPos.start) {
			overlayStartX = 0;
		} else {
			overlayStartX = -overlayRect.width;
		}

		let overlayStartY: number;
		if (pos.overlayY === VerticalConnectionPos.center) {
			overlayStartY = -overlayRect.height / 2;
		} else {
			overlayStartY = pos.overlayY === VerticalConnectionPos.top ? 0 : -overlayRect.height;
		}

		// The (x, y) coordinates of the overlay.
		return {
			x: originPoint.x + overlayStartX,
			y: originPoint.y + overlayStartY
		};
	}

	private _getOverlayFit(point: Point, overlay: ClientRect, viewport: ClientRect, position: ConnectedPosition): OverlayFit {
		let { x, y } = point;
		const offsetX = this._getOffset(position, 'x');
		const offsetY = this._getOffset(position, 'y');

		// Account for the offsets since they could push the overlay out of the viewport.
		if (offsetX) {
			x += offsetX;
		}

		if (offsetY) {
			y += offsetY;
		}

		// How much the overlay would overflow at this position, on each side.
		const leftOverflow = 0 - x;
		const rightOverflow = (x + overlay.width) - viewport.width;
		const topOverflow = 0 - y;
		const bottomOverflow = (y + overlay.height) - viewport.height;

		// Visible parts of the element on each axis.
		const visibleWidth = this._subtractOverflows(overlay.width, leftOverflow, rightOverflow);
		const visibleHeight = this._subtractOverflows(overlay.height, topOverflow, bottomOverflow);
		const visibleArea = visibleWidth * visibleHeight;

		return {
			visibleArea,
			isCompletelyWithinViewport: (overlay.width * overlay.height) === visibleArea,
			fitsInViewportVertically: visibleHeight === overlay.height,
			fitsInViewportHorizontally: visibleWidth === overlay.width
		};
	}

	private _isOriginVisible(originRect: ClientRect, viewportRect: ClientRect[]) {
		return !isElementScrolledOutsideView(originRect, viewportRect);
	}

	private _getOffset(position: ConnectedPosition, axis: 'x' | 'y') {
		if (axis === 'x') {
			// We don't do something like `position['offset' + axis]` in
			// order to avoid breking minifiers that rename properties.
			return position.offsetX == null ? this._offsetX : position.offsetX;
		}

		return position.offsetY == null ? this._offsetY : position.offsetY;
	}

	/** Subtracts the amount that an element is overflowing on an axis from it's length. */
	private _subtractOverflows(length: number, ...overflows: number[]): number {
		return overflows.reduce((currentValue: number, currentOverflow: number) => {
			return currentValue - Math.max(currentOverflow, 0);
		}, length);
	}

	/** Sets the transform origin based on the configured selector and the passed-in position.  */
	private _setTransformOrigin(position: ConnectedPosition) {
		// if (!this._transformOriginSelector) {
		// 	return;
		// }

		// const elements: NodeListOf<HTMLElement> =
		// 	this._boundingBox!.querySelectorAll(this._transformOriginSelector);
		// let xOrigin: 'left' | 'right' | 'center';
		// let yOrigin: 'top' | 'bottom' | 'center' = position.overlayY;

		// if (position.overlayX === 'center') {
		// 	xOrigin = 'center';
		// } else if (this._isRtl()) {
		// 	xOrigin = position.overlayX === 'start' ? 'right' : 'left';
		// } else {
		// 	xOrigin = position.overlayX === 'start' ? 'left' : 'right';
		// }

		// for (let i = 0; i < elements.length; i++) {
		// 	elements[i].style.transformOrigin = `${xOrigin} ${yOrigin}`;
		// }
	}

	/** Sets positioning styles to the overlay element. */
	private _setOverlayElementStyles(originPoint: Point, position: ConnectedPosition): void {
		const styles = {} as CSSStyleDeclaration;

		if (this._hasExactPosition()) {
			const scrollPosition = this.getViewportScrollPosition();
			extendStyles(styles, this._getExactOverlayY(position, originPoint, scrollPosition));
			extendStyles(styles, this._getExactOverlayX(position, originPoint, scrollPosition));
		} else {
			styles.position = 'static';
		}

		// Use a transform to apply the offsets. We do this because the `center` positions rely on
		// being in the normal flex flow and setting a `top` / `left` at all will completely throw
		// off the position. We also can't use margins, because they won't have an effect in some
		// cases where the element doesn't have anything to "push off of". Finally, this works
		// better both with flexible and non-flexible positioning.
		let transformString = '';
		const offsetX = this._getOffset(position, 'x');
		const offsetY = this._getOffset(position, 'y');

		if (offsetX) {
			transformString += `translateX(${offsetX}px) `;
		}

		if (offsetY) {
			transformString += `translateY(${offsetY}px)`;
		}

		styles.transform = transformString.trim();

		// If a maxWidth or maxHeight is specified on the overlay, we remove them. We do this because
		// we need these values to both be set to "100%" for the automatic flexible sizing to work.
		// The maxHeight and maxWidth are set on the boundingBox in order to enforce the constraint.
		// if (this._hasFlexibleDimensions && this._overlayRef.getConfig().maxHeight) {
		// 	styles.maxHeight = '';
		// }

		// if (this._hasFlexibleDimensions && this._overlayRef.getConfig().maxWidth) {
		// 	styles.maxWidth = '';
		// }

		extendStyles(this._overlay.style, styles);
	}

	/** Gets the exact top/bottom for the overlay when not using flexible sizing or when pushing. */
	private _getExactOverlayY(position: ConnectedPosition, originPoint: Point, scrollPosition: ViewportScrollPosition) {
		// Reset any existing styles. This is necessary in case the
		// preferred position has changed since the last `apply`.
		const styles = { top: null, bottom: null } as CSSStyleDeclaration;
		let overlayPoint = this._getOverlayPoint(originPoint, this._overlayRect, position);

		if (this._isPushed) {
			overlayPoint = this._pushOverlayOnScreen(overlayPoint, this._overlayRect, scrollPosition);
		}

		// @breaking-change 8.0.0 Currently the `_overlayContainer` is optional in order to avoid a
		// breaking change. The null check here can be removed once the `_overlayContainer` becomes
		// a required parameter.
		// let virtualKeyboardOffset = this._overlayContainer ? this._overlayContainer.getContainerElement().getBoundingClientRect().top : 0;

		// Normally this would be zero, however when the overlay is attached to an input (e.g. in an
		// autocomplete), mobile browsers will shift everything in order to put the input in the middle
		// of the screen and to make space for the virtual keyboard. We need to account for this offset,
		// otherwise our positioning will be thrown off.
		// overlayPoint.y -= virtualKeyboardOffset;

		overlayPoint.y += scrollPosition.top;

		// We want to set either `top` or `bottom` based on whether the overlay wants to appear
		// above or below the origin and the direction in which the element will expand.
		if (position.overlayY === VerticalConnectionPos.bottom) {
			// When using `bottom`, we adjust the y position such that it is the distance
			// from the bottom of the viewport rather than the top.
			const documentHeight = this._document.documentElement!.clientHeight;
			styles.bottom = `${documentHeight - (overlayPoint.y + this._overlayRect.height)}px`;
		} else {
			styles.top = coerceCssPixelValue(overlayPoint.y);
		}

		return styles;
	}

	/** Gets the exact left/right for the overlay when not using flexible sizing or when pushing. */
	private _getExactOverlayX(position: ConnectedPosition, originPoint: Point, scrollPosition: ViewportScrollPosition) {
		// Reset any existing styles. This is necessary in case the preferred position has
		// changed since the last `apply`.
		const styles = { left: null, right: null } as CSSStyleDeclaration;
		let overlayPoint = this._getOverlayPoint(originPoint, this._overlayRect, position);

		if (this._isPushed) {
			overlayPoint = this._pushOverlayOnScreen(overlayPoint, this._overlayRect, scrollPosition);
		}

		// We want to set either `left` or `right` based on whether the overlay wants to appear "before"
		// or "after" the origin, which determines the direction in which the element will expand.
		// For the horizontal axis, the meaning of "before" and "after" change based on whether the
		// page is in RTL or LTR.
		let horizontalStyleProperty: 'left' | 'right';

		horizontalStyleProperty = position.overlayX === HorizontalConnectionPos.end ? 'right' : 'left';

		overlayPoint.x += scrollPosition.left;

		// When we're setting `right`, we adjust the x position such that it is the distance
		// from the right edge of the viewport rather than the left edge.
		if (horizontalStyleProperty === 'right') {
			const documentWidth = this._document.documentElement!.clientWidth;
			styles.right = `${documentWidth - (overlayPoint.x + this._overlayRect.width)}px`;
		} else {
			styles.left = coerceCssPixelValue(overlayPoint.x);
		}

		return styles;
	}

	/**
	 * Gets the point at which the overlay can be "pushed" on-screen. If the overlay is larger than
	 * the viewport, the top-left corner will be pushed on-screen (with overflow occuring on the
	 * right and bottom).
	 *
	 * @param start Starting point from which the overlay is pushed.
	 * @param overlay Dimensions of the overlay.
	 * @param scrollPosition Current viewport scroll position.
	 * @returns The point at which to position the overlay after pushing. This is effectively a new
	 *     originPoint.
	 */
	private _pushOverlayOnScreen(start: Point, overlay: ClientRect, scrollPosition: ViewportScrollPosition): Point {
		// If the position is locked and we've pushed the overlay already, reuse the previous push
		// amount, rather than pushing it again. If we were to continue pushing, the element would
		// remain in the viewport, which goes against the expectations when position locking is enabled.
		// if (this._previousPushAmount) {
		// 	return {
		// 		x: start.x + this._previousPushAmount.x,
		// 		y: start.y + this._previousPushAmount.y
		// 	};
		// }

		const viewport = this._viewportRect;

		// Determine how much the overlay goes outside the viewport on each
		// side, which we'll use to decide which direction to push it.
		const overflowRight = Math.max(start.x + overlay.width - viewport.right, 0);
		const overflowBottom = Math.max(start.y + overlay.height - viewport.bottom, 0);
		const overflowTop = Math.max(viewport.top - scrollPosition.top - start.y, 0);
		const overflowLeft = Math.max(viewport.left - scrollPosition.left - start.x, 0);

		// Amount by which to push the overlay in each axis such that it remains on-screen.
		let pushX = 0;
		let pushY = 0;

		// If the overlay fits completely within the bounds of the viewport, push it from whichever
		// direction is goes off-screen. Otherwise, push the top-left corner such that its in the
		// viewport and allow for the trailing end of the overlay to go out of bounds.
		if (overlay.width <= viewport.width) {
			pushX = overflowLeft || -overflowRight;
		} else {
			pushX = start.x < this._viewportMargin ? (viewport.left - scrollPosition.left) - start.x : 0;
		}

		if (overlay.height <= viewport.height) {
			pushY = overflowTop || -overflowBottom;
		} else {
			pushY = start.y < this._viewportMargin ? (viewport.top - scrollPosition.top) - start.y : 0;
		}

		return {
			x: start.x + pushX,
			y: start.y + pushY
		};
	}

	/** Adds a single CSS class or an array of classes on the overlay panel. */
	private _addPanelClasses(cssClasses: string | string[]) {
		if (this._overlay) {
			coerceArray(cssClasses).forEach(cssClass => {
				if (this._appliedPanelClasses.indexOf(cssClass) === -1) {
					this._appliedPanelClasses.push(cssClass);
					this._overlay.classList.add(cssClass);
				}
			});
		}
	}

	/** Adds a single CSS class or an array of classes on the overlay panel. */
	private _removePanelClasses(cssClasses: string | string[]) {
		if (this._overlay) {
			coerceArray(cssClasses).forEach(cssClass => {
				const idx = this._appliedPanelClasses.indexOf(cssClass);
				if (idx !== -1) {
					this._appliedPanelClasses.splice(idx);
					this._overlay.classList.remove(cssClass);
				}
			});
		}
	}

	/** Clears the classes that the position strategy has applied from the overlay panel. */
	private _clearPanelClasses() {
		if (this._overlay) {
			this._appliedPanelClasses.forEach(cssClass => this._overlay.classList.remove(cssClass));
			this._appliedPanelClasses = [];
		}
	}
}

function coerceArray<T>(value: T | T[]): T[] {
	return Array.isArray(value) ? value : [value];
}

/** Shallow-extends a stylesheet object with another stylesheet object. */
function extendStyles(dest: CSSStyleDeclaration, source: CSSStyleDeclaration): CSSStyleDeclaration {
	for (const key in source) {
		if (source.hasOwnProperty(key)) {
			dest[key] = source[key];
		}
	}
	return dest;
}

function coerceCssPixelValue(value: any): string {
	if (value == null) {
		return '';
	}

	return typeof value === 'string' ? value : `${value}px`;
}

/**
 * Gets whether an element is scrolled outside of view by any of its parent scrolling containers.
 * @param element Dimensions of the element (from getBoundingClientRect)
 * @param scrollContainers Dimensions of element's scrolling containers (from getBoundingClientRect)
 * @returns Whether the element is scrolled out of view
 * @docs-private
 */
export function isElementScrolledOutsideView(element: ClientRect, scrollContainers: ClientRect[]) {
	return scrollContainers.some(containerBounds => {
		const outsideAbove = element.bottom < containerBounds.top;
		const outsideBelow = element.top > containerBounds.bottom;
		const outsideLeft = element.right < containerBounds.left;
		const outsideRight = element.left > containerBounds.right;

		return outsideAbove || outsideBelow || outsideLeft || outsideRight;
	});
}

/**
 * Gets whether an element is clipped by any of its scrolling containers.
 * @param element Dimensions of the element (from getBoundingClientRect)
 * @param scrollContainers Dimensions of element's scrolling containers (from getBoundingClientRect)
 * @returns Whether the element is clipped
 * @docs-private
 */
export function isElementClippedByScrolling(element: ClientRect, scrollContainers: ClientRect[]) {
	return scrollContainers.some(scrollContainerRect => {
		const clippedAbove = element.top < scrollContainerRect.top;
		const clippedBelow = element.bottom > scrollContainerRect.bottom;
		const clippedLeft = element.left < scrollContainerRect.left;
		const clippedRight = element.right > scrollContainerRect.right;

		return clippedAbove || clippedBelow || clippedLeft || clippedRight;
	});
}
