import {Uri} from '../uri/uri';
import {Headers} from './headers';
import {RequestOptions, RequestMethod, ResponseContentType} from './types';

export class Request implements RequestOptions {
	public readonly method: string | RequestMethod;
	public readonly uri: Uri;
	public readonly headers: Headers;
	public readonly responseType: string | ResponseContentType;
	public readonly withCredentials: boolean;
	public readonly reportProgress: boolean;
	public readonly body?: any;

	public constructor(
			method: 'get' | 'delete' | 'options' | 'head',
			uri: string | Uri,
			options?: Partial<RequestOptions>);
	public constructor(
			method: 'post' | 'put' | 'patch',
			uri: string | Uri,
			body: any,
			options?: Partial<RequestOptions>);
	public constructor(
			method: string | RequestMethod,
			uri: string | Uri,
			bodyOrOptions: any | Partial<RequestOptions>,
			options?: Partial<RequestOptions>) {
		this.method = method != null ? method : 'get';
		this.uri = Uri.from(uri);
		if (options != null || this._mightHaveBody(this.method)) {
			this.body = bodyOrOptions;
		}
		else {
			options = bodyOrOptions as Partial<RequestOptions>;
		}
		options = options || {};
		this.headers = options.headers || new Headers();
		this.responseType = options.responseType || 'json';
		this.withCredentials = !!options.withCredentials;
		this.reportProgress = !!options.reportProgress;
	}

	private _mightHaveBody(method: string | RequestMethod): boolean {
		switch (method.toLowerCase()) {
			case 'get':
			case 'delete':
			case 'options':
			case 'head':
				return false;
			default:
				return true;
		}
	}
}
