import {Utils} from 'kn-utils';
import {Observable, from as observableFrom, merge as observableMerge, concat as observableConcat} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {UriContext, Response} from 'kn-http';
import {AbstractResource, ChangeEntry} from 'kn-rest';
import * as Model from 'common-web/model';

export interface EntitiesDiff<T extends Model.EntityBase> {
	idsToRemove: number[];
	entitiesToCreate: T[];
	entitiesToUpdate: T[];
}

export namespace EntityUtils {
	export function calculateDiff<T extends Model.EntityBase>(previous: T[], next: T[]): EntitiesDiff<T> {
		const idsToRemove = previous
			.map(x => x.id)
			.filter(id => next.map(x => x.id).indexOf(id) === -1);
		const entitiesToCreate = next
			.filter(entity => entity.id == null || previous.map(x => x.id).indexOf(entity.id) === -1);
		const entitiesToUpdate = next
			.filter(entity => {
				const referenceEntity = previous.find(x => x.id === entity.id);
				if (referenceEntity == null) {
					return false;
				}
				return !equal(entity, referenceEntity);
			});
		return {
			idsToRemove: idsToRemove,
			entitiesToCreate: entitiesToCreate,
			entitiesToUpdate: entitiesToUpdate
		};
	}

	export function saveDiff<T extends Model.EntityBase>(resource: AbstractResource<T>, diff: EntitiesDiff<T>, context?: UriContext, concurrent?: number): Observable<Response> {
		const removeRequests$ = Rx.mergeAll(concurrent)(observableFrom(
			diff.idsToRemove.map(x => resource.remove(x, context))
		));
		const createRequests$ = Rx.mergeAll(concurrent)(observableFrom(
			diff.entitiesToCreate.map(x => resource.save(x, context))
		));
		const updateRequests$ = Rx.mergeAll(concurrent)(observableFrom(
			diff.entitiesToUpdate.map(x => resource.save(x, context))
		));
		return observableConcat<Response>(removeRequests$, createRequests$, updateRequests$);
	}

	export function exceptReferences<T extends Model.EntityBase>(item: T, exclude?: string[]) {
		const supplement = {} as T;
		for (const key in item) {
			if (item.hasOwnProperty(key) && Utils.isObject(item[key])
					&& (exclude == null || exclude.indexOf(key) === -1)) {
				supplement[key] = item[key];
				delete item[key];
			}
		}
		return supplement;
	}

	export function equal<T extends Model.EntityBase>(entity: T, referenceEntity: T) {
		for (const key in entity) {
			if (entity.hasOwnProperty(key) && entity[key] !== referenceEntity[key]
					&& (entity[key] != null || referenceEntity[key] != null)
					&& (entity[key] == null || referenceEntity[key] == null
						|| !Utils.isObject(entity[key])
						|| !equal(entity[key], referenceEntity[key]))) {
				return false;
			}
		}
		return true;
	}

	export function mergeChanges(...resources: AbstractResource<any>[]): Observable<ChangeEntry> {
		const references = Utils.array.flatten(resources.map(x => Object.values(x.getReferences())));
		return observableMerge(
			...resources.map(x => x.changes),
			...references.filter(x => x != null).map(x => x.changes)
		);
	}
}
