import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('substr')
export class SubstrFilter implements PrintRendererFilter {
	public transform(value: string, from: number = 0, length?: number): string {
		return value == null ? null : `${value}`.substr(from, length);
	}
}
