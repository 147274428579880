import {Routes, RouterModule} from '@angular/router';
import {ComponentDeactivateGuard} from 'kn-modal';
import {UsersViewComponent} from './users-view.component';
import {UserEditComponent} from './user-edit.component';

const routes: Routes = [
	{
		path: '',
		component: UsersViewComponent
	}, {
		path: ':id/:action',
		component: UserEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}, {
		path: 'new',
		component: UserEditComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}
];

export const routing = RouterModule.forChild(routes);
