import {NgModule} from '@angular/core';
import {KnSticky} from './sticky.component';

export const KN_STICKY_DIRECTIVES = [
	KnSticky
];

@NgModule({
	declarations: [KN_STICKY_DIRECTIVES],
	exports: [KN_STICKY_DIRECTIVES]
})
export class KnStickyModule { }
