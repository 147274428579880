import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Response, UriContext} from 'kn-http';
import {ChangeEntry} from 'kn-rest';
import {EntityUtils} from 'common-web/rest';
import {RolesResourceService} from '../../services/roles/roles-resource.service';
import * as CommonModel from '../../model/common-database.types';

@Injectable()
export class RolesStoreService {
	public constructor(private readonly _rolesResource: RolesResourceService) { }

	public get changes(): Observable<ChangeEntry> {
		return this._rolesResource.changes;
	}

	public query(context?: UriContext): Observable<CommonModel.Role[]> {
		return this._rolesResource.query(context);
	}

	public save(roles: CommonModel.Role[], context?: UriContext): Observable<Response[]> {
		return this._retriveRolesInstructions(roles, context).pipe(
			Rx.switchMap(instr => EntityUtils.saveDiff(this._rolesResource, instr.diff, context)),
			Rx.toArray()
		);
	}

	private _retriveRolesInstructions(roles: CommonModel.Role[], context: UriContext) {
		return this._rolesResource.query(context).pipe(
			Rx.map(next => {
				const diff = EntityUtils.calculateDiff(next, roles);
				for (const role of diff.entitiesToCreate) {
					delete role.id;
					role.rolePermissions.forEach(x => delete x.id);
				}
				for (const role of [...diff.entitiesToCreate, ...diff.entitiesToUpdate]) {
					for (let i = role.rolePermissions.length - 1; i >= 0; i--) {
						if (!role.rolePermissions[i].actions) {
							role.rolePermissions.splice(i, 1);
						}
					}
				}
				return { diff };
			})
		);
	}
}
