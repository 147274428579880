import {UserSettingsFactory} from './user-settings-factory.service';
import {AbstractUserSettingsService} from './abstract-user-settings.service';
import {KeySpecifier} from './types';
import {normalizeKey} from './utils';

export class KeysUserSettingsService extends AbstractUserSettingsService {
	public constructor(factory: UserSettingsFactory, origin: any) {
		super(factory, origin);

		if (factory.config.autoFetch) {
			this.fetch();
		}
	}

	public get keys(): string[] {
		return Object.keys((this.origin as KeySpecifier[]).map(x => normalizeKey(x).key));
	}
}
