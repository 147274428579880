<kn-month-view tabindex="-1"
	*ngFor="let view of monthViews; let i=index"
	[hidden]="viewIndex !== i"
	[class.previous]="view.previous"
	[class.animated]="animated"
	[year]="view.year"
	[month]="view.month"
	[day]="view.day"
	(dayChange)="setDay($event)"
	(accept)="acceptDay($event)"></kn-month-view>
