import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {I18n} from 'kn-shared';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

function bootstrapPlatform(platform, options = {}) {
	platform
		.bootstrapModule(AppModule, options)
		.catch(err => console.error(err));
}

async function bootstrapProd() {
	const platform = platformBrowserDynamic();
	await I18n.load();
	const loadTimeElapsed = Date.now() - ((window as any)['$$loadTimestamp'] || 0);
	const bootsrapDelay = Math.max(0, 1000 - loadTimeElapsed);
	setTimeout(() => bootstrapPlatform(platform), bootsrapDelay);
}

async function bootstrapDev() {
	const platform = platformBrowserDynamic();
	const i18n = await I18n.load();
	const options = { providers: i18n.getCompilerProviders() };
	bootstrapPlatform(platform, options);
}

if (environment.production) {
	enableProdMode();
	bootstrapProd();
}
else {
	bootstrapDev();
}
