import {Observable, Subscription} from 'rxjs';
import {ActiveMonitor} from 'kn-shared';
import {Description, Model, DataSource, Column, RowItem} from '../types';
import {DescriptionBuilder} from '../description-builder';
import {ModelBuilder} from '../model-builder';
import {AbstractConcreteDatagridBond} from './abstract-concrete-datagrid-bond';
import {DatasourceTransformerContext, DatasourceTransformer} from './datasource-transformer';
import {ContractDescription, ContractModel} from '../contract.types';

export abstract class AbstractFetchableDatagridBond<T extends RowItem> extends AbstractConcreteDatagridBond<T> {
	private readonly _loadingMonitor = new ActiveMonitor(0, 300);
	private readonly _transformers: DatasourceTransformer<T>[] = [];
	private _lastSorting: Column<T>[];
	private _dataSource: DataSource<T>;

	public get loading() {
		return this._loadingMonitor.active;
	}

	public description: Description<T>;

	public _model: Model;
	public dataSource: DataSource<T>;
	public expanded: T[];
	public selected: T[];

	public get model() {
		return this._model;
	}

	public set model(value: Model) {
		this._model = value;
	}

	public constructor(description: ContractDescription<T>, model: ContractModel) {
		super();
		this.description = DescriptionBuilder.from(description);
		this.model = ModelBuilder.from(model, this.description);
	}

	public onModelChange(value: Model) {
		this.model = value;
		this._update();
	}

	public onDataSourceChange(value: DataSource<T>) {
		this.dataSource = value;
	}

	public onExpandedChange(value: T[]) { /* intentionally empty */ }

	public onSelectedChange(value: T[]) {
		this.selected = value;
	}

	public onRowSelect(value: T) { /* intentionally empty */ }
	public onRowActivate(value: T) { /* intentionally empty */ }

	protected _update() {
		if (this._dataSource == null || this._dataSource.length === 0) {
			return;
		}

		const sorting = this._getSorting(this.model.columns, this.description);

		const sortingNeeded = sorting.length > 0
				&& (sorting.length !== this._lastSorting.length
				|| sorting.some((x, index) => x !== this._lastSorting[index]));

		if (sortingNeeded) {
			this.dataSource = this._applyTransformers(this._dataSourceSort(this._dataSource.slice(0), sorting));
			this._lastSorting = sorting;
		}
		else if (this.dataSource === this._dataSource) {
			this.dataSource = this._applyTransformers(this._dataSource.slice(0));
		}
		else {
			this.dataSource = this._applyTransformers(this._dataSource);
		}
	}

	public fetch(): Subscription {
		const sorting = this._getSorting(this.model.columns, this.description);
		this._lastSorting = sorting;
		return this._fetch(sorting)
			.lift(this._loadingMonitor.operator(true))
			.subscribe(next => {
				this._dataSource = next;
				this.dataSource = this._applyTransformers(this._dataSource);
			});
	}

	protected abstract _fetch(sorting: Column<T>[]): Observable<DataSource<T>>;

	private _applyTransformers(dataSource: DataSource<T>): DataSource<T> {
		const context: DatasourceTransformerContext<T> = {
			model: this.model,
			description: this.description
		};
		return this._transformers.reduce((acc, x) => x.transform(acc, context), dataSource);
	}
}

export class FetchableDatagridBond<T extends RowItem> extends AbstractFetchableDatagridBond<T> {
	public constructor(
			description: ContractDescription<T>,
			model: ContractModel,
			private readonly _fetcher: (sorting: Column<T>[]) => Observable<DataSource<T>>) {
		super(description, model);
	}

	protected _fetch(sorting: Column<T>[]): Observable<DataSource<T>> {
		return this._fetcher(sorting);
	}
}
