import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('mux')
export class MuxHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		return content.exec(content.params[1 + content.params[0]]);
	}
}
