import {Component, Output, EventEmitter} from '@angular/core';
import {combineLatest as observableCombineLatest} from 'rxjs';
import {Autocompletition} from 'kn-forms';
import {KcuniBridgeService} from './kcuni-bridge.service';
import {KcuniVersion, KcuniSettings} from './types';

const KN_KCUNI_DOC_URL = '/assets/docs/kcuni-bridge-manual.pdf';
const KN_KCUNI_APP_URL = '/assets/accessories/kcuni-bridge-1.2.zip';

@Component({
	selector: 'kn-kcuni-settings',
	templateUrl: 'kcuni-settings.html'
})
export class KnKcuniSettings {
	private readonly _disposables: Function[] = [];

	public accessible: boolean = null;
	public message: string;
	public portName: string;
	public version: KcuniVersion;
	public settings: KcuniSettings;
	public portsAutocompletion: Autocompletition<string>;
	public docUrl = KN_KCUNI_DOC_URL;
	public appUrl = KN_KCUNI_APP_URL;

	@Output() public settingsChange = new EventEmitter<KcuniSettings>();

	public constructor(private readonly _kcuni: KcuniBridgeService) { }

	public ngOnInit() {
		const versionAndSettings$ = observableCombineLatest(
			this._kcuni.getVersion(),
			this._kcuni.getSetting()
		);
		const subscription = versionAndSettings$.subscribe(
			next => {
				[this.version, this.settings] = next;
				this.portName = this.settings.portName;
				this.accessible = true;
			},
			error => this.accessible = false
		);
		this._disposables.push(() => subscription.unsubscribe());
		this.portsAutocompletion = Autocompletition.from<string>(this._kcuni.getPorts());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public save() {
		const settings = {
			type: 'KCuni',
			portName: this.portName
		};
		this.message = null;
		const subscription = this._kcuni.setSetting(settings)
			.subscribe(
				() => Object.assign(this.settings, settings),
				error => this.message = error.message || error,
				() => this.settingsChange.emit(settings)
			);
		this._disposables.push(() => subscription.unsubscribe());
	}
}
