import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService} from 'kn-rest';
import {UidResource, Formatters} from 'common-web/rest';
import * as Model from '../../model/master-database.types';

@Injectable()
export class ConnectorSettingsResourceService extends UidResource<Model.ConnectorSetting> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(rest, notifier, 'ConnectorSettings', {
			rules: [
				{ path: 'address', formatter: Formatters.slugify(40) },
				{ path: 'port' }
			]
		});
	}
}
