import {OnDestroy, Injectable} from '@angular/core';
import {Subject, Subscription, interval} from 'rxjs';
import {delay} from 'rxjs/operators';
import {SessionStorageService, SessionStorage} from 'kn-storage';
import {Utils} from 'kn-utils';

export interface TemporaryData {
	expire: Date;
	data: {[key: string]: any};
}

@Injectable()
export class TemporaryStorageService extends SessionStorageService implements OnDestroy {
	private readonly _sweepSubject: Subject<void> = new Subject<void>();

	@SessionStorage('@TEMP_PRESERVE')
	private _preservedKeys: string[] = [];

	protected _keyPrefix: string = 'TEMP_';
	protected _disposables: Function[] = [];
	protected _preservedSub: Subscription;
	protected _preservedMin: number = Number.MAX_SAFE_INTEGER;

	public itemLivetime: number = 5 * 60;

	public constructor() {
		super();
		const subs = [
			this._sweepSubject.pipe(delay(1000)).subscribe(() => this._sweep())
		];
		subs.forEach(x => this._disposables.push(() => x.unsubscribe()));
		// HACK
		this._subscribePreserved(new Date().valueOf() + 2000);
	}

	public ngOnDestroy() {
		this._unsubscribePreserved();
		this._disposables.forEach(x => x());
	}

	protected _addPreservedKey(key: string) {
		const tmp = this._preservedKeys;
		if (tmp.indexOf(key) === -1) {
			tmp.push(key);
			this._preservedKeys = tmp;
		}
	}

	protected _removePreservedKey(key: string) {
		const tmp = this._preservedKeys;
		const idx = tmp.indexOf(key);
		if (idx !== -1) {
			tmp.splice(idx, 1);
			this._preservedKeys = tmp;
			return true;
		}
		return false;
	}

	public setItem(key: string = null, value: TemporaryData): string {
		if (value == null) {
			return key;
		}
		if (key == null) {
			key = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		}
		if (value.expire == null) {
			value.expire = new Date(new Date().valueOf() + this.itemLivetime * 1000);
		}
		this._sweepSubject.next();
		super.setItem(this._createKey(key), value);
		return key;
	}

	public getItem(key: string): TemporaryData {
		const item: TemporaryData = super.getItem(this._createKey(key));
		if (item && Utils.isString(item.expire)) {
			item.expire = Utils.date.fromIso8601(item.expire as any as string);
		}
		if (this._isExpired(item)) {
			this.removeItem(key);
			return super.getItem(this._createKey(key));
		}
		return item;
	}

	public removeItem(key: string): void {
		this.lose(key);
		super.removeItem(this._createKey(key));
		this._sweepSubject.next();
	}

	protected _createKey(key: string): string {
		return this._keyPrefix + key;
	}

	protected _keys(): string[] {
		return super.keys().filter(key => key.startsWith(this._keyPrefix))
			.map(key => key.substr(this._keyPrefix.length));
	}

	public keys(): string[] {
		this._sweep();
		return this.keys();
	}

	public isPreseved(key: string): boolean {
		return this._preservedKeys.indexOf(key) !== -1;
	}

	public refresh(key: string): TemporaryData {
		return this._refresh(key, true);
	}

	public preserve(key: string) {
		if (this.getItem(key)) {
			this._addPreservedKey(key);
			this._schedulePreservedKey(key);
		}
	}

	public lose(key: string) {
		if (this._removePreservedKey(key)) {
			const item = this.getItem(key);
			if (item && item.expire.valueOf() <= this._preservedMin) {
				this._schedulePreserved();
			}
		}
	}

	protected _isExpired(item: TemporaryData): boolean {
		return !item || item.expire < new Date();
	}

	protected _sweep() {
		this._keys().forEach(key => this.getItem(key));
	}

	protected _refresh(key: string, schedule: boolean): TemporaryData {
		const item = this.getItem(key);
		if (item) {
			item.expire = null;
			this.setItem(key, item);
			if (schedule) {
				this._schedulePreservedKey(key);
			}
		}
		return item;
	}

	protected _schedulePreserved(refresh: boolean = false) {
		this._unsubscribePreserved();
		this._preservedMin = this._preservedKeys.map(key => refresh ? this._refresh(key, false) : this.getItem(key))
			.filter(x => x != null).map(x => x.expire.valueOf())
			.reduce((p, v) => ( p != null && p < v ? p : v ), null);
		this._subscribePreserved(this._preservedMin);
	}

	protected _schedulePreservedKey(key: string) {
		if (!this.isPreseved(key)) {
			return;
		}
		const item = this.getItem(key);
		if (item && (item.expire.valueOf() < this._preservedMin || this._preservedSub == null)) {
			this._subscribePreserved(item.expire.valueOf());
		}
	}

	protected _unsubscribePreserved() {
		this._preservedSub && this._preservedSub.unsubscribe();
		this._preservedSub = null;
	}

	protected _subscribePreserved(toTime: number) {
		this._unsubscribePreserved();
		const now = new Date().valueOf();
		if (toTime > now) {
			const t = Math.min(this.itemLivetime * 1000 / 2, toTime - now);
			this._preservedSub = interval(t).subscribe(() => this._schedulePreserved(true));
		}
	}
}
