import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnFormsModule} from 'kn-forms';

import {KnQueryFilter} from './query-filter.component';
import {KnFilterGroup} from './parts/filter-group.component';
import {KnFilterItem} from './parts/filter-item.component';
import {FilterOptionsPipe} from './pipes/filter-options.pipe';

const KN_QUERY_FILTER_DIRECTIVES = [KnQueryFilter, KnFilterGroup, KnFilterItem];

@NgModule({
	imports: [CommonModule, FormsModule, KnFormsModule],
	declarations: [KN_QUERY_FILTER_DIRECTIVES, FilterOptionsPipe],
	exports: [KN_QUERY_FILTER_DIRECTIVES]
})
export class KnQueryFilterModule { }
