<div class="kn-options-container" #dropDown *ngIf="isOpen">
	<div class="kn-options-container-filter" *ngIf="hasFilter">
		<a class="clear" tabindex="-1" href="javascript:void(0)" (click)="clearFilterInput()">X</a>
		<input type="text" i18n-placeholder="options-constraint-input" placeholder="enter constraint" #filterInput knAutofocus
				[ngModel]="queryValue"
				(ngModelChange)="handleFilterInput($event)"
				(keyup)="handleKeyup($event)"
				(keydown)="handleKeydown($event)">
	</div>
	<div class="kn-options-container-viewport" #viewport>
		<ng-content></ng-content>
	</div>
	<ul class="kn-options-container-hints" *ngIf="hints?.length">
		<li *ngFor="let hint of hints" [class]="hint.key" [innerHTML]="hint.message"></li>
	</ul>
</div>
