import {AbstractModelMerger} from './abstract-model-merger';
import {ModelBuilder} from '../model-builder';
import {DescriptionBuilder} from '../description-builder';
import {Description, Model, Filter, FilterNode, FilterValue, OptionDescription} from '../types';
import {ContractDescription, ContractWithId} from '../contract.types';
import {FilterNodeUtils} from '../internal-utils';

export class FlatModelMerger extends AbstractModelMerger {
	public constructor(
			description: Description,
			private readonly _mergeMasterAsBackground: boolean = false) {
		super(description);
	}

	public static from(descriptionOrOptions: ContractDescription | ContractWithId<OptionDescription<FilterValue>>[], mergeMasterAsBackground: boolean = false) {
		let description = descriptionOrOptions as ContractDescription;
		if (Array.isArray(description)) {
			description = { options: description };
		}
		return new FlatModelMerger(DescriptionBuilder.from(description), mergeMasterAsBackground);
	}

	public merge(master: Model, slave: Model, preserveMaster: boolean = false): Model {
		if (!preserveMaster) {
			this._removeLooseMasterFilters(master, slave);
		}
		this._mergeRootOperator(master, slave);
		this._mergeFiltersAndGroups(master, slave, preserveMaster);
		ModelBuilder.clean(master);
		return master;
	}

	private _removeLooseMasterFilters(master: Model, slave: Model) {
		const leafToRemove = new Set<string>();
		FilterNodeUtils.forEachNode(slave, x => {
			if (!FilterNodeUtils.isGroup(x)) {
				const filter = x as Filter<FilterValue>;
				if (this._mergeMasterAsBackground || filter.fixed || filter.disabled) {
					leafToRemove.add(filter.id);
				}
			}
		});

		FilterNodeUtils.forEachNode(master, x => {
			if (FilterNodeUtils.isGroup(x)) {
				const group = x as FilterNode<FilterValue>;
				for (let i = group.children.length - 1; i >= 0; i--) {
					if (!FilterNodeUtils.isGroup(group.children[i])) {
						const filter = group.children[i] as Filter<FilterValue>;
						if (leafToRemove.has(filter.id) || (!this._mergeMasterAsBackground
								&& (!filter.fixed && !filter.disabled && !filter.hidden))) {
							group.children.splice(i, 1);
						}
					}
				}
			}
		});
	}

	private _mergeRootOperator(master: Model, slave: Model) {
		if (master.operator !== slave.operator) {
			if (master.children.length < 2) {
				master.operator = slave.operator;
			}
			else {
				master = {
					operator: slave.operator,
					children: [master]
				};
			}
		}
	}

	// eslint-disable-next-line complexity
	private _mergeFiltersAndGroups(master: Model, slave: Model, preserveMaster: boolean) {
		const masterFilters = master.children
			.filter(x => !FilterNodeUtils.isGroup(x)) as Filter<FilterValue>[];

		const filters: Filter<FilterValue>[] = [];
		const groups: FilterNode<FilterValue>[] = [];
		for (const node of slave.children) {
			if (FilterNodeUtils.isGroup(node)) {
				groups.push(node as FilterNode<FilterValue>);
			}
			else {
				const filter = node as Filter<FilterValue>;
				const masterFilter = masterFilters.find(x => x.id === filter.id);
				if (masterFilter != null) {
					if ((masterFilter.disabled && !filter.disabled)
							|| (masterFilter.hidden && !filter.hidden)) {
						continue;
					}
					if (!masterFilter.fixed || (masterFilter.disabled && filter.disabled)) {
						masterFilter.operator = filter.operator;
						masterFilter.value = filter.value;
						masterFilter.fixed = masterFilter.fixed || filter.fixed;
						masterFilter.disabled = masterFilter.disabled || filter.disabled;
						masterFilter.hidden = masterFilter.hidden || filter.hidden;
						continue;
					}
					else if (preserveMaster) {
						masterFilter.operator = filter.operator;
						masterFilter.value = filter.value;
						continue;
					}
					else if (masterFilter.fixed) {
						continue;
					}
				}
				filters.push(node as Filter<FilterValue>);
			}
		}

		let indexForFilters = master.children.length;
		let indexForNodes = master.children.length;
		for (let i = master.children.length - 1; i >= 0; i--) {
			if (indexForNodes === master.children.length) {
				if (FilterNodeUtils.isGroup(master.children[i])) {
					indexForNodes = i;
				}
			}
			else if (indexForFilters === master.children.length) {
				if (!FilterNodeUtils.isGroup(master.children[i])) {
					indexForFilters = i;
					break;
				}
			}
		}

		indexForFilters = indexForFilters < indexForNodes ? indexForFilters : indexForNodes;
		master.children.splice(indexForNodes, 0, ...groups);
		master.children.splice(indexForFilters, 0, ...filters);
	}
}
