import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import * as Model from '../../model/customer-database.types';

@Injectable()
export class ReportSpecificationsResourceService extends Resource<Model.ReportSpecification> {
	public constructor(rest: RestService, notifier: RestChangeNotifierService) {
		super(rest, notifier, 'ReportSpecifications');
	}
}
