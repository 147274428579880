import {ComponentFactoryResolver, ComponentRef, ViewContainerRef, Type} from '@angular/core';
import {CellContext, CellValue} from '../types';
import {RendererFactory} from './renderer-factory';
import {ComponentBinder, ComponentRenderBinderRef} from './binders/component-render-binder-ref';
import {RenderAs} from './render-as';

export interface ComponentMappingParameters<C, T> {
	value: CellValue;
	context: CellContext<T>;
	binder: ComponentBinder<C, T>;
	onCreation: (instance: C, context: CellContext<T>) => void;
}

export class ComponentRendererFactory extends RendererFactory {
	public constructor() {
		super();
		this.register(RendererFactory.mainTarget, this._mainRenderer.bind(this));
	}

	protected _createComponent<C, T>(
			viewContainerRef: ViewContainerRef,
			componentType: Type<C>,
			params: ComponentMappingParameters<C, T>): ComponentRenderBinderRef<C, T> {
		const componentFactoryResolver = viewContainerRef.injector.get<ComponentFactoryResolver>(ComponentFactoryResolver as any);
		const factory = componentFactoryResolver.resolveComponentFactory(componentType);
		const componentRef = viewContainerRef.createComponent(factory);
		return this._connectComponent(componentRef, params);
	}

	protected _connectComponent<C, T>(componentRef: ComponentRef<C>, params: ComponentMappingParameters<C, T>): ComponentRenderBinderRef<C, T> {
		const binderRef = new ComponentRenderBinderRef(componentRef, params.binder);
		binderRef.updateBindings(params.value, params.context, true);
		params.onCreation && params.onCreation(componentRef.instance, params.context);
		componentRef.changeDetectorRef.detectChanges();
		return binderRef;
	}

	private _mainRenderer<C, T>(
			value: CellValue,
			context: CellContext<T>,
			componentType: Type<C>,
			binder?: ComponentBinder<C, T>,
			onCreation?: (instance: C, context: CellContext<T>) => void): any {
		const params: ComponentMappingParameters<C, T> = { value, context, binder, onCreation };
		return RenderAs.component(viewContainerRef => this._createComponent(viewContainerRef, componentType, params));
	}
}
