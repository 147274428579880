import {I18nService} from 'kn-shared';
import {GridAction} from './grid-action.type';

export function provideDefaultGridActions(i18n: I18nService, bulk: boolean = false): GridAction<any>[] {
	return [
		{
			title: i18n.t('Add'),
			order: 1,
			icon: 'add',
			permission: 'new',
			visible: x => true,
			command: () => ({ name: 'new', item: null })
		}, {
			title: i18n.t('New as copy'),
			order: 2,
			icon: 'content_copy',
			permission: 'copy',
			visible: x => (x.selected && x.selected.length) === 1,
			command: x => ({ name: 'copy', item: x.selected[0] })
		}, {
			title: i18n.t('Edit'),
			order: 3,
			icon: 'edit',
			permission: 'edit',
			visible: x => x.selected && (bulk ? (x.selected.length >= 1) : (x.selected.length === 1)),
			command: x => ({ name: 'edit', items: x.selected })
		}, {
			title: i18n.t('Remove'),
			order: 4,
			icon: 'delete',
			permission: 'remove',
			visible: x => (x.selected && x.selected.length) > 0,
			command: x => ({ name: 'delete', items: x.selected })
		}
	];
}
