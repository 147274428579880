import {Injectable, Injector} from '@angular/core';
import {Observable, from as observableFrom, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {PrintContext, PrintConfiguration} from './types';
import {HtmlRenderer} from './html-renderer.service';
import {ContextFetcher} from './context-fetcher.service';
import {ContextProcessor} from './context-processor.service';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './backends/abstract-print-backend.service';
import {HypdfPrintBackend} from './backends/hypdf-print-backend.service';
import {BrowserPrintBackend} from './backends/browser-print-backend.service';
import {PlaintextPrintBackend} from './backends/plaintext-print-backend.service';
import {CsvPrintBackend} from './backends/csv-print-backend.service';

export interface DecomposedDocument {
	doctype: string;
	head: string;
	body: string;
}

@Injectable()
export class PrinterService {
	private static readonly _htmlCommentsRegExp = /<!--[\s\S]*?-->/g;
	private static readonly _emptyLineRegExp = /^\s*[\r\n]/gm;
	private static readonly _htmlDecomposingRegExp = /^\s*(<!doctype\s+[^>]+>)?\s*<html>\s*(?:<head>([\s\S]*)<\/head>)?\s*(?:<body>([\s\S]*)<\/body>)?\s*<\/html>\s*$/i;

	public constructor(
			private readonly _injector: Injector,
			private readonly _htmlRenderer: HtmlRenderer,
			private readonly _contextFetcher: ContextFetcher,
			private readonly _contextProcessor: ContextProcessor) {
	}

	private _backends: { type: string; name: string; print: boolean; backend: AbstractPrintBackend; }[] = null;
	public get backends(): { type: string; name: string; print: boolean; backend: AbstractPrintBackend; }[] {
		if (this._backends == null) {
			this._backends = [
				{
					type: 'txt',
					name: 'Text file',
					print: false,
					backend: this._injector.get<PlaintextPrintBackend>(PlaintextPrintBackend)
				}, {
					type: 'pdf',
					name: 'PDF file',
					print: true,
					backend: this._injector.get<HypdfPrintBackend>(HypdfPrintBackend)
				}, {
					type: 'csv',
					name: 'CSV file',
					print: false,
					backend: this._injector.get<CsvPrintBackend>(CsvPrintBackend)
				}, {
					type: 'print',
					name: 'Print',
					print: true,
					backend: this._injector.get<BrowserPrintBackend>(BrowserPrintBackend)
				}
			];
		}
		return this._backends;
	}

	public backend(name: string): AbstractPrintBackend {
		const backendDesc = this.backends.find(x => x.type === name);
		return (backendDesc && backendDesc.backend)
			|| this._injector.get<BrowserPrintBackend>(BrowserPrintBackend);
	}

	public getFileName(backendName: string, name: string): string {
		const backend = this.backend(backendName);
		return name + '.' + backend.name;
	}

	public print(backendType: string, template: string, context: PrintContext, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any> {
		const backend = this.backend(backendType);
		return this.fetchContext(context, config).pipe(
			Rx.toArray(),
			Rx.switchMap(contexts => this.processContext(contexts, config)),
			Rx.switchMap(contexts => this.renderTemplate(template, contexts, config)),
			Rx.switchMap(html => backend.print(html, config, options))
		);
	}

	public printPreview(backendType: string, html: string, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any> {
		const backend = this.backend(backendType);
		return backend.printPreview(html, config, options);
	}

	public fetchContext(context: PrintContext, config?: PrintConfiguration): Observable<PrintContext> {
		return observableOf(context, config && config.data)
			.pipe(
				Rx.reduce((acc, next) => Object.assign(acc, next), {}),
				Rx.switchMap(next => this._contextFetcher.fetch(config || {}, next)),
				Rx.switchMap(next => this._fetchIterableContexts(next, config))
			);
	}

	public processContext(contexts: PrintContext[], config?: PrintConfiguration): Observable<PrintContext[]> {
		return this._contextProcessor.apply(contexts, config);
	}

	public renderTemplate(template: string, contexts: PrintContext[], config?: PrintConfiguration): Observable<string> {
		const decomposedTemplate = this._decomposeTemplate(template);
		return observableFrom(contexts).pipe(
			Rx.mergeMap(context => observableFrom(
				this._htmlRenderer.render(decomposedTemplate.body, context, config))),
			Rx.reduce((acc, part) => this._aggragatePart(acc, part, config), null as string),
			Rx.map(body => this._composeHtml(
				Object.assign(decomposedTemplate, { body: this._cleanComments(body) })))
		);
	}

	protected _fetchIterableContexts(ambientContext: PrintContext, config?: PrintConfiguration) {
		if (config == null || config.iterate == null) {
			return observableOf(ambientContext);
		}
		const { iterable, key } = this._parseIterateRule(config.iterate.for);
		if (!ambientContext.hasOwnProperty(iterable)) {
			throw new Error('Passed context not contains specified iterable.');
		}
		const context$ = observableFrom(ambientContext[iterable]).pipe(
			Rx.map((next, index) => ({ [key]: next, index: index })),
			Rx.mergeMap(next => this._contextFetcher.fetch(config.iterate, next, ambientContext))
		);
		if (config.iterate.aggregate) {
			return context$.pipe(
				Rx.toArray(),
				Rx.map(next => Utils.array.sort(next, 'index')),
				Rx.map(next => Object.assign({}, ambientContext, { [iterable]: next }))
			);
		}
		return context$.pipe(Rx.map(next => Object.assign({}, ambientContext, next)));
	}

	protected _decomposeTemplate(html: string): DecomposedDocument {
		html = this._cleanComments(html);
		let matches = html.match(PrinterService._htmlDecomposingRegExp);
		if (matches == null) {
			matches = Array(3).concat(html);
		}
		return {
			doctype: matches[1] || '<!DOCTYPE html>',
			head: matches[2] || '',
			body: matches[3] || ''
		} as DecomposedDocument;
	}

	protected _cleanComments(html: string): string {
		return html.replace(PrinterService._htmlCommentsRegExp, '').replace(PrinterService._emptyLineRegExp, '');
	}

	protected _aggragatePart(acc: string, part: string, config?: PrintConfiguration): string {
		if (acc == null) {
			return part;
		}
		return acc + '<br/>' + part;
	}

	protected _composeHtml(html: DecomposedDocument): string {
		return html.doctype + '<html>\n<head>' + html.head + '</head>\n<body>' + html.body + '</body>\n</html>';
	}

	protected _parseIterateRule(rule: string) {
		const matches = rule.match(/^\s*let\s+([^ ]+)\s+of\s+([^ ]+)\s*$/);
		if (matches == null) {
			throw new Error('Invalid iterate rule.');
		}
		return { key: matches[1], iterable: matches[2] };
	}
}
