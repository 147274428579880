import {Injectable, Inject, Optional} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpWrapper, Http, Request, Response, WebsocketConfig, WebsocketSubject, Uri, UriQuery} from 'kn-http';
import {Utils} from 'kn-utils';
import {JwtAuthStore} from './jwt-auth-store.service';

export class JwtAuthHttpConfig {
	public authHeader?: string;
	public queryAuthParameter?: string;
}

@Injectable()
export class JwtAuthHttp extends HttpWrapper {
	private readonly _options: JwtAuthHttpConfig;

	public constructor(
			@Inject(Http) http: Http,
			private readonly _store: JwtAuthStore,
			@Optional() options?: JwtAuthHttpConfig) {
		super(http);
		this._options = Utils.object.defaults({}, options, {
			authHeader: 'Authorization',
			queryAuthParameter: 'token'
		} as JwtAuthHttpConfig);
	}

	private _getBearer(): string {
		const token = this._store.getToken();
		return token && `Bearer ${token}`;
	}

	protected _executeRequest(request: Request): Observable<Response> {
		const bearer = this._getBearer();
		if (bearer) {
			request.headers.set(this._options.authHeader, bearer);
		}
		return super._executeRequest(request);
	}

	protected _establishWebsocket(config: WebsocketConfig): WebsocketSubject {
		const query = new UriQuery();
		const bearer = this._getBearer();
		if (bearer != null) {
			query.parameters[this._options.queryAuthParameter] = bearer;
		}
		return super._establishWebsocket(new WebsocketConfig(Uri.from(config.uri, query), config));
	}
}
