export class CanvasColorParser {
	public static parse(value: string | CanvasGradient | CanvasPattern): { color: string | number[] | PDFKit.PDFGradient, opacity?: number } {
		if (typeof value === 'string' || value instanceof String) {
			const color = value as string;
			const matches = color.match(/^(rgb|rgba)\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(0|\d*(?:\.\d+)?)\s*)?\)$/);
			if (matches != null) {
				return {
					color: matches.slice(2, 5).map(x => +x),
					opacity: matches[6] == null ? undefined : +matches[6]
				};
			}
			return { color: color };
		}
		throw new Error('Unsupported color definition.');
	}
}
