import {Injectable, Inject, Optional} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {UriContext} from 'kn-http';
import {AMBIENT_CONTEXT_TOKEN} from './ambient-context.token';

@Injectable()
export class UriContextService {
	private readonly _changesSubject$: Subject<UriContext>;
	private readonly _changes$: Observable<UriContext>;
	private readonly _ambientContext: UriContext;
	private _context: UriContext = {};

	public constructor(
			@Inject(AMBIENT_CONTEXT_TOKEN) @Optional() ambientContext?: any /* UriContext */) {
		this._ambientContext = ambientContext || {};
		this._changesSubject$ = new Subject<UriContext>();
		this._changes$ = this._changesSubject$.asObservable();
	}

	public get contextChanges(): Observable<UriContext> {
		return this._changes$;
	}

	public get ambientContext(): UriContext {
		return this._ambientContext;
	}

	public get context(): UriContext {
		return Object.assign({}, this._ambientContext, this._context);
	}

	public add(context: UriContext) {
		Object.assign(this._context, context);
		this._changesSubject$.next(this.context);
	}

	public remove(keys: string[]) {
		keys.forEach(x => delete this._context[x]);
		this._changesSubject$.next(this.context);
	}

	public clear() {
		this._context = {};
		this._changesSubject$.next(this.context);
	}
}
