<kn-menu #menu class="permission-actions-select">
	<kn-checkbox
			[title]="title"
			[indeterminate]="state === null"
			[checked]="state"
			(change)="cyclePermissions()"></kn-checkbox>
	<kn-menu-content class="permission-actions-select">
		<ng-template [ngIf]="menu.expanded">
			<div *ngFor="let action of actions">
				<kn-checkbox [checked]="action.state" (checkedChange)="setPermission(action, $event)">
					<span kn-label>
						<span class="name">{{ action.name }}</span>
						<span class="description">{{ action.description }}</span>
					</span>
				</kn-checkbox>
			</div>
		</ng-template>
	</kn-menu-content>
</kn-menu>
