import {Injectable} from '@angular/core';
import {AbstractModuleLoader} from 'kn-common';
import {DataSource, RowItem, Spreadsheet, SpreadsheetData, AbstractExporter, SpreadsheetBuilder} from 'kn-datagrid';
import {Utils} from 'kn-utils';

@Injectable()
export class XlsxExporter extends AbstractExporter {
	private readonly _xlsxProviderFunctor: () => Promise<any>;

	public constructor(
			moduleLoader: AbstractModuleLoader,
			spreadsheetBuilder: SpreadsheetBuilder) {
		super(spreadsheetBuilder, 'export:xlsx');

		let xlsxProvider: Promise<any>;
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		this._xlsxProviderFunctor = () => {
			if (xlsxProvider == null) {
				xlsxProvider = moduleLoader.import<any>('./assets/js/xlsx-populate.js', { name: 'XlsxPopulate' });
			}
			return xlsxProvider;
		};
	}

	public async export(dataSource: DataSource<RowItem>): Promise<Blob> {
		const xlsx = await this._xlsxProviderFunctor();
		const workbook = await xlsx.fromBlankAsync();
		const worksheet = workbook.sheet(0).name('export');
		const objectTree = this._spreadsheetBuilder.build(
			dataSource, this.exportTarget, this.model, this.description);
		if (objectTree.columns.length > 0) {
			this._writeSheet(worksheet, objectTree);
			this._applyStyles(worksheet, objectTree);
		}
		return workbook.outputAsync();
	}

	private _writeSheet(worksheet: any, objectTree: Spreadsheet) {
		worksheet
			.range(1, 1, 1, objectTree.columns.length)
				.value([objectTree.columns.map(x => x.label)]);
		const data = this._getObjectTreeData(objectTree.data);
		worksheet
			.range(2, 1, 2 + data.length, objectTree.columns.length)
				.value(data);
		return worksheet;
	}

	private _getObjectTreeData(objectTreeData: SpreadsheetData[]): string[][] {
		const result: string[][] = new Array();

		objectTreeData.forEach(x => {
				if (x.children && x.children.length > 0) {
					const child = x.children[0];
					if (child && child.children && child.children.length > 0) {
						this._getObjectTreeData(x.children).forEach(r => result.push(r));
						return;
					}
				}
				result.push(x.cells);
			}
		);

		return result;
	}

	private _applyStyles(worksheet: any, objectTree: Spreadsheet) {
		worksheet.row(1).style({ bold: true, bottomBorderStyle: 'thin' });
		const content = worksheet.usedRange().value() as any[][];
		for (let i = 0; i < content[0].length; i++) {
			const column = content.map(x => x[i]);
			let width: number;
			if (column.some(x => Utils.isDate(x))) {
				width = Math.max(22, this._calcColumnWidth([column[0]]));
				worksheet.column(i + 1).style('numberFormat', 'dd.mm.yyyy hh:mm:ss');
			}
			else {
				width = this._calcColumnWidth(column);
			}
			worksheet.column(i + 1).width(width);
		}
	}

	private _calcColumnWidth(column: any[]) {
		const width = column
			.reduce((acc, x) => Math.max(acc || 0, x == null ? 0 : `${x}`.length), null);
		return width == null ? null : (width * 1.1 + 1.5);
	}
}
