<div class="picker-relative-container">
	<ul>
		<li *ngFor="let hourMinute of hourMinutes.labels"
			[style.left.%]="hourMinute.position.x"
			[style.top.%]="hourMinute.position.y">
			<a href="javascript:void(0)"
				[class.now]="hourMinute.value === nowMinute"
				[class.selected]="hourMinute.value === minute"
				(click)="selectMinute(hourMinute.value)">{{ hourMinute.name }}</a>
		</li>
	</ul>
	<ul class="minor">
		<li *ngFor="let hourMinute of hourMinutes.ticks"
			class="minor"
			[class.major]="hourMinute.major"
			[style.left.%]="hourMinute.position.x"
			[style.top.%]="hourMinute.position.y">
			<a href="javascript:void(0)"
				[class.now]="hourMinute.value === nowMinute"
				[class.selected]="hourMinute.value === minute"
				(click)="selectMinute(hourMinute.value)">{{ hourMinute.name }}</a>
		</li>
	</ul>
	<div class="center"></div>
	<div class="gauge" style="width: 50%;"
		*ngIf="minute != null"
		[style.transform]="'rotate(' + (6 * minute - 90) + 'deg)'"></div>
</div>
