import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {UsersConfig} from './users.config';
import {UsersResourceService} from './users-resource.service';
import {UserRolesResourceService} from './user-roles-resource.service';

export class UsersOptions extends UsersConfig { }

export const USERS_FORROOT_GUARD = new InjectionToken<void>('USERS_FORROOT_GUARD');

export function provideUsersConfig(config: UsersOptions) {
	return Utils.object.defaults(config, {
		usersTable: 'Users',
		userRolesTable: 'UserRoles'
	});
}

@NgModule({})
export class KnUsersModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(USERS_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<UsersOptions>): ModuleWithProviders<KnUsersModule> {
		return {
			ngModule: KnUsersModule,
			providers: [
				...forRootGuardFactory('KnUsersModule', USERS_FORROOT_GUARD, UsersResourceService),
				{
					provide: UsersOptions, useValue: config ? config : {}
				}, {
					provide: UsersConfig,
					useFactory: provideUsersConfig,
					deps: [UsersOptions]
				},
				UsersResourceService,
				UserRolesResourceService
			]
		};
	}
}
