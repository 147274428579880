import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {AccessControls} from '../access-control/access-controls';
import {AbstractAccessControl} from '../access-control/abstract-access-control';
import {AuthorizationService} from './authorization.service';
import {AuthorizationConfig} from './authorization.config';
import {RootUserService} from '../root-user.service';
import {Policy} from './policy';
import {Permission, AccessControlFactory} from '../configuration.types';

@Injectable()
export class PermissionConfiguratorService {
	private _context: { [key: string]: any };

	public constructor(
			private readonly _user: RootUserService,
			private readonly _accessControls: AccessControls,
			private readonly _config: AuthorizationConfig) {
		this.reconfigure();
	}

	public reconfigure(context?: { [key: string]: any }) {
		this._context = context || this._context;
		const root = Policy.root();
		root.children = this._user.permissions
			.map(x => this._buildPermissionNode(root, x, this._buildContext(this._context)));
		(this._user.authorization as AuthorizationService).update(root);
	}

	public get context() {
		return this._context;
	}

	private _buildPermissionNode(parent: Policy, permission: Permission, context: { [key: string]: any }) {
		const accessControlRule = permission.accessControl || this._config.defaultAccessControl;
		let accessControl: AbstractAccessControl;
		if (Utils.isString(accessControlRule)) {
			const factory = this._accessControls.find(accessControlRule as string);
			accessControl = factory.create(context, permission.mapping);
		}
		else {
			const factory = accessControlRule as AccessControlFactory;
			accessControl = factory(context, permission.mapping);
		}
		const node = Policy.child(permission.resource, accessControl, parent);
		if (permission.children) {
			node.children = permission.children
				.map(x => this._buildPermissionNode(node, x, context));
		}
		return node;
	}

	private _buildContext(context: { [key: string]: any }) {
		return Object.assign({
			authenticated: this._user.isAuthenticated(),
			user: this._user.getUser()
		}, context);
	}
}
