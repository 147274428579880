import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnRadioGroup} from './radio-group.directive';
import {KnRadio} from './radio.component';

const KN_RADIO_DIRECTIVES = [
	KnRadioGroup,
	KnRadio
];

@NgModule({
	imports: [CommonModule, FormsModule, KnDirectivesModule],
	declarations: [KN_RADIO_DIRECTIVES],
	exports: [KN_RADIO_DIRECTIVES]
})
export class KnRadioModule { }
