export namespace OwnershipUtils {
	export const ownershipRegExp = /^([a-z0-9_-]+\/)*([a-z0-9_-]+)?$/;

	export function toRelative(prefix: string, ownership: string) {
		if (ownership && ownership.startsWith(prefix)) {
			return ownership.substr(prefix.length);
		}
		return ownership || '';
	}

	export function toAbsolute(prefix: string, ownership: string) {
		if (prefix && ownership) {
			ownership = prefix + (prefix[prefix.length - 1] !== '/' ? '/' : '') + ownership;
		}
		else {
			ownership = prefix || ownership || '';
		}
		if (ownership && ownership[ownership.length - 1] !== '/') {
			ownership += '/';
		}
		if (!ownershipRegExp.test(ownership)) {
			throw new Error('Invalid ownership.');
		}
		return ownership;
	}
}
