import {Component, Injector, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChange, ViewChild} from '@angular/core';
import {KnInput} from 'kn-forms';
import {AbstractViewTemplateSelector} from './abstract-view-template-selector.component';
import {ViewTemplateItem} from '../../services/view-template-manager/types';
import { Utils } from 'kn-utils';

@Component({
	selector: 'kn-view-template-selector',
	templateUrl: 'view-template-selector.html'
})
export class KnViewTemplateSelector extends AbstractViewTemplateSelector implements OnInit, OnChanges {
	@Input() public queryParam: string;
	@Input() public view: string;
	@Input() public version: string;
	@Input() public template: any;
	@Output() public templateChange: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild('nameInput', { static: false })
	public set nameInput(value: KnInput) {
		value && !value.focused && setTimeout(() => value.focus());
	}

	public constructor(injector: Injector) {
		super(injector);
	}

	public ngOnChanges(changes: { [key: string]: SimpleChange }) {
		if ('template' in changes) {
			this._updateModified();
		}
	}

	protected _setSelected(template: ViewTemplateItem<any>) {
		super._setSelected(template);
		this.templateChange.emit(template && template.value);
	}

	public select(id: number) {
		if (id == null) {
			this.isSaveMode = true;
		}
		else {
			super.select(id);
		}
	}

	public save(idOrName: { id?: number, name?: string, userUid?: string }) {
		if (Utils.isString(idOrName)) {
			idOrName = {name: idOrName as string};
		}
		super.save(idOrName);
	}
}
