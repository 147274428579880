import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('pad')
export class PadFilter implements PrintRendererFilter {
	public transform(value: string, length: number = 0, padChar?: string): string {
		value = `${value}` || '';
		padChar = ((padChar != null) && `${padChar}`) || ' ';
		if (length > 0) {
			return (value + padChar.repeat(length)).substr(0, length);
		}
		else {
			return (padChar.repeat(-length) + value).substr(length, -length);
		}
	}
}
