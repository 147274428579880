import {Description, Model, DataSource, ColumnModel, Column, RowItem} from '../types';
import {getSorting, dataSourceSort} from '../utils';

export abstract class AbstractDatagridBond<T extends RowItem> {
	public abstract loading: boolean;

	public abstract description: Description<T>;

	public abstract model: Model;
	public abstract onModelChange(value: Model): void;

	public abstract dataSource: DataSource<T>;
	public abstract onDataSourceChange(value: DataSource<T>): void;

	public abstract expanded: T[];
	public abstract onExpandedChange(value: T[]): void;

	public abstract selected: T[];
	public abstract onSelectedChange(value: T[]): void;

	public abstract onRowSelect(value: T): void;
	public abstract onRowActivate(value: T): void;

	public abstract refreshRows(rebuildTree?: boolean, refreshItems?: RowItem[]): void;

	protected _getSorting: { (columns: ColumnModel[], description: Description<T>): Column<T>[] } = getSorting;
	protected _dataSourceSort: { (dataSource: DataSource<T>, sortingColumns: Column<T>[]): DataSource<T> } = dataSourceSort;
}
