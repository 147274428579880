import {Directive, Input, HostBinding, ElementRef, Renderer2, ContentChildren, QueryList} from '@angular/core';
import {Node} from '../model/node';
import {RowItem} from '../types';
import {KnTableCell} from './table-cell.component';

@Directive({
	selector: 'tr[knTableRow]'
})
export class KnTableRow {
	private _marked: boolean;
	private _dragging: boolean;

	@Input() public node: Node<RowItem>;

	public constructor(
			private readonly _element: ElementRef,
			private readonly _renderer: Renderer2) {
	}

	@ContentChildren(KnTableCell, { descendants: false })
	public cells: QueryList<KnTableCell>;

	@HostBinding('class.leaf')
	public get isLeaf(): boolean {
		return this.node.isLeaf();
	}

	public set marked(value: boolean) {
		this._marked = value;
		this._setClass('marked', this._marked);
	}

	public get marked() {
		return this._marked;
	}

	public set dragging(value: boolean) {
		this._dragging = value;
		this._setClass('dragging', this._dragging);
	}

	public get dragging() {
		return this._dragging;
	}

	public getClientRect(): ClientRect {
		return this._element.nativeElement.getBoundingClientRect();
	}

	public scrollIntoView() {
		const scrollable = this._findScrollableParent(this._element.nativeElement);
		if (!scrollable) {
			return;
		}
		const bounding = scrollable.getBoundingClientRect();
		const rect = this.getClientRect();
		if (rect.top < (scrollable.scrollHeight - bounding.top)) {
			this._element.nativeElement.scrollIntoView({block: 'center', inline: 'nearest'});
		}
		else if (rect.bottom > (scrollable.scrollHeight - bounding.bottom)) {
			this._element.nativeElement.scrollIntoView({block: 'center', inline: 'nearest'});
		}
	}

	public refreshCells() {
		this.cells.map((item, index, array) => {
			item.refresh();
		});
	}

	private _setClass(className: string, isAdd: boolean) {
		const setElementClass = (isAdd ? this._renderer.addClass : this._renderer.removeClass)
			.bind(this._renderer);
		setElementClass(this._element.nativeElement, className);
	}

	private _findScrollableParent(element: HTMLElement): HTMLElement {
		let lineHeight = element && parseInt(window.getComputedStyle(element).lineHeight, 10);
		if (isNaN(lineHeight)) {
			lineHeight = 0;
		}
		return element && (element.scrollHeight - lineHeight > element.clientHeight
				? element
				: this._findScrollableParent(element.parentElement));
	}
}
