import {Injectable, Inject, Optional} from '@angular/core';
import {Observable, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {PrintContext, PrintConfiguration, PrintContextProcessor} from './types';
import {PRINT_PROCESSOR_TOKEN} from './print-processor.token';
import {PrintProcessorMetadata} from './metadata/print-processor.decorator';

@Injectable()
export class ContextProcessor {
	public constructor(@Optional() @Inject(PRINT_PROCESSOR_TOKEN) private readonly _processors: PrintContextProcessor[]) { }

	public apply(contexts: PrintContext[], config: PrintConfiguration): Observable<PrintContext[]> {
		let contexts$ = observableOf(contexts);
		for (const key of config.processors || []) {
			contexts$ = contexts$
				.pipe(Rx.switchMap(next => this._process(key, next, config)));
		}
		return contexts$;
	}

	private _process(key: string, contexts: PrintContext[], config: PrintConfiguration) {
		const processor = (this._processors || [])
			.find(x => this._getProcessorKey(x) === key);
		if (processor == null) {
			throw new Error(`Print context processor with name '${key}' not found.`);
		}
		return processor.process(contexts, config);
	}

	private _getProcessorKey(processor: PrintContextProcessor) {
		return Utils.reflector.annotations(processor.constructor)
			.find(y => y instanceof PrintProcessorMetadata)?.key;
	}
}
