import {Injectable} from '@angular/core';
import {Observable, of as observableOf} from 'rxjs';
import {SandboxedDocument, SandboxedDocumentBuilder} from 'kn-common';
import {AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {AbstractDomPrintBackend} from './abstract-dom-print-backend.service';

@Injectable()
export class BrowserPrintBackend extends AbstractDomPrintBackend {
	public constructor(docBuilder: SandboxedDocumentBuilder) {
		super(docBuilder);
	}

	public get name(): string {
		return 'browser';
	}

	protected _print(doc: SandboxedDocument, options?: AbstractPrintBackendOptions): Observable<any> {
		return observableOf(doc.nativeWindow.print());
	}
}
