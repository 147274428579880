import {AccessControlFactory} from '../configuration.types';

export enum Access {
	Deny,
	Allow
}

export class AuthorizationConfig {
	public defaultAccessControl: string | AccessControlFactory;
	public missingPolicyStrategy: Access;
	public fallbackAccess: Access;
}
