import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {KcuniBridgeService} from './kcuni-bridge.service';
import {KcuniChip} from './types';

@Directive({
	selector: '[knKcuniChipRead]'
})
export class KnKcuniChipRead {
	private _subscription: Subscription;

	public constructor(private readonly _kcuni: KcuniBridgeService) { }

	@HostListener('focus')
	public activate() {
		this.deactivate();
		this._subscription = this._kcuni.chipsFeed()
			.pipe(Rx.filter(next => next.type !== 'UniPCD'))
			.subscribe(next => this.chip.next(next));
	}

	@HostListener('blur')
	public deactivate() {
		this._subscription && this._subscription.unsubscribe();
	}

	@HostBinding('class.reader-active')
	public get isActive() {
		return this._subscription && !this._subscription.closed;
	}

	@Output('knKcuniChipRead') public chip = new EventEmitter<KcuniChip>();
}
