import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Http} from 'kn-http';
import {KnCommonModule} from 'kn-common';
import {KnFormsModule} from 'kn-forms';
import {forRootGuardFactory} from 'kn-shared';
import {KcuniBridgeService, KcuniBridgeConfig} from './kcuni-bridge.service';
import {KnKcuniChipRead} from './kcuni-chip-read.directive';
import {KnKcuniSettings} from './kcuni-settings.component';
import {KCUNI_HTTP_TOKEN} from './kcuni-http.token';

export function provideKcuniBridgeService(http: Http, config: KcuniOptions) {
	return new KcuniBridgeService(http, config.bridge);
}

export class KcuniOptions {
	public bridge: KcuniBridgeConfig;
}

export const KCUNI_FORROOT_GUARD = new InjectionToken<void>('KCUNI_FORROOT_GUARD');

const KN_KCUNI_DIRECTIVES = [
	KnKcuniChipRead,
	KnKcuniSettings
];

@NgModule({
	imports: [CommonModule, KnCommonModule.forChild(), KnFormsModule.forChild()],
	declarations: [KN_KCUNI_DIRECTIVES],
	exports: [KN_KCUNI_DIRECTIVES]
})
export class KnKcuniModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KCUNI_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<KcuniOptions>): ModuleWithProviders<KnKcuniModule> {
		return {
			ngModule: KnKcuniModule,
			providers: [
				...forRootGuardFactory('KnKcuniModule', KCUNI_FORROOT_GUARD, KcuniBridgeService),
				{
					provide: KcuniOptions, useValue: config ? config : {}
				}, {
					provide: KCUNI_HTTP_TOKEN,
					useExisting: Http
				}, {
					provide: KcuniBridgeService,
					useFactory: provideKcuniBridgeService,
					deps: [KCUNI_HTTP_TOKEN, KcuniOptions]
				}
			]
		};
	}

	public static forChild(): ModuleWithProviders<KnKcuniModule> {
		return { ngModule: KnKcuniModule };
	}
}
