import {Injectable} from '@angular/core';
import {AbstractTemplateEncoder} from './abstract-template-encoder';

@Injectable()
export class JsonTemplateEncoder<T extends {}> extends AbstractTemplateEncoder<T> {
	public support(view: string): boolean {
		return true;
	}

	public encode(view: string, value: T): string {
		return value && JSON.stringify(value);
	}

	public decode(view: string, value: string): T {
		return JSON.parse(value || '{}');
	}
}
