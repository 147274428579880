import {ComponentRef, OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import {CellContext, RowItem, CellValue} from '../../types';
import {RenderBinderRef} from './render-binder-ref';
import {Utils} from 'kn-utils';

export function updateBinding<C, U>(componentRef: ComponentRef<C>, bindingName: string, value: U | { (): U }, firstChange?: boolean): SimpleChanges {
	const previousValue = (componentRef.instance as any)[bindingName];
	const currentValue = Utils.isFunction(value) ? (value as () => U)() : value;
	if (!Utils.looseIdentical(previousValue, currentValue)) {
		const changes: SimpleChanges = {
			[bindingName]: new SimpleChange(previousValue, currentValue, firstChange)
		};
		(componentRef.instance as any)[bindingName] = currentValue;
		return changes;
	}
	return {};
}

export type ComponentBinder<C, T extends RowItem> = {
	(componentRef: ComponentRef<C>, value: CellValue, context: CellContext<T>, firstChange: boolean): SimpleChanges
};

export class ComponentRenderBinderRef<C, T extends RowItem> extends RenderBinderRef<T> {
	private readonly _hasOnChanges: boolean;

	public constructor(
			private readonly _componentRef: ComponentRef<C>,
			private readonly _binder?: ComponentBinder<C, T>) {
		super();
		this._hasOnChanges = Utils.reflector.hasHook('ngOnChanges', this.component.instance);
		if (this._binder == null) {
			this._binder = this._createDefaultBinder(this.component);
		}
		this.changeDetectorRef = this._componentRef.changeDetectorRef;
	}

	public get component(): ComponentRef<C> {
		return this._componentRef;
	}

	public get binder(): ComponentBinder<C, T> {
		return this._binder;
	}

	public updateBindings(value: CellValue, context: CellContext<T>, firstChange?: boolean) {
		const changes = this.binder(this.component, value, context, firstChange || false);
		if (this._hasOnChanges && Object.keys(changes).length > 0) {
			(this.component.instance as any as OnChanges).ngOnChanges(changes);
		}
		return changes;
	}

	protected _createDefaultBinder(componentRef: ComponentRef<C>): ComponentBinder<C, T> {
		const hasValue = componentRef.instance.hasOwnProperty('value');
		const hasContext = componentRef.instance.hasOwnProperty('context');
		return (component, value, context, firstChange) => {
			const changes = Object.assign({},
				hasValue && updateBinding(component, 'value', value, firstChange),
				hasContext && updateBinding(component, 'context', context, firstChange)
			);
			return changes;
		};
	}
}
