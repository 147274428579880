import {forwardRef, ViewEncapsulation, Component, HostBinding, Input, ViewChild, ElementRef, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectorRef, Provider} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {BooleanField} from 'kn-common';
import {ContainedFocus} from '../../contained-focus';

export const KN_TEXTAREA_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnTextarea), // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
	multi: true
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-textarea',
	templateUrl: 'textarea.html',
	styleUrls: ['textarea.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [KN_TEXTAREA_CONTROL_VALUE_ACCESSOR],
	host: {
		'(click)' : 'focus()'
	}
})
export class KnTextarea implements OnInit, OnDestroy, ControlValueAccessor {
	private readonly _containedFocus: ContainedFocus;
	private _value: any = '';

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@HostBinding('class.focused')
	public get focused() {
		return this._containedFocus.focused;
	}

	public get inputId(): string {
		return `${this.id}-input`;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-textarea-${nextUniqueId++}`;

	@Input() @BooleanField() public autofocus: boolean = false;
	@Input() public maxlength: number = null;
	@Input() public placeholder: string = null;
	@Input() @BooleanField() public required: boolean = false;
	@Input() @BooleanField() public spellcheck: boolean = false;
	@Input() @BooleanField() public hideEmptyLabel: boolean = false;
	@Input() public tabindex: number = null;
	@Input() public wrap: string = 'text';
	@Input() public name: string = null;
	@Input() public rows: number = null;
	@Input() public cols: number = null;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@HostBinding('class.loading')
	@Input() public loading: boolean = false;

	public get value(): any {
		return this._value;
	}

	@Input() public set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this._onChangeCallback(v);

			// FIXME: emit only on user interaction (move to handleChange?, but witgets like
			// datepicker and autocomplete must emit this event too)
			this.valueChange.emit(v);
		}
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<string>();
	@Output() public keyup = new EventEmitter<KeyboardEvent>();
	@Output() public keydown = new EventEmitter<KeyboardEvent>();

	@ViewChild('input', { static: true })
	public _inputElement: ElementRef;

	@ViewChild('knLabelContent', { static: true })
	private readonly _knLabel: ElementRef;

	public hasKnLabel: boolean = true;

	public constructor(private readonly _cdr: ChangeDetectorRef) {
		this._containedFocus = new ContainedFocus(
			event => {
				this.focusEvent.emit(event);
			},
			event => {
				this._onTouchedCallback();
				this.blurEvent.emit(event);
				this._cdr.markForCheck();
			}
		);
	}

	public ngOnInit() {
		this._containedFocus.register(this._inputElement);
	}

	public ngOnDestroy() {
		this._containedFocus.unregisterAll();
	}

	public ngAfterViewInit() {
		this.hasKnLabel = !this.hideEmptyLabel || this._knLabel && this._knLabel.nativeElement && this._knLabel.nativeElement.children.length > (this.required ? 1 : 0);
		this._cdr.detectChanges();
	}

	public registerChild(element: ElementRef) {
		this._containedFocus.register(element, true);
		return () => this._containedFocus.unregister(element);
	}

	public focus() {
		this._inputElement.nativeElement.focus();
	}

	public select() {
		this._inputElement.nativeElement.select();
	}

	/** @internal */
	public handleChange(event: Event) {
		this.value = (event.target as HTMLInputElement | HTMLTextAreaElement).value;
		this._onTouchedCallback();
		this.changeEvent.emit();
	}

	/** @internal */
	public handleKeyup(event: KeyboardEvent) {
		this.keyup.emit(event);
	}

	/** @internal */
	public handleKeydown(event: KeyboardEvent) {
		this.keydown.emit(event);
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._value = value;
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
