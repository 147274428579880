import {Injectable, Inject} from '@angular/core';
import {Observable, of as observableOf, merge as observableMerge} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Http} from 'kn-http';
import {PrintContext, ContextConfiguration} from './types';
import {AbstractResolutionStrategy, ResolutionFunctor} from './resolution-strategies/abstract-resolution-strategy';
import {PRINT_HTTP_TOKEN} from './print-http.token';

@Injectable()
export class ContextFetcher {
	public constructor(
			@Inject(PRINT_HTTP_TOKEN) private readonly _http: Http,
			private readonly _strategy: AbstractResolutionStrategy) {
	}

	public fetch(config: ContextConfiguration, context: PrintContext, ambientContext?: PrintContext): Observable<PrintContext> {
		const avaibleKeys = Utils.array.unique(Object.keys(context), Object.keys(ambientContext || {}));
		const sequence = this._strategy.create(config, avaibleKeys);
		let fetcher$ = observableOf(context);
		for (const functor of sequence) {
			fetcher$ = fetcher$
				.pipe(Rx.switchMap(next => this._fetch(next, ambientContext, functor)));
		}
		return fetcher$;
	}

	protected _fetch(context: PrintContext, ambientContext: PrintContext, functor: ResolutionFunctor) {
		const uris = functor(Object.assign({}, ambientContext, context));
		const fetches$: Observable<PrintContext>[] = [];
		for (const key in uris) {
			if (uris.hasOwnProperty(key)) {
				fetches$.push(this._http.get(uris[key].toString())
					.pipe(Rx.map(next => ({ [key]: next.body }))));
			}
		}
		return observableMerge(...fetches$)
			.pipe(Rx.reduce((ctx, next) => Object.assign(ctx, next), context));
	}
}
