import {FormGroup, FormArray, AbstractControl, ValidationErrors} from '@angular/forms';

export abstract class AbstractFormControl<T extends AbstractControl> {
	public readonly abstract control: T;

	public get value(): any {
		return this.control.value;
	}

	public get parent(): FormGroup | FormArray {
		return this.control.parent;
	}

	public get status(): string {
		return this.control.status;
	}

	public get valid(): boolean {
		return this.control.valid;
	}

	public get invalid(): boolean {
		return this.control.invalid;
	}

	public get pending(): boolean {
		return this.control.pending;
	}

	public get disabled(): boolean {
		return this.control.disabled;
	}

	public get enabled(): boolean {
		return this.control.enabled;
	}

	public get errors(): ValidationErrors | null {
		return this.control.errors;
	}

	public get pristine(): boolean {
		return this.control.pristine;
	}

	public get dirty(): boolean {
		return this.control.dirty;
	}

	public get touched(): boolean {
		return this.control.touched;
	}

	public get untouched(): boolean {
		return this.control.untouched;
	}

	public get(path: (string | number)[] | string): AbstractControl | null {
		return this.control.get(path);
	}

	public getError(errorCode: string, path?: string[]): any {
		return this.control.getError(errorCode, path);
	}

	public hasError(errorCode: string, path?: string[]): boolean {
		return this.control.hasError(errorCode, path);
	}
}
