import {Observable} from 'rxjs';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {Indexer, ChangeEntry} from 'kn-rest';
import {AncestorMapper} from './types';
import * as Model from 'common-web/model';

export abstract class AbstractDependentEntitiesStore<T extends Model.EntityBase> {
	private readonly _ancestorMapper: AncestorMapper<T>;

	public constructor(
			ancestorMapper: AncestorMapper<T> | string,
			protected _context?: UriContext) {
		this._ancestorMapper = ancestorMapper as AncestorMapper<T>;
		if (Utils.isString(this._ancestorMapper)) {
			this._ancestorMapper = ancestorIndexer => ({
				[ancestorMapper as string]: ancestorIndexer
			}) as T;
		}
	}

	public get ancestorMapper() {
		return this._ancestorMapper;
	}

	public abstract get changes(): Observable<ChangeEntry>;

	public get(ancestorIndexer: Indexer | Indexer[]): Observable<T[]> {
		return this._query(this._buildContext(ancestorIndexer, this._context));
	}

	public save(ancestorIndexer: Indexer, items: T[]): Observable<Response[]> {
		return this._save(items, this._buildContext(ancestorIndexer));
	}

	public remove(ancestorIndexer: Indexer): Observable<Response> {
		return this._remove(this._buildContext(ancestorIndexer));
	}

	protected _buildContext(ancestorIndexer: Indexer | Indexer[], context?: UriContext) {
		const query: { [key: string]: any } = {};
		if (Array.isArray(ancestorIndexer)) {
			const partials = (ancestorIndexer as Indexer[]).map(x => this._ancestorMapper(x));
			const partialq: string[] = [];
			for (const partial of partials) {
				const q: string[] = [];
				for (const key in partial) {
					if (partial.hasOwnProperty(key)) {
						q.push(encodeURIComponent(key + '=' + partial[key]));
					}
				}
				partialq.push('(' + q.join('&') + ')');
			}
			query['q'] = encodeURIComponent(partialq.join('|'));
		}
		else {
			const partial = this._ancestorMapper(ancestorIndexer);
			for (const key in partial) {
				if (partial.hasOwnProperty(key)) {
					query['$' + key] = partial[key];
				}
			}
		}
		context = Object.assign({}, context);
		context['query'] = Object.assign({}, context['query'], query);
		return context;
	}

	protected abstract _query(context: UriContext): Observable<T[]>;
	protected abstract _save(items: T[], context: UriContext): Observable<Response[]>;
	protected abstract _remove(context: UriContext): Observable<Response>;
}
