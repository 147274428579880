import {Injectable} from '@angular/core';
import {Observable, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {RestService, RestChangeNotifierService, UriContextService, LiveFeedResource, LiveFeed} from 'kn-rest';
import {UriContext} from 'kn-http';
import {DatabasesConfig} from './databases.config';
import * as Model from 'common-web/model';

@Injectable()
export class DatabasesResourceService extends LiveFeedResource<Model.Database> {
	private readonly _defaultContext = {
		sort: 'name',
		disabled: false,
		query: {
			only: ['id', 'uid', 'name', 'description']
		}
	};

	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			private readonly _uriContext: UriContextService,
			private readonly _config: DatabasesConfig) {
		super(rest, notifier, _config.table);
	}

	public getMasterDatabase(): Partial<Model.Database> {
		return {
			description: 'Master database',
			name: 'Master',
			ownership: '',
			type: 'master',
			uid: 'master',
			disabled: false
		};
	}

	public liveFeed(context?: UriContext): LiveFeed<Model.Database> {
		return super.liveFeed(context || this._defaultContext);
	}

	public query(context?: UriContext): Observable<Model.Database[]> {
		return super.query(context || this._defaultContext);
	}

	public single(): Observable<Model.Database> {
		return super.query({ query: { limit: 2 } }).pipe(
			Rx.map(next => {
				if (next.length === 1) {
					return next[0];
				}
				throw new Error('No single database.');
			})
		);
	}

	public current(): Observable<Model.Database> {
		const fetch = (uid: any) => {
			if (uid != null) {
				return super.query({ query: { $uid: uid, limit: 1 } });
			}
			return observableOf<Model.Database[]>([]);
		};
		return this._uriContext.contextChanges.pipe(
			Rx.publishBehavior(this._uriContext.context),
			Rx.refCount(),
			Rx.map(next => next[this._config.uriKey]),
			Rx.distinctUntilChanged(),
			Rx.switchMap(outer => fetch(outer).pipe(
				Rx.map(inner => inner.find(x => x.uid === outer)))
			)
		);
	}
}
