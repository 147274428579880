import {Injectable, OnDestroy} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';

@Injectable()
export abstract class RouterReload implements OnDestroy {
	private _navigationSubscription: Subscription;

	protected constructor(private readonly router: Router) {
		this._navigationSubscribe();
	}

	// eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
	public routerReload(redirects: boolean) {	}

	private _navigationSubscribe() {
		this._navigationUnsubscribe();
		this._navigationSubscription = this.router.events.subscribe((e: any) => {
			if (e instanceof NavigationEnd) {
				this.routerReload(e.url !== e.urlAfterRedirects);
			}
		});
	}

	private _navigationUnsubscribe() {
		this._navigationSubscription && this._navigationSubscription.unsubscribe();
	}

	public ngOnDestroy() {
		this._navigationUnsubscribe();
	}
}
