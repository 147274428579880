import {PrintConfiguration} from 'kn-print';
import {Utils} from 'kn-utils';
import {PrintBaseConfiguration} from './types';

export function expandPrintConfiguration(base: PrintBaseConfiguration, config: PrintConfiguration) {
	if (config == null) {
		return config;
	}
	let baseConfig = Utils.isFunction(base)
			? (base as () => PrintConfiguration)()
			: base as PrintConfiguration;
	if (config.iterate != null && baseConfig) {
		Utils.object.defaults(config.iterate, baseConfig.iterate);
	}
	else if (baseConfig && baseConfig.iterate != null) {
		baseConfig = Utils.clone(baseConfig);
		delete baseConfig.iterate;
	}
	Utils.object.defaults(config, baseConfig);
	return config;
}
