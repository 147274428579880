import {Injectable, Optional} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Params} from '@angular/router';
import {Observable} from 'rxjs';
import {Utils} from 'kn-utils';
import {JwtAuth} from './jwt-auth.service';

export type JwtAuthGuardNavigationAction = {
	commands: any[],
	extras?: {
		relativeTo?: ActivatedRoute,
		queryParams?: Params,
		fragment?: string
	}
};

export type ResolveAction = (auth: JwtAuth, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	Observable<boolean> | boolean;

export class JwtAuthGuardConfig {
	public deniedActions?: {
		resolve?: ResolveAction,
		navigateTo?: string | JwtAuthGuardNavigationAction;
	};
}

@Injectable()
export class JwtAuthGuard implements CanActivate {
	private readonly _options: JwtAuthGuardConfig;

	public constructor(
			private readonly _auth: JwtAuth,
			private readonly _router: Router,
			@Optional() options?: JwtAuthGuardConfig) {
		this._options = Utils.object.defaults({}, options);
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		return this._auth.isAuthenticated() || this._resolveDeniedActions(route, state);
	}

	private _resolveDeniedActions(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const deniedActions = this._options.deniedActions;
		if (deniedActions == null) {
			return false;
		}

		const navigateTo = deniedActions.navigateTo;
		if (navigateTo != null) {
			if (typeof navigateTo === 'string' || navigateTo instanceof String) {
				this._router.navigateByUrl(navigateTo as string);
			}
			else {
				this._router.navigate(navigateTo.commands, navigateTo.extras);
			}
		}

		const resolve = deniedActions.resolve;
		return resolve != null ? resolve(this._auth, route, state) : false;
	}
}
