import {OnDecorate, OnPropDecorate} from './interfaces';
import 'reflect-metadata';

export class ReflectorUtils {
	public static hasHook(hookName: string, instance: {}): boolean {
		if (hookName == null) {
			return false;
		}
		const ctor = instance.constructor;
		if (!(ctor instanceof Function)) {
			return false;
		}
		return hookName in ctor.prototype;
	}

	public static makeDecorator(annotationCls: any): any {
		function DecoratorFactory(...args: any[]): any {
			const annotationInstance = new annotationCls(...args);
			return function TypeDecorator(cls: any) {
				let annotations = Reflect.getOwnMetadata('annotations', cls);
				annotations = annotations || [];
				annotations.push(annotationInstance);
				Reflect.defineMetadata('annotations', annotations, cls);
				if (ReflectorUtils.hasHook('knOnDecorate', annotationInstance)) {
					(annotationInstance as OnDecorate).knOnDecorate(cls);
				}
				return cls;
			};
		}
		DecoratorFactory.prototype = Object.create(annotationCls.prototype);
		(DecoratorFactory as any).annotationCls = annotationCls;
		return DecoratorFactory;
	}

	public static makePropDecorator(annotationCls: any): any {
		function PropDecoratorFactory(...args: any[]): any {
			const decoratorInstance = new annotationCls(...args);
			return function PropDecorator(target: any, name: string) {
				let meta = Reflect.getOwnMetadata('propMetadata', target.constructor);
				meta = meta || {};
				meta[name] = meta[name] || [];
				meta[name].unshift(decoratorInstance);
				Reflect.defineMetadata('propMetadata', meta, target.constructor);
				if (ReflectorUtils.hasHook('knOnPropDecorate', decoratorInstance)) {
					(decoratorInstance as OnPropDecorate).knOnPropDecorate(target, name);
				}
			};
		}
		PropDecoratorFactory.prototype = Object.create(annotationCls.prototype);
		(PropDecoratorFactory as any).annotationCls = annotationCls;
		return PropDecoratorFactory;
	}

	public static propMetadata(typeOrFunc: any): { [key: string]: any[] } {
		if (typeOrFunc.propMetadata != null) {
			let meta = typeOrFunc.propMetadata;
			if (typeof meta === 'function' && meta.propMetadata) {
				meta = meta.propMetadata;
			}
			return meta;
		}
		else {
			const meta = Reflect.getMetadata('propMetadata', typeOrFunc);
			if (meta != null) {
				return meta;
			}
		}
		return {};
	}

	public static annotations(typeOrFunc: any): any[] {
		if (typeOrFunc.annotations != null) {
			let annotations = typeOrFunc.annotations;
			if (typeof annotations === 'function' && annotations.annotations) {
				annotations = annotations.annotations;
			}
			return annotations;
		}
		else {
			const annotations = Reflect.getMetadata('annotations', typeOrFunc);
			if (annotations != null) {
				return annotations;
			}
		}
		return [];
	}
}
