import {NgModule, Optional, SkipSelf, ModuleWithProviders} from '@angular/core';
import {CHANGE_ENTRIES_TABLE_TOKEN} from './change-entries-table.token';
import {ChangesSubscriptionGuard} from './changes-subscription.guard';
import {ChangeEntityCacheService} from './change-entity-cache.service';

export class RestChangesOptions {
	public changeEntriesTable: string;
}

export function provideChangeEntriesTable(config: RestChangesOptions) {
	return config.changeEntriesTable || 'ChangeEntries';
}

@NgModule()
export class KnRestChangesModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnRestChangesModule) {
		if (parentModule) {
			throw new Error('KnRestChangesModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config?: Partial<RestChangesOptions>): ModuleWithProviders<KnRestChangesModule> {
		return {
			ngModule: KnRestChangesModule,
			providers: [
				ChangesSubscriptionGuard,
				ChangeEntityCacheService,
				{
					provide: RestChangesOptions, useValue: config ? config : {}
				}, {
					provide: CHANGE_ENTRIES_TABLE_TOKEN,
					useFactory: provideChangeEntriesTable,
					deps: [RestChangesOptions]
				}
			]
		};
	}
}
