import {Component, Input, Output, EventEmitter} from '@angular/core';
import {DisplayedViewIndex} from './views/time-view.component';
import {DateTime} from '../types';

@Component({
	selector: 'kn-time',
	templateUrl: 'time.html'
})
export class KnTime {
	@Input() public time: DateTime;
	@Output() public timeChange = new EventEmitter<DateTime>();
	@Output() public accept = new EventEmitter<DateTime>();

	public timeViewIndex = DisplayedViewIndex.DayView;
	public hoursInPeriod: number = 24;

	public acceptTime() {
		this.timeChange.next(this.time);
	}
}
