<div class="tabs-labels">
	<ul>
		<li *ngFor="let label of labels; let i = index" [class]="label.classes" [class.active]="activeIndex === i">
			<a href="javascript:void(0)" (click)="selectIndex(i)">
				<ng-template [ngTemplateOutlet]="label.template"></ng-template>
			</a>
		</li>
	</ul>
	<ng-content select="[kn-side]"></ng-content>
</div>
<div class="tabs-viewport">
	<ng-content></ng-content>
</div>
