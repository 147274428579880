export class CssLengthParser {
	public static parse(lengthValue: string): number {
		const parts = lengthValue.match(/^((?:\+|-)?[0-9]*(?:\.[0-9]+)?(?:e(?:\+|-)?\d+)?)([a-z]*)$/);
		if (parts == null) {
			throw new Error('Unsupported length value format.');
		}
		const value = parseFloat(parts[1]);
		switch (parts[2]) {
			case 'px':
				return value;
			case 'mm':
				return value * 96 / 25.4;
			case 'q':
				return value * 96 / (25.4 * 40);
			case 'cm':
				return value * 96 / 2.54;
			case 'in':
				return value * 96;
			case 'pt':
				return value * 96 / 72;
			case 'pc':
				return value * 12 * (96 / 72);
			case '':
				if (value !== 0) {
					throw new Error('Only 0 may not have the unit.');
				}
				return 0;
		}
		throw new Error('Unsupported length value unit.');
	}
}
