import {Inject, LOCALE_ID, Component, Input, Output, EventEmitter, HostListener} from '@angular/core';
import {DatePipe} from 'kn-common';
import {DateTime} from '../../types';

@Component({
	selector: 'kn-date-header',
	templateUrl: 'date-header.html'
})
export class KnDateHeader {
	private readonly _datePipe: DatePipe;

	@Input() public date: DateTime;
	@Output() public selectPrevMonthView = new EventEmitter<Event>();
	@Output() public selectNextMonthView = new EventEmitter<Event>();

	public constructor(@Inject(LOCALE_ID) locale: string) {
		this._datePipe = new DatePipe(locale);
	}

	@HostListener('wheel', ['$event'])
	public wheelHandler(event: WheelEvent) {
		if (event.deltaY > 0) {
			this.selectNextMonthView.next(event);
		}
		else if (event.deltaY < 0) {
			this.selectPrevMonthView.next(event);
		}
		event.preventDefault();
	}

	public getMonth() {
		if (this.date == null) {
			return '--';
		}
		return this._datePipe.transform(new Date(this.date.year, this.date.month), 'MMMy');
	}
}
