import {Injectable, Inject, LOCALE_ID} from '@angular/core';
import {Utils} from 'kn-utils';
import {DatePipe} from 'kn-common';
import {Renderer, PipeRendererFactory, CellValue, CellContext} from 'kn-datagrid';

@Injectable()
export class DateRendererFactory extends PipeRendererFactory {
	private static readonly _csvReplace = /([^0-9])(\s)/g;

	public constructor(
			@Inject(LOCALE_ID) private readonly _locale: string
		) {
		super();
		this.register('print', this._printRenderer.bind(this));
		this.register('export:csv', this._printFileRenderer.bind(this));
	}

	public create(pattern?: string): Renderer<any> {
		// FIXME: Inject pipes
		return super.create([new DatePipe(this._locale), pattern]);
	}

	private _printRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		return this._applyPipes(value, ...args);
	}

	private _printFileRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		const result = this._applyPipes(value, ...args);
		return result ? (result as string).replace(DateRendererFactory._csvReplace, '$1') : result;
	}

	protected _defaultRenderer(value: CellValue) {
		return Utils.isString(value) ? Utils.date.fromIso8601(value) : value;
	}
}
