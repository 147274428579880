import {Injectable, Inject, LOCALE_ID} from '@angular/core';
import {DecimalPipe} from 'kn-common';
import {Renderer, PipeRendererFactory, CellValue, CellContext} from 'kn-datagrid';

@Injectable()
export class DecimalRendererFactory extends PipeRendererFactory {
	public constructor(@Inject(LOCALE_ID) private readonly _locale: string) {
		super();
		this.register('print', this._printRenderer.bind(this));
		this.register('export:csv', this._printRenderer.bind(this));
	}

	public create(digits?: string): Renderer<any> {
		// FIXME: Inject pipes
		return super.create([new DecimalPipe(this._locale), digits]);
	}

	private _printRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		return this._applyPipes(value, ...args);
	}
}
