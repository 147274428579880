import {Component, Input, Output, EventEmitter} from '@angular/core';
import {KnBackgroundFocusable} from 'kn-directives';
import {DateTime} from '../../types';

export enum DisplayedViewIndex {
	DayView,
	HourView
}

@Component({
	selector: 'kn-time-view',
	templateUrl: 'time-view.html'
})
export class KnTimeView {
	@Input() public hoursInPeriod: number = 24;
	@Input() public viewIndex = DisplayedViewIndex.DayView;
	@Output() public viewIndexChange = new EventEmitter<DisplayedViewIndex>();
	@Input() public time: DateTime;
	@Output() public timeChange = new EventEmitter<DateTime>();
	@Output() public accept = new EventEmitter<DateTime>();

	public constructor(private readonly _backgroundFocusable: KnBackgroundFocusable) { }

	public setHour(hour: number) {
		this.time = this.time || {};
		this.time.hour = hour;
		this.timeChange.emit(this.time);
	}

	public acceptHour(hour: number) {
		this._backgroundFocusable.focus();
		this.setHour(hour);
		this.viewIndex = DisplayedViewIndex.HourView;
		this.viewIndexChange.next(this.viewIndex);
	}

	public setMinute(minute: number) {
		this.time = this.time || {};
		this.time.minute = minute;
		this.timeChange.emit(this.time);
	}

	public acceptMinute(minute: number) {
		this.setMinute(minute);
		this.accept.emit(this.time);
	}
}
