import {Directive, OnDestroy, ViewContainerRef, TemplateRef, Renderer2, ElementRef} from '@angular/core';

enum CollapseState {
	Enter,
	EnterActive,
	Show,
	Leave,
	LeaveActive,
	Hide
}

@Directive({
	selector: '[knCollapse]',
	inputs: ['knCollapse']
})
export class KnCollapse implements OnDestroy {
	private _condition: boolean = null;
	private _visible: boolean = null;
	private _eventDisposable: Function = undefined;
	private static _transitionEndEvent: string = undefined;
	private static readonly _cssTriggerDelay: number = 50;
	private static readonly _failsafeTimeoutFactor: number = 1.2;
	private static readonly _enterClass: string = 'kn-enter';
	private static readonly _leaveClass: string = 'kn-leave';
	private static readonly _enterActiveClass: string = 'kn-enter-active';
	private static readonly _leaveActiveClass: string = 'kn-leave-active';

	public constructor(
			private readonly _viewContainer: ViewContainerRef,
			private readonly _templateRef: TemplateRef<{}>,
			private readonly _renderer: Renderer2) {
		if (KnCollapse._transitionEndEvent === undefined) {
			const el = this._renderer.createElement('div');
			KnCollapse._transitionEndEvent = KnCollapse._detectTransitionEndEvent(el);
		}
	}

	public ngOnDestroy(): void {
		this._eventDisposable && this._eventDisposable();
	}

	public set knCollapse(condition: any) {
		this._condition = condition;
		this._apply();
	}

	private get _underlyingElement(): ElementRef {
		const el = this._viewContainer.element.nativeElement.nextSibling as HTMLElement;
		return new ElementRef(el && el.nodeType === Node.ELEMENT_NODE ? el : null);
	}

	private _setStateClasses(state: CollapseState): void {
		if (this._underlyingElement.nativeElement) {
			switch (state) {
				case CollapseState.Enter:
					this._renderer.addClass(this._underlyingElement.nativeElement,
						KnCollapse._enterClass);
					break;
				case CollapseState.EnterActive:
					this._renderer.addClass(this._underlyingElement.nativeElement,
						KnCollapse._enterActiveClass);
					break;
				case CollapseState.Leave:
					this._renderer.addClass(this._underlyingElement.nativeElement,
						KnCollapse._leaveClass);
					break;
				case CollapseState.LeaveActive:
					this._renderer.addClass(this._underlyingElement.nativeElement,
						KnCollapse._leaveActiveClass);
					break;
				default:
					this._renderer.removeClass(this._underlyingElement.nativeElement,
						KnCollapse._enterClass);
					this._renderer.removeClass(this._underlyingElement.nativeElement,
						KnCollapse._enterActiveClass);
					this._renderer.removeClass(this._underlyingElement.nativeElement,
						KnCollapse._leaveClass);
					this._renderer.removeClass(this._underlyingElement.nativeElement,
						KnCollapse._leaveActiveClass);
					break;
			}
		}
	}

	private _calcTransitionDuration(): number {
		let transitionDuration = 0;
		if (KnCollapse._transitionEndEvent && this._underlyingElement.nativeElement) {
			const durations = window
				.getComputedStyle(this._underlyingElement.nativeElement)
				.transitionDuration;
			for (const duration of (durations || '').split(',')) {
				const parts = duration.match(/^\s*((?:-|\+)?\d+(?:\.\d+)?)(s|ms)\s*$/);
				if (parts) {
					let currentTime = +parts[1];
					if (parts[2] === 's') {
						currentTime *= 1000;
					}
					if (transitionDuration < currentTime) {
						transitionDuration = currentTime;
					}
				}
			}
		}
		return transitionDuration;
	}

	private _registerTransitionEndEvent(callback: Function): Function {
		let failsafeTimeout: any;
		const failsafeDisposable = () => {
			if (failsafeTimeout != null) {
				clearTimeout(failsafeTimeout);
				failsafeTimeout = undefined;
			}
		};
		const callbackWrapper = () => {
			failsafeDisposable();
			callback();
		};
		const transitionDuration = this._calcTransitionDuration();
		failsafeTimeout = setTimeout(callbackWrapper,
			transitionDuration * KnCollapse._failsafeTimeoutFactor);
		if (transitionDuration > 0) {
			const eventDisposable = this._renderer.listen(this._underlyingElement.nativeElement,
				KnCollapse._transitionEndEvent, callbackWrapper);
			return () => {
				failsafeDisposable();
				eventDisposable();
			};
		}
		return failsafeDisposable;
	}

	private _apply(): void {
		if (!this._eventDisposable && this._condition !== this._visible) {
			this._condition ? this._show() : this._hide();
		}
	}

	private _show(): void {
		this._viewContainer.createEmbeddedView(this._templateRef);
		this._setStateClasses(CollapseState.Enter);
		setTimeout(() => {
			this._setStateClasses(CollapseState.EnterActive);
			this._eventDisposable = this._registerTransitionEndEvent(() => {
				this._setStateClasses(CollapseState.Show);
				this._visible = true;
				if (this._eventDisposable) {
					this._eventDisposable();
				}
				this._eventDisposable = undefined;
				this._apply();
			});
		}, KnCollapse._cssTriggerDelay);
	}

	private _hide(): void {
		this._setStateClasses(CollapseState.Leave);
		setTimeout(() => {
			this._setStateClasses(CollapseState.LeaveActive);
			this._eventDisposable = this._registerTransitionEndEvent(() => {
				this._viewContainer.clear();
				this._visible = false;
				if (this._eventDisposable) {
					this._eventDisposable();
				}
				this._eventDisposable = undefined;
				this._apply();
			});
		}, KnCollapse._cssTriggerDelay);
	}

	private static _detectTransitionEndEvent(el: HTMLElement): string {
		const transitions: { [name: string]: string } = {
			'transition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'MozTransition': 'transitionend',
			'WebkitTransition': 'webkitTransitionEnd'
		};
		for (const key in transitions) {
			if (transitions.hasOwnProperty(key)) {
				if ((el.style as any)[key] !== undefined) {
					return transitions[key];
				}
			}
		}
		return null;
	}
}
