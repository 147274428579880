import {ChangeDetectorRef, Component, ViewEncapsulation, ChangeDetectionStrategy, HostBinding, OnInit, Input, ContentChild, ViewChild, ElementRef, EventEmitter, Output, TemplateRef, Provider, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {BooleanField} from 'kn-common';
import {ContainedFocus} from '../../contained-focus';
import {OptionsContext, AbstractOptionsHost} from '../options/abstract-options-host';
import {Utils} from 'kn-utils';

export const KN_SELECT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnSelect), // eslint-disable-line no-use-before-define,@typescript-eslint/no-use-before-define
	multi: true
};

export const KN_SELECT_OPTIONS_HOST: Provider = {
	provide: AbstractOptionsHost,
	useExisting: forwardRef(() => KnSelect) // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-select',
	templateUrl: 'select.html',
	styleUrls: ['select.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(click)' : '!focused && focus()'
	},
	providers: [
		KN_SELECT_CONTROL_VALUE_ACCESSOR,
		KN_SELECT_OPTIONS_HOST
	]
})
export class KnSelect extends AbstractOptionsHost implements OnInit, ControlValueAccessor {
	private readonly _containedFocus: ContainedFocus;
	private _value: any = null;

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@HostBinding('class.focused')
	public get focused() {
		return this._containedFocus.focused;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-select-${nextUniqueId++}`;

	@Input() @BooleanField() public hasFilter: boolean = false;
	@Input() @BooleanField() public autofocus: boolean = false;
	@Input() @BooleanField() public multiple: boolean = false;
	@Input() @BooleanField() public hideEmptyLabel: boolean = false;
	@Input() public placeholder: string = null;
	@Input() @BooleanField() public required: boolean = false;
	@Input() public tabindex: number = null;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@HostBinding('class.loading')
	@Input() public loading: boolean = false;

	public get value(): any {
		return this._value;
	}

	@Input() public set value(v: any) {
		if (v !== this._value) {
			this._value = v;
			this._onChangeCallback(v);
			this._cdr.markForCheck();
		}
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<any>();
	@Output() public filterChange = new EventEmitter<string>();
	@Output() public keyup = new EventEmitter<KeyboardEvent>();
	@Output() public keydown = new EventEmitter<KeyboardEvent>();

	@ViewChild('headerElement', { static: true })
	private readonly _headerElement: ElementRef;

	@ContentChild('header', { static: false })
	public template: TemplateRef<OptionsContext>;

	@ViewChild('knLabelContent', { static: true })
	private readonly _knLabel: ElementRef;

	public hasKnLabel: boolean = true;

	public constructor(cdr: ChangeDetectorRef) {
		super(cdr);
		this._containedFocus = new ContainedFocus(
			event => {
				this.focusEvent.emit(event);
				this.open();
			},
			event => {
				this._onTouchedCallback();
				this.blurEvent.emit(event);
				this.close();
				cdr.markForCheck();
			}
		);
	}

	public ngOnInit() {
		this._containedFocus.register(this._headerElement);
		if (this.autofocus) {
			this.focus();
		}
	}

	public ngOnDestroy() {
		super.ngOnDestroy();
		this._containedFocus.unregisterAll();
	}

	public ngAfterViewInit() {
		super.ngAfterViewInit();
		this.hasKnLabel = !this.hideEmptyLabel || this._knLabel && this._knLabel.nativeElement && this._knLabel.nativeElement.children.length > (this.required ? 1 : 0);
		this._cdr.detectChanges();
	}

	protected get _multiple() {
		return this.multiple;
	}

	public registerChild(element: ElementRef) {
		this._containedFocus.register(element, true);
		return () => this._containedFocus.unregister(element);
	}

	public getTabindex() {
		return this.disabled ? -1 : (this.tabindex || 0);
	}

	public focus() {
		!this.disabled && this._headerElement.nativeElement.focus();
	}

	protected _selectOptionInternal(value: any) {
		if (this.multiple) {
			if (!Array.isArray(this.value)) {
				this.value = [value];
			}
			else {
				const index = this.value.indexOf(value);
				if (index === -1) {
					this.value = Utils.clone(this.value.concat(value));
				}
				else {
					const values = this.value;
					values.splice(index, 1);
					this.value = Utils.clone(values);
				}
			}
		}
		else {
			this.value = value;
		}
		this._onTouchedCallback();
		this.changeEvent.emit();
		this.valueChange.emit(this.value);
	}

	public get displayPlaceholder(): boolean {
		return this.placeholder && (this.value == null || !('' + this.value).length);
	}

	/** @internal */
	public handleFilterInput(value: string) {
		this.filterChange.emit(value);
	}

	/** @internal */
	public clearFilterInput() {
		this.filterChange.emit('');
	}

	/** @internal */
	public handleClick(event: FocusEvent) {
		this.open();
	}

	/** @internal */
	public handleKeyup(event: KeyboardEvent) {
		this.keyup.emit(event);
	}

	/** @internal */
	public handleKeydown(event: KeyboardEvent) {
		this._processKey(event);
		this.keydown.emit(event);
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._value = value;
		this.changeEvent.emit();
		this._update();
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
