<ng-container *ngFor="let action of actions">
	<ng-container *knCan="action.permission the 'item'">
		<a href="javascript:void(0)"
				*ngIf="evaluate(action.visible)"
				[title]="action.title"
				(click)="command.emit(evaluate(action.command))">
			<i class="material-icons">{{ action.icon }}</i>
		</a>
	</ng-container>
</ng-container>
<ng-content></ng-content>
