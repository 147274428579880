import {Observable, Subject} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {NotSupportedError} from 'kn-shared';
import {Http, Response, Uri, UriTemplate, UriContext} from 'kn-http';
import {AbstractConcreteFetcher, Indexer, resolveWebsocketScheme} from 'kn-rest';

export interface EntityChangeEntry {
	action: string /* Type */;
	table: string;
	id: number;
	syncTime: string /*DateTime*/;
}

export class EntitiesChangesFetcher extends AbstractConcreteFetcher<EntityChangeEntry> {
	protected _uri: Uri;

	public constructor(
			protected _http: Http,
			uriTemplate: string,
			table: string,
			protected _websocketScheme?: string | { (uri: Uri): string }) {
		super(table);
		this._uri = new Uri(new UriTemplate(uriTemplate));
	}

	protected _get<U extends EntityChangeEntry | EntityChangeEntry[]>(indexer: Indexer, context: UriContext): Observable<U> {
		if (indexer != null) {
			throw new NotSupportedError('Non enumerable GET method is not supported');
		}

		const uri = this._uri.build(context || {});
		return this._http.get(uri).pipe(Rx.map(x => x.body as U));
	}

	protected _head(indexer: Indexer, context: UriContext): Observable<Response> {
		if (indexer != null) {
			throw new NotSupportedError('Non enumerable GET method is not supported');
		}

		const uri = this._uri.build(context || {});
		return this._http.head(uri);
	}

	protected _postOrPut(item: EntityChangeEntry, context: UriContext): Observable<Response> {
		throw new NotSupportedError('POST and PUT methods are not supported');
	}

	protected _delete(indexer: Indexer, context: UriContext): Observable<Response> {
		throw new NotSupportedError('DELETE method is not supported');
	}

	public websocket(context: UriContext): Subject<EntityChangeEntry[]> {
		const uri = this._uri.build(context || {}, {
			scheme: resolveWebsocketScheme(this._websocketScheme, this._uri)
		});
		return this._http.websocket(uri);
	}
}
