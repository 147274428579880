import {Subject, Observable} from 'rxjs';
import {OnDecorate} from 'kn-utils';
import {PropertyAccessor} from '../property-accessor';
import {normalizeKey} from '../utils';
import {KeySpecifier} from '../types';

export class UserSettingLinkPropertyMetadata implements OnDecorate, PropertyAccessor<any> {
	private readonly _valueChangeSubject$ = new Subject<any>();
	private readonly _valueChange$ = this._valueChangeSubject$.asObservable();

	public key: string;
	public version: string;
	public value: any;

	public get valueChange(): Observable<any> {
		return this._valueChange$;
	}

	public constructor(
			key: KeySpecifier,
			public propertyName?: string,
			public discriminatorArg?: any
			) {
		Object.assign(this, normalizeKey(key));
	}

	public knOnDecorate(cls: any) {
		Object.defineProperty(cls.prototype, this.propertyName || this.key, {
			get: () => this.value,
			set: value => this._valueChangeSubject$.next(value),
			enumerable: true,
			configurable: true
		});
	}
}
