import {AbstractChartFactory} from './abstract-chart';
import {PieChart, BaseChart, BaseChartEventsTypes, PieChartData, PieChartOptions, ResponsiveOptions } from 'chartist';

export class PieChartFactory extends AbstractChartFactory {
	public get name(): string {
		return 'pie';
	}

	public create(target: string | Element | null, data: PieChartData, options?: PieChartOptions, responsiveOptions?: ResponsiveOptions<PieChartOptions>): BaseChart<BaseChartEventsTypes> {
		return new PieChart(target, data, options, responsiveOptions);
	}
}
