import {Injectable, Inject} from '@angular/core';
import {NotSupportedError} from 'kn-shared';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';
import {AbstractMigrationStrategy} from './migration-strategies/abstract-migration-strategy';
import {AbstractTemplateEncoder} from './template-encoders/abstract-template-encoder';
import {ViewTemplateProjection} from './view-template-projection';
import {VIEW_TEMPLATE_MANAGER_MIGRATION_STRATEGIES_TOKEN} from './view_template_manager_migration_strategies.token';
import {VIEW_TEMPLATE_MANAGER_TEMPLATE_ENCODERS_TOKEN} from './view_template_manager_template_encoders.token';

@Injectable()
export class ViewTemplateManagerService {
	private readonly _migrationStrategies: AbstractMigrationStrategy<any>[] = [];
	private readonly _templateEncoders: AbstractTemplateEncoder<any>[] = [];

	public constructor(
			private readonly _viewTemplatesResource: ViewTemplatesResourceService,
			@Inject(VIEW_TEMPLATE_MANAGER_MIGRATION_STRATEGIES_TOKEN) migrationStrategies: AbstractMigrationStrategy<any>[],
			@Inject(VIEW_TEMPLATE_MANAGER_TEMPLATE_ENCODERS_TOKEN) templateEncoders: AbstractTemplateEncoder<any>[]) {
		this._migrationStrategies = migrationStrategies.slice().reverse();
		this._templateEncoders = templateEncoders.slice().reverse();
	}

	public project<T>(view: string, version?: string) {
		const resource = this._viewTemplatesResource;
		const encoder = this._getTemplateEncoder(view);
		const migration = this._getMigrationStrategy(view, version);
		return new ViewTemplateProjection<T>(resource, encoder, migration, view, version);
	}

	private _getTemplateEncoder(view: string) {
		for (const strategy of this._templateEncoders) {
			if (strategy.support(view)) {
				return strategy;
			}
		}
		throw new NotSupportedError('Compatible template encoder not found.');
	}

	private _getMigrationStrategy(view: string, targetVersion: string) {
		for (const strategy of this._migrationStrategies) {
			if (strategy.support(view, targetVersion)) {
				return strategy;
			}
		}
		throw new NotSupportedError('Compatible migration strategy not found.');
	}
}
