import {Headers} from './headers';

export type RequestMethod = 'get' | 'post' | 'put' | 'delete' | 'options' | 'head' | 'patch';

export type ResponseContentType = XMLHttpRequestResponseType;
export type WebsocketResponseContentType = '' | 'text' | 'json' | 'blob' | 'arraybuffer';

export interface RequestOptions {
	headers: Headers;
	responseType: string | ResponseContentType;
	withCredentials: boolean;
	reportProgress: boolean;
}

export interface ResponseOptions {
	headers: Headers;
	status: number;
	statusText: string;
}

export enum ResponseState {
	Initiated,
	Upload,
	Download,
	Finished,
	Error
}

export interface ProgressEvent {
	state: ResponseState;
	loaded: number;
	total?: number;
}

export interface WebsocketConfigOptions {
	protocol: string | string[];
	responseType: string | WebsocketResponseContentType;
}
