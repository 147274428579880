import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnFormsModule} from 'kn-forms';
import {KnViewTemplateSelector} from './view-template-selector.component';

@NgModule({
	imports: [CommonModule, FormsModule, KnFormsModule],
	declarations: [KnViewTemplateSelector],
	exports: [KnViewTemplateSelector]
})
export class KnViewTemplateSelectorModule { }
