import {NgZone, ElementRef} from '@angular/core';
import {RippleRenderer} from './ripple-renderer';
import {RippleRef} from './ripple-ref';
import {RippleState, RippleConfig} from './ripple.types';

export class Ripple extends RippleRef {
	private _onVisibleCallback: () => void = () => { /* intentionally empty */ };
	private _onHiddenCallback: () => void = () => { /* intentionally empty */ };
	private _timeoutId: any;

	public constructor(
			private readonly _zone: NgZone,
			private readonly _renderer: RippleRenderer,
			public config: RippleConfig) {
		super();
	}

	public fadeIn(x: number, y: number) {
		if (this._state === RippleState.FADING_IN || this._state === RippleState.VISIBLE) {
			return;
		}
		this._elementRef && this._destroyRipple();
		this._elementRef = new ElementRef(this._renderer.fadeIn(x, y, this.config));
		this._state = RippleState.FADING_IN;
		this._runTimeoutOutsideZone(() => {
			this._state = RippleState.VISIBLE;
			this._onVisibleCallback();
		}, this.config.animation.enterDuration);
	}

	public fadeOut() {
		if (this._state === RippleState.FADING_OUT || this._state === RippleState.HIDDEN) {
			return;
		}
		this._renderer.fadeOut(this._elementRef.nativeElement, this.config);
		this._state = RippleState.FADING_OUT;
		this._runTimeoutOutsideZone(() => {
			this._state = RippleState.HIDDEN;
			this._onHiddenCallback();
			this._destroyRipple();
			this._elementRef = null;
		}, this.config.animation.exitDuration);
	}

	public registerOnVisible(fn: () => void) {
		this._onVisibleCallback = fn;
	}

	public registerOnHidden(fn: () => void) {
		this._onHiddenCallback = fn;
	}

	private _destroyRipple() {
		const parent = this._elementRef.nativeElement.parentNode;
		parent && parent.removeChild(this._elementRef.nativeElement);
	}

	private _runTimeoutOutsideZone(fn: () => void, delay: number) {
		clearTimeout(this._timeoutId);
		this._zone.runOutsideAngular(() =>
			this._timeoutId = setTimeout(() => { this._timeoutId = null; fn(); }, delay)
		);
	}
}
