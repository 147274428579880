import {ElementRef, SimpleChanges, SimpleChange, Renderer2} from '@angular/core';
import {CellContext, RowItem, CellValue} from '../../types';
import {RenderBinderRef} from './render-binder-ref';

export type ElementRenderer<T extends RowItem> = {
	(elementRef: ElementRef, value: CellValue, context: CellContext<T>, firstChange: boolean): string
};

export type ElementChangeIndicator<T extends RowItem> = {
	(prevValue: { [key: string]: any }, value: CellValue, context: CellContext<T>): boolean
};

export class ElementRenderBinderRef<T extends RowItem> extends RenderBinderRef<T> {
	private readonly _prevValue: { [key: string]: any } = {};

	public constructor(
			private readonly _renderer: Renderer2,
			private readonly _element: ElementRef,
			private readonly _propertyName: string,
			private readonly _elmRenderer?: ElementRenderer<T>,
			private readonly _changeIndicator?: ElementChangeIndicator<T>) {
		super();
		if (this._elmRenderer == null) {
			this._elmRenderer = this._defaultElmRenderer;
		}
		if (this._changeIndicator == null) {
			this._changeIndicator = this._defaultChangeIndicator;
		}
	}

	public get element(): ElementRef {
		return this._element;
	}

	public get elmRenderer(): ElementRenderer<T> {
		return this._elmRenderer;
	}

	public get changeIndicator(): ElementChangeIndicator<T> {
		return this._changeIndicator;
	}

	public get propertyName(): string {
		return this._propertyName;
	}

	public updateBindings(value: CellValue, context: CellContext<T>, firstChange?: boolean) {
		if (!this.changeIndicator(this._prevValue, value, context)) {
			return {} as SimpleChanges;
		}
		const renderValue = this.elmRenderer(this.element, value, context, firstChange || false);
		this._renderer.setProperty(this.element.nativeElement, this.propertyName, renderValue);
		return {
			'value': new SimpleChange(this._prevValue, value, firstChange)
		};
	}

	protected _defaultElmRenderer(elementRef: ElementRef, value: CellValue, context: CellContext<T>, firstChange: boolean): string {
		return value as string;
	}

	protected _defaultChangeIndicator(prevValue: { [key: string]: any }, value: CellValue, context: CellContext<T>): boolean {
		if (prevValue.value === value) {
			return false;
		}
		prevValue.value = value;
		return true;
	}
}
