import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {I18nService, NotSupportedError} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {UserService} from 'kn-user';
import {Utils} from 'kn-utils';

@Injectable()
export class RouteAccessGuard implements CanActivate {
	public constructor(
			private readonly _i18n: I18nService,
			private readonly _toast: ToastService,
			private readonly _user: UserService) {
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (route.data.authorize == null) {
			return true;
		}

		const authorize = {
			action: 'enter',
			resource: null as string | string[]
		};

		if (Utils.isString(route.data.authorize) || Array.isArray(route.data.authorize)) {
			authorize.resource = route.data.authorize;
		}
		else if (Utils.isObject(route.data.authorize)) {
			Object.assign(authorize, route.data.authorize);
		}
		else {
			throw new NotSupportedError('Unsupported route.data.authorize type.');
		}

		if (this._user.can(authorize.action, authorize.resource)) {
			return true;
		}

		this._toast.show(
			this._i18n.t('Access denied'),
			this._i18n.t('Sorry, you don\'t have appropriate permissions to enter. Please contact your administrator.'));

		return false;
	}
}
