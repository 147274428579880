import {forwardRef, Component, ViewEncapsulation, HostBinding, Input, ViewChild, ElementRef, EventEmitter, Provider, Output, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {BooleanField} from 'kn-common';
import {ContainedFocus} from '../../contained-focus';
import {AbstractOptionsHost} from '../options/abstract-options-host';

export const KN_INPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnInput), // eslint-disable-line no-use-before-define,@typescript-eslint/no-use-before-define
	multi: true
};

export const KN_INPUT_OPTIONS_HOST: Provider = {
	provide: AbstractOptionsHost,
	useExisting: forwardRef(() => KnInput) // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-input',
	templateUrl: 'input.html',
	styleUrls: ['input.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(click)' : '!focused && focus()'
	},
	providers: [
		KN_INPUT_CONTROL_VALUE_ACCESSOR,
		KN_INPUT_OPTIONS_HOST
	]
})
export class KnInput extends AbstractOptionsHost implements OnInit, OnDestroy, ControlValueAccessor {
	private readonly _containedFocus: ContainedFocus;
	private _value: any = '';
	private _valueInternal: string = '';

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@HostBinding('class.focused')
	public get focused() {
		return this._containedFocus.focused;
	}

	public get inputId(): string {
		return `${this.id}-input`;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-input-${nextUniqueId++}`;

	@Input() public autocomplete: string = 'off';
	@Input() @BooleanField() public autofocus: boolean = false;
	@Input() public list: string = null;
	@Input() public max: string | number = null;
	@Input() public maxlength: number = null;
	@Input() public min: string | number = null;
	@Input() public minlength: number = null;
	@Input() public placeholder: string = null;
	@Input() @BooleanField() public required: boolean = false;
	@Input() @BooleanField() public spellcheck: boolean = false;
	@Input() @BooleanField() public hideEmptyLabel: boolean = false;
	@Input() public step: number = null;
	@Input() public tabindex: number = null;
	@Input() public type: string = 'text';
	@Input() public name: string = null;

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@HostBinding('class.loading')
	@Input() public loading: boolean = false;

	public get value(): any {
		return this._value;
	}

	@Input() public set value(v: any) {
		this._setValue(v, true);
	}

	/** @internal */
	public get valueInternal(): string {
		return this._valueInternal;
	}

	/** @internal */
	public set valueInternal(v: string) {
		if (v === '') {
			if (this.type === 'number') {
				this.inputIsInvalid = true;
			}
			return;
		}
		this.inputIsInvalid = false;
		this._setValueInternal(v);
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<string>();
	@Output() public keyup = new EventEmitter<KeyboardEvent>();
	@Output() public keydown = new EventEmitter<KeyboardEvent>();

	@ViewChild('input', { static: true })
	public _inputElement: ElementRef;

	@ViewChild('knLabelContent', { static: true })
	private readonly _knLabel: ElementRef;

	public hasKnLabel: boolean = true;
	public inputIsInvalid: boolean = false;

	public constructor(cdr: ChangeDetectorRef) {
		super(cdr);
		this._containedFocus = new ContainedFocus(
			event => {
				this.focusEvent.emit(event);
				this.open();
			},
			event => {
				this._onTouchedCallback();
				this.blurEvent.emit(event);
				this.close();

				if (this.inputIsInvalid) {
					/* Always propagate the change */
					this._value = undefined;
					this._setValue(null, true);
				}
				this._cdr.markForCheck();
			}
		);
	}

	public ngOnInit() {
		this._containedFocus.register(this._inputElement);
	}

	public ngOnDestroy() {
		super.ngOnDestroy();
		this._containedFocus.unregisterAll();
	}

	public ngAfterViewInit() {
		super.ngAfterViewInit();
		this.hasKnLabel = !this.hideEmptyLabel || this._knLabel && this._knLabel.nativeElement && this._knLabel.nativeElement.children.length > (this.required ? 1 : 0);
		this._cdr.detectChanges();
	}

	public get multiple() {
		return false;
	}

	public registerChild(element: ElementRef) {
		this._containedFocus.register(element, true);
		return () => this._containedFocus.unregister(element);
	}

	public focus() {
		this._inputElement.nativeElement.focus();
	}

	public select() {
		this._inputElement.nativeElement.select();
	}

	protected _selectOptionInternal(value: any) {
		this._setValueInternal(value);
		this.valueChange.emit(this.value);
		this.changeEvent.emit();
		this._onTouchedCallback();
	}

	public open() {
		super.open();
		this.emitQuery(this.value);
	}

	/** @internal */
	public handleFilterInput(query: string) {
		this.emitQuery(query);
	}

	/** @internal */
	public handleChange(event: Event) {
		const element = event.target as HTMLInputElement;
		this._setValueInternal(element.value);
		this.valueChange.emit(this.value);
		this.changeEvent.emit();
		this._onTouchedCallback();
	}

	/** @internal */
	public handleKeyup(event: KeyboardEvent) {
		this.keyup.emit(event);
	}

	/** @internal */
	public handleKeydown(event: KeyboardEvent) {
		this._processKey(event);
		this.keydown.emit(event);
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._setValue(value, false);
		this.changeEvent.emit();
		this._update();
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		if (isDisabled) {
			this.close();
			this._inputElement.nativeElement.blur();
		}
		this._cdr.markForCheck();
	}

	private _setValue(v: any, change: boolean) {
		if (v !== this._value) {
			this._value = v;
			if (change) {
				this._onChangeCallback(v);
			}
			if (v != null) {
				v = v.toString();
			}
			this._valueInternal = v as string;
			this._cdr.markForCheck();
		}
	}

	private _setValueInternal(v: string) {
		if (v !== this._valueInternal) {
			this._valueInternal = v;

			let parsedValue: any;
			if (this.type === 'number') {
				/* Let the browser parse number input */
				parsedValue = this._inputElement.nativeElement.valueAsNumber;
			}
			else {
				parsedValue = v;
			}
			if (parsedValue !== this._value) {
				this._value = parsedValue;
				this._onChangeCallback(parsedValue);
			}
		}
	}
}
