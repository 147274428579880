import {Component, Input, OnInit, ComponentRef, SimpleChanges, ChangeDetectionStrategy} from '@angular/core';
import {Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {CellContext, CellValue} from 'kn-datagrid';
import {Utils} from 'kn-utils';
import {EntityBase} from 'common-web/model';
import {DefaultTarget} from '../lookup-component-renderer-factory';

export interface QueryMapping {
	cond: (value: EntityBase) => boolean;
	query?: string | string[] | { [key: string]: string | string[] };
	key?: string;
}

@Component({
	selector: 'kn-app-link',
	template: '<a [href]="href" (click)="navigate($event)">{{ value }}</a>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnLink implements OnInit, DefaultTarget {
	@Input() public value: EntityBase = null;
	@Input() public href: string = null;
	@Input() public commands: string[] = null;
	@Input() public queryParams: { [key: string]: string | string[] } = null;

	public constructor(
			private readonly _locationStrategy: LocationStrategy,
			private readonly _router: Router) {
	}

	public ngOnInit() {
		const tree = this._router.createUrlTree(this._buildCommands(), { queryParams: this.queryParams });
		if (tree != null) {
			this.href = this._locationStrategy.prepareExternalUrl(this._router.serializeUrl(tree));
		}
	}

	public navigate(event: Event) {
		this._router.navigate(this._buildCommands(), { queryParams: this.queryParams });
		event.stopPropagation();
		event.preventDefault();
	}

	protected _buildCommands(): string[] {
		return this.commands.map(x => x == null ? undefined : x);
	}

	public renderDefaultTarget(): any {
		return this.value;
	}

	public static mapping(path: string | string[], query?: string | string[] | QueryMapping[] | { [key: string]: string }, key?: string):
	(component: ComponentRef<KnLink>, value: CellValue, context: CellContext<EntityBase>) => SimpleChanges {
		if (Array.isArray(query)) {
			return this._mapping(path, query as QueryMapping[]);
		}
		return this._mapping(path, [{ cond: () => true, query, key }]);
	}

	protected static _mapping(path: string | string[], mapping: QueryMapping[]) {
		return (component: ComponentRef<KnLink>, value: CellValue, context: CellContext<EntityBase>): SimpleChanges => {
			const selectedMapping = mapping && mapping.find(x => x.cond(context.node.item));
			if (selectedMapping == null) {
				return { };
			}

			component.instance.value = value;

			component.instance.commands = [];
			path = Utils.array.box(path);
			for (let part of path) {
				if (part.startsWith('{') && part.endsWith('}')) {
					part = part.slice(1, -1);
					part = Utils.object.get(context.node.item, part);
				}
				component.instance.commands.push(part);
			}
			component.instance.queryParams = {};
			const query = selectedMapping.query || {};
			for (const prop in (query as any)) {
				if (!query.hasOwnProperty(prop)) {
					continue;
				}
				let val = (query as any)[prop];
				if (val.startsWith('{') && val.endsWith('}')) {
					val = val.slice(1, -1);
					val = Utils.object.get(context.node.item, val);
				}
				component.instance.queryParams[prop] = val;
			}
			return {};
		};
	}
}
