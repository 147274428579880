import {Injectable, Injector, PipeTransform, Type} from '@angular/core';
import {Utils} from 'kn-utils';
import {CellContext, CellValue} from '../types';
import {RendererFactory} from './renderer-factory';

@Injectable()
export class PipeRendererFactory extends RendererFactory {
	private readonly _injector: Injector;

	public constructor(injector?: Injector) {
		super();
		this._injector = injector;
		this.register(RendererFactory.mainTarget, this._mainRenderer.bind(this));
	}

	private _mainRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		return this._applyPipes(value, ...args);
	}

	protected _applyPipes(value: CellValue, ...args: any[]): any {
		for (const arg of args) {
			if (Array.isArray(arg)) {
				value = this._transform.apply(this, [value].concat(arg));
			}
			else {
				value = this._transform(value, arg);
			}
		}
		return value;
	}

	private _transform(value: CellValue, pipe: PipeTransform | Type<PipeTransform>, ...args: any[]) {
		let pipeInstance = pipe as PipeTransform;
		if (!Utils.isFunction(pipeInstance['transform'])) {
			pipeInstance = this._injector.get(pipe as Type<PipeTransform>);
		}
		return pipeInstance.transform(value, ...args);
	}
}
