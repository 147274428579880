import {Directive, Input, HostListener} from '@angular/core';
import {DatagridBond} from 'kn-datagrid';
import {Utils} from 'kn-utils';
import {GridExporterService} from '../../exporters/grid-exporter.service';

@Directive({
	selector: '[knXlsxExport]'
})
export class KnXlsxExportDirective {
	@Input() public knXlsxExport: DatagridBond<any>;
	@Input() public knXlsxExportView: string;

	public constructor(private _exporter: GridExporterService) { }

	@HostListener('click')
	public async export() {
		const options = {
			type: 'xlsx',
			saveAs: `${this.knXlsxExportView}-${Utils.date.toIso8601(new Date(), 'date')}.xlsx`
		};
		const result = this._exporter.export(
			this.knXlsxExport.description,
			this.knXlsxExport.model,
			this.knXlsxExport.dataSource,
			options
		);
		return result;
	}
}
