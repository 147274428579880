import {Observable} from 'rxjs';
import {Http} from './http';
import {ConcreteHttp} from './concrete-http';
import {Request} from './request';
import {Response} from './response';
import {WebsocketConfig} from './websocket-config';
import {WebsocketSubject} from './websocket-subject';

export abstract class HttpWrapper extends ConcreteHttp {
	public constructor(private readonly _http: Http) {
		super();
	}

	protected _executeRequest(request: Request): Observable<Response> {
		return this._http.request(request);
	}

	protected _establishWebsocket(config: WebsocketConfig): WebsocketSubject {
		return this._http.websocket(config);
	}
}
