import {Observable, of, throwError} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {Indexer, ChangeEntry} from 'kn-rest';
import {QueryContext, IndexerRetriver} from './types';
import * as Model from 'common-web/model';
import {EntityUtils} from '../../entity-utils';

export abstract class AbstractEntityStore<T extends Model.EntityBase> {
	protected readonly _indexerRetriver: IndexerRetriver<T>;

	public constructor(
			indexerRetriver: IndexerRetriver<T> | string,
			protected _context?: UriContext) {
		this._indexerRetriver = indexerRetriver as IndexerRetriver<T>;
		if (Utils.isString(this._indexerRetriver)) {
			this._indexerRetriver = item => item[indexerRetriver as string];
		}
	}

	public get indexerRetriver(): IndexerRetriver<T> {
		return this._indexerRetriver;
	}

	public abstract get changes(): Observable<ChangeEntry>;

	public get(indexer: Indexer): Observable<T | T[]> {
		return this._get(indexer, this._context);
	}

	public getByQuery(query: QueryContext<string>): Observable<T | T[]> {
		const queryKey = Object.keys(query)[0];
		const context = Object.assign({ [queryKey]: {} as any }, this._context);
		Object.assign(context[queryKey], query[queryKey]);
		context[queryKey].limit = 1;
		return this._query(context).pipe(
			Rx.map(next => {
				if (next == null || next.length !== 1) {
					throw Error('Cannot select singular item by UID.');
				}
				return next[0];
			})
		);
	}

	public save(item: T): Observable<Response> {
		return this._save(item);
	}

	public saveIfChanged(item: T): Observable<Response> {
		return this._get(this.indexerRetriver(item), this._context).pipe(
			Rx.catchError(err => err.status === 404 ? of({} as T) : throwError(err)),
			Rx.switchMap(x => {
				return EntityUtils.equal(item, x)
					? of({} as Response)
					: this.save(item);
			})
		);
	}

	public remove(indexer: Indexer): Observable<Response> {
		return this._remove(indexer);
	}

	protected abstract _get(indexer: Indexer, context: UriContext): Observable<T>;
	protected abstract _query(context: UriContext): Observable<T[]>;
	protected abstract _save(item: T): Observable<Response>;
	protected abstract _remove(indexer: Indexer): Observable<Response>;
}
