import {ClassProvider} from '@angular/core';
import {PRINT_STATEMENT_TOKEN} from '../print-statement.token';

import {CeilFilter} from './filters/ceil-filter';
import {CountFilter} from './filters/count-filter';
import {EmptyOnNullFilter} from './filters/empty-on-null-filter';
import {FirstFilter} from './filters/first-filter';
import {FloorFilter} from './filters/floor-filter';
import {JsonFilter} from './filters/json-filter';
import {LowerCaseFilter} from './filters/lower-case-filter';
import {PadFilter} from './filters/pad-filter';
import {ReplaceFilter} from './filters/replace-filter';
import {RoundFilter} from './filters/round-filter';
import {SortFilter} from './filters/sort-filter';
import {SubstrFilter} from './filters/substr-filter';
import {SumFilter} from './filters/sum-filter';
import {UpperCaseFilter} from './filters/upper-case-filter';

import {SwitchHelper} from './helpers/switch-helper';
import {GroupByHelper} from './helpers/group-by-helper';
import {MuxHelper} from './helpers/mux-helper';
import {SelectHelper} from './helpers/select-helper';
import {WithHelper} from './helpers/with-helper';
import {EvalHelper} from './helpers/eval-helper';
import {IncludeFileHelper} from './helpers/include-file-helper';
import {ExtendsFileHelper} from './helpers/extends-file-helper';

export const PRINT_STATEMENTS_PROVIDERS: ClassProvider[] = [
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: CeilFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: CountFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: EmptyOnNullFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: FirstFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: FloorFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: JsonFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: LowerCaseFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: PadFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: ReplaceFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: RoundFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: SortFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: SubstrFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: SumFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: UpperCaseFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: SwitchHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: GroupByHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: MuxHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: SelectHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: WithHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: EvalHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: IncludeFileHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: ExtendsFileHelper }
];
