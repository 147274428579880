import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {UsersConfig} from './users.config';
import * as Model from 'common-web/model';

@Injectable()
export class UsersResourceService extends Resource<Model.User> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: UsersConfig) {
		super(rest, notifier, config.usersTable);
	}
}
