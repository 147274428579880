import {AbstractControl, Validators} from '@angular/forms';
import {Validator} from '../types';

export class PatternValidator implements Validator {
	public validate(control: AbstractControl, pattern: RegExp) {
		if (typeof pattern === 'string') {
			// rationale: Angular magically appends missing anchors, flags also not possible with strings
			console.log('PatternValidator - string patterns are deprecated, use RegExp directly.');
		}
		return Validators.pattern(pattern)(control) == null;
	}
}
