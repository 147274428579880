import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDateInput} from './dateinput.component';
import {KnOptionsModule} from '../options/options.module';
import {KnCheckboxModule} from '../checkbox/checkbox.module';
import {KnDatepickerModule} from '../datepicker/datepicker.module';

const KN_DATEINPUT_DIRECTIVES = [
	KnDateInput
];

@NgModule({
	imports: [CommonModule, FormsModule, KnOptionsModule, KnCheckboxModule, KnDatepickerModule],
	declarations: [KN_DATEINPUT_DIRECTIVES],
	exports: [KN_DATEINPUT_DIRECTIVES]
})
export class KnDateInputModule { }
