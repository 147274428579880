import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Response} from 'kn-http';
import {ViewTemplatesResourceService} from '../../services/view-templates/view-templates-resource.service';
import {ViewTemplateItem, ViewTemplatePreambule} from './types';
import {AbstractTemplateEncoder} from './template-encoders/abstract-template-encoder';
import {AbstractMigrationStrategy} from './migration-strategies/abstract-migration-strategy';
import * as Model from 'common-web/model';

export class ViewTemplateProjection<T> {
	public constructor(
			private readonly _resource: ViewTemplatesResourceService,
			private readonly _encoder: AbstractTemplateEncoder<T>,
			private readonly _migration: AbstractMigrationStrategy<T>,
			private readonly _view: string,
			private readonly _version: string) {
	}

	public get view() {
		return this._view;
	}

	public get version() {
		return this._version;
	}

	public list(): Observable<ViewTemplatePreambule[]> {
		const query = { $view: this.view, only: ['id', 'name', 'hidden', 'userUid'], sort: ['userUid', 'name'] };
		return this._resource.query({ query })
			.pipe(Rx.map(next => next as ViewTemplatePreambule[]));
	}

	public load(id: number): Observable<ViewTemplateItem<T>> {
		return this._resource.get(id).pipe(
			Rx.map(next => {
				const preamble = {
					id: next.id,
					name: next.name,
					hidden: next.hidden,
					userUid: next.userUid
				};
				const projection = {
					version: next.version,
					value: this._encoder.decode(this.view, next.value)
				};
				return Object.assign(
					preamble,
					this._migration.migrate(this.view, this.version, projection));
			})
		);
	}

	public save(template: ViewTemplateItem<T>): Observable<Response> {
		const item = {
			id: template.id,
			view: this._view,
			name: template.name,
			userUid: template.userUid,
			hidden: template.hidden,
			version: template.version || this.version,
			value: this._encoder.encode(this.view, template.value)
		} as Model.ViewTemplate;
		return this._resource.save(item)
			.pipe(Rx.tap(() => template.id = item.id));
	}

	public remove(id: number): Observable<Response> {
		return this._resource.remove(id);
	}
}
