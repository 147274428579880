import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'property' })
export class PropertyPipe implements PipeTransform {
	public transform<T, P extends keyof T>(value: T, property: P): T[P] {
		if (value == null) {
			return null;
		}
		return value[property];
	}
}
