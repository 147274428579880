import {Injectable} from '@angular/core';
import {Router, Event, GuardsCheckStart, GuardsCheckEnd, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, from as observableFrom, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {UriContext} from 'kn-http';
import {Utils} from 'kn-utils';
import {UserService, PermissionConfiguratorService} from 'kn-user';
import {UriContextService} from 'kn-rest';
import {UserRolesResourceService} from '../users/user-roles-resource.service';
import {RolesResourceService} from '../roles/roles-resource.service';
import {RestAutorizationConfig, RestContextDelegate, RestContextDescriptor, RestContextSource} from './rest-autorization.config';
import * as Model from 'common-web/model';
import * as CommonModel from '../../model/common-database.types';

@Injectable()
export class RestPermissionsConfiguratorGuard implements CanActivate {
	private _rollbackContext: { [key: string]: any } = null;

	public constructor(
			private readonly _router: Router,
			private readonly _user: UserService,
			private readonly _configurator: PermissionConfiguratorService,
			private readonly _uriContext: UriContextService,
			private readonly _userRolesResourceService: UserRolesResourceService,
			private readonly _rolesResourceService: RolesResourceService,
			private readonly _config?: RestAutorizationConfig) {
		this._router.events.subscribe(next => this._routerEventsListener(next));
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		let delegates = this._config != null ? this._config.contextDelegates : null;
		if (route.data.hasOwnProperty('restPermissionsContextDelegates')) {
			delegates = route.data['restPermissionsContextDelegates'];
		}
		const contexts = (delegates || []).map(x => this._constructContext(x));
		return this._fetchRoles(contexts).pipe(
			Rx.tap(next => this._configurator.reconfigure({ roles: next })),
			Rx.mapTo(true)
		);
	}

	private _routerEventsListener(event: Event) {
		if (event instanceof GuardsCheckStart) {
			this._rollbackContext = this._configurator.context;
		}
		else if (event instanceof GuardsCheckEnd) {
			if (this._rollbackContext != null && !event.shouldActivate) {
				this._configurator.reconfigure(this._rollbackContext);
			}
			this._rollbackContext = null;
		}
	}

	private _fetchRoles(contexts: UriContext[]) {
		const user = this._user.getUser<Model.User>();
		const query = {
			$userUid: user.uid,
			only: ['roleId', ...(this._config && this._config.restrictionKeys || [])]
		};
		return observableFrom(contexts).pipe(
			Rx.concatMap(outer => this._userRolesResourceService
				.query(Object.assign({ query }, outer))
				.pipe(Rx.map(inner => ({ context: outer, roles: this._roles(inner) })))
			),
			Rx.map(next => next.roles.map(x => this._rolesResourceService.get(x.roleId, next.context).pipe(
				Rx.tap(role => role['restrictions'] = x.restrictions)
			))),
			Rx.mergeMap(next => observableCombineLatest(next).pipe(Rx.defaultIfEmpty([]))),
			Rx.reduce((acc, next) => acc.concat(next), [])
		);
	}

	private _roles(roles: CommonModel.UserRole[]) {
		const restrictions = this._config && this._config.restrictionKeys || [];
		const emptyRestrictions = restrictions.reduce((x, key) => { x[key] = []; return x; }, {} as { [key: string]: any[] });
		const result: Partial<CommonModel.UserRole>[] = [];
		roles.forEach(role => {
			let found = result.find(x => x.roleId === role.roleId);
			if (!found) {
				found = {
					roleId: role.roleId,
					restrictions: Utils.clone(emptyRestrictions, true)
				} as Partial<CommonModel.UserRole>;
				result.push(found);
			}
			restrictions.forEach(restrictionKey => {
				if (found['restrictions'][restrictionKey].indexOf(role[restrictionKey]) === -1) {
					found['restrictions'][restrictionKey].push(role[restrictionKey]);
				}
			});
		});
		return result;
	}

	private _constructContext(delegate: RestContextDelegate) {
		const constructField = (uriKey: string, descriptor: RestContextDescriptor & any) => {
			switch (descriptor.source) {
				case RestContextSource.Static:
					return descriptor.value;
				case RestContextSource.UriContextService:
					if (this._uriContext.context.hasOwnProperty(descriptor.key || uriKey)) {
						return this._uriContext.context[descriptor.key || uriKey];
					}
					return descriptor.default;
			}
		};

		const context = {} as UriContext;
		for (const uriKey in delegate) {
			if (delegate.hasOwnProperty(uriKey)) {
				const descriptor = delegate[uriKey];
				context[uriKey] = Utils.isObject(descriptor)
					? constructField(uriKey, descriptor)
					: descriptor;
			}
		}
		return context;
	}
}
