import {DefaultImageResolver} from './default-image-resolver';
import {SandboxedDocument} from '../../sandboxed-document';
import {AbstractNodeIterator} from '../../utils/abstract-node-iterator';
import {DefaultXhrFetcher} from '../../utils/default-xhr-fetcher';

class ImageIterator extends AbstractNodeIterator {
	public acceptNode(node: Node): number {
		return (node as HTMLElement).tagName === 'IMG' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
	}

	protected _whatToShow(): number {
		return NodeFilter.SHOW_ELEMENT;
	}
}

export interface RegistrationImageResolverOptions {
	fetcher?: (uri: string, binary: boolean) => Promise<ArrayBuffer | string>;
}

export class RegistrationImageResolver extends DefaultImageResolver {
	private readonly _options: RegistrationImageResolverOptions;
	private readonly _images = new Map<string, string>();

	public constructor(options?: RegistrationImageResolverOptions) {
		super();
		this._options = Object.assign({
			fetcher: DefaultXhrFetcher.fetch
		}, options);
	}

	public resolve(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement): ArrayBuffer | string {
		if (image instanceof HTMLImageElement && this._images.has(image.src)) {
			return this._images.get(image.src);
		}
		return super.resolve(image);
	}

	public async register(uri: string): Promise<string> {
		const extenssion = uri.match(/\.(jpg|jpeg|png)$/);
		let mimetype: string;

		switch (extenssion && extenssion[1]) {
			case 'jpg':
			case 'jpeg':
				mimetype = 'image/jpeg';
				break;
			case 'png':
				mimetype = 'image/png';
				break;
			default:
				throw new Error('Unsupported image format.');
		}

		const data = await this._options.fetcher(uri, true) as ArrayBuffer;
		const bytes = new Uint8Array(data);
		const blob = new Blob([bytes.buffer], { type: mimetype });
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = x => {
				this._images.set(uri, (x.target as any).result);
				resolve(uri);
			};
			reader.onerror = x => reject(x);
			reader.readAsDataURL(blob);
		});
	}

	public async registerFromDocument(sandbox: SandboxedDocument): Promise<string[]> {
		const iterator = new ImageIterator(sandbox.nativeDocument.body);
		const uris: string[] = [];
		let node: Node;
		// eslint-disable-next-line no-cond-assign
		while (node = iterator.nextNode()) {
			const uri = (node as HTMLImageElement).src;
			if (uris.indexOf(uri) === -1) {
				uris.push(uri);
			}
		}
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		return Promise.all(uris.map(uri => this.register(uri)));
	}
}
