import {Injectable, IterableDiffers} from '@angular/core';
import {NodeTreeBuilder} from './builders/node-tree-builder';
import {RowItem} from '../types';

@Injectable()
export class NodeTreeBuilderFactory {
	public constructor(private readonly _differs: IterableDiffers) { }

	public create<T extends RowItem>() {
		return new NodeTreeBuilder<T>(this._differs);
	}
}
