import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router, CanDeactivate, ActivatedRouteSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Utils} from 'kn-utils';

export interface CanComponentDeactivate {
	canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class ComponentDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
	public constructor(
		private readonly location: Location,
		private readonly router: Router
	) {}

	public canDeactivate(
		component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (component && component.canDeactivate) {
			const result = component.canDeactivate();
			if (Utils.isBoolean(result) && !result) {
				this._fixHistory(currentRoute);
			}
			if (Utils.isPromise(result)) {
				(result as Promise<boolean>).then(x => {
					if (!x) {
						this._fixHistory(currentRoute);
					}
				});
			}
			if (Utils.isObservable(result)) {
				(result as Observable<boolean>).subscribe(x => {
					if (!x) {
						this._fixHistory(currentRoute);
					}
				});
			}
			return result;
		}
		return true;
	}

	private _fixHistory(currentRoute: ActivatedRouteSnapshot) {
		const currentUrlTree = this.router.createUrlTree([], currentRoute);
		const currentUrl = currentUrlTree.toString();
		if (!this.location.isCurrentPathEqualTo(currentUrl)) {
			this.location.go(currentUrl);
		}
	}
}
