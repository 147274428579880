import {Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, HostBinding} from '@angular/core';
import {Subscription, Observable, combineLatest as observableCombineLatest, of as observableOf} from 'rxjs';
import {I18nService} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {PermissionActionsResourceService} from '../../services/roles/permission-actions-resource.service';
import {PermissionsResourceService} from '../../services/roles/permissions-resource.service';
import {RolesStoreService} from './roles-store.service';
import {RolesViewConfig} from './roles-view.config';
import * as Model from 'common-web/model';
import * as CommonModel from '../../model/common-database.types';

@Component({
	selector: 'kn-database-roles',
	templateUrl: 'database-roles.html'
})
export class DatabaseRolesComponent implements OnChanges {
	private _subscription: Subscription;
	private _savable = false;

	@Input() public database: Model.Database;
	@Output() public savableChange = new EventEmitter<boolean>();

	public permissionActions: Model.ActionInfo[];
	public permissions: Model.PermissionInfo[] = [];
	public roles: CommonModel.Role[];

	@HostBinding('class.expanded')
	public isVisible = false;

	public get savable() {
		return this._savable;
	}

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _rolesStore: RolesStoreService,
			private readonly _permissionActionsResource: PermissionActionsResourceService,
			private readonly _permissionsResource: PermissionsResourceService,
			private readonly _toast: ToastService,
			private readonly _config: RolesViewConfig) {
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.isVisible) {
			this.roles = null;
			this.expand();
		}
	}

	public ngOnDestroy() {
		this._dispose();
	}

	private _dispose() {
		this._subscription && this._subscription.unsubscribe();
		this._subscription = null;
	}

	public expand() {
		this._dispose();
		const context = { [this._config.databaseUriKey]: this.database.uid };
		this._subscription = observableCombineLatest(
				this._rolesStore.query(context),
				this._permissionActionsResource.query(context),
				this._permissionsResource.query(context)
			)
			.subscribe(
				next => {
					this.roles = next[0];
					this.permissionActions = next[1];
					this.permissions = next[2];
					this.isVisible = true;
				},
				error => this._toast.show(this._i18n.t('Loading failed.'), this._retriveErrorMessage(error)));
	}

	public collapse() {
		this.roles = null;
		this.isVisible = false;
		this._setSavable(false);
	}

	public save(): Observable<any> {
		if (!this.isVisible) {
			return observableOf([]);
		}
		const context = { [this._config.databaseUriKey]: this.database.uid };
		return this._rolesStore.save(this.roles, context);
	}

	public handleStatus(status: string) {
		this._setSavable(this.isVisible && status === 'VALID');
	}

	private _setSavable(value: boolean) {
		if (this._savable !== value) {
			this._savable = value;
			this.savableChange.emit(value);
		}
	}

	private _retriveErrorMessage(error: any) {
		return error.statusText || error.message || error;
	}
}
