import {CommonModule} from '@angular/common';
import {InjectionToken, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KnCommonModule} from 'kn-common';
import {KnDirectivesModule} from 'kn-directives';
import {KnFormsModule} from 'kn-forms';
import {Resource} from 'kn-rest';
import {KnFileStorageInputComponent} from './file-storage-input.component';
import * as CommonWebModel from 'common-web/model';

export const FILE_STORAGE_INPUT_RESOURCE = new InjectionToken<Resource<CommonWebModel.FileStorage>>('FILE_STORAGE_INPUT_RESOURCE');

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnCommonModule,
		KnDirectivesModule,
		KnFormsModule,
		ReactiveFormsModule
	],
	declarations: [
		KnFileStorageInputComponent
	],
	exports: [
		KnFileStorageInputComponent
	]
})
export class KnFileStorageInputModule { }
