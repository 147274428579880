import {ArrayUtils} from './array-utils';

type KObj = { [key: string]: any };

export class ObjectUtils {
	private static readonly _propNameTokenizerRegExp = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]/g;
	private static readonly _escapeCharRegExp = /\\(\\)?/g;

	public static get<T extends {}>(object: T, path: string | string[]): any {
		return ObjectUtils._getOrSet(object, path);
	}

	public static set<T extends {}>(object: T, path: string | string[], value: any): void {
		ObjectUtils._getOrSet(object, path, value !== undefined ? value : null);
	}

	private static _getOrSet<T extends {}>(object: T, path: string | (string | number)[], setValue?: any): any {
		if (object == null) {
			return undefined;
		}

		if (object.hasOwnProperty(path as string)) {
			if (setValue !== undefined) {
				(object as KObj)[path as string] = setValue;
			}
			return (object as KObj)[path as string];
		}

		const tokenizedPath: string[] = [];
		if (!Array.isArray(path)) {
			path.replace(ObjectUtils._propNameTokenizerRegExp, (m, n, q, s) => {
				tokenizedPath.push(q ? s.replace(ObjectUtils._escapeCharRegExp, '$1') : (n || m));
				return '';
			});
			path = tokenizedPath;
		}

		let index = 0;
		let value: any = object;
		while (value != null && index < path.length - 1) {
			if (+path[index] < 0 && Array.isArray(value)) {
				path[index] = value.length + +path[index];
			}
			value = value[path[index++]];
		}
		if (value == null) {
			return undefined;
		}
		if (+path[index] < 0 && Array.isArray(value)) {
			path[index] = value.length + +path[index];
		}
		if (setValue !== undefined) {
			value[path[index]] = setValue;
		}
		return value[path[index]];
	}

	public static initStructure<T extends {}, U extends {}>(dest: T, structure: U): U {
		for (const key in structure) {
			if (!structure.hasOwnProperty(key)) {
				continue;
			}
			if ((dest as KObj)[key] === undefined) {
				(dest as KObj)[key] = (structure as KObj)[key];
			}
			else if (!!(structure as KObj)[key] && typeof (structure as KObj)[key] === 'object') {
				ObjectUtils.initStructure((dest as KObj)[key], (structure as KObj)[key]);
			}
		}
		return dest as any as U;
	}

	public static defaultsResolver<T extends {}, U extends {}>(dest: T, resolvers: { [P in keyof T]?: { (object: T): T[P] } }): U {
		for (const key in resolvers) {
			if (resolvers.hasOwnProperty(key) && (dest as KObj)[key] === undefined) {
				(dest as KObj)[key] = resolvers[key](dest);
			}
		}
		return dest as any as U;
	}

	public static defaults<T extends {}, U extends {}>(dest: T, ...src: {}[]): U {
		const defaultsCustomizer = (dstValue: any, srcValue: any) => dstValue === undefined;
		return ObjectUtils.assignWith(defaultsCustomizer, dest, ...src);
	}

	public static assignWith<T extends {}, U extends {}>(customizer: (dstValue: any, srcValue: any) => boolean, dest: T, ...src: {}[]): U {
		for (const object of src) {
			for (const key in object) {
				if (object.hasOwnProperty(key)) {
					if (!dest.hasOwnProperty(key)
							|| customizer((dest as any)[key], (object as any)[key])) {
						(dest as any)[key] = (object as any)[key];
					}
				}
			}
		}
		return dest as any as U;
	}

	public static base64Encode(data: ArrayBuffer): string {
		return window.btoa(
			ArrayUtils.chunkArray(Array.from(new Uint8Array(data)), 1024 * 100)
			.map(sub => String.fromCharCode(...sub)).join('')
		);
	}

	public static base64Decode(base64data: string): Uint8Array[] {
		const sliceSize = 1024;
		const byteCharacters = window.atob(base64data);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			let offset = begin;
			for (let i = 0 ; offset < end; ++i, ++offset) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}

		return byteArrays;
	}
}
