import {AbstractIterablePreprocessor} from './abstract-iterable-preprocessor';

export class AnchorImageSizePreprocessor extends AbstractIterablePreprocessor {
	public whatToShow(): number {
		return NodeFilter.SHOW_ELEMENT;
	}

	public acceptNode(node: Node): number {
		return (node as HTMLElement).tagName === 'IMG' ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
	}

	protected _processNode(node: Node): void {
		const image = node as HTMLImageElement;
		image.width = image.width;
		image.height = image.height;
	}
}
