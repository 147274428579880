import {KnAuthorizationModule, Permissions} from 'kn-user';

export const permissions: Permissions = [
	{
		resource: 'master'
	}, {
		resource: 'customer'
	}, {
		resource: 'common',
		children: [
			{
				resource: 'users'
			}, {
				resource: 'roles'
			}, {
				resource: 'print-templates'
			}, {
				resource: 'view-templates'
			}
		]
	}
];

export const authorization = KnAuthorizationModule.forRoot(permissions);
