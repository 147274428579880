import {Observable, throwError as observableThrowError, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Component, AfterViewInit, OnDestroy, HostBinding, ViewChildren, QueryList, ChangeDetectorRef} from '@angular/core';
import {ActiveMonitor, I18nService} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {UriContext} from 'kn-http';
import {AbstractResource} from 'kn-rest';
import {DatabaseRolesComponent} from './database-roles.component';
import * as Model from 'common-web/model';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';

@Component({
	selector: 'kn-roles',
	templateUrl: 'roles.html'
})
export class RolesComponent implements AfterViewInit, OnDestroy {
	private _disposables: Function[] = [];
	private _savable = false;
	private readonly _loadingMonitor = new ActiveMonitor(5, 300);

	public databases: Partial<Model.Database>[] = [];

	@HostBinding('class.loading')
	public get loading(): boolean {
		return this._loadingMonitor.active;
	}

	@ViewChildren(DatabaseRolesComponent)
	public databaseRolesComponents: QueryList<DatabaseRolesComponent>;

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _toast: ToastService,
			private readonly _cdr: ChangeDetectorRef,
			private readonly _databasesResource: DatabasesResourceService) {
	}

	public ngAfterViewInit() {
		this._reload();
	}

	public ngOnDestroy() {
		this._dispose();
	}

	protected _dispose() {
		this._disposables.forEach(x => x());
		this._disposables = [];
	}

	private _reload() {
		const subscriptions = [
			// https://github.com/angular/angular/issues/10131
			this.databaseRolesComponents.changes
				.pipe(Rx.delay(0))
				.subscribe(() => this.refreshSavable()),
			this._fetch(this._databasesResource)
				.lift(this._loadingMonitor.operator())
				.subscribe(next => this.databases = [this._databasesResource.getMasterDatabase(), ...next])
		];
		subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
	}

	protected _fetch<U>(resource: AbstractResource<U>, context?: UriContext): Observable<U[]> {
		return resource.query(context)
			.pipe(Rx.catchError(error => this._handleError<U[]>(error)));
	}

	protected _handleError<U>(error: any): Observable<U> {
		this._toast.show(this._i18n.t('Loading failed.'), error.statusText);
		return observableThrowError(error);
	}

	public save() {
		this._savable = false;
		const savings$ = this.databaseRolesComponents.map(component => component.save());
		const subscription = observableCombineLatest(savings$)
			.subscribe(
				() =>  this._toast.show(this._i18n.t('Saving is successful.')),
				error => this._toast.show(this._i18n.t('Saving failed.'), error.statusText),
				() => {
					this._dispose();
					this._reload();
				}
			);

		this._disposables.push(() => subscription.unsubscribe());
	}

	public refreshSavable() {
		let savable = false;
		if (this.databaseRolesComponents) {
			const components = this.databaseRolesComponents.toArray();
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < components.length; i++) {
				if (components[i].isVisible && !components[i].savable) {
					savable = false;
					break;
				}
				if (components[i].savable) {
					savable = true;
				}
			}
		}
		if (this._savable !== savable) {
			this._savable = savable;
			this._cdr.detectChanges();
		}
	}

	public get isSavable() {
		return this._savable;
	}
}
