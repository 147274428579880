import {RemoteAutocompleteBondConfig, RemoteAutocompletition} from 'kn-forms';
import {UriContext} from 'kn-http';
import {AbstractFilterSerializer} from 'kn-query-filter';
import {AbstractResource} from 'kn-rest';
import {Subscription} from 'rxjs';
import {autocompletitionFactoryWithMaster} from './autocompletition-factory-with-master';
import * as CommonModel from 'common-web/model';

export class ResourceAutocompletitionFactoryWithMaster<T extends CommonModel.IUid, U extends CommonModel.IUid> {
	private _autocompletition: RemoteAutocompletition<T | U>;

	public get autocompletition() {
		if (this._autocompletition == null) {
			this._createAutocompletition();
		}
		return this._autocompletition;
	}

	public constructor(
		private readonly _resource: AbstractResource<T>,
		private readonly _masterResource: AbstractResource<U>,
		private readonly _masterContext: UriContext,
		private readonly _queryProperties: string[] | string,
		private readonly _valueProperties: string = 'id',
		private readonly _query?: { [key: string]: any },
		private readonly _config?: RemoteAutocompleteBondConfig,
		private readonly _serializer?: AbstractFilterSerializer<string>) {
	}

	public subscribe(onChange: { (autocompetition: RemoteAutocompletition<T | U>): void }): Subscription {
		const subscription = this._resource.changes.subscribe(next => {
			this._createAutocompletition();
			onChange(this._autocompletition);
		});
		onChange(this.autocompletition);
		return subscription;
	}

	private _createAutocompletition() {
		this._autocompletition = autocompletitionFactoryWithMaster<T, U>(
			this._resource,
			this._masterResource,
			this._masterContext,
			this._queryProperties,
			this._valueProperties,
			this._query,
			this._config,
			this._serializer
		);
	}
}
