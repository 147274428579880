import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'decycle' })
export class DecyclePipe implements PipeTransform {
	public transform(value: any, replacer?: (value: any) => any): any {
		if (value == null) {
			return null;
		}
		return DecyclePipe._derez(value, '$', replacer);
	}

	// based on <https://github.com/douglascrockford/JSON-js/blob/master/cycle.js>
	private static _derez(value: any, path: string, replacer: (value: any) => any, refs: { reference: any, path: string }[] = []) {
		if (replacer !== undefined) {
			value = replacer(value);
		}

		if (typeof value === 'object' && value !== null
				&& !(value instanceof Boolean)
				&& !(value instanceof Date)
				&& !(value instanceof Number)
				&& !(value instanceof RegExp)
				&& !(value instanceof String)) {

			const ref = refs.find(x => x.reference === value);
			if (ref != null) {
				return { $ref: ref.path };
			}
			refs.push({ reference: value, path: path });

			if (Array.isArray(value)) {
				return value.reduce((acc, item, i) => {
					acc.push(DecyclePipe._derez(item, `${path}[${i}]`, replacer, refs));
					return acc;
				}, []);
			}

			return Object.keys(value).reduce((acc, key) => {
				const name = JSON.stringify(key);
				acc[key] = DecyclePipe._derez(value[key], `${path}[${name}]`, replacer, refs);
				return acc;
			}, {} as { [key: string]: any });
		}
		return value;
	}
}
