import {Component, ViewEncapsulation, Input, Output, EventEmitter, ContentChildren, QueryList, AfterContentInit, OnDestroy, TemplateRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {BooleanField} from 'kn-common';
import {KnTab} from './tab.component';

@Component({
	selector: 'kn-tabs',
	templateUrl: 'tabs.html',
	styleUrls: ['tabs.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnTabs implements AfterContentInit, OnDestroy {
	private _subscription: Subscription;
	private _activeIndex: number = -1;

	public labels: { classes: string, template: TemplateRef<void> }[];

	public get activeIndex(): number {
		return this._activeIndex;
	}

	@Input() public set activeIndex(value: number) {
		value = this._sanitizeActiveIndex(value);
		this._tabs.forEach((x, index) => x.active = index === value);
		if (this._activeIndex !== value) {
			this._activeIndex = value;
			this.change.emit(this);
		}
	}

	@Output() public change = new EventEmitter<KnTabs>();

	@Input() @BooleanField() public deselectable: boolean = false;

	@ContentChildren(KnTab)
	public _tabs: QueryList<KnTab>;

	public ngAfterContentInit() {
		this._subscription = this._tabs.changes.subscribe(() => this._updateTabs());
		this._updateTabs();
	}

	public ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	public selectIndex(index: number) {
		if (this.activeIndex === index && this.deselectable) {
			this.activeIndex = -1;
		}
		else {
			this.activeIndex = index;
		}
	}

	private _updateTabs() {
		this.labels = this._tabs.map(x => ({ classes: x.classes, template: x.labelTemplate }));
		this.activeIndex = -1;
	}

	private _sanitizeActiveIndex(index: number) {
		if (index >= 0 && index < this._tabs.length) {
			return index;
		}
		if (this.deselectable && index === -1) {
			return index;
		}
		const indexes = this._tabs.reduce((acc, x, i) => x.active ? acc.concat([i]) : acc, []);
		if (indexes.length >= 1) {
			return indexes[0];
		}
		else if (indexes.length === 0 && this._tabs.length > 0) {
			return 0;
		}
		return -1;
	}
}
