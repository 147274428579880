import {SimpleChange} from '@angular/core';
import {Utils} from 'kn-utils';
import {Renderers} from './renderers/renderers';
import {KnNumberedSelector} from './renderers/components/numbered-selector.component';
import {updateBinding} from './renderers/binders/component-render-binder-ref';
import {Contract, ContractDescription} from './contract.types';
import {SelectionMode, Description, ColumnDescription, RenderMode, SectionDescription} from './types';
import {SectionUtils} from './internal-utils';

export type ColumnExpander<T> = Pick<ColumnDescription<T>, 'id'> & Partial<ColumnDescription<T>>;
type SectionPathNode = { [key: string]: SectionPathNode };

export class DescriptionBuilder {
	public static getDefaultExpanders(): ColumnExpander<any>[] {
		return [
			{
				id: '@selector',
				label: '#',
				name: '#',
				sortable: false,
				resizable: false,
				classes: ['right', 'number'],
				renderer: Renderers.component(
					KnNumberedSelector,
					(componentRef, value, context, firstChange) => {
						const changes = Object.assign({
							'selection': new SimpleChange(null, null, firstChange)
						}, updateBinding(componentRef, 'context', context, firstChange));
						return changes;
					}),
				exportable: false
			}
		];
	}

	public static from<T>(description: ContractDescription<T>, expanders?: ColumnExpander<T>[]): Description<T> {
		description = Utils.clone(description, true);
		if (!description) {
			description = {};
			DescriptionBuilder._initStructure(description);
			return description as Description<T>;
		}
		expanders = expanders || DescriptionBuilder.getDefaultExpanders();
		DescriptionBuilder._initStructure(description);
		DescriptionBuilder._expandColumnsByExpanders(description, expanders);
		DescriptionBuilder._setColumnsDefaults(description);
		DescriptionBuilder._consolidateSections(description);
		DescriptionBuilder._setSectionsDefaults(description);
		return description as Description<T>;
	}

	private static _initStructure<T>(description: ContractDescription<T>) {
		const structure = {
			renderMode: RenderMode.Static,
			selectionMode: SelectionMode.None,
			mutalSorting: false,
			rowsReordering: false,
			columnsReordering: true,
			rows: {
				height: null,
				visible: true,
				selectable: false,
				classes: []
			},
			columns: [],
			sections: []
		} as Description<T>;

		Utils.object.initStructure(description, structure);
	}

	private static _expandColumnsByExpanders<T>(description: ContractDescription<T>, expanders: ColumnExpander<T>[]) {
		for (const expander of expanders) {
			const column = description.columns.find(x => x.id === expander.id);
			if (column != null) {
				Utils.object.defaults(column, expander);
			}
		}
	}

	private static _setColumnsDefaults<T>(description: ContractDescription<T>) {
		for (const column of description.columns) {
			DescriptionBuilder._setColumnDefaults(column);
		}
	}

	private static _setColumnDefaults<T>(column: Contract<ColumnDescription<T>>) {
		const properCase: string = null;
		const getProperCase = () => properCase || DescriptionBuilder._toProperCase(column.id);
		const defaults = {
			label: column.label != null ? column.label : (column.name != null ? column.name : getProperCase()),
			name: column.name != null ? column.name : (column.label != null ? column.label : getProperCase()),
			section: [],
			gravity: 0,
			hidden: false,
			visibility: true,
			sortable: true,
			groupable: false,
			resizable: true,
			exportable: true,
			classes: [],
			accessor: (item, x) => Utils.object.get(item, x.id),
			renderer: (value, context, target) => value,
			aggregator: (values, context) => values.some(x => x !== values[0]) ? null : values[0]
		} as Partial<ColumnDescription<T>>;

		Utils.object.defaults(column, defaults);
	}

	private static _consolidateSections<T>(description: Contract<ContractDescription<T>>) {
		const tree = DescriptionBuilder._buildSectionsTree(description);
		let usedIds = Object.keys(tree);
		DescriptionBuilder._removeOrphanedSections(description.sections, usedIds);
		DescriptionBuilder._completeMissingSections(description.sections, usedIds);
		SectionUtils.forEachNode(description.sections, (node, path) => {
			if (node.children == null) {
				node.children = [];
			}
			const subtree = DescriptionBuilder._getSubtree(tree, path.concat([node.id]));
			usedIds = Object.keys(subtree);
			DescriptionBuilder._removeOrphanedSections(node.children, usedIds);
			DescriptionBuilder._completeMissingSections(node.children, usedIds);
		});
	}

	private static _removeOrphanedSections<T>(sections: Contract<SectionDescription<T>>[], usedIds: string[]) {
		for (let i = sections.length - 1; i >= 0; i--) {
			if (usedIds.indexOf(sections[i].id) === -1) {
				sections.splice(i, 1);
			}
		}
	}

	private static _completeMissingSections<T>(sections: Contract<SectionDescription<T>>[], usedIds: string[]) {
		const missingIds = usedIds.filter(id => !sections.some(x => x.id === id));
		for (const missingId of missingIds) {
			sections.push({ id: missingId } as Partial<SectionDescription<T>>);
		}
	}

	private static _buildSectionsTree<T>(description: ContractDescription<T>): SectionPathNode {
		const paths = description.columns.map(x => Utils.array.box(x.section));
		const tree: SectionPathNode = {};
		for (const path of paths) {
			let subtree = tree;
			for (const sectionId of path) {
				if (!subtree.hasOwnProperty(sectionId)) {
					subtree[sectionId] = {};
				}
				subtree = subtree[sectionId];
			}
		}
		return tree;
	}

	private static _getSubtree(tree: SectionPathNode, path: string[]) {
		let subtree = tree;
		for (const sectionId of path) {
			if (!subtree.hasOwnProperty(sectionId)) {
				return null;
			}
			subtree = subtree[sectionId];
		}
		return subtree;
	}

	private static _setSectionsDefaults<T>(description: Contract<ContractDescription<T>>) {
		SectionUtils.forEachNode(description.sections, node => {
			DescriptionBuilder._setSectionDefaults(node);
		});
	}

	private static _setSectionDefaults<T>(section: Contract<SectionDescription<T>>) {
		const properCase: string = null;
		const getProperCase = () => properCase || DescriptionBuilder._toProperCase(section.id);
		const defaults = {
			label: section.name != null ? section.name : getProperCase(),
			name: section.label != null ? section.label : getProperCase(),
			gravity: 0,
			monolithic: false,
			hidden: false,
			visibility: true,
			header: section.label != null,
			menu: section.label == null && section.name != null,
			classes: [],
			children: []
		} as Partial<SectionDescription<T>>;

		Utils.object.defaults(section, defaults);
	}

	private static _toProperCase(text: string) {
		return text.replace(/(^|[A-Z0-9]|[^a-z])[a-z]*/g, x => {
			let chunk = x.replace(/[^a-zA-Z0-9]/g, '');
			chunk = chunk.charAt(0).toUpperCase() + chunk.substr(1).toLowerCase();
			return chunk.length > 0 ? chunk + ' ' : '';
		}).trim();
	}
}
