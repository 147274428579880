import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('replace')
export class ReplaceFilter implements PrintRendererFilter {
	public transform(value: string, searchValue: string | RegExp, replacement: string): string {
		return value == null ? null: `${value}`.replace(searchValue, replacement);
	}
}
