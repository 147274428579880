import {Inject, Directive, OnInit, OnChanges, OnDestroy, SimpleChanges, Input, EventEmitter, forwardRef} from '@angular/core';
import {Observable, Subscription, from as observableFrom} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {I18nService} from 'kn-shared';
import {Utils} from 'kn-utils';
import {AbstractAutocompletition, AutocompleteResult} from './autocompletition/abstract-autocompletition';
import {AbstractOptionsHost} from '../options/abstract-options-host';

export type QueryResolver = (query: string) => any[] | Promise<any[]> | Observable<any[]> | EventEmitter<any[]>;

@Directive({
	selector: '[knAutocomplete]',
	exportAs: 'knAutocomplete'
})
export class KnAutocomplete implements OnInit, OnChanges, OnDestroy {
	private readonly _disposables: Function[] = [];
	private _querySubscription: Subscription;
	private _suggestions: any[];
	public _suspend = false;

	@Input('knAutocomplete') public autocompletition: AbstractAutocompletition<any>;

	public constructor(
			private readonly _i18n: I18nService,
			@Inject(forwardRef(() => AbstractOptionsHost)) private readonly _host: AbstractOptionsHost) {
	}

	public ngOnInit() {
		const subscriptions = [
			this._host.changeEvent
				.pipe(
					Rx.mapTo(this._host.value),
					Rx.distinctUntilChanged()
				)
				.subscribe(() => this._handleChange()),
			this._host.query.subscribe((next: string) => this._resolveQuery(next))
		];
		subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
		this._handleChange();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('autocompletition' in changes) {
			if (this._host.value != null) {
				this._resolveQuery(null);
			}
		}
	}

	public ngOnDestroy() {
		this._host.loading = false;
		this._querySubscription && this._querySubscription.unsubscribe();
		this._disposables.forEach(x => x());
	}

	public get suggestions() {
		return this._suggestions;
	}

	private _handleChange() {
		if (this._host.value != null) {
			this._resolveQuery(null);
		}
	}

	private _resolveQuery(query: string) {
		this._host.loading = false;
		this._querySubscription && this._querySubscription.unsubscribe();

		if (this._suspend) {
			this._suspend = false;
			return;
		}

		const setQueryResult = (result: any[] | AutocompleteResult<any>) => {
			if (Array.isArray(result)) {
				this._suggestions = result;
				if (result == null || result.length === 0) {
					this._setHints('empty-result', { query });
				}
			}
			else {
				this._suggestions = result.datasource;
				if (result.constrainsNotMet) {
					this._setHints('constrains-not-met', { query });
					return;
				}
				else if (result.partial) {
					this._setHints('partial-result', { query });
					return;
				}
				else if (result.empty) {
					this._setHints('empty-result', { query });
					return;
				}
			}
			this._setHints(null);
		};

		if (this.autocompletition == null) {
			this._suggestions = null;
			this._setHints('empty-result', { query });
			return;
		}

		const queryResult = this.autocompletition
			.querySearch(this._host.value, query, this._host.getter);
		if (Utils.isPromise(queryResult) || Utils.isObservable(queryResult)) {
			this._host.loading = true;
			this._querySubscription = observableFrom(queryResult as any)
				.subscribe(
					(next: any[] | AutocompleteResult<any>) => setQueryResult(next),
					error => {
						this._suggestions = null;
						this._setHints('error', {
							query: query,
							error: this._retriveErrorMessage(error)
						});
						this._host.loading = false;
					},
					() => this._host.loading = false
				);
		}
		else {
			setQueryResult(queryResult as any[] | AutocompleteResult<any>);
		}
	}

	private _retriveErrorMessage(error: any) {
		return error.statusText || error.message || error;
	}

	private _setHints(key: string, context?: { [key: string]: string }) {
		const hintTemplates: { [key: string]: (context: { [key: string]: string }) => string } = {
			'error': x => this._i18n.t('Error: <em>{{ error }}</em>.', x),
			'constrains-not-met': x => this._i18n.t('Constraints not met.', x),
			'empty-result': x => x.query
				? this._i18n.t('No items matching <em>{{ query }}</em> were found.', x)
				: this._i18n.t('No items were found.'),
			'partial-result': x => this._i18n.t('Partial result, please be more specific.', x)
		};

		const hintsToRemove = Object.keys(hintTemplates).filter(x => x !== key);
		this._host.removeHints(...hintsToRemove);
		if (key != null) {
			this._host.addHints({ [key]: hintTemplates[key](context) });
		}
	}
}
