import {Component, Input, Host} from '@angular/core';
import {FormGroupDirective, ControlContainer} from '@angular/forms';
import {ValidationService} from './validation.service';

@Component({
	selector: 'kn-control-messages',
	template: '<div *knCollapse="error.active">{{ error.message }}</div>'
})
export class KnControlMessages {
	private _errorMessage: string;

	@Input('control') public controlName: string;

	public constructor(
		@Host() private readonly _formGroup: FormGroupDirective,
		@Host() private readonly _controlContainer: ControlContainer,
		private readonly _validation: ValidationService) {
	}

	public get error(): { message: string, active: boolean } {
		const path = this._controlContainer.path.concat(this.controlName);
		const control = this._formGroup.form.get(path);
		if (control == null) {
			throw new Error(`Cannot find form control for ${path}.`);
		}
		for (const propertyName in control.errors) {
			if (control.errors.hasOwnProperty(propertyName) && control.touched) {
				this._errorMessage = this._validation.getErrorMessage(propertyName);
				return {
					message: this._errorMessage,
					active: true
				};
			}
		}
		return {
			message: this._errorMessage,
			active: false
		};
	}
}
