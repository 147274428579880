<kn-sticky>
	<div class="grid-panel">
		<kn-grid-selector [bond]="bonding.datagrid"></kn-grid-selector>
		<div class="actions">
			<ng-container *knCan="'copy' the 'item'">
				<a i18n-title="create-new-item-as-copy" title="New as copy"
						href="javascript:void(0)"
						*ngIf="bonding.datagrid.selected?.length === 1"
						(click)="newItem(bonding.datagrid.selected[0])">
					<i class="material-icons">content_copy</i>
				</a>
			</ng-container>
			<ng-container *knCan="'edit' the 'item'">
				<a i18n-title="edit-item" title="Edit"
						href="javascript:void(0)"
						*ngIf="bonding.datagrid.selected?.length === 1"
						(click)="editItem(bonding.datagrid.selected[0])">
					<i class="material-icons">edit</i>
				</a>
			</ng-container>
			<ng-container *knCan="'remove' the 'item'">
				<a i18n-title="delete-item" title="Remove"
						href="javascript:void(0)"
						*ngIf="bonding.datagrid.selected?.length > 0"
						(click)="deleteItems(bonding.datagrid.selected)">
					<i class="material-icons">delete</i>
				</a>
			</ng-container>
		</div>
		<div class="widgets">
			<kn-grid-reset [bond]="bonding"></kn-grid-reset>
			<kn-grid-query-filter
					[description]="bonding.filter.description"
					[model]="bonding.filter.model"
					(modelChange)="bonding.filter.onModelChange($event)"
					useDefault
					[defaultModel]="defaultFilter"
					(defaultModelChange)="onDefaultFilterChange($event)"></kn-grid-query-filter>
			<kn-grid-preset [view]="view" [bond]="bonding"
					*knCan="'show' the 'view-templates'"></kn-grid-preset>
			<kn-grid-exporter [view]="view" [bond]="bonding.datagrid"></kn-grid-exporter>
			<kn-grid-search [bond]="bonding.datagrid"></kn-grid-search>
		</div>
	</div>
</kn-sticky>
<kn-datagrid [bond]="bonding.datagrid"></kn-datagrid>
