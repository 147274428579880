import {NgModule, ModuleWithProviders} from '@angular/core';
import {EVENT_MANAGER_PLUGINS} from '@angular/platform-browser';
import {KnDraggableModule} from './draggable/draggable.module';
import {KnResizableModule} from './resizable/resizable.module';
import {ExtendedDomEventsPlugin} from './events/extended-dom-events-plugin';

const KN_INTERACTIONS_MODULES = [
	KnDraggableModule,
	KnResizableModule
];

@NgModule({
	exports: [KN_INTERACTIONS_MODULES],
	providers: [
		{ provide: EVENT_MANAGER_PLUGINS, useClass: ExtendedDomEventsPlugin, multi: true }
	]
})
export class KnInteractionsRootModule { }

@NgModule({
	exports: [KN_INTERACTIONS_MODULES]
})
export class KnInteractionsModule {
	public static forRoot(): ModuleWithProviders<KnInteractionsRootModule> {
		return { ngModule: KnInteractionsRootModule };
	}

	public static forChild(): ModuleWithProviders<KnInteractionsModule> {
		return { ngModule: KnInteractionsModule };
	}
}
