import {Utils} from 'kn-utils';
import {AbstractOptionComparer} from './abstract-option-comparer';

export class TransliterateOptionComparer<T> extends AbstractOptionComparer<T> {
	public constructor(accessor?: (item: T) => string) {
		super(accessor);
	}

	protected _prepareQuery(query: string): string {
		return Utils.text.latinise(query).toLowerCase();
	}

	protected _compare(value: string, query: string, strict: boolean): boolean {
		const text = Utils.text.latinise(value).toLowerCase();
		return strict ? text === query : text.indexOf(query) !== -1;
	}
}
