import {Injector, ElementRef} from '@angular/core';
import {ElementRenderer, ElementRendererFactory, CellValue, CellContext} from 'kn-datagrid';

export class BoolRendererFactory extends ElementRendererFactory {
	public constructor(
			private readonly _positiveHtml: string,
			private readonly _negativeHtml: string,
			private readonly _neutralHtml: string) {
		super();
	}

	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef): ElementRenderer<T> {
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			const html = value == null ? this._neutralHtml : (value ? this._positiveHtml : this._negativeHtml);
			if (html.indexOf('{{ title }}') >= 0 && context && context.column && context.column.description && context.column.description.label) {
				return html.replace('{{ title }}', context.column.description.label.toString());
			}
			return html;
		};
	}
}
