import {Component, Input, OnChanges, SimpleChanges, HostBinding} from '@angular/core';
import {JwtAuth} from 'kn-jwt-auth';
import * as Model from 'common-web/model';

@Component({
	selector: 'kn-ownership-display',
	template: ''
})
export class KnOwnershipDisplay implements OnChanges {
	private readonly _prefix: string;

	@HostBinding('title')
	@Input() public ownership: string;

	@HostBinding('innerHTML')
	public htmlContent: string;

	public constructor(auth: JwtAuth) {
		this._prefix = auth.getAuth<{ user: Model.User }>().user.ownership;
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.ownership == null) {
			this.htmlContent = null;
			return;
		}
		let prefix = this._prefix;
		if (!this.ownership.startsWith(prefix)) {
			prefix = '';
		}
		const prefixSegments = prefix.split('/').filter(x => x);
		const segments = this.ownership.substr(prefix.length).split('/').filter(x => x);
		this.htmlContent = (prefixSegments.length ? '' : '/')
			+ segments.map(x => `<span>${x}</span>`).join('/');
	}
}
