import {Observable, of as observableOf} from 'rxjs';
import {AbstractStorageService} from 'kn-storage';
import {AbstractStoreBackend} from './abstract-store-backend';
import {SettingsData, SettingsItem} from '../types';
import {indexOfSettingsItem} from '../utils';

export class StorageStoreBackend extends AbstractStoreBackend {
	public constructor(
			private readonly _storage: AbstractStorageService,
			private readonly _prefix: string = 'kn-user-settings:') {
		super();
	}

	public load(keys: string[]): Observable<SettingsData> {
		const data: SettingsData = {};
		for (const key of keys) {
			const item = this._get(key);
			if (item != null) {
				data[key] = item;
			}
		}
		return observableOf(data);
	}

	public save(data: SettingsData): Observable<SettingsData> {
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				this._set(key, this._merge(this._get(key), data[key]));
			}
		}
		return observableOf(data);
	}

	private _get(key: string) {
		return this._storage.getItem(this._prefix + key) as SettingsItem[];
	}

	private _set(key: string, items: SettingsItem[]) {
		this._storage.setItem(this._prefix + key, items);
	}

	private _merge(dest: SettingsItem[], src: SettingsItem[]) {
		for (const item of src) {
			const settingsItemIndex = indexOfSettingsItem(dest, item);
			if (settingsItemIndex !== -1) {
				dest[settingsItemIndex] = item;
				dest.push(item);
			}
		}
		return dest;
	}
}
