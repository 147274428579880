import {Routes, RouterModule} from '@angular/router';
import {DatabaseManageComponent} from './database-manage.component';

const routes: Routes = [
	{
		path: '',
		component: DatabaseManageComponent
	}
];

export const routing = RouterModule.forChild(routes);
