import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {KnPortalService} from './portal.service';
import {KnPortal} from './portal-directives';
import {FlexiblePosition} from './flexible-position';

export interface RegistredPortal {
	portal: KnPortal;
	attach: boolean;
	modal: boolean;
	positionStrategy?: FlexiblePosition;
	outletPos?: { left: number; top: number };
}

@Component({
	selector: 'kn-portal-container',
	template: '<kn-attached-portal *ngFor="let portal of attachedPortals" [portal]="portal"></kn-attached-portal>',
	styleUrls: ['portal-container.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnPortalContainer implements OnDestroy {
	protected _disposables: Function[] = [];
	public portals: RegistredPortal[] = [];

	public get attachedPortals() {
		return this.portals.filter(next => next.attach);
	}

	public constructor(
			private readonly _portalService: KnPortalService) {
		this._portalService.host = this;
	}

	public ngOnDestroy(): void {
		this._disposables.forEach(x => x());
	}

	public attachPortal(portal: KnPortal, modal: boolean = false) {
		const index = this.portals.findIndex(next => next.portal === portal);
		if (index !== -1) {
			this.portals[index].modal = modal;
			this.portals[index].attach = true;
		}
		else {
			this.portals.push(({ portal, attach: true, modal: modal }));
		}
	}

	public detachPortal(portal: KnPortal) {
		const index = this.portals.findIndex(next => next.portal === portal);
		if (index !== -1) {
			this.portals[index].attach = false;
		}
	}

	public registerPortal(portal: KnPortal) {
		if (portal == null) {
			return;
		}
		this.portals.push(({ portal, attach: false, modal: false }));
	}

	public unregisterPortal(portal: KnPortal) {
		if (portal == null) {
			return;
		}
		const index = this.portals.findIndex(next => next.portal === portal);
		if (index !== -1) {
			this.portals.splice(index);
		}
	}
}
