import {Directive, HostBinding} from '@angular/core';
import {Http} from 'kn-http';
import {KnGravatar} from './gravatar.service';

@Directive({
	selector: 'img[knGravatarSrc]',
	inputs: ['knGravatarSrc']
})
export class KnGravatarSrc {
	private static readonly _emailRegexp = new RegExp('^[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$', 'i');
	public static defaultDataUri = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=';

	@HostBinding('src')
	public imgSrc = KnGravatarSrc.defaultDataUri;

	@HostBinding('class.valid')
	public imgValidClass = false;

	public constructor(
			private readonly _gravatarService: KnGravatar,
			private readonly _http: Http) {
	}

	public set knGravatarSrc(email: string) {
		if (this._validateEmail(email)) {
			const uri = this._gravatarService.url(email, {});
			this._http.get(uri, { responseType: 'blob' })
				.subscribe(
					next => {
						const reader = new FileReader();
						reader.onload = x => {
							this._setSrc((x.target as any).result);
						};
						reader.readAsDataURL(next.body);
					},
					error => this._setSrc());
		}
		else {
			this._setSrc();
		}
	}

	private _validateEmail(email: string): boolean {
		return KnGravatarSrc._emailRegexp.test(email);
	}

	private _setSrc(dataUri?: string): void {
		if (dataUri == null) {
			this.imgValidClass = false;
		}
		else {
			this.imgSrc = dataUri;
			this.imgValidClass = true;
		}
	}
}
