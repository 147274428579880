import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'unique' })
export class UniquePipe implements PipeTransform {
	public transform(value: any[]): any {
		if (value == null) {
			return null;
		}
		return value.filter((x, idx) => value.indexOf(x) === idx);
	}
}
