import {Observable, of as observableOf, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {RestService, RestChangeNotifierService, Resource, AbstractResource, ProxyResource} from 'kn-rest';
import {EntityUtils} from '../entity-utils';
import {EntityBase} from 'common-web/model';

export type ReferenceDescriptor<T, U> = {
	id: string;
	resource: AbstractResource<U>;
	property: string;
	propertyId: string;
	updateByReferer?: (reference: U, referer: T) => void
};

enum DiffResult {
	Remove,
	RemoveAndCreate,
	Create,
	Update
}

export abstract class AbstractReferencedResource<T extends EntityBase> extends ProxyResource<T> {
	protected _referenceDescriptors: ReferenceDescriptor<T, any>[] = [];

	public constructor(baseResource: AbstractResource<T>);
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			table: string);
	public constructor(
			baseResourceOrRest: AbstractResource<T> | RestService,
			notifier?: RestChangeNotifierService,
			table?: string) {
		super(baseResourceOrRest instanceof AbstractResource
			? baseResourceOrRest
			: new Resource(baseResourceOrRest, notifier, table));
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return this._referenceDescriptors
			.reduce((acc, x) => Object.assign(acc, { [x.id]: x.resource }), {});
	}

	public query(context?: UriContext): Observable<T[]> {
		return super.query(this._extendContextWithReferences(context));
	}

	public get(id: number, context?: UriContext): Observable<T> {
		return super.get(id, this._extendContextWithReferences(context));
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		let saveFunction = this._save.bind(this);
		if (item.id != null) {
			saveFunction = this._update.bind(this);
		}
		else if (this._referenceDescriptors.map(x => item[x.property]).some(x => x != null)) {
			saveFunction = this._create.bind(this);
		}
		return saveFunction(item, context);
	}

	protected _remove(id: number, context: UriContext): Observable<Response> {
		return super._remove(id, context)
			.pipe(Rx.switchMap(next => this._removeReferences(next).pipe(Rx.map(() => next))));
	}

	private _save(item: T, context?: UriContext) {
		return super.save(item, context);
	}

	private _create(item: T, context?: UriContext) {
		/*
		 * 1) POST item w/o reference properties and ids
		 * 2) update references with data from item
		 * 3) POST references
		 * 4) update reference ids in item from references
		 * 5) PUT item
		 */
		return observableOf(Utils.clone(item)).pipe(
			Rx.tap(next => this._referenceDescriptors.forEach(x => {
				delete next[x.property];
				delete next[x.propertyId];
			})),
			Rx.switchMap(next => super.save(next, context).pipe(Rx.map(() => next))),
			Rx.tap(next => {
				Utils.object.assignWith(x => x == null, item, next);
				this._referenceDescriptors
					.filter(x => x.updateByReferer != null && item[x.property] != null)
					.forEach(x => x.updateByReferer(item[x.property], item));
			}),
			Rx.switchMap(() => {
				const requests$ = this._referenceDescriptors
					.filter(x => item[x.property] != null)
					.map(x => x.resource.save(item[x.property]));
				return observableCombineLatest(requests$);
			}),
			Rx.map(() => {
				this._referenceDescriptors
					.filter(x => item[x.property] != null)
					.forEach(x => (item as any)[x.propertyId] = item[x.property].id);
				return Utils.clone(item);
			}),
			Rx.tap(next => this._referenceDescriptors.forEach(x => delete next[x.property])),
			Rx.switchMap(next => super.save(next, context))
		);
	}

	private _update(item: T, context?: UriContext) {
		/*
		 * 1) diff references
		 * 2) update update references with (Create | Update) from CardInAvp
		 * 3) GET references with (Update); ignore if equals
		 * 4) PUT references with (Update)
		 * 5) POST references with (Create)
		 * 6) if POST on reference with (Create) returns 409, send again with PUT and abort DELETE with returned id
		 * 7) update reference ids in item from references
		 * 8) PUT item
		 * 9) DELETE references with (Remove)
		 */
		const diff = this._calculateDiff(item);

		const createDiffs = [DiffResult.Create, DiffResult.RemoveAndCreate];
		const updateDiffs = [DiffResult.Update];
		const removeDiffs = [DiffResult.Remove, DiffResult.RemoveAndCreate];
		this._referenceDescriptors
			.filter(x => x.updateByReferer != null)
			.filter(x => [...createDiffs, ...updateDiffs].indexOf(diff[x.id]) !== -1)
			.forEach(x => x.updateByReferer(item[x.property], item));

		const removeRequests$ = this._referenceDescriptors.reduce((acc, x) =>
			Object.assign(acc, { [x.id]: {} as { [id: string]: Observable<Response> } }),
			{} as { [id: string]: { [id: string]: Observable<Response> } });
		this._referenceDescriptors
			.filter(x => removeDiffs.indexOf(diff[x.id]) !== -1)
			.forEach(x => removeRequests$[x.id][item[x.propertyId]] = x.resource.remove(item[x.propertyId]));

		const updateRequests$ = this._referenceDescriptors
			.filter(x => updateDiffs.indexOf(diff[x.id]) !== -1)
			.map(x => x.resource.get(item[x.propertyId]).pipe(
				Rx.filter(next => !EntityUtils.equal(item[x.property], next)),
				Rx.switchMapTo(x.resource.save(item[x.property])))
			);

		const createRequests$ = this._referenceDescriptors
			.filter(x => createDiffs.indexOf(diff[x.id]) !== -1)
			.map(x => x.resource.save(item[x.property]).pipe(
				Rx.catchError((error: Response) => {
					if (error.status === 409) {
						item[x.property].id = this._retriveId(error);
						delete removeRequests$[x.id][item[x.property].id];
						return x.resource.save(item[x.property]);
					}
					throw error;
				}))
			);

		return observableCombineLatest(...updateRequests$, ...createRequests$).pipe(
			Rx.defaultIfEmpty([]),
			Rx.map(() => {
				this._referenceDescriptors.forEach(x =>
					(item as any)[x.propertyId] = item[x.property] != null ? item[x.property].id : null);
				return Utils.clone(item);
			}),
			Rx.tap(next => this._referenceDescriptors.forEach(x => delete next[x.property])),
			Rx.switchMap(next => super.save(next, context)),
			Rx.switchMap(next => {
				const requests = Object.values(removeRequests$).map(x => Object.values(x));
				return observableCombineLatest(Utils.array.flatten(requests)).pipe(
					Rx.defaultIfEmpty([]),
					Rx.map(() => next));
			})
		);
	}

	private _removeReferences(response: Response) {
		let removedItems = response.body as T[];
		removedItems = Utils.array.box(removedItems);
		const requests$: Observable<Response>[][] = [];
		for (const desc of this._referenceDescriptors) {
			requests$.push(removedItems.map(x => x[desc.propertyId])
				.filter(x => x)
				.reduce((acc, x) => acc.concat(desc.resource.remove(x)), [] as Observable<T>[]));
		}
		return observableCombineLatest(Utils.array.flatten(requests$))
			.pipe(Rx.defaultIfEmpty([]));
	}

	private _calculateDiff(item: T): { [key: string]: DiffResult } {
		const diff: { [key: string]: DiffResult } = {};
		for (const desc of this._referenceDescriptors) {
			let referenceDiff = null;
			const property = desc.property;
			const propertyId = desc.propertyId;
			if (item[property] == null && item[propertyId] != null) {
				referenceDiff = DiffResult.Remove;
			}
			else if (item[property] != null && item[propertyId] != null) {
				if (item[property].id !== item[propertyId]) {
					referenceDiff = DiffResult.RemoveAndCreate;
				}
				referenceDiff = DiffResult.Update;
			}
			else if (item[property] != null && item[propertyId] == null) {
				referenceDiff = DiffResult.Create;
			}
			diff[desc.id] = referenceDiff;
		}
		return diff;
	}

	private _retriveId(response: Response) {
		return response.headers.get('Location').match(/\/([\w_-]+)$/)[1];
	}

	private _extendContextWithReferences(context?: UriContext) {
		const refs = Object.keys(this.getReferences());
		context = Utils.object.initStructure(context || {}, { query: { with: [] } });
		context['query']['with'] = Utils.array.flatten([context['query']['with'], refs]);
		return context;
	}
}
