export enum RestContextSource {
	Static,
	UriContextService
}

export interface RestContextDescriptor {
	source: RestContextSource;
}

export interface StaticRestContextDescriptor extends RestContextDescriptor {
	source: RestContextSource.Static;
	value: any;
}

export interface UriContextServiceRestContextDescriptor extends RestContextDescriptor {
	source: RestContextSource.UriContextService;
	key?: string;
	default?: any;
}

export type RestContextDelegate = {
	[uriKey: string]: any | StaticRestContextDescriptor | UriContextServiceRestContextDescriptor
};

export class RestAutorizationConfig {
	public contextDelegates: RestContextDelegate[];
	public restrictionKeys?: string[];
}
