import {Injectable} from '@angular/core';
import * as Sqrl from 'squirrelly';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('switch')
export class SwitchHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		const caseBlock = blocks.find(
			x => x.name === 'case' && x.params.includes(content.params[0])
		);
		const defaultBlock = blocks.find(x => x.name === 'default');
		return (caseBlock ?? defaultBlock ?? content).exec();
	}
}
