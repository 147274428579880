import {Injectable} from '@angular/core';
import {PrintStatement, PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from 'kn-print';
import {DataSource, Description, Model, RowItem, DescriptionBuilder, ModelBuilder, SpreadsheetBuilder, Spreadsheet, SpreadsheetColumn, SpreadsheetData} from 'kn-datagrid';
import {Utils} from 'kn-utils';

export interface SpreadsheetGroup {
	id: string;
	label: string;
	distinct: boolean;
	total: boolean;
	data: { [id: string]: string };
	values: { [id: string]: any };
}

export interface TableData {
	groups: SpreadsheetGroup[];
	classes: string[];
	headers: string[];
	headerIds: string[];
	rows: string[][];
	values: any[][];
}

export type GridCompound = {
	description: Description<RowItem>;
	model?: Model;
};

@Injectable()
@PrintStatement('tabulate')
export class TabulateHelper implements PrintRendererHelper {
	protected _exportTarget = 'print';

	public constructor(private readonly _spreadsheetBuilder: SpreadsheetBuilder) { }

	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string | Promise<string> {
		return content.exec(
			this._transform(...content.params as [any, any, ...any[]])
		);
	}

	private _transform(dataSource: DataSource<any>, compound: GridCompound, target?: string): TableData[];
	private _transform(dataSource: DataSource<any>, compound: GridCompound, model: Model, target?: string): TableData[];
	private _transform(dataSource: DataSource<any>, descriptionOrCompound: Description<RowItem> | GridCompound, modelOrTarget?: Model | string, target?: string): TableData[] {
		let description = descriptionOrCompound as Description<RowItem>;
		if (descriptionOrCompound.hasOwnProperty('description')) {
			description = (descriptionOrCompound as GridCompound)['description'];
		}
		let model: Model;
		if (Utils.isObject(modelOrTarget)) {
			model = modelOrTarget as Model;
		}
		else if (descriptionOrCompound.hasOwnProperty('model')) {
			model = (descriptionOrCompound as GridCompound)['model'];
		}
		let exportTarget = target;
		if (exportTarget == null) {
			exportTarget = Utils.isString(modelOrTarget)
				? (modelOrTarget as string)
				: this._exportTarget;
		}
		description = DescriptionBuilder.from(description);
		model = ModelBuilder.from(model, description);
		const spreadsheet = this._spreadsheetBuilder.build(
			dataSource,
			exportTarget,
			model,
			description
		);
		const columns = spreadsheet.columns.filter(x => !x.group).map(x => x.id);
		const columnClasses = columns.map(x => {
			const column = description.columns.find(y => y.id === x);
			return column == null || Utils.isFunction(column.classes)
				? ''
				: Utils.array.flatten(Utils.array.box(column.classes as (string | string[])).map(c => c.split(' ')))
					.map(c => c.trim()).filter(c => c && c.length > 0)
					.join(' ');
		});
		return this._buildTableData(spreadsheet, columnClasses);
	}

	private _buildTableData(spreadsheet: Spreadsheet, columnClasses: string[]) {
		const flatData = this._flatten(spreadsheet.data);
		const groupIds = spreadsheet.columns.filter(x => x.group).map(x => x.id);
		const headerIds = spreadsheet.columns.filter(x => !x.group).map(x => x.id);
		const headers = spreadsheet.columns.filter(x => !x.group).map(x => x.label);
		const tableData: TableData[] = [];
		let prevGroups: SpreadsheetGroup[];
		for (const data of flatData) {
			const groups = groupIds && groupIds
				.map((x, i) => this._makeGroup(spreadsheet.columns, data.groupsCells[i], data.groupsValues[i], x));
			if (groups && groups.length) {
				// last group is always total
				groups[groups.length - 1].total = true;
			}
			if (prevGroups != null) {
				let distinct = false;
				groups.forEach((g, i) => {
					if (!distinct && (prevGroups.length <= i || prevGroups[i].data[g.id] !== g.data[g.id])) {
						distinct = true;
					}
					g.distinct = distinct;
				});
				prevGroups.forEach((g, i) => g.total = groups[i].distinct);
			}
			const rows = data.rows
				.map(row => row.filter((x, i) => !spreadsheet.columns[i].group));
			const values = data.values
				.map(row => row.filter((x, i) => !spreadsheet.columns[i].group));
			tableData.push({ groups, headers, headerIds, rows, values, classes: columnClasses });
			prevGroups = groups;
		}
		prevGroups && prevGroups.forEach(g => g.total = true);
		return tableData;
	}

	private _flatten(data: SpreadsheetData[]) {
		if (data.some(x => !x.children || x.children.length === 0)) {
			return [{ groupsCells: [] as string[][], groupsValues: [] as any[][], rows: data.map(x => x.cells), values: data.map(x => x.values) }];
		}
		let acc: { groupsCells: string[][], groupsValues: any[][], rows: string[][], values: any[][] }[] = [];
		for (const item of data) {
			const flatData = this._flatten(item.children)
				.map(x => ({ groupsCells: [item.cells, ...x.groupsCells], groupsValues: [item.values, ...x.groupsValues], rows: x.rows, values: x.values }));
			acc = acc.concat(flatData);
		}
		return acc;
	}

	private _makeGroup(columns: SpreadsheetColumn[], groupsCells: string[], groupsValues: any[], id: string): SpreadsheetGroup {
		const label = columns.find(x => x.id === id).label;
		const data = groupsCells
			.reduce((acc, x, i) => Object.assign(acc, { [columns[i].id]: x }), {});
		const values = groupsValues
			.reduce((acc, x, i) => Object.assign(acc, { [columns[i].id]: x }), {});
		return { id, label, distinct: true, total: false, data, values };
	}
}
