import {CoreUtils} from './core-utils';
import {ObjectUtils} from './object-utils';
import {Sorting} from './types';

export class ArrayUtils {
	public static flatten<T>(array: T[][][] | T[][][][] | T[][][][][], shallow?: false): T[];
	public static flatten<T>(array: T[][][][][], shallow: true): T[][][][];
	public static flatten<T>(array: T[][][][], shallow: true): T[][][];
	public static flatten<T>(array: T[][][], shallow: true): T[][];
	public static flatten<T>(array: T[][], shallow?: boolean): T[];
	public static flatten<T>(array: any[], shallow?: boolean): T[] {
		return array.reduce((acc, x) => {
			const final = shallow || ![].concat(x).some(Array.isArray);
			return acc.concat(final ? x : ArrayUtils.flatten(x));
		}, []);
	}

	public static box<T>(value: T | T[]): T[] {
		return (value == null ? null : (Array.isArray(value) ? value : [value]));
	}

	public static unbox<T>(value: T[]): T | T[] {
		return value == null ? null : value.length === 1 ? value[0] : value;
	}

	public static intersect<T>(...arrays: T[][]): T[] {
		if (arrays.length < 2) {
			return arrays[0];
		}

		const result: T[] = [];
		while (arrays[0].length > 0) {
			let min = arrays[0][0];
			let max = arrays[0][0];
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 1; i < arrays.length; i++) {
				if (arrays[i].length === 0) {
					return result;
				}
				if (max < arrays[i][0]) {
					max = arrays[i][0];
				}
				else if (min > arrays[i][0]) {
					min = arrays[i][0];
				}
			}
			if (min === max) {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let i = 0; i < arrays.length; i++) {
					arrays[i].shift();
				}
				result.push(max);
			}
			else {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let i = 0; i < arrays.length; i++) {
					if (max !== arrays[i][0]) {
						arrays[i].shift();
					}
				}
			}
		}
		return result;
	}

	public static difference<T>(array: T[], ...others: T[][]): T[] {
		const result: T[] = [];
		for (const item of array) {
			let contained = false;
			for (const other of others) {
				if (other.indexOf(item) !== -1) {
					contained = true;
					break;
				}
			}
			if (!contained) {
				result.push(item);
			}
		}
		return result;
	}

	public static unique<T>(...arrays: T[][]): T[] {
		const result: T[] = [];
		for (const array of arrays) {
			for (const item of array) {
				if (result.indexOf(item) === -1) {
					result.push(item);
				}
			}
		}
		return result;
	}

	public static defaults<T extends {}, U extends {}>(dest: T[], ...src: {}[]): U[] {
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < dest.length; i++) {
			ObjectUtils.defaults(dest[i], ...src);
		}
		return dest as any[] as U[];
	}

	public static defaultsResolver<T extends {}, U extends {}>(dest: T[], resolvers: { [P in keyof T]?: { (object: T): T[P] } }): U[] {
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < dest.length; i++) {
			ObjectUtils.defaultsResolver(dest[i], resolvers as any);
		}
		return dest as any[] as U[];
	}

	public static groupBy<T extends {}>(array: T[], selector: string | ((item: T) => string)): { [key: string]: T[] };
	public static groupBy<T extends {}, U extends {}>(array: T[], selector: string | ((item: T) => string), transformer: (item: T) => U): { [key: string]: U[] };
	public static groupBy<T extends {}, U extends {}>(array: T[], selector: string | ((item: T) => string), transformer?: (item: T) => U): { [key: string]: U[] } {
		if (typeof selector === 'string') {
			const path = selector as string; // eslint-disable-line @typescript-eslint/no-unnecessary-type-assertion
			selector = item => ObjectUtils.get(item, path);
		}
		if (transformer == null) {
			transformer = x => x as any as U;
		}
		const groups: { [key: string]: U[] } = {};
		for (const item of array) {
			const key = (selector as (item: T) => string)(item);
			if (groups.hasOwnProperty(key)) {
				groups[key].push(transformer(item));
			}
			else {
				groups[key] = [transformer(item)];
			}
		}
		return groups;
	}

	public static sort<T>(array: T[], sorting: Sorting<T>): T[] {
		if (sorting == null) {
			return array;
		}
		return ArrayUtils._stableSort(array, ArrayUtils._makeComparer(sorting));
	}

	public static chunkArray<T>(array: T[], chunkSize: number) {
		const results = [];
		while (array.length) {
			results.push(array.splice(0, chunkSize));
		}
		return results;
	}

	private static _makeComparer<T>(sorting: Sorting<T>): { (a: any, b: any): number } {
		if (CoreUtils.isFunction(sorting)) {
			return sorting as (a: any, b: any) => number;
		}

		let columns: { desc: boolean, accessor: (item: T) => any }[];
		if (CoreUtils.isObject((sorting as any)[0])) {
			columns = sorting as { desc: boolean, accessor: (item: T) => any }[];
		}
		else {
			const properties = Array.isArray(sorting) ? sorting as string[] : [sorting as string];
			columns = properties.map(x => {
				let sign: string;
				if (x[0] === '+' || x[0] === '-') {
					sign = x[0];
					x = x.substr(1);
				}
				return {
					desc: sign === '-',
					accessor: (item: T): any => ObjectUtils.get(item, x)
				};
			});
		}

		return (a: any, b: any) => {
			for (const column of columns) {
				const order = column.desc ? -1 : 1;
				const aValue = column.accessor(a);
				const bValue = column.accessor(b);
				if (!isNaN(parseFloat(aValue)) && isFinite(aValue) && !isNaN(parseFloat(bValue)) && isFinite(bValue)) {
					const diff = parseFloat(aValue) - parseFloat(bValue);
					if (diff < 0) {
						return -order;
					}
					if (diff > 0) {
						return order;
					}
				}
				else {
					const aStr = aValue == null ?  '' : aValue + '';
					const bStr = bValue == null ?  '' : bValue + '';
					const cmp = aStr.localeCompare(bStr, undefined, { usage: 'sort', numeric: true, sensitivity: 'accent' });
					if (cmp !== 0) {
						return cmp > 0 ? order : -order;
					}
				}
			}
			return 0;
		};
	}

	private static _stableSort<T>(array: T[], comparer: (a: T, b: T) => number): T[] {
		const originalArray = array.slice(0);
		array.sort((a, b) => {
			const diff = comparer(a, b);
			return diff === 0 ? originalArray.indexOf(a) - originalArray.indexOf(b) : diff;
		});
		return array;
	}
}
