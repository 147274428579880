import {Injectable} from '@angular/core';
import {AbstractStorageService} from './abstract-storage.service';

@Injectable()
export class MemoryStorageService implements AbstractStorageService {
	private _storage: { [key: string]: any } = {};

	public clear(): void {
		this._storage = {};
	}

	public setItem(key: string, value: any): void {
		this._storage[key] = value;
	}

	public getItem(key: string): any {
		return this._storage[key];
	}

	public removeItem(key: string): void {
		delete this._storage[key];
	}

	public keys(): string[] {
		return Object.keys(this._storage);
	}
}
