import {AbstractControl} from '@angular/forms';
import {Utils} from 'kn-utils';
import {Validator} from '../types';

export class MinDateValidator implements Validator {
	public validate(control: AbstractControl, max: string) {
		if (control.value == null) {
			return true;
		}
		const controlDate = Utils.date.fromIso8601(control.value);
		const maxDate = Utils.date.fromIso8601(max);
		return (maxDate && maxDate.valueOf()) <= (controlDate && controlDate.valueOf());
	}
}
