import {NgModule, ModuleWithProviders, Inject, Optional, InjectionToken} from '@angular/core';
import {forRootGuardFactory} from 'kn-shared';
import {KnChartistComponent} from './chartist.component';
import {Charts} from './charts/charts';
import {BarChartFactory} from './charts/bar-chart';
import {LineChartFactory} from './charts/line-chart';
import {PieChartFactory} from './charts/pie-chart';
import {KN_CHART_FACTORIES_TOKEN} from './charts/chart-factories.token';

export const KN_CHARTIST_FORROOT_GUARD = new InjectionToken<void>('KN_CHARTIST_FORROOT_GUARD');

export const KN_CHARTIST_DIRECTIVES = [
	KnChartistComponent
];

export class ChartistOptions { }

export function provideChartFactories() {
	return [
		new BarChartFactory(),
		new LineChartFactory(),
		new PieChartFactory()
	];
}

@NgModule({
	declarations: [KN_CHARTIST_DIRECTIVES],
	exports: [KN_CHARTIST_DIRECTIVES]
})
export class KnChartistModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KN_CHARTIST_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<ChartistOptions>): ModuleWithProviders<KnChartistModule> {
		return {
			ngModule: KnChartistModule,
			providers: [
				forRootGuardFactory('KnChartistModule', KN_CHARTIST_FORROOT_GUARD, ChartistOptions),
				{
					provide: ChartistOptions, useValue: config ? config : {}
				},
				[
					{
						provide: KN_CHART_FACTORIES_TOKEN,
						multi: true,
						useFactory: provideChartFactories
					},
					Charts
				]
			]
		};
	}

	public static forChild(): ModuleWithProviders<KnChartistModule> {
		return { ngModule: KnChartistModule };
	}
}
