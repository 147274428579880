import {Component, Output, EventEmitter} from '@angular/core';

@Component({
	selector: 'kn-modal',
	template: `
	<div class="overlay" *knCollapse="isOpen" (click)="close()">
		<div class="container" (click)="$event.stopPropagation()">
			<ng-content></ng-content>
		</div>
	</div>`
})
export class KnModal {
	@Output() public closed: EventEmitter<any> = new EventEmitter();

	private _open: boolean = false;

	public get isOpen(): boolean {
		return this._open;
	}

	public set isOpen(value: boolean) {
		this._open = value;
	}

	public open(): void {
		this.isOpen = true;
	}

	public close(): void {
		this.isOpen = false;
		this.closed.emit();
	}
}
