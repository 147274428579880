import {ElementRef} from '@angular/core';
import {RippleState} from './ripple.types';

export abstract class RippleRef {
	protected _elementRef: ElementRef;
	protected _state: RippleState = RippleState.HIDDEN;

	public get elementRef() {
		return this._elementRef;
	}

	public get state() {
		return this._state;
	}

	public abstract fadeOut(): void;
}
