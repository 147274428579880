import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnCommonModule} from 'kn-common';
import {KnPortal, KnPortalOutlet} from './portal-directives';
import {KnPortalService} from './portal.service';
import {KnPortalContainer} from './portal-container.component';
import {KnAttachedPortal} from './attached-portal.component';

const KN_PORTAL_DIRECTIVES = [
	KnPortalContainer,
	KnPortal,
	KnPortalOutlet,
	KnAttachedPortal
];
const KN_PORTAL_PROVIDERS = [KnPortalService, KnPortalOutlet];

@NgModule({
	imports: [CommonModule, KnCommonModule],
	exports: [KN_PORTAL_DIRECTIVES],
	declarations: [KN_PORTAL_DIRECTIVES]
})
export class KnPortalModule {
	public static forRoot(): ModuleWithProviders<KnPortalModule> {
		return {
			ngModule: KnPortalModule,
			providers: KN_PORTAL_PROVIDERS
		};
	}
}
