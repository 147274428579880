import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';
import {forRootGuardFactory} from 'kn-shared';
import {KnOverlayModule} from 'kn-overlay';
import {KnDirectivesModule} from 'kn-directives';
import {SharedModule} from '../shared.module';
import {KnToast} from './toast.component';
import {ToastService} from './toast.service';

export const TOAST_FORROOT_GUARD = new InjectionToken<void>('TOAST_FORROOT_GUARD');

const KN_TOAST_DIRECTIVES = [
	KnToast
];

const KN_TOAST_PROVIDERS = [
	ToastService
];

@NgModule({
	imports: [CommonModule, KnOverlayModule, KnDirectivesModule, SharedModule],
	declarations: [KN_TOAST_DIRECTIVES],
	entryComponents: [KN_TOAST_DIRECTIVES],
	exports: [KN_TOAST_DIRECTIVES]
})
export class KnToastModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(TOAST_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnToastModule> {
		return {
			ngModule: KnToastModule,
			providers: [
				forRootGuardFactory('KnToastModule', TOAST_FORROOT_GUARD, KN_TOAST_PROVIDERS[0]),
				KN_TOAST_PROVIDERS
			]
		};
	}
}
