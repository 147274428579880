<kn-menu>
	<kn-menu-activator>
		<div [hidden]="isSaveMode">
			<a href="javascript:void(0)" *ngIf="selected?.name" (click)="deselectTemplate($event)"><i i18n-title title="Cancel" class="material-icons">close</i></a>
			<span [class.template-name]="selected?.name">
				<ng-container *ngIf="!selected?.name; else displayNameBlock" i18n>Select template...</ng-container>
				<ng-template #displayNameBlock>
					<i *ngIf="selected?.userUid" i18n-title title="Personal" class="material-icons">person</i>
					<i *ngIf="!selected?.userUid" i18n-title title="Global" class="material-icons">group</i>{{ selected?.name }}<i *ngIf="canAdmin() && selected?.userUid" class="ownerUid"> ({{ selected.userUid }})</i>
				</ng-template>
			</span>
			<a href="javascript:void(0)" *ngIf="!selected ? canCreate() : canSave(myUid)" (click)="enterSaveMode($event)"><i i18n-title title="Save" class="material-icons">edit</i></a>
		</div>
		<div [hidden]="!isSaveMode" *ngIf="!selected ? canCreate() : canSave(myUid)">
			<input type="text" i18n-placeholder placeholder="Save as..." #nameInput
				[value]="selected?.name || ''"
				(click)="$event.stopPropagation()"
				maxlength="40"
				(keyup.enter)="save({ id: selected?.id, name: nameInput.value, userUid: saveUserUid })">
			<a href="javascript:void(0)" (click)="save({ id: selected?.id, name: nameInput.value, userUid: saveUserUid })"><i i18n-title title="OK" class="material-icons">save</i></a>
		</div>
	</kn-menu-activator>
	<kn-menu-content [class.empty]="!visiblePreambules.length" class="kn-grid-button-content kn-preset-selector-content">
		<ul *ngIf="isSaveMode && canSave(null)">
			<li class="user-select">
				<kn-select i18n-placeholder="select-placeholder" placeholder="global" [value]="saveUserUid" (valueChange)="saveUserUid = $event" getter="uid" [hasFilter]="users && users.length > 7">
					<kn-option [value]="{ uid: null }"><em i18n>global</em></kn-option>
					<kn-option *ngFor="let user of users" [value]="user">
						{{ user.fullName }} ({{ user.uid }})
					</kn-option>
				</kn-select>
			</li>
		</ul>
		<ul>
			<ng-container *ngFor="let preambule of visiblePreambules">
				<li *ngIf="!isSaveMode || canSave(preambule.userUid)" (click)="select(preambule.id)">
					<span>
						<i *ngIf="preambule.userUid" i18n-title title="Personal" class="material-icons">person</i>
						<i *ngIf="!preambule.userUid" i18n-title title="Global" class="material-icons">group</i>
						{{ preambule.name }}<i *ngIf="canAdmin() && preambule.userUid" class="ownerUid"> ({{ preambule.userUid }})</i>
					</span>
					<a href="javascript:void(0)" *ngIf="canRemove(preambule.userUid) && !isSaveMode" (click)="remove(preambule.id); $event.stopPropagation()"><i i18n-title title="Remove" class="material-icons">delete</i></a>
				</li>
			</ng-container>
		</ul>
	</kn-menu-content>
</kn-menu>
