import {Utils} from 'kn-utils';
import {Uri} from 'kn-http';

export function resolveWebsocketScheme(scheme: string | { (uri: Uri): string }, uri: Uri) {
	let websocketScheme = scheme as string;
	if (Utils.isFunction(scheme)) {
		websocketScheme = (scheme as { (uri: Uri): string })(uri);
	}
	if (websocketScheme != null) {
		return websocketScheme;
	}
	return ((uri.scheme ? uri.scheme + ':' : window.location.protocol) === 'http:') ? 'ws' : 'wss';
}
