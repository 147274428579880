import {Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, ViewEncapsulation, ViewChild, Inject, ElementRef, forwardRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {BooleanField} from 'kn-common';
import {AbstractOptionsHost} from './abstract-options-host';
import {KnOption} from './option.component';

@Component({
	selector: 'kn-options-container',
	templateUrl: 'options-container.html',
	styleUrls: ['options-container.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnOptionsContainer {
	private readonly _disposables: Function[] = [];
	private readonly _document: Document;

	public queryValue: string;
	public isOpen: boolean = false;

	@Input() public hints: { key: string, message: string }[] = [];
	@Input() @BooleanField() public hasFilter: boolean = false;
	@Input() @BooleanField() public clearOnOpen: boolean = false;

	@ViewChild('viewport', { static: false })
	public viewport: ElementRef;

	public constructor(
			@Inject(DOCUMENT) document: any /* Document */,
			@Inject(forwardRef(() => AbstractOptionsHost)) private readonly _host: AbstractOptionsHost,
			private readonly _cdr: ChangeDetectorRef,
			private readonly _element: ElementRef) {
		this._document = document;
	}

	public ngOnInit() {
		if (this._host != null) {
			this._disposables.push(this._host.registerChild(this._element));
		}
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public open() {
		if (this._host.disabled) {
			return;
		}
		this._host.options.forEach(x => {
			x.marked = false;
		});
		this.isOpen = true;
		if (!this.clearOnOpen) {
			this._host.emitQuery(this.queryValue);
		}
		else if (this.hasFilter) {
			this.clearFilterInput();
		}
		this._cdr.markForCheck();
	}

	public close() {
		this.isOpen = false;
		this._cdr.markForCheck();
	}

	public clearFilterInput() {
		this.queryValue = '';
		this._host.emitQuery(this.queryValue);
	}

	/** @internal */
	public handleFilterInput(query: string) {
		this.queryValue = query;
		this._host.emitQuery(this.queryValue);
	}

	/** @internal */
	public handleKeyup(event: KeyboardEvent) {
		this._host.handleKeyup(event);
	}

	/** @internal */
	public handleKeydown(event: KeyboardEvent) {
		this._host.handleKeydown(event);
	}

	public scrollOptionIntoView(option: KnOption) {
		const viewportRect = this.viewport.nativeElement.getBoundingClientRect();
		const optionRect = option.getClientRect();

		if (viewportRect.top > optionRect.top) {
			this.viewport.nativeElement.scrollTop += optionRect.top - viewportRect.top;
		}
		else if (viewportRect.bottom < optionRect.bottom) {
			this.viewport.nativeElement.scrollTop += optionRect.bottom - viewportRect.bottom;
		}

		if (viewportRect.top < 0) {
			this.viewport.nativeElement.scrollIntoView(true);
		}
		else if (viewportRect.bottom > this._document.documentElement.clientHeight) {
			this.viewport.nativeElement.scrollIntoView(false);
		}
	}
}
