import {AbstractAccessControl, AbstractAccessControlFactory, AccessRestriction} from './abstract-access-control';

export class AllowAccessControl extends AbstractAccessControl {
	public constructor(private readonly _authenticated = true) {
		super();
	}

	public can(action: string, restrictions?: AccessRestriction) {
		return this._authenticated;
	}
}

export class AllowAccessControlFactory extends AbstractAccessControlFactory {
	public get name() {
		return 'allow';
	}

	public create(context: { [key: string]: any }) {
		return new AllowAccessControl(!!context.authenticated);
	}
}
