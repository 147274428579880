import {NotSupportedError} from 'kn-shared';
import {Utils} from 'kn-utils';
import {UriComponents} from './uri-components';
import {UriPath} from './uri-path';
import {UriQuery} from './uri-query';
import {UriTemplate} from './uri-template';
import {UriContext} from '../types';

export type UriAcceptedTypes = UriTemplate | Uri | UriComponents | UriPath | UriQuery | Object | string;

export class Uri {
	private _template: UriTemplate;
	private _uri: string;
	private _components: UriComponents;

	public constructor(...uri: UriAcceptedTypes[]) {
		if (uri.length > 1) {
			uri.map(x => x instanceof Uri ? x : new Uri(x)).forEach(x => this._combine(x));
		}
		else if (uri.length === 1 && uri[0] != null) {
			if (uri[0] instanceof UriTemplate) {
				this._template = uri[0] as UriTemplate;
			}
			else if (uri[0] instanceof Uri) {
				this._template = (uri[0] as Uri)._template;
				this._uri = (uri[0] as Uri)._uri;
				this._components = (uri[0] as Uri)._components;
			}
			else if (uri[0] instanceof UriComponents) {
				this._components = uri[0] as UriComponents;
			}
			else if (uri[0] instanceof UriPath) {
				this._components = new UriComponents({ path: uri[0] as UriPath });
			}
			else if (uri[0] instanceof UriQuery) {
				this._components = new UriComponents({ query: uri[0] as UriQuery });
			}
			else if (uri[0] instanceof Object) {
				this._components = new UriComponents(uri[0] as any);
			}
			else if (Utils.isString(uri[0])) {
				this._uri = uri[0] as string;
			}
			else {
				throw new NotSupportedError('Unsupported uri type.');
			}
		}
	}

	private _combine(uri: Uri) {
		if (this.template == null && uri.template != null) {
			this.template = uri.template;
		}

		if (uri.scheme != null) {
			this.scheme = uri.scheme;
		}

		let isAbsolute = false;
		if (uri.host != null) {
			this.userinfo = uri.userinfo;
			this.host = uri.host;
			this.port = uri.port;
			isAbsolute = true;
		}

		if (isAbsolute || (uri.path.isRootPath())) {
			this.path = uri.path;
		}
		else if (!uri.path.isEmpty()) {
			this.path = new UriPath(this.path, uri.path);
		}

		if (!uri.query.isEmpty()) {
			this.query = new UriQuery(this.query, uri.query);
		}

		if (isAbsolute || uri.fragment != null) {
			this.fragment = uri.fragment;
		}
	}

	public toComponents() {
		return this._getOrCreateComponents();
	}

	public toString() {
		if (this._uri == null && this._components != null) {
			this._uri = this._components.toString();
		}
		return this._uri;
	}

	private _getOrCreateComponents() {
		if (this._components == null) {
			this._components = UriComponents.parse(this._uri || '');
		}
		return this._components;
	}

	private _retriveComponent<T>(key: string): T {
		const components: any = this._getOrCreateComponents();
		return components[key];
	}

	private _updateComponent<T>(key: string, value: T) {
		const components: any = this._getOrCreateComponents();
		if (components[key] !== value) {
			components[key] = value;
			this._uri = null;
		}
	}

	public get scheme() {
		return this._retriveComponent<string>('scheme');
	}

	public set scheme(value: string) {
		this._updateComponent('scheme', value);
	}

	public get userinfo() {
		return this._retriveComponent<string>('userinfo');
	}

	public set userinfo(value: string) {
		this._updateComponent('userinfo', value);
	}

	public get host() {
		return this._retriveComponent<string>('host');
	}

	public set host(value: string) {
		this._updateComponent('host', value);
	}

	public get port() {
		return this._retriveComponent<number>('port');
	}

	public set port(value: number) {
		this._updateComponent('port', value);
	}

	public get authority() {
		return this._retriveComponent<string>('authority');
	}

	public set authority(value: string) {
		this._updateComponent('authority', value);
	}

	public get path() {
		return this._retriveComponent<UriPath>('path');
	}

	public set path(value: UriPath) {
		this._updateComponent('path', value);
	}

	public get query() {
		return this._retriveComponent<UriQuery>('query');
	}

	public set query(value: UriQuery) {
		this._updateComponent('query', value);
	}

	public get fragment() {
		return this._retriveComponent<string>('fragment');
	}

	public set fragment(value: string) {
		this._updateComponent('fragment', value);
	}

	public get template() {
		return this._template;
	}

	public set template(value: UriTemplate) {
		this._template = value;
	}

	public expand(context?: UriContext, ...uri: UriAcceptedTypes[]) {
		if (this.template != null) {
			this._combine(this.template.expand(context || {}));
		}
		uri.map(x => x instanceof Uri ? x : new Uri(x)).forEach(x => this._combine(x));
		return this;
	}

	public build(context?: UriContext, ...uri: UriAcceptedTypes[]) {
		if (this.template != null) {
			return (new Uri(this, this.template.expand(context || {}), ...uri)).toString();
		}
		return (new Uri(this, ...uri)).toString();
	}

	public static from(...uri: UriAcceptedTypes[]) {
		return new Uri(...uri);
	}

	public static build(...uri: UriAcceptedTypes[]) {
		return Uri.from(...uri).toString();
	}
}
