import {Utils} from 'kn-utils';
import {Response} from 'kn-http';
import {IndexerResolver} from './indexer-resolver';
import {Indexer} from '../../types';

export class DefaultIndexerResolver<T extends { [key: string]: any }> implements IndexerResolver<T> {
	private readonly _indexerProps: { key: string, numeric: boolean }[];

	public constructor(indexerKeys: string | string[] = '+id') {
		this._indexerProps = Utils.array.box(indexerKeys).map(x => {
			const numeric = x[0] === '+';
			const key = numeric ? x.substr(1) : x;
			return { key, numeric };
		});
	}

	public getIndexer(item: T): Indexer {
		return this._alterIndexer(this._indexerProps.map(x => item[x.key]));
	}

	public setIndexer(item: T, indexer: Indexer): void {
		this._checkIndexer(indexer);
		indexer = Utils.array.box(indexer);
		if (item != null) {
			for (let i = 0; i < this._indexerProps.length; i++) {
				(item as any)[this._indexerProps[i].key] = indexer[i];
			}
		}
	}

	public getIndexerFromResponse(response: Response): Indexer {
		const params = response.headers.get('Location').split('/');
		const shift = (params.length > 0 && params[params.length - 1] === '') ? -1 : 0;
		const end = shift + params.length;
		if (end < this._indexerProps.length) {
			return null;
		}
		return params.slice(end - this._indexerProps.length, end)
			.map((x, i) => this._indexerProps[i].numeric ? +x : x);
	}

	public getContextFromIndexer(indexer: Indexer): { [key: string]: any; } {
		this._checkIndexer(indexer);
		indexer = Utils.array.box(indexer);
		const context: { [key: string]: any; } = {};
		for (let i = 0; i < this._indexerProps.length; i++) {
			context[this._indexerProps[i].key] = `${indexer[i]}`;
		}
		return context;
	}

	private _alterIndexer(indexer: (number | string)[]): Indexer {
		if (indexer.some(x => x == null)) {
			return null;
		}
		return indexer.length > 1 ? indexer : indexer[0];
	}

	private _checkIndexer(indexer: Indexer) {
		if (indexer == null
				|| (Array.isArray(indexer) ? indexer.length : 1) !== this._indexerProps.length) {
			throw new Error('Invalid indexer');
		}
	}
}
