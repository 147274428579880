import {Injectable} from '@angular/core';
import {Router, Event, GuardsCheckStart, GuardsCheckEnd, CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Params} from '@angular/router';
import {Observable} from 'rxjs';
import {Utils} from 'kn-utils';
import {UriContext} from 'kn-http';
import {UriContextService} from './uri-context.service';

@Injectable()
export class UriContextGuard implements CanActivate, CanDeactivate<any> {
	private _rollbackContext: UriContext = null;

	public constructor(
			private readonly _router: Router,
			private readonly _uriContext: UriContextService) {
		this._router.events.subscribe(next => this._routerEventsListener(next));
	}

	public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		this._uriContext.add(this._getRouteParams(route));
		return true;
	}

	public canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		this._uriContext.remove(Object.keys(this._getRouteParams(route)));
		return true;
	}

	private _getRouteParams(route: ActivatedRouteSnapshot) {
		const params: Params = {};
		const parentParams = (route.parent && route.parent.params) || {};
		for (const key in route.params) {
			if (route.params.hasOwnProperty(key) && !parentParams.hasOwnProperty(key)) {
				params[key] = route.params[key];
			}
		}
		return params;
	}

	private _routerEventsListener(event: Event) {
		if (event instanceof GuardsCheckStart) {
			this._rollbackContext = Utils.clone(this._uriContext.context);
		}
		else if (event instanceof GuardsCheckEnd) {
			if (this._rollbackContext != null && !event.shouldActivate) {
				const rollbackKeys = Object.keys(this._rollbackContext);
				const contextKeys = Object.keys(this._uriContext.context);
				const ambientKeys = Object.keys(this._uriContext.ambientContext);
				this._uriContext.remove(Utils.array.difference(contextKeys, rollbackKeys));
				this._uriContext.add(Utils.array.difference(rollbackKeys, ambientKeys)
					.reduce((acc, x) => {
						acc[x] = this._rollbackContext[x];
						return acc;
					}, {} as UriContext));
			}
			this._rollbackContext = null;
		}
	}
}
