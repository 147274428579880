export enum DayOfWeek {
	Monday,
	Tuesday,
	Wednesday,
	Thursday,
	Friday,
	Saturday,
	Sunday
}

export interface DateTime {
	year?: number;
	month?: number;
	day?: number;
	hour?: number;
	minute?: number;
	/** In minutes. */
	timezone?: number;
}
