import {Utils} from 'kn-utils';
import {Node} from '../model/node';

export interface NodeAgg {
	weight: number;
	value: any;
}

export type PropertyAccessor<U> =  keyof U | { (item: U): any};

export class AggregatorBase {
	protected _lookupNode<T>(node: Node<T>, valueProperty: PropertyAccessor<T>, agg: (aggs: NodeAgg[]) => NodeAgg, weightProperty: PropertyAccessor<T>): NodeAgg {
		if (node.item) {
			const weight = weightProperty == null ? 1 : (
					((Utils.isString(weightProperty)
					? node.item[weightProperty as keyof T]
					: (weightProperty as (item: T) => number)(node.item)) as number) || 0);
			return {
				weight,
				value: (Utils.isString(valueProperty)
					? node.item[valueProperty as keyof T]
					: (valueProperty as (item: T) => any)(node.item)) as any
			};
		}
		return agg(node.children.map(ch => this._lookupNode(ch, valueProperty, agg, weightProperty)));
	}
}
