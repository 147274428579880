import {Injectable} from '@angular/core';
import {PrintContext, ContextConfiguration} from '../types';
import {AbstractResolutionStrategy, ResolutionStrategy, ResolutionFunctor} from './abstract-resolution-strategy';

@Injectable()
export class SequenceResolutionStrategy extends AbstractResolutionStrategy {
	public create(config: ContextConfiguration, avaibleKeys: string[]): ResolutionStrategy {
		if (config.dependencies == null) {
			return [];
		}
		return this._iterateDependences(config.dependencies, (key, template) => {
			const functor: ResolutionFunctor = (context: PrintContext) => {
				const uri = this._expandUri(template, context, config.baseUri);
				return uri == null ? {} : { [key]: uri };
			};
			return functor;
		});
	}
}
