import {Injectable} from '@angular/core';
import {webSocket as observableWebSocket} from 'rxjs/webSocket';
import {WebsocketBackend} from './websocket-backend';
import {WebsocketConfig} from '../websocket-config';
import {WebsocketSubject} from '../websocket-subject';

@Injectable()
export class BrowserWebsocketBackend extends WebsocketBackend {
	public handle(config: WebsocketConfig): WebsocketSubject {
		let binaryType: 'blob' | 'arraybuffer';
		let resultSelector: <T>(e: MessageEvent) => T;
		switch (config.responseType) {
			case '':
			case 'text':
				resultSelector = x => x.data;
				break;
			case 'json':
				resultSelector = x => JSON.parse(x.data);
				break;
			case 'blob':
			case 'arraybuffer':
				binaryType = config.responseType;
				resultSelector = x => x.data;
				break;
		}
		const socketConfig = {
			url: config.uri.toString(),
			protocol: config.protocol,
			binaryType: binaryType,
			resultSelector: resultSelector
		};
		return observableWebSocket(socketConfig);
	}
}
