import {AbstractFilterSerializer} from './abstract-filter-serializer';
import {DescriptionBuilder} from '../description-builder';
import {Model, Filter, FilterNode, FilterValue, OptionDescription, BooleanOperator} from '../types';
import {ContractDescription, ContractWithId} from '../contract.types';
import {FilterNodeUtils} from '../internal-utils';

enum LexemeType {
	Or,
	And,
	LeftBracket,
	RightBracket,
	Clause
}

type Lexeme = {
	type: LexemeType,
	clause?: { key: string, value: string }
};

export type OperatorMap = {
	operator: string,
	symbol: string,
	encode: (value: FilterValue) => string,
	decode: (value: string) => FilterValue
};

export class DefaultFilterSerializer extends AbstractFilterSerializer<string> {
	public operatorsMap: OperatorMap[] = [
		{
			operator: 'not-contains',
			symbol: '!~',
			encode: x => x,
			decode: x => x
		}, {
			operator: 'contains',
			symbol: '~',
			encode: x => x,
			decode: x => x
		}, {
			operator: 'ends-with',
			symbol: '$',
			encode: x => x,
			decode: x => x
		}, {
			operator: 'starts-with',
			symbol: '^',
			encode: x => x,
			decode: x => x
		}, {
			operator: 'ne',
			symbol: '!',
			encode: x => x,
			decode: x => x
		}, {
			operator: 'between',
			symbol: '',
			encode: x => x.join('...'),
			decode: x => {
				const parts = x.split('...').filter(part => !!part);
				return parts.length === 2 ? parts : null;
			}
		}, {
			operator: 'ge',
			symbol: '',
			encode: x => x + '...',
			decode: x => x.endsWith('...') ? x.substr(0, x.length - 3) : null
		}, {
			operator: 'le',
			symbol: '',
			encode: x => '...' + x,
			decode: x => x.startsWith('...') ? x.substr(3) : null
		}, {
			operator: 'gt',
			symbol: '',
			encode: x => x + '..',
			decode: x => x.endsWith('..') ? x.substr(0, x.length - 2) : null
		}, {
			operator: 'lt',
			symbol: '',
			encode: x => '..' + x,
			decode: x => x.startsWith('..') ? x.substr(2) : null
		}, {
			operator: 'eq',
			symbol: '',
			encode: x => x,
			decode: x => x
		}
	];

	public static from(descriptionOrOptions: ContractDescription | ContractWithId<OptionDescription<FilterValue>>[]) {
		let description = descriptionOrOptions as ContractDescription;
		if (Array.isArray(description)) {
			description = { options: description };
		}
		return new DefaultFilterSerializer(DescriptionBuilder.from(description));
	}

	public static serialize(model: Model) {
		const options = AbstractFilterSerializer._deriveOptionsFromModel(model);
		const serializer = DefaultFilterSerializer.from(options);
		return serializer.serialize(model);
	}

	public serialize(model: Model): string {
		return this._serializeGroup(model);
	}

	public deserialize(query: string): Model {
		const tokens = DefaultFilterSerializer._tokenizer(query);
		const lexemes = DefaultFilterSerializer._lexer(tokens);

		const nodes: FilterNode<FilterValue>[] = [{
			operator: BooleanOperator.And,
			children: []
		}];

		for (const lexeme of lexemes) {
			switch (lexeme.type) {
				case LexemeType.LeftBracket:
					const newNode = {
						operator: (nodes[0].operator === BooleanOperator.And) ? BooleanOperator.Or : BooleanOperator.And,
						children: []
					} as FilterNode<FilterValue>;
					nodes[0].children.push(newNode);
					nodes.unshift(newNode);
					break;

				case LexemeType.RightBracket:
					nodes.shift();
					if (nodes.length < 1) {
						throw new Error('Query filter cannot be deserialized.');
					}
					break;

				case LexemeType.Clause:
					const filter = this._decodeFilter(lexeme.clause);
					if (filter == null) {
						throw new Error('Query filter cannot be deserialized.');
					}
					nodes[0].children.push(filter);
					break;

				case LexemeType.And:
					if (nodes[0].operator !== BooleanOperator.And) {
						if (nodes[0].children.length > 1) {
							throw new Error('Query filter cannot be deserialized.');
						}
						nodes[0].operator = BooleanOperator.And;
					}
					break;

				case LexemeType.Or:
					if (nodes[0].operator !== BooleanOperator.Or) {
						if (nodes[0].children.length > 1) {
							throw new Error('Query filter cannot be deserialized.');
						}
						nodes[0].operator = BooleanOperator.Or;
					}
					break;

				default:
					throw new Error('Unexpected lexeme type.');
			}
		}

		if (nodes.length !== 1) {
			throw new Error('Query filter cannot be deserialized.');
		}

		return nodes[0];
	}

	private _serialize(node: FilterNode<FilterValue> | Filter<FilterValue>): string {
		if (FilterNodeUtils.isGroup(node)) {
			const serializedGroup = this._serializeGroup(node as FilterNode<FilterValue>);
			return serializedGroup == null ? null : '(' + serializedGroup + ')';
		}
		return this._encodeFilter(node as Filter<FilterValue>);
	}

	private _serializeGroup(node: FilterNode<FilterValue>): string {
		const separator = node.operator === BooleanOperator.And ? ',' : '|';
		const serializedChildren = node.children.map(x => this._serialize(x)).filter(x => x != null);
		return serializedChildren.length === 0 ? null : serializedChildren.join(separator);
	}

	private _encodeFilter(filter: Filter<FilterValue>): string {
		const operatorMap = this.operatorsMap.find(op => op.operator === filter.operator);
		const type = this._getOptionDescription(filter.id).type;
		const value = this._serializeValue(filter.value, type);
		return encodeURIComponent(filter.id + operatorMap.symbol)
			+ '=' + encodeURIComponent(operatorMap.encode(value));
	}

	private _decodeFilter(clause: { key: string, value: string }): Filter<FilterValue> {
		const id = clause.key;
		let value: string | string[] = null;
		for (const operatorMap of this.operatorsMap) {
			if (!id.endsWith(operatorMap.symbol)) {
				continue;
			}

			const tempolaryId = id.slice(0, id.length - operatorMap.symbol.length);
			const option = this._getOptionDescription(tempolaryId);
			if (option == null) {
				continue;
			}

			const typeSetting = this._description.typeSettings.find(x => x.id === option.type);
			if (typeSetting == null) {
				continue;
			}

			if (typeSetting.operators.findIndex(x => x.id === operatorMap.operator) === -1) {
				continue;
			}

			value = operatorMap.decode(clause.value);
			if (value != null) {
				return {
					id: tempolaryId,
					operator: operatorMap.operator,
					value: this._deserializeValue(value, option.type)
				};
			}
		}
		return null;
	}

	private static _lexer(tokens: string[]): Lexeme[] {
		const lexemes: Lexeme[] = [];
		let orCompound: boolean = null;
		for (const token of tokens) {
			switch (token) {
				case ',':
					break;

				case '|':
					orCompound = true;
					break;

				case '(':
					if (orCompound != null) {
						lexemes.push({ type: orCompound ? LexemeType.Or : LexemeType.And });
					}
					lexemes.push({ type: LexemeType.LeftBracket });
					orCompound = null;
					break;

				case ')':
					lexemes.push({ type: LexemeType.RightBracket });
					orCompound = false;
					break;

				default:
					if (orCompound != null) {
						lexemes.push({ type: orCompound ? LexemeType.Or : LexemeType.And });
					}
					lexemes.push({
						type: LexemeType.Clause,
						clause: DefaultFilterSerializer._parseClause(token)
					});
					orCompound = false;
					break;
			}
		}
		return lexemes;
	}

	private static _tokenizer(query: string): string[] {
		let offset = 0;
		const tokens: string[] = [];
		const delimiters = [',', '|', '(', ')'];
		for (let i = 0; i < query.length; i++) {
			if (delimiters.indexOf(query[i]) !== -1) {
				if (offset !== i) {
					tokens.push(query.substring(offset, i));
				}
				tokens.push(query[i]);
				offset = i + 1;
			}
		}
		if (offset !== query.length) {
			tokens.push(query.substring(offset));
		}
		return tokens;
	}

	private static _parseClause(query: string): { key: string, value: string } {
		const p = query.split('=');
		if (p.length !== 2) {
			throw new Error('Clause in query filter has invalid format.');
		}
		return { key: decodeURIComponent(p[0]), value: decodeURIComponent(p[1]) };
	}
}
