<div class="kn-radio-container"
		(click)="handleClick($event)"
		knRipple
		knRippleUnbounded
		knRippleCentered
		[knRippleDisabled]="disabled"
		[knRippleTrigger]="label"
		knRippleRadius="22">
	<input #input
			type="radio"
			[id]="inputId"
			[checked]="checked"
			[disabled]="disabled"
			[attr.name]="name"
			[attr.value]="value"
			[attr.tabIndex]="tabindex"
			(focus)="handleFocus($event)"
			(blur)="handleBlur($event)"
			(change)="handleChange($event)">

	<div class="kn-radio-box"><div class="kn-radio-mark"></div></div>
</div>

<label [attr.for]="inputId" #label>
	<ng-content select="[kn-label]"></ng-content>
</label>
