import {NgModule} from '@angular/core';
import {KnOwnershipDisplay} from './ownership-display.component';

@NgModule({
	declarations: [
		KnOwnershipDisplay
	],
	exports: [
		KnOwnershipDisplay
	]
})
export class KnOwnershipDisplayModule { }
