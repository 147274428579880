import {Directive, EventEmitter, HostListener, HostBinding, Output} from '@angular/core';

@Directive({
	selector: '[knFileDrop]'
})
export class KnFileDrop {
	@Output() public knFileDrop = new EventEmitter<FileList>();

	@HostBinding('class.kn-drag-over')
	public dragover: boolean = false;

	@HostListener('dragover', ['$event'])
	public dragOverHandler(event: DragEvent) {
		const transfer = event.dataTransfer;
		if (transfer && transfer.types && transfer.types.indexOf('Files') !== -1) {
			transfer.dropEffect = 'copy';
			this.dragover = true;
			event.preventDefault();
			event.stopPropagation();
		}
	}

	@HostListener('dragleave', ['$event'])
	public dragLeaveHandler(event: DragEvent) {
		this.dragover = false;
		event.preventDefault();
		event.stopPropagation();
	}

	@HostListener('drop', ['$event'])
	public dropHandler(event: DragEvent) {
		const transfer = event.dataTransfer;
		if (transfer) {
			transfer.dropEffect = 'copy';
			this.dragover = true;
			event.preventDefault();
			event.stopPropagation();
			this.knFileDrop.emit(transfer.files);
		}
	}
}
