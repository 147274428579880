import {Component, ChangeDetectorRef, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter} from '@angular/core';
import {DateTime} from '../../types';

@Component({
	selector: 'kn-date-view',
	templateUrl: 'date-view.html'
})
export class KnDateView implements OnInit, OnChanges {
	@Input() public date: DateTime;
	@Output() public dateChange = new EventEmitter<DateTime>();
	@Input() public viewDate: DateTime;
	@Output() public viewDateChange = new EventEmitter<DateTime>();
	@Output() public accept = new EventEmitter<DateTime>();

	public animated = true;
	public viewIndex = 0;
	public monthViews: { year?: number, month?: number, day?: number, previous?: boolean }[];

	/*@HostListener('wheel', ['$event'])
	private _wheelHandler(event: WheelEvent) {
		if (event.wheelDelta > 0) {
			this.changeHour(this.hour + (this.hour >= 23 ? -23 : 1));
		}
		else if (event.wheelDelta < 0) {
			this.changeHour(this.hour + (this.hour <= 0 ? 23 : -1));
		}
		event.preventDefault();
	}*/

	public constructor(private readonly _cdr: ChangeDetectorRef) { }

	public ngOnInit() {
		const today = new Date();
		if (this.viewDate == null) {
			this.viewDate = {
				year: this.date != null ? this.date.year : today.getFullYear(),
				month: this.date != null ? this.date.month : today.getMonth()
			};
		}
		this.monthViews = [{
			year: this.viewDate.year,
			month: this.viewDate.month,
			day: (this.date != null
					&& this.viewDate.year === this.date.year
					&& this.viewDate.month === this.date.month) ? this.date.day : null
		}, { }];
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (this.monthViews && 'viewDate' in changes) {
			this._update();
		}
	}

	public setDay(day: number) {
		this.monthViews[this.viewIndex].day = day;
		this.date = this.date || {};
		this.date.year = this.monthViews[this.viewIndex].year;
		this.date.month = this.monthViews[this.viewIndex].month;
		this.date.day = day;
		this.dateChange.emit(this.date);
	}

	public acceptDay(day: number) {
		this.setDay(day);
		this.accept.emit(this.date);
	}

	private _update() {
		const nextIndex = this.viewIndex ? 0 : 1;
		const activeView = this.monthViews[this.viewIndex];
		const nextView = this.monthViews[nextIndex];
		this.animated = false;
		nextView.year = this.viewDate.year;
		nextView.month = this.viewDate.month;
		nextView.day = (this.date != null
				&& nextView.year === this.date.year
				&& nextView.month === this.date.month) ? this.date.day : null;
		if (this.viewDate.year < activeView.year
				|| (this.viewDate.year === activeView.year
				&& this.viewDate.month < activeView.month)) {
			nextView.previous = true;
			activeView.previous = false;
		}
		else {
			nextView.previous = false;
			activeView.previous = true;
		}
		this._cdr.detectChanges();
		setTimeout(
			() => {
				this.viewIndex = nextIndex;
				this.animated = true;
				this._cdr.detectChanges();
			},
			20);
	}
}
