import {Http} from 'kn-http';
import {IndexableFetcher} from './indexable-fetcher';
import {DefaultIndexerResolver} from './indexable/default-indexer-resolver';

export class Fetcher<T extends { [key: string]: any }> extends IndexableFetcher<T> {
	public constructor(
			http: Http,
			uriTemplate: string,
			table: string,
			identifierProperties: string | string[] = '+id') {
		super(http, uriTemplate, table, new DefaultIndexerResolver(identifierProperties));
	}
}
