import {KnAuthorizationModule, Permissions} from 'kn-user';
import {RestAccessControl} from '../../services/authorization/access-control/rest-access-control';

export function provideViewTemplateCrudMapping() {
	return RestAccessControl.crud('ViewTemplate');
}

export const permissions: Permissions = [
	{
		resource: 'item',
		accessControl: 'krest',
		mapping: {
			new: [{ 'ViewTemplate': 'C' }],
			copy: [{ 'ViewTemplate': 'CR' }],
			edit: [{ 'ViewTemplate': 'U' }],
			remove: [{ 'ViewTemplate': 'D' }],
			admin: [{ 'ViewTemplate': 'C', 'Admin': 'C' }]
		}
	}, {
		resource: 'viewTemplate',
		accessControl: 'krest',
		mapping: provideViewTemplateCrudMapping
	}
];

export const authorization = KnAuthorizationModule.forChild(permissions, 'view-templates');
