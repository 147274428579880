import {Component, HostBinding, HostListener, EventEmitter, Input, Output, ChangeDetectionStrategy, NgZone} from '@angular/core';
import {AbstractDraggable} from './abstract-draggable';

@Component({
	selector: 'kn-draggable-handler',
	template: '<ng-content></ng-content>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnDraggableHandlerComponent extends AbstractDraggable {
	private _initialPosition: number[] = null;

	@Input() public minX: number;
	@Input() public minY: number;
	@Input() public maxX: number;
	@Input() public maxY: number;
	@Input() public positionX: number;
	@Input() public positionY: number;
	@Output() public positionXChange = new EventEmitter<number>();
	@Output() public positionYChange = new EventEmitter<number>();

	public constructor(private readonly _zone: NgZone) {
		super();
	}

	@HostBinding('class.dragging')
	public get dragging(): boolean {
		return super.isDragging();
	}

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public dragStartHandler(event: MouseEvent | TouchEvent) {
		if (event.type === 'mousedown' && (event as MouseEvent).button !== 0) {
			return;
		}
		super._dragStartHandler(event);
		this._initialPosition = [this.positionX || 0, this.positionY || 0];
	}

	@HostListener('document:mousemove.outside', ['$event'])
	@HostListener('document:touchmove.outside', ['$event'])
	public dragMoveHandler(event: MouseEvent | TouchEvent) {
		super._dragMoveHandler(event);
	}

	@HostListener('document:mouseup', ['$event'])
	@HostListener('document:touchend', ['$event'])
	public dragEndHandler(event: MouseEvent | TouchEvent) {
		super._dragEndHandler(event);
	}

	@HostListener('click.capture', ['$event'])
	public clickHandler(event: Event) {
		super._clickHandler(event);
	}

	protected _evaluateMove(offset: number[]) {
		const position = [
			this._clamp(this._initialPosition[0] + offset[0], +this.minX, +this.maxX),
			this._clamp(this._initialPosition[1] + offset[1], +this.minY, +this.maxY)
		];
		return [position[0] - this._initialPosition[0], position[1] - this._initialPosition[1]];
	}

	protected _applyMove(offset: number[]) {
		this._zone.run(() => {
			this.positionX = this._initialPosition[0] + offset[0];
			this.positionY = this._initialPosition[1] + offset[1];
			this.positionXChange.emit(this.positionX);
			this.positionYChange.emit(this.positionY);
		});
	}

	private _clamp(value: number, min: number, max: number) {
		if (max != null && value > max) {
			value = max;
		}
		if (min != null && value < min) {
			value = min;
		}
		return value;
	}
}
