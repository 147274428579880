import {DataSource, Description, Model, RowItem} from '../types';
import {SpreadsheetBuilder} from '../services/spreadsheet-builder.service';

export abstract class AbstractExporter {
	public description: Description<RowItem>;
	public model: Model;

	public constructor(
			protected _spreadsheetBuilder: SpreadsheetBuilder,
			protected _exportTarget: string = 'export') {
	}

	public get exportTarget() {
		return this._exportTarget;
	}

	public abstract export(dataSource: DataSource<RowItem>): Promise<Blob>;
}
