import {Injectable, Optional} from '@angular/core';
import {NotAuthorizedError} from 'kn-shared';
import {Policy} from './policy';
import {Authorization} from './authorization.type';
import {normalizePath, splitPath} from './utils';
import {AuthorizationConfig, Access} from './authorization.config';
import {AccessRestriction} from '../access-control/abstract-access-control';

@Injectable()
export class AuthorizationService implements Authorization {
	public constructor(
			private readonly _config: AuthorizationConfig,
			@Optional() private readonly _policy?: Policy) {
		if (this._policy == null) {
			this._policy = Policy.root();
		}
	}

	public get policy() {
		return this._policy;
	}

	public fork(scope: string | string[]): AuthorizationService {
		const path = normalizePath(scope);
		let policy = this._policy;
		for (const segment of path) {
			switch (segment) {
				case '/':
					policy = policy.root;
					break;
				case '.':
					break;
				case '..':
					policy = policy.parent;
					break;
				default:
					policy = policy.children.find(x => x.resource === segment);
					break;
			}
			if (policy == null) {
				throw new Error(`Invalid authorization scope ${scope}.`);
			}
		}
		return new AuthorizationService(this._config, policy);
	}

	public getPolicy(resource?: string | string[]): Policy {
		if (resource == null) {
			return this._policy;
		}

		const [path, name] = splitPath(normalizePath(resource));
		let policy = this._policy;
		for (const segment of path) {
			switch (segment) {
				case '/':
					policy = policy.root;
					break;
				case '.':
					break;
				case '..':
					policy = policy.parent || policy.root;
					break;
				default:
					policy = policy.children.find(x => x.resource === segment) || policy;
					break;
			}
			if (policy.isRoot) {
				break;
			}
		}

		if (!name) {
			return policy;
		}

		while (!policy.isRoot) {
			policy = policy.children.find(x => x.resource === name) || policy;
			if (name === policy.resource) {
				return policy;
			}
			policy = policy.parent;
		}

		return policy.children.find(x => x.resource === name);
	}

	public can(action: string, resource?: string | string[], restrictions?: AccessRestriction): boolean {
		const policy = this.getPolicy(resource);
		if (policy == null) {
			return this._config.missingPolicyStrategy === Access.Allow;
		}
		if (policy.accessControl == null) {
			return this._config.fallbackAccess === Access.Allow;
		}
		return policy.accessControl.can(action, restrictions);
	}

	public authorize(action: string, resource?: string | string[], restrictions?: AccessRestriction): void {
		if (!this.can(action, resource, restrictions)) {
			throw new NotAuthorizedError(`Access denied to "${action}" the "${resource}".`);
		}
	}

	public update(policy: Policy) {
		this._updateNode(this._policy, policy);
	}

	private _updateNode(dest: Policy, src: Policy) {
		dest.accessControl = src.accessControl;
		const children = [];
		for (const node of src.children) {
			const target = dest.children.find(x => x.resource === node.resource);
			if (target) {
				this._updateNode(target, node);
				children.push(target);
			}
			else {
				node.parent = dest;
				dest.children.push(node);
				children.push(node);
			}
		}
		dest.children = children;
	}
}
