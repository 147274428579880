import {Component, HostBinding} from '@angular/core';
import {KnExpansion} from './expansion.component';

@Component({
	selector: 'kn-expansion-content',
	template: '<ng-content></ng-content>'
})
export class KnExpansionContent {
	public constructor(private readonly _expansion: KnExpansion) { }

	@HostBinding('hidden')
	public get hidden(): boolean {
		return !this._expansion.expanded;
	}
}
