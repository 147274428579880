import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnCheckbox} from './checkbox.component';

const KN_CHECKBOX_DIRECTIVES = [
	KnCheckbox
];

@NgModule({
	imports: [CommonModule, FormsModule, KnDirectivesModule],
	declarations: [KN_CHECKBOX_DIRECTIVES],
	exports: [KN_CHECKBOX_DIRECTIVES]
})
export class KnCheckboxModule { }
