import {NgModule, Optional, SkipSelf, ModuleWithProviders, InjectionToken} from '@angular/core';
import {forRootGuardFactory} from '../helpers/for-root-guard-factory';
import {I18n} from './i18n';
import {I18N_CATALOG} from './i18n-catalog.token';
import {I18nService} from './i18n.service';

export const KN_I18N_FORROOT_GUARD = new InjectionToken<void>('KN_I18N_FORROOT_GUARD');

export class I18nOptions {
	public locale: string;
}

export function provideI18nCatalog(config: I18nOptions) {
	return I18n.get(config.locale).getCatalog();
}

@NgModule()
export class KnI18nModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnI18nModule) {
		if (parentModule) {
			throw new Error('KnI18nModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config?: Partial<I18nOptions>): ModuleWithProviders<KnI18nModule> {
		return {
			ngModule: KnI18nModule,
			providers: [
				forRootGuardFactory('KnDatagridModule', KN_I18N_FORROOT_GUARD, I18nService),
				{
					provide: I18nOptions, useValue: config ? config : {}
				}, {
					provide: I18N_CATALOG,
					useFactory: provideI18nCatalog,
					deps: [I18nOptions]
				},
				I18nService
			]
		};
	}
}
