import {ClassProvider} from '@angular/core';
import {PRINT_STATEMENT_TOKEN} from 'kn-print';

import {ConsoleFilter} from './filters/console-filter';
import {DateFilter} from './filters/date-filter';
import {DecimalFilter} from './filters/decimal-filter';
import {DecycleFilter} from './filters/decycle-filter';
import {FilterFilter} from './filters/filter-filter';
import {FindFilter} from './filters/find-filter';
import {FlattenFilter} from './filters/flatten-filter';
import {GetFilter} from './filters/get-filter';
import {JoinFilter} from './filters/join-filter';
import {TimesFilter} from './filters/times-filter';
import {TimespanFilter} from './filters/timespan-filter';
import {UniqueFilter} from './filters/unique-filter';
import {UtcFilter} from './filters/utc-filter';

import {QueryHelper} from './helpers/query-helper';
import {TabulateHelper} from './helpers/tabulate-helper';

export const COMMON_PRINT_STATEMENTS_PROVIDERS: ClassProvider[] = [
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: ConsoleFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: DateFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: DecimalFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: DecycleFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: FilterFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: FindFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: FlattenFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: GetFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: JoinFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: TimesFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: TimespanFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: UniqueFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: UtcFilter },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: QueryHelper },
	{ provide: PRINT_STATEMENT_TOKEN, multi: true, useClass: TabulateHelper }
];
