import {Uri} from '../uri/uri';
import {WebsocketConfigOptions, WebsocketResponseContentType} from './types';

export class WebsocketConfig implements WebsocketConfigOptions {
	public readonly uri: Uri;
	public readonly protocol: string | string[];
	public readonly responseType: string | WebsocketResponseContentType;

	public constructor(
			uri: string | Uri,
			options?: Partial<WebsocketConfigOptions>) {
		this.uri = Uri.from(uri);
		options = options || {};
		this.protocol = options.protocol;
		this.responseType = options.responseType || 'json';
	}
}
