import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnFormsModule} from 'kn-forms';
import {ComponentDeactivateGuard} from 'kn-modal';

import {KnAuditDisplayModule} from 'common-web/forms';
import {KnGridPanelModule, GRID_ACTIONS_TOKEN, provideDefaultGridActions} from 'common-web/grid';
import {KnGridPanelExportModule} from 'common-web/export';
import {KnDatagridModule} from 'kn-datagrid';

import {UserEditService} from './user-edit.service';
import {UsersViewComponent} from './users-view.component';
import {UserEditComponent} from './user-edit.component';
import {UsersGridData} from './users-grid-data.service';
import {UserRoleRestrictionsService} from './user-role-restrictions.service';
import {UsersViewConfig} from './users-view.config';

import {routing} from './users-view.routes';
import {authorization} from './users-view.permissions';

export class UsersViewOptions extends UsersViewConfig { }

export function provideUsersViewConfig(config: UsersViewOptions) {
	return Utils.object.defaults(config, {
		databaseUriKey: 'database'
	});
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KnCommonModule.forChild(),
		KnComponentsModule,
		KnFormsModule.forChild(),
		KnDatagridModule,
		KnGridPanelModule,
		KnGridPanelExportModule,
		KnAuditDisplayModule,
		routing,
		authorization
	],
	declarations: [
		UsersViewComponent,
		UserEditComponent
	],
	providers: [
		ComponentDeactivateGuard
	]
})
export class KnUsersViewModule {
	public static forChild(config?: Partial<UsersViewOptions>): ModuleWithProviders<KnUsersViewModule> {
		return {
			ngModule: KnUsersViewModule,
			providers: [
				{
					provide: UsersViewOptions, useValue: config ? config : {}
				}, {
					provide: UsersViewConfig,
					useFactory: provideUsersViewConfig,
					deps: [UsersViewOptions]
				}, {
					provide: GRID_ACTIONS_TOKEN,
					multi: true,
					useFactory: provideDefaultGridActions,
					deps: [I18nService]
				},
				UsersGridData,
				UserEditService,
				UserRoleRestrictionsService
			]
		};
	}
}
