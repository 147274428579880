import {Utils} from 'kn-utils';

export class Headers {
	private readonly _headers = new Map<string, string[]>();
	private readonly _normalizedNames: Map<string, string> = new Map();
	private _headerString: string;

	public constructor(headers?: string | { [name: string]: string | string[] }) {
		if (Utils.isString(headers)) {
			this._headerString = headers as string;
		}
		else {
			const headersObject = headers as { [name: string]: string | string[] };
			for (const name in headersObject) {
				if (headersObject.hasOwnProperty(name)) {
					this.set(name, headersObject[name]);
				}
			}
		}
	}

	public has(name: string): boolean {
		this._parseHeaderString();
		return this._headers.has(name.toLowerCase());
	}

	public get(name: string): string {
		this._parseHeaderString();
		const values = this._headers.get(name.toLowerCase());
		return values && values.length > 0 ? values[0] : null;
	}

	public getAll(name: string): string[] {
		this._parseHeaderString();
		return this._headers.get(name.toLowerCase()) || null;
	}

	public keys(): string[] {
		this._parseHeaderString();
		return Array.from(this._normalizedNames.values());
	}

	public append(name: string, value: string | string[]): Headers {
		this._parseHeaderString();
		const values = this.getAll(name);
		if (values === null) {
			this.set(name, value);
		}
		else {
			Utils.array.box(value).forEach(x => values.push(x));
		}
		return this;
	}

	public set(name: string, value: string | string[]): Headers {
		this._parseHeaderString();
		const key = name.toLowerCase();
		this._headers.set(key, Utils.array.box(value));
		this._normalizedNames.set(key, name);
		return this;
	}

	public delete(name: string, value?: string | string[]): Headers {
		this._parseHeaderString();
		const key = name.toLowerCase();
		this._normalizedNames.delete(key);
		this._headers.delete(key);
		return this;
	}

	private _parseHeaderString() {
		if (this._headerString == null) {
			return;
		}
		const chunks = this._headerString.trim().split(/[\r\n]+/);
		this._headerString = null;
		for (const chunk of chunks) {
			const index = chunk.indexOf(':');
			if (index > 0) {
				const name = chunk.slice(0, index);
				const value = chunk.slice(index + 1).trim();
				this.append(name, value);
			}
		}
	}
}
