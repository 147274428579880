<div class="header">
	<kn-date-header *ngIf="viewIndex === 0"
		[date]="viewDate"
		(selectPrevMonthView)="prevMonth()"
		(selectNextMonthView)="nextMonth()"></kn-date-header>
	<kn-time-header *ngIf="viewIndex === 1"
		[time]="dateTime"
		(selectView)="timeViewIndex = $event"></kn-time-header>
</div>
<div class="view">
	<kn-date-view
		*knCollapse="viewIndex === 0"
		[(date)]="dateTime"
		[(viewDate)]="viewDate"
		(accept)="acceptDate()"></kn-date-view>
	<kn-time-view
		*knCollapse="viewIndex === 1"
		[(viewIndex)]="timeViewIndex"
		[hoursInPeriod]="hoursInPeriod"
		[(time)]="dateTime"
		(accept)="acceptTime()"></kn-time-view>
</div>
