import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {KnOption} from './option.component';
import {KnOptgroup} from './optgroup.component';
import {KnOptionsContainer} from './options-container.component';
import {KnAutocomplete} from './autocomplete.directive';

const KN_OPTIONS_DIRECTIVES = [
	KnOption,
	KnOptgroup,
	KnOptionsContainer,
	KnAutocomplete
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnDirectivesModule
	],
	declarations: [KN_OPTIONS_DIRECTIVES],
	exports: [KN_OPTIONS_DIRECTIVES]
})
export class KnOptionsModule { }
