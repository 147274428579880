import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnFormsModule} from 'kn-forms';

import {ViewTemplateEditService} from './view-template-edit.service';
import {ViewTemplateEditComponent} from './view-template-edit.component';
import {ViewTemplatesViewComponent} from './view-templates-view.component';
import {ViewTemplatesGridData} from './view-templates-grid-data.service';

import {KnDatagridModule} from 'kn-datagrid';
import {KnAuditDisplayModule} from 'common-web/forms';
import {KnGridPanelModule} from 'common-web/grid';
import {KnGridPanelExportModule} from 'common-web/export';
import {KnGridPanelPrintModule} from 'common-web/print';

import {routing} from './view-templates.routes';
import {KnViewTemplatesModule} from '../../services/view-templates/view-templates.module';
import {KnGridPanelPresetModule} from '../../grid-panel/grid-panel-preset.module';
import {authorization} from './view-templates-view.permissions';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KnCommonModule.forChild(),
		KnComponentsModule,
		KnFormsModule.forChild(),
		KnViewTemplatesModule,
		KnDatagridModule,
		KnGridPanelModule,
		KnGridPanelPresetModule,
		KnGridPanelExportModule,
		KnGridPanelPrintModule,
		KnAuditDisplayModule,
		authorization,
		routing
	],
	declarations: [
		ViewTemplateEditComponent,
		ViewTemplatesViewComponent
	]
})
export class KnViewTemplatesViewModule {
	public static forChild(): ModuleWithProviders<KnViewTemplatesViewModule> {
		return {
			ngModule: KnViewTemplatesViewModule,
			providers: [
				ViewTemplatesGridData,
				ViewTemplateEditService
			]
		};
	}
}
