import {Injectable} from '@angular/core';
import {OverlayService, AbstractOverlayService} from 'kn-overlay';
import {KnToast} from './toast.component';

@Injectable()
export class ToastService extends AbstractOverlayService<KnToast> {
	private _timerId: any;

	public constructor(overlay: OverlayService) {
		super(overlay, KnToast);
	}

	public show(message: string, details ?: string, duration: number = 10000) {
		const toast = this._component.instance;
		toast.message = message;
		toast.details = details;
		if (duration) {
			if (this._timerId) {
				clearTimeout(this._timerId);
			}
			this._timerId = setTimeout(() => {
				toast.close();
				this._timerId = undefined;
			}, duration);
		}
		toast.open();
	}
}
