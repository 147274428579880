export class DefaultXhrFetcher {
	public static async fetch(uri: string, binary?: false): Promise<ArrayBuffer>;
	public static async fetch(uri: string, binary: true): Promise<string>;
	public static async fetch(uri: string, binary: boolean = false): Promise<ArrayBuffer | string> {
		return new Promise<ArrayBuffer>((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('GET', uri, true);
			request.responseType = binary ? 'arraybuffer' : 'text';
			request.onload = () => resolve(request.response);
			request.onerror = err => reject(err);
			request.send(null);
		});
	}
}
