import {Injectable, Inject, Optional} from '@angular/core';
import {CachedHttp} from 'kn-cache';
import {EntityChangeEntry} from './fetchers/entities-changes-fetcher';
import {RestService, FetcherContextWrapper, UriContextService} from 'kn-rest';
import {CHANGE_DEPENDENTS_MAP_TOKEN} from './change-dependents-map.token';

export interface DependentsMap {
	[key: string]: string[]; // tableName => [...List of tables on which depends]
}

@Injectable()
export class ChangeEntityCacheService {
	private readonly _lastChange = new Map<string, string>();

	public constructor(
			private readonly _rest: RestService,
			private readonly _uriContext: UriContextService,
			private readonly _cachedHttp: CachedHttp,
			@Inject(CHANGE_DEPENDENTS_MAP_TOKEN) @Optional() private readonly dependents?: DependentsMap) {
	}

	public invalidate(change: EntityChangeEntry) {
		this._invalidate(change);
		if (this.dependents != null) {
			for (const table in this.dependents) {
				if (this.dependents.hasOwnProperty(table) && this.dependents[table].indexOf(change.table) !== -1) {
					this._invalidate({table, id: 0, syncTime: change.syncTime, action: change.action});
				}
			}
		}
	}

	protected _invalidate(change: EntityChangeEntry) {
		const fetcher = (this._rest.for(change.table) as FetcherContextWrapper<any>)?.baseFetcher;
		const http = fetcher && ((fetcher as any).http as CachedHttp);
		const uri = fetcher && (fetcher as any).expandUri && (fetcher as any).expandUri(change.id, this._uriContext.context);
		if (!(uri && http && http.pruneUri)) {
			return;
		}
		if (change.syncTime != null) {
			if (this._lastChange.has(change.table) && this._lastChange.get(change.table) > change.syncTime) {
				return;
			}
			this._lastChange.set(change.table, change.syncTime);
		}
		http.pruneUri(uri);
	}

	public invalidateAll() {
		this._cachedHttp.pruneUriAll();
	}
}
