import {Injectable, Inject} from '@angular/core';
import * as Rx from 'rxjs/operators';
import * as Sqrl from 'squirrelly';
import {Http} from 'kn-http';
import {PRINT_HTTP_TOKEN} from '../../print-http.token';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('includeFile')
export class IncludeFileHelper implements PrintRendererHelper {
	public constructor(@Inject(PRINT_HTTP_TOKEN) private readonly _http: Http) { }

	public async exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): Promise<string> {
		if (blocks && blocks.length > 0) {
			throw new Error('Helper \'includeFile\' doesn\'t accept blocks');
		}
		const partial = await this._fetch(content.params[0]);
		const data = content.params[1];
		return Sqrl.compile(partial, config)(data, config as any);
	}

	private async _fetch(uri: string): Promise<string> {
		return this._http.get(uri, { responseType: 'text' })
			.pipe(Rx.map(next => next.body))
			.toPromise();
	}
}
