import {Component, Input, Output, OnInit, OnDestroy, EventEmitter, ChangeDetectorRef} from '@angular/core';
import * as Rx from 'rxjs/operators';
import {I18nService} from 'kn-shared';
import {Description, OptionDescription, FilterNode, Filter, FilterValue, BooleanOperator} from '../types';
import {FilterNodeUtils, ValueResolveUtils} from '../internal-utils';

export interface GroupOperator {
	operator: BooleanOperator;
	group: boolean;
}

@Component({
	selector: 'div[knFilterGroup], li[knFilterGroup]',
	templateUrl: 'filter-group.html'
})
export class KnFilterGroup implements OnInit, OnDestroy {
	private readonly _disposables: Function[] = [];
	public selectedFilter: OptionDescription<FilterValue> = null;
	public operator: GroupOperator = { operator: BooleanOperator.And, group: false };
	public loading = false;

	@Input() public description: Description;
	@Input() public node: FilterNode<FilterValue>;
	@Output() public remove = new EventEmitter<FilterNode<FilterValue>>();

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _cdr: ChangeDetectorRef) {
	}

	public ngOnInit() {
		if (this.node != null) {
			this.operator.operator = this.node.operator;
			this.operator.group = this.node.children && this.node.children.length > 0 && this.node.children[0].hasOwnProperty('children');
		}
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public isGroup = FilterNodeUtils.isGroup;

	public getLabel(option: OptionDescription<FilterValue>): string {
		return ValueResolveUtils.resolveLabel(option);
	}

	public getOperatorName(operator: BooleanOperator) {
		return operator === BooleanOperator.Or ? this._i18n.t('or') : this._i18n.t('and');
	}

	public toggleNewFilterCoalescing() {
		if (this._isOperatorChangable(this.node)) {
			this.operator.operator = this.operator.operator === BooleanOperator.And ? BooleanOperator.Or : BooleanOperator.And;
		}
		if (this.operator.operator === BooleanOperator.And || !this._isOperatorChangable(this.node)) {
			this.operator.group = !this.operator.group;
		}
	}

	public getNewFilterCoalescingName() {
		// if (!this._isOperatorChangable(this.node) && this.node.operator !== this.operator) {
		// 	return this.node.operator === BooleanOperator.Or ? this._i18n.t('or group') : this._i18n.t('and group');
		// }
		// return this.operator === BooleanOperator.Or ? this._i18n.t('or') : this._i18n.t('and');
		if (this.operator.group) {
			return this.operator.operator === BooleanOperator.Or ? this._i18n.t('or group') : this._i18n.t('and group');
		}
		return this.operator.operator === BooleanOperator.Or ? this._i18n.t('or') : this._i18n.t('and');
	}

	public add(item: OptionDescription<FilterValue>) {
		this.loading = true;
		const defaults = ValueResolveUtils.resolveDefaults(item);
		const operator = this.description.typeSettings
			.find(typeSetting => typeSetting.id === item.type)
			.operators.find(x => x.id === defaults.operator);

		const subscription = ValueResolveUtils.resolveDefaultsValue(defaults, item)
			.pipe(
				Rx.map(next => ({
					id: item.id,
					operator: operator.id,
					value: next,
					fixed: defaults.fixed,
					disabled: defaults.disabled
				} as Filter<FilterValue>))
			)
			.subscribe(next => {
				this._addFilter(next);
				this.loading = false;
			});

		this._disposables.push(() => subscription.unsubscribe());
	}

	public removeChild(item: FilterNode<FilterValue> | Filter<FilterValue>) {
		const index = this.node.children.indexOf(item);
		if (index !== -1) {
			this.node.children.splice(index, 1);
			if (this.node.children.length === 0) {
				this.remove.emit(this.node);
			}
		}
	}

	private _addFilter(filter: Filter<FilterValue>) {
		if (this.node.children.length === 0) {
			this.node.operator = BooleanOperator.And;
			this.node.children.push(filter);
		}
		else if (this.operator.group) {
			this.node.operator = this.operator.operator;
			this.node.children.push({
				operator: this.operator.operator === BooleanOperator.And ? BooleanOperator.Or : BooleanOperator.And,
				children: [filter]
			});
		}
		else {
			this.node.operator = this.operator.operator;
			this.node.children.push(filter);
		}

		this._cdr.detectChanges();
		this.selectedFilter = null;
		this.operator.operator = this.node.operator;
	}

	private _isOperatorChangable(node: FilterNode<FilterValue>): boolean {
		return this.node.children.length <= 1 && !this._isFixed(this.node);
	}

	private _isFixed(node: FilterNode<FilterValue> | Filter<FilterValue>): boolean {
		if (FilterNodeUtils.isGroup(node)) {
			for (const child of (node as FilterNode<FilterValue>).children) {
				if (this._isFixed(child)) {
					return true;
				}
			}
			return false;
		}
		return (node as Filter<FilterValue>).fixed;
	}
}
