import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {ConnectionsResourceService} from './resources/connections-resource.service';
import {ConnectorSettingsResourceService} from './resources/connector-settings-resource.service';
import {DeviceLogRecordsResourceService} from './resources/device-log-records-resource.service';
import {DevicesResourceService} from './resources/devices-resource.service';
import {PeopleCounterRecordsResourceService} from './resources/people-counter-records-resource.service';
import {ReportRecordsResourceService} from './resources/report-records-resource.service';
import {ReportSpecificationsResourceService} from './resources/report-specifications-resource.service';
import {ReportsResourceService} from './resources/reports-resource.service';
import {ServiceRecordsResourceService} from './resources/service-records-resource.service';
import {SitesResourceService} from './resources/sites-resource.service';
import {SyncTrackersResourceService} from './resources/sync-trackers-resource.service';

@NgModule()
export class AppRestResourcesModule {
	public constructor(@Optional() @SkipSelf() parentModule: AppRestResourcesModule) {
		if (parentModule) {
			throw new Error('AppRestResourcesModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(): ModuleWithProviders<AppRestResourcesModule> {
		return {
			ngModule: AppRestResourcesModule,
			providers: [
				ConnectorSettingsResourceService,
				ConnectionsResourceService,
				DevicesResourceService,
				ServiceRecordsResourceService,
				SitesResourceService,
				DeviceLogRecordsResourceService,
				PeopleCounterRecordsResourceService,
				SyncTrackersResourceService,
				ReportsResourceService,
				ReportSpecificationsResourceService,
				ReportRecordsResourceService
			]
		};
	}
}
