import {Component, Input} from '@angular/core';
import {DatagridBond} from 'kn-datagrid';

@Component({
	selector: 'kn-grid-exporter',
	template: `
		<kn-export-button
				[view]="view"
				[description]="bond.description"
				[model]="bond.model"
				[dataSource]="bond.dataSource"
				[filter]="filter"></kn-export-button>`
})
export class KnGridExporter {
	@Input() public view: string;
	@Input() public bond: DatagridBond<any>;
	@Input() public filter: string;
}
