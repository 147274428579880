<kn-sticky>
	<div class="form-container heading">
		<a href="javascript:void(0)" class="form-back" (click)="navigateBack()"><i class="material-icons">arrow_back</i></a>
		<div class="content">
			<h1>
				<ng-container *ngIf="isEditMode()" i18n>Edit view template {{ name }} ({{ viewName }})</ng-container>
				<ng-container *ngIf="!isEditMode()" i18n>New view template</ng-container>
			</h1>
			<div *ngIf="outdated" class="form-actions-info warning">
				<i class="material-icons">warning</i> <ng-container i18n>Editing outdated copy</ng-container>
			</div>
			<div class="form-buttons">
				<button type="submit" *ngIf="savable" [disabled]="!form.valid" form="main-form">
					<ng-container *ngIf="isEditMode()" i18n>Save</ng-container>
					<ng-container *ngIf="!isEditMode()" i18n>Create</ng-container>
				</button>
			</div>
		</div>
	</div>
</kn-sticky>

<div class="form-container">
	<form id="main-form" (ngSubmit)="save()" [formGroup]="form" class="form-layout">

		<div class="form-grid">
			<div class="form-box" formGroupName="viewTemplate">
				<div class="content">
					<div class="row">
						<div class="form-input flex-1">
							<kn-select i18n-placeholder="select-placeholder" placeholder="Select ..." formControlName="view" getter="value" required [hasFilter]="views && views.length > 7">
								<kn-option *ngFor="let view of views" [value]="view">
									{{ view.label }}
								</kn-option>
								<span kn-label i18n>View</span>
								<kn-control-messages kn-hint control="view"></kn-control-messages>
							</kn-select>
						</div>

						<div class="form-input flex-3">
							<kn-input type="text" formControlName="name" required>
								<span kn-label i18n>Name</span>
								<kn-control-messages kn-hint control="name"></kn-control-messages>
							</kn-input>
						</div>
					</div>

					<div class="row">
						<div class="form-input flex-1">
							<kn-select i18n-placeholder="select-placeholder" placeholder="Unassigned" formControlName="userUid" getter="uid" [hasFilter]="users && users.length > 7">
								<kn-option><em i18n>Unassigned</em></kn-option>
								<kn-option *ngFor="let user of users" [value]="user">
									{{ user.fullName }} ({{ user.uid }})
								</kn-option>
								<span kn-label i18n>Owner</span>
								<kn-control-messages kn-hint control="userUid"></kn-control-messages>
							</kn-select>
						</div>

						<div class="flex-1">
						</div>

						<div class="form-input flex-1 flex-middle">
							<kn-checkbox formControlName="hidden">
								<span kn-label i18n>Hidden</span>
							</kn-checkbox>
						</div>

						<div class="form-input flex-1">
							<kn-input type="text" formControlName="version">
								<span kn-label i18n>Version</span>
								<kn-control-messages kn-hint control="version"></kn-control-messages>
							</kn-input>
						</div>
					</div>
				</div>
			</div>

			<div class="form-box" formGroupName="viewTemplate">
				<div class="content">
					<div class="form-input">
						<kn-textarea formControlName="value" class="template-value">
								<span kn-label i18n>Value</span>
								<kn-control-messages kn-hint control="value"></kn-control-messages>
						</kn-textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="form-footer">
			<kn-audit-display [audit]="audit"></kn-audit-display>
		</div>

	</form>
</div>
