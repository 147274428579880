import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {AbstractExporter} from './abstract-exporter';
import {SpreadsheetBuilder, Spreadsheet, SpreadsheetData} from '../services/spreadsheet-builder.service';
import {DataSource, RowItem} from '../types';

@Injectable()
export class CsvExporter extends AbstractExporter {
	public delimiter: string = ';';
	public quote: string = '"';
	public lineTerminator: string = '\n';

	public constructor(spreadsheetBuilder: SpreadsheetBuilder) {
		super(spreadsheetBuilder, 'export:csv');
	}

	public async export(dataSource: DataSource<RowItem>): Promise<Blob> {
		const csv = this._toCsv(this._spreadsheetBuilder.build(dataSource, this.exportTarget, this.model, this.description));
		return new Blob([csv], { type: 'text/csv; charset=utf-8' });
	}

	private _toCsv(spreadsheet: Spreadsheet): string {
		const lines: string[] = [];
		const header = spreadsheet.columns.map(column => this._sanityCell(column.label));
		lines.push(header.join(this.delimiter));
		for (const row of this._getObjectTreeData(spreadsheet.data)) {
			lines.push(row.map(cell => this._sanityCell(cell)).join(this.delimiter));
		}
		return lines.join(this.lineTerminator);
	}

	private _getObjectTreeData(objectTreeData: SpreadsheetData[]): string[][] {
		const result: string[][] = new Array();
		objectTreeData.forEach(x => {
				if (x.children && x.children.length > 0) {
					const child = x.children[0];
					if (child && child.children && child.children.length > 0) {
						this._getObjectTreeData(x.children).forEach(r => result.push(r));
						return;
					}
				}
				result.push(x.cells);
			}
		);
		return result;
	}

	private _sanityCell(value: any): string {
		const specialCharacters = [
			this.delimiter, this.quote, this.lineTerminator, '\r', '\n'
		].map(ch => Utils.escapeRegExp(ch)).join();

		const re = new RegExp('[' + specialCharacters + ']');

		value = (value == null) ? '' : `${value}`;
		const escape = !value.length || re.test(value);

		value = value.replace(this.quote, this.quote + this.quote);
		return escape ? this.quote + value + this.quote : value;
	}
}
