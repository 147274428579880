import {NgModule} from '@angular/core';
import {KnRouterPrefixDirective} from './router-prefix.directive';

export const KN_DIRECTIVES = [
	KnRouterPrefixDirective
];

@NgModule({
	declarations: [KN_DIRECTIVES],
	exports: [KN_DIRECTIVES]
})
export class KnSharedDirectivesModule { }
