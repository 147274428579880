import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {KnDirectivesModule} from 'kn-directives';
import {KnComponentsModule} from 'kn-components';
import {KnAuthorizationModule} from 'kn-user';
import {KnNavbar} from './navbar.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		KnDirectivesModule,
		KnComponentsModule,
		KnAuthorizationModule
	],
	declarations: [
		KnNavbar
	],
	exports: [
		KnNavbar
	]
})
export class KnNavbarModule { }
