import {Injectable, Inject, InjectionToken} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs';
import {HttpHandler} from './http-handler';
import {Request} from '../request';
import {Response} from '../response';

export const XSRF_COOKIE_NAME = new InjectionToken<string>('XSRF_COOKIE_NAME');
export const XSRF_HEADER_NAME = new InjectionToken<string>('XSRF_HEADER_NAME');

@Injectable()
export class HttpXsrfInterceptor implements HttpHandler {
	public constructor(
			@Inject(DOCUMENT) private readonly _doc: any /* Document */,
			@Inject(XSRF_COOKIE_NAME) private readonly _cookieName: string,
			@Inject(XSRF_HEADER_NAME) private readonly _headerName: string) {
	}

	public handle(request: Request, next: HttpHandler): Observable<Response> {
		const nonMutatingRequest = ['get', 'head'].indexOf(request.method.toLowerCase()) !== -1;
		const absoluteUrl = ['http', 'https'].indexOf(request.uri.scheme) !== -1;
		if (!nonMutatingRequest && !absoluteUrl) {
			this._setHeader(request, this._getToken());
		}
		return next.handle(request);
	}

	private _getToken(): string {
		const cookieString = (this._doc as Document).cookie || '';
		return cookieString.split('; ').reduce((acc, x) => {
			const parts = x.split('=');
			return parts[0] === this._cookieName ? decodeURIComponent(parts[1]) : acc;
		}, '');
	}

	private _setHeader(request: Request, token: string) {
		if (token !== null && !request.headers.has(this._headerName)) {
			request.headers.set(this._headerName, token);
		}
	}
}
