import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('groupby')
export class GroupByHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		return content.exec(this._transform(content.params[0], content.params[1]));
	}

	private _transform<T, U>(array: T[], selector: string): { key: U, value: T[] }[] {
		const groups: { key: U, value: T[] }[] = [];
		for (const item of array) {
			const key = Utils.object.get(item, selector);
			const group = groups.find(x => Utils.equal(x.key, key));
			if (group != null) {
				group.value.push(item);
			}
			else {
				groups.push({ key, value: [item] });
			}
		}
		return groups;
	}
}
