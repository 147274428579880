import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {PrintContext} from 'kn-print';

const printContextCaptureQuery = '*print-context-capture';
const contextChannelProperty = '_$$printContextChannel';

@Injectable()
export class PrintContextCaptureService implements OnDestroy {
	private readonly _subscriptions: Subscription;
	private _view: string;

	public context: PrintContext | { (): PrintContext };

	public constructor(route: ActivatedRoute) {
		this._subscriptions = route.queryParams
			.pipe(Rx.filter(next => next.hasOwnProperty(printContextCaptureQuery)))
			.subscribe(next => this._view = next[printContextCaptureQuery]);
	}

	public ngOnDestroy() {
		this.exitCaptureMode();
	}

	public isCaptureMode() {
		return this._view != null;
	}

	public exitCaptureMode() {
		this._subscriptions && this._subscriptions.unsubscribe();
		this._view = null;
	}

	public get view() {
		return this._view;
	}

	public isChannelActive() {
		return window.opener != null
			&& !(window.opener as Window).closed
			&& (window.opener as Window).hasOwnProperty(contextChannelProperty)
			&& window.opener[contextChannelProperty].view === this._view;
	}

	public capture(context?: PrintContext | { (): PrintContext }) {
		if (context != null) {
			this.context = context;
		}
		else {
			context = this.context;
		}
		if (!this.isCaptureMode() || !this.isChannelActive()) {
			return false;
		}
		if (Utils.isFunction(this.context)) {
			context = (this.context as () => PrintContext)();
		}
		window.opener[contextChannelProperty].sendContext(context);
		return true;
	}
}
