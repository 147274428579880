import {SandboxedDocument} from './sandboxed-document';
import {CssLengthParser} from './parsers/css-length-parser';
import {CssFontParser} from './parsers/css-font-parser';
import {CssFontFace} from './css-font.type';

export class DocumentQueries {
	public static async getPageSize(doc: SandboxedDocument, fetcher?: (uri: string, binary: boolean) => Promise<ArrayBuffer | string>) {
		const styleNodes: HTMLStyleElement[] = [];
		const linkNodes: HTMLLinkElement[] = [];

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < doc.nativeDocument.styleSheets.length; i++) {
			const cssRules = (doc.nativeDocument.styleSheets[i] as CSSStyleSheet).cssRules;
			for (let j = 0; cssRules && j < cssRules.length; j++) {
				if (cssRules[j].type === CSSRule.PAGE_RULE) {
					const pageRule = cssRules[j] as CSSPageRule;
					const sizeRule = pageRule.style.getPropertyValue('size');
					if (sizeRule) {
						return DocumentQueries._parseSizeRule(sizeRule);
					}
					else {
						const node = pageRule.parentStyleSheet.ownerNode;
						switch (node && node.nodeName.toLowerCase()) {
							case 'link':
								linkNodes.push(node as HTMLLinkElement);
								break;
							case 'style':
								styleNodes.push(node as HTMLStyleElement);
								break;
						}
					}
				}
			}
		}

		for (const styleNode of styleNodes) {
			const content = styleNode.textContent;
			const sizeRule = this._getSizeRuleFromStyleContent(content);
			if (sizeRule) {
				return DocumentQueries._parseSizeRule(sizeRule);
			}
		}

		if (fetcher) {
			for (const linkNode of linkNodes) {
				const content = await fetcher(linkNode.href, false) as string;
				const sizeRule = this._getSizeRuleFromStyleContent(content);
				if (sizeRule) {
					return DocumentQueries._parseSizeRule(sizeRule);
				}
			}
		}

		return DocumentQueries._parseSizeRule();
	}

	public static async getFontFaces(doc: SandboxedDocument) {
		const fontFaces: { uri: string, fontFace: CssFontFace }[] = [];
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < doc.nativeDocument.styleSheets.length; i++) {
			const cssRules = (doc.nativeDocument.styleSheets[i] as CSSStyleSheet).cssRules;
			for (let j = 0; cssRules && j < cssRules.length; j++) {
				if (cssRules[j].type === CSSRule.FONT_FACE_RULE) {
					const fontFaceRule = cssRules[j] as CSSFontFaceRule;
					const uri = fontFaceRule.style.getPropertyValue('src').match(/url\(["']?(.+?)["']?\)/);
					fontFaces.push({
						uri: uri[1],
						fontFace: {
							fontFamily: CssFontParser.parseFontFamily(
								fontFaceRule.style.getPropertyValue('font-family')),
							fontStyle: CssFontParser.parseFontStyle(
								fontFaceRule.style.getPropertyValue('font-style')),
							fontVariant: CssFontParser.parseFontVariant(
								fontFaceRule.style.getPropertyValue('font-variant')),
							fontWeight: CssFontParser.parseFontWeight(
								fontFaceRule.style.getPropertyValue('font-weight')),
							fontStretch: CssFontParser.parseFontStretch(
								fontFaceRule.style.getPropertyValue('font-stretch'))
						}
					});
				}
			}
		}
		return fontFaces;
	}

	private static _getSizeRuleFromStyleContent(style: string) {
		const mx = style.match(/@page\s*\{[\s\S]*?size:\s*([^;]+)/);
		return mx && mx[1];
	}

	// eslint-disable-next-line complexity
	private static _parseSizeRule(sizeRule?: string) {
		const sizeQualifiers = {
			orientation: 'auto',
			size: [] as string[]
		};

		if (sizeRule != null) {
			for (const part of sizeRule.split(' ')) {
				switch (part.toLowerCase()) {
					case 'auto':
					case 'portrait':
					case 'landscape':
						sizeQualifiers.orientation = part;
						break;

					case 'a5':
						sizeQualifiers.size = ['148mm', '210mm'];
						break;
					case 'a4':
						sizeQualifiers.size = ['210mm', '297mm'];
						break;
					case 'a3':
						sizeQualifiers.size = ['297mm', '420mm'];
						break;
					case 'b5':
						sizeQualifiers.size = ['176mm', '250mm'];
						break;
					case 'b4':
						sizeQualifiers.size = ['250mm', '353mm'];
						break;
					case 'jis-b5':
						sizeQualifiers.size = ['182mm', '257mm'];
						break;
					case 'jis-b4':
						sizeQualifiers.size = ['257mm', '364mm'];
						break;
					case 'letter':
						sizeQualifiers.size = ['8.5in', '11in'];
						break;
					case 'legal':
						sizeQualifiers.size = ['8.5in', '14in'];
						break;
					case 'ledger':
						sizeQualifiers.size = ['11in', '17in'];
						break;

					default:
						sizeQualifiers.size.push(part);
						break;
				}
			}
		}

		switch (sizeQualifiers.size.length) {
			case 0:
				sizeQualifiers.size = ['210mm', '297mm'];
				break;
			case 1:
				sizeQualifiers.size[1] = sizeQualifiers.size[0];
				break;
			case 2:
				break;
			default:
				throw new Error('Invalid @page size rule.');
		}

		const size = sizeQualifiers.size.map(x => CssLengthParser.parse(x));

		switch (sizeQualifiers.orientation) {
			case 'portrait':
				return size[0] <= size[1] ? size : [size[1], size[0]];
			case 'landscape':
				return size[0] >= size[1] ? size : [size[1], size[0]];
			case 'auto':
				return size;
		}
		throw new Error('Unsupported @page size orientation.');
	}
}
