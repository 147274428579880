import {Component, ComponentRef, SimpleChanges} from '@angular/core';
import {CellContext} from 'kn-datagrid';
import {DefaultTarget} from '../../lookup-component-renderer-factory';
import {ActionCommand} from '../../../abstract-grid-view.component';
import {Context} from '../../../types';
import {InGridAction, InGridActionCallable} from './in-grid-action.type';

@Component({
	selector: 'kn-app-in-grid-actions',
	templateUrl: 'in-grid-actions.html'
})
export class KnInGridActions<T> implements DefaultTarget {
	private _gridContext: Context<T>;
	private _cellContext: CellContext<T> = null;

	public actions: InGridAction<any>[];

	public get item(): T {
		return this._cellContext.node.item;
	}

	public get isLeaf() {
		return this._cellContext && this._cellContext.node && this._cellContext.node.isLeaf();
	}

	public static mapping<T>(gridContext: Context<T>, actions: InGridAction<any>[]) {
		return (component: ComponentRef<KnInGridActions<T>>, value: T, context: CellContext<T>): SimpleChanges => {
			component.instance._gridContext = gridContext;
			component.instance._cellContext = context;
			component.instance.actions = actions;
			return {};
		};
	}

	public execute(event: Event, command: ActionCommand<T>) {
		if (this._gridContext && this._gridContext.executeCommand) {
			this._gridContext.executeCommand(command);
			event.stopPropagation();
		}
	}

	public evaluate<U>(callable: InGridActionCallable<T, U>): U {
		return callable(this.item);
	}

	public renderDefaultTarget() {
		return '';
	}
}
