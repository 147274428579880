import {NgModule, Optional, SkipSelf, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Router} from '@angular/router';
import {KN_AUTH_SERVICE_TOKEN} from 'kn-user';
import {Http} from 'kn-http';
import {AbstractStorageService, LocalStorageService} from 'kn-storage';
import {JwtAuth, JwtAuthConfig} from './jwt-auth.service';
import {JwtAuthStore, JwtAuthStoreConfig} from './jwt-auth-store.service';
import {JwtAuthHttp, JwtAuthHttpConfig} from './jwt-auth-http.service';
import {JwtAuthGuard, JwtAuthGuardConfig} from './jwt-auth.guard';
import {AbstractRenewStrategy} from './renew-strategies/abstract-renew-strategy';
import {DefaultRenewStrategy} from './renew-strategies/default-renew-strategy';

export class JwtAuthOptions {
	public auth: JwtAuthConfig;
	public http: JwtAuthHttpConfig;
	public guard: JwtAuthGuardConfig;
	public store: JwtAuthStoreConfig;
}

export function provideJwtAuthStore(backend: AbstractStorageService, config: JwtAuthOptions) {
	return new JwtAuthStore(backend, config.store);
}

export function provideJwtAuthHttp(http: Http, store: JwtAuthStore, config: JwtAuthOptions) {
	return new JwtAuthHttp(http, store, config.http);
}

export function provideJwtAuth(http: JwtAuthHttp, store: JwtAuthStore, renewStrategy: AbstractRenewStrategy, config: JwtAuthOptions) {
	return new JwtAuth(http, store, renewStrategy, config.auth);
}

export function provideJwtAuthGuard(auth: JwtAuth, router: Router, config: JwtAuthOptions) {
	return new JwtAuthGuard(auth, router, config.guard);
}

export const JWT_AUTH_HTTP_TOKEN = new InjectionToken<Http>('JwtAuthHttpToken');
export const JWT_AUTH_STORAGE_TOKEN = new InjectionToken<AbstractStorageService>('JwtAuthStorageToken');

// Add dependences RouterModule, KnStorageModule, ...?

@NgModule()
export class KnJwtAuthModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnJwtAuthModule) {
		if (parentModule) {
			throw new Error('KnJwtAuthModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(config?: Partial<JwtAuthOptions>): ModuleWithProviders<KnJwtAuthModule> {
		return {
			ngModule: KnJwtAuthModule,
			providers: [
				{
					provide: JwtAuthOptions, useValue: config ? config : {}
				}, {
					provide: JWT_AUTH_HTTP_TOKEN, useExisting: Http
				}, {
					provide: JWT_AUTH_STORAGE_TOKEN, useClass: LocalStorageService
				}, {
					provide: AbstractRenewStrategy, useClass: DefaultRenewStrategy
				}, {
					provide: JwtAuthStore,
					useFactory: provideJwtAuthStore,
					deps: [JWT_AUTH_STORAGE_TOKEN, JwtAuthOptions]
				}, {
					provide: JwtAuthHttp,
					useFactory: provideJwtAuthHttp,
					deps: [JWT_AUTH_HTTP_TOKEN, JwtAuthStore, JwtAuthOptions]
				}, {
					provide: JwtAuth,
					useFactory: provideJwtAuth,
					deps: [JwtAuthHttp, JwtAuthStore, AbstractRenewStrategy, JwtAuthOptions]
				}, {
					provide: JwtAuthGuard,
					useFactory: provideJwtAuthGuard,
					deps: [JwtAuth, Router, JwtAuthOptions]
				}, {
					provide: KN_AUTH_SERVICE_TOKEN,
					useExisting: JwtAuth
				}
			]
		};
	}

	public static forChild(): ModuleWithProviders<KnJwtAuthModule> {
		return { ngModule: KnJwtAuthModule };
	}
}
