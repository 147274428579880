import {Component, Input, Injector, HostBinding} from '@angular/core';
import {I18nService} from 'kn-shared';
import {ConfirmationService} from 'kn-modal';
import {GridBonding} from '../../grid-bonding';

@Component({
	selector: 'kn-grid-reset',
	template: '<a href="javascript:void(0)" [ngClass]="{\'disabled\': isInitial}" i18n-title="reset-grid" title="Reset grid" (click)="!isInitial && reset();"><i class="material-icons">delete_sweep</i></a>'
})
export class KnGridReset {
	protected _i18n: I18nService;
	protected _confirmation: ConfirmationService;
	protected _injector: Injector;

	@Input() public bond: GridBonding<any>;

	@HostBinding('class.disabled')
	public get isInitial() {
		return this.bond && this.bond.datagrid && this.bond.datagrid.isInitial;
	}

	public constructor(
		injector: Injector) {
		this._injector = injector;
		this._i18n = injector.get(I18nService);
		this._confirmation = injector.get(ConfirmationService);
	}

	public async reset(confirm: boolean = true) {
		if (!confirm || confirm && await this._confirmReset()) {
			this.bond && this.bond.datagrid && this.bond.datagrid.setInitial();
		}
	}

	protected async _confirmReset() {
		const result = await this._confirmation.show(
			this._i18n.t('Reset datagrid?'),
			this._i18n.t('Do you really want to set layout of the table to default state?'), [
				{ name: this._i18n.t('Yes'), classes: ['danger'], result: 'yes' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'yes';
	}
}
