import {Component, HostListener} from '@angular/core';
import {KnMenu} from './menu.component';

@Component({
	selector: 'kn-menu-activator',
	template: '<ng-content></ng-content>',
	host: { tabindex: '0' }
})
export class KnMenuActivator {
	public constructor(private readonly _menu: KnMenu) { }

	@HostListener('click', ['$event'])
	@HostListener('keyup.enter', ['$event'])
	public onActivate(event: Event) {
		this._menu.expanded = !this._menu.expanded;
		event.stopPropagation();
	}
}
