import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('json')
export class JsonFilter implements PrintRendererFilter {
	public transform(value: any): string {
		return JSON.stringify(value, null, '\t');
	}
}
