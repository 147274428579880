import {Injectable} from '@angular/core';
import {Node} from '../model/node';
import {GroupIdsCache} from './group-ids-cache.service';
import {RowsVisibility} from './rows-visibility.service';
import {RowItem} from '../types';

@Injectable()
export class RowsTreeMarkClass {
	public key: string = 'treeMarkClasses';

	public constructor(
			private readonly _rowsVisibility: RowsVisibility,
			private readonly _groupIdsCache: GroupIdsCache) {
	}

	public getForNode(node: Node<RowItem>): { [key: string]: string } {
		return node.cache.get(this.key);
	}

	public calculate(node: Node<RowItem>): RowsTreeMarkClass {
		if (!node.cache.has(this.key)) {
			this._calculate(node);
		}
		return this;
	}

	private _calculate(node: Node<RowItem>) {
		const classes: { [key: string]: string } = {};
		const branch = node.getBranch();
		const groups = this._groupIdsCache.getForNode(node);
		let index = 0;

		while (index < groups.length) {
			const key = groups[index++];
			if (branch.length - index === 0) {
				classes[key] = 'tree-mark-source';
				break;
			}
			else if (branch.length - index === 1) {
				classes[key] = this._isLastNode(branch[index])
						? 'tree-mark-sink' : 'tree-mark-fork';
			}
			else {
				classes[key] = this._isLastNode(branch[index])
						? 'tree-mark-none' : 'tree-mark-transit';
			}
		}

		while (index < groups.length) {
			classes[groups[index++]] = 'tree-mark-none';
		}

		node.cache.set(this.key, classes);
	}

	private _isLastNode(node: Node<RowItem>): boolean {
		const children = node.parent.children;
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = children.indexOf(node) + 1; i < children.length; i++) {
			if (this._rowsVisibility.getForNode(children[i])) {
				return false;
			}
		}
		return true;
	}
}
