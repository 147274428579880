import {Injectable} from '@angular/core';
import {RolesResourceService} from '../services/roles/roles-resource.service';
import {UserRolesResourceService} from '../services/users/user-roles-resource.service';
import {UsersResourceService} from '../services/users/users-resource.service';
import {AbstractUserRolesClonerService} from './abstract-user-roles-cloner.service';

@Injectable()
export class UserRolesClonerService extends AbstractUserRolesClonerService {
	public constructor(
			rolesResource: RolesResourceService,
			userRolesResource: UserRolesResourceService,
			usersResource: UsersResourceService) {
		super([
			{
				key: 'roles',
				resource: rolesResource,
				target: { query: { only: ['id', 'name'] } }
			}, {
				key: 'userRoles',
				resource: userRolesResource,
				target: { query: { only: ['userUid', 'roleId', 'customerBranchId'] } }
			}, {
				key: 'users',
				resource: usersResource,
				target: { query: { only: ['uid'] } }
			}
		]);
	}
}
