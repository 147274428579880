import {Component, Input, HostBinding, ChangeDetectorRef, ChangeDetectionStrategy} from '@angular/core';
import {Column, RowItem} from '../types';
import {Node} from '../model/node';
import {ExpansionService} from '../services/expansion.service';
import {RowsTreeMarkClass} from '../services/rows-tree-mark-class.service';

@Component({
	selector: 'td[knTableCell]',
	templateUrl: 'table-cell.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnTableCell {
	@Input() public column: Column<RowItem>;
	@Input() public node: Node<RowItem>;

	public constructor(
			private readonly _expansion: ExpansionService,
			private readonly _cdr: ChangeDetectorRef,
			private readonly _rowsTreeMarkClass: RowsTreeMarkClass) {
	}

	public isExpanded(node: Node<RowItem>) {
		return this._expansion.isExpanded(node);
	}

	public toggleExpand(node: Node<RowItem>) {
		this._expansion.toggleExpand(node);
	}

	@HostBinding('class.group')
	public get isGroup(): boolean {
		return this.column.model.group;
	}

	public get isExpandable(): boolean {
		return this.node.isGroup() && this.node.group.id === this.column.id;
	}

	public getTreeMarkClass() {
		return this._rowsTreeMarkClass.calculate(this.node).getForNode(this.node)[this.column.id];
	}

	public refresh() {
		this._cdr.markForCheck();
	}
}
