import {Utils} from 'kn-utils';
import {PrintStatementMetadata} from './print-statement.decorator';
import {PrintProcessorMetadata} from './print-processor.decorator';

export interface PrintMetadataFactory {
	(key: string): any;
	new (key: string): any;
}

const printStatement: PrintMetadataFactory = Utils.reflector.makeDecorator(PrintStatementMetadata);
const printProcessor: PrintMetadataFactory = Utils.reflector.makeDecorator(PrintProcessorMetadata);

export function PrintStatement(key: string): any {
	return (target: string, name: string) =>
		printStatement(key)(target, name);
}

export function PrintProcessor(key: string): any {
	return (target: string, name: string) =>
		printProcessor(key)(target, name);
}
