import {Subscription} from 'rxjs';
import {AbstractResource} from 'kn-rest';
import {AbstractFilterSerializer, Model as FilterModel} from 'kn-query-filter';
import {RemoteAutocompletition, RemoteAutocompleteBondConfig} from 'kn-forms';
import {autocompletitionFactory} from './autocompletition-factory';

export class ResourceAutocompletitionFactory<T> {
	private _autocompletition: RemoteAutocompletition<T>;

	public get autocompletition() {
		if (this._autocompletition == null) {
			this._createAutocompletition();
		}
		return this._autocompletition;
	}

	public constructor(
			private readonly _resource: AbstractResource<T>,
			private readonly _queryModelOrProperties: ((query: string) => FilterModel) | string[] | string,
			private readonly _valueModelOrProperties?: ((value: any) => FilterModel) | string,
			private readonly _query?: { [key: string]: any },
			private readonly _config?: RemoteAutocompleteBondConfig,
			private readonly _serializer?: AbstractFilterSerializer<string>) {
	}

	public subscribe(onChange: { (autocompetition: RemoteAutocompletition<T>): void }): Subscription {
		const subscription = this._resource.changes.subscribe(next => {
			this._createAutocompletition();
			onChange(this._autocompletition);
		});
		onChange(this.autocompletition);
		return subscription;
	}

	private _createAutocompletition() {
		this._autocompletition = this.buildAutocompletition();
	}

	public buildAutocompletition() {
		return autocompletitionFactory<T>(
			this._resource,
			this._queryModelOrProperties,
			this._valueModelOrProperties,
			this._query,
			this._config,
			this._serializer
		);
	}
}
