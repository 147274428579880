import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('count')
export class CountFilter implements PrintRendererFilter {
	public transform(value: any[]): number {
		return Array.isArray(value) ? value.length : 0;
	}
}
