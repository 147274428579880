import {Injectable} from '@angular/core';
import {PrintStatement, PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from 'kn-print';
import {Description, Model, Filter, FilterValue} from 'kn-query-filter';

export type FilterCompound = {
	description: Description;
	model: Model;
};

@Injectable()
@PrintStatement('query')
export class QueryHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string | Promise<string> {
		return content.exec(
			this._transform(...content.params as [any, any, any?])
		);
	}

	private _transform(compound: FilterCompound, id: string): any;
	private _transform(description: Description, model: Model, id: string): any;
	private _transform(descriptionOrCompound: Description | FilterCompound, modelOrId: Model | string, id?: string): any {
		// not used...
		// let description = descriptionOrCompound as Description;
		let model = modelOrId as Model;
		if (descriptionOrCompound.hasOwnProperty('description')
				&& descriptionOrCompound.hasOwnProperty('model')) {
			// description = (descriptionOrCompound as FilterCompound)['description'];
			model = (descriptionOrCompound as FilterCompound)['model'];
			id = modelOrId as string;

			const filter = model.children
				.find(x => (x as Filter<FilterValue>).id === id) as Filter<FilterValue>;
			return filter && filter.value;
		}
	}
}
