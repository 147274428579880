import {Injectable, Inject} from '@angular/core';
import {Description, Model, DataSource} from 'kn-datagrid';
import {EXPORTER_FACTORIES_TOKEN} from './exporter-factories.token';
import {ExporterFactory} from './exporter-factory.type';
import {saveAs} from 'file-saver';

@Injectable()
export class GridExporterService {
	public constructor(
			@Inject(EXPORTER_FACTORIES_TOKEN) private readonly _exporterFactories: ExporterFactory[]) {
	}

	public getSupportedTypes() {
		return this._exporterFactories.map(x => x.type);
	}

	public async export(description: Description<any>, model: Model, dataSource: DataSource<any>, options?: { type?: string, saveAs?: string }) {
		options = options || {};
		const exporter = this._createExporter(options.type || 'csv', description, model);
		const data = await exporter.export(dataSource);
		if (options.saveAs) {
			saveAs(data, options.saveAs);
		}
		return data;
	}

	private _createExporter(type: string, description: Description<any>, model: Model) {
		return this._exporterFactories.find(x => x.type === type).create(description, model);
	}
}
