import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {KnCommonModule} from 'kn-common';
import {KnFormsModule} from 'kn-forms';
import {DynamicSubformComponent} from './dynamic-subform.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		KnCommonModule,
		KnFormsModule
	],
	declarations: [
		DynamicSubformComponent
	],
	exports: [
		DynamicSubformComponent
	]
})
export class KnDynamicSubformModule { }
