import {Directive, forwardRef, AfterContentInit, Input, Output, EventEmitter, ContentChildren, QueryList, Provider, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {BooleanField} from 'kn-common';
import {KnRadio} from './radio.component';

export const KN_RADIO_GROUP_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnRadioGroup), // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
	multi: true
};

let nextUniqueId = 0;

@Directive({
	selector: 'kn-radio-group',
	providers: [KN_RADIO_GROUP_CONTROL_VALUE_ACCESSOR],
	host: {
		'role': 'radiogroup'
	}
})
export class KnRadioGroup implements AfterContentInit, ControlValueAccessor, OnDestroy {
	private _value: any = null;
	private _name: string = `kn-radio-group-${nextUniqueId++}`;
	private _isInitialized: boolean = false;
	protected _disposables: Function[] = [];

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@Input() @BooleanField() public disabled: boolean = false;

	public get name(): string {
		return this._name;
	}

	@Input() public set name(value: string) {
		this._name = value;
		this._updateName();
	}

	public get value(): any {
		return this._value;
	}

	@Input() public set value(v: any) {
		if (this._value !== v) {
			this._value = v;
			this._updateSelection();
		}
	}

	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<any>();

	@ContentChildren(KnRadio, { descendants: true })
	public _radios: QueryList<KnRadio>;

	public ngAfterContentInit() {
		const sub = this._radios.changes.subscribe(() => {
			this._updateName();
			this._updateSelection();
		});
		this._disposables.push(() => sub.unsubscribe());
		this._isInitialized = true;
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
		this._disposables = [];
	}

	/** @internal */
	public change(value: string, event: Event) {
		const valueChanged = this.value !== value;
		this._value = value;
		this._onChangeCallback(value);
		if (this._isInitialized && valueChanged) {
			this.valueChange.emit(this.value);
			this.changeEvent.emit();
		}
	}

	/** @internal */
	public touch() {
		this._onTouchedCallback();
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this.value = value;
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	private _updateName(): void {
		if (this._radios) {
			this._radios.forEach(x => x.name = this.name);
		}
	}

	private _updateSelection(): void {
		if (this._radios) {
			this._radios.forEach(x => x.checked = this.value === x.value);
		}
	}
}
