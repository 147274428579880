import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('sum')
export class SumFilter implements PrintRendererFilter {
	public transform(value: number[]): number {
		return value == null ? null : value.reduce((sum, x) => sum + x, 0);
	}
}
