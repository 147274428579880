import {Component, ViewEncapsulation, Input, Output, EventEmitter, HostListener, ElementRef, ChangeDetectionStrategy, Inject, OnDestroy, NgZone} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Utils} from 'kn-utils';
import {ColumnModel, Model, Description, RowItem} from '../types';

interface HeaderMetadata {
	element: Element;
	rect: ClientRect;
	shift: number;
	column: ColumnModel;
}

interface BordersRect {
	left: number;
	right: number;
}

interface CurrentReordableState {
	started: boolean;
	dragging: boolean;
}

interface Deadband {
	moveThreshold: number;
	stopPropagation: boolean;
}

// TODO: use Renderer
@Component({
	selector: 'kn-reordable-handler',
	template: '<ng-content></ng-content>',
	styleUrls: ['reordable-handler.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnReordableHandler implements OnDestroy {
	@Input() public enable: boolean;
	@Input() public model: Model;
	@Input() public description: Description<RowItem>;
	@Input() public column: ColumnModel;
	@Output() public reorderColumn = new EventEmitter<{ fromIndex: number, toIndex: number }>();

	private readonly _eventsRemover: { (): void };
	private _eventsRemoverOutsideAngular: { (): void };

	public constructor(
			private readonly _zone: NgZone,
			private readonly _element: ElementRef,
			@Inject(DOCUMENT) doc: any /* Document */) {
		const draggingEndHandlerListener = this._draggingEndHandler.bind(this);
		const clickHandlerListener = this._clickHandler.bind(this);
		doc.addEventListener('mouseup', draggingEndHandlerListener);
		doc.addEventListener('touchend', draggingEndHandlerListener);
		doc.addEventListener('click', clickHandlerListener, true);
		this._eventsRemover = () => {
			doc.removeEventListener('mouseup', draggingEndHandlerListener);
			doc.removeEventListener('touchend', draggingEndHandlerListener);
			doc.removeEventListener('click', clickHandlerListener, true);
		};
		this._zone.runOutsideAngular(() => {
			const reorderMoveListener = this._reorderMove.bind(this);
			doc.addEventListener('mousemove', reorderMoveListener);
			doc.addEventListener('touchmove', reorderMoveListener);
			this._eventsRemoverOutsideAngular = () => {
				doc.removeEventListener('mousemove', reorderMoveListener);
				doc.removeEventListener('touchmove', reorderMoveListener);
			};
		});
	}

	public ngOnDestroy() {
		this._eventsRemover();
		this._zone.runOutsideAngular(() => this._eventsRemoverOutsideAngular());
	}

	private readonly _deadband: Deadband = {
		moveThreshold: 5,
		stopPropagation: false
	};
	private _offset: number = 0;
	private _headersMetadata: HeaderMetadata[] = [];
	private _moveDistance: number = 0;
	private _theadElement: Element;
	private _borders: BordersRect;
	private _current: CurrentReordableState = {
		started: false,
		dragging: false
	};

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public reorderStartHandler(event: MouseEvent | TouchEvent) {
		if (!this.enable) {
			return;
		}

		if (event.type === 'mousedown' && (event as MouseEvent).button !== 0) {
			return;
		}

		// FIXME: parentElement.parentElement
		this._theadElement = this._element.nativeElement.parentElement.parentElement;
		this._headersMetadata = this._getHeadersMetadata(this._theadElement.querySelectorAll('.header'));
		if (this._headersMetadata.length <= 1) {
			return;
		}

		this._offset = (event as MouseEvent).screenX || (event as TouchEvent).targetTouches[0].screenX;

		const index = this._headersMetadata.findIndex(header => header.column === this.column);
		if (index === -1) {
			return;
		}
		else if (index === 0) {
			this._moveDistance = this._headersMetadata[1].rect.left - this._headersMetadata[0].rect.left;
		}
		else {
			this._moveDistance = this._headersMetadata[index].rect.right - this._headersMetadata[index - 1].rect.right;
		}

		this._borders = {
			left: this._headersMetadata[index].rect.left,
			right: this._headersMetadata[index].rect.right
		};
		for (const headerMetadata of this._headersMetadata) {
			if (this.column.group && !headerMetadata.column.group) {
				continue;
			}
			if (this._borders.left > headerMetadata.rect.left) {
				this._borders.left = headerMetadata.rect.left;
			}
			if (this._borders.right < headerMetadata.rect.right) {
				this._borders.right = headerMetadata.rect.right;
			}
		}

		this._current = {
			started: true,
			dragging: false
		};

		event.preventDefault();
	}

	private _reorderMove(event: MouseEvent | TouchEvent) {
		if (this._current.started) {
			const currentHeadersMetadata = this._headersMetadata.find(header => header.column === this.column);
			let currentShift = ((event as MouseEvent).screenX
				|| (event as TouchEvent).targetTouches[0].screenX) - this._offset;

			if (currentShift < this._borders.left - currentHeadersMetadata.rect.left) {
				currentShift = this._borders.left - currentHeadersMetadata.rect.left;
			}
			if (currentShift > this._borders.right - currentHeadersMetadata.rect.right) {
				currentShift = this._borders.right - currentHeadersMetadata.rect.right;
			}
			if (currentHeadersMetadata.shift === currentShift) {
				return;
			}

			currentHeadersMetadata.shift = currentShift;
			(currentHeadersMetadata.element as HTMLElement).style.left = currentShift + 'px';

			if (this._deadband.moveThreshold < Math.abs(currentShift)) {
				this._deadband.stopPropagation = true;
				if (!this._current.dragging) {
					this._element.nativeElement.classList.add('dragging');
					(this._theadElement as HTMLElement).classList.add('dragging');
					this._current.dragging = true;
				}
			}

			const range = {
				left: currentHeadersMetadata.rect.left + currentHeadersMetadata.shift,
				right: currentHeadersMetadata.rect.right + currentHeadersMetadata.shift
			};
			let afterCurrent = false;
			for (let i = 0; i < this._headersMetadata.length; i++) {
				if (this._headersMetadata[i] === currentHeadersMetadata) {
					afterCurrent = true;
					continue;
				}

				const collection = [this._headersMetadata[i]];
				while (!this.column.group && this._headersMetadata[i].column.group
					&& i + 1 < this._headersMetadata.length && this._headersMetadata[i + 1].column.group) {
					collection.push(this._headersMetadata[++i]);
				}

				let shift = 0;
				const decisionPoint = this._calcDecisionPoint(collection);
				if (afterCurrent) {
					if (decisionPoint < range.right) {
						shift = -this._moveDistance;
					}
					else {
						shift = 0;
					}
				}
				else {
					if (decisionPoint < range.left) {
						shift = 0;
					}
					else {
						shift = this._moveDistance;
					}
				}
				for (const item of collection) {
					if (item.shift !== shift) {
						item.shift = shift;
						(item.element as HTMLElement).style.left = shift + 'px';
					}
				}
			}
		}
	}

	private _draggingEndHandler() {
		if (this._current.started) {
			if (this._current.dragging) {
				const srcIndex = this._headersMetadata.findIndex(header => header.column === this.column);
				const srcPoint = this._calcDecisionPoint(this._headersMetadata[srcIndex]) + this._headersMetadata[srcIndex].shift;
				let dstIndex = 0;
				let dstPoint = 0;
				for (let i = 0; i < this._headersMetadata.length; i++) {
					const collection = [this._headersMetadata[i]];
					while (!this.column.group && this._headersMetadata[i].column.group
						&& i + 1 < this._headersMetadata.length && this._headersMetadata[i + 1].column.group) {
						collection.push(this._headersMetadata[++i]);
					}
					const point = this._calcDecisionPoint(collection) + this._headersMetadata[i].shift;
					if (point > dstPoint && point < srcPoint) {
						dstIndex = i + (i < srcIndex ? 1 : 0);
						dstPoint = point;
					}
				}
				if (srcIndex !== dstIndex) {
					const fromIndex = this.model.columns.indexOf(this._headersMetadata[srcIndex].column);
					const toIndex = this.model.columns.indexOf(this._headersMetadata[dstIndex].column);
					this.reorderColumn.emit({ fromIndex, toIndex });
				}
			}
			for (const headerMetadata of this._headersMetadata) {
				(headerMetadata.element as HTMLElement).style.left = '0px';
			}
			this._current.started = false;
			this._current.dragging = false;
			this._theadElement.classList.remove('dragging');
			this._element.nativeElement.classList.remove('dragging');
		}
	}

	private _clickHandler(event: Event) {
		if (this._deadband.stopPropagation) {
			event.preventDefault();
			event.stopPropagation();
			this._deadband.stopPropagation = false;
		}
	}

	private _getHeadersMetadata(elements: NodeListOf<Element>): HeaderMetadata[] {
		const headersMetadata: HeaderMetadata[] = [];
		let index = 0;
		for (let i = 0; i < this.model.columns.length && index < elements.length; i++) {
			if (!this._columnVisible(this.model.columns[i])) {
				continue;
			}
			headersMetadata.push({
				element: elements[index],
				rect: elements[index].getBoundingClientRect(),
				shift: 0,
				column: this.model.columns[i]
			});
			index++;
		}
		return headersMetadata;
	}

	private _columnVisible(column: ColumnModel) {
		const colDescription = this.description.columns.find(c => c.id === column.id);
		const sections = Utils.array.box(colDescription.section).filter(x => x !== '');
		if (!column.visible || sections.length === 0) {
			return column.visible;
		}
		let modelSections = this.model.sections;
		for (const colSection of sections) {
			const tmp = modelSections.find(x => x.id === colSection);
			if (tmp == null || !tmp.visible) {
				return false;
			}
			modelSections = tmp.children;
		}
		return true;
	}

	private _calcDecisionPoint(collection: HeaderMetadata | HeaderMetadata[]): number {
		if (Array.isArray(collection)) {
			return collection[0].rect.left
				+ (collection[collection.length - 1].rect.right - collection[0].rect.left) / 2;
		}
		return collection.rect.left + (collection.rect.right - collection.rect.left) / 2;
	}
}
