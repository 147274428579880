import {Utils} from 'kn-utils';
import {Observable, of as observableOf, from as observableFrom} from 'rxjs';
import {OptionDescription, FilterNode, Filter, FilterDefaults, FilterValue, Resolvable} from './types';

// eslint-disable-next-line
export namespace FilterNodeUtils {
	export function isGroup<T>(node: FilterNode<T> | Filter<T>) {
		return node.hasOwnProperty('children');
	}

	export function forEachNode<T>(node: FilterNode<T> | Filter<T>, visitor: (node: FilterNode<T> | Filter<T>) => void): void {
		visitor(node);
		if (isGroup(node)) {
			(node as FilterNode<T>).children.forEach(x => forEachNode(x, visitor));
		}
	}
}

export namespace ValueResolveUtils {
	function _resolveValue<T, V extends { (...args: any[]): T }>(resolvee: T | V, ...args: any[]): T {
		return Utils.isFunction(resolvee) ? (resolvee as V)(...args) : resolvee as T;
	}

	function _toObservable<T>(resolvable: Resolvable<T>): Observable<T> {
		if (Utils.isObservable(resolvable)) {
			return resolvable as Observable<T>;
		}
		if (Utils.isPromise(resolvable)) {
			return observableFrom(resolvable as Promise<T>);
		}
		return observableOf(resolvable as T);
	}

	export function resolveLabel<T extends FilterValue>(option: OptionDescription<T>): string {
		return option && _resolveValue(option.label, option) as string;
	}

	export function resolveDefaults<T extends FilterValue>(option: OptionDescription<T>): FilterDefaults<T> {
		return option && _resolveValue(option.defaults, option) as FilterDefaults<T>;
	}

	export function resolveConstraints<T extends FilterValue>(option: OptionDescription<T>): Observable<{ [others: string]: any }> {
		return option && _toObservable(_resolveValue(option.constraints, option));
	}

	export function resolveOptionParameter<T extends FilterValue>(key: string, option: OptionDescription<T>): Observable<any> {
		return option && _toObservable(_resolveValue(option[key as keyof OptionDescription<T>], option) as Resolvable<T>);
	}

	export function resolveDefaultsValue<T extends FilterValue>(defaults: FilterDefaults<T>, option: OptionDescription<T>): Observable<{ label: string, value: string }[]> {
		return defaults && _toObservable(_resolveValue(defaults.value as any, option));
	}
}
