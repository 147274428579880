import {Component, OnInit} from '@angular/core';
import {RouteTitlePresenter} from 'common-web/app';

@Component({
	selector: 'kn-app-viewport',
	template: `<kn-portal-container></kn-portal-container><kn-overlay-host></kn-overlay-host><router-outlet></router-outlet>`
})
export class AppComponent implements OnInit {
	public constructor(private readonly _titlePresenter: RouteTitlePresenter) { }

	public ngOnInit() {
		this._titlePresenter.activate();
	}
}
