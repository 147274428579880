import {Injectable, TemplateRef, ViewContainerRef} from '@angular/core';
import {CellContext, CellValue} from '../types';
import {RendererFactory} from './renderer-factory';
import {TemplateRendererContext, TemplateRenderBinderRef} from './binders/template-render-binder-ref';
import {RenderAs} from './render-as';

@Injectable()
export class TemplateRendererFactory extends RendererFactory {
	public constructor() {
		super();
		this.register(RendererFactory.mainTarget, this._mainRenderer.bind(this));
	}

	private _createView<T>(viewContainerRef: ViewContainerRef, templateRef: TemplateRef<TemplateRendererContext<T>>, context: TemplateRendererContext<T>): TemplateRenderBinderRef<T> {
		const embededView = viewContainerRef.createEmbeddedView(templateRef, context);
		return new TemplateRenderBinderRef(embededView);
	}

	private _mainRenderer<T>(value: CellValue, context: CellContext<T>, templateRef: TemplateRef<TemplateRendererContext<T>>): any {
		const ctx = new TemplateRendererContext(value, context);
		return RenderAs.template(viewContainerRef => this._createView(viewContainerRef, templateRef, ctx));
	}
}
