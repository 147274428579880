import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {GravatarConfig} from './gravatar.config';
import {KnGravatar} from './gravatar.service';
import {KnGravatarSrc} from './gravatar-src.directive';

export class GravatarOptions extends GravatarConfig { }

export const GRAVATAR_FORROOT_GUARD = new InjectionToken<void>('GRAVATAR_FORROOT_GUARD');

const KN_GRAVATAR_DIRECTIVES = [KnGravatarSrc];

export function provideGravatarConfig(config: GravatarOptions) {
	return Utils.object.defaults(config, {
		params: { 'default': '404' },
		secure: false,
		protocol: null
	});
}

@NgModule({
	declarations: [KN_GRAVATAR_DIRECTIVES],
	exports: [KN_GRAVATAR_DIRECTIVES]
})
export class KnGravatarModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(GRAVATAR_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<GravatarOptions>): ModuleWithProviders<KnGravatarModule> {
		return {
			ngModule: KnGravatarModule,
			providers: [
				forRootGuardFactory('KnGravatarModule', GRAVATAR_FORROOT_GUARD, KnGravatar),
				{
					provide: GravatarOptions, useValue: config ? config : {}
				}, {
					provide: GravatarConfig,
					useFactory: provideGravatarConfig,
					deps: [GravatarOptions]
				},
				KnGravatar
			]
		};
	}
}
