import {Inject, Component, ViewChild, ViewEncapsulation, Input, Optional, HostListener, HostBinding, ElementRef, forwardRef} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {BooleanField} from 'kn-common';
import {AbstractOptionsHost} from './abstract-options-host';
import {KnOptgroup} from './optgroup.component';

@Component({
	selector: 'kn-option',
	templateUrl: 'option.html',
	styleUrls: ['option.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnOption {
	@Input('value') public item: any;
	@Input() @BooleanField() public disabled: boolean = false;

	// deprecated
	@Input('item') public item2(value: any) {
		console.warn('Deprecated');
		this.item = value;
	}

	public constructor(
			@Inject(forwardRef(() => AbstractOptionsHost)) private readonly _host: AbstractOptionsHost,
			@Optional() private readonly _optgroup: KnOptgroup,
			private readonly _sanitizer: DomSanitizer,
			private readonly _element: ElementRef) {
	}

	@ViewChild('content', { static: true })
	public contentElement: ElementRef;

	@HostBinding('class.marked')
	public marked: boolean = false;

	@HostBinding('hidden')
	public hidden: boolean = true;

	@HostBinding('class.selected')
	public selected: boolean = false;

	@HostBinding('class.disabled')
	public get isDisabled(): boolean {
		return this.disabled || (this._optgroup != null && this._optgroup.disabled);
	}

	public get html(): SafeHtml {
		const html = this.contentElement.nativeElement.innerHTML;
		return this._sanitizer.bypassSecurityTrustHtml(html);
	}

	public get text() {
		return this.contentElement.nativeElement.textContent.trim();
	}

	public get hasCheckbox(): boolean {
		return this._host.multiple;
	}

	public getClientRect(): ClientRect {
		return this._element.nativeElement.getBoundingClientRect();
	}

	public scrollIntoView() {
		this._host.container.scrollOptionIntoView(this);
	}

	@HostListener('click', ['$event'])
	public clickHandler(event: Event) {
		if (!this.isDisabled) {
			this._host.toggleOption(this);
		}
		event.preventDefault();
	}

	@HostListener('mousedown', ['$event'])
	public mousedownHandler(event: Event) {
		event.preventDefault();
	}
}
