<label #knLabelContent>
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required && hasKnLabel">*</span>
</label>

<div class="kn-date-range-container">
	<div class="time-back-selector" *ngIf="type === 'fromDate' || type === 'toDate'">
		<kn-input type="number" min="0"
				comparer="null"
				[readonly]="readonly"
				[ngModel]="relativeDiff"
				(ngModelChange)="handleTimeBackChange($event)">
			<kn-option *ngFor="let suggestion of timeBackSuggestions"
					[value]="suggestion">{{ suggestion }}</kn-option>
			<span kn-label [ngSwitch]="relativeDiffType">
				<ng-container *ngSwitchCase="'months'" i18n>Months back</ng-container>
				<ng-container *ngSwitchCase="'weeks'" i18n>Weeks back</ng-container>
				<ng-container *ngSwitchCase="'days'" i18n>Days back</ng-container>
				<ng-container *ngSwitchCase="'hours'" i18n>Hours back</ng-container>
			</span>
		</kn-input>
	</div>
	<div class="date-selector" *ngIf="type === 'fixRange' || type === 'fromDate'">
		<kn-date-input #fromInput
				[type]="inputType"
				[nowAllowed]="nowAllowed && !hideFromNow"
				[readonly]="readonly"
				[required]="required"
				[nowDateLock]="nowLockFrom"
				[ngModel]="beginDate"
				(ngModelChange)="handleBeginDateChange($event)">
		</kn-date-input>
	</div>
	<div class="date-selector" *ngIf="type === 'fixRange' || type === 'toDate'">
		<kn-date-input #toInput
				[type]="inputType"
				[nowAllowed]="nowAllowed && !hideToNow"
				[readonly]="readonly"
				[required]="required"
				[nowDateLock]="nowLockTo"
				[ngModel]="endDate"
				(ngModelChange)="handleEndDateChange($event)">
		</kn-date-input>
	</div>
</div>

<div class="kn-date-range-underline"></div>

<ng-content></ng-content>

<ng-content select="[kn-hint]"></ng-content>
