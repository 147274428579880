import {Inject, Injectable, Optional} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Http, Response, Uri, UriTemplate} from 'kn-http';
import {KcuniVersion, KcuniSettings, KcuniChip} from './types';
import {KCUNI_HTTP_TOKEN} from './kcuni-http.token';

export class KcuniBridgeConfig {
	public host?: string;
}

@Injectable()
export class KcuniBridgeService {
	private readonly _uri: Uri;
	private readonly _options: KcuniBridgeConfig;

	public constructor(
			@Inject(KCUNI_HTTP_TOKEN) private readonly _http: Http,
			@Optional() options?: KcuniBridgeConfig) {
		this._options = Utils.object.defaults({}, options, {
			host: 'https://localhost:8008'
		} as KcuniBridgeConfig);
		this._uri = new Uri(new UriTemplate(`${this._options.host}/api/{endpoint}`));
	}

	public getApiUrl() {
		return this._uri;
	}

	public getVersion(): Observable<KcuniVersion> {
		return this._http.get(this._uri.build({ endpoint: 'version' })).pipe(
			Rx.map(next => {
				const data = next.body;
				return {
					version: data.version,
					date: Utils.date.fromIso8601(data.date)
				};
			})
		);
	}

	public getPorts(): Observable<string[]> {
		return this._http.get(this._uri.build({ endpoint: 'get-ports' }))
			.pipe(Rx.map(next => next.body as string[]));
	}

	public getSetting(): Observable<KcuniSettings> {
		return this._http.get(this._uri.build({ endpoint: 'setting' }))
			.pipe(Rx.map(next => next.body as KcuniSettings));
	}

	public setSetting(settings: KcuniSettings): Observable<Response> {
		const body = JSON.stringify(settings);
		return this._http.put(this._uri.build({ endpoint: 'setting' }), body);
	}

	public chipsFeed(): Subject<KcuniChip> {
		const uri = this._uri.build({ endpoint: 'chip' }, { scheme: 'wss' });
		return this._http.websocket(uri);
	}
}
