import {Injectable, Inject} from '@angular/core';
import {AbstractChartFactory} from './abstract-chart';
import {KN_CHART_FACTORIES_TOKEN} from './chart-factories.token';

@Injectable()
export class Charts {
	public constructor(
			@Inject(KN_CHART_FACTORIES_TOKEN) private readonly _factories: AbstractChartFactory[][]) {
	}

	public find(name: string): AbstractChartFactory {
		for (const factories of this._factories) {
			const factory = factories.find(x => x.name === name);
			if (factory != null) {
				return factory;
			}
		}
		throw new Error(`Cannot find a Chart.`);
	}
}
