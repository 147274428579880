import {Injector, Directive, OnInit, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, FormArray, AbstractControl} from '@angular/forms';
import {Observable, throwError as observableThrowError, of} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {
	ValidationService,
	RequiredValidator,
	MinLengthValidator,
	MaxLengthValidator,
	PatternValidator,
	NullValidator,
	EmailValidator,
	MinValidator,
	MaxValidator,
	RangeValidator,
	MinDateValidator,
	MaxDateValidator,
	MinLengthUTF8Validator,
	MaxLengthUTF8Validator
} from 'kn-forms';
import {UriContext} from 'kn-http';
import {AbstractResource} from 'kn-rest';
import {I18nService} from 'kn-shared';
import {Utils} from 'kn-utils';
import {ToastService, ConfirmationService, CanComponentDeactivate} from 'kn-modal';
import {navigateBack} from './utils/navigate-back';
import {EditMode} from './types';

@Directive()
export abstract class AbstractEditComponent implements OnInit, OnDestroy, CanComponentDeactivate {
	public form: FormGroup;

	protected _mode = EditMode.New;
	protected _defaultValues: any;
	protected _resistiveDisabledControls = new WeakSet<AbstractControl>();
	protected _i18n: I18nService;
	protected _location: Location;
	protected _router: Router;
	protected _route: ActivatedRoute;
	protected _formBuilder: FormBuilder;
	protected _validation: ValidationService;
	protected _confirmation: ConfirmationService;
	protected _toast: ToastService;
	protected _disposables: Function[] = [];

	private _confirmDeactivate: boolean = true;

	public constructor(injector: Injector) {
		this._i18n = injector.get(I18nService);
		this._location = injector.get(Location);
		this._router = injector.get(Router);
		this._route = injector.get(ActivatedRoute);
		this._formBuilder = injector.get(FormBuilder);
		this._validation = injector.get(ValidationService);
		this._confirmation = injector.get(ConfirmationService);
		this._toast = injector.get(ToastService);

		this._validation.add('required', new RequiredValidator(), this._i18n.t('Required'));
		this._validation.add('minLength', new MinLengthValidator(), this._i18n.t('Too short'));
		this._validation.add('maxLength', new MaxLengthValidator(), this._i18n.t('Too long'));
		this._validation.add('minLengthUTF8', new MinLengthUTF8Validator(), this._i18n.t('Too short'));
		this._validation.add('maxLengthUTF8', new MaxLengthUTF8Validator(), this._i18n.t('Too long'));
		this._validation.add('pattern', new PatternValidator(), this._i18n.t('Invalid format'));
		this._validation.add('null', new NullValidator());
		this._validation.add('email', new EmailValidator(), this._i18n.t('Invalid email'));
		this._validation.add('min', new MinValidator(), this._i18n.t('Too low'));
		this._validation.add('max', new MaxValidator(), this._i18n.t('Too high'));
		this._validation.add('range', new RangeValidator(), this._i18n.t('Outside range'));
		this._validation.add('minDate', new MinDateValidator(), this._i18n.t('Too low'));
		this._validation.add('maxDate', new MaxDateValidator(), this._i18n.t('Too high'));
	}

	public ngOnInit() {
		this._init();
		this.form = this._buildForm();
		this._scanResistiveDisabledControls(this.form);
		this._defaultValues = this.form.value;
		this._load();
	}

	public ngOnDestroy() {
		this._dispose();
	}

	protected _init() {
		/* intentionally empty */
	}

	protected _load() {
		/* intentionally empty */
	}

	protected _reload() {
		this._dispose();
		this._init();
		this.form.reset(this._defaultValues);
		this._load();
	}

	protected _dispose() {
		this._disposables.forEach(x => x());
		this._disposables = [];
	}

	protected abstract _buildForm(): FormGroup;

	public navigateBack(confirm: boolean = true): void {
		this._confirmDeactivate = confirm;
		navigateBack(this._location, true, () => {
			this._router.navigate(['.'], { relativeTo: this._route.parent });
		});
	}

	public canDeactivate() {
		return !this._confirmDeactivate || !this.form.dirty || this.form.dirty && this._confirmDiscard();
	}

	private async _confirmDiscard() {
		const result = await this._confirmation.show(
			this._i18n.t('Discard changes?'),
			this._i18n.t('Are you sure?\nThis is inreversible operation.'), [
				{ name: this._i18n.t('Discard'), classes: ['danger'], result: 'discard' },
				{ name: this._i18n.t('No') }
			]);
		return result === 'discard';
	}

	protected _fetch<U>(resource: AbstractResource<U>, context?: UriContext, returnWhenForbidden?: U[]): Observable<U[]> {
		return resource.query(context)
			.pipe(Rx.catchError(error => {
				if ((returnWhenForbidden !== undefined) && (error.status === 403)) {
					return of(returnWhenForbidden);
				}
				return this._handleError<U[]>(error);
		}));
	}

	protected _handleError<U>(error: any): Observable<U> {
		this._toast.show(this._i18n.t('Loading failed.'), this._retriveErrorMessage(error));
		return observableThrowError(error);
	}

	protected _retriveErrorMessage(error: any) {
		if (Utils.isString(error.statusText)) {
			const msg = error.statusText as string;
			// FIXME: This generic component cannot make any assumptions to error type/format
			if (msg.indexOf('violates foreign key') !== -1) {
				return this._i18n.t('Another items depends on this item ({{ msg }}).', { msg });
			}
			else if (msg.indexOf('Restrictions forbids access') !== -1) {
				return this._i18n.t('Your restrictions do not allow this. ({{ msg }}).', { msg });
			}
		}
		return error.statusText || error.message || error;
	}

	public isEditMode(): boolean {
		return this._mode === EditMode.Edit;
	}

	public disableControl(control: AbstractControl, resistive: boolean = true) {
		resistive && this._resistiveDisabledControls.add(control);
		control.disable();
	}

	public enableControl(control: AbstractControl, resistive: boolean = true) {
		resistive && this._resistiveDisabledControls.delete(control);
		if (control.parent == null || control.parent.enabled) {
			control.enable();
		}
	}

	protected _applyResistiveDisable() {
		this._traverseControls(this.form, x => {
			if (x.disabled) {
				return false;
			}
			if (this._resistiveDisabledControls.has(x)) {
				x.disable();
				return false;
			}
			return true;
		});
	}

	private _scanResistiveDisabledControls(control: AbstractControl) {
		this._traverseControls(control, x => {
			if (x.disabled) {
				this._resistiveDisabledControls.add(x);
				return false;
			}
			this._resistiveDisabledControls.delete(x);
			return true;
		});
	}

	private _traverseControls(control: AbstractControl, action: (control: AbstractControl) => boolean) {
		if (!action(control)) {
			return;
		}
		if (control instanceof FormGroup) {
			for (const key in control.controls) {
				if (control.controls.hasOwnProperty(key)) {
					this._traverseControls(control.controls[key], action);
				}
			}
		}
		else if (control instanceof FormArray) {
			control.controls.forEach(x => this._traverseControls(x, action));
		}
	}

	public addControl(formGroup: FormGroup, control: AbstractControl, name: string): void;
	public addControl(formArray: FormArray, control: AbstractControl, index?: number): void;
	public addControl(formGroupOrArray: FormGroup | FormArray, control: AbstractControl, nameOrIndex?: string | number) {
		this._scanResistiveDisabledControls(control);
		formGroupOrArray.disabled && control.disable();
		if (formGroupOrArray instanceof FormGroup) {
			formGroupOrArray.addControl(nameOrIndex as string, control);
		}
		else {
			if (nameOrIndex == null) {
				formGroupOrArray.push(control);
			}
			else {
				formGroupOrArray.insert(nameOrIndex as number, control);
			}
		}
	}

	public setControl(formGroup: FormGroup, control: AbstractControl, name: string): void;
	public setControl(formArray: FormArray, control: AbstractControl, index: number): void;
	public setControl(formGroupOrArray: FormGroup | FormArray, control: AbstractControl, nameOrIndex: string | number) {
		this._scanResistiveDisabledControls(control);
		formGroupOrArray.disabled && control.disable();
		if (formGroupOrArray instanceof FormGroup) {
			formGroupOrArray.setControl(nameOrIndex as string, control);
		}
		else {
			formGroupOrArray.setControl(nameOrIndex as number, control);
		}
	}

	public removeControl(formGroup: FormGroup, name: string): void;
	public removeControl(formArray: FormArray, index: number): void;
	public removeControl(formGroupOrArray: FormGroup | FormArray, nameOrIndex: string | number): void {
		if (formGroupOrArray instanceof FormGroup) {
			formGroupOrArray.removeControl(nameOrIndex as string);
		}
		else {
			formGroupOrArray.removeAt(nameOrIndex as number);
		}
		/** HOT FIX */
		formGroupOrArray.markAsDirty();
	}

	protected _optionsFromEnum<U extends {} | string[]>(enumeration: U): { label: string, value: string }[] {
		const keys = (Array.isArray(enumeration) ? enumeration : Object.values(enumeration)) as string[];
		return keys.map(x => ({ label: this._i18n.t(x), value: x }));
	}
}
