import {Observable} from 'rxjs';
import {Response, UriContext} from 'kn-http';
import {AbstractFetcher} from './abstract-fetcher';
import {ChangeAction, ChangeEntry} from '../change-notification/types';
import {Indexer} from '../types';

export abstract class AbstractConcreteFetcher<T extends { [key: string]: any }> extends AbstractFetcher<T> {
	private _onChangeCallback: (change: ChangeEntry) => void = (change: ChangeEntry) => { /* intentionally empty */ };

	public registerOnChange(callback: (change: ChangeEntry) => void) {
		this._onChangeCallback = callback;
	}

	public query(context?: UriContext): Observable<T[]> {
		return this._get<T[]>(null, context);
	}

	public get(indexer: Indexer, context?: UriContext): Observable<T> {
		return this._get<T>(indexer, context);
	}

	public head(indexer: Indexer, context?: UriContext): Observable<Response> {
		return this._head(indexer, context);
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		return this._postOrPut(item, context);
	}

	protected _remove(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._delete(indexer, context);
	}

	protected abstract _get(indexer: Indexer, context: UriContext): Observable<T | T[]>;
	protected abstract _get<U extends T | T[]>(indexer: Indexer, context: UriContext): Observable<U>;
	protected abstract _head(indexer: Indexer, context: UriContext): Observable<Response>;
	protected abstract _postOrPut(item: T, context: UriContext): Observable<Response>;
	protected abstract _delete(indexer: Indexer, context: UriContext): Observable<Response>;

	protected _emitChange(indexer: Indexer, action: ChangeAction) {
		this._onChangeCallback({
			action: action,
			table: this.table,
			indexer: indexer
		});
	}
}
