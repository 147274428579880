export interface MultiplexPoolProxy<T> {
	getter: () => T;
	setter?: (value: T, active: boolean) => void;
}

export class MultiplexPool<T> {
	private readonly _pool: MultiplexPoolProxy<T>[] = [];
	private _index: number;

	public unshift(proxy: MultiplexPoolProxy<T>) {
		this._pool.unshift(proxy);
	}

	public push(proxy: MultiplexPoolProxy<T>) {
		this._pool.push(proxy);
	}

	public remove(proxy: MultiplexPoolProxy<T>) {
		const index = this._pool.indexOf(proxy);
		if (index !== -1) {
			if (index === this._index) {
				this._index = null;
			}
			else if (index < this._index) {
				this._index--;
			}
			this._pool.splice(index, 1);
		}
	}

	public get value(): T {
		let value: T;
		for (let i = 0; i < this._pool.length; i++) {
			value = this._pool[i].getter();
			if (value != null) {
				this._index = i;
				break;
			}
		}
		return value;
	}

	public set value(value: T) {
		for (let i = 0; i < this._pool.length; i++) {
			this._pool[i].setter && this._pool[i].setter(value, this._index === i);
		}
	}
}
