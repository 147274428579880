<kn-sticky>
	<div class="grid-panel">
		<kn-grid-selector [bond]="bonding.datagrid"></kn-grid-selector>
		<kn-grid-actions [bond]="bonding.datagrid" (command)="execute($event)"></kn-grid-actions>
		<div class="widgets">
			<kn-grid-query-filter
					[description]="bonding.filter.description"
					[model]="bonding.filter.model"
					(modelChange)="bonding.filter.onModelChange($event)"></kn-grid-query-filter>
			<kn-grid-exporter [view]="view" [bond]="bonding.datagrid"></kn-grid-exporter>
			<kn-grid-search [bond]="bonding.datagrid"></kn-grid-search>
		</div>
	</div>
</kn-sticky>
<kn-datagrid [bond]="bonding.datagrid"></kn-datagrid>
