import {Inject, NgModule, InjectionToken, Optional, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {forRootGuardFactory} from 'kn-shared';
import {HasLabelPipe} from './pipes/has-label.pipe';
import {PropertyPipe} from './pipes/property.pipe';
import {TabulateNodeTreePipe} from './pipes/tabulate-node-tree.pipe';
import {KnCellRenderer} from './features/cell-renderer.component';
import {KnReordable} from './features/reordable.directive';
import {KnReordableHandler} from './features/reordable-handler.component';
import {KnResizableHandler} from './features/resizable-handler.component';
import {KnColumnContextMenu} from './features/context-menu/column-context-menu.component';
import {KnColumnContextMenuNode} from './features/context-menu/column-context-menu-node.component';
import {KnColumnContextMenuActivator} from './features/context-menu/column-context-menu-activator.directive';
import {KnReordableRowsContainer} from './features/reordable-rows-container.directive';
import {KnRowSelect} from './features/row-select.directive';
import {KnKeysControl} from './features/keys-control.directive';
import {KnDatagridPropertyBinding, KnDatagridServiceBinding} from './datagrid.component';
import {KnTable} from './parts/table.component';
import {KnTableHead} from './parts/table-head.component';
import {KnTableBody} from './parts/table-body.component';
import {KnTableRow} from './parts/table-row.directive';
import {KnTableCell} from './parts/table-cell.component';
import {KnNumberedSelector} from './renderers/components/numbered-selector.component';
import {NodeTreeBuilderFactory} from './services/note-tree-builder-factory.service';
import {ColumnsBuilderFactory} from './services/columns-builder-factory.service';
import {SpreadsheetBuilder} from './services/spreadsheet-builder.service';
import {RowsVisibility} from './services/rows-visibility.service';
import {RowsNumbering} from './services/rows-numbering.service';
import {RowsTreeMarkClass} from './services/rows-tree-mark-class.service';
import {GroupIdsCache} from './services/group-ids-cache.service';
import {KnPortalModule} from 'kn-overlay';

export const KN_DATAGRID_FORROOT_GUARD = new InjectionToken<void>('KN_DATAGRID_FORROOT_GUARD');

const KN_DATAGRID_DIRECTIVES = [
	KnDatagridPropertyBinding,
	KnDatagridServiceBinding,
	KnTable,
	KnTableHead,
	KnTableBody,
	KnTableRow,
	KnTableCell,
	KnCellRenderer,
	KnReordable,
	KnReordableHandler,
	KnResizableHandler,
	KnColumnContextMenu,
	KnColumnContextMenuNode,
	KnColumnContextMenuActivator,
	KnReordableRowsContainer,
	KnRowSelect,
	KnKeysControl,
	KnNumberedSelector
];

export const KN_DATAGRID_PIPES = [
	HasLabelPipe,
	PropertyPipe,
	TabulateNodeTreePipe
];

const KN_DATAGRID_PROVIDERS = [
	NodeTreeBuilderFactory,
	ColumnsBuilderFactory,
	SpreadsheetBuilder,
	RowsTreeMarkClass,
	GroupIdsCache,
	RowsVisibility,
	RowsNumbering
];

@NgModule({
	imports: [CommonModule, KnPortalModule],
	declarations: [
		KN_DATAGRID_PIPES,
		KN_DATAGRID_DIRECTIVES
	],
	entryComponents: [KnNumberedSelector],
	exports: [KnDatagridPropertyBinding, KnDatagridServiceBinding]
})
export class KnDatagridModule {
	// eslint-disable-next-line no-empty, no-empty-function, @typescript-eslint/no-empty-function
	public constructor(@Optional() @Inject(KN_DATAGRID_FORROOT_GUARD) guard: any) { }

	public static forRoot(): ModuleWithProviders<KnDatagridModule> {
		return {
			ngModule: KnDatagridModule,
			providers: [
				forRootGuardFactory('KnDatagridModule', KN_DATAGRID_FORROOT_GUARD, KN_DATAGRID_PROVIDERS[0]),
				KN_DATAGRID_PROVIDERS
			]
		};
	}

	public static forChild(): ModuleWithProviders<KnDatagridModule> {
		return {
			ngModule: KnDatagridModule
		};
	}
}
