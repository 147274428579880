import {Aggregator, CellContext} from '../types';
import {AggregatorBase} from './aggregator-base';

export class MaxAggregator extends AggregatorBase  {
	public create<T>(compareProperty: keyof T | { (item: T): number }): Aggregator<T> {
		return (values: any[], ctx: CellContext<T>) => {
			if (compareProperty == null) {
				values = values.filter(x => x != null);
				return values.length === 0 ? null : values.reduce((p, c) => p > c ? p : c, null);
			}
			const nodeValue = this._lookupNode(
				ctx.node,
				ctx.column.description.accessor && (i => ctx.column.description.accessor(i, ctx.column)) || ctx.column.id as keyof T,
				aggs => aggs.reduce((p, c) => p.weight > c.weight ? p : c, {weight: null, value: null}),
				compareProperty
			);
			return nodeValue.value;
		};
	}
}
