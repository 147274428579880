import {Observable} from 'rxjs';
import {Response, UriContext} from 'kn-http';
import {Fetcher} from 'kn-rest';
import {ISyncableEntity} from 'common-web/model';

export class SyncableFetcher<T extends ISyncableEntity> extends Fetcher<T> {
	protected _postOrPut(item: T, context: UriContext): Observable<Response> {
		delete item.syncId;
		return super._postOrPut(item, context);
	}
}
