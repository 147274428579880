import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Response, UriContext} from 'kn-http';
import {AbstractResource, Indexer, ChangeEntry} from 'kn-rest';
import {AbstractEntityStore} from './abstract-entity-store';
import {EntityUtils} from '../../entity-utils';
import {IndexerRetriver} from './types';
import * as Model from 'common-web/model';

export class EntityStore<T extends Model.EntityBase> extends AbstractEntityStore<T> {
	public constructor(
			protected _resource: AbstractResource<T>,
			indexerRetriver: IndexerRetriver<T> | string,
			context?: UriContext) {
		super(indexerRetriver, context);
	}

	public get changes(): Observable<ChangeEntry> {
		return EntityUtils.mergeChanges(this._resource);
	}

	protected _get(indexer: Indexer, context: UriContext) {
		return this._resource.get(indexer, context);
	}

	protected _query(context: UriContext) {
		return this._resource.query(context);
	}

	protected _save(item: T): Observable<Response> {
		const managedReferences = Object.keys(this._resource.getReferences());
		const supplement = EntityUtils.exceptReferences(item, managedReferences);
		return this._resource.save(item)
			.pipe(Rx.finalize(() => Object.assign(item, supplement)));
	}

	protected _remove(indexer: Indexer): Observable<Response> {
		return this._resource.remove(indexer);
	}
}
