import {Component, ViewChild, AfterContentInit, ViewContainerRef, ChangeDetectorRef} from '@angular/core';
import {OverlayService} from './overlay.service';

@Component({
	selector: 'kn-overlay-host',
	template: '<ng-template #host></ng-template>'
})
export class KnOverlayHost implements AfterContentInit {
	@ViewChild('host', { static: true, read: ViewContainerRef })
	private readonly _host: ViewContainerRef;

	public constructor(
			private readonly _cdr: ChangeDetectorRef,
			private readonly _overlay: OverlayService) {
	}

	public ngAfterContentInit() {
		this._cdr.detectChanges();
		this._overlay.host = this._host;
	}
}
