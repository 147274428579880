import {Utils} from 'kn-utils';
import {UserSettingLinkPropertyMetadata} from './user-setting-link-property.decorator';
import {UserSettingProxyMetadata} from './user-setting-proxy.decorator';
import {UserSettingMetadata} from './user-setting.decorator';
import {KeySpecifier} from '../types';

export interface UserSettingLinkMetadataFactory {
	(key?: KeySpecifier, propertyName?: string, discriminatorArg?: any): any;
	new (key?: KeySpecifier, propertyName?: string, discriminatorArg?: any): any;
}

export interface UserSettingMetadataFactory {
	(key?: KeySpecifier, discriminatorArg?: any): any;
	new (key?: KeySpecifier, discriminatorArg?: any): any;
}

const userSettingLinkProperty: UserSettingLinkMetadataFactory = Utils.reflector.makeDecorator(UserSettingLinkPropertyMetadata);
const userSettingProxy: UserSettingMetadataFactory = Utils.reflector.makePropDecorator(UserSettingProxyMetadata);
const userSetting: UserSettingMetadataFactory = Utils.reflector.makePropDecorator(UserSettingMetadata);

export function UserSettingLinkProperty(key: string, propertyName?: string, version?: string, discriminatorArg?: any): any {
	return (target: string, name: string) =>
		userSettingLinkProperty(version == null ? key : { key, version }, propertyName, discriminatorArg)(target, name);
}

export function UserSettingProxy(key?: string, version?: string, discriminatorArg?: any): any {
	return (target: string, name: string) =>
		userSettingProxy(version == null ? key : { key, version }, discriminatorArg)(target, name);
}

export function UserSetting(key?: string, version?: string, discriminatorArg?: any): any {
	return (target: string, name: string) =>
		userSetting(version == null ? key : { key, version }, discriminatorArg)(target, name);
}
