import {Injectable} from '@angular/core';
import {Observable, of as observableOf, from as observableFrom, combineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {UriContext, mergeContext} from 'kn-http';
import {Description, Model, OperatorSettingDescription, FilterNode, Filter, FilterValue, ExpandOptionDescription} from 'kn-query-filter';

interface ExpanderOptionDefinition<T> {
	selector: string;
	label: string;
	placeholder: string;
	operator: string;
	resolver: (filter: Filter<T>, fetcher: (context: UriContext) => Observable<any[]>, baseContext?: UriContext) => Observable<any[]>;
	evaluator: (filter: Filter<T>, ...args: any[]) => void;
}

export interface NodeExpanderOptionDefinition<T> {
	value: string | number;
	label: string;
	placeholder: string;
	resolver: (filter: Filter<T>, fetcher: (context: UriContext) => Observable<any[]>, baseContext?: UriContext) => Observable<any[]>;
	evaluator: (filter: Filter<T>, ...args: any[]) => void;
	nodeExpander: (filter: Filter<T>, ...args: any[]) => FilterNode<T> | Filter<T>;
}

interface ExpanderDefinition<T> {
	operators: OperatorSettingDescription[];
	options: (ExpanderOptionDefinition<T> | NodeExpanderOptionDefinition<T>)[];
	changeOperatorValueAdaption: (filter: Filter<T>, oldOperator: string, newOperator: string) => boolean;
}

type ExpanderEntries = { [type: string]: ExpanderDefinition<FilterValue> };

@Injectable()
export class QueryFilterExpanderService {
	private entries: ExpanderEntries;

	public constructor(protected i18n: I18nService) { }

	private get Entries(): ExpanderEntries {
		if (this.entries == null) {
			this.entries = {
				'date': {
					operators: [
						{
							id: 'eq',
							label: this.i18n.t('for last'),
							presenter: 'combobox'
						}
					],
					options: [
						{
							selector: 'empty',
							label: this.i18n.t('not set'),
							placeholder: '::empty',
							operator: 'between',
							resolver: null,
							evaluator: filter => []
						}, {
							selector: '1d',
							label: this.i18n.t('day'),
							placeholder: '::last-1d',
							operator: 'between',
							resolver: null,
							evaluator: filter => this._getDateRange(0, 1)
						}, {
							selector: '7d',
							label: this.i18n.t('week'),
							placeholder: '::last-7d',
							operator: 'between',
							resolver: null,
							evaluator: filter => this._getDateRange(0, 7)
						}, {
							selector: '1m',
							label: this.i18n.t('month'),
							placeholder: '::last-1m',
							operator: 'between',
							resolver: null,
							evaluator: filter => this._getDateRange(1, 0)
						}, {
							selector: '3m',
							label: this.i18n.t('3 months'),
							placeholder: '::last-3m',
							operator: 'between',
							resolver: null,
							evaluator: filter => this._getDateRange(3, 0)
						}, {
							selector: '1y',
							label: this.i18n.t('year'),
							placeholder: '::last-1y',
							operator: 'between',
							resolver: null,
							evaluator: filter => this._getDateRange(12, 0)
						}, {
							selector: '1D',
							label: this.i18n.t('day from last occurrence'),
							placeholder: '::last-1d-occurrence',
							operator: 'between',
							resolver: this._lastDateResolver,
							evaluator: (filter, date) => this._getDateRange(0, 1, date)
						}, {
							selector: '7D',
							label: this.i18n.t('week from last occurrence'),
							placeholder: '::last-7d-occurrence',
							operator: 'between',
							resolver: this._lastDateResolver,
							evaluator: (filter, date) => this._getDateRange(0, 7, date)
						}, {
							selector: '1M',
							label: this.i18n.t('month from last occurrence'),
							placeholder: '::last-1m-occurrence',
							operator: 'between',
							resolver: this._lastDateResolver,
							evaluator: (filter, date) => this._getDateRange(1, 0, date)
						}, {
							selector: '3M',
							label: this.i18n.t('3 months from last occurrence'),
							placeholder: '::last-3m-occurrence',
							operator: 'between',
							resolver: this._lastDateResolver,
							evaluator: (filter, date) => this._getDateRange(3, 0, date)
						}, {
							selector: '1Y',
							label: this.i18n.t('year from last occurrence'),
							placeholder: '::last-1y-occurrence',
							operator: 'between',
							resolver: this._lastDateResolver,
							evaluator: (filter, date) => this._getDateRange(12, 0, date)
						}, {
							selector: '1c',
							label: this.i18n.t('calendar month'),
							placeholder: '::last-calendar-1m',
							operator: 'between',
							resolver: this._lastCalendarMonthResolver,
							evaluator: (filter, date) => {
								const startDate = date ? new Date(date) : new Date();
								const endDate = date ? new Date(date) : new Date();
								startDate.setHours(0, 0, 0, 0);
								endDate.setHours(23, 59, 0, 0);
								startDate.setDate(1);
								return [startDate, endDate];
							}
						}
					],
					changeOperatorValueAdaption: (filter, oldOperator, newOperator) => {
						if (oldOperator === 'between' && newOperator === 'eq') {
							filter.value = '::last-1m';
							return true;
						}
						else if (oldOperator === 'eq' && newOperator === 'between') {
							const startDate = new Date();
							const endDate = new Date();
							startDate.setHours(0, 0, 0, 0);
							endDate.setHours(23, 59, 0, 0);
							filter.value = [startDate, endDate];
							return true;
						}
						return false;
					}
				},
				'identifier': {
					operators: [],
					options: [],
					changeOperatorValueAdaption: () => false
				}
			};
		}
		return this.entries;
	}

	public _lastDateResolver<T>(filter: Filter<T>, fetcher: (context: UriContext) => Observable<any[]>, baseContext?: UriContext) {
		const context = mergeContext(
			{
				query: {
					sort: '-' + filter.id,
					limit: 1,
					select: filter.id
				}
			}, baseContext || {}
		);
		return fetcher(context).pipe(
			Rx.map(next => {
				const dates = next.filter(x => x != null && Utils.isString(x))
					.map(x => Utils.date.fromIso8601(x));
				return dates.length === 0 ? [] : [Math.max.apply(null, dates)];
			})
		);
	}

	private _lastCalendarMonthResolver() {
		const date = new Date();
		date.setMonth(date.getMonth(), 0);
		return observableOf([date]);
	}

	private _getDateRange(months: number, days: number, date?: Date) {
		const startDate = date ? new Date(date) : new Date();
		const endDate = date ? new Date(date) : new Date();
		startDate.setHours(0, 0, 0, 0);
		endDate.setHours(23, 59, 59, 0);
		startDate.setMonth(startDate.getMonth() - months);
		startDate.setDate(startDate.getDate() - days);
		return [startDate, endDate];
	}

	public buildDateOptions<T>(...selectors: string[]) {
		return selectors.map(selector => {
			const option = (this.Entries['date'].options as ExpanderOptionDefinition<T>[])
				.find(x => x.selector === selector);
			if (option == null) {
				throw new Error(`Cannot find definition for ${selector}.`);
			}
			return {
				label: option.label,
				value: option.placeholder
			};
		});
	}

	public buildExpandOptions<T>(
			placeholder: string,
			options: ({label: string, value: string | number })[],
			resolver: (filter: Filter<T>, fetcher: (context: UriContext) => Observable<any[]>, baseContext?: UriContext) => Observable<any[]>,
			nodeExpander: (filter: Filter<T>, ...args: any[]) => FilterNode<T> | Filter<T>) {
		return options.map(opt => Object.assign(opt, { placeholder, resolver, nodeExpander }) as NodeExpanderOptionDefinition<T>);
	}

	public extendDescription(description: Description) {
		let adaption = description.changeOperatorValueAdaption;
		for (const key in this.Entries) {
			if (this.Entries.hasOwnProperty(key)) {
				const typeSetting = description.typeSettings.find(x => x.id === key);
				for (const operator of this.Entries[key].operators) {
					typeSetting.operators.unshift(operator);
				}
				const fallbackAdaption = adaption;
				adaption = (filter: Filter<FilterValue>, oldOperator: string, newOperator: string) => {
					const type = description.options.find(x => x.id === filter.id).type;
					if (type === key && this.Entries[key]
							.changeOperatorValueAdaption(filter, oldOperator, newOperator)) {
						return;
					}
					fallbackAdaption(filter, oldOperator, newOperator);
				};
			}
		}
		description.changeOperatorValueAdaption = adaption;
	}

	public expandModel(model: Model, description: Description, fetcher: (context: UriContext) => Observable<any[]>, baseContext?: UriContext) {
		const result = Utils.clone(model, true);

		const resolvers: { [placeholder: string]: Observable<any[]> } = {};
		this._forEachFilterLeaf(result, x => {
			const option = this._getOption(x, description);
			if (option != null
					&& option.resolver != null
					&& !resolvers.hasOwnProperty(option.placeholder)) {
				resolvers[option.placeholder] = option.resolver(x, fetcher, baseContext);
			}
		});
		const $options: Observable<any>[] = [];
		const options: { [id: string]: { [value: string]: any} } = {};
		this._forEachFilterLeaf(result, x =>
			$options.push(
				this._getDescriptionOption(x, description).pipe(
					Rx.filter(option => option != null),
					Rx.tap(option => {
						if (options[x.id]) {
							options[x.id][option.value] = option;
						}
						else {
							options[x.id] = { [option.value]: option };
						}
						if (option != null
								&& option.resolver != null
								&& !resolvers.hasOwnProperty(option.placeholder)) {
							resolvers[option.placeholder] = option.resolver(x, fetcher, baseContext);
						}
					})
				)
			)
		);
		return combineLatest(...$options).pipe(
			Rx.defaultIfEmpty({}),
			Rx.switchMap(next => observableFrom(Object.keys(resolvers))),
			Rx.toArray(),
			Rx.switchMap(next => combineLatest(next.map(placeholder => resolvers[placeholder].pipe(
				Rx.map(resolvedValue => ({ [placeholder]: resolvedValue }))
			)))),
			Rx.map(next => next.reduce((acc, res) => Object.assign(acc, res), {})),
			Rx.defaultIfEmpty<{ [id: string]: any[] }>({}),
			Rx.map(next => {
				this._forEachFilterLeaf(result, x => {
					const option = this._getOption(x, description);
					if (option != null) {
						x.value = option.evaluator(x, ...(next[option.placeholder] || []));
						x.operator = (option as ExpanderOptionDefinition<any>).operator;
					}
				});
				this._forEachFilterLeafExpand(result, x => {
					const option = options && options[x.id] && options[x.id][x.value];
					if (option != null && option.hasOwnProperty('nodeExpander')) {
						return (option as NodeExpanderOptionDefinition<any>).nodeExpander(x, ...(next[option.placeholder] || []));
					}
					return x;
				});
				return result;
			})
		);
	}

	private _forEachFilterLeaf<T>(node: FilterNode<T> | Filter<T>, visitor: (leaf: Filter<T>) => void): void {
		if (node.hasOwnProperty('children')) {
			(node as FilterNode<T>).children.forEach(x => this._forEachFilterLeaf(x, visitor));
		}
		else {
			visitor(node as Filter<T>);
		}
	}

	private _forEachFilterLeafExpand<T>(node: FilterNode<T> | Filter<T>, expander: (node: Filter<T>) => FilterNode<T> | Filter<T>): FilterNode<T> | Filter<T> {
		if (node.hasOwnProperty('children')) {
			const filterNode = node as FilterNode<T>;
			for (let idx = 0; idx < filterNode.children.length; idx++) {
				filterNode.children[idx] = this._forEachFilterLeafExpand(filterNode.children[idx], expander);
			}
		}
		return expander(node as Filter<T>);
	}

	private _getOption<T extends FilterValue>(filter: Filter<T>, description: Description) {
		const type = description.options.find(x => x.id === filter.id).type;
		if (!this.Entries.hasOwnProperty(type)) {
			return null;
		}
		const entry = this.Entries[type];
		if (!entry.operators.some(x => x.id === filter.operator)) {
			return null;
		}
		return entry.options.find(x => x.placeholder === filter.value as FilterValue);
	}

	private _getDescriptionOption<T extends FilterValue>(filter: Filter<T>, description: Description) {
		const entry = description.options.find(x => x.id === filter.id) as ExpandOptionDescription;
		const options = entry && entry.options;
		if (options == null) {
			return observableOf(null);
		}
		return (Utils.isObservable(options)
				? (options as Observable<NodeExpanderOptionDefinition<T>[]>)
				: observableOf(options as NodeExpanderOptionDefinition<T>[])).pipe(
			Rx.filter(next => next != null),
			Rx.map(next => next.find(x => x.value === filter.value as FilterValue))
		);
	}
}
