<div class="header">
	<kn-date-header
		[date]="viewDate"
		(selectPrevMonthView)="prevMonth()"
		(selectNextMonthView)="nextMonth()"></kn-date-header>
</div>
<div class="view">
	<kn-date-view
		[(date)]="date"
		[(viewDate)]="viewDate"
		(accept)="acceptDate()"></kn-date-view>
</div>
