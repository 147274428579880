import {AbstractTagger} from './abstract-tagger';

export class Tagger<T extends {}> extends AbstractTagger<T> {
	public constructor(private readonly _evaluator: (item: T) => string[]) {
		super();
	}

	public evaluate(item: T): string[] {
		return this._evaluator(item);
	}

	public static from<U>(evaluator: (item: U) => string[]) {
		return new Tagger(evaluator);
	}
}
