import {NgModule, Optional, SkipSelf, InjectionToken, ModuleWithProviders} from '@angular/core';
import {UserSettingsFactory, UserSettingsConfig} from './user-settings-factory.service';
import {PreloadUserSettingsGuard} from './preload-user-settings.guard';
import {EraseIncompatibleMigrationStrategy} from './migration-strategies/erase-incompatible-migration-strategy';
import {PrefixDiscriminateStrategy} from './discriminate-strategies/prefix-discriminate-strategy';
import {AbstractStoreBackend} from './backends/abstract-store-backend';
import {NullDiscriminatorProvider} from './discriminator-providers/null-discriminator-provider';
import {USER_SETTINGS_MIGRATION_STRATEGIES_TOKEN} from './user_settings_migration_strategies.token';
import {USER_SETTINGS_DISCRIMINATE_STRATEGIES_TOKEN} from './user_settings_discriminate_strategies.token';
import {USER_SETTINGS_DISCRIMINATE_PROVIDER_TOKEN} from './user_settings_discriminate_provider.token';
import {USER_SETTINGS_BACKENDS_TOKEN} from './user_settings_backends.token';

export const USER_SETTINGS_FORROOT_GUARD = new InjectionToken<void>('USER_SETTINGS_FORROOT_GUARD');

@NgModule()
export class KnUserSettingsModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnUserSettingsModule) {
		if (parentModule) {
			throw new Error('KnUserSettingsModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(backends?: AbstractStoreBackend[], config?: UserSettingsConfig): ModuleWithProviders<KnUserSettingsModule> {
		return {
			ngModule: KnUserSettingsModule,
			providers: [
				{
					provide: UserSettingsConfig,
					useValue: config ? config : {}
				}, {
					provide: USER_SETTINGS_MIGRATION_STRATEGIES_TOKEN,
					useClass: EraseIncompatibleMigrationStrategy,
					multi: true
				}, {
					provide: USER_SETTINGS_DISCRIMINATE_STRATEGIES_TOKEN,
					useClass: PrefixDiscriminateStrategy,
					multi: true
				}, {
					provide: USER_SETTINGS_DISCRIMINATE_PROVIDER_TOKEN,
					useClass: NullDiscriminatorProvider
				}, {
					provide: USER_SETTINGS_BACKENDS_TOKEN,
					useValue: backends ? backends : []
				},
				UserSettingsFactory,
				PreloadUserSettingsGuard
			]
		};
	}
}
