import {Injectable} from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';
import {GravatarConfig} from './gravatar.config';

@Injectable()
export class KnGravatar {
	public constructor(private readonly _options: GravatarConfig) { }

	public url(src: string, options: { [name: string]: any }) {
		const prefix = this._options.protocol ? (this._options.protocol + ':') : '';
		let uri = this._options.secure ? 'https://secure' : (prefix + '/' + '/www');
		uri += '.gravatar.com/avatar/' + Md5.hashStr(src || '');
		const query: string[] = [];
		const params = Object.assign({}, this._options.params, options || {}) as { [name: string]: any };
		for (const key in params) {
			if (params.hasOwnProperty(key)) {
				query.push(key + '=' + encodeURIComponent(params[key]));
			}
		}
		if (query.length > 0) {
			uri += '?' + query.join('&');
		}
		return uri;
	}
}
