export abstract class AbstractOptionComparer<T> {
	public constructor(public accessor?: (item: T) => string) { }

	public getValue(item: T, accessor?: (item: T) => string) {
		if (accessor) {
			return accessor(item);
		}
		return this.accessor ? this.accessor(item) : `${item}`;
	}

	public compare(value: string, query: string, strict: boolean = false): boolean {
		return this._compare(value, this._prepareQuery(query), strict);
	}

	protected _prepareQuery(query: string): string {
		return query;
	}

	protected abstract _compare(value: string, query: string, strict: boolean): boolean;
}
