import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, HostListener, ElementRef} from '@angular/core';
import {DayOfWeek} from '../../types';
import {L10nService} from '../../services/l10n.service';

@Component({
	selector: 'kn-month-view',
	templateUrl: 'month-view.html'

})
export class KnMonthView implements OnInit, OnChanges {
	@Input() public firstDayOfWeek: DayOfWeek = DayOfWeek.Monday;
	@Input() public year: number;
	@Input() public month: number;
	@Input() public day: number;
	@Output() public dayChange = new EventEmitter<number>();
	@Output() public accept = new EventEmitter<number>();

	public weekdayNames: string[];
	public nowDay: number;
	public monthDays: number[][];

	@HostListener('wheel', ['$event'])
	public wheelHandler(event: WheelEvent) {
		if (this.day == null) {
			this.day = 0;
		}
		const endDate = new Date(this.year, this.month + 1, 0);
		const daysOfMonth = endDate.getDate();
		if (event.deltaY > 0) {
			this.changeDay(this.day + (this.day >= daysOfMonth ? -daysOfMonth : 1));
		}
		else if (event.deltaY < 0) {
			this.changeDay(this.day + (this.day <= 0 ? daysOfMonth - 1 : -1));
		}
		this._element.nativeElement.focus();
		event.preventDefault();
	}

	@HostListener('keydown.enter', ['$event'])
	public enterHandler(event: KeyboardEvent) {
		if (this.day != null) {
			this.accept.emit(this.day);
		}
		event.preventDefault();
	}

	public constructor(
			private readonly _element: ElementRef,
			private readonly _l10n: L10nService) {
	}

	public ngOnInit() {
		this._update();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('year' in changes || 'month' in changes || 'firstDayOfWeek' in changes) {
			this._update();
		}
	}

	public changeDay(value: number) {
		this.day = value;
		this.dayChange.emit(this.day);
	}

	public selectDay(value: number) {
		this.changeDay(value);
		this.accept.emit(this.day);
	}

	private _update() {
		this.weekdayNames = [];
		for (let i = 0; i < 7; i++) {
			this.weekdayNames.push(this._l10n.getWeekdayName((this.firstDayOfWeek + i) % 7));
		}

		const startDate = new Date(this.year, this.month, 1);
		const endDate = new Date(this.year, this.month + 1, 0);

		const firstDayOfMonth = (startDate.getDay() + this.firstDayOfWeek + 6) % 7;
		const daysOfMonth = endDate.getDate();

		const nowDate = new Date();
		this.nowDay = null;
		if (nowDate.getFullYear() === startDate.getFullYear()
				&& nowDate.getMonth() === startDate.getMonth()) {
			this.nowDay = nowDate.getDate() - 1;
		}

		let dayIndex = 0;
		this.monthDays = [];
		for (let i = 0; i < Math.ceil((firstDayOfMonth + daysOfMonth) / 7); i++) {
			const weekDays: number[] = [];
			for (let j = 0; j < 7; j++) {
				if (dayIndex < firstDayOfMonth || dayIndex - firstDayOfMonth >= daysOfMonth) {
					weekDays.push(null);
				}
				else {
					weekDays.push(dayIndex - firstDayOfMonth);
				}
				dayIndex++;
			}
			this.monthDays.push(weekDays);
		}
	}
}
