<tr *ngFor="let node of tree | tabulateNodeTree: description.rows.visible; trackBy: nodeTrackBy"
		knTableRow knRowSelect
		[description]="description"
		[node]="node"
		(click)="rowSelect.emit(node.item)"
		(dblclick)="rowActivate.emit(node.item)"
		[class.selectable]="isSelectable(node)"
		[ngClass]="getRowClases(node)">
	<td *ngFor="let column of visibleColumns"
			knTableCell
			[column]="column"
			[node]="node"
			[ngClass]="getCellClasses(node, column)"></td>
</tr>
