<kn-sticky>
	<div class="form-container heading">
		<a href="javascript:void(0)" class="form-back" (click)="navigateBack()"><i class="material-icons">arrow_back</i></a>
		<div class="content">
			<h1>
				<ng-container *ngIf="isEditMode()" i18n>Edit user {{ name }}</ng-container>
				<ng-container *ngIf="!isEditMode()" i18n>New user</ng-container>
			</h1>
			<div *ngIf="outdated" class="form-actions-info warning">
				<i class="material-icons">warning</i> <ng-container i18n>Editing outdated copy</ng-container>
			</div>
			<div class="form-buttons">
				<button type="button" class="danger"
						*ngIf="isEditMode() && deletable" (click)="delete()" i18n>Remove</button>
				<button type="submit" *ngIf="savable" [disabled]="!form.valid" form="main-form">
					<ng-container *ngIf="isEditMode()" i18n>Save</ng-container>
					<ng-container *ngIf="!isEditMode()" i18n>Create</ng-container>
				</button>
			</div>
		</div>
	</div>
</kn-sticky>

<div class="form-container">
	<form id="main-form" (ngSubmit)="save()" [formGroup]="form" class="form-layout">

		<div class="form-grid">
			<div class="row">
				<div class="form-box flex-4" formGroupName="user">
					<div class="content feature">
						<div class="row">
							<div class="form-input flex-2">
								<kn-input type="text" i18n-placeholder="name-placeholder" placeholder="John Doe" formControlName="fullName" required>
									<span kn-label i18n>Full Name</span>
									<kn-control-messages kn-hint control="fullName"></kn-control-messages>
								</kn-input>
							</div>

							<div class="form-input flex-1 flex-middle">
								<kn-checkbox formControlName="disabled">
									<span kn-label i18n>Disabled</span>
								</kn-checkbox>
							</div>

							<div class="flex-1 flex-middle">
								<a href="https://gravatar.com" target="_blank" i18n>Edit avatar image</a>
							</div>
						</div>
					</div>

					<div class="content">
						<div class="row">
							<div class="form-input flex-2">
								<kn-input type="text" formControlName="ownership" class="ownership">
									<span kn-prefix>{{ prefixOwnership }}</span>
									<span kn-label i18n>Ownership</span>
									<kn-control-messages kn-hint control="ownership"></kn-control-messages>
								</kn-input>
							</div>

							<div class="flex-2"></div>
						</div>
					</div>
				</div>

				<div class="form-box flex-2" formGroupName="user">
					<div class="title">
						<h2 i18n>Creditials</h2>
					</div>

					<div class="content">
						<div class="form-input">
							<kn-input type="text" i18n-placeholder="username-placeholder" placeholder="john.doe@example.com" formControlName="uid" required>
								<span kn-label i18n>Username</span>
								<kn-control-messages kn-hint control="uid"></kn-control-messages>
							</kn-input>
						</div>
					</div>

					<div class="content alt">
						<ng-template #passwordForm>
							<div class="form-input">
								<kn-input type="password" formControlName="newPassword" required>
									<span kn-label i18n>Password</span>
									<kn-control-messages kn-hint control="newPassword"></kn-control-messages>
								</kn-input>
							</div>

							<div class="form-input">
								<kn-input type="password" formControlName="newPasswordCheck" required>
									<span kn-label i18n>Password check</span>
									<kn-control-messages kn-hint control="newPasswordCheck"></kn-control-messages>
								</kn-input>
							</div>
						</ng-template>

						<ng-template [ngIf]="!isEditMode()">
							<ng-template [ngTemplateOutlet]="passwordForm"></ng-template>
						</ng-template>
						<ng-template [ngIf]="isEditMode()">
							<div [hidden]="!isPasswordChangeMode()">
								<ng-template [ngTemplateOutlet]="passwordForm"></ng-template>
								<div class="alt-actions">
									<a href="javascript:void(0)" (click)="cancelPasswordChange()"><i class="material-icons">cancel</i> <ng-container i18n>Cancel password change</ng-container></a>
								</div>
							</div>
							<div [hidden]="isPasswordChangeMode()">
								<div class="alt-actions">
									<a href="javascript:void(0)" (click)="enterPasswordChange()"><i class="material-icons">lock</i> <ng-container i18n>Change password</ng-container></a>
								</div>
							</div>
						</ng-template>
					</div>
				</div>
			</div>

			<ng-container formArrayName="databasesUserRoles" #databasesUserRolesForm="knFormArray">
				<div class="form-box" *ngIf="databasesUserRolesForm.length || databasesUserRolesForm.enabled">
					<div class="title">
						<h2 i18n>Roles</h2>
						<a class="side-action" i18n-title title="Add" href="javascript:void(0)"
								*ngIf="availableDatabases().length > 0" (click)="addDatabaseUserRoles()"><i class="material-icons">add</i></a>
					</div>

					<div class="container" *ngFor="let databaseUserRolesForm of databasesUserRolesForm.controls; let i = index" [formGroupName]="i">
						<div class="content">
							<div class="row">
								<div class="form-input flex-1">
									<kn-select formControlName="databaseId" getter="id">
										<kn-option *ngFor="let database of availableDatabases(databaseUserRolesForm.controls.databaseId.value)" [value]="database">{{ database.name }}</kn-option>
										<span kn-label i18n>Database</span>
										<kn-control-messages kn-hint control="databaseId"></kn-control-messages>
									</kn-select>
								</div>

								<div class="flex-4">
									<table class="user-roles" *ngIf="queryRolesData(i) | async; let rolesData">
										<thead *ngIf="databaseUserRolesForm.controls.userRoles.controls.length > 0">
											<tr>
												<td i18n>Role</td>
												<td><ng-template [ngIf]="rolesData.restrictions.template" i18n>Restrictions</ng-template></td>
												<td></td>
											</tr>
										</thead>
										<tbody formArrayName="userRoles" class="user-roles-table">
											<tr *ngFor="let userRoleForm of databaseUserRolesForm.controls.userRoles.controls; let j = index" formGroupName="{{j}}">
												<td class="user-role">
													<kn-select formControlName="roleId" getter="id">
														<kn-option *ngFor="let role of rolesData.roles" [value]="role">{{ role.name }}</kn-option>
													</kn-select>
												</td>
												<td class="user-role-restrictions">
													<ng-template
															[ngTemplateOutlet]="rolesData.restrictions.template"
															[ngTemplateOutletContext]="{ $implicit: userRoleForm, data: rolesData.restrictions.data }"></ng-template>
												</td>
												<td class="actions">
													<button type="button" i18n-title title="Remove" (click)="removeUserRole(i, j)">
														<i class="material-icons">clear</i>
													</button>
												</td>
											</tr>
										</tbody>
										<tbody>
											<tr>
												<td class="user-role">
													<kn-select #newRoleSelect
															i18n-placeholder="new-role-placeholder" placeholder="New role..." getter="id"
															(valueChange)="addUserRole(i, newRoleSelect.value); newRoleSelect.value = ''">
														<kn-option *ngFor="let role of rolesData.roles" [value]="role">{{ role.name }}</kn-option>
														<span kn-label i18n>New role</span>
													</kn-select>
												</td>
												<td colspan="2"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div class="side-actions" *ngIf="databasesUserRolesForm.enabled">
							<a href="javascript:void(0)" i18n-title title="Remove" (click)="removeDatabaseUserRoles(i)"><i class="material-icons">clear</i></a>
						</div>
					</div>
				</div>
			</ng-container>
		</div>

		<div class="form-footer">
			<kn-audit-display [audit]="audit"></kn-audit-display>
		</div>

	</form>
</div>
