import {InjectionToken, Type, Optional, SkipSelf} from '@angular/core';

export const moduleNameToken = new InjectionToken<string>('MODULE_NAME_TOKEN');

export function provideForRootGuard(moduleName: string, alreadyProvided: any) {
	if (alreadyProvided) {
		throw new Error(`${moduleName}.forRoot() called twice. Lazy loaded modules should use ${moduleName}.forChild() instead.`);
	}
}

export function forRootGuardFactory(moduleName: string, guardToken: InjectionToken<void>, guardedProvider: Type<any> | any) {
	return [
		{
			provide: moduleNameToken,
			useValue: moduleName
		}, {
			provide: guardToken,
			useFactory: provideForRootGuard,
			deps: [moduleNameToken, [guardedProvider, new Optional(), new SkipSelf()]]
		}
	];
}
