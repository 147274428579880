import {RowItem} from '../types';

export interface NodeGroup<T> {
	id: string;
	value: T;
}

export class Node<T extends RowItem> {
	private readonly _group: NodeGroup<T>;
	private readonly _children: Node<T>[];
	private _parent: Node<T>;

	public item: T;
	public data = new Map<string, any>();
	public cache = new Map<string, any>();
	public index: number;

	public get group() { return this._group; }
	public get parent() { return this._parent; }
	public get children() { return this._children; }

	public constructor(group?: NodeGroup<T>, parent?: Node<T>, children?: Node<T>[], item?: T) {
		this._group = group;
		this._parent = parent;
		this._children = children || [];
		this.item = item;
	}

	public static root<T>(): Node<T> {
		const root = new Node<T>();
		root.index = 0;
		return root;
	}

	public static leaf<T>(item: T, parent: Node<T>): Node<T> {
		const node = new Node<T>();
		node.item = item;
		node._parent = parent;
		return node;
	}

	public static group<T>(group: NodeGroup<T>, parent: Node<T>): Node<T> {
		return new Node<T>(group, parent);
	}

	public getBranch(): Node<T>[] {
		return Node._expandBranch<T>([this]);
	}

	public isRoot(): boolean {
		return !this.parent;
	}

	public isGroup(): boolean {
		return !!this.group;
	}

	public isLeaf(): boolean {
		return this.children.length === 0;
	}

	public getRoot(): Node<T> {
		return Node._getRoot<T>(this);
	}

	public traverse(visitor: (node: Node<T>) => boolean | void): boolean {
		return Node._traverse<T>(this, visitor);
	}

	public invalidateCache(): void {
		this.cache.clear();
		this.children.forEach(x => x.invalidateCache());
	}

	private static _getRoot<T>(node: Node<T>): Node<T> {
		while (!node.isRoot()) {
			node = node.parent;
		}
		return node;
	}

	private static _expandBranch<T>(branch: Node<T>[]): Node<T>[] {
		if (!branch[0].isRoot() && !branch[0].parent.isRoot()) {
			branch.unshift(branch[0].parent);
			return Node._expandBranch(branch);
		}
		return branch;
	}

	private static _traverse<T>(node: Node<T>, visitor: (node: Node<T>) => boolean | void): boolean {
		while (node) {
			if (visitor(node) === false) {
				return false;
			}
			node = node.parent;
		}
		return true;
	}
}
