import {Injectable} from '@angular/core';
import {Column, RowValueResolver, RowItem} from '../types';
import {Node} from '../model/node';
import {Utils} from 'kn-utils';

type VisibleFunctor<T> = (item: T, column: Column<T>) => boolean;

@Injectable()
export class RowsVisibility {
	public key: string = 'visible';

	public getForNode(node: Node<RowItem>): number {
		return node.cache.get(this.key);
	}

	public calculate(node: Node<RowItem>, visible: RowValueResolver<RowItem, boolean>): RowsVisibility {
		if (!node.cache.has(this.key)) {
			this._calculate(node, visible);
		}
		return this;
	}

	private _calculate(node: Node<RowItem>, visible: RowValueResolver<RowItem, boolean>) {
		let visibleFunctor: VisibleFunctor<RowItem>;
		if (Utils.isFunction(visible)) {
			visibleFunctor = visible as VisibleFunctor<RowItem>;
		}
		else {
			visibleFunctor = (item: RowItem, column: Column<RowItem>) => visible as boolean;
		}
		this._calculateSubtree(node.getRoot(), visibleFunctor);
	}

	private _calculateSubtree(node: Node<RowItem>, visibleFunctor: VisibleFunctor<RowItem>): boolean {
		let visible: boolean;
		if (node.isLeaf()) {
			visible = visibleFunctor(node.item, null);
		}
		else {
			visible = node.children
					.reduce((acc, x) => this._calculateSubtree(x, visibleFunctor) || acc, false);
		}
		node.cache.set(this.key, visible);
		return visible;
	}
}
