import {Injectable} from '@angular/core';
import {KnPortalContainer} from './portal-container.component';
import {KnPortal} from './portal-directives';

@Injectable()
export class KnPortalService {
	private _host: KnPortalContainer;

	public set host(value: KnPortalContainer) {
		if (value == null) {
			throw new Error('Container must be valid KnPortalContainer.');
		}
		this._host = value;
	}

	public attachPortal(portal: KnPortal, modal: boolean = false) {
		this._host.attachPortal(portal, modal);
	}

	public detachPortal(portal: KnPortal) {
		this._host.detachPortal(portal);
	}

	public registerPortal(portal: KnPortal) {
		this._host.registerPortal(portal);
	}

	public unregisterPortal(portal: KnPortal) {
		this._host.unregisterPortal(portal);
	}
}
