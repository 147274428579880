import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource, AbstractResource} from 'kn-rest';
import {RolesConfig} from './roles.config';
import * as CommonModel from '../../model/common-database.types';

@Injectable()
export class RolesResourceService extends Resource<CommonModel.Role> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: RolesConfig) {
		super(rest, notifier, config.rolesTable);
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return {
			rolePermissions: null
		};
	}
}
