import {Component, Input} from '@angular/core';

@Component({
	selector: 'kn-spinner',
	template: `
    <svg *ngIf="show" class="circular" viewBox="25 25 50 50">
		<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10" />
    </svg>`
})
export class KnSpinner {
	@Input() public show: boolean = false;
}
