import {Component, Injector, TemplateRef} from '@angular/core';
import {FormGroup, FormArray} from '@angular/forms';
import {Observable, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {UserEditService} from './user-edit.service';
import {ValidationService, CheckValidator} from 'kn-forms';
import {Utils} from 'kn-utils';
import {JwtAuth} from 'kn-jwt-auth';
import {AbstractStoreEditComponent} from 'common-web/forms';
import {OwnershipUtils} from 'common-web';
import {UserModel} from './user-model';
import {UserRoleRestrictionsService, RestrictionsTemplateContext} from './user-role-restrictions.service';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import {RolesResourceService} from '../../services/roles/roles-resource.service';
import {UsersViewConfig} from './users-view.config';
import * as CommonModel from '../../model/common-database.types';
import * as Model from 'common-web/model';

export type RolesData = {
	roles: CommonModel.Role[],
	restrictions: {
		data: { [key: string]: any };
		template: TemplateRef<RestrictionsTemplateContext>;
	}
};

@Component({
	selector: 'kn-user-edit',
	templateUrl: 'user-edit.html',
	providers: [ValidationService]
})
export class UserEditComponent extends AbstractStoreEditComponent<UserModel> {
	private readonly _rolesAndRestrictionsCache = new Map<number, Observable<RolesData>>();

	public prefixOwnership: string;
	public databases: Model.Database[] = [];
	public subjectReqistryQuerying = false;

	public constructor(
			injector: Injector,
			userEditService: UserEditService,
			auth: JwtAuth,
			private readonly _rolesResource: RolesResourceService,
			private readonly _databasesResource: DatabasesResourceService,
			private readonly _restrictionsService: UserRoleRestrictionsService,
			private readonly _config: UsersViewConfig) {
		super(injector, userEditService);
		this.prefixOwnership = auth.getAuth<{ user: Model.User }>().user.ownership;

		const notMatchPasswordValidator = new CheckValidator<string>(value => {
			if (this.form == null) {
				return false;
			}
			return this.form.get(['user', 'newPassword']).value === value;
		});
		this._validation.add('notMatchPassword', notMatchPasswordValidator, this._i18n.t('Not match'));
		const validators = this._restrictionsService.getValidators();
		for (const name in validators) {
			if (validators.hasOwnProperty(name)) {
				this._validation.add(name, validators[name]);
			}
		}
	}

	protected _load() {
		super._load();

		const masterDatabase = {
			id: null,
			description: 'Master database',
			name: 'Master',
			ownership: '',
			uid: 'master'
		} as Model.Database;
		const subscriptions = [
			this._fetch(this._databasesResource, { query: { only: ['id', 'uid', 'name'] } })
				.subscribe(next => this.databases = [masterDatabase].concat(next))
		];
		subscriptions.forEach(x => this._disposables.push(() => x.unsubscribe()));
	}

	protected _extractName(item: Model.User): string {
		return item.fullName;
	}

	protected _buildControlGroup(key: string): FormGroup {
		switch (key) {
			case 'user':
				return this._buildUserControlGroup();
			case 'databasesUserRoles':
				return this._buildDatabaseUserRolesControlGroup();
			case 'userRoles':
				return this._buildUserRoleControlGroup();
		}
		throw new Error('Unknown model control group.');
	}

	protected _buildUserControlGroup() {
		const validators = this._validation.validators;
		return this._formBuilder.group({
			id: [undefined],
			disabled: [false],
			disabledUntil: ['0001-01-01T00:00:00'],
			fullName: ['', [validators.required(), validators.maxLength(50)]],
			newPassword: [{ value: '', disabled: this.isEditMode() }, [validators.required(), validators.minLength(5), validators.maxLength(50)]],
			newPasswordCheck: [{ value: '', disabled: this.isEditMode() }, [validators.required(), validators.notMatchPassword()]],
			ownership: ['', [validators.maxLength(100)]],
			uid: ['', [validators.required(), validators.maxLength(40)]]
		});
	}

	protected _buildDatabaseUserRolesControlGroup() {
		return this._formBuilder.group({
			databaseId: [undefined],
			userRoles: this._formBuilder.array([])
		});
	}

	protected _buildUserRoleControlGroup() {
		const validators = this._validation.validators;
		const controlGroup = this._formBuilder.group({
			id: [undefined],
			roleId: [null, validators.required()]
		});
		const restrictionsGroup = this._restrictionsService.buildControlGroup(validators);
		if (restrictionsGroup != null) {
			for (const name in restrictionsGroup.controls) {
				if (restrictionsGroup.controls.hasOwnProperty(name)) {
					controlGroup.addControl(name, restrictionsGroup.controls[name]);
				}
			}
			const groupValidators = [controlGroup.validator, restrictionsGroup.validator]
				.filter(x => x);
			const groupAsyncValidators = [controlGroup.asyncValidator, restrictionsGroup.asyncValidator]
				.filter(x => x);
			controlGroup.setValidators(groupValidators);
			controlGroup.setAsyncValidators(groupAsyncValidators);
		}
		return controlGroup;
	}

	protected _sanityModel(model: UserModel) {
		const resistentKeys = ['id', 'roleId'];
		delete model.user['newPasswordCheck'];
		for (const databaseUserRoles of model.databasesUserRoles) {
			for (const userRole of databaseUserRoles.userRoles) {
				if (userRole.id == null) {
					delete userRole.id;
				}
				const database = this._getDatabaseById(databaseUserRoles.databaseId);
				let restrictionsKeys: string[] = [];
				if (database != null) {
					restrictionsKeys = this._restrictionsService.getKeys(database);
				}
				const keys = Object.keys(userRole);
				const keysToRemove = Utils.array.difference(keys, resistentKeys, restrictionsKeys);
				for (const key of keysToRemove) {
					delete userRole[key];
				}
			}
		}
		return this._restrictionsService.sanityModel(model);
	}

	protected _prepareModelToSave(model: UserModel) {
		model = super._prepareModelToSave(model);
		if (!this.isEditMode()) {
			model.databasesUserRoles.forEach(dbRole =>
				dbRole.userRoles.forEach(role => delete role.id)
			);
		}
		return model;
	}

	protected _populateFormValues(form: FormGroup, model: UserModel) {
		model.user.ownership = OwnershipUtils.toRelative(this.prefixOwnership, model.user.ownership);
		super._populateFormValues(form, model);
	}

	protected _formToModel(form: FormGroup): UserModel {
		const model = super._formToModel(form);
		model.user.ownership = OwnershipUtils.toAbsolute(this.prefixOwnership, model.user.ownership);
		return model;
	}

	public isPasswordChangeMode() {
		return !this.form.get(['user', 'newPassword']).disabled;
	}

	public enterPasswordChange() {
		this.form.get(['user', 'newPassword']).reset();
		this.form.get(['user', 'newPasswordCheck']).reset();
		this.enableControl(this.form.get(['user', 'newPassword']));
		this.enableControl(this.form.get(['user', 'newPasswordCheck']));
	}

	public cancelPasswordChange() {
		this.disableControl(this.form.get(['user', 'newPassword']));
		this.disableControl(this.form.get(['user', 'newPasswordCheck']));
	}

	public availableDatabases(currentId?: number): Model.Database[] {
		const selectedIds = (this.form.get('databasesUserRoles') as FormArray).controls.map(c => c.get('databaseId').value as number);
		return this.databases.filter(db => currentId === db.id || selectedIds.indexOf(db.id) === -1);
	}

	public addDatabaseUserRoles() {
		const userRoleControlGroup = this._buildDatabaseUserRolesControlGroup();
		this.addControl(this.form.get('databasesUserRoles') as FormArray, userRoleControlGroup);
	}

	public removeDatabaseUserRoles(index: number) {
		this.removeControl(this.form.get('databasesUserRoles') as FormArray, index);
	}

	public addUserRole(databaseIndex: number, roleId: number) {
		if (!roleId) {
			return;
		}
		const userRoleControlGroup = this._buildUserRoleControlGroup();
		userRoleControlGroup.get('roleId').setValue(roleId);
		this.addControl(this.form.get(['databasesUserRoles', databaseIndex, 'userRoles']) as FormArray, userRoleControlGroup);
	}

	public removeUserRole(databaseIndex: number, index: number) {
		this.removeControl(this.form.get(['databasesUserRoles', databaseIndex, 'userRoles']) as FormArray, index);
	}

	public queryRolesData(index: number): Observable<RolesData> {
		return this._getOrCreateRolesDataSource(this._getDatabaseIdByIndex(index));
	}

	private _getOrCreateRolesDataSource(databaseId: number) {
		if (!this._rolesAndRestrictionsCache.has(databaseId)) {
			const database = this._getDatabaseById(databaseId);
			if (database == null) {
				return null;
			}
			const source = this._createRolesDataSource(database);
			this._rolesAndRestrictionsCache.set(databaseId, source);
		}
		return this._rolesAndRestrictionsCache.get(databaseId);
	}

	private _createRolesDataSource(database: Model.Database) {
		const context = {
			[this._config.databaseUriKey]: database.uid,
			query: { only: ['id', 'name'] }
		};
		const roles$ = this._fetch(this._rolesResource, context);
		const restrictionsData$ = this._restrictionsService.fetchData(database);
		const rolesData$ = observableCombineLatest(roles$, restrictionsData$).pipe(
			Rx.map(next => ({
				roles: next[0],
				restrictions: {
					data: next[1],
					template: this._restrictionsService.getTemplate(database)
				}
			}))
		);
		return Rx.share<RolesData>()(rolesData$);
	}

	private _getDatabaseIdByIndex(index: number): number {
		return this.form.get(['databasesUserRoles', index, 'databaseId']).value;
	}

	private _getDatabaseById(id: number): Model.Database {
		return this.databases.find(x => x.id === id || (x.id == null && id == null));
	}
}
