import {NotSupportedError} from 'kn-shared';
import {PairSegments, encodePairSegments, decodePairSegments} from './utils';

export type QueryParameters = PairSegments;

export class UriQuery {
	private static readonly _queryRegExp = /^([\w.~!$&'()*+,;=:@\/?-]|%[0-9a-fA-F]{2})*$/;

	private _query: string;
	private _parameters: QueryParameters;

	public constructor(...uri: (UriQuery | QueryParameters | string)[]) {
		if (uri.length > 1) {
			uri.map(x => x instanceof UriQuery ? x : new UriQuery(x)).forEach(x => this._combine(x));
		}
		else if (uri.length === 1 && uri[0] != null) {
			if (uri[0] instanceof UriQuery) {
				this._parameters = (uri[0] as UriQuery)._parameters;
				this._query = (uri[0] as UriQuery)._query;
			}
			else if (uri[0] instanceof Object) {
				this._parameters = uri[0] as QueryParameters;
			}
			else if (typeof uri[0] === 'string') {
				this._query = uri[0] as string;
			}
			else {
				throw new NotSupportedError('Unsupported query type.');
			}
		}
	}

	private _combine(uri: UriQuery) {
		Object.assign(this.parameters, uri.parameters);
	}

	public get parameters() {
		if (this._parameters == null) {
			this._parameters = UriQuery.decode(this._query);
		}
		return this._parameters;
	}

	public set parameters(value: QueryParameters) {
		this._parameters = value;
		this._query = null;
	}

	public get query() {
		if (this._query == null && this._parameters != null) {
			this._query = UriQuery.encode(this._parameters);
		}
		return this._query;
	}

	public set query(value: string) {
		if (value != null && !UriQuery._queryRegExp.test(value)) {
			throw new Error('Invalid query subcomponent.');
		}
		this._query = value;
		this._parameters = null;
	}

	public toString() {
		return this.query;
	}

	public isEmpty() {
		return (this._query == null || this._query.length === 0) && this._parameters == null;
	}

	public static encode(value: QueryParameters): string {
		return encodePairSegments(value).join('&');
	}

	public static decode(value: string): QueryParameters {
		if (value == null || value.length === 0) {
			return {};
		}
		return decodePairSegments(value.split('&'));
	}
}
