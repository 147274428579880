import {combineLatest, of, Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Indexer} from 'kn-rest';
import {QueryContext} from './types';
import * as Model from 'common-web/model';
import {AbstractGraphStore} from './abstract-graph-store';

export abstract class AbstractGraphBulkStore<T extends { [key: string]: any }> extends AbstractGraphStore<T> {
	public get(indexerOrQuery: Indexer | QueryContext<string>) {
		if (this._isIndexer(indexerOrQuery)) {
			return this._getByIndexer(indexerOrQuery as Indexer);
		}
		return this._getByQuery(indexerOrQuery as QueryContext<string>);
	}

	protected _getByIndexer(indexer: Indexer): Observable<T | T[]> {
		const fetchers$ = [this._mappedStore.store.get(indexer)]
			.concat(this._mappedDependentStores.map(x => x.store.get(indexer)));
		return combineLatest(...fetchers$)
			.pipe(Rx.map(next => this._sortBulkDependents(this._foldBulk(next))));
	}

	protected _getByQuery(query: QueryContext<string>): Observable<T | T[]> {
		return this._mappedStore.store.getByQuery(query).pipe(
			Rx.map(next => {
				const indexer = Utils.array.box(next).map(x => this._mappedStore.store.indexerRetriver(x));
				return [of(next)]
					.concat(this._mappedDependentStores.map(x => x.store.get(indexer)));
			}),
			Rx.switchMap(next => combineLatest(next)),
			Rx.map(next => this._sortBulkDependents(this._foldBulk(next)))
		);
	}

	protected _foldBulk(items: any[]): T[] {
		const models = (items[0] as T[]).map(x => ({ [this._mappedStore.key]: x } as T));
		for (const model of models) {
			const indexer = this._mappedStore.store.indexerRetriver(model[this._mappedStore.key]);
			const fce = (x: T, mapper: Partial<Model.EntityBase>) => {
				for (const mKey in mapper) {
					if (mapper.hasOwnProperty(mKey)) {
						if (x[mKey] !== mapper[mKey]) {
							return false;
						}
					}
				}
				return true;
			};
			for (let i = 0; i < items.length - 1; i++) {
				const mapper = this._mappedDependentStores[i].store.ancestorMapper(indexer);
				(model as any)[this._mappedDependentStores[i].key] = Utils.array.box(items[i + 1]).filter(x => fce(x, mapper));
			}
		}
		return models;
	}

	protected _sortBulkDependents(models: T[]): T[] {
		this._mappedDependentStores.forEach(x =>
			models.forEach(model =>
				(model as any)[x.key] = Utils.array.sort(model[x.key] as any[], x.sort))
		);
		return models;
	}
}
