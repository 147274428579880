import {Observable, from as observableFrom, concat as observableConcat, merge as observableMerge, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {AbstractResource} from 'kn-rest';
import {EntitiesDiff} from '../../entity-utils';
import * as Model from 'common-web/model';

export type Mirror = {
	key: string,
	resource?: AbstractResource<Model.EntityBase>,
	target?: {
		resource?: AbstractResource<Model.EntityBase>,
		query?: { [key: string]: any }
	},
	source?: {
		resource?: AbstractResource<Model.EntityBase>,
		query?: { [key: string]: any }
	}
};

export type BlendStore = {
	precursor: UriContext;
	context: UriContext;
	data: Model.EntityBase[];
};

export type BlendModel = {
	[key: string]: {
		target: BlendStore,
		source: BlendStore
	}
};

export type BlendCommand = {
	key: string,
	blender: () => EntitiesDiff<Model.EntityBase>
};

export type BlendSequence = (BlendCommand | BlendCommand[])[];

export enum MirrorSide {
	Target,
	Source
}

export abstract class AbstractCloner {
	public constructor(protected _mirrors: Mirror[], protected _concurrent?: number) {
		this._mirrors.forEach(x => Utils.object.defaults(x, { target: {}, source: {} }));
	}

	public clone(targetPrecursor: any, sourcePrecursor: any): Observable<{ [key: string]: Response }> {
		return this._fetchModel(targetPrecursor, sourcePrecursor).pipe(
			Rx.map(next => ({ model: next, sequence: this._blend(next) })),
			Rx.switchMap(next => this._executeSequence(next.sequence, next.model)),
			Rx.defaultIfEmpty({})
		);
	}

	protected _fetchModel(targetPrecursor: any, sourcePrecursor: any): Observable<BlendModel> {
		const fetchers$ = this._mirrors
			.map(x => this._fetchMirror(x, targetPrecursor, sourcePrecursor));
		return observableMerge(...fetchers$)
			.pipe(Rx.reduce((acc, next) => Object.assign(acc, next), {}));
	}

	protected _fetchMirror(mirror: Mirror, targetPrecursor: any, sourcePrecursor: any): Observable<BlendModel> {
		const targetContext = this._buildContext(MirrorSide.Target, mirror, targetPrecursor);
		const sourceContext = this._buildContext(MirrorSide.Source, mirror, sourcePrecursor);
		const target$ = (mirror.target.resource || mirror.resource)
			.query(targetContext)
			.pipe(Rx.map(next => {
				if (next == null) {
					throw new Error(`Unable to load data from ${JSON.stringify(targetPrecursor)}`);
				}
				return { precursor: targetPrecursor, context: targetContext, data: next };
			}));
		const source$ = (mirror.source.resource || mirror.resource)
			.query(sourceContext)
			.pipe(Rx.map(next => {
				if (next == null) {
					throw new Error(`Unable to load data from ${JSON.stringify(sourcePrecursor)}`);
				}
				return { precursor: sourcePrecursor, context: sourceContext, data: next };
			}));
		return observableCombineLatest(target$, source$)
			.pipe(Rx.map(next => ({ [mirror.key]: { target: next[0], source: next[1] } } )));
	}

	protected _buildContext(side: MirrorSide, mirror: Mirror, precursor: any): UriContext {
		const query = side === MirrorSide.Target ? mirror.target.query : mirror.source.query;
		return Object.assign({}, precursor, query && { query });
	}

	protected abstract _blend(model: BlendModel): BlendSequence;

	protected _executeSequence(sequence: BlendSequence, model: BlendModel) {
		return observableFrom(sequence)
			.pipe(Rx.concatMap(next => this._processCommands(Utils.array.box(next), model)));
	}

	protected _processCommands(commands: BlendCommand[], model: BlendModel) {
		return observableMerge(...commands.map(command => {
			const mirror = this._mirrors.find(x => x.key === command.key);
			const resource = mirror.target.resource || mirror.resource;
			const context = model[command.key].target.context;
			return this._applyDiff(resource, context, this._executeBlender(command, model))
				.pipe(Rx.map(next => ({ [command.key]: next })));
		}));
	}

	protected _executeBlender(command: BlendCommand, model: BlendModel): EntitiesDiff<Model.EntityBase> {
		const diff = command.blender();
		diff.entitiesToCreate = Utils.clone(diff.entitiesToCreate, true);
		diff.entitiesToUpdate = Utils.clone(diff.entitiesToUpdate, true);
		diff.entitiesToCreate.forEach(entity => delete entity.id);
		const target = model[command.key].target.data;
		diff.entitiesToCreate
			.forEach(entity => target.push(entity));
		diff.entitiesToUpdate
			.forEach(entity => target[target.findIndex(x => x.id === entity.id)] = entity);
		diff.idsToRemove
			.forEach(id => target.splice(target.findIndex(x => x.id === id), 1));
		return diff;
	}

	protected _applyDiff(resource: AbstractResource<Model.EntityBase>, context: { [key: string]: any }, diff: EntitiesDiff<Model.EntityBase>) {
		const remover$ = observableFrom(diff.idsToRemove)
			.pipe(Rx.mergeMap(next => resource.remove(next, context), this._concurrent));
		const saver$ = observableFrom(diff.entitiesToCreate.concat(diff.entitiesToUpdate))
			.pipe(Rx.mergeMap(next => resource.save(next, context), this._concurrent));
		return observableConcat(remover$, saver$);
	}

	protected _partition<T extends Model.EntityBase>(model: BlendModel, key: string) {
		return {
			target: model[key].target.data as T[],
			source: model[key].source.data as T[]
		};
	}

	protected _createDiff<T extends Model.EntityBase>(diff: { idsToRemove?: number[], entitiesToCreate?: T[], entitiesToUpdate?: T[] } = {}): EntitiesDiff<T> {
		return {
			idsToRemove: diff.idsToRemove || [],
			entitiesToCreate: diff.entitiesToCreate || [],
			entitiesToUpdate: diff.entitiesToUpdate || []
		};
	}
}
