import {Component, ElementRef, OnInit, DoCheck, AfterViewInit, OnDestroy, HostBinding, ContentChildren, QueryList} from '@angular/core';
import {BehaviorSubject, Observer} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {KnResizableComponent} from './resizable.component';

@Component({
	selector: 'kn-resizable-container',
	template: '<ng-content></ng-content>',
	styles: [`
		:host {
			display: flex;
			flex: 1;
			overflow: auto;
		}

		:host.vertical {
			flex-direction: column;
		}

		:host.horizontal {
			flex-direction: row;
		}
	`]
})
export class KnResizableContainerComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
	private readonly _disposables: Function[] = [];
	private _resizeDetector$: Observer<void>;
	private _lastClientSizes: number[];
	private _horizontal: boolean;

	@ContentChildren(KnResizableComponent)
	public resizables: QueryList<KnResizableComponent>;

	@HostBinding('class.vertical')
	public get vertical(): boolean {
		return !this._horizontal;
	}

	@HostBinding('class.horizontal')
	public get horizontal(): boolean {
		return this._horizontal;
	}

	public constructor(private readonly _element: ElementRef) { }

	public ngOnInit() {
		const resizeDetector$ = new BehaviorSubject(null);
		this._resizeDetector$ = resizeDetector$;
		const resize$ = resizeDetector$.pipe(
			Rx.debounceTime(500),
			Rx.map(() => this._getClientSizes()),
			Rx.distinctUntilChanged((x, y) => x[0] === y[0] && x[1] === y[1])
		);
		const subscription = resize$
			.subscribe(next => this._processResize(next[0], next[1]));
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngDoCheck() {
		this._resizeDetector$.next(null);
	}

	public ngAfterViewInit() {
		// wait a tick first to avoid one-time devMode unidirectional-data-flow-violation error
		// https://github.com/angular/angular/issues/10131
		const subscription = this.resizables.changes
			.pipe(
				Rx.publishBehavior(this.resizables),
				Rx.refCount(),
				Rx.delay(0),
				Rx.filter<QueryList<KnResizableComponent>>(next => next.length > 0)
			)
			.subscribe(next => this._horizontal = next.reduce(
				(acc, x) => acc || x.leftHandler || x.rightHandler, false as boolean));
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	private _processResize(width: number, height: number) {
		if (this._lastClientSizes != null) {
			const widthFactor = width / this._lastClientSizes[0];
			const heightFactor = height / this._lastClientSizes[1];
			this.resizables.forEach(x => this._applyResize(x, widthFactor, heightFactor));
		}
		this._lastClientSizes = [width, height];
	}

	private _applyResize(resizable: KnResizableComponent, widthFactor: number, heightFactor: number) {
		if (resizable.width != null) {
			let width = resizable.width * widthFactor;
			width = this._clamp(width, resizable.minWidth, resizable.maxWidth);
			resizable.width = width;
		}
		if (resizable.height != null) {
			let height = resizable.height * heightFactor;
			height = this._clamp(height, resizable.minHeight, resizable.maxHeight);
			resizable.height = height;
		}
	}

	private _getClientSizes() {
		return [this._element.nativeElement.clientWidth, this._element.nativeElement.clientHeight];
	}

	private _clamp(value: number, min: number, max: number) {
		if (max != null && value > max) {
			value = max;
		}
		if (min != null && value < min) {
			value = min;
		}
		return value;
	}
}
