import {Utils} from 'kn-utils';
import {RowItem, Column, Section, RowsDescription} from './types';

export namespace SectionUtils {
	export function forEachNode<T extends { id: string, children?: T[] }>(nodes: T[], visitor: (node: T, path: string[]) => boolean | void, path: string[] = []): boolean {
		let result = true;
		for (const node of nodes) {
			if (visitor(node, path) === false) {
				result = false;
				continue;
			}
			if (node.children && node.children.length > 0) {
				if (!forEachNode(node.children, visitor, path.concat([node.id]))) {
					result = false;
				}
			}
		}
		return result;
	}

	export function traverse<T extends RowItem>(node: Section<T>, visitor: (node: Section<T>) => boolean | void): boolean {
		while (node && node.parent != null) {
			if (visitor(node) === false) {
				return false;
			}
			node = node.parent;
		}
		return true;
	}

	export function toArray<T extends RowItem>(node: Section<T>): Section<T>[] {
		const sections: Section<T>[] = [];
		traverse(node, x => { sections.unshift(x); return undefined; });
		return sections;
	}
}

export namespace ValueResolveUtils {
	function _resolveValue<T, V extends { (...args: any[]): T }>(resolvee: T | V, ...args: any[]): T {
		return Utils.isFunction(resolvee) ? (resolvee as V)(...args) : resolvee as T;
	}

	export function resolveCellClasses<T extends RowItem>(column: Column<T>, item: T = null): string[] {
		return Utils.array.box(_resolveValue(column.description.classes, item, column) as string | string[]);
	}

	export function resolveRowClasses<T extends RowItem>(rows: RowsDescription<T>, item: T): string[] {
		return Utils.array.box(_resolveValue(rows.classes, item) as string | string[]);
	}

	export function resolveSectionClasses<T extends RowItem>(section: Section<T>): string[] {
		return Utils.array.box(_resolveValue(section.description.classes, section) as string | string[]);
	}

	export function resolveLabel<T extends RowItem>(columnOrSection: Column<T> | Section<T>): string {
		return _resolveValue(columnOrSection.description.label, columnOrSection) as string;
	}

	export function resolveName<T extends RowItem>(columnOrSection: Column<T> | Section<T>): string {
		return _resolveValue(columnOrSection.description.name, columnOrSection) as string;
	}

	export function resolveHidden<T extends RowItem>(columnOrSection: Column<T> | Section<T>): boolean {
		return !!_resolveValue(columnOrSection.description.hidden, columnOrSection);
	}
}
