import {NgModule, Optional, SkipSelf, ModuleWithProviders} from '@angular/core';
import {OverlayService} from './overlay.service';
import {KnOverlayHost} from './overlay-host.component';

const KN_OVERLAY_DIRECTIVES = [KnOverlayHost];
const KN_OVERLAY_PROVIDERS = [OverlayService];

@NgModule({
	declarations: [KN_OVERLAY_DIRECTIVES],
	exports: [KN_OVERLAY_DIRECTIVES]
})
export class KnOverlayModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnOverlayModule) {
		if (parentModule) {
			throw new Error('KnOverlayModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(): ModuleWithProviders<KnOverlayModule> {
		return {
			ngModule: KnOverlayModule,
			providers: KN_OVERLAY_PROVIDERS
		};
	}
}
