import {Inject, Injectable, Provider} from '@angular/core';
import {AbstractAccessControlFactory} from './abstract-access-control';
import {KN_ACCESS_CONTROL_FACTORIES_TOKEN} from './access-control-factories.token';

@Injectable()
export class AccessControls {
	public constructor(
			@Inject(KN_ACCESS_CONTROL_FACTORIES_TOKEN) private readonly _factories: AbstractAccessControlFactory[][]) {
	}

	public find(name: string): AbstractAccessControlFactory {
		for (const factories of this._factories) {
			const factory = factories.find(x => x.name === name);
			if (factory != null) {
				return factory;
			}
		}
		throw new Error(`Cannot find a AccessControl.`);
	}

	public static create(factories: { (): AbstractAccessControlFactory[] }): Provider {
		return [
			AccessControls.extend(factories),
			AccessControls
		];
	}

	public static extend(factories: { (): AbstractAccessControlFactory[] }): Provider {
		return {
			provide: KN_ACCESS_CONTROL_FACTORIES_TOKEN,
			multi: true,
			useFactory: factories
		};
	}
}
