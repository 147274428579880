import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'console' })
export class ConsolePipe implements PipeTransform {
	public transform(value: any, msg?: string, target?: string): any {
		/* eslint-disable no-console */
		switch (target) {
			case 'debug':
				msg ? console.debug(msg, value) : console.debug(value);
				break;
			case 'info':
				msg ? console.info(msg, value) : console.info(value);
				break;
			case 'warn':
				msg ? console.warn(msg, value) : console.warn(value);
				break;
			case 'error':
				msg ? console.error(msg, value) : console.error(value);
				break;
			default:
				msg ? console.log(msg, value) : console.log(value);
				break;
		}
		/* eslint-enable no-console */
		return value;
	}
}
