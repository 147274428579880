import {AbstractStorageService} from './abstract-storage.service';

export class WebStorageService implements AbstractStorageService {
	public constructor(private readonly _storage: Storage) { }

	public clear(): void {
		this._storage.clear();
	}

	public setItem(key: string, value: any): void {
		this._storage.setItem(key, JSON.stringify(value));
	}

	public getItem(key: string): any {
		return JSON.parse(this._storage.getItem(key));
	}

	public removeItem(key: string): void {
		this._storage.removeItem(key);
	}

	public keys(): string[] {
		return Object.keys(this._storage);
	}
}
