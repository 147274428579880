import {AbstractFontResolver} from './abstract-font-resolver';
import {CssFontStyle, CssFontFace} from '../../css-font.type';

export class DefaultFontResolver extends AbstractFontResolver {
	public resolve(font: CssFontFace): string {
		const italic = font.fontStyle !== CssFontStyle.Normal;
		const bold = font.fontWeight >= 500;

		let name: string;
		if (font.fontFamily.indexOf('sans-serif') !== -1) {
			name = 'Helvetica';
			const style = italic ? 'Oblique' : '';
			const weight = bold ? 'Bold' : '';
			if (style || weight) {
				name += '-' + weight + style;
			}
		}
		else if (font.fontFamily.indexOf('monospace') !== -1) {
			name = 'Courier';
			const style = italic ? 'Oblique' : '';
			const weight = bold ? 'Bold' : '';
			if (style || weight) {
				name += '-' + weight + style;
			}
		}
		else {
			name = 'Times';
			const style = italic ? 'Italic' : '';
			const weight = bold ? 'Bold' : '';
			if (style || weight) {
				name += '-' + weight + style;
			}
			else {
				name += '-Roman';
			}
		}

		return name;
	}
}
