import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {forRootGuardFactory} from 'kn-shared';
import {KnDirectivesModule} from 'kn-directives';
import {KnDateHeader} from './portlets/headers/date-header.component';
import {KnTimeHeader} from './portlets/headers/time-header.component';
import {KnDateView} from './portlets/views/date-view.component';
import {KnTimeView} from './portlets/views/time-view.component';
import {KnMonthView} from './portlets/views/month-view.component';
import {KnDayView} from './portlets/views/day-view.component';
import {KnHourView} from './portlets/views/hour-view.component';
import {KnDatetime} from './portlets/datetime.component';
import {KnDate} from './portlets/date.component';
import {KnTime} from './portlets/time.component';
import {KnDatepicker} from './datepicker.component';
import {L10nService} from './services/l10n.service';

import {KnInputModule} from '../input/input.module';

export const DATEPICKER_FORROOT_GUARD = new InjectionToken<void>('DATEPICKER_FORROOT_GUARD');

const KN_DATEPICKER_DIRECTIVES = [
	KnDateHeader,
	KnTimeHeader,
	KnDateView,
	KnTimeView,
	KnMonthView,
	KnDayView,
	KnHourView,
	KnDatetime,
	KnDate,
	KnTime,
	KnDatepicker
];

const KN_DATEPICKER_PROVIDERS = [
	L10nService
];

@NgModule({
	imports: [CommonModule, FormsModule, KnDirectivesModule, KnInputModule],
	declarations: [KN_DATEPICKER_DIRECTIVES],
	exports: [KnDatepicker]
})
export class KnDatepickerModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(DATEPICKER_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnDatepickerModule> {
		return {
			ngModule: KnDatepickerModule,
			providers: [
				forRootGuardFactory('KnDatepickerModule', DATEPICKER_FORROOT_GUARD, KN_DATEPICKER_PROVIDERS[0]),
				KN_DATEPICKER_PROVIDERS
			]
		};
	}
}
