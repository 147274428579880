import {Injectable, Injector, ComponentFactoryResolver, ViewContainerRef, Type, ComponentRef} from '@angular/core';

@Injectable()
export class OverlayService {
	private _host: ViewContainerRef;

	public constructor(
			private readonly _injector: Injector,
			private readonly _componentFactoryResolver: ComponentFactoryResolver) {
	}

	public set host(value: ViewContainerRef) {
		if (value == null) {
			throw new Error('Overlay host must be valid ViewContainerRef.');
		}
		this._host = value;
	}

	public create<T>(componentType: Type<T>): ComponentRef<any> {
		if (this._host == null) {
			throw new Error('Overlay host is not defined.');
		}

		const factory = this._componentFactoryResolver.resolveComponentFactory(componentType);
		return this._host.createComponent(factory, 0, this._injector);
	}
}
