<div class="header">
	<kn-time-header
		[time]="time"
		(selectView)="timeViewIndex = $event"></kn-time-header>
</div>
<div class="view">
	<kn-time-view
		[(viewIndex)]="timeViewIndex"
		[hoursInPeriod]="hoursInPeriod"
		[(time)]="time"
		(accept)="acceptTime()"></kn-time-view>
</div>
