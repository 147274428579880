import {Injector} from '@angular/core';
import {CellValue, Column, ContractDescription as ContractDatagridDescription, ContractModel as ContractDatagridModel, DataSource, Model as GridModel, Sorting} from 'kn-datagrid';
import {UriContext} from 'kn-http';
import {ContractDescription as ContractFilterDescription, ContractModel as ContractFilterModel, DefaultFilterSerializer, FilterNode, Filter, FilterValue, Model as FilterModel} from 'kn-query-filter';
import {Observable, Subject, Subscription} from 'rxjs';
import {DatagridBond} from './datagrid-bond';
import {QueryFilterBond} from './query-filter-bond';
import {QueryFilterExpanderService} from './query-filter-expander.service';
import * as Rx from 'rxjs/operators';

export class GridBonding<T> {
	public datagrid: DatagridBond<T>;
	public filter: QueryFilterBond;

	protected queryExpander: QueryFilterExpanderService;

	private readonly _rowActivate$: Observable<T>;
	private readonly _selectedChanged$: Observable<T[]>;
	private readonly _modelChanged$: Observable<GridModel>;
	private readonly _expandedChanged$: Observable<T[]>;
	private readonly _dataSourceChanged$: Observable<DataSource<T>>;

	public constructor(
			injector: Injector,
			protected _fetcher: (context: UriContext) => Observable<T[]>,
			datagridDescription: ContractDatagridDescription<T>,
			datagridModel: ContractDatagridModel,
			filterdescription: ContractFilterDescription,
			filterModel: ContractFilterModel,
			protected _context: UriContext) {
		this.queryExpander = injector.get(QueryFilterExpanderService);
		this.datagrid = new DatagridBond<T>(datagridDescription, datagridModel, this._fetch.bind(this));
		this.filter = new QueryFilterBond(injector, filterdescription, filterModel, this.fetch.bind(this));
		const rowActivateSubject$ = new Subject<T>();
		const selectedChangedSubject$ = new Subject<T[]>();
		const modelChangedSubject$ = new Subject<GridModel>();
		const expandedChangedSubject$ = new Subject<T[]>();
		const dataSourceChangedSubject$ = new Subject<DataSource<T>>();
		this.datagrid.registerRowActivate(x => rowActivateSubject$.next(x));
		this.datagrid.registerSelectedCallback(x => selectedChangedSubject$.next(x));
		this.datagrid.registerModelChangeCallback(x => modelChangedSubject$.next(x));
		this.datagrid.registerExpandedChangeCallback(x => expandedChangedSubject$.next(x));
		this.datagrid.registerDataSourceChangeCallback(x => dataSourceChangedSubject$.next(x));
		this._rowActivate$ = rowActivateSubject$.asObservable();
		this._selectedChanged$ = selectedChangedSubject$.asObservable();
		this._modelChanged$ = modelChangedSubject$.asObservable();
		this._expandedChanged$ = expandedChangedSubject$.asObservable();
		this._dataSourceChanged$ = dataSourceChangedSubject$.asObservable();
	}

	public get rowActivate() {
		return this._rowActivate$;
	}

	public get selectedChanged() {
		return this._selectedChanged$;
	}

	public get modelChanged() {
		return this._modelChanged$;
	}

	public get expandedChanged() {
		return this._expandedChanged$;
	}

	public get dataSourceChanged() {
		return this._dataSourceChanged$;
	}

	public fetch(): Subscription {
		return this.datagrid.fetch();
	}

	protected _getSortingQuery(sortingColumns: Column<T>[]): string {
		return sortingColumns
			.filter(column => !column.id.startsWith('$') && !column.id.startsWith('@') && !column.id.startsWith('%'))
			.map(column => (column.model.sort === Sorting.Descending ? '-' : '') + column.id)
			.join();
	}

	protected _fetch(sorting: Column<T>[]): Observable<DataSource<CellValue>> {
		const context = Object.assign({ query: {} as any }, this._context);
		const model = this._cloneModel(this.filter.model, context);
		return this._serializeFilterModel(model).pipe(
			Rx.switchMap(next => {
				Object.assign(context.query, {
					q: encodeURIComponent(next || '') || undefined,
					sort: this._getSortingQuery(sorting)
				});
				return this._fetcher(context);
			})
		);
	}

	protected _cloneModel(model: FilterModel, context: UriContext): FilterModel {
		return this._cloneNodeOrValue(model, context) as FilterModel;
	}

	protected _cloneNodeOrValue(
			node: FilterNode<FilterValue> | Filter<FilterValue>,
			context: UriContext): FilterNode<FilterValue> | Filter<FilterValue> {
		if (node.hasOwnProperty('children')) {
			return {
				operator: (node as FilterNode<FilterValue>).operator,
				children: (node as FilterNode<FilterValue>).children.map(x => this._cloneNodeOrValue(x, context))
			} as FilterNode<FilterValue>;
		}
		return Object.assign({}, node);
	}

	protected _prepareFilterModel(model: FilterModel): Observable<FilterModel> {
		return this.queryExpander.expandModel(model, this.filter.description, this._fetcher);
	}

	protected _serializeFilterModel(model: FilterModel): Observable<string> {
		const serializer = new DefaultFilterSerializer(this.filter.description);
		return this._prepareFilterModel(model)
			.pipe(Rx.map(next => serializer.serialize(next)));
	}
}
