import {NgModule, ModuleWithProviders, Inject, Optional, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {Permissions} from '../configuration.types';
import {PERMISSIONS} from '../permissions.token';
import {SCOPE} from '../scope.token';
import {ScopedUserService} from '../scoped-user.service';
import {UserService} from '../user.service';
import {AuthorizationConfig, Access} from './authorization.config';
import {PermissionConfiguratorService} from './permission-configurator.service';
import {AllowAccessControlFactory} from '../access-control/allow-access-control';
import {AccessControls} from '../access-control/access-controls';
import {DenyAccessControlFactory} from '../access-control/deny-access-control';
import {StaticAccessControlFactory} from '../access-control/static-access-control';
import {KnPolicy} from './directives/policy.directive';
import {KnCan} from './directives/can.directive';

export const KN_AUTHORIZATION_FORROOT_GUARD = new InjectionToken<void>('KN_AUTHORIZATION_FORROOT_GUARD');

export const KN_AUTHORIZATION_DIRECTIVES = [
	KnPolicy,
	KnCan
];

export class AuthorizationOptions extends AuthorizationConfig { }

export function provideAuthorizationConfig(config: AuthorizationOptions) {
	return Utils.object.defaults(config, {
		defaultAccessControl: 'allow',
		missingPolicyStrategy: Access.Deny,
		fallbackAccess: Access.Deny
	});
}

export function provideAccessControlFactories() {
	return [
		new AllowAccessControlFactory(),
		new DenyAccessControlFactory(),
		new StaticAccessControlFactory()
	];
}

@NgModule({
	declarations: [KN_AUTHORIZATION_DIRECTIVES],
	exports: [KN_AUTHORIZATION_DIRECTIVES]
})
export class KnAuthorizationModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KN_AUTHORIZATION_FORROOT_GUARD) guard: any, loader: UserService) { /* intentionally empty */ }

	public static forRoot(permissions: Permissions, config?: Partial<AuthorizationOptions>): ModuleWithProviders<KnAuthorizationModule> {
		return {
			ngModule: KnAuthorizationModule,
			providers: [
				forRootGuardFactory('KnAuthorizationModule', KN_AUTHORIZATION_FORROOT_GUARD, AuthorizationOptions),
				{
					provide: AuthorizationOptions, useValue: config ? config : {}
				}, {
					provide: AuthorizationConfig,
					useFactory: provideAuthorizationConfig,
					deps: [AuthorizationOptions]
				}, {
					provide: PERMISSIONS,
					useValue: permissions
				},
				AccessControls.create(provideAccessControlFactories),
				PermissionConfiguratorService
			]
		};
	}

	public static forChild(permissions: Permissions, scope: string | string[]): ModuleWithProviders<KnAuthorizationModule> {
		return {
			ngModule: KnAuthorizationModule,
			providers: [
				{
					provide: SCOPE,
					useValue: scope
				}, {
					provide: PERMISSIONS,
					useValue: permissions
				}, {
					provide: UserService,
					useExisting: ScopedUserService
				},
				ScopedUserService
			]
		};
	}
}
