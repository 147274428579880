<kn-menu [ngClass]="{'disabled': loading}">
	<kn-menu-activator>
		<i class="material-icons">file_download</i> <span i18n>Export</span>
		<kn-spinner [show]="loading"></kn-spinner>
	</kn-menu-activator>
	<kn-menu-content autoclose class="kn-grid-button-content" [ngClass]="portalClass">
		<ul>
			<li *ngFor="let exporter of exporters" (click)="export(exporter.type)">
				<span>{{ exporter.name }}</span>
			</li>
		</ul>
	</kn-menu-content>
</kn-menu>
