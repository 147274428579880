import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {forRootGuardFactory} from 'kn-shared';
import {KnCommonModule} from 'kn-common';
import {KnAuthorizationModule} from 'kn-user';
import {KnComponentsModule} from 'kn-components';
import {KnDatagridModule} from 'kn-datagrid';
import {KnQueryFilterModule} from 'kn-query-filter';

import {KnGridSelector} from './widgets/grid-selector.component';
import {KnGridActions} from './widgets/grid-actions/grid-actions.component';
import {KnGridSearch} from './widgets/grid-search.component';
import {KnGridQueryFilter} from './widgets/grid-query-filter.component';
import {KnGridReset} from './widgets/grid-reset.component';

export const KN_GRID_PANEL_FORROOT_GUARD = new InjectionToken<void>('KN_GRID_PANEL_FORROOT_GUARD');

const KN_GRID_PANEL_DIRECTIVES = [
	KnGridSelector,
	KnGridActions,
	KnGridSearch,
	KnGridQueryFilter,
	KnGridReset
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnAuthorizationModule,
		KnDatagridModule,
		KnQueryFilterModule,
		KnCommonModule,
		KnComponentsModule
	],
	declarations: [KN_GRID_PANEL_DIRECTIVES],
	exports: [KN_GRID_PANEL_DIRECTIVES]
})
export class KnGridPanelModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KN_GRID_PANEL_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnGridPanelModule> {
		return {
			ngModule: KnGridPanelModule,
			providers: [
				...forRootGuardFactory('KnGridPanelModule', KN_GRID_PANEL_FORROOT_GUARD, KnGridSelector)
			]
		};
	}
}
