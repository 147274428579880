<form [formGroup]="form" class="table-container role-permissions">
	<div class="scrollable" formGroupName="roles" #rolesForm="knFormGroup">
		<table>
			<thead>
				<tr>
					<th class="name"></th>
					<th class="permission" *ngFor="let permission of permissions">
						<div class="rotate">
							<span class="name">{{ permission.name }}</span>
							<span class="description">{{ permission.description }}</span>
						</div>
					</th>
					<th class="spacer"></th>
					<th class="actions"></th>
				</tr>
			</thead>
			<tbody class="permissions-matrix">
				<tr *ngFor="let roleForm of rolesForm.controls; let i = index"
						[formGroupName]="i"
						[class.invalid]="!roleForm.valid">
					<td class="name">
						<kn-input type="text" formControlName="name"></kn-input>
					</td>
					<td class="permission" *ngFor="let permission of permissions">
						<kn-permission-actions-select
								[intermediate]="default3rdStateActions"
								[options]="permissionActions"
								[permission]="permission"
								[rolePermissions]="roleForm.controls.rolePermissions.value"
								(rolePermissionsChange)="setRolePermissions(i, $event)"></kn-permission-actions-select>
					</td>
					<td class="spacer"></td>
					<td class="actions">
						<button type="button" i18n-title title="Remove" (click)="removeRole(i)">
							<i class="material-icons">clear</i>
						</button>
					</td>
				</tr>
			</tbody>
			<tbody class="add-new-input">
				<tr>
					<td class="name">
						<kn-input #newRoleInput type="text"
								i18n-placeholder="new-role-placeholder" placeholder="New role..."
								(valueChange)="newRoleInput._valid = isNewRoleValid(newRoleInput.value)"
								(keydown.enter)="addRole(newRoleInput.value); newRoleInput.value = ''; newRoleInput._valid = false; $event.preventDefault()"
								[class.ng-invalid]="newRoleInput.value && !newRoleInput._valid" class="ng-touched">
							<span kn-label i18n>New role</span>
						</kn-input>
					</td>
					<td class="permission" [attr.colspan]="permissions.length"></td>
					<td class="spacer"></td>
					<td class="actions">
						<button type="button" i18n-title title="Add new"
								*ngIf="newRoleInput._valid"
								(click)="addRole(newRoleInput.value); newRoleInput.value = ''; newRoleInput._valid = false">
							<i class="material-icons">add</i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</form>
