import {AbstractControl} from '@angular/forms';
import {Validator} from '../types';

export class CheckValidator<T> implements Validator {
	public constructor(private readonly _checker: (value: T) => boolean) { }

	public validate(control: AbstractControl) {
		return control.value == null || this._checker(control.value);
	}
}
