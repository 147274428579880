<label #knLabelContent>
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required && hasKnLabel">*</span>
</label>

<div class="kn-select-container" knRipple [knRippleDisabled]="disabled">
	<div class="kn-select-prefix">
		<ng-content select="[kn-prefix]"></ng-content>
	</div>

	<div class="kn-select-header" #headerElement [attr.tabindex]="getTabindex()"
			(click)="handleClick($event)"
			(keyup)="handleKeyup($event)"
			(keydown)="handleKeydown($event)">
		<div *ngIf="!displayPlaceholder">
			<ng-template
				[ngTemplateOutlet]="template"
				[ngTemplateOutletContext]="context"></ng-template>
			<ng-template [ngIf]="!template">
				<ng-template ngFor let-option [ngForOf]="selectedOptions" let-last="last">
					<div class="option" [innerHTML]="option.html"></div><div class="separator" *ngIf="!last">,</div>
				</ng-template>
			</ng-template>
		</div>
		<span class="placeholder" *ngIf="displayPlaceholder">{{ placeholder }}</span>
	</div>

	<div class="kn-select-suffix">
		<ng-content select="[kn-suffix]"></ng-content>
	</div>

	<i class="kn-select-dropdown material-icons">arrow_drop_down</i>
</div>

<div class="kn-select-underline"></div>

<kn-options-container *ngIf="renderContainer" [hints]="hints" [hasFilter]="hasFilter" clearOnOpen>
	<ng-content select="kn-optgroup, kn-option"></ng-content>
</kn-options-container>

<ng-content></ng-content>

<ng-content select="[kn-hint]"></ng-content>
