import {Location} from '@angular/common';
import {Subscription} from 'rxjs';

export function navigateBack(location: Location, closeShort: boolean, onNavigationFailed?: Function) {
	if (closeShort && window.history.length <= 1) {
		window.close();
	}
	let backSucceded = false;
	const subscription = location.subscribe(next => {
		backSucceded = true;
	});
	location.back();
	setTimeout(() => {
		if (subscription instanceof Subscription) {
			subscription.unsubscribe();
		}
		if (backSucceded) {
			return;
		}
		if (onNavigationFailed != null) {
			onNavigationFailed();
		}
		else {
			window.close();
		}
	}, 500);
}
