import {Observable} from 'rxjs';
import {Uri} from '../uri/uri';
import {WebsocketSubject} from './websocket-subject';
import {Request} from './request';
import {Response} from './response';
import {RequestOptions, WebsocketConfigOptions} from './types';
import {WebsocketConfig} from './websocket-config';

export abstract class Http {
	public abstract request(request: Request): Observable<Response>;
	public abstract get(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract post(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract put(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract delete(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract patch(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract head(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract options(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response>;
	public abstract websocket(config: WebsocketConfig): WebsocketSubject;
	public abstract websocket(uri: string | Uri, options: Partial<WebsocketConfigOptions>): WebsocketSubject;
	public abstract websocket(uriOrConfig: string | Uri | WebsocketConfig, options?: Partial<WebsocketConfigOptions>): WebsocketSubject;
}
