import {AbstractAccessControl} from '../access-control/abstract-access-control';

export abstract class AbstractPolicy {
	public abstract get resource(): string;
	public abstract get accessControl(): AbstractAccessControl;
	public abstract get parent(): AbstractPolicy;
	public abstract get children(): AbstractPolicy[];

	public get isRoot() {
		return this.parent == null;
	}

	public get root() {
		if (this.isRoot) {
			return this;
		}
		let parent = this.parent;
		while (!parent.isRoot) {
			parent = parent.parent;
		}
		return parent;
	}

	public get scope() {
		if (this.isRoot) {
			return [];
		}
		const scope = [];
		let parent = this.parent;
		while (!parent.isRoot) {
			scope.unshift(parent.resource);
			parent = parent.parent;
		}
		return scope;
	}
}
