import {Directive, HostBinding, ElementRef} from '@angular/core';

@Directive({
	selector: '[knBackgroundFocusable]'
})
export class KnBackgroundFocusable {
	public constructor(private readonly _elementRef: ElementRef) { }

	@HostBinding('tabindex')
	public tabindex = -1;

	public focus(): void {
		this._elementRef.nativeElement.focus();
	}
}
