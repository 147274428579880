import {NgModule} from '@angular/core';
import {HttpService} from './http.service';
import {Http} from './http';
import {HttpHandler} from './handlers/http-handler';
import {WebsocketHandler} from './handlers/websocket-handler';
import {HTTP_HANDLERS} from './http-handlers.token';
import {WEBSOCKET_HANDLERS} from './websocket-handlers.token';
import {BrowserXhrHttpBackend} from './backends/browser-xhr-http-backend';
import {BrowserWebsocketBackend} from './backends/browser-websocket-backend';

export function provideHttp(httpHandlers: HttpHandler[], websocketHandlers: WebsocketHandler[]) {
	return new HttpService(httpHandlers, websocketHandlers);
}

@NgModule({
	providers: [
		{
			provide: HTTP_HANDLERS,
			useClass: BrowserXhrHttpBackend,
			multi: true
		}, {
			provide: WEBSOCKET_HANDLERS,
			useClass: BrowserWebsocketBackend,
			multi: true
		}, {
			provide: Http,
			useFactory: provideHttp,
			deps: [HTTP_HANDLERS, WEBSOCKET_HANDLERS]
		}
	]
})
export class KnHttpModule { }
