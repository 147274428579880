import {Observable, of as observableOf, merge as observableMerge, combineLatest as observableCombineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http, Response, Uri, UriAcceptedTypes} from 'kn-http';
import {AbstractStoreBackend} from './abstract-store-backend';
import {SettingsData} from '../types';
import {indexOfSettingsItem} from '../utils';

type SettingsModel = {
	id: number,
	discriminator: string,
	key: string,
	value: string,
	version: string,
	[key: string]: any
}[];

export class RemoteStoreBackend extends AbstractStoreBackend {
	public constructor(private readonly _http: Http, private readonly _uri: string) {
		super();
	}

	public load(keys: string[]): Observable<SettingsData> {
		if (keys == null || !keys.length) {
			return observableOf({} as SettingsData);
		}

		const q = keys.map(encodeURIComponent).map(x => `key=${x}`).join('|');
		return this._http.get(this._buildUri({ query: { q }} as Object))
			.pipe(Rx.map(next => this._processResponse(next.body as SettingsModel, keys)));
	}

	public save(data: SettingsData): Observable<SettingsData> {
		return this._connectIds(Object.assign({}, data))
			.pipe(Rx.switchMap(next => this._save(next)));
	}

	private _processResponse(model: SettingsModel, keys: string[]): SettingsData {
		const data: SettingsData = {};
		for (const key of keys) {
			model.filter(x => x.key === key).forEach(item => {
				const settingsItem = {
					id: item.id,
					value: JSON.parse(item.value),
					version: item.version,
					discriminator: item.discriminator
				};
				if (data.hasOwnProperty(key)) {
					data[key].push(settingsItem);
				}
				else {
					data[key] = [settingsItem];
				}
			});
		}
		return data;
	}

	private _connectIds(data: SettingsData) {
		const nonfetchedQueryies: string[] = [];
		const keys = Object.keys(data);
		for (const key of keys) {
			if (data.hasOwnProperty(key)) {
				for (const item of data[key]) {
					if (item.id == null) {
						const pair = [key, item.discriminator].map(encodeURIComponent);
						nonfetchedQueryies.push(`(key=${pair[0]},discriminator=${pair[1]})`);
					}
				}
			}
		}

		if (nonfetchedQueryies.length === 0) {
			return observableOf(data);
		}
		const q = nonfetchedQueryies.join('|');
		const only = ['id', 'key', 'discriminator'];
		return this._http.get(this._buildUri({ query: { q, only }} as Object))
			.pipe(Rx.map(next => {
				for (const item of next.body) {
					const settingsItemIndex = indexOfSettingsItem(data[item.key], item);
					if (settingsItemIndex !== -1) {
						data[item.key][settingsItemIndex].id = item.id;
						break;
					}
				}
				return data;
			}));
	}

	private _save(data: SettingsData): Observable<SettingsData> {
		const savers$: Observable<SettingsData>[] = [];

		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const requests$: Observable<Response>[] = [];
				for (const item of data[key]) {
					if (item.value != null) {
						const body = JSON.stringify({
							key: key,
							version: item.version,
							discriminator: item.discriminator,
							value: JSON.stringify(item.value || null)
						});
						let request$: Observable<Response>;
						if (item.id == null) {
							request$ = this._http.post(this._buildUri(), body)
								.pipe(Rx.tap(next => item.id = this._retriveId(next)));
						}
						else {
							request$ = this._http.put(this._buildUri(`${item.id}`), body);
						}
						requests$.push(request$);
					}
					else if (item.id != null) {
						const request$ = this._http.delete(this._buildUri(`${item.id}`))
							.pipe(Rx.tap(() => item.id = null));
						requests$.push(request$);
					}
				}
				const savedSetting$ = observableCombineLatest(requests$).pipe(
					Rx.defaultIfEmpty(),
					Rx.map(() => ({ [key]: data[key] } as SettingsData)),
					Rx.catchError(() => observableOf(null))
				);
				savers$.push(savedSetting$);
			}
		}

		return observableMerge(...savers$)
			.pipe(Rx.reduce((acc, next) => Object.assign(acc, next), {}));
	}

	private _retriveId(response: Response) {
		return +response.headers.get('Location').match(/\/([\w_-]+)$/)[1];
	}

	protected _buildUri(...uri: UriAcceptedTypes[]) {
		return new Uri(this._uri, ...uri).toString();
	}
}
