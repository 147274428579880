import {Component, Input} from '@angular/core';

@Component({
	selector: 'kn-toast',
	template: `
	<div *knCollapse="isOpen">
		<div class="content">
			<strong>{{ message }}</strong>
			<span *ngIf="details">{{ details }}</span>
		</div>
		<a href="javascript:void(0)" (click)="close()" i18n-title title="Close">X</a>
	</div>`
})
export class KnToast {
	@Input() public message: string;
	@Input() public details: string;

	private _open: boolean = false;

	public get isOpen(): boolean {
		return this._open;
	}

	public set isOpen(value: boolean) {
		this._open = value;
	}

	public open(): void {
		this.isOpen = true;
	}

	public close(): void {
		this.isOpen = false;
	}
}
