import {Injectable, Inject, forwardRef} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable, combineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {UserSettingsFactory} from './user-settings-factory.service';
import {Utils} from 'kn-utils';

@Injectable()
export class PreloadUserSettingsGuard implements CanActivate {
	// FIXME
	public constructor(
			@Inject(forwardRef(() => UserSettingsFactory)) private readonly _settingsFactory: UserSettingsFactory) {
	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
		const components: any[] = Utils.array.box(next.data['userSettingComponents'] || next.component);
		return combineLatest(components.map(component => this._settingsFactory.linkComponent(component).changes))
			.pipe(Rx.map(() => true), Rx.take(1));
	}
}
