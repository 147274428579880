import {PdfkitCanvasAdaptor} from './canvas-adaptor/pdfkit-canvas-adaptor';
import {DomPagination} from './dom-pagination/dom-pagination';
import {DomPageIterator} from './dom-pagination/dom-page-iterator';
import {TableElementBreaker} from './dom-pagination/breakers/table-element-breaker';
import {BlockElementBreaker} from './dom-pagination/breakers/block-element-breaker';
import {InvisibleNodesFilterPreprocessor} from './dom-pagination/preprocessors/invisible-nodes-filter-preprocessor';
import {WhiteTextNodesFilterPreprocessor} from './dom-pagination/preprocessors/white-text-nodes-filter-preprocessor';
import {AnchorImageSizePreprocessor} from './dom-pagination/preprocessors/anchor-image-size-preprocessor';
import {RegistrationFontResolver} from './canvas-adaptor/resolvers/registration-font-resolver';
import {RegistrationImageResolver} from './canvas-adaptor/resolvers/registration-image-resolver';
import {SandboxedDocument, SandboxedDocumentBuilder} from './sandboxed-document';
import {DefaultXhrFetcher} from './utils/default-xhr-fetcher';
import {DocumentQueries} from './document-queries';
import {px2pt} from './utils/conversions';

declare namespace BlobStream {
	interface IBlobStream extends NodeJS.WritableStream{
		toBlob(type?: string): Blob;
	}
}

declare function blobStream(): BlobStream.IBlobStream;
declare const PDFDocument: PDFKit.PDFDocument;

export interface HyPdfOptions {
	pdfkit?: PDFKit.PDFDocument;
	html2canvas?: Html2CanvasStatic;
	blobstream?: () => BlobStream.IBlobStream;
	fetcher?: (uri: string, binary: boolean) => Promise<ArrayBuffer | string>;
}

export class HyPdf {
	private readonly _options: HyPdfOptions;

	public constructor(options?: HyPdfOptions) {
		this._options = Object.assign({
			fetcher: DefaultXhrFetcher.fetch
		}, options);

		this._options.pdfkit = this._options.pdfkit || PDFDocument;
		this._options.html2canvas = this._options.html2canvas || html2canvas;
		this._options.blobstream = this._options.blobstream || blobStream;
	}

	public async print(html: string): Promise<Blob> {
		const sandbox = await this._buildSandboxedDocument(html);
		try {
			return await this._printSandboxedDocument(sandbox);
		}
		finally {
			sandbox.destroy();
		}
	}

	private async _buildSandboxedDocument(html: string): Promise<SandboxedDocument> {
		const sandboxBuilder = new SandboxedDocumentBuilder();
		const sandbox = await sandboxBuilder.build(html);
		const size = await DocumentQueries.getPageSize(sandbox, this._options.fetcher);
		sandbox.style.position = 'absolute';
		sandbox.style.right = '0';
		sandbox.style.bottom = '0';
		sandbox.style.width = size[0] + 'px';
		sandbox.style.height = size[1] + 'px';
		return sandbox;
	}

	private async _printSandboxedDocument(sandbox: SandboxedDocument) {
		const size = [parseInt(sandbox.style.width, 10), parseInt(sandbox.style.height, 10)];

		const doc = new this._options.pdfkit({ size: size.map(px2pt), autoFirstPage: false });

		const fontResolver = new RegistrationFontResolver(doc, { fetcher: this._options.fetcher });
		const imageResolver = new RegistrationImageResolver({ fetcher: this._options.fetcher });

		const resolves = [
			fontResolver.registerFromDocument(sandbox),
			imageResolver.registerFromDocument(sandbox)
		];

		const canvas = new PdfkitCanvasAdaptor(doc);
		canvas.fontResolver = fontResolver;
		canvas.imageResolver = imageResolver;

		const pagination = new DomPagination();
		pagination.breakers = [
			new TableElementBreaker(),
			new BlockElementBreaker()
		];
		pagination.preprocessors = [
			new InvisibleNodesFilterPreprocessor(),
			new WhiteTextNodesFilterPreprocessor(),
			new AnchorImageSizePreprocessor()
		];

		await Promise.all(resolves);
		const pageIterator = pagination.createPageIterator(sandbox.nativeDocument);

		while (await this._nextPage(pageIterator, size)) {
			await this._printPage(doc, canvas, sandbox);
		}

		return this._save(doc);
	}

	private async _nextPage(pageIterator: DomPageIterator, size: number[]) {
		if (pageIterator.nextPage({ size })) {
			return new Promise(resolve => setTimeout(() => resolve(true)));
		}
		return false;
	}

	private async _printPage(doc: PDFKit.PDFDocument, canvas: PdfkitCanvasAdaptor, sandbox: SandboxedDocument) {
		doc.addPage();
		const options = { canvas: canvas, strict: true } as Html2CanvasOptions;
		return this._options.html2canvas(sandbox.nativeDocument.body, options);
	}

	private async _save(doc: PDFKit.PDFDocument) {
		return new Promise<Blob>((resolve, reject) => {
			const stream = doc.pipe(this._options.blobstream());
			doc.end();
			stream.on('finish', () => resolve(stream.toBlob('application/pdf')));
			stream.on('error', (x: any) => reject(x));
		});
	}
}
