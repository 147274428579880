<table>
	<thead>
		<tr>
			<th *ngFor="let weekdayName of weekdayNames">{{ weekdayName }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let weekDays of monthDays">
			<td *ngFor="let calendarDay of weekDays">
				<a href="javascript:void(0)"
					*ngIf="calendarDay != null"
					[class.now]="calendarDay === nowDay"
					[class.selected]="calendarDay === day"
					(click)="selectDay(calendarDay)">
					{{ calendarDay + 1 }}
				</a>
			</td>
		</tr>
	</tbody>
</table>