import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('first')
export class FirstFilter implements PrintRendererFilter {
	public transform(value: any[]): number {
		return value == null ? null : value[0];
	}
}
