import {Directive, HostBinding, HostListener, ContentChild} from '@angular/core';
import {KnColumnContextMenu} from './column-context-menu.component';

@Directive({
	selector: '[knColumnContextMenuActivator]'
})
export class KnColumnContextMenuActivator {
	@ContentChild(KnColumnContextMenu, { static: false })
	public contextmenu: KnColumnContextMenu;

	@HostBinding('class.contextmenu')
	public get isOpen(): boolean {
		return this.contextmenu && this.contextmenu.isOpen;
	}

	@HostListener('contextmenu', ['$event'])
	public showContextMenuHandler(event: Event) {
		this.contextmenu.show();
		event.stopPropagation();
		event.preventDefault();
	}

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public suppressClosingHandler(event: Event) {
		if (this.contextmenu.isOpen) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	@HostListener('document:mousedown')
	@HostListener('document:touchstart')
	public closeContextMenuHandler() {
		if (this.contextmenu.isOpen) {
			this.contextmenu.commit();
		}
	}
}
