import {KnAuthorizationModule, Permissions} from 'kn-user';

export const permissions: Permissions = [
	{
		resource: 'item',
		accessControl: 'krest',
		mapping: {
			new: [{ 'User': 'C', 'Admin': 'C' }],
			copy: [{ 'User': 'CR', 'Admin': 'CR' }],
			edit: [{ 'User': 'R', 'Admin': 'R' }],
			remove: [{ 'User': 'D', 'Admin': 'D' }]
		}
	}
];

export const authorization = KnAuthorizationModule.forChild(permissions, 'roles');
