import {Observable, from as observableFrom} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {SandboxedDocument, SandboxedDocumentBuilder} from 'kn-common';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {PrintConfiguration} from '../types';

export abstract class AbstractDomPrintBackend extends AbstractPrintBackend {
	public constructor(private readonly _docBuilder: SandboxedDocumentBuilder) {
		super();
	}

	public print(html: string, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any> {
		return observableFrom(this._docBuilder.build(html))
			.pipe(Rx.map(doc => this._print(doc, options).pipe(Rx.tap(() => doc.destroy()))));
	}

	public printPreview(html: string, config?: PrintConfiguration, options?: AbstractPrintBackendOptions): Observable<any> {
		return this.print(html, options);
	}

	protected abstract _print(doc: SandboxedDocument, options?: AbstractPrintBackendOptions): Observable<any>;
}
