export enum BreakableType {
	Auto,
	Avoid,
	Always
}

export interface NodeBreakables {
	before: BreakableType;
	inside: BreakableType;
	after: BreakableType;
}

export interface BreakableAnnotation {
	id: number;
	breakable: BreakableType;
}
