import {KnAuthorizationModule, Permissions} from 'kn-user';
import {RestAccessControl} from '../../services/authorization/access-control/rest-access-control';

export function provideDatabaseCrudMapping() {
	return RestAccessControl.crud('Database');
}

export const permissions: Permissions = [
	{
		resource: 'item',
		accessControl: 'krest',
		mapping: {
			new: [{ 'Database': 'C', 'Admin': 'C' }],
			copy: [{ 'Database': 'CR', 'Admin': 'CR' }],
			edit: [{ 'Database': 'R', 'Admin': 'R' }],
			remove: [{ 'Database': 'D', 'Admin': 'D' }]
		}
	}, {
		resource: 'database',
		accessControl: 'krest',
		mapping: provideDatabaseCrudMapping
	}
];

export const authorization = KnAuthorizationModule.forChild(permissions, 'databases');
