import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {RolesConfig} from './roles.config';
import * as Model from 'common-web/model';

@Injectable()
export class PermissionActionsResourceService extends Resource<Model.ActionInfo> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: RolesConfig) {
		super(rest, notifier, config.permissionActionsTable);
	}
}
