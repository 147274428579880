import {Observable} from 'rxjs';
import {Response, UriContext} from 'kn-http';
import {AbstractResource} from './abstract-resource';
import {ChangeEntry} from '../change-notification/types';
import {Indexer} from '../types';

export abstract class ProxyResource<T> extends AbstractResource<T> {
	public constructor(protected _baseResource: AbstractResource<T>) {
		super();
	}

	public get baseResource(): AbstractResource<T> {
		return this._baseResource;
	}

	public get changes(): Observable<ChangeEntry> {
		return this._baseResource.changes;
	}

	public get table(): string {
		return this._baseResource.table;
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return this._baseResource.getReferences();
	}

	public query(context?: UriContext): Observable<T[]> {
		return this._baseResource.query(context);
	}

	public get(indexer: Indexer, context?: UriContext): Observable<T> {
		return this._baseResource.get(indexer, context);
	}

	public head(indexer: Indexer, context?: UriContext): Observable<Response> {
		return this._baseResource.head(indexer, context);
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		return this._baseResource.save(item, context);
	}

	protected _remove(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._baseResource.remove(indexer, context);
	}
}
