import {AbstractElementBreaker} from './abstract-element-breaker';
import {BreakableType, NodeBreakables} from '../types';

export class BlockElementBreaker extends AbstractElementBreaker {
	private static readonly _generateBoxProperties = [
		'block',
		'table',
		'table-row',
		'flex',
		'grid'
	];
	private static readonly _normalFlowProperties: { [key: string]: string[] } = {
		position: ['static', 'relative'],
		float: ['none']
	};

	public isSupported(node: Node): boolean {
		return true;
	}

	public getBreakables(node: Node): NodeBreakables {
		const breakables = {
			before: BreakableType.Avoid,
			inside: BreakableType.Avoid,
			after: BreakableType.Avoid
		};

		switch (node.nodeType) {
			case Node.ELEMENT_NODE:
				const style = window.getComputedStyle(node as HTMLElement);
				if (BlockElementBreaker._generateBoxProperties.indexOf(style.display) === -1) {
					break;
				}
				let outOfFlow = false;
				for (const key in BlockElementBreaker._normalFlowProperties) {
					if (BlockElementBreaker._normalFlowProperties.hasOwnProperty(key)) {
						const values = BlockElementBreaker._normalFlowProperties[key];
						if (values.indexOf((style as any)[key]) === -1) {
							outOfFlow = true;
							break;
						}
					}
				}
				if (outOfFlow) {
					break;
				}
				if (!this._isConcreate(node)) {
					breakables.inside = this._cssBreakToBreakable(style.pageBreakInside, style.breakInside);
				}
				breakables.before = this._cssBreakToBreakable(style.pageBreakBefore, style.breakBefore);
				breakables.after = this._cssBreakToBreakable(style.pageBreakAfter, style.breakAfter);
				break;

			case Node.TEXT_NODE:
				break;

			default:
				return null;
		}

		return breakables;
	}

	private _isConcreate(node: Node) {
		return window.getComputedStyle(node as HTMLElement).display !== 'block'
				|| this._containsFloats(node)
				|| this._containsPositionedElements(node);
	}

	private _containsFloats(node: Node) {
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < node.childNodes.length; i++) {
			const child = node.childNodes[i];
			if (child.nodeType === Node.ELEMENT_NODE
					&& window.getComputedStyle(child as HTMLElement).cssFloat !== 'none') {
				return true;
			}
		}
		return false;
	}

	private _containsPositionedElements(node: Node) {
		if (window.getComputedStyle(node as HTMLElement).position !== 'relative') {
			// eslint-disable-next-line @typescript-eslint/prefer-for-of
			for (let i = 0; i < node.childNodes.length; i++) {
				const child = node.childNodes[i];
				if (child.nodeType === Node.ELEMENT_NODE
						&& window.getComputedStyle(child as HTMLElement).position === 'relative') {
					return true;
				}
			}
			return false;
		}
		else {
			const childIterator = document.createNodeIterator(node, NodeFilter.SHOW_ELEMENT);
			let child: Node;
			// eslint-disable-next-line no-cond-assign
			while (child = childIterator.nextNode()) {
				if (['relative', 'absolute'].indexOf(window.getComputedStyle(child as HTMLElement).position) !== -1) {
					return true;
				}
			}
		}
		return false;
	}

	protected _remove(parent: Node, node: Node) {
		if (node.nodeType === Node.ELEMENT_NODE) {
			const style = window.getComputedStyle(node as HTMLElement);
			if (['fixed'].indexOf(style.position) !== -1) {
				return false;
			}
		}
		parent.removeChild(node);
		return true;
	}
}
