import {AbstractIterablePreprocessor} from './abstract-iterable-preprocessor';

export abstract class AbstractFilterPreprocessor extends AbstractIterablePreprocessor {
	protected _processNode(node: Node): void {
		if (this._isFiltered(node)) {
			node.parentNode.removeChild(node);
		}
	}

	protected abstract _isFiltered(node: Node): boolean;
}
