import {NgModule, ModuleWithProviders} from '@angular/core';
import {Utils} from 'kn-utils';
import {UserService} from './user.service';
import {AuthorizationService} from './authorization/authorization.service';
import {UserConfig} from './user.config';
import {RootUserService} from './root-user.service';

export class UserOptions extends UserConfig { }

export function provideUserConfig(config: UserOptions) {
	return Utils.object.defaults(config, {});
}

@NgModule()
export class KnUserModule {
	public static forRoot(config?: Partial<UserOptions>): ModuleWithProviders<KnUserModule> {
		return {
			ngModule: KnUserModule,
			providers: [
				{
					provide: UserOptions, useValue: config ? config : {}
				}, {
					provide: UserConfig,
					useFactory: provideUserConfig,
					deps: [UserOptions]
				}, {
					provide: UserService,
					useExisting: RootUserService
				},
				AuthorizationService,
				RootUserService
			]
		};
	}
}
