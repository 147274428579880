import {Directive, Input, ViewChild, ChangeDetectorRef, ContentChildren, QueryList, ElementRef, AfterContentInit, AfterViewInit, OnDestroy, forwardRef} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {NotSupportedError} from 'kn-shared';
import {KnOption} from './option.component';
import {KnOptionsContainer} from './options-container.component';
import {TransliterateOptionComparer} from './comparers/transliterate-option-comparer';
import {NullOptionComparer} from './comparers/null-option-comparer';
import {StrictOptionComparer} from './comparers/strict-option-comparer';
import {AbstractOptionComparer} from './comparers/abstract-option-comparer';
import {KnOptgroup} from './optgroup.component';

export interface OptionsContext {
	$implicit: any;
	value: any;
}

@Directive()
export abstract class AbstractOptionsHost implements AfterContentInit, AfterViewInit, OnDestroy {
	protected _disposables: Function[] = [];
	private _context: OptionsContext;
	private _hints: { key: string, message: string }[] = [];
	private _getter: (item: any) => any;
	private _comparer: AbstractOptionComparer<any>;
	private readonly _querySubject = new Subject<string>();
	private readonly _query = this._querySubject.asObservable();

	public queryValue: string;
	public selectedOptions: KnOption[] = [];

	public abstract loading: boolean;
	public abstract get focused(): boolean;
	public abstract get readonly(): boolean;
	public abstract get disabled(): boolean;
	public abstract get multiple(): boolean;
	public abstract get value(): any;
	public abstract blurEvent: Observable<FocusEvent>;
	public abstract focusEvent: Observable<FocusEvent>;
	public abstract changeEvent: Observable<void>;

	@Input('getter')
	public set coerceGetter(value: string | string[] | { (item: any): any }) {
		if (value == null) {
			this._getter = x => x;
		}
		else if (!Utils.isFunction(value)) {
			this._getter = x => Utils.object.get(x, value as string);
		}
		else {
			this._getter = value as { (item: any): any };
		}
	}

	@Input('comparer')
	public set coerceComparer(value: string | AbstractOptionComparer<any>) {
		if (value == null) {
			this._comparer = new TransliterateOptionComparer();
		}
		else if (!Utils.isFunction(value)) {
			switch (value as string) {
				case 'null':
					this._comparer = new NullOptionComparer();
					break;
				case 'strict':
					this._comparer = new StrictOptionComparer();
					break;
				case 'transliterate':
					this._comparer = new TransliterateOptionComparer();
					break;
				default:
					throw new NotSupportedError('Not supported comparer.');
			}
		}
		else {
			this._comparer = value as AbstractOptionComparer<any>;
		}
	}

	public constructor(protected _cdr: ChangeDetectorRef) {
		this.coerceGetter = null;
		this.coerceComparer = null;
	}

	public get getter() {
		return this._getter;
	}

	public get comparer() {
		return this._comparer;
	}

	public get query(): Observable<string> {
		return this._query;
	}

	public emitQuery(value: string) {
		this.queryValue = value;
		this._querySubject.next(value);
		this._update();
	}

	@ViewChild(forwardRef(() => KnOptionsContainer), { static: false })
	public viewContainer: KnOptionsContainer;

	@ContentChildren(forwardRef(() => KnOptgroup))
	public optgroups: QueryList<KnOptgroup>;

	@ContentChildren(forwardRef(() => KnOption), { descendants: true })
	public options: QueryList<KnOption>;

	public get container() {
		return this.viewContainer;
	}

	public get renderContainer() {
		return (this.optgroups && this.optgroups.length) || (this.options && this.options.length);
	}

	public get context() {
		return this._context;
	}

	public ngAfterContentInit() {
		if (this.options) {
			// wait a tick first to avoid one-time devMode unidirectional-data-flow-violation error
			// https://github.com/angular/angular/issues/10131
			const subscription = this.options.changes
				.pipe(Rx.delay(0))
				.subscribe(() => this._update());
			this._disposables.push(() => subscription.unsubscribe());
		}
	}

	public ngAfterViewInit() {
		// wait a tick first to avoid one-time devMode unidirectional-data-flow-violation error
		// https://github.com/angular/angular/issues/10131
		setTimeout(() => this._update());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public get isOpen() {
		return this.container && this.container.isOpen;
	}

	public open() {
		if (!this.readonly && !this.disabled) {
			this.container && this.container.open();
		}
	}

	public close() {
		if (this.focused) {
			this.focus();
		}
		this.container && this.container.close();
		this._cdr.markForCheck();
	}

	public abstract focus(): void;
	public abstract registerChild(element: ElementRef): Function;

	/** @internal */
	public abstract handleKeyup(event: KeyboardEvent): void;

	/** @internal */
	public abstract handleKeydown(event: KeyboardEvent): void;

	protected abstract _selectOptionInternal(value: any): void;

	public toggleOption(option: KnOption) {
		this.selectOption(this.getter(option.item));
	}

	public selectOption(value: any) {
		this._selectOptionInternal(value);
		if (!this.multiple) {
			this.close();
		}
		this._update();
	}

	protected _update() {
		if (!this.options || !this.options.length) {
			this.selectedOptions = [];
			this._context = null;
			this._cdr.markForCheck();
			return;
		}

		const items: any[] = [];
		this.selectedOptions = [];
		const values = Utils.array.box(this.value) || [this.value];
		const options = this.options.toArray();
		const comparer = this.comparer;
		for (const option of options) {
			option.selected = values.indexOf(this.getter(option.item)) !== -1;
			if (option.selected) {
				items.push(option.item);
				this.selectedOptions.push(option);
			}
			const accessor = comparer.accessor || (() => option.text);
			const value = comparer.getValue(option.item, accessor);
			option.hidden = !comparer.compare(value, this.queryValue ? `${this.queryValue}` : '');
		}

		this._context = {
			$implicit: this.multiple ? items : items[0],
			value: this.value
		};

		this._cdr.markForCheck();
	}

	public get hints() {
		return this._hints;
	}

	public addHints(hints: { [key: string]: string }) {
		for (const key in hints) {
			if (hints.hasOwnProperty(key)) {
				const hint = this._hints.find(x => x.key === key);
				if (hint != null) {
					hint.message = hints[key];
				}
				else {
					this._hints.push({ key: key, message: hints[key] });
				}
			}
		}

		this._cdr.markForCheck();
	}

	public removeHints(...keys: string[]) {
		this._hints = this._hints.filter(x => keys.indexOf(x.key) === -1);
		this._cdr.markForCheck();
	}

	public clearHints() {
		this._hints = [];
		this._cdr.markForCheck();
	}

	protected _processKey(event: KeyboardEvent) {
		switch (event.key || (event as any).code) {
			case 'ArrowDown':
				if (!this.isOpen) {
					this.open();
					return;
				}
				break;
			case 'Escape':
				this.close();
				return;
		}

		if (!this.isOpen || !this.options) {
			return;
		}
		const options = this.options.filter(x => !x.isDisabled && !x.hidden);
		if (options.length === 0) {
			return;
		}

		let index = options.findIndex(x => x.marked);
		switch (event.key || (event as any).code) {
			case 'ArrowDown':
				index = (index === -1) ? 0 : (index + 1);
				event.preventDefault();
				break;
			case 'ArrowUp':
				index = (index === -1) ? options.length - 1 : (index - 1);
				event.preventDefault();
				break;
			case 'Enter':
			case ' ':
				const marked = options.find(x => x.marked);
				if (marked != null) {
					this.toggleOption(marked);
					event.preventDefault();
				}
				return;
		}

		if (index >= 0 && index < options.length) {
			options.forEach((x, i) => x.marked = i === index);
			options[index].scrollIntoView();
		}
	}
}
