<div class="kn-column-context-menu-viewport"
		*knPortal="portalOrigin positions menuPositions minWidthByOrigin false"
		(mousedown)="suppressClosingHandler($event)"
		(touchstart)="suppressClosingHandler($event)">
	<ul>
		<li [ngClass]="{ disabled: !isColumnHidable(currentEntry) }">
			<a (click)="setColumnVisible(currentEntry, !!0)" i18n>Hide column</a>
		</li>
	</ul>
	<ul>
		<li class="checkbox"
			[ngClass]="{ active: getColumnGrouping(currentEntry), disabled: !isColumnGroupable(currentEntry) }">
			<a (click)="setColumnGrouping(currentEntry, !getColumnGrouping(currentEntry))" i18n>Group by</a>
		</li>
	</ul>
	<ul [ngClass]="{ disabled: !isColumnSortable(currentEntry) }">
		<li class="radio" [ngClass]="{ active: getColumnSorting(currentEntry) === 'none', disabled: getColumnGrouping(currentEntry) }">
			<a (click)="setColumnSorting(currentEntry, 'none')" i18n>No sorting</a>
		</li>
		<li class="radio" [ngClass]="{ active: getColumnSorting(currentEntry) === 'asc' }">
			<a (click)="setColumnSorting(currentEntry, 'asc')" i18n>Sort ascending</a>
		</li>
		<li class="radio" [ngClass]="{ active: getColumnSorting(currentEntry) === 'desc' }">
			<a (click)="setColumnSorting(currentEntry, 'desc')" i18n>Sort descending</a>
		</li>
	</ul>
	<ul>
		<li class="checkbox"
				[ngClass]="{ disabled: !isColumnResizable(currentEntry) || !getColumnSize(currentEntry) }">
			<a (click)="setSize(currentEntry, false)" i18n>Unset size</a>
		</li>
	</ul>
	<kn-column-context-menu-node [nodes]="entries"></kn-column-context-menu-node>
	<!-- <ul>
		<li class="checkbox"
				*ngFor="let column of cache.columns | hasLabel"
				[ngClass]="getCellClasses(column)">
			<a (click)="setVisibility(column, !getVisibility(column), false)">{{ getNameFromId(column.id) }}</a>
		</li>
	</ul> -->
</div>
