<div class="kn-checkbox-container"
		(click)="handleClick($event)"
		knRipple
		knRippleUnbounded
		knRippleCentered
		[knRippleDisabled]="disabled"
		[knRippleTrigger]="label"
		knRippleRadius="22">
	<input #input
			type="checkbox"
			[id]="inputId"
			[required]="required"
			[checked]="checked"
			[disabled]="disabled"
			[attr.name]="name"
			[attr.tabIndex]="tabindex"
			[indeterminate]="indeterminate"
			(focus)="handleFocus($event)"
			(blur)="handleBlur($event)"
			(change)="handleChange($event)">

	<div class="kn-checkbox-box">
		<svg class="kn-checkbox-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
			<path class="kn-checkbox-mark-path" fill="none" d="M4.1,12.7 9,17.6 20.3,6.3"/>
		</svg>
	</div>
</div>

<label [attr.for]="inputId" #label>
	<ng-content select="[kn-label]"></ng-content>
</label>
