import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'join' })
export class JoinPipe implements PipeTransform {
	public transform(value: string[], separator?: string): string {
		if (value == null) {
			return null;
		}
		return value.join(separator);
	}
}
