import {Utils} from 'kn-utils';
import {StaticAccessControl, AbstractAccessControlFactory, AccessAction} from 'kn-user';
import * as CommonModel from '../../../model/common-database.types';

export type RolePermissionRule = {
	[key: string]: string;
};

export type ActionsMappings = {
	[action: string]: RolePermissionRule | RolePermissionRule[]
};

export class RestAccessControl extends StaticAccessControl {
	public constructor(mapping: ActionsMappings, roles: CommonModel.Role[], authenticated: boolean = true) {
		super(RestAccessControl._buildActions(mapping, roles), authenticated);
	}

	private static _buildActions(mapping: ActionsMappings, roles: CommonModel.Role[]) {
		const actions: AccessAction[] = [];
		for (const action in mapping) {
			if (mapping.hasOwnProperty(action)) {
				const rolePermissionRules = Utils.array.box<RolePermissionRule>(mapping[action]);
				const matchingRoles = rolePermissionRules.reduce((acc, x) => acc.concat(RestAccessControl._matchRole(x, roles)), []);
				if (matchingRoles.length > 0) {
					actions.push({ name: action, restrictions: RestAccessControl._buildRestrictions(matchingRoles) });
				}
			}
		}
		return actions;
	}

	private static _buildRestrictions(appliedRoles: CommonModel.Role[]) {
		const restrictions: { [key: string]: any[] } = {};
		for (const role of appliedRoles) {
			for (const restrictionKey in role.restrictions) {
				if (role.restrictions.hasOwnProperty(restrictionKey)) {
					if (restrictions[restrictionKey] == null) {
						restrictions[restrictionKey] = [];
					}
					role.restrictions[restrictionKey].forEach((next: any) => {
						if (restrictions[restrictionKey].indexOf(next) === -1) {
							restrictions[restrictionKey].push(next);
						}
					});
				}
			}
		}
		return restrictions;
	}

	private static _matchRole(rule: RolePermissionRule, roles: CommonModel.Role[]) {
		const matching = [];
		for (const role of roles) {
			const relevant = Object.keys(rule).map(key => role.rolePermissions.find(x => x.permission === key));
			if (relevant.every(x => x && rule[x.permission].split('').every(a => x.actions.indexOf(a) !== -1))) {
				matching.push(role);
			}
		}
		return matching;
	}

	public static crud(keys: string | string[], or: boolean = false) {
		const makeAction = (action: string) => {
			const pairs = Utils.array.box(keys).map(x => ({ [x]: action }));
			return or ? pairs : [pairs.reduce((acc, x) => Object.assign(acc, x), {})];
		};
		return {
			create: makeAction('C'),
			read: makeAction('R'),
			update: makeAction('U'),
			delete: makeAction('D')
		};
	}
}

export class RestAccessControlFactory extends AbstractAccessControlFactory {
	public get name() {
		return 'krest';
	}

	public create(context: { [key: string]: any }, mapping: { (): ActionsMappings } | ActionsMappings) {
		if (!context.hasOwnProperty('roles')) {
			return null;
		}
		let resolvedMapping = mapping as ActionsMappings;
		if (Utils.isFunction(mapping)) {
			resolvedMapping = (mapping as () => ActionsMappings)();
		}
		if (resolvedMapping.hasOwnProperty('permissionNameReplacement')) {
			const replacedName = resolvedMapping['permissionNameReplacement'] as any as string;
			delete resolvedMapping['permissionNameReplacement'];
			for (const property in resolvedMapping) {
				if (resolvedMapping.hasOwnProperty(property)) {
					const rules = resolvedMapping[property];
					if (Array.isArray(rules)) {
						for (const rule of rules) {
							if (rule.hasOwnProperty('permission')) {
								rule[replacedName] = rule['permission'];
								delete rule['permission'];
							}
						}
					}
					else {
						if (rules.hasOwnProperty('permission')) {
							rules[replacedName] = rules['permission'];
							delete rules['permission'];
						}
					}
				}
			}
		}
		return new RestAccessControl(resolvedMapping, context.roles, !!context.authenticated);
	}
}
