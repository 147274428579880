<ng-container *ngIf="isSaveMode; else selectTemplateBlock">
	<kn-input #nameInput
			[ngModel]="selected?.name || ''"></kn-input>
	<a href="javascript:void(0)" i18n-title title="Save"
		*ngIf="nameInput.value?.length"
		(click)="save(nameInput.value)"><i class="material-icons">done</i></a>
</ng-container>
<ng-template #selectTemplateBlock>
	<kn-select
			i18n-placeholder="select-template-placeholder"
			placeholder="Select template..."
			getter="id"
			[ngModel]="selected?.id"
			(ngModelChange)="select($event)">
		<kn-option *ngFor="let preambule of preambules" [value]="preambule">
			{{ preambule.name }}
		</kn-option>
		<kn-option><em i18n>New template</em></kn-option>
	</kn-select>
	<a href="javascript:void(0)" i18n-title title="Save"
			*ngIf="selected && modified"
			(click)="save(selected.name)"><i class="material-icons">save</i></a>
	<a href="javascript:void(0)" i18n-title title="Remove"
			*ngIf="selected"
			(click)="remove(selected.id)"><i class="material-icons">delete</i></a>
</ng-template>
<a href="javascript:void(0)" i18n-title title="Cancel"
	*ngIf="selected || isSaveMode"
	(click)="cancel()"><i class="material-icons">clear</i></a>
