import {AbstractFilterSerializer} from './abstract-filter-serializer';
import {DescriptionBuilder} from '../description-builder';
import {Model, Filter, FilterNode, FilterValue, OptionDescription, BooleanOperator} from '../types';
import {ContractDescription, ContractWithId} from '../contract.types';
import {FilterNodeUtils} from '../internal-utils';

export class FlatFilterSerializer extends AbstractFilterSerializer<{ [key: string]: string }> {
	private static readonly _groupOperator = BooleanOperator.And;
	private static readonly _filterOperator = 'eq';

	public static from(descriptionOrOptions: ContractDescription | ContractWithId<OptionDescription<FilterValue>>[]) {
		let description = descriptionOrOptions as ContractDescription;
		if (Array.isArray(description)) {
			description = { options: description };
		}
		return new FlatFilterSerializer(DescriptionBuilder.from(description));
	}

	public static serialize(model: Model) {
		const options = AbstractFilterSerializer._deriveOptionsFromModel(model);
		const serializer = FlatFilterSerializer.from(options);
		return serializer.serialize(model);
	}

	public serialize(model: Model): { [key: string]: string } {
		if (!this._validateSerialization(model)) {
			throw new Error('Filter model cannot be serialized.');
		}

		const query: { [key: string]: string } = {};
		FilterNodeUtils.forEachNode(model, x => {
			if (!FilterNodeUtils.isGroup(x)) {
				const filter = x as Filter<FilterValue>;
				const type = this._getOptionDescription(filter.id).type;
				const id = encodeURIComponent(filter.id);
				const value = encodeURIComponent(this._serializeValue(filter.value, type) as string);
				query[id] = value;
			}
		});
		return query;
	}

	public deserialize(query: { [key: string]: string }): Model {
		const model: Model = {
			operator: FlatFilterSerializer._groupOperator,
			children: []
		};
		for (const key in query) {
			if (query.hasOwnProperty(key)) {
				const id = decodeURIComponent(key);
				const type = this._getOptionDescription(id).type;
				model.children.push({
					id: id,
					operator: FlatFilterSerializer._filterOperator,
					value: this._deserializeValue(decodeURIComponent(query[key]), type)
				} as Filter<FilterValue>);
			}
		}
		return model;
	}

	private _validateSerialization(node: FilterNode<FilterValue> | Filter<FilterValue>): boolean {
		let valid = true;
		FilterNodeUtils.forEachNode(node, x => {
			if (!FilterNodeUtils.isGroup(x)) {
				const filter = x as Filter<FilterValue>;
				valid = valid && filter.operator === FlatFilterSerializer._filterOperator;
				return;
			}

			const filterNode = x as FilterNode<FilterValue>;
			valid = valid && filterNode.children.length <= 1
					|| filterNode.operator === FlatFilterSerializer._groupOperator;
		});
		return valid;
	}
}
