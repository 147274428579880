import {Injectable} from '@angular/core';
import {OverlayService, AbstractOverlayService} from 'kn-overlay';
import {I18nService} from 'kn-shared';
import {KnConfirmation, ButtonDescriptor} from './confirmation.component';

@Injectable()
export class ConfirmationService extends AbstractOverlayService<KnConfirmation> {
	public constructor(private readonly _i18n: I18nService, overlay: OverlayService) {
		super(overlay, KnConfirmation);
	}

	public async show(message: string, details?: string, buttons?: ButtonDescriptor[]) {
		const confirmation = this._component.instance;

		confirmation.message = message;
		confirmation.details = details;
		confirmation.buttons = buttons;

		if (buttons == null) {
			confirmation.buttons = [{
				name: this._i18n.t('OK'),
				classes: ['primary'],
				result: 'ok'
			}, {
				name: this._i18n.t('Cancel')
			}];
		}

		confirmation.open();
		return new Promise((resolve, reject) => confirmation.choose.subscribe(resolve, reject));
	}
}
