import {Directive, Output, OnDestroy, EventEmitter, ElementRef} from '@angular/core';

@Directive({
	selector: '[knDeepBlur]'
})
export class KnDeepBlur implements OnDestroy {
	private readonly _eventsRemover: { (): void };

	@Output() public knDeepBlur = new EventEmitter<FocusEvent>();

	public constructor(private readonly _elementRef: ElementRef) {
		const blurListener = this._blur.bind(this);
		this._elementRef.nativeElement.addEventListener('blur', blurListener, true);
		this._eventsRemover = () => {
			this._elementRef.nativeElement.removeEventListener('blur', blurListener, true);
		};
	}

	public ngOnDestroy(): void {
		this._eventsRemover();
	}

	private _blur(event: FocusEvent) {
		if (!this._elementRef.nativeElement.contains(event.relatedTarget)) {
			this.knDeepBlur.emit(event);
		}
	}
}
