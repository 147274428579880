import {AbstractChartFactory} from './abstract-chart';
import {LineChart, BaseChart, BaseChartEventsTypes, LineChartData, LineChartOptions, ResponsiveOptions } from 'chartist';

export class LineChartFactory extends AbstractChartFactory {
	public get name(): string {
		return 'line';
	}

	public create(target: string | Element | null, data: LineChartData, options?: LineChartOptions, responsiveOptions?: ResponsiveOptions<LineChartOptions>): BaseChart<BaseChartEventsTypes> {
		return new LineChart(target, data, options, responsiveOptions);
	}
}
