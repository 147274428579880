export enum VersionComparison {
	Compatible,
	Down,
	Up
}

export type Version = {
	major: number; // breaking changes
	minor: number; // breaking changes, if major version is zero
	patch: number;
};

export class Semver {
	public static compare(currentVersion: string, requiredVersion: string): VersionComparison {
		if (currentVersion === requiredVersion || requiredVersion == null) {
			return VersionComparison.Compatible;
		}
		const versions = [currentVersion, requiredVersion].map(x => Semver.parse(x));
		if (versions[0].major < versions[1].major) {
			return VersionComparison.Up;
		}
		else if (versions[0].major > versions[1].major) {
			return VersionComparison.Down;
		}
		if (versions[0].major === 0) {
			if (versions[0].minor < versions[1].minor) {
				return VersionComparison.Up;
			}
			else if (versions[0].minor > versions[1].minor) {
				return VersionComparison.Down;
			}
		}
		return VersionComparison.Compatible;
	}

	public static parse(version: string): Version {
		if (version == null) {
			return { major: 0, minor: 0, patch: 0 };
		}
		const parts = version.split('.').map(Number);
		if (parts.length < 1 && parts.length > 3 && parts.some(isNaN)) {
			throw new Error('Invalid version.');
		}
		return { major: parts[0], minor: parts[1] || 0, patch: parts[2] || 0 };
	}
}
