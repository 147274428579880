import {Component, ChangeDetectionStrategy, Injectable, ComponentRef, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {UriContextService} from 'kn-rest';
import {KnLink} from 'common-web/grid';

@Component({
	selector: 'kn-app-link',
	template: '<a [href]="href" (click)="navigate($event)">{{ value }}</a>',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnCustomerLink extends KnLink {
	public constructor(
			locationStrategy: LocationStrategy,
			router: Router,
			private readonly _uriContext: UriContextService) {
		super(locationStrategy, router);
	}

	protected _buildCommands(): string[] {
		return [this._uriContext.context['customer'], ...super._buildCommands()];
	}
}
