import {AbstractModuleLoader} from './abstract-module-loader';

export class ScriptModuleLoader extends AbstractModuleLoader {
	public async import<T>(module: string, options: { name?: string } = {}): Promise<T> {
		const name = options.name ?? module;
		return new Promise((resolve, reject) => {
			const script = document.createElement("script");
			const teardown = () => {
				delete window[name];
				script.onerror = null;
				script.onload = null;
				script.remove();
				script.src = "";
			};
			script.onerror = () => {
				reject(new Error(`Failed to import: ${module}`));
				teardown();
			};
			script.onload = () => {
				resolve(window[name]);
				teardown();
			};
			script.src = module;
			document.head.appendChild(script);
		});
	}
}
