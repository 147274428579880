import {Component, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges, forwardRef, EventEmitter} from '@angular/core';
import {ControlValueAccessor, FormGroup, FormBuilder, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ValidationService} from 'kn-forms';
import {Subscription} from 'rxjs';
import {SubformConfiguration, SubformRow, SubformItem} from './types';

export interface InternalSubformItem extends SubformItem {
	required?: boolean;
}

export interface InternalSubformRow extends SubformRow  {
	inputs: InternalSubformItem[];
}

@Component({
	selector: 'kn-dynamic-subform',
	templateUrl: 'dynamic-subform.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => DynamicSubformComponent), // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
		multi: true
	}]
})
export class DynamicSubformComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
	private _subscription: Subscription;
	private _formValid: boolean;

	@Input() public configuration: SubformConfiguration;
	@Output() public validChange = new EventEmitter<boolean>();

	public form: FormGroup;
	public rows: InternalSubformRow[];

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	public constructor(
			private readonly _validation: ValidationService,
			private readonly _formBuilder: FormBuilder) { }

	public ngOnInit() {
		this._subscription = this.form.valueChanges
			.subscribe(next => {
				this._onTouchedCallback();
				this._onChangeCallback(next);
				if (this._formValid !== !this.form.invalid) {
					this._formValid = !this.form.invalid;
					this.validChange.emit(this._formValid);
				}
			});
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('configuration' in changes) {
			this.form = this._buildForm(this.configuration);
			this.rows = this._buildRows(this.configuration);
		}
	}

	public ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	private _buildForm(config: SubformConfiguration): FormGroup {
		const groupItems = {} as { [key: string]: any };

		for (const row of config.rows) {
			for (const item of row.items) {
				if (item.name == null) {
					continue;
				}
				const validators = (item.validators || []).map(validator => this._validation.validators[validator]);
				const asyncValidators = (item.asyncValidators || []).map(validator => this._validation.validators[validator]);
				groupItems[item.name] = [{value: item.defaultValue, disabled: item.disabled}, validators, asyncValidators];
			}
		}
		return this._formBuilder.group(groupItems);
	}

	private _buildRows(config: SubformConfiguration) {
		const rows = config.rows as InternalSubformRow[];

		for (const row of rows) {
			row.inputs = row.items as InternalSubformItem[];
			for (const item of row.inputs) {
				item.required = (item.validators || []).some(x => x === 'required');
				if (item.type === 'select' || item.type === 'radio') {
					const options = item.options || [];
					if (options[0] && typeof options[0] === 'string') {
						item.options = (options as string[]).map(opt => ({ value: opt, label: opt }));
					}
				}
			}
		}
		return rows;
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this.form.patchValue(value || {});
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean) {
		isDisabled ? this.form.disable() : this.form.enable();
	}
}
