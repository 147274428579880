import {Injectable} from '@angular/core';
import {Node} from '../model/node';
import {RowItem, Column} from '../types';

@Injectable()
export class GroupIdsCache {
	public key: string = 'groupIds';

	public getForNode(node: Node<RowItem>): string[] {
		return node.getRoot().cache.get(this.key);
	}

	public calculate(node: Node<RowItem>, columns: Column<RowItem>[]): GroupIdsCache {
		const root = node.getRoot();
		if (!root.cache.has(this.key)) {
			this._calculate(root, columns);
		}
		return this;
	}

	private _calculate(root: Node<RowItem>, columns: Column<RowItem>[]) {
		const groups = columns.filter(c => c.model.group).map(c => c.id);
		root.cache.set(this.key, groups);
	}
}
