import {Injectable, Optional} from '@angular/core';
import {AbstractStorageService} from 'kn-storage';

export class CacheProviderOptions {
	public maxAgeTimeSeconds: number;
	public maxUnusedTimeSeconds: number;
	public maxCacheSize: number;
	public keyPrefix: string;
}

interface CacheMetadata {
	length: number;
	timeToLive: Date;
	timeToUse: Date;
}

const ECMA_SIZES = {
		STRING: 2,
		BOOLEAN: 4,
		NUMBER: 8
};

const METADATA_SUFFIX = '_metadata';

@Injectable()
// https://github.com/pamelafox/lscache/blob/master/lscache.js
export class CacheProvider {
	private static readonly _arrayTypeNames = [
		'[object Float32Array]',
		'[object Float64Array]',
		'[object Int8Array]',
		'[object Int16Array]',
		'[object Int32Array]',
		'[object Uint8Array]',
		'[object Uint8ClampedArray]',
		'[object Uint16Array]',
		'[object Uint32Array]'
	];

	private readonly _options: CacheProviderOptions;

	public constructor(
			private readonly _backend: AbstractStorageService,
			@Optional() options?: CacheProviderOptions) {
		this._options = options;

		if (this._options == null) {
			this._options = {
				maxAgeTimeSeconds: 5 * 60,
				maxUnusedTimeSeconds: 60
			} as CacheProviderOptions;
		}
		if (this._options.keyPrefix == null) {
			this._options.keyPrefix = '_cache_provider_entry_';
		}
	}

	public get(key: string): any {
		const item = this._backend.getItem(this._getKey(key));
		if (item == null) {
			return null;
		}
		const metadata = this._validate(key);
		if (metadata == null) {
			return null;
		}
		metadata.timeToUse = new Date(Date.now() + this._options.maxUnusedTimeSeconds * 1000);
		this._setMetadata(key, metadata);
		return item;
	}

	public put(key: string, data: any): void {
		const metadata = {
			length: this._sizeof(data),
			timeToLive: new Date(Date.now() + this._options.maxAgeTimeSeconds * 1000),
			timeToUse: new Date(Date.now() + this._options.maxUnusedTimeSeconds * 1000)
		} as CacheMetadata;
		try {
			this._backend.setItem(this._getKey(key), data);
			this._setMetadata(key, metadata);
		}
		catch (e) {
			if (!this._isOutOfSpace(e)) {
				return;
			}
			this._prune(metadata.length);
			try {
				this._backend.setItem(this._getKey(key), data);
				this._setMetadata(key, metadata);
			}
			catch (e) {
				return;
			}
		}
	}

	public remove(key: string): void {
		this._backend.removeItem(this._getKey(key));
		this._backend.removeItem(this._getMetadataKey(key));
	}

	public clear() {
		this._backend.clear();
	}

	public keys(): string[] {
		const privatePrefixLength = this._options.keyPrefix.length;
		const matchingKeys: string[] = [];
		for (const key of this._backend.keys()) {
			if (!key.startsWith(this._options.keyPrefix)) {
				continue;
			}
			matchingKeys.push(key.substring(privatePrefixLength));
		}
		return matchingKeys;
	}

	private _getKey(key: string): string {
		return this._options.keyPrefix + key;
	}

	private _getMetadataKey(key: string): string {
		return this._getKey(key) + METADATA_SUFFIX;
	}

	private _sizeof(object: any): number {
		if (object !== null && typeof(object) === 'object') {
			const typeName = object.toString();
			if (typeName === '[object ArrayBuffer]') {
				return object.byteLength;
			}
			if (CacheProvider._arrayTypeNames.indexOf(typeName) !== -1) {
				return object.buffer.byteLength;
			}
			else {
				let bytes = 0;
				for (const key in object) {
					if (!Object.hasOwnProperty.call(object, key)) {
						continue;
					}

					bytes += this._sizeof(key);
					try {
						bytes += this._sizeof(object[key]);
					}
					catch (ex) {
						if (ex instanceof RangeError) {
							// circular reference detected, final result might be incorrect
							// let's be nice and not throw an exception
							bytes = 0;
						}
					}
				}
				return bytes;
			}
		}
		else if (typeof(object) === 'string') {
			return object.length * ECMA_SIZES.STRING;
		}
		else if (typeof(object) === 'boolean') {
			return ECMA_SIZES.BOOLEAN;
		}
		else if (typeof(object) === 'number') {
			return ECMA_SIZES.NUMBER;
		}
		else {
			return 0;
		}
	}

	private _isOutOfSpace(e: DOMException): boolean {
		return e && ['QUOTA_EXCEEDED_ERR', 'NS_ERROR_DOM_QUOTA_REACHED', 'QuotaExceededError']
			.indexOf(e.name) !== -1;
	}

	private _prune(atLeastSize: number): void {
		const now = new Date(Date.now());
		const notExpired: any[] = [];

		for (const key of this._backend.keys()) {
			if (!key.startsWith(this._options.keyPrefix)) {
				continue;
			}
			if (key.endsWith(METADATA_SUFFIX)) {
				continue;
			}
			const metadata = this._getMetadata(key);
			if (metadata == null) {
				this._backend.removeItem(key);
				continue;
			}

			if (metadata.timeToLive < now || metadata.timeToUse < now) {
				this.remove(key);
				if (atLeastSize > 0) {
					atLeastSize -= length;
					if (atLeastSize <= 0) {
						return;
					}
				}
				continue;
			}
			notExpired.push({
				key: key,
				length: length,
				expiration: metadata.timeToLive < metadata.timeToUse ? metadata.timeToLive : metadata.timeToUse
			});
		}
		notExpired.sort((a, b) => b.expiration - a.expiration);
		while (atLeastSize > 0) {
			const item = notExpired.pop();
			this.remove(item.key);
			atLeastSize -= item.length;
		}
	}

	private _getMetadata(key: string): CacheMetadata {
		const retrieved = this._backend.getItem(this._getMetadataKey(key));
		if (retrieved == null) {
			return null;
		}
		const parts = retrieved.split(' ');
		return {
			length: parseInt(parts[0], 10),
			timeToUse: new Date(parseInt(parts[1], 10)),
			timeToLive: new Date(parseInt(parts[2], 10))
		} as CacheMetadata;
	}

	private _setMetadata(key: string, metadata: CacheMetadata) {
		const data = metadata.length + ' '
			+ metadata.timeToUse.getTime() + ' '
			+ metadata.timeToLive.getTime();
		this._backend.setItem(this._getMetadataKey(key), data);
	}

	private _validate(key: string): CacheMetadata {
		const now = new Date(Date.now());
		const metadata = this._getMetadata(key);

		if (metadata == null) {
			this._backend.removeItem(this._getKey(key));
		}
		if (metadata.timeToLive < now || metadata.timeToUse < now) {
			this.remove(key);
			return null;
		}
		return metadata;
	}
}
