import {Directive, Input, SkipSelf, Optional, ViewContainerRef, EmbeddedViewRef, TemplateRef, SimpleChanges, OnChanges} from '@angular/core';
import {KnPolicy} from './policy.directive';
import {UserService} from '../../user.service';
import {AccessRestriction} from '../../access-control/abstract-access-control';

@Directive({
	selector: '[knCan]'
})
export class KnCan implements OnChanges {
	private readonly _user: UserService;
	private _view: EmbeddedViewRef<{}>;

	public constructor(
			@SkipSelf() @Optional() policy: KnPolicy,
			user: UserService,
			private readonly _viewContainer: ViewContainerRef,
			private readonly _template: TemplateRef<{}>) {
		this._user = policy ? user.fork(policy.scope) : user;
	}

	@Input('knCan') public action: string;
	@Input('knCanThe') public resource: string | string[];
	@Input('knCanRestrictions') public restrictions: AccessRestriction;

	public ngOnChanges(changes: SimpleChanges) {
		let negate = false;
		let action = this.action;
		if (action[0] === '!') {
			action = action.substr(1);
			negate = true;
		}
		let accessible = this._user.can(action, this.resource, this.restrictions);
		if (negate) {
			accessible = !accessible;
		}
		if (!accessible && this._view != null) {
			this._viewContainer.clear();
			this._view = null;
		}
		if (accessible && this._view == null) {
			this._view = this._viewContainer.createEmbeddedView(this._template);
		}
	}
}
