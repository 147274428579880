import {from as observableFrom, Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {AbstractAutocompletition, AutocompleteQueryResult, Resolvable} from './abstract-autocompletition';

export class Autocompletition<T> extends AbstractAutocompletition<T> {
	public constructor(
			private readonly _querySearch: (value: any, query: string, getter: { (item: T): any }) => AutocompleteQueryResult<T>) {
		super();
	}

	public querySearch(value: any, query: string, getter: { (item: T): any }): AutocompleteQueryResult<T> {
		return this._querySearch(value, query, getter);
	}

	public static from<T>(subject: Resolvable<T[]> | { (): T[] }) {
		if (Utils.isPromise(subject) || Utils.isObservable(subject)) {
			const items$ = (observableFrom(subject as Resolvable<T[]>) as Observable<T[]>)
				.pipe(Rx.publishReplay(1), Rx.refCount());
			return new Autocompletition<T>(() => items$);
		}
		else if (Utils.isFunction(subject)) {
			return new Autocompletition<T>(() => (subject as { (): T[] })());
		}
		else {
			return new Autocompletition<T>(() => subject as T[]);
		}
	}
}
