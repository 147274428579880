import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {KnAuthorizationModule} from 'kn-user';
import {KnLink} from './renderers/components/link.component';
import {KnInGridActions} from './renderers/components/in-grid-actions/in-grid-actions.component';
import {KnFileSaver} from './renderers/components/file-saver.component';

const KN_GRID_PANEL_DIRECTIVES = [
	KnLink,
	KnInGridActions,
	KnFileSaver
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		KnAuthorizationModule
	],
	declarations: [KN_GRID_PANEL_DIRECTIVES],
	exports: [KN_GRID_PANEL_DIRECTIVES],
	entryComponents: [KN_GRID_PANEL_DIRECTIVES]
})
export class KnGridFoundationModule { }
