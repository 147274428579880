import {NotAuthorizedError} from 'kn-shared';
import {AccessControlMapping} from '../configuration.types';

export type AccessRestriction = {
	[key: string]: any | any[];
};

export abstract class AbstractAccessControl {
	public abstract can(action: string, restrictions?: AccessRestriction): boolean;

	public authorize(action: string, restrictions?: AccessRestriction) {
		if (!this.can(action, restrictions)) {
			throw new NotAuthorizedError(`Access denied for ${action}`);
		}
	}
}

export abstract class AbstractAccessControlFactory {
	public abstract get name(): string;
	public abstract create(context: { [key: string]: any }, mapping?: AccessControlMapping): AbstractAccessControl;
}
