import {Injectable, Injector, ElementRef} from '@angular/core';
import {CellContext, CellValue} from '../types';
import {ElementRendererFactory} from './element-renderer-factory';
import {ElementRenderer} from './binders/element-render-binder-ref';

@Injectable()
export class EnumRendererFactory extends ElementRendererFactory {
	public constructor() {
		super();
	}

	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, translation: { [key: string]: any }, defaultValue?: any): ElementRenderer<T> {
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			return translation.hasOwnProperty(value) ? translation[value] : (defaultValue != null ? defaultValue : value);
		};
	}

	protected _defaultRenderer<T>(value: CellValue, context: CellContext<T>, translation: { [key: string]: any }, defaultValue?: any): any {
		return translation.hasOwnProperty(value) ? translation[value] : (defaultValue != null ? defaultValue : value);
	}
}
