import {Subject, Observable} from 'rxjs';
import {PropertyAccessor} from './property-accessor';

export class SettingWrapper<T> implements PropertyAccessor<T> {
	private readonly _valueChangeSubject$ = new Subject<T>();
	private readonly _valueChange$ = this._valueChangeSubject$.asObservable();

	public constructor(
			private readonly _key: string,
			private readonly _version: string,
			private readonly _discriminatorArg: any,
			private _value: T,
			private readonly _changeCallback: (value: T) => void) {
	}

	public get key() {
		return this._key;
	}

	public get version() {
		return this._version;
	}

	public get discriminatorArg() {
		return this._discriminatorArg;
	}

	public get value() {
		return this._value;
	}

	public set value(value: T) {
		this._valueChangeSubject$.next(value);
		if (this._value !== value) {
			this._value = value;
			this._changeCallback(value);
		}
	}

	public get valueChange(): Observable<T> {
		return this._valueChange$;
	}
}
