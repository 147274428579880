import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {RouteTitlePresenterConfig} from './route-title-presenter.config';
import {RouteTitlePresenter} from './route-title-presenter.service';

export class RouteTitlePresenterOptions extends RouteTitlePresenterConfig { }

export const ROUTE_TITLE_PRESENTER_FORROOT_GUARD = new InjectionToken<void>('ROUTE_TITLE_PRESENTER_FORROOT_GUARD');

export function provideRouteTitlePresenterConfig(config: RouteTitlePresenterOptions) {
	return Utils.object.defaults(config, {
		field: 'title',
		separator: ' · '
	});
}

@NgModule({})
export class KnRouteTitlePresenterModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(ROUTE_TITLE_PRESENTER_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<RouteTitlePresenterOptions>): ModuleWithProviders<KnRouteTitlePresenterModule> {
		return {
			ngModule: KnRouteTitlePresenterModule,
			providers: [
				...forRootGuardFactory('KnRouteTitlePresenterModule', ROUTE_TITLE_PRESENTER_FORROOT_GUARD, RouteTitlePresenter),
				{
					provide: RouteTitlePresenterOptions, useValue: config ? config : {}
				}, {
					provide: RouteTitlePresenterConfig,
					useFactory: provideRouteTitlePresenterConfig,
					deps: [RouteTitlePresenterOptions]
				},
				RouteTitlePresenter
			]
		};
	}
}
