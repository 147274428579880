import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {Utils} from 'kn-utils';
import * as Model from 'common-web/model';

@Component({
	selector: 'kn-audit-display',
	template: `
		<ng-template [ngIf]="audit && isValid()">
			<ng-template #modifiedByBlock><!--
				--><ng-container *ngIf="isEmail(audit.modifiedBy) else onlyNameBlock"><!--
					--><a href="mailto:{{ audit.modifiedBy }}">{{ audit.modifiedBy }}</a><!--
				--></ng-container><!--
				--><ng-template #onlyNameBlock>{{ audit.modifiedBy }}</ng-template><!--
			--></ng-template>
			<ng-container *ngIf="audit.modifiedBy && audit.lastModified" i18n><!--
				-->Edited by <!--
				--><strong><ng-template *ngTemplateOutlet="modifiedByBlock"></ng-template></strong> on <!--
				--><strong>{{ audit.lastModified | date: 'yMdjjmm' }}</strong><!--
				--></ng-container>
			<ng-container *ngIf="audit.modifiedBy && !audit.lastModified" i18n><!--
				-->Edited by <strong><ng-template *ngTemplateOutlet="modifiedByBlock"></ng-template></strong><!--
			--></ng-container>
			<ng-container *ngIf="!audit.modifiedBy && audit.lastModified" i18n><!--
				-->Edited on <strong>{{ audit.lastModified | date: 'yMdjjmm' }}</strong><!--
			--></ng-container>
		</ng-template>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnAuditDisplay {
	private static readonly _emailRexExp: RegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

	@Input() public audit: Model.AuditableEntity;

	public isValid() {
		if (this.audit.modifiedBy) {
			return true;
		}
		try {
			return Utils.date.fromIso8601(this.audit.lastModified) > new Date(0);
		}
		catch (err) {
			return false;
		}
	}

	public isEmail(value: string) {
		return value != null && value.match(KnAuditDisplay._emailRexExp);
	}
}
