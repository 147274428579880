import {Inject, NgModule, Optional, ModuleWithProviders, Provider, InjectionToken} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {forRootGuardFactory} from 'kn-shared';
import {KnCommonModule, AbstractModuleLoader, ScriptModuleLoader} from 'kn-common';
import {KnDatagridModule, CsvExporter} from 'kn-datagrid';
import {XlsxExporter} from '../exporters/xlsx-exporter.service';
import {GridExporterService} from './grid-exporter.service';

import {EXPORTER_FACTORIES_TOKEN} from './exporter-factories.token';
import {ExporterFactory} from './exporter-factory.type';

export const KN_GRID_EXPORTER_FORROOT_GUARD = new InjectionToken<void>('KN_GRID_EXPORTER_FORROOT_GUARD');

export function provideExporters(csvExporter: CsvExporter, xlsxExporter: XlsxExporter) {
	return [
		{
			type: 'csv',
			create: (description, model) => {
				csvExporter.description = description;
				csvExporter.model = model;
				return csvExporter;
			}
		}, {
			type: 'xlsx',
			create: (description, model) => {
				xlsxExporter.description = description;
				xlsxExporter.model = model;
				return xlsxExporter;
			}
		}
	] as ExporterFactory[];
}

export const KN_GRID_EXPORTER_PROVIDERS: Provider[] = [
	CsvExporter,
	XlsxExporter,
	{
		provide: AbstractModuleLoader,
		useClass: ScriptModuleLoader
	}, {
		provide: EXPORTER_FACTORIES_TOKEN,
		useFactory: provideExporters,
		deps: [CsvExporter, XlsxExporter]
	},
	GridExporterService
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnDatagridModule,
		KnCommonModule
	]
})
export class KnGridExporterModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KN_GRID_EXPORTER_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnGridExporterModule> {
		return {
			ngModule: KnGridExporterModule,
			providers: [
				...forRootGuardFactory('KnGridExporterModule', KN_GRID_EXPORTER_FORROOT_GUARD, KN_GRID_EXPORTER_PROVIDERS[0]),
				KN_GRID_EXPORTER_PROVIDERS
			]
		};
	}
}
