import {Aggregator, CellContext} from '../types';
import {AggregatorBase} from './aggregator-base';

export class AvgAggregator extends AggregatorBase  {
	public create<T>(weightProperty: keyof T | { (item: T): number }): Aggregator<T> {
		return (values: any[], ctx: CellContext<T>) => {
			if (weightProperty == null) {
				values = values.filter(x => x != null);
				return values.length === 0 ? null : values.reduce((p, c) => p + c, 0) / values.length;
			}
			const value = this._lookupNode(
				ctx.node,
				ctx.column.description.accessor && (i => ctx.column.description.accessor(i, ctx.column)) || ctx.column.id as keyof T,
				aggs => aggs.reduce((p, c) => {
					const nw = p.weight + c.weight;
					p.value = (p.value * (p.weight === 0 ? 1 : p.weight) + (c.weight === 0 ? 1 : c.weight) * (c.value || 0)) / (nw === 0 ? 1 : nw);
					p.weight = nw;
					return p;
				}, {weight: 0, value: 0}),
				weightProperty
			);
			return value.value;
		};
	}
}
