import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'utc' })
export class UtcPipe implements PipeTransform {
	public transform(value: Date | string | number): Date {
		if (value == null) {
			return null;
		}
		let date = value as Date;
		if (Utils.isString(value)) {
			date = Utils.date.fromIso8601(value as string);
		}
		else if (Utils.isNumber(value)) {
			date = new Date(value as number);
		}
		return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
	}
}
