import {NgModule, ModuleWithProviders} from '@angular/core';
import {KnConfirmationModule} from './confirmation/confirmation.module';
import {KnToastModule} from './toast/toast.module';
import {SharedModule} from './shared.module';
import {ComponentDeactivateGuard} from './component-deactivate.guard';

const KN_MODAL_MODULES = [
	KnConfirmationModule,
	KnToastModule,
	SharedModule
];

@NgModule({
	imports: [
		KnConfirmationModule.forRoot(),
		KnToastModule.forRoot()
	],
	exports: KN_MODAL_MODULES
})
export class KnModalRootModule { }

@NgModule({
	imports: [KN_MODAL_MODULES],
	exports: [KN_MODAL_MODULES]
})
export class KnModalModule {
	public static forRoot(): ModuleWithProviders<KnModalRootModule> {
		return {
			ngModule: KnModalRootModule,
			providers: [ComponentDeactivateGuard]
		};
	}

	public static forChild(): ModuleWithProviders<KnModalModule> {
		return { ngModule: KnModalModule };
	}
}
