import {Routes, RouterModule} from '@angular/router';
import {ComponentDeactivateGuard} from 'kn-modal';
import {RolesComponent} from './roles.component';

const routes: Routes = [
	{
		path: '',
		component: RolesComponent,
		canDeactivate: [ComponentDeactivateGuard]
	}
];

export const routing = RouterModule.forChild(routes);
