import {Component, OnInit, OnDestroy} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {of} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {DatabasesResourceService} from 'common-web/app';
import {I18nService} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {UserService} from 'kn-user';
import {SessionStorageService} from 'kn-storage';
import {UserSettingsFactory} from 'kn-user-settings';
import {AppMeta} from '../../app.meta';
import {User} from '../../model/master-database.types';

@Component({
	selector: 'kn-app-signin',
	templateUrl: 'signin.html'
})
export class SigninComponent implements OnInit, OnDestroy {
	private readonly _disposables: Function[] = [];

	public isAuthenticated: boolean = false;
	public form: FormGroup;
	public user: User;

	public constructor(
			public meta: AppMeta,
			private readonly _i18n: I18nService,
			private readonly _user: UserService,
			private readonly _router: Router,
			private readonly _formBuilder: FormBuilder,
			private readonly _databasesResource: DatabasesResourceService,
			private readonly _sessionStorage: SessionStorageService,
			private readonly _toast: ToastService,
			private readonly _settingsFactory: UserSettingsFactory) {
	}

	public ngOnInit() {
		if (this._user.isAuthenticated()) {
			this.user = this._user.getUser<User>();
			this.isAuthenticated = true;
		}
		this.form = this._formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required]
		});
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public signIn() {
		this._signOut();
		const subscription = this._user.signIn(this.form.value)
			.pipe(Rx.switchMap(() => this._databasesResource.single()
				.pipe(Rx.catchError(() => of(null)))))
			.subscribe(
				next => {
					if (next != null) {
						this.databaseSelected(next.uid);
					}
					else {
						this.user = this._user.getUser<User>();
						this.isAuthenticated = true;
					}
				},
				error => {
					let message: string;
					if ((error as Response).status >= 500) {
						message = (error as Response).statusText;
					}
					else {
						message = this._i18n.t('Login or password is incorrect.');
					}
					this._toast.show(this._i18n.t('Authentication failed'), message);
					this.isAuthenticated = false;
					this.form.get('password').reset();
				}
			);
		this._disposables.push(() => subscription.unsubscribe());
	}

	public signOut($event: Event) {
		this._signOut();
		$event.preventDefault();
	}

	public databaseSelected(databaseUid: string) {
		this._router.navigate([databaseUid]);
	}

	private _signOut() {
		this._user.signOut();
		this._sessionStorage.clear();
		this._settingsFactory.unlinkAll();
		this.isAuthenticated = false;
	}
}
