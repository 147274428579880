export class SandboxedDocument {
	public constructor(private readonly _frame: HTMLIFrameElement) { }

	public get nativeWindow(): Window {
		return this._frame.contentWindow;
	}

	public get nativeDocument(): Document {
		return this._frame.contentDocument || this._frame.contentWindow.document;
	}

	public get style(): CSSStyleDeclaration {
		return this._frame.style;
	}

	public destroy() {
		this._frame.parentElement.removeChild(this._frame);
	}
}

export class SandboxedDocumentBuilder {
	public async build(html: string): Promise<SandboxedDocument> {
		const iframe = document.createElement('iframe');
		iframe.style.overflow = 'hidden';
		iframe.style.visibility = 'hidden';
		document.body.appendChild(iframe);
		return new Promise<SandboxedDocument>((resolve, reject) => {
			let doc: SandboxedDocument;
			iframe.onload = () => resolve(doc);
			iframe.onerror = reject;
			doc = new SandboxedDocument(iframe);
			doc.nativeDocument.open();
			doc.nativeDocument.write(html);
			doc.nativeDocument.close();
		});
	}
}
