import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {CachedHttp} from 'kn-cache';
import {Http} from 'kn-http';
import {forRootGuardFactory} from 'kn-shared';
import {CountriesConfig} from './countries.config';
import {CountriesStore} from './countries-store.service';
import {COUNTRIES_HTTP_TOKEN} from './countries-http.token';

export class CountriesOptions extends CountriesConfig { }

export const COUNTRIES_FORROOT_GUARD = new InjectionToken<void>('COUNTRIES_FORROOT_GUARD');

export function provideCountriesConfig(config: CountriesOptions) {
	return Utils.object.defaults(config, {
		countriesUrl: '/assets/data/countries.json'
	});
}

export function provideCountriesHttp(http: Http, cachedhttp: Http) {
	return cachedhttp || http;
}

@NgModule({})
export class KnCountriesModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(COUNTRIES_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<CountriesOptions>): ModuleWithProviders<KnCountriesModule> {
		return {
			ngModule: KnCountriesModule,
			providers: [
				...forRootGuardFactory('KnCountriesModule', COUNTRIES_FORROOT_GUARD, CountriesStore),
				{
					provide: CountriesOptions, useValue: config ? config : {}
				}, {
					provide: COUNTRIES_HTTP_TOKEN,
					useFactory: provideCountriesHttp,
					deps: [Http, [CachedHttp, new Optional()]]
				}, {
					provide: CountriesConfig,
					useFactory: provideCountriesConfig,
					deps: [CountriesOptions]
				},
				CountriesStore
			]
		};
	}
}
