import {Inject, Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {NotSupportedError} from 'kn-shared';
import {AbstractStoreBackend} from './backends/abstract-store-backend';
import {AbstractUserSettingsService} from './abstract-user-settings.service';
import {ComponentUserSettingsService} from './component-user-settings.service';
import {KeysUserSettingsService} from './keys-user-settings.service';
import {AbstractMigrationStrategy} from './migration-strategies/abstract-migration-strategy';
import {AbstractDiscriminateStrategy} from './discriminate-strategies/abstract-discriminate-strategy';
import {AbstractDiscriminatorProvider} from './discriminator-providers/abstract-discriminator-provider';
import {USER_SETTINGS_MIGRATION_STRATEGIES_TOKEN} from './user_settings_migration_strategies.token';
import {USER_SETTINGS_DISCRIMINATE_STRATEGIES_TOKEN} from './user_settings_discriminate_strategies.token';
import {USER_SETTINGS_DISCRIMINATE_PROVIDER_TOKEN} from './user_settings_discriminate_provider.token';
import {USER_SETTINGS_BACKENDS_TOKEN} from './user_settings_backends.token';
import {KeySpecifier} from './types';

export class UserSettingsConfig {
	public debounceTime?: number;
	public retry?: number;
	public flushInterval?: number;
	public autoFlush?: boolean;
	public autoFetch?: boolean;
}

@Injectable()
export class UserSettingsFactory {
	private readonly _config: UserSettingsConfig;
	private readonly _services: AbstractUserSettingsService[] = [];
	private readonly _backends: AbstractStoreBackend[];
	private readonly _migrationStrategies: AbstractMigrationStrategy[] = [];
	private readonly _discriminateStrategies: AbstractDiscriminateStrategy[] = [];
	private readonly _discriminatorProvider: AbstractDiscriminatorProvider;

	public constructor(
			@Inject(USER_SETTINGS_BACKENDS_TOKEN) backends: AbstractStoreBackend[],
			@Inject(USER_SETTINGS_MIGRATION_STRATEGIES_TOKEN) migrationStrategies: AbstractMigrationStrategy[],
			@Inject(USER_SETTINGS_DISCRIMINATE_STRATEGIES_TOKEN) discriminateStrategies: AbstractDiscriminateStrategy[],
			@Inject(USER_SETTINGS_DISCRIMINATE_PROVIDER_TOKEN) discriminatorProvider: AbstractDiscriminatorProvider,
			config: UserSettingsConfig = {}) {
		this._config = Utils.object.defaults({}, config, {
			debounceTime: 3000,
			retry: 3,
			flushInterval: null,
			autoFlush: true,
			autoFetch: true
		});
		this._backends = backends;
		this._migrationStrategies = migrationStrategies.slice().reverse();
		this._discriminateStrategies = discriminateStrategies.slice().reverse();
		this._discriminatorProvider = discriminatorProvider;
	}

	public get config() {
		return this._config;
	}

	public get backends() {
		return this._backends;
	}

	public getDiscriminateStrategy(key: string, discriminator: string, discriminatorArg: any) {
		for (const strategy of this._discriminateStrategies) {
			if (strategy.support(key, discriminator, discriminatorArg)) {
				return strategy;
			}
		}
		throw new NotSupportedError('Compatible discriminate strategy not found.');
	}

	public getMigrationStrategy(key: string, targetVersion: string) {
		for (const strategy of this._migrationStrategies) {
			if (strategy.support(key, targetVersion)) {
				return strategy;
			}
		}
		throw new NotSupportedError('Compatible migration strategy not found.');
	}

	public getDiscriminatorProvider() {
		return this._discriminatorProvider;
	}

	public linkComponent(typeOrFunc: any) {
		return this._link(typeOrFunc, () => new ComponentUserSettingsService(this, typeOrFunc));
	}

	public linkKeys(keys: KeySpecifier[]) {
		return this._link(keys, () => new KeysUserSettingsService(this, keys));
	}

	private _link(origin: any, factory: () => AbstractUserSettingsService) {
		let service = this._services.find(x => x.origin === origin);
		if (service != null) {
			service.relink();
			return service;
		}
		service = factory();
		this._services.push(service);
		return service;
	}

	public unlink(service: AbstractUserSettingsService) {
		const index = this._services.indexOf(service);
		if (index !== -1) {
			service.dispose();
			this._services.splice(index, 1);
		}
	}

	public unlinkAll() {
		this._services.forEach(service => this.unlink(service));
	}
}
