import {Directive, HostListener, Inject, NgZone, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {RowItem, Description} from '../types';
import {SelectionService} from '../services/selection.service';
import {KnTableBody} from '../parts/table-body.component';
import {KnTableRow} from '../parts/table-row.directive';

interface RowDescription {
	decisionPoint: number;
	row: KnTableRow;
}

interface Reordering {
	rows: RowDescription[];
	subjects: RowItem[];
	origin: number;
	index: number;
}

export interface ReorderRowData {
	subjects: RowItem[];
	offset: number;
	modified: boolean;
	done: boolean;
}

@Directive({
	selector: '[knReordableRowsContainer]'
})
export class KnReordableRowsContainer {
	private readonly _document: Document;
	private _preventClick = false;
	private _reordering: Reordering = null;
	private _eventsRemoverOutsideAngular: { (): void };

	@Input() public description: Description<RowItem>;
	@Output() public reorderRows = new EventEmitter<ReorderRowData>();

	public constructor(
			private readonly _changeDetector: ChangeDetectorRef,
			private readonly _zone: NgZone,
			@Inject(DOCUMENT) document: any /* Document */,
			private readonly _tableBody: KnTableBody,
			private readonly _selection: SelectionService) {
		this._document = document;
		this._zone.runOutsideAngular(() => {
			const reorderClickListener = this._reorderClickHandler.bind(this);
			const reorderMoveListener = this._reorderMoveHandler.bind(this);
			this._document.addEventListener('click', reorderClickListener, true);
			this._document.addEventListener('mousemove', reorderMoveListener);
			this._document.addEventListener('touchmove', reorderMoveListener);
			this._eventsRemoverOutsideAngular = () => {
				this._document.removeEventListener('click', reorderClickListener, true);
				this._document.removeEventListener('mousemove', reorderMoveListener);
				this._document.removeEventListener('touchmove', reorderMoveListener);
			};
		});
	}

	public ngOnDestroy() {
		this._zone.runOutsideAngular(() => this._eventsRemoverOutsideAngular());
	}

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public reorderStartHandler(event: MouseEvent | TouchEvent) {
		if (!event.ctrlKey || !this.description.rowsReordering) {
			return;
		}
		const position = (event as MouseEvent).clientY || (event as TouchEvent).targetTouches[0].clientY;
		const rows = this._getRowsDescriptions();
		const index = this._findIndexOfTargetRow(rows, position);
		this._reordering = this._createReordering(rows, index);
		if (this._reordering != null) {
			event.preventDefault();
		}
	}

	private _reorderMoveHandler(event: MouseEvent | TouchEvent) {
		this._preventClick = false;
		if (this._reordering) {
			const position = (event as MouseEvent).clientY || (event as TouchEvent).targetTouches[0].clientY;
			let index = this._findIndexOfTargetRow(this._reordering.rows, position);

			if (index !== this._reordering.index) {
				const indexRow = this._reordering.rows[this._reordering.index].row;
				this.reorderRows.emit({
					subjects: this._reordering.subjects,
					offset: index - this._reordering.index,
					modified: index !== this._reordering.origin,
					done: false
				});
				this._changeDetector.detectChanges();

				const rows = this._getRowsDescriptions();
				index = rows.findIndex(x => x.row === indexRow);
				this._reordering = this._createReordering(rows, this._reordering.origin, index);
				this._tableBody.rows
						.filter(x => this._reordering.subjects.indexOf(x.node.item) !== -1)
						.forEach(x => x.dragging = true);
				this._changeDetector.detectChanges();
			}

			const epsilon = this._document.documentElement.clientHeight / 50;
			if (position + epsilon >= this._document.documentElement.clientHeight) {
				this._document.body.scrollTop += epsilon;
			}
			if (position - epsilon <= 0) {
				this._document.body.scrollTop -= epsilon;
			}

			event.preventDefault();
		}
	}

	@HostListener('mouseup', ['$event'])
	@HostListener('touchend', ['$event'])
	public reorderEndHandler(event: MouseEvent | TouchEvent) {
		if (this._reordering) {
			const position = (event as MouseEvent).clientY || (event as TouchEvent).targetTouches[0].clientY;
			const index = this._findIndexOfTargetRow(this._reordering.rows, position);

			this.reorderRows.emit({
				subjects: this._reordering.subjects,
				offset: index - this._reordering.index,
				modified: index !== this._reordering.origin,
				done: true
			});

			this._preventClick = (index !== this._reordering.origin);
			this._tableBody.rows.forEach(x => x.dragging = false);
			this._reordering = undefined;

			if (this._preventClick) {
				event.preventDefault();
			}
		}
	}

	private _reorderClickHandler(event: MouseEvent | TouchEvent): void {
		if (this._reordering || this._preventClick) {
			event.stopPropagation();
			this._preventClick = false;
		}
	}

	private _getRowsDescriptions(): RowDescription[] {
		const rows = this._tableBody.rows.map(x => {
			return {
				decisionPoint: x.getClientRect().top,
				row: x
			};
		});
		rows.sort((a, b) => a.decisionPoint - b.decisionPoint);
		return rows;
	}

	private _findIndexOfTargetRow(rows: RowDescription[], position: number) {
		let index = 0;
		while (index < rows.length - 1) {
			if (position < rows[index + 1].decisionPoint) {
				break;
			}
			index++;
		}
		return index;
	}

	private _createReordering(rows: RowDescription[], origin: number, index?: number): Reordering {
		const indexIsOrigin = index == null;
		index = indexIsOrigin ? origin : index;
		if (!rows[index].row.node.isLeaf()) {
			return null;
		}

		const siblings = rows[index].row.node.parent.children;
		const indexRow = rows[index];
		let validTargets = rows.filter(x => siblings.indexOf(x.row.node) !== -1);

		let subjects: RowDescription[] = [indexRow];
		if (this._selection.isSelected(indexRow.row.node)) {
			subjects = validTargets.filter(x => this._selection.isSelected(x.row.node));
			const indexes = validTargets
					.map((x, i) => subjects.indexOf(x) !== -1 ? i : -1)
					.filter(x => x >= 0);
			const offset = validTargets.indexOf(indexRow);
			validTargets = validTargets.slice(offset - indexes[0], validTargets.length - indexes[indexes.length - 1] + offset);
		}

		index = validTargets.indexOf(indexRow);
		origin = indexIsOrigin ? index : origin;

		if (validTargets.length < 2 || index === -1) {
			return null;
		}

		return {
			rows: validTargets,
			subjects: subjects.map(x => x.row.node.item),
			origin: origin,
			index: index
		};
	}
}
