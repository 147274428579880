import {ConsolePipe} from './pipes/console.pipe';
import {DatePipe} from './pipes/date.pipe';
import {DecimalPipe} from './pipes/decimal.pipe';
import {DecyclePipe} from './pipes/decycle.pipe';
import {FilterPipe} from './pipes/filter.pipe';
import {FindPipe} from './pipes/find.pipe';
import {FlattenPipe} from './pipes/flatten.pipe';
import {GetPipe} from './pipes/get.pipe';
import {JoinPipe} from './pipes/join.pipe';
import {TimesPipe} from './pipes/times.pipe';
import {TimespanPipe} from './pipes/timespan.pipe';
import {UniquePipe} from './pipes/unique.pipe';
import {UtcPipe} from './pipes/utc.pipe';

export {ConsolePipe} from './pipes/console.pipe';
export {GetPipe} from './pipes/get.pipe';
export {FlattenPipe} from './pipes/flatten.pipe';
export {TimesPipe} from './pipes/times.pipe';
export {TimespanPipe} from './pipes/timespan.pipe';
export {DatePipe} from './pipes/date.pipe';
export {DecimalPipe} from './pipes/decimal.pipe';
export {JoinPipe} from './pipes/join.pipe';
export {DecyclePipe} from './pipes/decycle.pipe';
export {FilterPipe} from './pipes/filter.pipe';
export {FindPipe} from './pipes/find.pipe';
export {UniquePipe} from './pipes/unique.pipe';
export {UtcPipe} from './pipes/utc.pipe';

export const KN_COMMON_PIPES = [
	ConsolePipe,
	GetPipe,
	FlattenPipe,
	TimesPipe,
	TimespanPipe,
	DatePipe,
	DecimalPipe,
	JoinPipe,
	DecyclePipe,
	FilterPipe,
	FindPipe,
	UtcPipe,
	UniquePipe
];
