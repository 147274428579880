import {Utils} from 'kn-utils';
import {ColumnModel, Sorting, Description, DataSource, Column} from './types';

export function getSorting<T>(columns: ColumnModel[], description: Description<T>): Column<T>[] {
	const sorting = columns
		.filter(x => x.visible && (x.group || x.sort !== Sorting.None))
		.map(x => ({
			id: x.id,
			model: x,
			description: description.columns.find(column => x.id === column.id)
		} as Column<T>));
	return Utils.array.sort(sorting, (a, b) => (a.model.group ? 0 : 1) - (b.model.group ? 0 : 1));
}

export function dataSourceSort<T>(dataSource: DataSource<T>, sortingColumns: Column<T>[]): DataSource<T> {
	const columns = sortingColumns.map(x => {
		return {
			desc: x.model.sort === Sorting.Descending,
			accessor: (item: any) => x.description.accessor(item, x)
		};
	});
	return Utils.array.sort(dataSource, columns);
}

export function groupAccessor<T>(path: string[], item: T, column: Column<T>): any {
	let index = 0;
	let value: any = null;
	while (!value && index < path.length) {
		value = Utils.object.get(item, path[index++]);
	}
	return value || Utils.object.get(item, column.id);
}

export function avgAggregator<T>(values: number[], column: Column<T>): number {
	let count = 0;
	let sum = 0;

	values.forEach(element => {
		if (element) {
			count++;
			sum += element;
		}
	});

	return count === 0 ? 0 : sum / count;
}
