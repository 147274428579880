import {Directive, AfterViewInit, ElementRef, Input} from '@angular/core';
import {BooleanField} from 'kn-common';

@Directive({
	selector: '[knAutofocus]'
})
export class KnAutofocus implements AfterViewInit {
	private static readonly _selector: string = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
	@BooleanField() @Input() public knAutofocus: boolean = true;

	public constructor(private readonly _elementRef: ElementRef) { }

	public ngAfterViewInit(): void {
		if (this.knAutofocus) {
			// HOTFIX: https://github.com/angular/angular/issues/16049
			setTimeout(() => {
				const el = this._elementRef.nativeElement.querySelector(KnAutofocus._selector) || this._elementRef.nativeElement;
				el && el.focus();
			});
		}
	}
}
