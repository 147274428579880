import {Injectable} from '@angular/core';
import {AbstractTemplatesClonerService} from './abstract-templates-cloner.service';
import {ViewTemplate} from 'common-web/model';
import {ViewTemplatesResourceService} from '../services/view-templates/view-templates-resource.service';

@Injectable()
export class ViewTemplatesClonerService extends AbstractTemplatesClonerService<ViewTemplate> {
	public constructor(viewTemplatesResource: ViewTemplatesResourceService) {
		super({
			key: 'viewTemplate',
			resource: viewTemplatesResource,
			target: { query: { only: ['view', 'name'] } }
		});
	}
}
