import {AbstractDatagridBond} from './abstract-datagrid-bond';
import {KnDatagrid} from '../datagrid.component';
import {RowItem} from '../types';

export abstract class AbstractConcreteDatagridBond<T extends RowItem> extends AbstractDatagridBond<T> {
	private _datadrid: KnDatagrid;

	public refreshRows(rebuildTree?: boolean, refreshItems?: RowItem[]): void {
		this._datadrid && this._datadrid.refreshRows(rebuildTree, refreshItems);
	}

	public scrollToView(item: RowItem) {
		this._datadrid && this._datadrid.scrollToView(item);
	}

	public registerDatagrid(datadrid: KnDatagrid) {
		this._datadrid = datadrid;
	}
}
