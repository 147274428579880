export type PairSegments = { [key: string]: string | string[] };

export function encodePairSegments(value: PairSegments): string[] {
	let result: string[] = [];
	for (const key in value) {
		if (!value.hasOwnProperty(key)) {
			continue;
		}
		if (value[key] === null) {
			result.push(encodeURIComponent(key));
		}
		else if (value[key] !== undefined) {
			if (Array.isArray(value[key])) {
				const parts = (value[key] as string[])
					.map(item => [key, item].map(encodeURIComponent).join('='));
				result = result.concat(parts);
			}
			else {
				result.push([key, value[key] as string].map(encodeURIComponent).join('='));
			}
		}
	}
	return result;
}

export function decodePairSegments(value: string[]): PairSegments {
	const result: PairSegments = {};
	for (const part of value) {
		const pair = part.split('=', 2).map(decodeURIComponent);
		if (pair.length === 1) {
			pair.push(null);
		}
		const [key, v] = pair;
		if (result.hasOwnProperty(key) && result[key] !== undefined) {
			if (Array.isArray(result[key])) {
				(result[key] as string[]).push(v);
			}
			else {
				result[key] = [result[key] as string, v];
			}
		}
		else {
			result[key] = v;
		}
	}
	return result;
}
