<span class="filter-name">
	<strong>{{ label }}</strong>
</span>
<span class="filter-operator">
	<kn-select [ngModel]="getOperator()" (ngModelChange)="selectOperator($event.id)" [disabled]="item.disabled || operators.length <= 1">
		<ng-template #header let-item>{{ item?.label }}</ng-template>
		<kn-option *ngFor="let item of operators" [value]="item">{{ item?.label }}</kn-option>
	</kn-select>
</span>
<span class="filter-value" [ngSwitch]="presenter">
	<ng-template [ngIf]="constraints">
		<span *ngSwitchCase="'date-between'" class="presenter">
			<span i18n>from</span>
			<kn-date-input type="datetime"
					required="true"
			 		[disabled]="item.disabled"
					[ngModel]="toIso8601(item.value[0])"
					(ngModelChange)="item.value[0] = fromIso8601($event, true)"
					[min]="toIso8601(constraints.mindate, 'datetime')"
					[max]="toIso8601(item.value[1], 'datetime')">
			</kn-date-input>
			<span i18n>to</span>
			<kn-date-input type="datetime"
					required="true"
			 		[disabled]="item.disabled"
					[ngModel]="toIso8601(item.value[1])"
					(ngModelChange)="item.value[1] = fromIso8601($event)"
					[min]="toIso8601(item.value[0], 'datetime')">
			</kn-date-input>
		</span>
		<span *ngSwitchCase="'combobox'" class="presenter">
			<kn-select [disabled]="item.disabled" [(ngModel)]="item.value" hasFilter>
				<kn-option *ngFor="let option of getOptionParameter('options') | async"
						[item]="option"
						[value]="option.value"><em *ngIf="option != null && option.value == null; else optionWithValue">{{ option.label }}</em>
						<ng-template #optionWithValue>{{ option?.label }}</ng-template></kn-option>
			</kn-select>
		</span>
		<span *ngSwitchCase="'string-input'" class="presenter">
			<kn-input type="text"
			 		[disabled]="item.disabled"
					[(ngModel)]="item.value"
					[attr.maxlength]="constraints.maxlength"
					[attr.minlength]="constraints.minlength">
				<kn-optgroup label="Dalsi" *ngIf="option.autocompletition"
						[knAutocomplete]="option.autocompletition" #options="knAutocomplete">
					<kn-option *ngFor="let option of options.suggestions" [value]="option">
						{{ option }}
					</kn-option>
				</kn-optgroup>
			</kn-input>
		</span>
		<span *ngSwitchCase="'number-input'" class="presenter">
			<kn-input type="number"
			 		[disabled]="item.disabled"
					[(ngModel)]="item.value"
					[attr.step]="constraints.step"
					[attr.max]="constraints.max"
					[attr.min]="constraints.min"></kn-input>
		</span>
		<span *ngSwitchCase="'number-between'" class="presenter">
			<span i18n>from</span>
			<kn-input type="number"
			 		[disabled]="item.disabled"
					[(ngModel)]="item.value[0]"
					[attr.step]="constraints.step"
					[attr.max]="item.value[1]"
					[attr.min]="constraints.min"></kn-input>
			<span i18n>to</span>
			<kn-input type="number"
			 		[disabled]="item.disabled"
					[(ngModel)]="item.value[1]"
					[attr.step]="constraints.step"
					[attr.max]="constraints.max"
					[attr.min]="item.value[0]"></kn-input>
		</span>
		<span *ngSwitchCase="'checkbox'" class="presenter">
			<kn-checkbox [disabled]="item.disabled" [(ngModel)]="item.value">
				<span kn-label>
					<ng-container *ngIf="item.value" i18n="bool">on</ng-container>
					<ng-container *ngIf="!item.value" i18n="bool">off</ng-container>
				</span>
			</kn-checkbox>
		</span>
	</ng-template>
</span>
<span class="filter-actions">
	<button i18n-title title="Remove" *ngIf="!(item.fixed || false)" (click)="remove.emit(item); $event.stopPropagation()"><i class="material-icons">clear</i></button>
</span>
