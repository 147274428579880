import {Component, Input} from '@angular/core';
import {Utils} from 'kn-utils';
import {AbstractFilterSerializer, AbstractModelMerger, DefaultFilterSerializer, FlatModelMerger, FilterNode, Filter, FilterValue} from 'kn-query-filter';
import {DatagridBond, QueryFilterBond, CompountBonder} from 'common-web/grid';
import {Model, Sorting} from 'kn-datagrid';

@Component({
	selector: 'kn-grid-preset',
	template: `
		<kn-preset-selector
				[queryParam]="queryParam"
				[view]="view"
				[version]="version"
				[template]="template"
				(templateChange)="onTemplateChange($event)"></kn-preset-selector>`
})
export class KnGridPreset {
	private _datagrid: DatagridBond<any>;
	private _filter: QueryFilterBond;
	private _filterMerger: AbstractModelMerger;
	private _filterSerializer: AbstractFilterSerializer<any>;
	private readonly _template: { datagrid?: any, filter?: any } = {};

	public template: { datagrid?: any, filter?: any } = {};

	@Input() public queryParam: string = 'preset';
	@Input() public view: string;
	@Input() public version: string;

	@Input() public set bond(value: DatagridBond<any> | QueryFilterBond | CompountBonder) {
		this._connectBonding(value);
	}

	public onTemplateChange($event: any) {
		if ($event != null) {
			this.template = $event;
		}
		delete this._template.datagrid;
		if ($event != null && this._datagrid && $event.hasOwnProperty('datagrid') && $event['datagrid'] != null) {
			this._template.datagrid = this._combineModel($event['datagrid']);
		}
		if ($event != null && this._filter && $event.hasOwnProperty('filter') && $event['filter'] != null) {
			const model = this._filterSerializer.deserialize($event['filter']);
			this._template.filter = this._filterMerger.merge(Utils.clone(this._filter.model, true), model, false);
		}
		else {
			delete this._template.filter;
		}
		this._datagrid && this._datagrid.onModelChange(this._datagrid.model);
		this._filter && this._filter.onModelChange(this._filter.model);
	}

	private _connectBonding(value: DatagridBond<any> | QueryFilterBond | CompountBonder) {
		if (value instanceof DatagridBond) {
			this._datagrid = value;
		}
		else if (value.hasOwnProperty('datagrid')) {
			this._datagrid = (value as any)['datagrid'];
		}
		else {
			this._datagrid = null;
		}

		if (value instanceof QueryFilterBond) {
			this._filter = value;
		}
		else if (value.hasOwnProperty('filter')) {
			this._filter = (value as any)['filter'];
		}
		else {
			this._filter = null;
		}

		if (this._datagrid) {
			this._datagrid.modelPool.unshift({
				getter: () => this._template.datagrid,
				setter: (x, active) => {
					this.template = Object.assign({}, this.template, { datagrid: x });
					if (active) {
						this._template.datagrid = this._template.datagrid == null ? Utils.clone(x, true) : x;
					}
				}
			});
		}

		if (this._filter) {
			this._filterMerger = new FlatModelMerger(this._filter.description);
			this._filterSerializer = new DefaultFilterSerializer(this._filter.description);
			this._filter.modelPool.unshift({
				getter: () => this._template.filter,
				setter: (x, active) => {
					const model = Utils.clone(x, true);
					this._filterHiddenDisabledAndFixed(model);
					const filterValue = this._filterSerializer.serialize(model);
					this.template = Object.assign({}, this.template, { filter: filterValue });
					if (active) {
						this._template.filter = this._template.filter == null ? Utils.clone(x, true) : x;
					}
				}
			});
		}
	}

	private _filterHiddenDisabledAndFixed(node: FilterNode<FilterValue>) {
		for (let i = node.children.length - 1; i >= 0; i--) {
			const child = node.children[i];
			if (child.hasOwnProperty('children')) {
				const group = child as FilterNode<FilterValue>;
				this._filterHiddenDisabledAndFixed(group);
				if (group.children == null || group.children.length === 0) {
					node.children.splice(i, 1);
				}
			}
			else if ((child as Filter<FilterValue>).hidden
					|| (child as Filter<FilterValue>).disabled
					|| (child as Filter<FilterValue>).fixed) {
				node.children.splice(i, 1);
			}
		}
	}

	private _combineModel(model: any): Model {
		const clonedModel = Utils.clone(this._datagrid.model, true);
		if (!model.hasOwnProperty('columns')) {
			return clonedModel;
		}
		clonedModel.columns.sort((a, b) => {
			const ai = model.columns.find((x: any) => x.id === a.id);
			if (ai == null) {
				return 1;
			}
			const bi = model.columns.find((x: any) => x.id === b.id);
			if (bi == null) {
				return -1;
			}
			return model.columns.indexOf(ai) - model.columns.indexOf(bi);
		});
		clonedModel.columns.forEach(column => {
			const c = model.columns.find((x: any) => x.id === column.id);
			if (c == null) {
				column.group = false;
				column.sort = Sorting.None;
				column.visible = false;
			}
			else {
				column.group = c.group;
				column.sort = c.sort;
				column.visible = c.visible;
				column.width = c.width;
			}
		});
		return clonedModel;
	}
}
