import {Injectable} from '@angular/core';
import {Semver, VersionComparison} from 'kn-shared';
import {AbstractMigrationStrategy} from './abstract-migration-strategy';
import {ViewTemplatePayload} from '../types';

@Injectable()
export class EraseIncompatibleMigrationStrategy<T> extends AbstractMigrationStrategy<T> {
	public support(view: string, targetVersion: string): boolean {
		return true;
	}

	public migrate(view: string, targetVersion: string, payload: ViewTemplatePayload<T>): ViewTemplatePayload<T> {
		if (Semver.compare(payload.version, targetVersion) !== VersionComparison.Compatible) {
			return null;
		}
		return payload;
	}
}
