import {Injectable} from '@angular/core';
import {AbstractRenewStrategy} from './abstract-renew-strategy';
import {decodeToken, getTokenIssueDate, getTokenExpirationDate} from '../jwt-utils';
import {AuthError} from '../auth.error';

@Injectable()
export class DefaultRenewStrategy extends AbstractRenewStrategy {
	public defaultExpiration = 5 * 60 * 1000;
	public renewTreshold = 60 * 1000;
	public renewFactor = 2 / 3;

	public monitor(token: string, renewRequest: Function) {
		const decoded = decodeToken(token);
		let issuedDate = getTokenIssueDate(decoded);
		let expirationDate = getTokenExpirationDate(decoded);

		const now = Date.now();
		if (issuedDate == null) {
			issuedDate = new Date(now);
		}
		if (expirationDate == null) {
			expirationDate = new Date(now + this.defaultExpiration);
		}
		const expiration = expirationDate.getTime();
		const issue = issuedDate.getTime();

		if (expiration <= 0) {
			throw new AuthError('Token already expired.');
		}

		const treshold = Math.min(
			(expiration - issue) * this.renewFactor,
			expiration - issue - this.renewTreshold);
		let remaining = treshold - (now - issue);
		remaining = remaining > 0 ? remaining : 0;

		let timeoutId: any;
		const teardown = () => {
			timeoutId && clearTimeout(timeoutId);
			timeoutId = 0;
		};
		const requester = () => {
			teardown();
			renewRequest();
		};
		timeoutId = setTimeout(requester, remaining);

		return { teardown };
	}
}
