import {ElementRef} from '@angular/core';
import {timer} from 'rxjs';
import * as Rx from 'rxjs/operators';

export class ScrollToView {
	private static readonly _margin: number = 20;
	private static _offset(el: HTMLElement) {
		const offSet: {left: number, top: number} = el.offsetParent && this._offset(el.offsetParent as HTMLElement) || {left: 0, top: 0};
		offSet.left += el.offsetLeft;
		offSet.top += el.offsetTop;
		return offSet;
	}

	public static animateScrollTo(target: ElementRef) {
		let scrollAnimateAvailable = true;
		timer(0, 20)
			.pipe(Rx.takeWhile(() => scrollAnimateAvailable))
			.subscribe(e => {
				const tmp = this._offset(target.nativeElement);
				const pos = {
					left: Math.max(0, tmp.left - this._margin),
					top: Math.max(0, tmp.top - this._margin),
					right: tmp.left + target.nativeElement.offsetWidth + this._margin,
					bottom: tmp.top + target.nativeElement.offsetHeight + this._margin
				};
				const page = {
					left: window.pageXOffset,
					top: window.pageYOffset,
					right: window.pageXOffset + window.innerWidth,
					bottom: window.pageYOffset + window.innerHeight,
					maxScrollOffsetX: Math.max(0, window.document.documentElement.scrollWidth - window.innerWidth),
					maxScrollOffsetY: Math.max(0, window.document.documentElement.scrollHeight - window.innerHeight)
				};
				if (pos.left >= page.left
					&& pos.right <= page.right
					&& pos.top >= page.top
					&& pos.bottom <= page.bottom) {
						scrollAnimateAvailable = false;
						return;
					}

				const dstX = Math.min(page.maxScrollOffsetX, Math.max(0, pos.right - window.innerWidth));
				const dstY = Math.min(page.maxScrollOffsetY, Math.max(0, pos.bottom - window.innerHeight));
				let nextX = window.pageXOffset;
				let nextY = window.pageYOffset;
				if (nextY > dstY) {
					nextY = Math.max(dstY, nextY - e);
				}
				else if (nextY < dstY) {
					nextY = Math.min(dstY, nextY + e);
				}
				if (nextX > dstX) {
					nextX = Math.max(dstX, nextX - e);
				}
				else if (nextX < dstX) {
					nextX = Math.min(dstX, nextX + e);
				}
				if (e === 0 || (nextX !== window.pageXOffset && e < dstX) || (nextY !== window.pageYOffset && e < dstY)) {
					window.scrollTo(nextX, nextY);
				}
				else {
					scrollAnimateAvailable = false;
				}
			}
		);
	}
}
