import {NgModule, ErrorHandler, LOCALE_ID} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {CachedHttp, CacheProvider, KnTemporaryStorageModule} from 'kn-cache';
import {KnCommonModule} from 'kn-common';
import {KnDatagridModule} from 'kn-datagrid';
import {KnDirectivesModule} from 'kn-directives';
import {KnFormsModule} from 'kn-forms';
import {KnGravatarModule} from 'kn-gravatar';
import {KnHttpModule, Http} from 'kn-http';
import {KnInteractionsModule} from 'kn-interactions';
import {KnJwtAuthModule, JwtAuthOptions, JwtAuthHttp} from 'kn-jwt-auth';
import {KnModalModule, ComponentDeactivateGuard} from 'kn-modal';
import {KnOverlayModule, KnPortalModule} from 'kn-overlay';
import {KnPrintModule, PRINT_HTTP_TOKEN, PRINT_STATEMENT_TOKEN} from 'kn-print';
import {KnQueryFilterModule} from 'kn-query-filter';
import {KnRestModule, RestOptions, UriContextService} from 'kn-rest';
import {KnI18nModule} from 'kn-shared';
import {KnStorageModule, MemoryStorageService, SessionStorageService} from 'kn-storage';
import {KnUserModule} from 'kn-user';
import {KnUserSettingsModule, StorageStoreBackend, RemoteStoreBackend, USER_SETTINGS_BACKENDS_TOKEN, USER_SETTINGS_DISCRIMINATE_PROVIDER_TOKEN, USER_SETTINGS_DISCRIMINATE_STRATEGIES_TOKEN} from 'kn-user-settings';
import {KnChartistModule} from 'kn-chartist';

import {
	KnRestAutorizationModule,
	KnDatabasesModule,
	KnDatabaseSelectorModule,
	KnRolesModule,
	KnUsersModule,
	KnViewTemplatesModule,
	KnViewTemplateManagerModule,
	KnRouteTitlePresenterModule
} from 'common-web/app';
import {KnCountriesModule} from 'common-web/countries';
import {KnGridExporterModule} from 'common-web/export';
import {KnGridFoundationModule, KnGridPanelModule, KnQueryFilterExpanderModule} from 'common-web/grid';
import {KnKcuniModule} from 'common-web/kcuni';
import {KnPrintTemplatesModule, PRINT_BASE_CONFIGURATION_TOKEN, COMMON_PRINT_STATEMENTS_PROVIDERS} from 'common-web/print';
import {KnRestChangesModule} from 'common-web/rest';

import {AppErrorHandler} from './app-error-handler';
import {AppRouteReuseStrategy} from './app-route-reuse-strategy';
import {AppComponent} from './app.component';
import {AppRestResourcesModule} from './rest-foundation/app-rest-resources.module';
import {createRestConfiguration} from './rest-foundation/rest.config';
import {SharedModule} from './shared.module';
import {RouteNotFoundComponent} from './views/route-not-found.component';
import {SigninComponent} from './views/signin/signin.component';

import {AppConfig, appConfig} from './app.config';
import {AppMeta, appMeta} from './app.meta';
import {authorization} from './app.permissions';
import {routing} from './app.routes';

// must be factory because window is undefined on AOT compilation
export function provideAppConfig() {
	return appConfig;
}

export function provideJwtAuthOptions(config: AppConfig) {
	return {
		auth: {
			signinUrl: config.apiUrl + '/signin',
			signupUrl: config.apiUrl + '/signup'
		},
		guard: {
			deniedActions: { navigateTo: config.signinPath 	}
		}
	};
}

export function provideCachedHttp(http: Http) {
	return new CachedHttp(
		http,
		new CacheProvider(
			new MemoryStorageService(),
			{
				maxAgeTimeSeconds: 15 * 60,
				maxUnusedTimeSeconds: 7 * 60,
				maxCacheSize: 256,
				keyPrefix: 'http'
			}
	));
}

export function provideRestConfiguration(http: Http, cachedHttp: CachedHttp, config: AppConfig): RestOptions {
	return createRestConfiguration(http, cachedHttp, config);
}

export function providePrintBaseConfiguration(uriContext: UriContextService) {
	return () => {
		const uri = uriContext.context['api'] + '/' + uriContext.context['customer'];
		return { baseUri: uri, iterate: { baseUri: uri } };
	};
}

export function provideUserSettingsBackends(http: Http, config: AppConfig) {
	return [
		new StorageStoreBackend(new SessionStorageService()),
		new RemoteStoreBackend(http, config.apiUrl + '/user-settings')
	];
}

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		KnHttpModule,
		KnI18nModule.forRoot(),
		KnDirectivesModule,
		KnDatagridModule.forRoot(),
		KnFormsModule.forRoot(),
		KnStorageModule.forRoot(),
		KnJwtAuthModule.forRoot(),
		KnUserModule.forRoot(),
		KnRouteTitlePresenterModule.forRoot(),
		KnRestAutorizationModule.forRoot({
			databaseUriKey: 'customer'
		}),
		KnCommonModule.forRoot(),
		KnGravatarModule.forRoot({
			params: { size: 48, default: '404' }
		}),
		KnInteractionsModule.forRoot(),
		KnOverlayModule.forRoot(),
		KnPortalModule.forRoot(),
		KnModalModule.forRoot(),
		KnPrintModule.forRoot(),
		KnQueryFilterModule,
		KnUserSettingsModule.forRoot(),
		SharedModule,
		KnKcuniModule.forRoot(),
		KnRestModule.forRoot(),
		KnRestChangesModule.forRoot(),
		KnDatabasesModule.forRoot({
			table: 'Databases',
			uriKey: 'customer'
		}),
		KnRolesModule.forRoot(),
		KnUsersModule.forRoot(),
		KnPrintTemplatesModule.forRoot(),
		KnViewTemplatesModule.forRoot(),
		KnViewTemplateManagerModule.forRoot(),
		AppRestResourcesModule.forRoot(),
		KnCountriesModule.forRoot(),
		KnGridExporterModule.forRoot(),
		KnGridPanelModule.forRoot(),
		KnGridFoundationModule,
		KnQueryFilterExpanderModule.forRoot(),
		KnDatabaseSelectorModule,
		KnTemporaryStorageModule.forRoot(),
		KnChartistModule.forRoot(),
		routing,
		authorization
	],
	declarations: [
		AppComponent,
		RouteNotFoundComponent,
		SigninComponent
	],
	bootstrap: [AppComponent],
	providers: [
		{ provide: ErrorHandler, useClass: AppErrorHandler },
		{ provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
		{
			provide: AppConfig,
			useFactory: provideAppConfig
		},
		{ provide: AppMeta, useValue: appMeta },
		{
			provide: JwtAuthOptions,
			useFactory: provideJwtAuthOptions,
			deps: [AppConfig]
		}, {
			provide: CachedHttp,
			useFactory: provideCachedHttp,
			deps: [JwtAuthHttp]
		},
		{ provide: PRINT_HTTP_TOKEN, useExisting: JwtAuthHttp },
		COMMON_PRINT_STATEMENTS_PROVIDERS,
		{
			provide: RestOptions,
			useFactory: provideRestConfiguration,
			deps: [JwtAuthHttp, CachedHttp, AppConfig]
		}, {
			provide: PRINT_BASE_CONFIGURATION_TOKEN,
			useFactory: providePrintBaseConfiguration,
			deps: [UriContextService]
		}, {
			provide: USER_SETTINGS_BACKENDS_TOKEN,
			useFactory: provideUserSettingsBackends,
			deps: [JwtAuthHttp, AppConfig]
		},
		ComponentDeactivateGuard
	]
})
export class AppModule { }
