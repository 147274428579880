import {Component, ViewEncapsulation, Input, HostBinding, ViewChild, ContentChild, TemplateRef} from '@angular/core';

@Component({
	selector: 'kn-tab',
	template: `
		<ng-template [ngIf]="active"><ng-content></ng-content></ng-template>
		<ng-template #defaultTemplate>{{ label }}</ng-template>`,
	styles: [`
		kn-tab {
			display: none;
		}

		kn-tab.active {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
	`],
	encapsulation: ViewEncapsulation.None
})
export class KnTab {
	@Input() public label: string;
	@Input('class') public classes: string;

	public get labelTemplate() {
		return this.template || this.defaultTemplate;
	}

	@HostBinding('class.active')
	public active = false;

	@ContentChild('label', { static: false })
	public template: TemplateRef<void>;

	@ViewChild('defaultTemplate', { static: true })
	public defaultTemplate: TemplateRef<void>;
}
