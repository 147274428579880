import {PdfkitCanvasRenderingContext2dAdaptor} from './pdfkit-canvas-rendering-context2d-adaptor';
import {AbstractFontResolver} from './resolvers/abstract-font-resolver';
import {DefaultFontResolver} from './resolvers/default-font-resolver';
import {AbstractImageResolver} from './resolvers/abstract-image-resolver';
import {DefaultImageResolver} from './resolvers/default-image-resolver';
import {px2pt, pt2px} from '../utils/conversions';

export class PdfkitCanvasAdaptor {
	public fontResolver: AbstractFontResolver = new DefaultFontResolver();
	public imageResolver: AbstractImageResolver = new DefaultImageResolver();

	public constructor(private readonly _doc: PDFKit.PDFDocument) { }

	/**
	 * Gets the height of a canvas element on a document.
	 */
	public get height(): number {
		return pt2px(this._doc.options.size[1] as number);
	}

	/**
	 * Sets the height of a canvas element on a document.
	 */
	public set height(value: number) {
		(this._doc.options.size as number[])[1] = px2pt(value);
	}

	/**
	 * Gets the width of a canvas element on a document.
	 */
	public get width(): number {
		return pt2px(this._doc.options.size[0] as number);
	}

	/**
	 * Sets the width of a canvas element on a document.
	 */
	public set width(value: number) {
		(this._doc.options.size as number[])[0] = px2pt(value);
	}

	/**
	 * Returns an object that provides methods and properties for drawing and manipulating images and graphics on a canvas element in a document. A context object includes information about colors, line widths, fonts, and other graphic parameters that can be drawn on a canvas.
	 * @param contextId The identifier (ID) of the type of canvas to create.
	 */
	public getContext(contextId: string, ...args: any[]): CanvasRenderingContext2D | WebGLRenderingContext {
		if (contextId !== '2d') {
			throw new Error('Not supported.');
		}

		const context = new PdfkitCanvasRenderingContext2dAdaptor(
			this._doc, this.fontResolver, this.imageResolver);
		return context as any as CanvasRenderingContext2D;
	}

	/**
	 * Returns the content of the current canvas as an image that you can use as a source for another canvas or an HTML element.
	 * @param type The standard MIME type for the image format to return. If you do not specify this parameter, the default value is a PNG format image.
	 */
	public toDataURL(type?: string, ...args: any[]): string {
		throw new Error('Method `toDataURL` not implemented.');
	}

	/**
	 * Returns a blob object encoded as a Portable Network Graphics (PNG) format from a canvas image or drawing.
	 */
	public toBlob(): Blob {
		throw new Error('Method `toBlob` not implemented.');
	}
}
