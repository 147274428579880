import {Subject, Observable} from 'rxjs';
import {Response, UriContext} from 'kn-http';
import {AbstractResource} from './abstract-resource';
import {RestService} from '../rest.service';
import {AbstractFetcher} from '../fetchers/abstract-fetcher';
import {RestChangeNotifierService} from '../change-notification/rest-changes-notifier.service';
import {ChangeEntry} from '../change-notification/types';
import {Indexer} from '../types';

export class Resource<T> extends AbstractResource<T> {
	private readonly _changesObservable$: Observable<ChangeEntry>;
	protected _changesSubject$: Subject<ChangeEntry>;
	protected _fetcher: AbstractFetcher<T>;

	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			private readonly _table: string) {
		super();
		this._changesSubject$ = new Subject<ChangeEntry>();
		this._changesObservable$ = this._changesSubject$.asObservable();
		this._fetcher = rest.for<T>(this._table);
		notifier.subscribe(next => this._processChanges(next));
	}

	public get changes(): Observable<ChangeEntry> {
		return this._changesObservable$;
	}

	public get table(): string {
		return this._table;
	}

	public getReferences(): { [key: string]: AbstractResource<any> } {
		return {};
	}

	public query(context?: UriContext): Observable<T[]> {
		return this._fetcher.query(context);
	}

	public get(indexer: Indexer, context?: UriContext): Observable<T> {
		return this._fetcher.get(indexer, context);
	}

	public head(indexer: Indexer, context?: UriContext): Observable<Response> {
		return this._fetcher.head(indexer, context);
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		return this._fetcher.save(item, context);
	}

	protected _remove(indexer: Indexer, context: UriContext): Observable<Response> {
		return this._fetcher.remove(indexer, context);
	}

	protected _processChanges(change: ChangeEntry) {
		if (change.table === this.table) {
			this._changesSubject$.next(change);
		}
	}
}
