import {Injector, Injectable, StaticProvider, Type, TemplateRef, getPlatform} from '@angular/core';
import {DatePipe, JsonPipe, LowerCasePipe, CurrencyPipe, DecimalPipe, PercentPipe, UpperCasePipe} from '@angular/common';
import {PipeRendererFactory} from './pipe-renderer-factory';
import {EnumRendererFactory} from './enum-renderer-factory';
import {TemplateRendererContext} from './binders/template-render-binder-ref';
import {ComponentBinder} from './binders/component-render-binder-ref';
import {TemplateRendererFactory} from './template-renderer-factory';
import {ComponentRendererFactory} from './component-renderer-factory';
import {Renderer, RowItem, CellContext} from '../types';
import {RendererFactory} from './renderer-factory';

export const RENDERERS_PROVIDERS: any = [
	PipeRendererFactory,
	EnumRendererFactory,
	TemplateRendererFactory,
	ComponentRendererFactory
];

@Injectable()
export class Renderers {
	private _factories: { [name: string]: RendererFactory } = {};

	private static get _injector() {
		const providers = [
			...RENDERERS_PROVIDERS,
			DatePipe,
			JsonPipe,
			LowerCasePipe,
			CurrencyPipe,
			DecimalPipe,
			PercentPipe,
			UpperCasePipe
		];
		return Injector.create({
			providers: providers.map(x => ({ provide: x, deps: [] } as StaticProvider)),
			parent: getPlatform().injector
		});
	}

	public static pipe = (...args: any[]) =>
		Renderers._injector.get<PipeRendererFactory>(PipeRendererFactory)
			.create(...args);
	public static enum = (translation: { [key: string]: any }, defaultValue?: any) =>
		Renderers._injector.get<EnumRendererFactory>(EnumRendererFactory)
			.create(translation, defaultValue); /* "semicolon": [true, "always"] rule bug */ // eslint-disable-line
	public static template = (templateRef: TemplateRef<TemplateRendererContext<RowItem>>) =>
		Renderers._injector.get<TemplateRendererFactory>(TemplateRendererFactory)
			.create(templateRef);
	public static component = (componentType: Type<any>, binder?: ComponentBinder<any, RowItem>, onCreation?: (instance: any, context: CellContext<RowItem>) => void) =>
		Renderers._injector.get<ComponentRendererFactory>(ComponentRendererFactory)
			.create(componentType, binder);

	public add(name: string, factory: RendererFactory): void {
		this._factories[name] = factory;
	}

	public remove(name: string): void {
		delete this._factories[name];
	}

	public clear(): void {
		this._factories = {};
	}

	public build<T>(): { [name: string]: (...args: any[]) => Renderer<T> } | any {
		const renderers: { [name: string]: (...args: any[]) => Renderer<T> } = {};
		for (const name in this._factories) {
			if (this._factories.hasOwnProperty(name)) {
				renderers[name] = (...args: any[]): Renderer<T> =>
					this._factories[name].create(...args);
			}
		}
		return renderers;
	}
}
