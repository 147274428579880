import {Injectable} from '@angular/core';
import {Utils, Sorting} from 'kn-utils';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('sort')
export class SortFilter implements PrintRendererFilter {
	public transform<T>(array: T[], sortKey?: Sorting<T>): T[] {
		const slice = array.slice(0);
		Utils.array.sort(slice, sortKey);
		return slice;
	}
}
