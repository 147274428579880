import {Inject, LOCALE_ID, Injectable} from '@angular/core';
import {DatePipe} from 'kn-common';
import {DayOfWeek} from '../types';

@Injectable()
export class L10nService {
	private readonly _formater: { (value: number): string };
	private static _weekdayNames: string[];

	public constructor(@Inject(LOCALE_ID) locale: string) {
		const datePipe = new DatePipe(locale);
		this._formater = value => datePipe.transform(value, 'EEE');
	}

	public getWeekdayName(dayOfWeek: DayOfWeek) {
		return L10nService._getOrCreateWeekdayNames(this._formater)[dayOfWeek];
	}

	private static _getOrCreateWeekdayNames(formater: { (value: number): string }): string[] {
		if (L10nService._weekdayNames == null) {
			L10nService._weekdayNames = L10nService._createWeekdayNames(formater);
		}
		return L10nService._weekdayNames;
	}

	private static _createWeekdayNames(formater: { (value: number): string }): string[] {
		const offset = 4 * 24 * 3600 * 1000; // offset in miliseconds to first monday after UNIX epoch
		const date = new Date(0);
		const names: string[] = [];
		for (let i = 1; i <= 7; i++) {
			names.push(formater(offset + date.setDate(i)));
		}
		return names;
	}
}
