import {Injectable} from '@angular/core';
import {Semver, VersionComparison} from 'kn-shared';
import {AbstractMigrationStrategy} from './abstract-migration-strategy';
import {SettingsItem} from '../types';

@Injectable()
export class EraseIncompatibleMigrationStrategy extends AbstractMigrationStrategy {
	public support(key: string, targetVersion: string): boolean {
		return true;
	}

	public migrate(key: string, targetVersion: string, item: SettingsItem): SettingsItem {
		if (item == null || Semver.compare(item.version, targetVersion) !== VersionComparison.Compatible) {
			return null;
		}
		return item;
	}
}
