import {Directive, Self} from '@angular/core';
import {FormGroupName, FormGroup, AbstractControl} from '@angular/forms';
import {AbstractFormControl} from './abstract-form-control';

@Directive({
	selector: '[formGroupName]',
	exportAs: 'knFormGroup'
})
export class KnFormGroup extends AbstractFormControl<FormGroup> {
	public constructor(@Self() private readonly _formGroupName: FormGroupName) {
		super();
	}

	public get control(): FormGroup {
		return this._formGroupName.control;
	}

	public get controls(): { [key: string]: AbstractControl } {
		return this.control.controls;
	}

	public contains(controlName: string): boolean {
		return this.control.contains(controlName);
	}
}
