export class CoreUtils {
	protected static _int32max = Math.pow(2, 31) - 1;
	protected static _int32min = -1 * Math.pow(2, 31);

	public static isBoolean(value: any): boolean {
		return value === true || value === false || typeof value === 'boolean';
	}

	public static isDate(value: any): boolean {
		return !!value && typeof value === 'object'
			&& Object.prototype.toString.call(value) === '[object Date]';
	}

	public static isNumber(value: any): boolean {
		return typeof value === 'number';
	}

	public static isInt32(value: number): boolean {
		return value && Math.round(value) === value
			&& value <= this._int32max  && value >= this._int32min;
	}

	public static isString(value: any): boolean {
		return typeof value === 'string' || value instanceof String;
	}

	public static isFunction(value: any): boolean {
		return typeof value === 'function';
	}

	public static isObject(value: any): boolean {
		return !!value && (typeof value === 'object' || typeof value === 'function');
	}

	public static isPromise(value: any): boolean {
		return !!value
				&& (typeof value === 'object' || typeof value === 'function')
				&& typeof value.then === 'function';
	}

	public static isObservable(value: any): boolean {
		return value && !!value.subscribe;
	}

	public static escapeRegExp(value: string): string {
		return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
	}

	public static equal(actual: any, expected: any): boolean {
		if (actual === expected) {
			return true;
		}

		if (actual instanceof Date && expected instanceof Date) {
			return actual.getTime() === expected.getTime();
		}

		if (!actual || !expected || typeof actual !== 'object' && typeof expected !== 'object') {
			// eslint-disable-next-line eqeqeq
			return actual == expected;
		}

		let actualKeys = Object.keys(actual);
		let expectedKeys = Object.keys(expected);
		if (actualKeys.length !== expectedKeys.length) {
			return false;
		}

		actualKeys = actualKeys.sort();
		expectedKeys = expectedKeys.sort();

		for (let i = 0; i < actualKeys.length; i++) {
			if (actualKeys[i] !== expectedKeys[i]) {
				return false;
			}
			if (!CoreUtils.equal(actual[actualKeys[i]], expected[expectedKeys[i]])) {
				return false;
			}
		}

		return true;
	}

	public static looseIdentical(a: any, b: any): boolean {
		return a === b || typeof a === 'number' && typeof b === 'number' && isNaN(a) && isNaN(b);
	}

	// eslint-disable-next-line complexity
	public static clone<T>(value: T, isDeep: boolean = false): T {
		if (!CoreUtils.isObject(value)) {
			return value;
		}

		if (Array.isArray(value)) {
			const array = value as any[];
			return isDeep ? array.map(x => CoreUtils.clone(x, true)) as any : array.slice(0);
		}

		if (value.constructor && value.constructor.name === 'Date') {
			return new Date(+value) as any;
		}

		let buffer: ArrayBuffer;
		switch (value.toString()) {
			case '[object Object]':
			case '[object Arguments]':
				const obj = value as { [key: string]: any };
				const dstObj = Object.create(Object.getPrototypeOf(obj) || {});
				if (!isDeep) {
					return Object.assign(dstObj, obj);
				}
				for (const key in obj) {
					if (obj.hasOwnProperty(key)) {
						dstObj[key] = isDeep ? CoreUtils.clone(obj[key], true) : obj[key];
					}
				}
				return dstObj;

			case '[object ArrayBuffer]':
				const arrayBuffer = value as any as ArrayBuffer;
				buffer = new ArrayBuffer(arrayBuffer.byteLength);
				new Uint8Array(buffer).set(new Uint8Array(arrayBuffer));
				return buffer as any;

			case '[object Boolean]':
				// eslint-disable-next-line no-new-wrappers
				return new Boolean(+value) as any;

			case '[object DataView]':
				const dataView = value as any as DataView;
				buffer = dataView.buffer;
				if (isDeep) {
					buffer = new ArrayBuffer(buffer.byteLength);
					new Uint8Array(buffer).set(new Uint8Array(buffer));
				}
				return new DataView(buffer, dataView.byteOffset, dataView.byteLength) as any;

			case '[object Float32Array]':
			case '[object Float64Array]':
			case '[object Int8Array]':
			case '[object Int16Array]':
			case '[object Int32Array]':
			case '[object Uint8Array]':
			case '[object Uint8ClampedArray]':
			case '[object Uint16Array]':
			case '[object Uint32Array]':
				const typedArray = value as any;
				buffer = typedArray.buffer;
				if (isDeep) {
					buffer = new ArrayBuffer(buffer.byteLength);
					new Uint8Array(buffer).set(new Uint8Array(buffer));
				}
				return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);

			case '[object Map]':
				const map = value as any as Map<any, any>;
				const dstMap = new Map();
				map.forEach((x, key) => dstMap.set(key, isDeep ? CoreUtils.clone(x, true) : x));
				return dstMap as any;

			case '[object Number]':
				// eslint-disable-next-line no-new-wrappers
				return new Number(value) as any;

			case '[object String]':
				// eslint-disable-next-line no-new-wrappers
				return new String(value) as any;

			case '[object RegExp]':
				const flagsMap = {
					global: 'g',
					ignoreCase: 'i',
					multiline: 'm',
					sticky: 'y',
					unicode: 'u'
				} as { [key: string]: string };
				const regexp = value as any as RegExp;
				const flags = regexp.flags || Object.keys(flagsMap)
						.reduce((acc, x) => (regexp as any)[x] ? acc + flagsMap[x] : acc, '');
				const dstRegexp = new RegExp(regexp.source, flags);
				dstRegexp.lastIndex = regexp.lastIndex;
				return dstRegexp as any;

			case '[object Set]':
				const set = value as any as Set<any>;
				const dstSet = new Set();
				set.forEach(x => dstSet.add(isDeep ? CoreUtils.clone(x, true) : x));
				return dstSet as any;

			case '[object Symbol]':
				return Object(value.valueOf());
		}

		return value;
	}
}
