import {Injectable} from '@angular/core';
import {Node} from '../model/node';
import {RowItem} from '../types';

@Injectable()
export class RowsNumbering {
	public key: string = 'rowNumber';

	public getForNode(node: Node<RowItem>): number {
		return node.cache.get(this.key);
	}

	public calculate(node: Node<RowItem>): RowsNumbering {
		if (!node.cache.has(this.key)) {
			this._calculate(node);
		}
		return this;
	}

	private _calculate(node: Node<RowItem>) {
		this._calculateSubtree(node.getRoot(), 1);
	}

	private _calculateSubtree(node: Node<RowItem>, offset: number) {
		node.cache.set(this.key, offset);
		if (node.children.length === 0) {
			offset++;
		}
		else {
			for (const child of node.children) {
				offset = this._calculateSubtree(child, offset);
			}
		}
		return offset;
	}
}
