<kn-day-view tabindex="-1"
	*knCollapse="viewIndex === 0"
	[hoursInPeriod]="hoursInPeriod"
	[hour]="time?.hour"
	(hourChange)="setHour($event)"
	(accept)="acceptHour($event)"></kn-day-view>
<kn-hour-view tabindex="-1"
	*knCollapse="viewIndex === 1"
	[minute]="time?.minute"
	(minuteChange)="setMinute($event)"
	(accept)="acceptMinute($event)"></kn-hour-view>
