import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http, Response, UriContext} from 'kn-http';
import {Fetcher} from 'kn-rest';
import {EntityBase} from 'common-web/model';

export class NewByIdFetcher<T extends EntityBase> extends Fetcher<T> {
	public constructor(
			http: Http,
			uriTemplate: string,
			table: string,
			identifierProperties: string | string[] = 'id') {
		super(http, uriTemplate, table, identifierProperties);
	}

	protected _postOrPut(item: T, context: UriContext): Observable<Response> {
		const postOrPut = item.id == null ? this._post : this._put;
		return postOrPut.bind(this)(item, context);
	}

	protected _post(item: T, context: UriContext): Observable<Response> {
		return super._post(item, context).pipe(
			Rx.switchMap(response => {
				const indexer = this._indexerResolver.getIndexer(item);
				return this.get(indexer, Object.assign({}, context, { query: { select: 'id' } }))
					.pipe(Rx.tap(next => item.id = next as any), Rx.map(() => response));
			})
		);
	}
}
