import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('ceil')
export class CeilFilter implements PrintRendererFilter {
	public transform(value: number, decimals: number = 0): number {
		if (value == null) {
			return null;
		}
		const factor = Math.pow(10, decimals);
		return Math.ceil(value * factor) / factor;
	}
}
