<label [attr.for]="inputId" #knLabelContent>
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required && hasKnLabel">*</span>
</label>

<div class="kn-textarea-container" knRipple [knRippleDisabled]="disabled">
	<div class="kn-textarea-prefix">
		<ng-content select="[kn-prefix]"></ng-content>
	</div>

	<textarea #input
			size="1"
			[id]="inputId"
			[attr.placeholder]="placeholder"
			[autofocus]="autofocus"
			[disabled]="disabled"
			[readonly]="readonly"
			[required]="required"
			[spellcheck]="spellcheck"
			[attr.maxlength]="maxlength"
			[attr.tabindex]="tabindex"
			[attr.name]="name"
			[attr.wrap]="wrap"
			[attr.rows]="rows"
			[attr.cols]="cols"
			[(ngModel)]="value"
			(change)="handleChange($event)"
			(keyup)="handleKeyup($event)"
			(keydown)="handleKeydown($event)"></textarea>

	<div class="kn-textarea-suffix">
		<ng-content select="[kn-suffix]"></ng-content>
	</div>
</div>

<div class="kn-textarea-underline"></div>

<ng-content></ng-content>

<ng-content select="[kn-hint]"></ng-content>
