import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'flatten' })
export class FlattenPipe implements PipeTransform {
	public transform(value: any[], shallow?: boolean): any {
		if (value == null) {
			return null;
		}

		return Utils.array.flatten(value, shallow);
	}
}
