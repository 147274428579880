import {Observable} from 'rxjs';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {ChangeEntry} from '../change-notification/types';
import {Indexer} from '../types';

export abstract class AbstractResource<T> {
	public abstract get changes(): Observable<ChangeEntry>;
	public abstract get table(): string;
	public abstract getReferences(): { [key: string]: AbstractResource<any> };

	public abstract query(context?: UriContext): Observable<T[]>;
	public abstract get(indexer: Indexer, context?: UriContext): Observable<T>;
	public abstract head(indexer: Indexer, context?: UriContext): Observable<Response>;
	public abstract save(item: T, context?: UriContext): Observable<Response>;

	public remove(context: UriContext): Observable<Response>;
	public remove(indexer: Indexer, context?: UriContext): Observable<Response>;
	public remove(indexerOrContext: Indexer | UriContext, context?: UriContext): Observable<Response> {
		let indexer = null as string;
		if (Utils.isObject(indexerOrContext)) {
			context = Object.assign({}, indexerOrContext as UriContext, context);
		}
		else {
			indexer = indexerOrContext as string;
		}
		return this._remove(indexer, context);
	}

	protected abstract _remove(indexer: Indexer, context: UriContext): Observable<Response>;
}
