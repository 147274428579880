import {Injectable, Inject, SkipSelf} from '@angular/core';
import {UserService} from './user.service';
import {SCOPE} from './scope.token';
import {PERMISSIONS} from './permissions.token';
import {Permissions} from './configuration.types';
import {RootUserService} from './root-user.service';
import {PermissionConfiguratorService} from './authorization/permission-configurator.service';

@Injectable()
export class ScopedUserService extends UserService {
	public constructor(
			configurator: PermissionConfiguratorService,
			@Inject(PERMISSIONS) permissions: Permissions,
			@SkipSelf() parent: UserService,
			@Inject(SCOPE) scope: any /* string | string[] (surprisingly AOT, again) */) {
		super(parent.authentication, parent.authorization.fork(scope), parent);
		(this.root() as RootUserService).mount(this.scope, permissions);
		configurator.reconfigure();
	}
}
