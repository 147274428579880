import {Directive, Renderer2, ElementRef, Input, NgZone, OnDestroy, OnInit, OnChanges, SimpleChanges, HostBinding, Optional} from '@angular/core';
import {BooleanField} from 'kn-common';
import {RippleRenderer} from './ripple-renderer';
import {Ripple} from './ripple';
import {RippleRef} from './ripple-ref';
import {RippleState, RippleAnimationConfig, RippleConfig, RippleGlobalConfig} from './ripple.types';
import {Utils} from 'kn-utils';

@Directive({
	selector: '[knRipple]',
	exportAs: 'knRipple',
	host: { class: 'kn-ripple' }
})
export class KnRipple implements OnInit, OnChanges, OnDestroy {
	private readonly _rippleRenderer: RippleRenderer;
	private readonly _config: RippleGlobalConfig;
	private readonly _activeRipples = new Set<Ripple>();
	private _handlersTeardown: Function;
	private _isPointerDown: boolean = false;

	@HostBinding('class.kn-ripple-unbounded')
	@Input('knRippleUnbounded') @BooleanField() public unbounded: boolean;

	@Input('knRippleColor') public color: string;
	@Input('knRippleCentered') @BooleanField() public centered: boolean;
	@Input('knRippleRadius') public radius: number = 0;
	@Input('knRippleAnimation') public animation: Partial<RippleAnimationConfig>;
	@Input('knRippleDisabled') public disabled: boolean;
	@Input('knRippleTrigger') public trigger: ElementRef | HTMLElement;

	public constructor(
			private readonly _zone: NgZone,
			renderer: Renderer2,
			private readonly _elementRef: ElementRef,
			@Optional() config: RippleGlobalConfig) {
		this._rippleRenderer = new RippleRenderer(renderer, this._elementRef);
		this._config = Utils.object.defaults(config || {}, {
			disabled: false,
			animation: {
				enterDuration: 450,
				exitDuration: 400
			}
		});
	}

	public ngOnInit() {
		this._registerTrigger();
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('trigger' in changes) {
			this._registerTrigger();
		}
	}

	public ngOnDestroy() {
		this._handlersTeardown && this._handlersTeardown();
		this._handlersTeardown = null;
	}

	public fadeOutAll() {
		this._activeRipples.forEach(ripple => ripple.fadeOut());
	}

	public launch(config?: RippleConfig): RippleRef;
	public launch(x: number, y: number, config?: RippleConfig): RippleRef;
	public launch(configOrX: number | RippleConfig, y: number = 0, config?: RippleConfig): RippleRef {
		let x = 0;
		if (typeof configOrX === 'number') {
			x = configOrX;
		}
		else {
			config = configOrX;
		}
		config = Utils.object.defaults(
			config || {}, {
				color: this.color,
				centered: this.centered,
				radius: this.radius,
				persistent: false,
				animation: this.animation
			}, {
				animation: this._config.animation
			}
		);
		const ripple = new Ripple(this._zone, this._rippleRenderer, config);
		ripple.fadeIn(x, y);
		ripple.registerOnVisible(() => {
			this._activeRipples.add(ripple);
			if (!ripple.config.persistent && !this._isPointerDown) {
				ripple.fadeOut();
			}
		});
		ripple.registerOnHidden(() => this._activeRipples.delete(ripple));
		return ripple;
	}

	private _registerTrigger() {
		let trigger = this._elementRef.nativeElement;
		if (this.trigger) {
			trigger = (this.trigger as ElementRef).nativeElement || this.trigger;
		}
		const pointerDownHandler = this._handlePointerDown.bind(this);
		const pointerUpHandler = this._handlePointerUp.bind(this);
		this._handlersTeardown && this._handlersTeardown();
		this._zone.runOutsideAngular(() => {
			trigger.addEventListener('pointerdown', pointerDownHandler, { passive: true });
			trigger.addEventListener('pointerup', pointerUpHandler, { passive: true });
			trigger.addEventListener('pointerleave', pointerUpHandler, { passive: true });
			this._handlersTeardown = () => {
				trigger.removeEventListener('pointerdown', pointerDownHandler);
				trigger.removeEventListener('pointerup', pointerUpHandler);
				trigger.removeEventListener('pointerleave', pointerUpHandler);
			};
		});
	}

	private _handlePointerDown(event: PointerEvent) {
		if (this._config.disabled || this.disabled) {
			return;
		}
		this._isPointerDown = true;
		this.launch(event.clientX, event.clientY);
	}

	private _handlePointerUp(event: PointerEvent) {
		if (!this._isPointerDown) {
			return;
		}
		this._isPointerDown = false;
		this._activeRipples.forEach(ripple => {
			if (!ripple.config.persistent && (ripple.state === RippleState.VISIBLE)) {
				ripple.fadeOut();
			}
		});
	}
}
