import {Injectable} from '@angular/core';
import {Observable, Subscription, of as observableOf} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {AbstractModuleLoader} from 'kn-common';
import {saveAs} from 'file-saver';
import {AbstractPrintBackend, AbstractPrintBackendOptions} from './abstract-print-backend.service';
import {PrintConfiguration} from '../types';

export interface PlaintextPrintBackendOptions extends AbstractPrintBackendOptions {
	saveAs?: string;
}

@Injectable()
export class PlaintextPrintBackend extends AbstractPrintBackend {
	private readonly _subscription: Subscription;

	public constructor(moduleLoader: AbstractModuleLoader) {
		super();
	}

	public get name(): string {
		return 'txt';
	}

	public print(text: string, config?: PrintConfiguration, options?: PlaintextPrintBackendOptions): Observable<any> {
		return observableOf(text).pipe(
			Rx.map(next => new Blob([next], { type: 'text/plain;charset=utf-8;' })),
			Rx.tap(blob => this._save(blob, options))
		);
	}

	public printPreview(text: string, config?: PrintConfiguration, options?: PlaintextPrintBackendOptions): Observable<any> {
		return this.print(text, config, options);
	}

	public destroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	private _save(data: Blob, options?: PlaintextPrintBackendOptions) {
		if (options != null && options.saveAs != null) {
			saveAs(data, options.saveAs);
		}
	}
}
