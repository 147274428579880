import {Injectable, Inject} from '@angular/core';
import {Observable} from 'rxjs';
import {ConcreteHttp} from './concrete-http';
import {HTTP_HANDLERS} from './http-handlers.token';
import {WEBSOCKET_HANDLERS} from './websocket-handlers.token';
import {Request} from './request';
import {Response} from './response';
import {HttpHandler} from './handlers/http-handler';
import {WebsocketHandler} from './handlers/websocket-handler';
import {WebsocketSubject} from './websocket-subject';
import {WebsocketConfig} from './websocket-config';

class HttpInterceptorHandler implements HttpHandler {
	public constructor(
			private readonly _next: HttpHandler,
			private readonly _handler: HttpHandler) {
	}

	public handle(request: Request): Observable<Response> {
		return this._handler.handle(request, this._next);
	}
}

class WebsocketInterceptorHandler implements WebsocketHandler {
	public constructor(
			private readonly _next: WebsocketHandler,
			private readonly _handler: WebsocketHandler) {
	}

	public handle(config: WebsocketConfig, next?: WebsocketHandler): WebsocketSubject {
		return this._handler.handle(config, this._next);
	}
}

@Injectable()
export class HttpService extends ConcreteHttp {
	public constructor(
			@Inject(HTTP_HANDLERS) private readonly _httpHandlers: HttpHandler[],
			@Inject(WEBSOCKET_HANDLERS) private readonly _websocketHandlers: WebsocketHandler[]) {
		super();
	}

	protected _executeRequest(request: Request): Observable<Response> {
		const chain = this._httpHandlers.reduceRight(
			(next, handler) => new HttpInterceptorHandler(next, handler));
		return chain.handle(request);
	}

	protected _establishWebsocket(config: WebsocketConfig): WebsocketSubject {
		const chain = this._websocketHandlers.reduceRight(
			(next, handler) => new WebsocketInterceptorHandler(next, handler));
		return chain.handle(config);
	}
}
