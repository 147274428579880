import {Directive, Input, HostListener, HostBinding, NgZone} from '@angular/core';
import {Node} from '../model/node';
import {SelectionService} from '../services/selection.service';
import {RowItem, Description} from '../types';

@Directive({
	selector: '[knRowSelect]'
})
export class KnRowSelect {
	private static _select = false;

	@Input() public node: Node<RowItem>;
	@Input() public description: Description<RowItem>;

	public constructor(
			private readonly _zone: NgZone,
			private readonly _selection: SelectionService) {
	}

	@HostListener('click', ['$event'])
	public clickHandler(event: MouseEvent) {
		if (!event.shiftKey) {
			this._selection.toggleSelect(this.node, this.description);
		}
	}

	@HostListener('mousedown', ['$event'])
	public mouseDownHandler(event: MouseEvent) {
		if (event.shiftKey) {
			KnRowSelect._select = !this._selection.isSelected(this.node);
			this._selection.setSelect(this.node, KnRowSelect._select, this.description);
			event.preventDefault();
		}
	}

	@HostListener('mouseenter.outside', ['$event'])
	public mouseEnterHandler(event: MouseEvent) {
		// eslint-disable-next-line no-bitwise
		if (event.shiftKey && (event.buttons & 1)) {
			this._zone.run(() => {
				this._selection.setSelect(this.node, KnRowSelect._select, this.description);
			});
			event.preventDefault();
		}
	}

	@HostBinding('class.selected')
	public get isSelected(): boolean {
		return this._selection.isSelected(this.node);
	}
}
