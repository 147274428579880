import {RestOptions, Fetcher} from 'kn-rest';
import {NewByIdFetcher, SyncableFetcher, EntitiesChangesFetcher, FileFetcher, BackupFetcher} from 'common-web/rest';
import {Http} from 'kn-http';
import {AppConfig} from '../app.config';
import * as CommonWebModel from 'common-web/model';
import * as WebModel from '../model/web.types';
import * as MasterModel from '../model/master-database.types';
import * as CustomerModel from '../model/customer-database.types';

export function createRestConfiguration(authHttp: Http, cachedHttp: Http, config: AppConfig): RestOptions {
	return {
		context: {
			api: config.apiUrl,
			data: config.dataUrl
		},
		fetchers: [
			new NewByIdFetcher<MasterModel.User>(authHttp, '{+api}/users{/uid}{?query*}', 'Users', 'uid'),
			new Fetcher<MasterModel.LoginHistory>(authHttp, '{+api}/login-histories{/id}{?query*}', 'LoginHistory'),
			new NewByIdFetcher<CommonWebModel.Database>(authHttp, '{+api}/databases{/uid}{?query*}', 'Databases', 'uid'),
			new Fetcher<MasterModel.ConnectorSetting>(authHttp, '{+api}/connector-settings{/id}{?query*}', 'ConnectorSettings'),
			new Fetcher<WebModel.Connection>(authHttp, '{+api}/connections{/mac}{?query*}', 'Connections'),

			new BackupFetcher(authHttp, '{+api}/{customer}/backup-restore', 'BackupRestore', 'customer'),

			new EntitiesChangesFetcher(authHttp, '{+api}/{customer}/entities-changes', 'ChangeEntries'),

			new SyncableFetcher<CustomerModel.StoristoLogMessage>(cachedHttp, '{+api}/{company}/log-messages{/id}{?query*}', 'LogMessages'),
			new Fetcher<CommonWebModel.ActionInfo>(authHttp, '{+api}/{customer}/permissions/actions{/key}{?query*}', 'ActionInfos', 'key'),
			new Fetcher<CustomerModel.Role | MasterModel.Role>(authHttp, '{+api}/{customer}/roles{/id}{?query*}', 'Roles'),
			new Fetcher<CommonWebModel.PermissionInfo>(authHttp, '{+api}/{customer}/permissions{/name}{?query*}', 'PermissionInfos', 'name'),
			new Fetcher<CustomerModel.UserRole | MasterModel.UserRole>(authHttp, '{+api}/{customer}/user-roles{/id}{?query*}', 'UserRoles'),
			new SyncableFetcher<MasterModel.UserSetting>(authHttp, '{+api}/user-settings{/id}{?query*}', 'UserSettings'),

			new SyncableFetcher<CustomerModel.Device>(cachedHttp, '{+api}/{customer}/devices{/id}{?query*}', 'Devices'),
			new SyncableFetcher<CustomerModel.ServiceRecord>(cachedHttp, '{+api}/{customer}/service-records{/id}{?query*}', 'ServiceRecords'),
			new SyncableFetcher<CustomerModel.Site>(cachedHttp, '{+api}/{customer}/sites{/id}{?query*}', 'Sites'),
			new SyncableFetcher<CustomerModel.DeviceLogRecord>(cachedHttp, '{+api}/{customer}/device-log-records{/id}{?query*}', 'DeviceLogRecords'),
			new SyncableFetcher<CustomerModel.PeopleCounterRecord>(cachedHttp, '{+api}/{customer}/people-counter-records{/id}{?query*}', 'PeopleCounterRecords'),
			new Fetcher<CustomerModel.SyncTracker>(cachedHttp, '{+api}/{customer}/sync-trackers{/id}{?query*}', 'SyncTrackers'),

			new SyncableFetcher<CustomerModel.ReportEntry>(cachedHttp, '{+api}/{customer}/reports{/id}{?query*}', 'Reports'),
			new SyncableFetcher<CustomerModel.ReportSpecification>(cachedHttp, '{+api}/{customer}/report-specifications{/id}{?query*}', 'ReportSpecifications'),
			new SyncableFetcher<CustomerModel.ReportRecord>(cachedHttp, '{+api}/{customer}/report-records{/id}{?query*}', 'ReportRecords'),

			new SyncableFetcher<CommonWebModel.PrintTemplate>(authHttp, '{+api}/{customer}/print-templates{/id}{?query*}', 'PrintTemplates'),
			new SyncableFetcher<CommonWebModel.ViewTemplate>(authHttp, '{+api}/{customer}/view-templates{/id}{?query*}', 'ViewTemplates'),

			new FileFetcher(authHttp, '{+api}/{customer}/assets/{+path}{?query*}', 'PrintAssets'),
			new FileFetcher(authHttp, '{+data}/defaults/{+path}', 'DefaultsData')
		]
	};
}
