<kn-sticky>
	<div class="form-container heading">
		<a href="javascript:void(0)" class="form-back" (click)="navigateBack()"><i class="material-icons">arrow_back</i></a>
		<div class="content">
			<h1>
				<ng-container *ngIf="isEditMode()" i18n="edit-database-name">Edit {{ databaseName }} {{ name }}</ng-container>
				<ng-container *ngIf="!isEditMode()" i18n>New {{ databaseName }}</ng-container>
			</h1>
			<div *ngIf="outdated" class="form-actions-info warning">
				<i class="material-icons">warning</i> <ng-container i18n>Editing outdated copy</ng-container>
			</div>
			<div class="form-buttons">
				<button type="button" class="danger"
						*ngIf="isEditMode() && deletable" (click)="delete()" i18n>Remove</button>
				<button type="submit" *ngIf="savable" [disabled]="!form.valid" form="main-form">
					<ng-container *ngIf="isEditMode()" i18n>Save</ng-container>
					<ng-container *ngIf="!isEditMode()" i18n>Create</ng-container>
				</button>
			</div>
		</div>
	</div>
</kn-sticky>

<div class="form-container">
	<form id="main-form" (ngSubmit)="save()" [formGroup]="form" class="form-layout">

		<div class="form-grid">
			<div class="form-box">
				<div class="content feature" formGroupName="database">
					<div class="row">
						<div class="form-input flex-2">
							<kn-input type="text" i18n-placeholder="name-placeholder" placeholder="Company name" formControlName="name" required>
								<span kn-label i18n>Name</span>
								<kn-control-messages kn-hint control="name"></kn-control-messages>
							</kn-input>
						</div>

						<div class="form-input flex-3">
							<kn-input type="text" formControlName="description">
								<span kn-label i18n>Description</span>
								<kn-control-messages kn-hint control="description"></kn-control-messages>
							</kn-input>
						</div>

						<div class="form-input flex-1">
							<kn-input type="text"
									[readonly]="isEditMode()"
									formControlName="uid"
									required>
								<span kn-label>UID</span>
								<kn-control-messages kn-hint control="uid"></kn-control-messages>
							</kn-input>
						</div>
					</div>

					<div class="row">
						<div class="form-input flex-2">
							<kn-select
									[readonly]="isEditMode()"
									formControlName="type"
									getter="value"
									required>
								<kn-option *ngFor="let type of databaseTypes" [value]="type">{{ type.label }}</kn-option>
								<span kn-label i18n>Type</span>
								<kn-control-messages kn-hint control="type"></kn-control-messages>
							</kn-select>
						</div>

						<div class="form-input flex-4">
							<kn-input type="text" formControlName="ownership">
								<span kn-prefix>{{ prefixOwnership }}</span>
								<span kn-label i18n>Ownership</span>
								<kn-control-messages kn-hint control="ownership"></kn-control-messages>
							</kn-input>
						</div>
					</div>
				</div>

				<div class="content" formGroupName="database">
					<div class="row">
						<div class="form-input flex-1">
							<kn-select
									[readonly]="isEditMode()"
									formControlName="provider"
									required>
								<kn-option *ngFor="let provider of databaseProviders" [value]="provider">{{ provider }}</kn-option>
								<span kn-label i18n>Provider</span>
								<kn-control-messages kn-hint control="provider"></kn-control-messages>
							</kn-select>
						</div>

						<div class="form-input flex-5">
							<kn-input type="text"
									[readonly]="isEditMode()"
									formControlName="connectionString"
									required>
								<span kn-label i18n>Connection String</span>
								<kn-control-messages kn-hint control="connectionString"></kn-control-messages>
							</kn-input>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="form-footer">
			<kn-audit-display [audit]="audit"></kn-audit-display>
		</div>

	</form>
</div>
