import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnCommonModule} from 'kn-common';
import {KnAuditDisplay} from './audit-display.component';

@NgModule({
	imports: [
		CommonModule, KnCommonModule
	],
	declarations: [
		KnAuditDisplay
	],
	exports: [
		KnAuditDisplay
	]
})
export class KnAuditDisplayModule { }
