import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {forRootGuardFactory} from 'kn-shared';
import {SandboxedDocumentBuilder} from './services/sandboxed-document.service';
import {AnimationService} from './services/animation.service';

import {KN_COMMON_PIPES} from './pipes';

export const KN_COMMON_PROVIDERS = [
	SandboxedDocumentBuilder,
	AnimationService
];

export const COMMON_FORROOT_GUARD = new InjectionToken<void>('COMMON_FORROOT_GUARD');

@NgModule({
	declarations: [KN_COMMON_PIPES],
	exports: [KN_COMMON_PIPES]
})
export class KnCommonModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(COMMON_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnCommonModule> {
		return {
			ngModule: KnCommonModule,
			providers: [
				forRootGuardFactory('KnCommonModule', COMMON_FORROOT_GUARD, KN_COMMON_PROVIDERS[0]),
				KN_COMMON_PROVIDERS
			]
		};
	}

	public static forChild(): ModuleWithProviders<KnCommonModule> {
		return {
			ngModule: KnCommonModule
		};
	}
}
