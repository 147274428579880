import {NgModule, Optional, SkipSelf, InjectionToken, ModuleWithProviders} from '@angular/core';
import {EraseIncompatibleMigrationStrategy} from './migration-strategies/erase-incompatible-migration-strategy';
import {JsonTemplateEncoder} from './template-encoders/json-template-encoder';
import {ViewTemplateManagerService} from './view-template-manager.service';
import {VIEW_TEMPLATE_MANAGER_MIGRATION_STRATEGIES_TOKEN} from './view_template_manager_migration_strategies.token';
import {VIEW_TEMPLATE_MANAGER_TEMPLATE_ENCODERS_TOKEN} from './view_template_manager_template_encoders.token';

export const VIEW_TEMPLATE_MANAGER_FORROOT_GUARD = new InjectionToken<void>('VIEW_TEMPLATE_MANAGER_FORROOT_GUARD');

@NgModule()
export class KnViewTemplateManagerModule {
	public constructor(@Optional() @SkipSelf() parentModule: KnViewTemplateManagerModule) {
		if (parentModule) {
			throw new Error('KnViewTemplateManagerModule is already loaded. Import it in the AppModule only.');
		}
	}

	public static forRoot(): ModuleWithProviders<KnViewTemplateManagerModule> {
		return {
			ngModule: KnViewTemplateManagerModule,
			providers: [
				{
					provide: VIEW_TEMPLATE_MANAGER_MIGRATION_STRATEGIES_TOKEN,
					useClass: EraseIncompatibleMigrationStrategy,
					multi: true
				}, {
					provide: VIEW_TEMPLATE_MANAGER_TEMPLATE_ENCODERS_TOKEN,
					useClass: JsonTemplateEncoder,
					multi: true
				},
				ViewTemplateManagerService
			]
		};
	}
}
