import {Response, UriContext} from 'kn-http';
import {Observable, Subject} from 'rxjs';
import {AbstractFetcher} from './fetchers/abstract-fetcher';
import {UriContextService} from './uri-context.service';

export class FetcherContextWrapper<T> extends AbstractFetcher<T> {
	public constructor(
			public baseFetcher: AbstractFetcher<T>,
			private readonly _uriContext: UriContextService) {
		super(baseFetcher.table);
	}

	public query(context?: UriContext): Observable<T[]> {
		return this.baseFetcher.query(this._mergeContext(context));
	}

	public get(indexer: string | string[], context?: UriContext): Observable<T> {
		return this.baseFetcher.get(indexer, this._mergeContext(context));
	}

	public head(indexer: string | string[], context?: UriContext): Observable<Response> {
		return this.baseFetcher.head(indexer, this._mergeContext(context));
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		return this.baseFetcher.save(item, this._mergeContext(context));
	}

	protected _remove(indexer: string | string[], context: UriContext): Observable<Response> {
		return this.baseFetcher.remove(indexer, this._mergeContext(context));
	}

	public websocket(context?: UriContext): Subject<T | T[]> {
		return this.baseFetcher.websocket(this._mergeContext(context));
	}

	private _mergeContext(context: UriContext) {
		return Object.assign({}, this._uriContext.context, context);
	}
}
