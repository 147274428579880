import {Directive, Injector, HostBinding, OnInit, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormGroup, FormArray, FormControl, AbstractControl} from '@angular/forms';
import {Observable, Subscription, merge as observableMerge, of as observableOf, from as observableFrom, combineLatest} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {Response} from 'kn-http';
import {ChangeEntry, Indexer} from 'kn-rest';
import {ActiveMonitor} from 'kn-shared';
import {TemporaryStorageService} from 'kn-cache';
import {EditMode} from './types';
import {AbstractGraphBulkStore, QueryContext} from 'common-web/rest';
import {AbstractEditComponent} from './abstract-edit.component';
import * as Model from 'common-web/model';

export enum MutateMode {
	Add,
	Remove,
	Update
}

export interface MutatorItem<T> {
	mode: MutateMode;
	item: T;
}

export interface Mutator {
	[key: string]: Model.EntityBase | MutatorItem<Model.EntityBase>[];
}

export type Mutable<U> = (original: U, template: U) => boolean;

@Directive()
export abstract class AbstractStoreBulkEditComponent<T extends { [key: string]: (Model.EntityBase | Model.EntityBase[]) }> extends AbstractEditComponent implements OnInit, OnDestroy {
	private readonly _mutables: { [key: string]: Mutable<any> } = {};
	private _indexers: (Indexer | QueryContext<string>)[];

	protected _route: ActivatedRoute;
	protected readonly _storage: TemporaryStorageService;
	protected _changesListenerSubscription: Subscription;
	protected _loadingMonitor = new ActiveMonitor(5, 500);
	protected _models: T[];
	protected _mutator: Mutator;
	protected _editToken: string;

	public outdated: boolean = false;

	@HostBinding('class.loading')
	public get loading(): boolean {
		return this._loadingMonitor.active;
	}

	public get modelsCount(): number {
		return this._models && this._models.length;
	}

	public constructor(injector: Injector, private readonly _store: AbstractGraphBulkStore<T>) {
		super(injector);
		this._route = injector.get(ActivatedRoute);
		this._storage = injector.get(TemporaryStorageService);
	}

	public ngOnInit() {
		super.ngOnInit();
		this._registerChangesListener();
	}

	public ngOnDestroy() {
		this._unregisterChangesListener();
		super.ngOnDestroy();
	}

	protected _init() {
		this._indexers = this._retriveIndexers();
		this._mode = EditMode.Edit;
	}

	protected _load() {
		this.disableControl(this.form, false);
		if (this._indexers) {
			const subscription = this._fetchModels(this._indexers)
				.lift(this._loadingMonitor.operator())
				.subscribe(
					next => {
						if (next != null) {
							this._indexers = next.map(x => this._store.retriveIndexer(x));
							this._models = next;
							this._mutator = this._combineModelsIntoMutator(this._models);
							this._populateFormValues(this.form, this._mutator);
						}
						else {
							this._indexers = null;
							this._models = null;
							this._mutator = null;
							this.form.reset(this._defaultValues);
						}
						this._updateDisable();
					},
					error => this._toast.show(this._i18n.t('Loading failed.'), this._retriveErrorMessage(error)));
			this._disposables.push(() => subscription.unsubscribe());
		}
		else {
			this._updateDisable();
		}
	}

	protected _reload() {
		super._reload();
		this.outdated = false;
	}

	protected _retriveMonitoredChanges(): Observable<ChangeEntry>[] {
		return [this._store.changes];
	}

	protected _registerChangesListener() {
		const monitoredChanges = this._retriveMonitoredChanges();
		if (monitoredChanges.length > 0) {
			this._changesListenerSubscription = observableMerge(...monitoredChanges)
				.pipe(Rx.filter(next => this._indexers.some(x => x == next.indexer))) // eslint-disable-line eqeqeq
				.subscribe(async next => {
					this.outdated = true;
					if (await this._confirmReload(next)) {
						this._reload();
					}
				});
		}
	}

	protected _updateDisable() {
		this._applyResistiveDisable();
	}

	private async _confirmReload(next: ChangeEntry) {
		const result = await this._confirmation.show(
			this._i18n.t('Data changed.'),
			this._i18n.t('Table {{ table }} has been modified.\nCan I reload this form?', { table: next.table }), [
				{ name: this._i18n.t('Reload'), classes: ['primary'], result: 'reload' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'reload';
	}

	protected _unregisterChangesListener() {
		this._changesListenerSubscription && this._changesListenerSubscription.unsubscribe();
	}

	protected _buildForm(): FormGroup {
		const formModel: { [key: string]: AbstractControl } = {
			[this._store.key]: this._buildControlGroup(this._store.key)
		};
		for (const key of this._store.dependentKeys) {
			formModel[key] = new FormGroup({
				add: new FormArray([]),
				remove: new FormArray([]),
				update: new FormArray([])
			});
		}
		return this._formBuilder.group(formModel);
	}

	protected abstract _buildControlGroup(key: string, mode?: string): FormGroup;

	protected _fetchModels(indexersOrQueries: (Indexer | QueryContext<string>)[]): Observable<T[]> {
		return combineLatest(indexersOrQueries.map(next => this._store.get(next) as Observable<T[]>)).pipe(
			Rx.map(next => Utils.array.flatten(next))
		);
	}

	protected _saveModels(models: T[]): Observable<{ [key: string]: Response | Response[] }[]> {
		let current = 0;
		return observableFrom(models).pipe(
			Rx.concatMap(next => this._store.save(next)),
			Rx.tap(next => {
				current++;
				this._toast.show(
					this._i18n.t('Saved item {{ current }} of {{ count }}.', { current, count: this._indexers.length })
				);
			}),
			Rx.toArray()
		);
	}

	protected _registerMutable<U>(key: string, mutable: Mutable<U>) {
		this._mutables[key] = mutable;
	}

	protected _combineModelsIntoMutator(models: T[]): Mutator {
		const mutator = {} as Mutator;
		for (const key in models[0]) {
			if (models[0].hasOwnProperty(key)) {
				if (Array.isArray(models[0][key])) {
					if (this._mutables.hasOwnProperty(key)) {
						const values = Utils.array.flatten(models.map(x => (x as any)[key]));
						mutator[key] = this._splitToMutableGroups(key, values)
							.filter(x => x.length === models.length)
							.map(x => this._combineObjects<any>(x))
							.filter(x => Object.values(x).length)
							.map(x => ({ mode: MutateMode.Update, item: x }));
					}
				}
				else {
					mutator[key] = this._combineObjects<any>(models.map(x => x[key]));
				}
			}
		}
		return mutator;
	}

	protected _splitToMutableGroups(key: string, values: any[]): any[][] {
		const groups = [];
		while (values.length) {
			const group = [values.pop()];
			const residue: any[] = [];
			while (values.length) {
				const template = values.pop();
				(this._mutables[key](group[0], template) ? group : residue).push(template);
			}
			groups.push(group);
			values = residue;
		}
		return groups;
	}

	protected _combineObjects<U extends { [key: string]: any }>(objects: U[]): U {
		const keys = Utils.array.intersect(...objects.map(x => Object.keys(x)));
		const result = {} as U;
		for (const key of keys) {
			let values = objects.map(x => x[key]);
			if (values.every(x => Utils.isObject(x))) {
				(result as any)[key] = this._combineObjects(values);
			}
			else {
				values = Utils.array.unique(values);
				if (values.length === 1) {
					(result as any)[key] = values[0];
				}
			}
		}
		return result;
	}

	protected _applyMutatorToModels(models: T[], mutator: Mutator): T[] {
		for (const key in mutator) {
			if (mutator.hasOwnProperty(key)) {
				if (Array.isArray(mutator[key])) {
					if (this._mutables.hasOwnProperty(key)) {
						const items = mutator[key] as MutatorItem<any>[];
						for (const item of items) {
							for (const model of models) {
								const originalIndex = (model[key] as any)
									.findIndex((x: any) => this._mutables[key](x, item.item));
								switch (item.mode) {
									case MutateMode.Add:
										if (originalIndex === -1) {
											(model[key] as any).push(Utils.clone(item.item, true));
										}
										break;
									case MutateMode.Remove:
										if (originalIndex !== -1) {
											(model[key] as any).splice(originalIndex, 1);
										}
										break;
									case MutateMode.Update:
										if (originalIndex !== -1) {
											this._applyChangesToObject((model[key] as any)[originalIndex], item.item);
										}
										break;
								}
							}
						}
					}
				}
				else {
					for (const model of models) {
						this._applyChangesToObject((model[key] as any), mutator[key]);
					}
				}
			}
		}
		return models;
	}

	protected _applyChangesToObject<U extends { [key: string]: any }>(object: U, changes: U) {
		for (const key in changes) {
			if (changes.hasOwnProperty(key)) {
				if (Utils.isObject(changes[key])) {
					this._applyChangesToObject(object[key], changes[key]);
				}
				else if (object && object.hasOwnProperty(key)) {
					object[key] = changes[key];
				}
			}
		}
	}

	protected _populateFormValues(form: FormGroup, mutator: Mutator) {
		const formGroup = form.controls[this._store.key] as FormGroup;
		this._setFormGroup(formGroup, this._mutator[this._store.key] as Model.EntityBase);
		for (const key of this._store.dependentKeys) {
			if (this._mutator.hasOwnProperty(key)) {
				let items = (this._mutator[key] as MutatorItem<any>[])
					.filter(x => x.mode === MutateMode.Add)
					.map(x => x.item);
				let formArray = form.get([key, 'add']) as FormArray;
				this._setFormArray(key, formArray, items, 'add');

				items = (this._mutator[key] as MutatorItem<any>[])
					.filter(x => x.mode === MutateMode.Remove)
					.map(x => x.item);
				formArray = form.get([key, 'remove']) as FormArray;
				this._setFormArray(key, formArray, items, 'remove');

				items = (this._mutator[key] as MutatorItem<any>[])
					.filter(x => x.mode === MutateMode.Update)
					.map(x => x.item);
				formArray = form.get([key, 'update']) as FormArray;
				this._setFormArray(key, formArray, items, 'update');
			}
		}
	}

	protected _setFormGroup(formGroup: FormGroup, entity: Model.EntityBase) {
		for (const key in formGroup.controls) {
			if (formGroup.controls.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
				const control = formGroup.controls[key];
				if (control instanceof FormGroup && Utils.isObject(entity[key])) {
					this._setFormGroup(control, entity[key]);
				}
				else if (control instanceof FormArray && Array.isArray(entity[key])) {
					this._setFormArray(key, control, entity[key] as any[]);
				}
				else if (control instanceof FormControl) {
					control.setValue(entity[key]);
				}
			}
		}
	}

	protected _setFormArray(key: string, controlsArray: FormArray, entitiesArray: Model.EntityBase[], mode?: string) {
		for (let i = controlsArray.length - 1; i >= entitiesArray.length; i--) {
			this.removeControl(controlsArray, i);
		}
		for (let i = 0; i < entitiesArray.length; i++) {
			let controlGroup: FormGroup;
			if (i === controlsArray.length) {
				controlGroup = this._buildControlGroup(key, mode);
				this.addControl(controlsArray, controlGroup);
			}
			else {
				controlGroup = controlsArray.at(i) as FormGroup;
			}
			if (controlGroup instanceof FormControl) {
				(controlGroup as FormControl).setValue(entitiesArray[i]);
			}
			else {
				this._setFormGroup(controlGroup, entitiesArray[i]);
			}
		}
	}

	protected _formToMutator(form: FormGroup): Mutator {
		const mutator = {
			[this._store.key]: (form.get([this._store.key]).enabled ? form.get([this._store.key]).value : {})
		} as Mutator;
		for (const key of this._store.dependentKeys) {
			const dependentMutator: MutatorItem<any>[] = [];

			form.get([key, 'add']).enabled && (form.get([key, 'add']).value as any[])
				.forEach(x => dependentMutator.push({ mode: MutateMode.Add, item: x }));
			form.get([key, 'remove']).enabled && (form.get([key, 'remove']).value as any[])
				.forEach(x => dependentMutator.push({ mode: MutateMode.Remove, item: x }));
			form.get([key, 'update']).enabled && (form.get([key, 'update']).value as any[])
				.forEach(x => dependentMutator.push({ mode: MutateMode.Update, item: x }));

			mutator[key] = dependentMutator;
		}
		return mutator;
	}

	protected _prepareModelToSave(model: T): T {
		for (const key of this._store.dependentKeys) {
			const dependentModel = model[key] as Model.EntityBase[];
			dependentModel.filter(item => item.id == null).forEach(item => delete item.id);
		}
		return model;
	}

	protected _retriveIndexers(): QueryContext<string>[] {
		let uid: string[];
		if (this._route.snapshot.queryParams['token']) {
			const token = this._route.snapshot.queryParams['token'];
			const data = this._storage.getItem(token);
			if (data) {
				uid = data.data['id'];
				this._editToken = token;
				this._disposables.push(() => this._storage.lose(this._editToken));
				this._storage.preserve(this._editToken);
			}
			else {
				this._toast.show(
					this._i18n.t('Loading failed.'),
					this._i18n.t('Edit token timeouted or not found.')
				);
				this.navigateBack(false);
				return [];
			}
		}
		if (uid == null) {
			uid = Utils.array.box(this._route.snapshot.queryParams['id']);
		}
		return uid && Utils.array.chunkArray(uid, 200).map(x => ({ query: { q: encodeURIComponent(x.map(id => encodeURIComponent('uid=' + id)).join('|')) } }));
	}

	public save(): void {
		const saver$ = observableOf(this._formToMutator(this.form)).pipe(
			Rx.map(next => this._applyMutatorToModels(this._models, next)),
			Rx.map(next => next.map(x => this._prepareModelToSave(x))),
			Rx.tap(() => {
				this._unregisterChangesListener();
				this.form.disable();
			}),
			Rx.switchMap(next => this._saveModels(next).pipe(Rx.map(() => next)))
		);

		/*
		 * No unsubscribe because save must be completed even if user navigates elsewhere early.
		 */
		saver$.subscribe(
			() => {
				if (this._editToken) {
					this._storage.removeItem(this._editToken);
				}
			},
			error => {
				this.form.enable();
				this._toast.show(this._i18n.t('Saving failed.'), this._retriveErrorMessage(error));
			},
			() => {
				this._toast.show(
					this._i18n.t('Items saved.'),
					this._i18n.t('{{ count }} items have been successfully saved!', { count: this._indexers.length })
				);
				this.outdated = false;
				this.navigateBack(false);
			});
	}
}
