import {Injectable} from '@angular/core';
import {Utils} from 'kn-utils';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('select')
export class SelectHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		return content.exec(this._transform(content.params[0], content.params[1]));
	}

	public _transform(value: any[], needle: { [key: string]: string }): any[] {
		const predicate = item => Object.keys(needle).reduce(
			(prev, key) => prev && item[key] === needle[key],
			true
		);
		return value == null ? null : value.find(predicate);
	}
}
