import {Injectable} from '@angular/core';
import {CanActivateChild, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {I18nService} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {PrintContextCaptureService} from './print-context-capture.service';

@Injectable()
export class PrintContextCaptureGuard implements CanActivateChild, CanDeactivate<any> {
	public constructor(
			private readonly _i18n: I18nService,
			private readonly _toast: ToastService,
			private readonly _printContextCapture: PrintContextCaptureService) {
	}

	public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this._canNavigate();
	}

	public canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		return this._canNavigate();
	}

	private _canNavigate() {
		if (this._printContextCapture.isCaptureMode()) {
			this._toast.show(
				this._i18n.t('Unavaible'),
				this._i18n.t('Sorry, this functionality is deactivated in print context capture mode.'));
			return false;
		}
		return true;
	}
}
