import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Response} from 'kn-http';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {RolesResourceService} from '../services/roles/roles-resource.service';
import {UserRolesResourceService} from '../services/users/user-roles-resource.service';
import {UsersResourceService} from '../services/users/users-resource.service';
import {Mirror, BlendModel} from 'common-web/rest';
import {AbstractUserRolesClonerService} from './abstract-user-roles-cloner.service';
import {DefaultLoader} from './default-loader.type';
import {User} from 'common-web/model';
import {UserRole, Role} from '../model/common-database.types';

@Injectable()
export class UserRolesDefaultLoaderService extends AbstractUserRolesClonerService implements DefaultLoader {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			rolesResource: RolesResourceService,
			userRolesResource: UserRolesResourceService,
			usersResource: UsersResourceService) {
		super([
			{
				key: 'roles',
				target: { resource: rolesResource, query: { only: ['id', 'name'] } },
				source: { resource: new Resource<Role>(rest, notifier, 'DefaultsData') }
			}, {
				key: 'userRoles',
				target: { resource: userRolesResource, query: { only: ['userUid', 'roleId', 'customerBranchId'] } },
				source: { resource: new Resource<UserRole>(rest, notifier, 'DefaultsData') }
			}, {
				key: 'users',
				target: { resource: usersResource, query: { only: ['uid'] } },
				source: { resource: new Resource<User>(rest, notifier, 'DefaultsData') }
			}
		]);
	}

	protected _fetchMirror(mirror: Mirror, targetPrecursor: any, sourcePrecursor: any): Observable<BlendModel> {
		const sourceUidMap: { [key: string]: string } = {
			roles: 'roles.json',
			userRoles: 'user-roles.json',
			users: 'users.json'
		};
		sourcePrecursor = { path: sourceUidMap[mirror.key] } || sourcePrecursor;
		return super._fetchMirror(mirror, targetPrecursor, sourcePrecursor);
	}

	public loadDefaults(targetPrecursor: any): Observable<{ [key: string]: Response }> {
		return this.clone(targetPrecursor, null);
	}
}
