import {Component, Input, Output, OnInit, OnChanges, OnDestroy, SimpleChanges, HostListener, EventEmitter, ElementRef} from '@angular/core';
import {Subscription, timer as observableTimer} from 'rxjs';
import {NotSupportedError} from 'kn-shared';

export interface HourDescriptor {
	position: {
		x: number;
		y: number;
	};
	name: string;
	value: number;
}

@Component({
	selector: 'kn-day-view',
	templateUrl: 'day-view.html'
})
export class KnDayView implements OnInit, OnChanges, OnDestroy {
	private _subscription: Subscription;
	private _hoursInPeriod: number = 24;

	@Input() public hour: number;
	@Output() public hourChange = new EventEmitter<number>();
	@Output() public accept = new EventEmitter<number>();

	public dayHours: HourDescriptor[];
	public nowHour: number;

	public get hoursInPeriod(): number {
		return this._hoursInPeriod;
	}

	@Input() public set hoursInPeriod(value: number) {
		if (value !== 12 && value !== 24) {
			throw new NotSupportedError();
		}
		this._hoursInPeriod = value;
	}

	@HostListener('wheel', ['$event'])
	public wheelHandler(event: WheelEvent) {
		if (this.hour == null) {
			this.hour = 0;
		}
		if (event.deltaY > 0) {
			this.changeHour(this.hour + (this.hour >= 23 ? -23 : 1));
		}
		else if (event.deltaY < 0) {
			this.changeHour(this.hour + (this.hour <= 0 ? 23 : -1));
		}
		this._element.nativeElement.focus();
		event.preventDefault();
	}

	@HostListener('keydown.enter', ['$event'])
	public enterHandler(event: KeyboardEvent) {
		if (this.hour != null) {
			this.accept.emit(this.hour);
		}
		event.preventDefault();
	}

	public get hourOffset(): number {
		return this.hoursInPeriod !== 12 || this.hour < 12 ? 0 : 12;
	}

	public constructor(private readonly _element: ElementRef) { }

	public ngOnInit() {
		this._update();
		this._subscription = observableTimer(0, 1000)
			.subscribe(() => this.nowHour = (new Date()).getHours());
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('hoursInPeriod' in changes) {
			this._update();
		}
	}

	public ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	public changeHour(value: number) {
		this.hour = value;
		this.hourChange.emit(this.hour);
	}

	public selectHour(value: number) {
		this.changeHour(value);
		this.accept.emit(this.hour);
	}

	public selectPeriod(value: number) {
		if (this.hour == null) {
			this.hour = 0;
		}
		if (value) {
			if (this.hour < 12) {
				this.changeHour(this.hour + 12);
			}
		}
		else {
			if (this.hour >= 12) {
				this.changeHour(this.hour - 12);
			}
		}
	}

	private _update() {
		this.dayHours = [];
		for (let i = 0; i < this.hoursInPeriod; i++) {
			const d = i >= 12 || this.hoursInPeriod === 12 ? 50 : 32;
			this.dayHours.push({
				position: {
					x: 50 + d * Math.sin(Math.PI * i / 6),
					y: 50 - d * Math.cos(Math.PI * i / 6)
				},
				name: i === 0 && this.hoursInPeriod === 12 ? '12' : i + '',
				value: i
			});
		}
	}
}
