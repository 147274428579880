import {AbstractFontResolver} from './resolvers/abstract-font-resolver';
import {AbstractImageResolver} from './resolvers/abstract-image-resolver';
import {CanvasColorParser} from './parsers/canvas-color-parser';
import {CssFontParser} from '../parsers/css-font-parser';
import {PdfkitCanvasGradientProxy} from './proxies/pdfkit-canvas-gradient-proxy';
import {px2pt} from '../utils/conversions';
import {PinnedCanvasRenderingContext2D} from './types';

export class PdfkitCanvasRenderingContext2dAdaptor implements PinnedCanvasRenderingContext2D {
	private readonly _stateStack: { [key: string]: any }[] = [];

	public constructor(
			private readonly _doc: PDFKit.PDFDocument,
			private readonly _fontResolver: AbstractFontResolver,
			private readonly _imageResolver: AbstractImageResolver) {
	}

	/**
	 * Width of lines. Default 1.0
	 */
	public set lineWidth(value: number) {
		this._doc.lineWidth(px2pt(value));
	}

	/**
	 * Type of endings on the end of lines. Possible values: butt (default), round, square.
	 */
	public set lineCap(value: string) {
		this._doc.lineCap(value);
	}

	/**
	 * Defines the type of corners where two lines meet. Possible values: round, bevel, miter (default).
	 */
	public set lineJoin(value: string) {
		this._doc.lineJoin(value);
	}

	/**
	 * Miter limit ratio. Default 10.
	 */
	public set miterLimit(value: number) {
		this._doc.miterLimit(px2pt(value));
	}

	/**
	 * Specifies where to start a dash array on a line.
	 */
	public lineDashOffset: number = 0;

	/**
	 * Font setting. Default value 10px sans-serif.
	 */
	public font: string = '10px sans-serif';

	/**
	 * Text alignment setting. Possible values: start (default), end, left, right or center.
	 */
	public textAlign: string = 'start';

	/**
	 * Baseline alignment setting. Possible values: top, hanging, middle, alphabetic (default), ideographic, bottom.
	 *
	 * @note Not supported
	 */
	public textBaseline: string = 'alphabetic';

	/**
	 * Directionality. Possible values: ltr, rtl, inherit (default).
	 */
	public direction: string = 'inherit';

	/**
	 * Color or style to use inside shapes. Default #000 (black).
	 */
	public set fillStyle(value: string | CanvasGradient | CanvasPattern) {
		const pdfColor = value instanceof PdfkitCanvasGradientProxy
			? { color: value.pdfGradient }
			: CanvasColorParser.parse(value);
		this._doc.fillColor(pdfColor.color as any, pdfColor.opacity);
	}

	/**
	 * Color or style to use for the lines around shapes. Default #000 (black).
	 */
	public set strokeStyle(value: string | CanvasGradient | CanvasPattern) {
		const pdfColor = value instanceof PdfkitCanvasGradientProxy
			? { color: value.pdfGradient }
			: CanvasColorParser.parse(value);
		this._doc.strokeColor(pdfColor.color as any, pdfColor.opacity);
	}

	/**
	 * Image smoothing mode; if disabled, images will not be smoothed if scaled.
	 *
	 * @note Not supported
	 */
	public imageSmoothingEnabled: boolean = true;

	/**
	 * Specifies the blurring effect. Default 0
	 *
	 * @note Not supported
	 */
	public shadowBlur: number = 0;

	/**
	 * Color of the shadow. Default fully-transparent black.
	 *
	 * @note Not supported
	 */
	public shadowColor: string = 'rgba(0, 0, 0, 0)';

	/**
	 * Horizontal distance the shadow will be offset. Default 0.
	 *
	 * @note Not supported
	 */
	public shadowOffsetX: number = 0;

	/**
	 * Vertical distance the shadow will be offset. Default 0.
	 *
	 * @note Not supported
	 */
	public shadowOffsetY: number = 0;

	/**
	 * Alpha value that is applied to shapes and images before they are composited onto the canvas. Default 1.0 (opaque).
	 */
	// TODO
	public globalAlpha: number = 1;

	/**
	 * With globalAlpha applied this sets how shapes and images are drawn onto the existing bitmap.
	 *
	 * @note Not supported
	 */
	public globalCompositeOperation: string = 'source-over';

	/**
	 * A read-only back-reference to the HTMLCanvasElement. Might be null if it is not associated with a <canvas> element.
	 */
	public get canvas(): HTMLCanvasElement {
		return null;
	}

	// Prefixed properties - there are just to meet interface
	public msFillRule: CanvasFillRule;
	public mozImageSmoothingEnabled: boolean;
	public oImageSmoothingEnabled: boolean;
	public webkitImageSmoothingEnabled: boolean;

	/**
	 * Sets all pixels in the rectangle defined by starting point (x, y) and size (width, height) to transparent black, erasing any previously drawn content.
	 */
	public clearRect(x: number, y: number, w: number, h: number): void {
		throw new Error('Method `clearRect` not implemented.');
	}

	/**
	 * Draws a filled rectangle at (x, y) position whose size is determined by width and height.
	 */
	public fillRect(x: number, y: number, w: number, h: number): void {
		this._doc.rect(px2pt(x), px2pt(y), px2pt(w), px2pt(h));
		this.fill();
	}

	/**
	 * Paints a rectangle which has a starting point at (x, y) and has a w width and an h height onto the canvas, using the current stroke style.
	 */
	public strokeRect(x: number, y: number, w: number, h: number): void {
		this._doc.rect(px2pt(x), px2pt(y), px2pt(w), px2pt(h));
		this.stroke();
	}

	/**
	 * Draws (fills) a given text at the given (x,y) position.
	 */
	public fillText(text: string, x: number, y: number, maxWidth?: number): void {
		this._drawText(text, x, y, maxWidth, false);
	}

	/**
	 * Draws (strokes) a given text at the given (x, y) position.
	 */
	public strokeText(text: string, x: number, y: number, maxWidth?: number): void {
		this._drawText(text, x, y, maxWidth, true);
	}

	/**
	 * Returns a TextMetrics object.
	 */
	public measureText(text: string): TextMetrics {
		throw new Error('Method `measureText` not implemented.');
	}

	/**
	 * Returns the current line dash pattern array containing an even number of non-negative numbers.
	 */
	public getLineDash(): number[] {
		throw new Error('Method `getLineDash` not implemented.');
	}

	/**
	 * Sets the current line dash pattern.
	 */
	public setLineDash(segments: number[]): void {
		segments = segments.map(px2pt);
		const length = segments[0];
		const space = segments.length > 1 ? segments[1] : segments[0];
		for (let i = 2; i < segments.length; i++) {
			if (segments[i] !== ((i % 2) === 0 ? length : space)) {
				throw new Error('Unsupported dash segments definition.');
			}
		}
		this._doc.dash(length, {
			space: space,
			phase: this.lineDashOffset
		});
	}

	/**
	 * Creates a linear gradient along the line given by the coordinates represented by the parameters.
	 */
	public createLinearGradient(x0: number, y0: number, x1: number, y1: number): CanvasGradient {
		return new PdfkitCanvasGradientProxy(this._doc.linearGradient(
			px2pt(x0),
			px2pt(y0),
			px2pt(x1),
			px2pt(y1)
		));
	}

	/**
	 * Creates a radial gradient given by the coordinates of the two circles represented by the parameters.
	 */
	public createRadialGradient(x0: number, y0: number, r0: number, x1: number, y1: number, r1: number): CanvasGradient {
		return new PdfkitCanvasGradientProxy(this._doc.radialGradient(
			px2pt(x0),
			px2pt(y0),
			px2pt(r0),
			px2pt(x1),
			px2pt(y1),
			px2pt(r1)
		));
	}

	/**
	 * Creates a pattern using the specified image (a CanvasImageSource). It repeats the source in the directions specified by the repetition argument. This method returns a CanvasPattern.
	 */
	public createPattern(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, repetition: string): CanvasPattern {
		throw new Error('Method `createPattern` not implemented.');
	}

	/**
	 * Starts a new path by emptying the list of sub-paths. Call this method when you want to create a new path.
	 */
	public beginPath(): void {
		// throw 'Method `beginPath` not implemented.';
	}

	/**
	 * Causes the point of the pen to move back to the start of the current sub-path. It tries to draw a straight line from the current point to the start. If the shape has already been closed or has only one point, this function does nothing.
	 */
	public closePath(): void {
		// throw 'Method `closePath` not implemented.';
	}

	/**
	 * Moves the starting point of a new sub-path to the (x, y) coordinates.
	 */
	public moveTo(x: number, y: number): void {
		this._doc.moveTo(px2pt(x), px2pt(y));
	}

	/**
	 * Connects the last point in the subpath to the x, y coordinates with a straight line.
	 */
	public lineTo(x: number, y: number): void {
		this._doc.lineTo(px2pt(x), px2pt(y));
	}

	/**
	 * Adds a cubic Bézier curve to the path. It requires three points. The first two points are control points and the third one is the end point. The starting point is the last point in the current path, which can be changed using moveTo() before creating the Bézier curve.
	 */
	public bezierCurveTo(cp1x: number, cp1y: number, cp2x: number, cp2y: number, x: number, y: number): void {
		this._doc.bezierCurveTo(
			px2pt(cp1x),
			px2pt(cp1y),
			px2pt(cp2x),
			px2pt(cp2y),
			px2pt(x),
			px2pt(y)
		);
	}

	/**
	 * Adds a quadratic Bézier curve to the current path.
	 */
	public quadraticCurveTo(cpx: number, cpy: number, x: number, y: number): void {
		this._doc.quadraticCurveTo(px2pt(cpx), px2pt(cpy), px2pt(x), px2pt(y));
	}

	/**
	 * Adds an arc to the path which is centered at (x, y) position with radius r starting at startAngle and ending at endAngle going in the given direction by anticlockwise (defaulting to clockwise).
	 */
	public arc(x: number, y: number, radius: number, startAngle: number, endAngle: number, anticlockwise?: boolean): void {
		const epsilon = 0.01;
		if (endAngle - startAngle < 2 * Math.PI - epsilon) {
			throw new Error('Arc is currently not fully supported. endAngle - startAngle must be 2*PI.');
		}
		this._doc.circle(px2pt(x), px2pt(y), px2pt(radius));
	}

	/**
	 * Adds an arc to the path with the given control points and radius, connected to the previous point by a straight line.
	 */
	public arcTo(x1: number, y1: number, x2: number, y2: number, radius: number): void {
		throw new Error('Method `arcTo` not implemented.');
	}

	/**
	 * Adds an ellipse to the path which is centered at (x, y) position with the radii radiusX and radiusY starting at startAngle and ending at endAngle going in the given direction by anticlockwise (defaulting to clockwise).
	 */
	public ellipse(x: number, y: number, radiusX: number, radiusY: number, rotation: number, startAngle: number, endAngle: number, anticlockwise?: boolean): void {
		const epsilon = 0.01;
		if (rotation !== 0) {
			throw new Error('Ellipse is currently not fully supported. rotation must be 0.');
		}
		if (endAngle - startAngle < 2 * Math.PI - epsilon) {
			throw new Error('Ellipse is currently not fully supported. endAngle - startAngle must be 2*PI.');
		}
		this._doc.ellipse(px2pt(x), px2pt(y), px2pt(radiusX), px2pt(radiusY));
	}

	/**
	 * Creates a path for a rectangle at position (x, y) with a size that is determined by width and height.
	 */
	public rect(x: number, y: number, w: number, h: number): void {
		this._doc.rect(px2pt(x), px2pt(y), px2pt(w), px2pt(h));
	}

	/**
	 * Fills the subpaths with the current fill style.
	 */
	public fill(fillRule?: CanvasFillRule): void;
	public fill(path: Path2D, fillRule?: CanvasFillRule): void;
	public fill(pathOrFillRule: Path2D | CanvasFillRule, fillRule?: CanvasFillRule): void {
		if (pathOrFillRule === Object(pathOrFillRule)) {
			throw new Error('Method `fill` with path not implemented.');
		}
		this._doc.fill(null, pathOrFillRule as CanvasFillRule);
	}

	/**
	 * Strokes the subpaths with the current stroke style.
	 */
	public stroke(): void {
		this.stroke();
	}

	/**
	 * If a given element is focused, this method draws a focus ring around the current path.
	 */
	public drawFocusIfNeeded(element: Element): void {
		throw new Error('Method `drawFocusIfNeeded` not implemented.');
	}

	/**
	 * Creates a clipping path from the current sub-paths. Everything drawn after clip() is called appears inside the clipping path only. For an example, see Clipping paths in the Canvas tutorial.
	 */
	public clip(fillRule?: CanvasFillRule): void;
	public clip(path: Path2D, fillRule?: CanvasFillRule): void;
	public clip(pathOrFillRule: Path2D | CanvasFillRule, fillRule?: CanvasFillRule): void {
		if (pathOrFillRule === Object(pathOrFillRule)) {
			throw new Error('Method `clip` with path not implemented.');
		}
		this._doc.clip(pathOrFillRule as CanvasFillRule);
	}

	/**
	 * Reports whether or not the specified point is contained in the current path.
	 */
	public isPointInPath(x: number, y: number, fillRule?: CanvasFillRule): boolean;
	public isPointInPath(path: Path2D, x: number, y: number, fillRule?: CanvasFillRule): boolean;
	public isPointInPath(pathOrX: Path2D | number, xOrY: number, yOrFillRule: number | CanvasFillRule, fillRule?: CanvasFillRule): boolean {
		throw new Error('Method `isPointInPath` not implemented.');
	}

	/**
	 * Reports whether or not the specified point is inside the area contained by the stroking of a path.
	 */
	public isPointInStroke(x: number, y: number): boolean {
		throw new Error('Method `isPointInStroke` not implemented.');
	}

	/**
	 * Adds a rotation to the transformation matrix. The angle argument represents a clockwise rotation angle and is expressed in radians.
	 */
	public rotate(angle: number): void {
		this._doc.rotate(angle);
	}

	/**
	 * Adds a scaling transformation to the canvas units by x horizontally and by y vertically.
	 */
	public scale(x: number, y: number): void {
		this._doc.scale(x, y);
	}

	/**
	 * Adds a translation transformation by moving the canvas and its origin x horzontally and y vertically on the grid.
	 */
	public translate(x: number, y: number): void {
		this._doc.translate(px2pt(x), px2pt(y));
	}

	/**
	 * Multiplies the current transformation matrix with the matrix described by its arguments.
	 *
	 * @note Not fully supported.
	 */
	public transform(m11: number, m12: number, m21: number, m22: number, dx: number, dy: number): void {
		this._doc.transform(m11, m12, m21, m22, dx, dy);
	}

	/**
	 * Resets the current transform to the identity matrix, and then invokes the transform() method with the same arguments.
	 *
	 * @note Not fully supported.
	 */
	public setTransform(m11: number, m12: number, m21: number, m22: number, dx: number, dy: number): void {
		this._doc.transform(m11, m12, m21, m22, dx, dy);
	}

	/**
	 * Draws the specified image. This method is available in multiple formats, providing a great deal of flexibility in its use.
	 */
	public drawImage(image: HTMLImageElement | HTMLCanvasElement | HTMLVideoElement, offsetX: number, offsetY: number, width?: number, height?: number, canvasOffsetX?: number, canvasOffsetY?: number, canvasImageWidth?: number, canvasImageHeight?: number): void {
		const imageSrc = this._imageResolver.resolve(image);
		this._doc.image(imageSrc, px2pt(canvasOffsetX), px2pt(canvasOffsetY), {
			width: px2pt(width == null ? image.width : width),
			height: px2pt(height == null ? image.height : height)
		});
	}

	/**
	 * Creates a new, blank ImageData object with the specified dimensions. All of the pixels in the new object are transparent black.
	 */
	public createImageData(imageDataOrSw: number | ImageData, sh?: number): ImageData {
		throw new Error('Method `createImageData` not implemented.');
	}

	/**
	 * Returns an ImageData object representing the underlying pixel data for the area of the canvas denoted by the rectangle which starts at (sx, sy) and has an sw width and sh height.
	 */
	public getImageData(sx: number, sy: number, sw: number, sh: number): ImageData {
		throw new Error('Method `getImageData` not implemented.');
	}

	/**
	 * Paints data from the given ImageData object onto the bitmap. If a dirty rectangle is provided, only the pixels from that rectangle are painted.
	 */
	public putImageData(imagedata: ImageData, dx: number, dy: number, dirtyX?: number, dirtyY?: number, dirtyWidth?: number, dirtyHeight?: number): void {
		throw new Error('Method `putImageData` not implemented.');
	}

	/**
	 * Saves the current drawing style state using a stack so you can revert any change you make to it using restore().
	 */
	public save(): void {
		const keys = [
			'lineDashOffset',
			'font',
			'textAlign',
			'textBaseline',
			'direction',
			'shadowBlur',
			'shadowColor',
			'shadowOffsetX',
			'shadowOffsetY',
			'globalAlpha',
			'globalCompositeOperation'
		];
		const properties = keys.reduce((acc, key) => Object.assign(acc, { [key]: (this as any)[key] }), {});
		this._stateStack.push(properties);
		this._doc.save();
	}

	/**
	 * Restores the drawing style state to the last element on the 'state stack' saved by save().
	 */
	public restore(): void {
		this._doc.restore();
		const properties = this._stateStack.pop();
		for (const key in properties) {
			if (properties.hasOwnProperty(key)) {
				(this as any)[key] = properties[key];
			}
		}
	}

	/**
	 * @note Not fully supported.
	 */
	private _drawText(text: string, x: number, y: number, maxWidth: number, stroke: boolean): void {
		if (x == null || y == null) {
			return;
		}
		let align = this.textAlign;
		switch (align) {
			case 'left':
			case 'right':
			case 'center':
				break;
			default:
			case 'start':
				align = this.direction === 'rtl' ? 'right' : 'left';
				break;
			case 'end':
				align = this.direction === 'rtl' ? 'left' : 'right';
				break;
		}
		const fontProperties = CssFontParser.parse(this.font);
		const fontSrc = this._fontResolver.resolve(fontProperties);
		this._doc.font(fontSrc, undefined, px2pt(fontProperties.fontSize));
		this._doc.text(text, px2pt(x), px2pt(y) - this._doc.currentLineHeight(), {
			lineBreak: false,
			align: align,
			fill: !stroke,
			stroke: stroke
		});
	}
}
