import {Component, Inject, OnInit, OnDestroy, ViewChildren, QueryList} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {forkJoin as observableForkJoin} from 'rxjs';
import {saveAs} from 'file-saver';
import {I18nService} from 'kn-shared';
import {ToastService} from 'kn-modal';
import {KnTabs} from 'kn-components';
import {AbstractCloner} from 'common-web/rest';
import {DefaultLoader} from '../../../clone-tools/default-loader.type';
import {DatabasesResourceService} from '../../../services/databases/databases-resource.service';
import {BackupDatabaseService} from '../../../services/databases/backup-database.service';
import {ManageDatabaseConfig} from './database-manage.config';
import {LOADER_DESCRIPTOR_FACTORIES_TOKEN} from './loader-descriptor-factories.token';
import {CLONER_DESCRIPTOR_FACTORIES_TOKEN} from './cloner-descriptor-factories.token';
import {LoaderDescriptor, ClonerDescriptor, LoaderDescriptorFactory, ClonerDescriptorFactory} from './types';
import {Database} from 'common-web/model';

@Component({
	selector: 'app-database-manage',
	templateUrl: 'database-manage.html'
})
export class DatabaseManageComponent implements OnInit, OnDestroy {
	public targetDatabase: Database;
	public sourceDatabases: Database[];
	public selectedDatabase: Database;
	public name: string;
	public loaderDescriptors: LoaderDescriptor[];
	public clonerDescriptors: ClonerDescriptor[];

	private _identifier: number | string;
	private _disposables: Function[] = [];

	@ViewChildren(KnTabs)
	public _tabsContainers: QueryList<KnTabs>;

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _route: ActivatedRoute,
			private readonly _location: Location,
			private readonly _databasesResource: DatabasesResourceService,
			private readonly _backupDatabase: BackupDatabaseService,
			private readonly _toast: ToastService,
			private readonly _config: ManageDatabaseConfig,
			@Inject(LOADER_DESCRIPTOR_FACTORIES_TOKEN) private readonly _loaderDescriptorFactories: LoaderDescriptorFactory[],
			@Inject(CLONER_DESCRIPTOR_FACTORIES_TOKEN) private readonly _clonerDescriptorFactories: ClonerDescriptorFactory[]) {
	}

	public ngOnInit() {
		this._init();
		this._load();
	}

	public ngOnDestroy() {
		this._dispose();
	}

	protected _init() {
		this._identifier = this._retriveIdentifier();
	}

	protected _load() {
		const query = { $type: this._config.databaseType, only: ['uid', 'name'] };
		const subscription = this._databasesResource.query({ query })
			.subscribe(
				next => {
					this.sourceDatabases = [];
					for (const database of next) {
						if (database.id === this._identifier || database.uid === this._identifier) {
							this.targetDatabase = database;
						}
						else {
							this.sourceDatabases.push(database);
						}
					}
					this._identifier = this.targetDatabase.id;
					this.name = this._extractName(this.targetDatabase);
					this.loaderDescriptors = this._loaderDescriptorFactories
						.map(x => x(this.targetDatabase, this.sourceDatabases));
					this.clonerDescriptors = this._clonerDescriptorFactories
						.map(x => x(this.targetDatabase, this.sourceDatabases));
				},
				error => this._toast.show('Loading failed.', this._retriveErrorMessage(error)));
		this._disposables.push(() => subscription.unsubscribe());
	}

	protected _dispose() {
		this._disposables.forEach(x => x());
		this._disposables = [];
	}

	protected _extractName(item: Database): string {
		return item['name'];
	}

	protected _retriveIdentifier() {
		return this._route.snapshot.params['id'];
	}

	protected _retriveErrorMessage(error: any) {
		return error.statusText || error.message || error;
	}

	public navigateBack(): void {
		this._location.back();
		// this._router.navigate(['.'], { relativeTo: this._route.parent });
	}

	public tabActivationChanged(tabs: KnTabs) {
		this.selectedDatabase = null;
		if (tabs.activeIndex >= 0) {
			this._tabsContainers.filter(x => x !== tabs).forEach(x => x.activeIndex = -1);
		}
	}

	public backup() {
		this._backupDatabase.backup(this.targetDatabase.uid, true)
			.subscribe(
				next => {
					saveAs(next, next.name);
					this._toast.show(
						this._i18n.t('Done'),
						this._i18n.t('Backuping has been completed successfully.'));
				},
				error => this._toast.show(
					this._i18n.t('Backup failed'),
					this._retriveErrorMessage(error)));
	}

	public restore(files: FileList) {
		if (files.length !== 1) {
			return;
		}
		this._backupDatabase.restore(this.targetDatabase.uid, files[0])
			.subscribe(
				() => { /* intentionaly empty */ },
				error => this._toast.show(
					this._i18n.t('Restore failed'),
					this._retriveErrorMessage(error)),
				() => this._toast.show(
					this._i18n.t('Done'),
					this._i18n.t('Restoring has been completed successfully.')));
	}

	public loadDefaults(loaders: DefaultLoader[]) {
		const targetContext = { [this._config.databaseUriKey]: this.targetDatabase.uid };
		observableForkJoin(loaders.map(x => x.loadDefaults(targetContext)))
			.subscribe(
				() => { /* intentionaly empty */ },
				error => this._toast.show(
					this._i18n.t('Loading defaults failed'),
					this._retriveErrorMessage(error)),
				() => this._toast.show(
					this._i18n.t('Done'),
					this._i18n.t('Defaults has been successfully loaded.')));
	}

	public clone(cloners: AbstractCloner[], source: Database) {
		const targetContext = { [this._config.databaseUriKey]: this.targetDatabase.uid };
		const sourceContext = { [this._config.databaseUriKey]: source.uid };
		observableForkJoin(cloners.map(x => x.clone(targetContext, sourceContext)))
			.subscribe(
				() => { /* intentionaly empty */ },
				error => this._toast.show(
					this._i18n.t('Cloning failed'),
					this._retriveErrorMessage(error)),
				() => this._toast.show(
					this._i18n.t('Done'),
					this._i18n.t('Tables has been successfully cloned.')));
	}
}
