import {Response, UriContext} from 'kn-http';
import {AbstractResource, ProxyResource, Resource, RestChangeNotifierService, RestService} from 'kn-rest';
import {Utils} from 'kn-utils';
import {Observable, combineLatest as observableCombineLatest, of as observableOf, throwError} from 'rxjs';
import * as Rx from 'rxjs/operators';

export interface UidRule {
	path: string | string[];
	table?: string;
	indexer?: string;
	formatter?: ((value: any) => string) | ((value: any) => string)[];
}

export interface UidConstructReceipt {
	property?: string;
	rules: UidRule[];
	composer?: (parts: string[]) => string;
	finalizer?: (value: string) => string;
}

export namespace Formatters {
	export function date(format: string, utc: boolean = false) {
		return (value: number | string | Date) => {
			let dateValue = value as Date;
			if (dateValue == null) {
				return null;
			}
			else if (Utils.isNumber(dateValue)) {
				dateValue = new Date(value as number);
			}
			else if (Utils.isString(value)) {
				dateValue = Utils.date.fromIso8601(value as string);
			}
			const pad = (n: string, width: number) =>
				n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
			const specifiers: { [key: string]: (x: Date) => string } = {
				'y': x => pad('' + (utc ? x.getUTCFullYear() : x.getFullYear()), 4),
				'M': x => pad('' + ((utc ? x.getUTCMonth() : x.getMonth()) + 1), 2),
				'd': x => pad('' + (utc ? x.getUTCDate() : x.getDate()), 2),
				'H': x => pad('' + (utc ? x.getUTCHours() : x.getHours()), 2),
				'm': x => pad('' + (utc ? x.getUTCMinutes() : x.getMinutes()), 2),
				's': x => pad('' + (utc ? x.getUTCSeconds() : x.getSeconds()), 2)
			};
			return format.split('')
				.map(x => specifiers.hasOwnProperty(x) ? specifiers[x](dateValue) : x)
				.join('');
		};
	}

	export function translate(translateTable: { [key: string]: string }, fallback: string | ((value: string) => string) = '') {
		return (value: string) => {
			if (translateTable.hasOwnProperty(value)) {
				return translateTable[value];
			}
			if (Utils.isFunction(fallback)) {
				return (fallback as (value: string) => string)(value);
			}
			return fallback as string;
		};
	}

	export function slugify(length: number, cropCenter: boolean = false, wordCropThreshold: number = 0.2) {
		return (value: string) => {
			if (value == null) {
				return value;
			}
			let slug = Utils.text.latinise(value)
				.toLowerCase()
				.replace(/[^a-z0-9\s._-]/g, '')
				.replace(/_{2,}/g, '_')
				.replace(/[\s.-]+/g, '-')
				.replace(/^[_-]+/, '');
			const index = ['-', '_'].map(x => slug.lastIndexOf(x, length))
				.reduce((a, b) => a > b ? a : b);
			let cropLength = index === -1 ? length : index;
			if (!cropCenter && (length - cropLength) / length > wordCropThreshold) {
				cropLength = length;
			}
			else {
				cropLength = length;
			}
			if (cropCenter && slug.length > cropLength) {
				slug = slug.substr(0, Math.floor(cropLength / 2)) + slug.substr(slug.length - Math.ceil(cropLength / 2));
			}
			return slug.substr(0, cropLength)
				.replace(/[_-]+$/, '');
		};
	}
}

export class UidResource<T extends { [key: string]: any }> extends ProxyResource<T> {
	private readonly _rest: RestService;
	private readonly _uidReceipt: UidConstructReceipt;

	public constructor(
			rest: RestService,
			baseResource: AbstractResource<T>,
			uidReceipt: UidConstructReceipt);
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			table: string,
			uidReceipt: UidConstructReceipt);
	public constructor(
			rest: RestService,
			baseResourceOrNotifier: AbstractResource<T> | RestChangeNotifierService,
			uidReceiptOrTable: string | UidConstructReceipt,
			uidReceipt?: UidConstructReceipt) {
		super(baseResourceOrNotifier instanceof AbstractResource
				? baseResourceOrNotifier
				: new Resource(rest, baseResourceOrNotifier, uidReceiptOrTable as string));
		this._rest = rest;
		this._uidReceipt = uidReceipt || uidReceiptOrTable as UidConstructReceipt;
	}

	public save(item: T, context?: UriContext): Observable<Response> {
		if (this._getUid(item) == null) {
			return this._constructUid(item).pipe(
				Rx.map(next => {
					if (!next) {
						return null;
					}
					return this._setUid(item, next);
				}),
				Rx.switchMap(next => {
					if (next == null) {
						return throwError('Uid cannot be empty');
					}
					return super.save(next, context);
				})
			);
		}
		return super.save(item, context);
	}

	private _constructUid(item: T) {
		const defaultComposer = (parts: string[]) => parts.filter(x => x !== '').join('-');
		const defaultFinalizer = (value: string) => value;
		const parts$ = this._uidReceipt.rules.map(x =>
			this._fetchUidPart(item, x).pipe(Rx.map(next => this._executeFormatters(next, x))));
		return observableCombineLatest(parts$).pipe(
			Rx.map(next => (this._uidReceipt.composer || defaultComposer)(next)),
			Rx.map(next => (this._uidReceipt.finalizer || defaultFinalizer)(next))
		);
	}

	private _fetchUidPart(item: T, rule: UidRule) {
		let path = rule.path;
		const part = Utils.object.get(item, path);
		if (part != null || rule.table == null || rule.indexer == null || item[rule.indexer] == null) {
			return observableOf(part);
		}
		path = Array.isArray(path) ? path.slice(1) : path.substr(path.indexOf('.') + 1);
		const query = {
			$id: item[rule.indexer],
			select: path,
			limit: 1
		};
		return this._rest.for<any>(rule.table).query({ query })
			.pipe(Rx.map(next => next[0]));
	}

	private _executeFormatters(value: any, rule: UidRule) {
		const defaultFormatter = (x: any) => x != null ? `${x}` : '';
		return Utils.array.box(rule.formatter || defaultFormatter)
			.reduce((acc, x) => x(acc), value);
	}

	private _getUid(item: T) {
		return item[this._uidReceipt.property || 'uid'];
	}

	private _setUid(item: T, uid: string) {
		return Object.assign(item, { [this._uidReceipt.property || 'uid']: uid });
	}
}
