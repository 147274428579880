import {Component, ViewEncapsulation, Input, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {Utils} from 'kn-utils';
import {RowItem, Sorting, Description, Model, SectionModel, Section, Column, ModelChange} from '../../types';
import {SectionUtils, ValueResolveUtils} from '../../internal-utils';
import {KnPortalService, KnPortal} from 'kn-overlay';
import {ConnectedPosition, HorizontalConnectionPos, VerticalConnectionPos} from 'kn-overlay';

export interface MenuColumnEntry {
	id: string;
	section: Section<RowItem>;
	column: Column<RowItem>;
	changes: { [key: string]: any };
	children: MenuColumnEntry[];
}

type MenuSectionNode = {
	section: Section<RowItem>;
	paths: string[][];
	children: MenuSectionNode[];
};

@Component({
	selector: 'kn-column-context-menu',
	templateUrl: 'column-context-menu.html',
	styleUrls: ['column-context-menu.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnColumnContextMenu implements AfterViewInit, OnDestroy {
	@Input() public portalOrigin: ElementRef;
	@Input() public column: Column<RowItem>;
	@Input() public columns: Column<RowItem>[];
	@Input() public description: Description<RowItem>;
	@Input() public model: Model;
	@Output() public modelEvent: EventEmitter<ModelChange> = new EventEmitter<ModelChange>();
	@Output() public commitModel = new EventEmitter<void>();

	@ViewChild(KnPortal, { static: true })
	public contextmenuPortal: KnPortal;

	public get isOpen(): boolean {
		return this.contextmenuPortal && this.contextmenuPortal.isAttached;
	}
	public entries: MenuColumnEntry[];
	public currentEntry: MenuColumnEntry;

	public readonly menuPositions: ConnectedPosition[] = [{
		originX: HorizontalConnectionPos.center,
		originY: VerticalConnectionPos.bottom,
		overlayX: HorizontalConnectionPos.center,
		overlayY: VerticalConnectionPos.top,
		panelClass: ['top-center'],
		panelClassOutsideViewport: ['clipped']
	}, {
		originX: HorizontalConnectionPos.start,
		originY: VerticalConnectionPos.bottom,
		overlayX: HorizontalConnectionPos.start,
		overlayY: VerticalConnectionPos.top,
		panelClass: ['top-left'],
		panelClassOutsideViewport: ['clipped']
	}, {
		originX: HorizontalConnectionPos.end,
		originY: VerticalConnectionPos.bottom,
		overlayX: HorizontalConnectionPos.end,
		overlayY: VerticalConnectionPos.top,
		panelClass: ['top-right'],
		panelClassOutsideViewport: ['clipped']
	}, {
		originX: HorizontalConnectionPos.end,
		originY: VerticalConnectionPos.top,
		overlayX: HorizontalConnectionPos.start,
		overlayY: VerticalConnectionPos.top,
		panelClass: ['left-top'],
		panelClassOutsideViewport: ['clipped']
	}, {
		originX: HorizontalConnectionPos.start,
		originY: VerticalConnectionPos.top,
		overlayX: HorizontalConnectionPos.end,
		overlayY: VerticalConnectionPos.top,
		panelClass: ['right-top'],
		panelClassOutsideViewport: ['clipped']
	}];

	public constructor(private readonly _portalService: KnPortalService) {
	}

	public suppressClosingHandler(event: Event) {
		if (this.isOpen) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	public ngAfterViewInit() {
		this._portalService.registerPortal(this.contextmenuPortal);
	}

	public ngOnDestroy() {
		this._portalService.unregisterPortal(this.contextmenuPortal);
	}

	public show() {
		this.initModel();
		this._portalService.attachPortal(this.contextmenuPortal);
	}

	public hide() {
		this._portalService.detachPortal(this.contextmenuPortal);
	}

	public initModel(): void {
		this.entries = this._buildEntries(this._createMenuSectionsTree());
		this.currentEntry = this._getCurrentEntry(this.entries);
	}

	private _buildEntries(node: MenuSectionNode) {
		const columns = this._filterColumnsByPath(node.paths);

		const entries: MenuColumnEntry[] = [];
		for (const column of columns) {
			entries.push({
				id: column.id,
				section: null,
				column: column,
				changes: {},
				children: []
			} as MenuColumnEntry);
		}

		for (const child of node.children) {
			entries.splice(this._getInsertPosition(entries, child), 0, {
				id: child.section.id,
				section: child.section,
				column: null,
				changes: {},
				children: this._buildEntries(child)
			} as MenuColumnEntry);
		}

		return entries;
	}

	private _getInsertPosition(entries: MenuColumnEntry[], node: MenuSectionNode) {
		let position = 0;
		const targetIds = entries.map(x => x.column != null ? x.id : null);
		const ordinalIds = this.description.columns.map(x => x.id);
		const nodeIds = node.section.columns.map(x => x.id);
		for (const id of ordinalIds) {
			const index = targetIds.indexOf(id);
			if (index !== -1) {
				position = index + 1;
			}
			else if (nodeIds.indexOf(id) !== -1) {
				break;
			}
		}
		return position;
	}

	private _getCurrentEntry(entries: MenuColumnEntry[]): MenuColumnEntry {
		const currentEntry = entries.find(x => x.column && (x.id === this.column.id));
		if (currentEntry != null) {
			return currentEntry;
		}
		if (entries.length === 0) {
			return null;
		}
		return this._getCurrentEntry(entries.reduce((acc, x) => acc.concat(x.children), []));
	}

	private _createMenuSectionsTree(section: Section<RowItem> = null, basePath: string[] = []): MenuSectionNode {
		if (section == null) {
			section = this.column.section;
			while (section.parent != null) {
				section = section.parent;
			}
		}

		const paths: string[][] = [basePath];
		const children: MenuSectionNode[] = [];
		SectionUtils.forEachNode(section.children, (x, p) => {
			const path = basePath.concat(p, [x.id]);
			if (x.description.menu) {
				children.push(this._createMenuSectionsTree(x, path));
				return false;
			}
			paths.push(path);
			return true;
		});

		return { section, paths, children } as MenuSectionNode;
	}

	private _filterColumnsByPath(paths: string[][]) {
		return this.description.columns
			.filter(column => {
				const currentPath = Utils.array.box(column.section);
				return paths.some(path => Utils.equal(path, currentPath));
			})
			.map(x => x.id)
			.map(id => this.columns.find(x => x.id === id))
			.filter(x => x != null && !ValueResolveUtils.resolveHidden(x));
	}

	public commit(): void {
		if (this.entries.reduce((acc, x) => this._commitEntry(x) || acc, false)) {
			this.commitModel.emit();
		}
		this.hide();
	}

	private _commitEntry(entry: MenuColumnEntry): boolean {
		let modified = false;
		if (entry.column != null) {
			const column = this.columns.find(x => x.id === entry.id);
			if (column != null) {
				for (const key in entry.changes) {
					if (entry.changes.hasOwnProperty(key)) {
						if ((column.model as { [key: string]: any })[key] !== entry.changes[key]) {
							(column.model as { [key: string]: any })[key] = entry.changes[key];
							modified = true;
						}
					}
				}
			}
		}
		else {
			let section: SectionModel = null;
			SectionUtils.forEachNode(this.model.sections, x => {
				if (x.id === entry.id) {
					section = x;
				}
			});
			if (section != null) {
				for (const key in entry.changes) {
					if (entry.changes.hasOwnProperty(key)) {
						if ((section as { [key: string]: any })[key] !== entry.changes[key]) {
							(section as { [key: string]: any })[key] = entry.changes[key];
							modified = true;
						}
					}
				}
			}
		}

		return entry.children.reduce((acc, x) => this._commitEntry(x) || acc, false) || modified;
	}

	public getNameFromId(id: string) {
		const column = this.columns.find(x => x.id === id);
		return column != null ? ValueResolveUtils.resolveName(column) : id;
	}

	public isColumnHidable(entry: MenuColumnEntry): boolean {
		return this.getColumnVisibility(entry) && entry.column.description.visibility;
	}

	public getColumnVisibility(entry: MenuColumnEntry): boolean {
		return entry.changes.hasOwnProperty('visible') ? entry.changes['visible'] : entry.column.model.visible;
	}

	public setColumnVisible(entry: MenuColumnEntry, value: boolean): void {
		if (this.isColumnHidable(entry)) {
			entry.changes['visible'] = value;
			this.commit();
		}
	}

	public isColumnGroupable(entry: MenuColumnEntry): boolean {
		return entry.column.description.groupable;
	}

	public getColumnGrouping(entry: MenuColumnEntry): boolean {
		return entry.changes.hasOwnProperty('group') ? entry.changes['group'] : entry.column.model.group;
	}

	public setColumnGrouping(entry: MenuColumnEntry, value: boolean): void {
		if (this.isColumnGroupable(entry)) {
			entry.changes['group'] = value;
			if (value && this.getColumnSorting(entry) === 'none') {
				this.setColumnSorting(entry, 'asc');
			}
			this.commit();
		}
	}

	public isColumnSortable(entry: MenuColumnEntry): boolean {
		return entry.column.description.sortable;
	}

	public getColumnSorting(entry: MenuColumnEntry): string {
		const sort = entry.changes.hasOwnProperty('sort') ? entry.changes['sort'] : entry.column.model.sort;
		switch (sort) {
			case Sorting.Ascending:
				return 'asc';
			case Sorting.Descending:
				return 'desc';
			default:
				return 'none';
		}
	}

	public setColumnSorting(entry: MenuColumnEntry, value: string): void {
		if (this.isColumnSortable(entry)) {
			if (this.description.mutalSorting && !this.getColumnGrouping(entry)) {
				this.entries
					.filter(x => !this.getColumnGrouping(x) && x !== entry)
					.forEach(x => x.changes['sort'] = Sorting.None);
			}
			switch (value) {
				case 'asc':
					entry.changes['sort'] = Sorting.Ascending;
					break;
				case 'desc':
					entry.changes['sort'] = Sorting.Descending;
					break;
				default:
					if (!this.getColumnGrouping(entry)) {
						entry.changes['sort'] = Sorting.None;
					}
					break;
			}
			this.commit();
		}
	}

	public getColumnSize(entry: MenuColumnEntry): number {
		return entry.changes.hasOwnProperty('width') ? entry.changes['width'] : entry.column.model.width;
	}

	public isColumnResizable(entry: MenuColumnEntry) {
		return entry.column.description.resizable;
	}

	public setSize(entry: MenuColumnEntry, value: number) {
		if (this.isColumnResizable(entry)) {
			entry.changes['width'] = value;
			this.commit();
		}
	}
}
