<ng-container [formGroup]="form">
	<div class="row" *ngFor="let row of rows" [ngClass]="row.classes">
		<div class="form-input" *ngFor="let input of row.inputs" [ngClass]="input.classes" [ngSwitch]="input.type">
			<kn-input *ngSwitchCase="'input'" [formControlName]="input.name"
					[max]="input.max"
					[maxlength]="input.maxlength"
					[min]="input.min"
					[minlength]="input.minlength"
					[placeholder]="input.placeholder"
					[readonly]="input.readonly"
					[required]="input.required"
					[step]="input.step"
					[type]="input.inputType">
				<span *ngIf="input.prefix" kn-prefix>{{ input.prefix }}</span>
				<kn-optgroup *ngIf="input.options">
					<kn-option *ngFor="let option of input.options" [value]="option">
						{{ option }}
					</kn-option>
				</kn-optgroup>
				<kn-datepicker *ngIf="input.inputType == 'date' || input.inputType == 'datetime-local'"></kn-datepicker>
				<span *ngIf="input.suffix" kn-suffix>{{ input.suffix }}</span>
				<span kn-label>{{ input.label }}</span>
				<kn-control-messages kn-hint [control]="input.name"></kn-control-messages>
			</kn-input>
			<kn-select *ngSwitchCase="'select'" [formControlName]="input.name"
					[hasFilter]="input.hasFilter"
					[multiple]="input.multiple"
					[placeholder]="input.placeholder"
					[readonly]="input.readonly"
					[required]="input.required"
					[hasFilter]="input.hasFilter"
					getter="value">
				<span *ngIf="input.prefix" kn-prefix>{{ input.prefix }}</span>
				<kn-optgroup>
					<kn-option *ngFor="let option of input.options" [value]="option">
						{{ option.label }}
					</kn-option>
				</kn-optgroup>
				<span *ngIf="input.suffix" kn-suffix>{{ input.suffix }}</span>
				<span kn-label>{{ input.label }}</span>
				<kn-control-messages kn-hint [control]="input.name"></kn-control-messages>
			</kn-select>
			<kn-textarea *ngSwitchCase="'textarea'" [formControlName]="input.name"
					[maxlength]="input.maxlength"
					[placeholder]="input.placeholder"
					[readonly]="input.readonly"
					[required]="input.required"
					[wrap]="input.wrap">
				<span *ngIf="input.prefix" kn-prefix>{{ input.prefix }}</span>
				<span *ngIf="input.suffix" kn-suffix>{{ input.suffix }}</span>
				<span kn-label>{{ input.label }}</span>
				<kn-control-messages kn-hint [control]="input.name"></kn-control-messages>
			</kn-textarea>
			<kn-radio-group *ngSwitchCase="'radio'" [formControlName]="input.name" [ngClass]="input.radioGroupClasses">
				<div *ngFor="let option of input.options" [ngClass]="input.radioClasses">
					<kn-radio [value]="option.value">
						<span kn-label>{{ option.label }}</span>
					</kn-radio>
				</div>
			</kn-radio-group>
			<kn-checkbox *ngSwitchCase="'checkbox'" [formControlName]="input.name"
					[required]="input.required"
					[indeterminate]="input.indeterminate">
				<span kn-label>{{ input.label }}</span>
			</kn-checkbox>
			<span *ngSwitchCase="'label'">
				{{ input.label }}
			</span>
		</div>
	</div>
</ng-container>
