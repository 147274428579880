<thead knTableHead
		[columns]="columns"
		[visibleColumns]="visibleColumns"
		[description]="description"
		[model]="model"
		(modelEvent)="onModelEvent($event)"
		(reorderColumn)="reorderColumn($event.fromIndex, $event.toIndex)"
		(commitModel)="commitModel()"></thead>
<tbody knTableBody
		knReordableRowsContainer knKeysControl
		[visibleColumns]="visibleColumns"
		[tree]="tree"
		[description]="description"
		(reorderRows)="reorderRows($event)"
		(rowSelect)="rowSelect.emit($event)"
		(rowActivate)="rowActivate.emit($event)"></tbody>
