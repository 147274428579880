import {Component, Input, HostBinding, ElementRef, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {BooleanField} from 'kn-common';
import {KnMenu} from './menu.component';
import {KnPortalService, KnPortal, ConnectedPosition, knPortalCenterPosition, knPortalAlignPosition, knPortalRightAlignPosition} from 'kn-overlay';
import {Utils} from 'kn-utils';

@Component({
	selector: 'kn-menu-content',
	template: '<div [class]="hostClass" *knPortal="menuOrigin positions positions" (click)="clickHandler()"><ng-content></ng-content></div>'
})
export class KnMenuContent implements AfterViewInit, OnDestroy {
	public constructor(
			private readonly _elRef: ElementRef,
			private readonly _menu: KnMenu,
			private readonly _portalService: KnPortalService) {
		const hostClass = Utils.clone((this._elRef.nativeElement as HTMLElement).classList, true);
		hostClass.add('kn-menu-content');
		this.hostClass = hostClass.value;
		this._menu.expandedChange.subscribe(
			(expanded: boolean) => {
				if (expanded) {
					this._portalService.attachPortal(this.menuContent);
				}
				else {
					this._portalService.detachPortal(this.menuContent);
				}
		});
	}

	@Input() @BooleanField() public autoclose = false;
	public get menuOrigin(): ElementRef {
		return this._menu.elementRef;
	}

	@Input('center') @BooleanField() public centerPosition = false;
	@Input('right') @BooleanField() public rightPosition = false;

	@Input('portalPositions')
	public portalPositions: ConnectedPosition[];

	@ViewChild(KnPortal, { static: true })
	public menuContent: KnPortal;

	public get positions(): ConnectedPosition[] {
		return this.portalPositions
		|| (this.centerPosition ? knPortalCenterPosition : (this.rightPosition ? knPortalRightAlignPosition : knPortalAlignPosition));
	}

	@HostBinding('hidden')
	public get hidden(): boolean {
		return !this._menu.expanded;
	}

	public hostClass: string;

	public clickHandler() {
		!this.autoclose && this._menu.preventCollapse();
	}

	public ngAfterViewInit() {
		this._portalService.registerPortal(this.menuContent);
	}

	public ngOnDestroy() {
		this._portalService.unregisterPortal(this.menuContent);
	}
}
