<ng-template #linkListRender let-links>
	<li *ngFor="let link of links">
		<ng-template
				[knCan]="link.action || 'show'"
				[knCanThe]="link.resource || link.link"
				[knCanRestrictions]="link.restrictions">
			<a knRipple [routerLink]="link.link" [routerLinkActive]="activeClass"
				[routerLinkActiveOptions]="{ exact: link.exact }">{{ link.name }}</a>
		</ng-template>
	</li>
</ng-template>

<ul>
	<ng-container
			*ngTemplateOutlet="linkListRender; context: { $implicit: navbarLinks }"
			></ng-container>
	<li *ngIf="moreLinks?.length">
		<kn-menu>
			<kn-menu-activator knRipple>
				<i class="material-icons">menu</i>
			</kn-menu-activator>
			<kn-menu-content class="kn-navbar-expansion header-liner">
				<ul>
					<ng-container
							*ngTemplateOutlet="linkListRender; context: { $implicit: moreLinks }"
							></ng-container>
				</ul>
			</kn-menu-content>
		</kn-menu>
	</li>
</ul>
