import {Injector, Directive, OnInit, OnDestroy, Input, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute, NavigationExtras} from '@angular/router';
import {Observable, combineLatest as observableCombineLatest, of, throwError} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {UriContext} from 'kn-http';
import {ToastService, ConfirmationService} from 'kn-modal';
import {Filter, QueryParametersFilterSerializer, FilterNode, FilterValue, Model as FilterModel, ModelBuilder as FilterModelBuilder} from 'kn-query-filter';
import {Model as GridModel, ModelBuilder as GridModelBuilder} from 'kn-datagrid';
import {EntityBase, IUid} from 'common-web/model';
import {AbstractGraphStore} from 'common-web/rest';
import {RouterReload} from 'common-web';
import {TemporaryStorageService} from 'kn-cache';
import {AbstractResource} from 'kn-rest';
import {BooleanField} from 'kn-common';
import {GridBonding} from './grid-bonding';
import {GridDataBonding} from './grid-data-bonding';
import {AbstractGridData} from './abstract-grid-data.service';

export type GridViewMode =
	'toplevel' |
	'embedded';
export const GridViewMode = {
	TopLevel: 'toplevel' as GridViewMode,
	Embedded: 'embedded' as GridViewMode
};

export type ActionItemCommand<T> = {
	name: 'new' | 'copy' | 'edit';
	item: T;
};

export type ActionItemsCommand<T> = {
	name: 'delete' | 'edit';
	items: T[];
};

export type ActionNavigateCommand<T> = {
	commands: any[];
	extras?: NavigationExtras;
	_?: T; // suppress 'T' is declared but never used.
};

export class CommantEvent<T> {
	private _prevented: boolean;

	public get prevented(): boolean {
		return this._prevented;
	}

	public constructor(public command: T) { }

	public preventDefault() {
		this._prevented = true;
	}
}

export type ActionCommand<T> = ActionItemCommand<T> | ActionItemsCommand<T> | ActionNavigateCommand<T>;

@Directive()
export abstract class AbstractGridViewComponent<T extends EntityBase | (EntityBase & IUid)> extends RouterReload implements OnInit, OnDestroy {
	protected _i18n: I18nService;
	protected _router: Router;
	protected _route: ActivatedRoute;
	protected _confirmation: ConfirmationService;
	protected _toast: ToastService;
	protected _disposables: Function[] = [];
	protected _storage: TemporaryStorageService;
	protected _injector: Injector;

	@Input() public mode: GridViewMode = GridViewMode.TopLevel;
	@Input() @BooleanField() public fixedQueryParams: boolean = false;

	@Output() public rowActivate: EventEmitter<T> = new EventEmitter<T>();
	@Output() public selectedChanged = new EventEmitter<T[]>();
	@Output() public executed = new EventEmitter<CommantEvent<ActionCommand<T>>>();

	// all complex grid/filter model handling should be moved to more specific class
	public abstract gridSetting: GridModel;
	public sessionFilter: FilterModel;
	public defaultFilter: FilterModel;
	public resolve: Observable<void>[] = [];
	public bonding: GridBonding<T>;

	public constructor(
			injector: Injector,
			protected _gridData: AbstractGridData<T>,
			protected _store?: AbstractGraphStore<any>) {
		super(injector.get(Router));
		this._injector = injector;
		this._i18n = injector.get(I18nService);
		this._router = injector.get(Router);
		this._route = injector.get(ActivatedRoute);
		this._confirmation = injector.get(ConfirmationService);
		this._toast = injector.get(ToastService);
		this._storage = injector.get(TemporaryStorageService);

		const subscription = this._route.queryParams
			.pipe(
				Rx.skip(1),
				Rx.filter(next => Object.keys(next).length > 0 && this.bonding != null)
			)
			.subscribe(next => this._applyQueryParams(next));
		this._disposables.push(() => subscription.unsubscribe());

		this._gridData.context.executeCommand = async command => this.execute(command);
	}

	protected _applyQueryParams(query: { [key: string]: string | string[] }) {
		const queryParams = Utils.clone(query, true);
		const groupOperatorOr = queryParams && queryParams.hasOwnProperty('or!');
		if (groupOperatorOr) {
			delete queryParams['or!'];
		}
		const ids = this.bonding.filter.description.options.map(x => x.id);
		if (Object.keys(queryParams).some(x => x !== 'or!' && ids.indexOf(x) === -1)) {
			return false;
		}
		const serializer = new QueryParametersFilterSerializer(this.bonding.filter.description);
		const target = Utils.clone(this.bonding.filter.model || this.defaultFilter, true);
		this._preserveFixedFilters(target);
		this._mergeFilters(target, serializer.deserialize(queryParams, groupOperatorOr), this.fixedQueryParams);
		this.bonding.filter.onModelChange(target);
		return true;
	}

	private _isFilterNodeGroup<T>(node: FilterNode<T> | Filter<T>) {
		return node.hasOwnProperty('children');
	}

	private _forEachFilterNode<T>(node: FilterNode<T> | Filter<T>, visitor: (node: FilterNode<T> | Filter<T>) => void): void {
		visitor(node);
		if (this._isFilterNodeGroup(node)) {
			(node as FilterNode<T>).children.forEach(x => this._forEachFilterNode(x, visitor));
		}
	}

	private _preserveFixedFilters(model: FilterNode<FilterValue>) {
		this._forEachFilterNode(model, x => {
			if (this._isFilterNodeGroup(x)) {
				const group = x as FilterNode<FilterValue>;
				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let idx = group.children.length - 1; idx >= 0 ; idx--) {
					const child = group.children[idx];
					if (!this._isFilterNodeGroup(child) && !(child as Filter<FilterValue>).fixed
							|| this._isFilterNodeGroup(child) && (child as FilterNode<FilterValue>).children.length === 0) {
						group.children.splice(idx);
					}
				}
			}
		});
	}

	// FIXME: replace with FlatModelMerger
	private _mergeFilters(target: FilterNode<FilterValue>, src: FilterNode<FilterValue>, setFixed: boolean = true) {
		if (target.operator !== src.operator) {
			return;
		}
		const targetFilters = target.children.filter(x => !x.hasOwnProperty('children')) as any as Filter<FilterValue>[];
		const srcFilters = src.children.filter(x => !x.hasOwnProperty('children')) as any as Filter<FilterValue>[];
		for (const srcFilter of srcFilters) {
			const targetFilter = targetFilters.find(x => x.id === srcFilter.id);
			if (targetFilter != null) {
				if (targetFilter.operator === srcFilter.operator) {
					targetFilter.value = srcFilter.value;
					targetFilter.fixed = setFixed;
					targetFilter.disabled = setFixed;
				}
			}
			else {
				target.children.push(Object.assign(srcFilter, { fixed: setFixed, disabled: setFixed }));
			}
		}

		const secondLevelFilters = src.children.map(x => (x as FilterNode<FilterValue>).children)
			.filter(x => x != null && !x.hasOwnProperty('children'))
			.map(filters => (filters as Filter<FilterValue>[]).map(x => x.id));
		const secondLevelIds = Utils.array.unique(Utils.array.flatten(secondLevelFilters));

		const dstFilters = target.children.filter(x => !x.hasOwnProperty('children')
				&& secondLevelIds.indexOf((x as Filter<FilterValue>).id) === -1);
		const srcGroups = src.children.filter(x => x.hasOwnProperty('children'));
		srcGroups.forEach(group =>
			(group as FilterNode<FilterValue>).children.forEach(y =>
				Object.assign(y, { fixed: setFixed, disabled: setFixed })));
		target.children = dstFilters.concat(srcGroups);
	}

	public ngOnInit() {
		this.bonding = this._createGridBonding();

		if (this.gridSetting != null) {
			this.gridSetting = GridModelBuilder.from(this.gridSetting, this.bonding.datagrid.description);
		}
		this.bonding.datagrid.modelPool.unshift({
			getter: () => this.gridSetting,
			setter: (value, active) => {
				if (active || this.gridSetting == null) {
					this.gridSetting = this.gridSetting == null ? Utils.clone(value, true) : value;
				}
			}
		});
		let workingFilterModel: FilterModel = Utils.clone(this.bonding.filter.model, true);
		if (this.sessionFilter != null) {
			workingFilterModel = FilterModelBuilder.from(this.sessionFilter, this.bonding.filter.description);
			this.sessionFilter = Utils.clone(workingFilterModel, true);
		}
		else if (this.defaultFilter != null) {
			workingFilterModel = FilterModelBuilder.from(this.defaultFilter, this.bonding.filter.description);
			this.defaultFilter = Utils.clone(workingFilterModel, true);
		}
		workingFilterModel = FilterModelBuilder.ensureFixed(workingFilterModel, this.bonding.filter.model);
		if (this.defaultFilter == null) {
			this.defaultFilter = Utils.clone(this.bonding.filter.model, true);
		}
		this.bonding.filter.modelPool.unshift({
			getter: () => workingFilterModel,
			setter: (value, active) => {
				if (active || this.sessionFilter == null) {
					workingFilterModel = workingFilterModel == null ? Utils.clone(value, true) : value;
					this.sessionFilter = Utils.clone(workingFilterModel, true);
				}
			}
		});

		const subscription = observableCombineLatest(this.resolve.concat(this._gridData.resolve))
			.pipe(Rx.defaultIfEmpty())
			.subscribe(() => {
				if (Object.keys(this._route.snapshot.queryParams).length === 0 || !this._applyQueryParams(this._route.snapshot.queryParams)) {
					this.reload();
				}
			});
		this._disposables.push(() => subscription.unsubscribe());

		this.bonding.rowActivate.subscribe((x: any) => this.rowActivate.emit(x));
		this.bonding.selectedChanged.subscribe((x: any) => this.selectedChanged.emit(x));
	}

	public ngOnDestroy() {
		super.ngOnDestroy();
		this._disposables.forEach(x => x());
	}

	public onDefaultFilterChange(model: FilterModel) {
		this.defaultFilter = model;
	}

	public routerReload(change: boolean) {
		if (this.bonding) {
			this.reload();
		}
	}

	public reload() {
		const subscription = this.bonding.fetch();
		this._disposables.push(() => subscription.unsubscribe());
	}

	// should be moved to more specific class
	protected _fetcherWithError(context: UriContext): Observable<T[]> {
		return this._fetcher(context).pipe(Rx.catchError((err: Response) => {
			this._toast.show(this._i18n.t('Loading failed.'), '(' + err.status + ') ' + err.statusText);
			throwError(err);
			return of([] as T[]);
		}));
	}

	protected abstract _fetcher(context: UriContext): Observable<T[]>;

	protected _createGridBonding() {
		return new GridDataBonding<T>(this._injector, this._fetcherWithError.bind(this), this._gridData);
	}

	protected _extendContextQuery(context: UriContext, query: { [key: string]: any }) {
		const result = Object.assign({ query: {} as any }, context);
		Object.assign(result.query, query);
		if (result.query.hasOwnProperty('select')) {
			delete result.query.with;
			delete result.query.only;
		}
		return result;
	}

	protected async _navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		extras = extras || {};
		if (this._route.snapshot.data.hasOwnProperty('relativeTo')) {
			extras.relativeTo = this._route.snapshot.data['relativeTo'](extras.relativeTo || this._route);
		}
		if (this._route.snapshot.data.hasOwnProperty('prefixCommands')) {
			commands = this._route.snapshot.data['prefixCommands'](this._route).concat(commands);
		}
		return this._router.navigate(commands, extras);
	}

	protected async _newItem(item?: T) {
		return this._navigate(item == null ? ['new'] : [(item as IUid).uid || item.id, 'new'], {
			relativeTo: this._route,
			queryParamsHandling: item == null ? 'preserve' : ''
		});
	}

	protected async _editItems(items: T[]) {
		if (items.length > 1) {
			const id = items.map(x => (x as IUid).uid || x.id);
			const token = this._storage.setItem(null, { expire: null, data: { id } });
			return this._navigate(['edit'], {
				relativeTo: this._route,
				queryParams: { token }
			});
		}
		else if (items.length === 1) {
			return this._navigate([(items[0] as IUid).uid || items[0].id, 'edit'], {
				relativeTo: this._route
			});
		}
		return Promise.resolve(false);
	}

	protected async _deleteItems(items: T[]) {
		if (await this._confirmDelete(items)) {
			const requests$ = items.map(x => this._removeItem(x));
			return observableCombineLatest(requests$).toPromise(Promise).then(
				() => {
					this.bonding.datagrid.onSelectedChange((this.bonding.datagrid.selected || [])
						.filter(x => items.indexOf(x) === -1));
					this.bonding.datagrid.onDataSourceChange((this.bonding.datagrid.dataSource || [])
						.filter(x => items.indexOf(x) === -1));
					this._toast.show(this._i18n.t('Item removed.'), this._i18n.t('{{ count }} items has been successfully removed!', { count: items.length }));
					return true;
				},
				error => {
					this._toast.show(this._i18n.t('Remove failed.'), this._retriveErrorMessage(error));
					return false;
				});
		}
		return false;
	}

	protected async _confirmDelete(items: T[]) {
		const result = await this._confirmation.show(
			this._i18n.t('Delete {{ count }} items?', { count: items.length }),
			this._i18n.t('Are you sure?\nRemoving is inreversible operation.'), [
				{ name: this._i18n.t('Delete'), classes: ['danger'], result: 'delete' },
				{ name: this._i18n.t('Cancel') }
			]);
		return result === 'delete';
	}

	protected _retriveErrorMessage(error: any) {
		if (Utils.isString(error.statusText)) {
			const msg = error.statusText as string;
			if (msg.indexOf('violates foreign key') !== -1) {
				return this._i18n.t('Another items depends on this item ({{ msg }}).', { msg });
			}
		}
		return error.statusText || error.message || error;
	}

	protected _removeItem(item: T): Observable<any> {
		return this._store.remove(item.id);
	}

	public async execute(command: ActionCommand<T>) {
		const event = new CommantEvent(command);
		this.executed.emit(event);
		if (event.prevented) {
			return Promise.resolve(true);
		}
		switch ((command as (ActionItemCommand<T> | ActionItemsCommand<T>)).name) {
			case 'new':
			case 'copy':
				return this._newItem((command as ActionItemCommand<T>).item);
			case 'edit':
				return this._editItems(
					'item' in command ? [command.item] : (command as ActionItemsCommand<T>).items
				);
			case 'delete':
				return this._deleteItems((command as ActionItemsCommand<T>).items);
		}
		const { commands, extras } = command as ActionNavigateCommand<T>;
		return this._navigate(commands, Object.assign({ relativeTo: this._route }, extras));
	}

	/**
	 * @deprecated use execute({ name: 'new' | name: 'copy', item: ... })
	 * @suppress {duplicate}
	 */
	public async newItem(item?: T) {
		console.log("DEPRECATED: AbstractGridViewComponent.newItem - use AbstractGridViewComponent.execute(/ name: 'new' | name: 'copy', item: ... })");
		return this._newItem(item);
	}

	/**
	 * @deprecated use execute({ name: 'edit', item: ... })
	 * @suppress {duplicate}
	 */
	public async editItem(item: T) {
		console.log("DEPRECATED: AbstractGridViewComponent.editItem - use AbstractGridViewComponent.execute({ name: 'edit', item: ... })");
		return this._editItems([item]);
	}

	/**
	 * @deprecated use execute({ name: 'delete', items: [...] })
	 * @suppress {duplicate}
	 */
	public async deleteItems(items: T[]) {
		console.log("DEPRECATED: AbstractGridViewComponent.deleteItems - use AbstractGridViewComponent.execute({ name: 'delete', items: [...] })");
		return this._deleteItems(items);
	}

	/**
	 * @deprecated use AbstractGridData<T>.optionsFromResource
	 */
	public optionsFromResource<U>(resource: AbstractResource<U>, labelProperty: keyof U | { (item: U): string }, valueProperty: keyof U, context?: { [key: string]: any }, allowNull: boolean = true): Observable<{ label: string, value: string | number }[]> {
		console.log("DEPRECATED: AbstractGridViewComponent.optionsFromResource - use AbstractGridData<T>.optionsFromResource");
		return this._gridData.optionsFromResource(resource, labelProperty, valueProperty, context, allowNull);
	}

	/**
	 * @deprecated use AbstractGridData<T>.optionsFromEnum
	 */
	public optionsFromEnum<U extends {} | string[]>(enumeration: U): { label: string, value: string }[] {
		console.log("DEPRECATED: AbstractGridViewComponent.optionsFromEnum - use AbstractGridData<T>.optionsFromEnum");
		return this._gridData.optionsFromEnum(enumeration);
	}
}
