import {Component, ChangeDetectionStrategy, ViewEncapsulation, Input, HostBinding, ContentChildren, QueryList, forwardRef} from '@angular/core';
import {BooleanField} from 'kn-common';
import {KnOption} from './option.component';

@Component({
	selector: 'kn-optgroup',
	templateUrl: 'optgroup.html',
	styleUrls: ['optgroup.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnOptgroup {
	@Input() public label: string;
	@Input() @BooleanField() public disabled: boolean = false;

	@ContentChildren(forwardRef(() => KnOption))
	public options: QueryList<KnOption>;

	@HostBinding('hidden')
	public get hidden() {
		if (this.options == null) {
			return true;
		}
		return !this.options.some(x => !x.hidden);
	}
}
