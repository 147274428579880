import {AbstractCloner} from 'common-web/rest';
import {DefaultLoader} from '../../../clone-tools/default-loader.type';
import {Database} from 'common-web/model';

export class ClonerDescriptor {
	public name: string;
	public icon: string;
	public description: string;
	public note: string;
	public cloners: AbstractCloner[];
}

export class LoaderDescriptor {
	public name: string;
	public icon: string;
	public description: string;
	public note: string;
	public loaders: DefaultLoader[];
}

export type ClonerDescriptorFactory = (target: Database, source: Database[]) => ClonerDescriptor;
export type LoaderDescriptorFactory = (target: Database, source: Database[]) => LoaderDescriptor;
