import {Component, Input, Output, OnChanges, SimpleChanges, EventEmitter, HostBinding, ViewChild} from '@angular/core';
import {Description, Model, Column, DataSource, RowItem} from './types';
import {KnTable} from './parts/table.component';
import {getSorting, dataSourceSort} from './utils';
import {AbstractConcreteDatagridBond} from './bond/abstract-concrete-datagrid-bond';
import {Utils} from 'kn-utils';

export abstract class KnDatagrid {
	protected abstract _table: KnTable;

	public refreshRows(rebuildTree?: boolean, refreshItems?: RowItem[]) {
		this._table.refreshRows(rebuildTree, refreshItems);
	}

	public scrollToView(item: RowItem) {
		this._table.scrollToView(item);
	}
}

@Component({
	selector: 'kn-datagrid:not([bond])',
	template: `
	<table knTable
			[description]="description"
			[model]="model"
			(modelChange)="onModelChange($event)"
			[dataSource]="sortedDataSource"
			(dataSourceChange)="dataSourceChange.emit($event)"
			[expanded]="expanded"
			(expandedChange)="expandedChange.emit($event)"
			[selected]="selected"
			(selectedChange)="selectedChange.emit($event)"
			(rowSelect)="rowSelect.emit($event)"
			(rowActivate)="rowActivate.emit($event)"></table>`,
	styles: [`:host { position: relative; }`]
})
export class KnDatagridPropertyBinding extends KnDatagrid implements OnChanges {
	private _dataSource: DataSource<RowItem>;
	private _lastSorting: Column<RowItem>[] = [];
	public sortedDataSource: DataSource<RowItem>;

	@ViewChild(KnTable, { static: true })
	protected _table: KnTable;

	@Input() public description: Description<RowItem>;

	@Input() public model: Model;
	@Output() public modelChange = new EventEmitter<Model>();

	@Input() public set dataSource(value: DataSource<RowItem>) {
		this._dataSource = value;
	}

	public get dataSource(): DataSource<RowItem> {
		return this.sortedDataSource;
	}

	@Output() public dataSourceChange = new EventEmitter<DataSource<RowItem>>();

	@Input() public expanded: RowItem[];
	@Output() public expandedChange = new EventEmitter<RowItem[]>();

	@Input() public selected: RowItem[];
	@Output() public selectedChange = new EventEmitter<RowItem[]>();

	@Output() public rowSelect: EventEmitter<RowItem> = new EventEmitter<RowItem>();
	@Output() public rowActivate: EventEmitter<RowItem> = new EventEmitter<RowItem>();

	@Input() public sortingFunction: (dataSource: DataSource<RowItem>, sortingColumns: Column<RowItem>[]) => DataSource<RowItem>;

	public ngOnChanges(changes: SimpleChanges) {
		const properties = ['description', 'model', 'dataSource'];
		if (['sortingFunction', ...properties].some(x => x in changes)
				&& this.description != null && this.model != null && this._dataSource != null) {
			this._update();
		}
	}

	public onModelChange(event: Model) {
		this._update();
		this.modelChange.emit(event);
	}

	private _update() {
		const sorting = getSorting(this.model.columns, this.description);

		const sortedExternaly = this.sortingFunction != null && !Utils.isFunction(this.sortingFunction);
		const sortingNeeded = !sortedExternaly
				&& sorting.length > 0
				&& (sorting.length !== this._lastSorting.length
				|| sorting.some((x, index) => x !== this._lastSorting[index]));

		if (sortingNeeded) {
			const sotingFunction = this.sortingFunction || dataSourceSort;
			this.sortedDataSource = sotingFunction(this._dataSource.slice(0), sorting);
			this._lastSorting = sorting;
		}
		else if (this.sortedDataSource === this._dataSource) {
			this.sortedDataSource = this._dataSource.slice(0);
		}
		else {
			this.sortedDataSource = this._dataSource;
		}

		if (sortedExternaly) {
			this._lastSorting = [];
		}
	}
}

@Component({
	selector: 'kn-datagrid[bond]',
	template: `
	<table knTable
			[description]="bond.description"
			[model]="bond.model"
			(modelChange)="bond.onModelChange($event)"
			[dataSource]="bond.dataSource"
			(dataSourceChange)="bond.onDataSourceChange($event)"
			[expanded]="bond.expanded"
			(expandedChange)="bond.onExpandedChange($event)"
			[selected]="bond.selected"
			(selectedChange)="bond.onSelectedChange($event)"
			(rowSelect)="bond.onRowSelect($event)"
			(rowActivate)="bond.onRowActivate($event)"></table>`,
	styles: [`:host { position: relative; }`]
})
export class KnDatagridServiceBinding extends KnDatagrid implements OnChanges {
	@Input() public bond: AbstractConcreteDatagridBond<RowItem>;

	@ViewChild(KnTable, { static: true })
	protected _table: KnTable;

	@HostBinding('class.loading')
	public get loading() {
		return this.bond && this.bond.loading;
	}

	public ngOnChanges(changes: SimpleChanges) {
		if ('bond' in changes) {
			const bond = changes['bond'].currentValue as AbstractConcreteDatagridBond<RowItem>;
			bond.registerDatagrid(this);
		}
	}
}
