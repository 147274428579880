import {ModuleWithProviders} from '@angular/core';
import {KnAuthorizationModule, Permissions} from 'kn-user';

export interface ResourcePermissionMap {
	resource: string;
	permission: string;
}

export function mapResourcePermission(resource: string, permission: string) {
	return ({
			resource: resource,
			accessControl: 'krest',
			mapping: {
				permissionNameReplacement: permission,
				create: [{ permission: 'C' }],
				read: [{ permission: 'R' }],
				update: [{ permission: 'U' }],
				delete: [{ permission: 'D' }]
			}
		});
}

export function viewEditAuthorizationHelper(scope: string, permission: string, mainPermission?: ResourcePermissionMap, itemsPermission?: ResourcePermissionMap): ModuleWithProviders<KnAuthorizationModule> {
	return KnAuthorizationModule.forChild([
		{
			resource: 'item',
			accessControl: 'krest',
			mapping: {
				permissionNameReplacement: permission,
				new: [{ permission: 'C' }],
				copy: [{ permission: 'CR' }],
				edit: [{ permission: 'R' }],
				remove: [{ permission: 'D' }]
			}
		},
		...(mainPermission ? [mapResourcePermission(mainPermission.resource, mainPermission.permission)] : []),
		...(itemsPermission ? [mapResourcePermission(itemsPermission.resource, itemsPermission.permission)] : [])
	] as Permissions, scope);
}
