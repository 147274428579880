import {Component, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {UriContext} from 'kn-http';
import {UserSetting, UserSettingsFactory} from 'kn-user-settings';
import {Model as GridModel} from 'kn-datagrid';
import {AbstractGridViewComponent} from 'common-web/grid';
import {UserEditService} from './user-edit.service';
import {UsersGridData} from './users-grid-data.service';
import {UsersResourceService} from '../../services/users/users-resource.service';
import * as Model from 'common-web/model';

@Component({
	selector: 'kn-users-view',
	templateUrl: 'users-view.html'
})
export class UsersViewComponent extends AbstractGridViewComponent<Model.User> {
	@UserSetting('UsersViewComponent.gridSetting@0.2.0') public gridSetting: GridModel;
	public view = 'users';

	public constructor(
			injector: Injector,
			userSettingsFactory: UserSettingsFactory,
			private readonly _usersResource: UsersResourceService,
			gridData: UsersGridData,
			userEditService: UserEditService) {
		super(injector, gridData, userEditService);
		userSettingsFactory.linkComponent(this.constructor);
	}

	protected _fetcher(context: UriContext): Observable<Model.User[]> {
		return this._usersResource.query(context);
	}

	protected _removeItem(item: Model.User): Observable<any> {
		return this._store.remove(item.uid);
	}
}
