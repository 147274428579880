import {
	forwardRef,
	Component,
	ViewEncapsulation,
	HostBinding,
	Input,
	ViewChild,
	ElementRef,
	EventEmitter,
	Provider,
	Output,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	ChangeDetectionStrategy
} from '@angular/core';
import {NG_VALUE_ACCESSOR, ControlValueAccessor} from '@angular/forms';
import {Subscription, timer as observableTimer} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {BooleanField} from 'kn-common';
import {ContainedFocus} from '../../contained-focus';
import {AbstractOptionsHost} from '../options/abstract-options-host';
import {DatepickerType, KnDatepicker} from '../datepicker/datepicker.component';

export const KN_DATEINPUT_CONTROL_VALUE_ACCESSOR: Provider = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => KnDateInput), // eslint-disable-line no-use-before-define,@typescript-eslint/no-use-before-define
	multi: true
};

export const KN_DATEINPUT_OPTIONS_HOST: Provider = {
	provide: AbstractOptionsHost,
	useExisting: forwardRef(() => KnDateInput) // eslint-disable-line no-use-before-define, @typescript-eslint/no-use-before-define
};

let nextUniqueId = 0;

@Component({
	selector: 'kn-date-input',
	templateUrl: 'dateinput.html',
	styleUrls: ['dateinput.css'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		'(click)' : '!focused && focus()'
	},
	providers: [
		KN_DATEINPUT_CONTROL_VALUE_ACCESSOR,
		KN_DATEINPUT_OPTIONS_HOST
	]
})
export class KnDateInput extends AbstractOptionsHost implements OnInit, OnDestroy, ControlValueAccessor {
	public static readonly NowValue: string = null;
	public static readonly EmptyValue: string = undefined;

	private readonly _containedFocus: ContainedFocus;
	private _timerSubscription: Subscription;
	private _openedTouch: boolean = false;

	/** Callback registered via registerOnTouched (ControlValueAccessor) */
	private _onTouchedCallback: () => void = () => { /* intentionally empty */ };
	/** Callback registered via registerOnChange (ControlValueAccessor) */
	private _onChangeCallback: (_: any) => void = () => { /* intentionally empty */ };

	@HostBinding('class.focused')
	public get focused() {
		return this._containedFocus.focused;
	}

	public get dateInputId(): string {
		return `${this.id}-date`;
	}

	public get timeInputId(): string {
		return `${this.id}-time`;
	}

	public get inputLabelForId(): string {
		return this.type === DatepickerType.Time ? this.timeInputId : this.dateInputId;
	}

	@HostBinding('id')
	@Input() public id: string = `kn-dateinput-${nextUniqueId++}`;

	@Input() @BooleanField() public nowAllowed: boolean = false;
	@Input() @BooleanField() public autofocus: boolean = false;
	@Input() @BooleanField() public required: boolean = false;
	@Input() @BooleanField() public hideCalendar: boolean = false;
	@Input() @BooleanField() public hideEmptyLabel: boolean = false;
	@Input() public step: number;
	@Input() public tabindex: number = null;
	@Input() public type: string = DatepickerType.Date;
	@Input() public name: string = null;
	@Input() public min: string = null;
	@Input() public max: string = null;

	public get minTime(): string {
		if (this.min == null) {
			return null;
		}
		const minValue = this._parseValue(this.min);
		if (this.dateValue <= minValue.date) {
			return minValue.time;
		}
		return '23:59';
	}

	public get maxTime(): string {
		if (this.max == null) {
			return null;
		}
		const maxValue = this._parseValue(this.max);
		if (this.dateValue >= maxValue.date) {
			return maxValue.time;
		}
		return '23:59';
	}

	@HostBinding('class.readonly')
	@Input() @BooleanField() public readonly: boolean = false;

	@HostBinding('class.disabled')
	@Input() @BooleanField() public disabled: boolean = false;

	@HostBinding('class.loading')
	@Input() public loading: boolean = false;

	public get value(): string {
		const tmp = this._constructValue(this.dateValue, this.timeValue, this.zoneValue);
		return tmp;
	}

	@Input() public set value(v: string) {
		if (this._setValue(v, true)) {
			this._onChangeCallback(this.value);
			this._cdr.markForCheck();
		}
	}

	private _nowDateLock: boolean = false;
	@Input() @BooleanField() public set nowDateLock(v: boolean) {
		if (this._nowDateLock !== v) {
			this._nowDateLock = v;
			if (v && this.nowAllowed) {
				this._startTimer();
			}
			else {
				this.close();
				this._stopTimer();
			}
			this._onChangeCallback(this.value);
			this._cdr.markForCheck();
		}
	}

	public get nowDateLock(): boolean {
		return this._nowDateLock;
	}

	public get hasTimeField(): boolean {
		return this.type !== DatepickerType.Date;
	}

	public get hasDateField(): boolean {
		return this.type !== DatepickerType.Time;
	}

	@Output('blur') public blurEvent = new EventEmitter<FocusEvent>();
	@Output('focus') public focusEvent = new EventEmitter<FocusEvent>();
	@Output('change') public changeEvent = new EventEmitter<void>();
	@Output() public valueChange = new EventEmitter<string>();

	@ViewChild('inputDate', { static: true })
	public _inputElementDate: ElementRef;

	@ViewChild('inputTime', { static: true })
	public _inputElementTime: ElementRef;

	@ViewChild('pickerDate', { static: true, read: ElementRef })
	public _pickerElementDate: ElementRef;

	@ViewChild('pickerTime', { static: true, read: ElementRef })
	public _pickerElementTime: ElementRef;

	@ViewChild('pickerDate', { static: true })
	public _pickerDate: KnDatepicker;

	@ViewChild('pickerTime', { static: true })
	public _pickerTime: KnDatepicker;

	@ViewChild('knLabelContent', { static: true })
	private readonly _knLabel: ElementRef;

	protected _dateValue: string = KnDateInput.EmptyValue;
	public get dateValue(): string {
		return this._dateValue;
	}
	public set dateValue(value: string) {
		if (value !== '') {
			this._dateValue = value;
		}
	}

	public _timeValue: string = KnDateInput.EmptyValue;
	public get timeValue(): string {
		return this._timeValue;
	}
	public set timeValue(value: string) {
		if (value !== '') {
			this._timeValue = value;
		}
	}

	public zoneValue: number = null;
	public hasKnLabel: boolean = true;

	public constructor(cdr: ChangeDetectorRef) {
		super(cdr);
		this._containedFocus = new ContainedFocus(
			event => {
				this.focusEvent.emit(event);
				this.open();
			},
			event => {
				this._onTouchedCallback();
				this.blurEvent.emit(event);
				this.close();
				this._cdr.markForCheck();
			}
		);
	}

	public ngOnInit() {
		this._containedFocus.register(this._inputElementDate);
		this._containedFocus.register(this._inputElementTime);
		this._containedFocus.register(this._pickerElementDate, true);
		this._containedFocus.register(this._pickerElementTime, true);
	}

	public ngOnDestroy() {
		super.ngOnDestroy();
		this._stopTimer();
		this._containedFocus.unregisterAll();
	}

	public ngAfterViewInit() {
		super.ngAfterViewInit();
		this.hasKnLabel = !this.hideEmptyLabel || this._knLabel && this._knLabel.nativeElement && this._knLabel.nativeElement.children.length > (this.required ? 1 : 0);
		this._cdr.detectChanges();
	}

	public get multiple() {
		return false;
	}

	public registerChild(element: ElementRef) {
		this._containedFocus.register(element, true);
		return () => this._containedFocus.unregister(element);
	}

	public focus() {
		/* empty */
	}

	public focusDate() {
		if (this._inputElementDate != null) {
			this._inputElementDate.nativeElement.focus();
		}
	}

	public focusTime() {
		if (this._inputElementTime != null) {
			this._inputElementTime.nativeElement.focus();
		}
	}

	public selectDate() {
		if (this._inputElementDate != null) {
			this._inputElementDate.nativeElement.select();
		}
	}

	public selectTime() {
		if (this._inputElementTime != null) {
			this._inputElementTime.nativeElement.select();
		}
	}

	protected _selectOptionInternal(value: string) {
		this.value = value;
		this.valueChange.emit(this.value);
		this.changeEvent.emit();
		this._onTouchedCallback();
	}

	public openDate(touch: boolean = false) {
		if (this.nowDateLock && this.nowAllowed || this.readonly) {
			return;
		}
		this.focusDate();
		this._openedTouch = touch;
		this._pickerDate.type = DatepickerType.Date;
		this._pickerDate.value = this.dateValue;
		this._pickerDate.open();
		this.emitQuery(this.value);
	}

	public openTime(touch: boolean = false) {
		if (this.nowDateLock && this.nowAllowed || this.readonly) {
			return;
		}
		this.focusTime();
		this._openedTouch = touch;
		this._pickerTime.type = DatepickerType.Time;
		this._pickerTime.value = this.timeValue;
		this._pickerTime.open();
		this.emitQuery(this.value);
	}

	public close() {
		super.close();
		this.closeDate();
		this.closeTime();
	}

	public closeDate() {
		this._pickerDate.close();
		this.emitQuery(this.value);
	}

	public closeTime() {
		this._pickerTime.close();
		this.emitQuery(this.value);
	}

	/** @internal */
	public handleFilterInput(query: string) {
		this.emitQuery(query);
	}

	/** @internal */
	public handleChange(event: Event) {
		this.valueChange.emit(this.value);
		this.changeEvent.emit();
		this._onTouchedCallback();

		this._onChangeCallback(this.value);
		this._cdr.markForCheck();
	}

	/** @internal */
	public handleKeyup(event: KeyboardEvent) {
		/* empty */
	}

	/** @internal */
	public handleFocus(event: KeyboardEvent) {
		if (this._inputElementDate != null && event.target === this._inputElementDate.nativeElement) {
			this.focusDate();
		}
		else if (this._inputElementTime != null && event.target === this._inputElementTime.nativeElement) {
			this.focusTime();
		}
	}

	/** @internal */
	public handleKeydown(event: KeyboardEvent) {
		if (this._inputElementDate != null && event.target === this._inputElementDate.nativeElement) {
			switch (event.key || (event as any).code) {
				case 'ArrowDown':
					this.open();
					return;
				case 'Escape':
					this.close();
					return;
			}
		}

		if (this._inputElementTime != null && event.target === this._inputElementTime.nativeElement && this.step != null) {
			switch (event.key || (event as any).code) {
				case 'ArrowDown':
					event.preventDefault();
					this.value = this._stepValue(this.value, this.step, false);
					return;
				case 'ArrowUp':
					event.preventDefault();
					this.value = this._stepValue(this.value, this.step, true);
					return;
			}
		}
	}

	/** Part of ControlValueAccessor */
	public writeValue(value: any) {
		this._setValue(value, true);
		this.changeEvent.emit();
		this._update();
	}

	/** Part of ControlValueAccessor */
	public registerOnChange(fn: any) {
		this._onChangeCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public registerOnTouched(fn: any) {
		this._onTouchedCallback = fn;
	}

	/** Part of ControlValueAccessor */
	public setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this._cdr.markForCheck();
	}

	/** @internal */
	public handleOpenDateClick(event: Event) {
		if (this.disabled || (this.nowDateLock && this.nowAllowed)) {
			return;
		}
		this._openedTouch = false;
		event.stopPropagation();
		this.openDate();
	}

	/** @internal */
	public handleOpenTimeClick(event: Event) {
		if (this.disabled || (this.nowDateLock && this.nowAllowed)) {
			return;
		}
		this._openedTouch = false;
		event.stopPropagation();
		this.openTime();
	}

	/** @internal */
	public handleClearClick(event: Event) {
		if (this.disabled || (this.nowDateLock && this.nowAllowed)) {
			return;
		}
		event.stopPropagation();
		this.dateValue = KnDateInput.EmptyValue;
		this.timeValue = KnDateInput.EmptyValue;
		this.changeEvent.emit();
		this.valueChange.emit(this.value);
		this._onChangeCallback(this.value);
		this._update();
	}

	/** @internal */
	public handleTap(event: Event) {
		event.preventDefault();
		if (this._inputElementDate != null && event.target === this._inputElementDate.nativeElement) {
			this.openDate(true);
		}
		else if (this._inputElementTime != null && event.target === this._inputElementTime.nativeElement) {
			this.openTime(true);
		}
	}

	public handleDatePickerValueChange(event: string) {
		if (this.dateValue !== event) {
			this.dateValue = event;
			this.changeEvent.emit();
			this.valueChange.emit(this.value);
			this._onChangeCallback(this.value);
			this._update();
		}
		this._enterTimeIfEmpty(this._openedTouch);
	}

	public handleTimePickerValueChange(event: string) {
		if (this.timeValue !== event) {
			this.timeValue = event;
			this.changeEvent.emit();
			this.valueChange.emit(this.value);
			this._onChangeCallback(this.value);
			this._update();
		}
		this._enterDateIfEmpty(this._openedTouch);
	}

	public handleNowDateLockChange(checked: boolean) {
		this.nowDateLock = checked;
		this._onTouchedCallback();
	}

	private _enterTimeIfEmpty(showPicker: boolean = false) {
		if (this.timeValue === KnDateInput.EmptyValue && this.hasTimeField) {
			this.value = this._constructValue(this.dateValue, '00:00', this.zoneValue);
			this.focusTime();
			if (showPicker) {
				this.openTime(showPicker);
			}
		}
	}

	private _enterDateIfEmpty(showPicker: boolean = false) {
		if (this.dateValue === KnDateInput.EmptyValue && this.hasDateField) {
			this.value = this._constructValue(Utils.date.toIso8601(new Date(), 'date') , this.timeValue, this.zoneValue);
			this.focusDate();
			if (showPicker) {
				this.openDate(showPicker);
			}
		}
	}

	private _parseValue(value: string) {
		let date: string = KnDateInput.EmptyValue;
		let time: string = KnDateInput.EmptyValue;
		let zone: number = null;
		if (value != null) {
			const ti = value.indexOf('T');
			switch (this.type) {
				case DatepickerType.Date:
					date = value.substring(0, ti < 0 ? value.length : ti);
					break;
				case DatepickerType.Time:
					time = value.substr(ti < 0 ? 0 : ti + 1, 5);
					break;
				case DatepickerType.DatetimeLocal:
					date = value.substring(0, ti < 0 ? value.length : ti);
					time = value.substr(ti < 0 ? 0 : ti + 1, 5);
					break;
				case DatepickerType.Datetime:
					const dateValue = Utils.date.fromIso8601(value);
					date = Utils.date.toIso8601(dateValue, 'date');
					time = Utils.date.toIso8601(dateValue, 'time');
					time = time.substr(0, Math.min(5, time.length));
					if (this.type === DatepickerType.Datetime) {
						zone = -1 * dateValue.getTimezoneOffset();
					}
					break;
			}
		}
		return {date, time, zone};
	}

	private _constructValue(date: string, time: string, zoneOffset: number) {
		switch (this.type) {
			case DatepickerType.Date:
				return date === '' ? KnDateInput.EmptyValue : date;
			case DatepickerType.Time:
				return time === '' ? KnDateInput.EmptyValue : time;
			default:
				if (date == null || time == null || date === '' || time === '') {
					return KnDateInput.EmptyValue;
				}
				else {
					let zone = '';
					if (this.type === DatepickerType.Datetime) {
						if (zoneOffset == null) {
							zone = Utils.date.getTimezone(Utils.date.fromIso8601(date + 'T' + time));
						}
						else if (zoneOffset === 0) {
							zone = 'Z';
						}
						else {
							const absOffset = Math.abs(zoneOffset);
							zone = (zoneOffset >= 0 ? '+' : '-') + ('0' + Math.floor(absOffset / 60)).slice(-2) + ':' + ('0' + (absOffset % 60)).slice(-2);
						}
					}
					return date + 'T' + time + zone;
				}
		}
	}

	private _equalValues(a: string, b: string) {
		if ((a === KnDateInput.EmptyValue || a === KnDateInput.NowValue) && a === b) {
			return true;
		}
		if (a == null || b == null) {
			return false;
		}
		const aP = this._parseValue(a);
		const bP = this._parseValue(b);

		return this._constructValue(aP.date, aP.time, aP.zone) === this._constructValue(bP.date, bP.time, bP.zone);
	}

	private _setValue(v: string, switchNow: boolean): boolean {
		const nowValue = this._nowFromValue(v);
		let valueSet = false;
		if (!this._equalValues(v, this.value) || switchNow && nowValue !== this.nowDateLock) {
			valueSet = true;
			if (v != null) {
				const p = this._parseValue(v);
				this.dateValue = p.date;
				this.timeValue = p.time;
				this.zoneValue = p.zone;
			}
			else if (this.nowAllowed && v === KnDateInput.NowValue) {
				this.dateValue = KnDateInput.NowValue;
				this.timeValue = KnDateInput.NowValue;
				this.zoneValue = null;
			}
			else if (!this.required) {
				this.dateValue = KnDateInput.EmptyValue;
				this.timeValue = KnDateInput.EmptyValue;
				this.zoneValue = null;
			}
			else {
				valueSet = false;
			}
			if (this.nowAllowed && switchNow) {
				this.nowDateLock = nowValue;
			}
		}
		return valueSet;
	}

	private _nowFromValue(v: string) {
		if (this.nowAllowed && v === KnDateInput.NowValue) {
			return true;
		}
		return false;
	}

	private _stepValue(value: string, step: number, up: boolean) {
		const p = this._parseValue(value);
		if (p == null || p.time == null) {
			return value;
		}
		return this._formatTime((this._toMs(p.time) + (up ? 1 : -1) * step * 1000) % (24 * 60 * 60 * 1000));

	}

	private _toMs(value: string) {
		try {
			if (value == null) {
				return 0;
			}
			const d = Utils.date.fromIso8601('0001-01-01T' + value);
			return ((d.getHours() * 60 + d.getMinutes()) * 60 + d.getSeconds()) * 1000;
		}
		catch (error) {
			return 0;
		}
	}

	private _formatTime(ms: number) {
		return Utils.date.toIso8601(
			new Date(0, 0, 0, 0, 0, 0, ms),
			'time'
		).substr(0, 5);
	}

	private _startTimer() {
		this._stopTimer();
		this._timerSubscription = observableTimer(0, 1000)
			.pipe(Rx.map(next => new Date()), Rx.tap(next => next.setSeconds(0, 0)))
			.subscribe(next => {
				if (this._setValue(Utils.date.toIso8601(next, this.type === DatepickerType.Datetime ? 'full' : this.type), false)) {
					this._onChangeCallback(this.value);
					this._cdr.markForCheck();
				}
			});
	}

	private _stopTimer() {
		this._timerSubscription && this._timerSubscription.unsubscribe();
		this._timerSubscription = null;
	}
}
