import {Component, Input} from '@angular/core';
import {MenuColumnEntry} from './column-context-menu.component';
import {ValueResolveUtils} from '../../internal-utils';

@Component({
	selector: 'kn-column-context-menu-node',
	template: `
		<ul>
			<ng-template ngFor let-node [ngForOf]="nodes">
				<li class="section" *ngIf="node.section"
						[ngClass]="getClasses(node)">
					<a class="expander" [class.expanded]="isExpanded(node)" (click)="toggleExpanded(node)">&nbsp;</a>
					<div class="subtree checkbox"
							[class.disabled]="!getVisibility(node)"
							[class.active]="getVisible(node)">
						<a (click)="setVisible(node, !getVisible(node))">{{ getName(node) }}</a>
						<kn-column-context-menu-node *ngIf="isExpanded(node)" [nodes]="node.children"></kn-column-context-menu-node>
					</div>
				</li>
				<li class="checkbox" *ngIf="node.column"
						[class.disabled]="!getVisibility(node)"
						[class.active]="getVisible(node)"
						[ngClass]="getClasses(node)">
					<a (click)="setVisible(node, !getVisible(node))">{{ getName(node) }}</a>
				</li>
			</ng-template>
		</ul>`
})
export class KnColumnContextMenuNode {
	private readonly _expandedNodes: MenuColumnEntry[] = [];

	@Input() public nodes: MenuColumnEntry[];

	public isExpanded(node: MenuColumnEntry) {
		return this._expandedNodes.indexOf(node) !== -1;
	}

	public toggleExpanded(node: MenuColumnEntry) {
		const index = this._expandedNodes.indexOf(node);
		if (index === -1) {
			this._expandedNodes.push(node);
		}
		else {
			this._expandedNodes.splice(index, 1);
		}
	}

	public getName(node: MenuColumnEntry) {
		return ValueResolveUtils.resolveName(node.column || node.section);
	}

	public getClasses(node: MenuColumnEntry) {
		if (node.column != null) {
			return ValueResolveUtils.resolveCellClasses(node.column);
		}
		return ValueResolveUtils.resolveSectionClasses(node.section);
	}

	public getVisibility(entry: MenuColumnEntry): boolean {
		return (entry.column || entry.section).description.visibility;
	}

	public getVisible(entry: MenuColumnEntry): boolean {
		if (entry.changes.hasOwnProperty('visible')) {
			return entry.changes['visible'];
		}
		return (entry.column || entry.section).model.visible;
	}

	public setVisible(entry: MenuColumnEntry, value: boolean): void {
		if (this.getVisibility(entry)) {
			entry.changes['visible'] = value;
		}
	}
}
