import {NgModule} from '@angular/core';
import {KnDraggableHandlerComponent} from './draggable-handler.component';

export const KN_DRAGGABLE_DIRECTIVES = [
	KnDraggableHandlerComponent
];

@NgModule({
	declarations: [KN_DRAGGABLE_DIRECTIVES],
	exports: [KN_DRAGGABLE_DIRECTIVES]
})
export class KnDraggableModule { }
