import {IterableDiffers, IterableDiffer, IterableChanges, IterableChangeRecord} from '@angular/core';
import {DataSource, Column, RowItem, CellContext} from '../../types';
import {Node} from '../../model/node';

export class NodeTreeBuilder<T extends RowItem> {
	private _differ: IterableDiffer<T>;
	private _root = Node.root<T>();
	private _groups: string[];

	public constructor(private readonly _differs: IterableDiffers) { }

	public build(columns: Column<T>[], dataSource: DataSource<T>): Node<T> {
		const groups = columns.filter(x => x.model.group).map(x => x.id);
		if (this._differ == null || this._groups.length !== groups.length
				|| this._groups.some((x, index) => x !== groups[index])) {
			this._groups = groups;
			this._root = Node.root<T>();
			this._differ = this._differs.find(dataSource).create<T>();
		}
		this._applyChanges(columns, this._differ.diff(dataSource));
		return this._root;
	}

	private _applyChanges(columns: Column<T>[], changes: IterableChanges<T>) {
		if (changes == null) {
			return;
		}

		const groups = columns.filter(column => column.model.group);

		let dirtyNodes = new Set<Node<T>>();
		const nodeIndexMap = new Map<Node<T>, number>();

		changes.forEachRemovedItem((record: IterableChangeRecord<T>) => {
			const node = this._getNodeByIndex(this._root, record.previousIndex) as Node<T>;
			if (node == null) {
				throw new Error('Node tree is corrupted.');
			}
			if (node.children && node.children.length) {
				throw new Error('Node tree is not leaf!');
			}
			nodeIndexMap.set(node, -1);
			dirtyNodes.add(node.parent);
			this._invalidateGroups(node.parent);
		});

		changes.forEachMovedItem((record: IterableChangeRecord<T>) => {
			const node = this._getNodeByIndex(this._root, record.previousIndex) as Node<T>;
			if (node == null) {
				throw new Error('Node tree is corrupted.');
			}
			if (node.children && node.children.length) {
				throw new Error('Node tree is not leaf!');
			}
			nodeIndexMap.set(node, record.currentIndex);
			dirtyNodes.add(node.parent);
		});

		changes.forEachAddedItem((record: IterableChangeRecord<T>) => {
			const path = this._getPath(record.item, groups);
			const parent = this._constructPathChain(this._root, path, groups);
			const node = Node.leaf<T>(record.item, parent);
			parent.children.push(node);
			nodeIndexMap.set(node, record.currentIndex);
			dirtyNodes.add(node.parent);
			this._invalidateGroups(node.parent);
		});

		nodeIndexMap.forEach((i, n) => {
			n.index = i;
		});

		while (dirtyNodes.size > 0) {
			dirtyNodes = this._applyGroupChanges(dirtyNodes);
		}

		this._updateGroups(this._root, columns);
	}

	private _getNodeByIndex(node: Node<T>, index: number): Node<T> | number {
		if (node.children.length > 0) {
			for (const ch of node.children.filter(x => x.index <= index).sort((x, y) => y.index - x.index)) {
				const tmp = this._getNodeByIndex(ch, index);
				if (tmp != null) {
					return tmp;
				}
			}
			return null;
		}
		return node.index === index ? node : null;
	}

	private _getPath(item: T, groups: Column<T>[]): any[] {
		return groups.map(group => group.description.accessor(item, group));
	}

	private _getLeafs(node: Node<T>): Node<T>[] {
		if (node.children.length > 0) {
			return node.children.reduce((acc, x) => acc.concat(this._getLeafs(x)), []);
		}
		return [node];
	}

	private _updateGroups(node: Node<T>, columns: Column<T>[]): void {
		const children = node.children.filter(x => x.item == null && x.isGroup());
		children.forEach(x => this._aggregateLeafs(x, columns));
		children.forEach(x => this._updateGroups(x, columns));
	}

	private _aggregateLeafs(node: Node<T>, columns: Column<T>[]): void {
		const leafsItems = this._getLeafs(node).map(leaf => leaf.item);
		const item = {} as { [key: string]: any };
		for (const column of columns) {
			const values = leafsItems.map(x => column.description.accessor(x, column));
			const context: CellContext<T> = { node, column };
			const aggregatedValue = column.description.aggregator(values, context);
			item[column.id] = aggregatedValue;
		}
		node.item = item as T;
	}

	private _constructPathChain(root: Node<T>, path: any[], groups: Column<T>[]): Node<T> {
		let node = root;
		for (let i = 0; i < path.length; i++) {
			let group = node.children.find(x => x.group.value === path[i]);
			if (group == null) {
				group = Node.group<T>({
					id: groups[i].id,
					value: path[i]
				}, node);
				node.children.push(group);
			}
			node = group;
		}
		return node;
	}

	private _invalidateGroups(node: Node<T>): void {
		node.traverse(x => {
			if (x.item != null) {
				x.item = null;
				return true;
			}
			return x.children.length === 1;
		});
	}

	private _applyGroupChanges(dirtyNodes: Set<Node<T>>) {
		const nextDirtyNodes = new Set<Node<T>>();
		dirtyNodes.forEach(node => {
			node.children.sort((x, y) => x.index - y.index);
			let nodesToRemove = 0;
			while (nodesToRemove < node.children.length && node.children[nodesToRemove].index === -1) {
				nodesToRemove++;
			}
			if (node.isRoot()) {
				node.children.splice(0, nodesToRemove);
			}
			else {
				if (node.children.length === nodesToRemove) {
					node.index = -1;
					nextDirtyNodes.add(node.parent);
				}
				else {
					node.children.splice(0, nodesToRemove);
					const index = node.children[0].index;
					if (index !== node.index) {
						node.index = index;
						nextDirtyNodes.add(node.parent);
					}
				}
			}
		});
		return nextDirtyNodes;
	}
}
