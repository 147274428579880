import {ModuleWithProviders, Type} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PreloadUserSettingsGuard} from 'kn-user-settings';

export function viewEditRoutesHelper(viewComponent: Type<any>, editComponent: Type<any>, additionalRoutes: Routes): ModuleWithProviders<RouterModule> {
	return RouterModule.forChild([
		...additionalRoutes,
		{
			path: '',
			component: viewComponent,
			canActivate: [PreloadUserSettingsGuard]
		}, {
			path: ':id/:action',
			component: editComponent
		}, {
			path: 'new',
			component: editComponent
		}
	]);
}
