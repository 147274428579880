import {Pipe, PipeTransform} from '@angular/core';
import {Utils} from 'kn-utils';

@Pipe({ name: 'get' })
export class GetPipe implements PipeTransform {
	public transform(value: any, ...paths: string[]): any {
		if (value == null) {
			return null;
		}

		const path = Utils.array.unbox(paths);
		if (Array.isArray(value)) {
			return value.map(x => Utils.object.get(x, path));
		}
		return Utils.object.get(value, path);
	}
}
