import {Injector, ElementRef} from '@angular/core';
import {CellContext, CellValue, ElementRendererFactory, ElementRenderer} from 'kn-datagrid';
import {Country} from '../country.type';

export class CountryRendererFactory extends ElementRendererFactory {
	public constructor(
			private readonly _code: keyof Country = 'alpha3',
			private readonly _present: keyof Country = _code) {
		super();
	}

	private _countries: Country[];

	protected get _propertyName(): string {
		return 'innerHTML';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, countries: { (): Country[] }): ElementRenderer<T> {
		if (this._countries == null) {
			this._countries = countries();
		}
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			if (this._countries == null) {
				return '';
			}
			const country = this._countries.find(x => x[this._code] === value);
			if (country == null) {
				return '';
			}
			return `<span class="country" title="${country.name}">
					<img src="assets/img/flags/${country.alpha2}.png" alt="${country.alpha2}">
					${country[this._present]}
					</span>`;
		};
	}
}
