<label [attr.for]="inputLabelForId" #knLabelContent>
	<ng-content select="[kn-label]"></ng-content>
	<span class="required" *ngIf="required && hasKnLabel">*</span>
</label>

<div class="kn-date-input-container">
	<div class="kn-input-prefix">
		<ng-content select="[kn-prefix]"></ng-content>
	</div>
	
	<input #inputDate
			size="1"
			class="kn-date-input-date"
			[id]="dateInputId"
			type="date"
			[hidden]="!hasDateField"
			[autofocus]="autofocus"
			[disabled]="disabled || nowDateLock"
			[readonly]="readonly"
			[required]="required"
			[attr.tabindex]="tabindex"
			[attr.name]="name + '-date'"
			[attr.max]="max"
			[attr.min]="min"
			[(ngModel)]="dateValue"
			(input)="handleFilterInput(inputDate.value)"
			(change)="handleChange($event)"
			(keyup)="handleKeyup($event)"
			(keydown)="handleKeydown($event)"
			(focus)="handleFocus($event)"
			(touchend)="handleTap($event)">

	<div class="input-button date"
			[hidden]="hideCalendar || readonly || !hasDateField">
		<a href="javascript:void(0)"
				[class.disabled]="nowDateLock"
				(click)="handleOpenDateClick($event)">
			<i class="material-icons">date_range</i>
		</a>
		<kn-datepicker #pickerDate type="date"
				(valueChange)="handleDatePickerValueChange($event)"></kn-datepicker>
	</div>

	<input #inputTime
			size="1"
			class="kn-date-input-time"
			[id]="timeInputId"
			type="time"
			[hidden]="!hasTimeField"
			[step]="step"
			[autofocus]="autofocus"
			[disabled]="disabled || nowDateLock"
			[readonly]="readonly"
			[required]="required"
			[attr.tabindex]="tabindex"
			[attr.name]="name + '-time'"
			[attr.max]="maxTime"
			[attr.min]="minTime"
			[(ngModel)]="timeValue"
			(input)="handleFilterInput(inputTime.value)"
			(change)="handleChange($event)"
			(keyup)="handleKeyup($event)"
			(keydown)="handleKeydown($event)"
			(focus)="handleFocus($event)"
			(touchend)="handleTap($event)">

	<div class="input-button time"
			[hidden]="hideCalendar || readonly || !hasTimeField">
		<a href="javascript:void(0)"
				[class.disabled]="nowDateLock"
				(click)="handleOpenTimeClick($event)">
			<i class="material-icons">date_range</i>
		</a>
		<kn-datepicker #pickerTime type="time"
				(valueChange)="handleTimePickerValueChange($event)"></kn-datepicker>
	</div>

	<a *ngIf="!readonly && !nowAllowed && !required"
			class="input-button"
			[class.disabled]="nowDateLock"
			href="javascript:void(0)"
			(click)="handleClearClick($event)">
		<i class="material-icons">clear</i>
	</a>

	<kn-checkbox *ngIf="!readonly && nowAllowed === true && !disabled"
			class="now-date-locker"
			[ngModel]="nowDateLock"
			(checkedChange)="handleNowDateLockChange($event)"
			title="Now" alt="Now" i18n></kn-checkbox>
</div>

<div class="kn-date-input-underline"></div>

<kn-options-container *ngIf="renderContainer" [hints]="hints">
	<ng-content select="kn-optgroup, kn-option"></ng-content>
</kn-options-container>

<ng-content></ng-content>

<ng-content select="[kn-hint]"></ng-content>
