import {Injector} from '@angular/core';
import {QueryFilterBond as OriginalQueryFilterBond, DescriptionBuilder, ModelBuilder, ContractDescription, ContractModel, Model, Description, FilterNode, FilterValue} from 'kn-query-filter';
import {MultiplexPool} from 'kn-shared';
import {QueryFilterExpanderService} from './query-filter-expander.service';

export class QueryFilterBond implements OriginalQueryFilterBond {
	public description: Description;

	public constructor(
			injector: Injector,
			description: ContractDescription,
			model: ContractModel,
			private readonly _onModelChangeCallback: () => void) {
		this.description = DescriptionBuilder.from(description);
		const queryExpander = injector.get(QueryFilterExpanderService);
		queryExpander.extendDescription(this.description);
		const initialModel = ModelBuilder.from(model, this.description);
		this.modelPool.push({ getter: () => initialModel });
	}

	public modelPool = new MultiplexPool<FilterNode<FilterValue>>();

	public get model() {
		return this.modelPool.value;
	}

	public onModelChange(value: Model) {
		this.modelPool.value = value;
		this._onModelChangeCallback();
	}
}
