import {Component, Input, Output, EventEmitter, ViewChild, HostListener, OnChanges, SimpleChanges, ChangeDetectionStrategy} from '@angular/core';
import {Utils} from 'kn-utils';
import {KnMenu} from 'kn-components';
import {I18nService} from 'kn-shared';
import * as Model from 'common-web/model';

@Component({
	selector: 'kn-permission-actions-select',
	templateUrl: 'permission-actions-select.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionActionsSelectComponent implements OnChanges {
	@Input() public intermediate: string;
	@Input() public options: Model.ActionInfo[];
	@Input() public permission: Model.PermissionInfo;
	@Input() public rolePermissions: Model.RolePermission[];
	@Output() public rolePermissionsChange: EventEmitter<Model.RolePermission[]> = new EventEmitter<Model.RolePermission[]>();

	public state: boolean = false;
	public title: string;
	public actions: (Model.ActionInfo & { state: boolean })[];

	@ViewChild(KnMenu, { static: true })
	public menu: KnMenu;

	@HostListener('contextmenu', ['$event'])
	public showContextMenuHandler(event: Event) {
		this.menu.expand();
		event.preventDefault();
	}

	public constructor(private readonly _i18n: I18nService) { }

	public ngOnChanges(changes: SimpleChanges) {
		if ('options' in changes) {
			this.actions = this.options.reduce(
				(acc, x) => acc.concat(Object.assign({}, x, { state: false })),
				[]);
		}
		if (['options', 'permission', 'rolePermissions'].some(x => x in changes)
				&& this.actions != null && this.permission != null && this.rolePermissions != null) {
			const rolePermission = this.rolePermissions.find(x => x.permission === this.permission.name);
			const actions = rolePermission == null ? '' : rolePermission.actions;
			this.actions.forEach(x => x.state = actions.indexOf(x.key) !== -1);
			this.title = this._formatTitle(this.actions);
			const count = this.actions.reduce((acc, x) => acc + (x.state ? 1 : 0), 0);
			switch (count) {
				case 0:
					this.state = false;
					break;
				case this.actions.length:
					this.state = true;
					break;
				default:
					this.state = null;
					break;
			}
		}
	}

	public cyclePermissions() {
		if (this.actions == null || this.permission == null) {
			return;
		}
		const count = this.actions.reduce((acc, x) => acc + (x.state ? 1 : 0), 0);
		switch (count) {
			case 0:
				this._emitRolePermissions(this.actions.map(x => x.key).join(''));
				break;
			case this.options.length:
				this._emitRolePermissions(this.intermediate || '');
				break;
			default:
				this._emitRolePermissions('');
				break;
		}
	}

	public setPermission(action: Model.ActionInfo & { state: boolean }, state: boolean) {
		const actions = this.actions
			.filter(x => x === action ? state : x.state)
			.map(x => x.key)
			.join('');
		this._emitRolePermissions(actions);
	}

	private _emitRolePermissions(actions: string) {
		const rolePermissions = Utils.clone(this.rolePermissions);
		let rolePermission = rolePermissions.find(x => x.permission === this.permission.name);
		if (rolePermission == null) {
			rolePermission = {
				actions: actions,
				permission: this.permission.name
			} as Model.RolePermission;
			rolePermissions.push(rolePermission);
			this.rolePermissionsChange.emit(rolePermissions);
		}
		else if (rolePermission.actions !== actions) {
			rolePermission.actions = actions;
			this.rolePermissionsChange.emit(rolePermissions);
		}
	}

	private _formatTitle(actions: (Model.ActionInfo & { state: boolean })[]) {
		return this._i18n.join(actions.filter(x => x.state).map(x => x.name));
	}
}
