import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http, Response, Headers, RequestOptions, Uri, UriTemplate, UriContext} from 'kn-http';
import {NotSupportedError} from 'kn-shared';
import {AbstractConcreteFetcher} from 'kn-rest';

export class BackupFetcher extends AbstractConcreteFetcher<File> {
	protected _uri: Uri;

	public constructor(
			protected _http: Http,
			uriTemplate: string,
			table: string,
			protected _referenceProperty = 'uid') {
		super(table);
		this._uri = new Uri(new UriTemplate(uriTemplate));
	}

	protected _get<U extends File | File[]>(uid: string, context: UriContext): Observable<U> {
		if (uid == null) {
			throw new NotSupportedError('Enumerable GET is not supported.');
		}
		return this.backup(uid, context) as Observable<U>;
	}

	protected _head(uid: string, context: UriContext): Observable<Response> {
		if (uid == null) {
			throw new NotSupportedError('Enumerable GET is not supported.');
		}
		const options = {
			responseType: 'blob',
			headers: new Headers({ 'Accept': this._retriveAccept(context) })
		} as RequestOptions;
		return this._http.head(this._buildUri(uid, context), options);
	}

	protected _postOrPut(item: File, context: UriContext): Observable<Response> {
		return this.restore(item, context);
	}

	protected _delete(uid: string, context: UriContext): Observable<Response> {
		throw new NotSupportedError('DELETE is not supported.');
	}

	public backup(uid: string, context: UriContext): Observable<File> {
		const options = {
			responseType: 'blob',
			headers: new Headers({ 'Accept': this._retriveAccept(context) })
		} as RequestOptions;
		return this._http.get(this._buildUri(uid, context), options)
			.pipe(Rx.map(next => new File([next.body], this._retriveFileName(next))));
	}

	public restore(item: File, context?: UriContext): Observable<Response> {
		const url = this._buildUri(this._retriveUid(item, context), context);
		const body = item;
		const options = {} as RequestOptions;
		options.headers = new Headers({ 'Content-Type': this._retriveContentType(item) });
		return this._http.post(url, body);
	}

	protected _buildUri(uri: string, context: UriContext) {
		return this._uri.build(Object.assign({}, context, { [this._referenceProperty]: uri }));
	}

	private _retriveFileName(response: Response) {
		return response.headers.get('Content-Disposition').match(/filename="([^"]+)"/)[1];
	}

	private _retriveUid(item: File, context: UriContext) {
		if (context && context.hasOwnProperty(this._referenceProperty)) {
			return context[this._referenceProperty];
		}
		const matches = item.name.match(/^backup-(.+?)(-\d{2}){3}\.bak(\.gz)?$/);
		if (matches != null) {
			return matches[1];
		}
		throw new Error('Cannot determine UID.');
	}

	private _retriveAccept(context: UriContext) {
		return context['gzip'] ? 'application/x-gzip' : 'application/json; charset=utf-8';
	}

	private _retriveContentType(item: File) {
		const contentType = item.type;
		if (/application\/(json|.*gzip)/.test(contentType)) {
			return contentType;
		}
		return item.name.endsWith('.gz') ? 'application/x-gzip' : 'application/json; charset=utf-8';
	}
}
