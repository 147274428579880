import {Utils} from 'kn-utils';
import {AbstractFilterSerializer} from './abstract-filter-serializer';
import {ModelBuilder} from '../model-builder';
import {DescriptionBuilder} from '../description-builder';
import {Model, Filter, FilterNode, FilterValue, OptionDescription, BooleanOperator} from '../types';
import {ContractDescription, ContractWithId} from '../contract.types';
import {FilterNodeUtils} from '../internal-utils';

export class QueryParametersFilterSerializer extends AbstractFilterSerializer<{ [key: string]: string| string[] }> {
	private static readonly _groupModelOperator = BooleanOperator.And;
	private static readonly _groupParameterOperator = BooleanOperator.Or;
	private static readonly _filterOperator = 'eq';

	public static from(descriptionOrOptions: ContractDescription | ContractWithId<OptionDescription<FilterValue>>[]) {
		let description = descriptionOrOptions as ContractDescription;
		if (Array.isArray(description)) {
			description = { options: description };
		}
		return new QueryParametersFilterSerializer(DescriptionBuilder.from(description));
	}

	public static serialize(model: Model) {
		const options = AbstractFilterSerializer._deriveOptionsFromModel(model);
		const serializer = QueryParametersFilterSerializer.from(options);
		return serializer.serialize(model);
	}

	public serialize(model: Model): { [key: string]: string | string[] } {
		const simplifiedModel = ModelBuilder.simplify(Utils.clone(model));
		if (!this._validateSerialization(simplifiedModel)) {
			throw new Error('Filter model cannot be serialized.');
		}

		const query: { [key: string]: string | string[] } = {};
		FilterNodeUtils.forEachNode(model, x => {
			if (!FilterNodeUtils.isGroup(x)) {
				const filter = x as Filter<FilterValue>;
				const type = this._getOptionDescription(filter.id).type;
				const id = encodeURIComponent(filter.id);
				const value = encodeURIComponent(this._serializeValue(filter.value, type) as string);
				if (query.hasOwnProperty(id)) {
					query[id] = Utils.array.box(query[id]);
					(query[id] as string[]).push(value);
				}
				else {
					query[id] = value;
				}
			}
		});
		return query;
	}

	public deserialize(query: { [key: string]: string | string[] }, subGroupQuery: boolean = false): Model {
		const model: Model = {
			operator: QueryParametersFilterSerializer._groupModelOperator,
			children: []
		};
		let root = model.children;
		if (subGroupQuery) {
			model.children.push({
				operator: QueryParametersFilterSerializer._groupParameterOperator,
				children: []
			} as FilterNode<FilterValue>);
			root = (model.children[0] as FilterNode<FilterValue>).children;
		}
		for (const key in query) {
			if (query.hasOwnProperty(key)) {
				const id = decodeURIComponent(key);
				const type = this._getOptionDescription(id).type;
				const filters = Utils.array.box(query[key]).map(x => {
					return {
						id: id,
						operator: QueryParametersFilterSerializer._filterOperator,
						value: this._deserializeValue(decodeURIComponent(x), type)
					} as Filter<FilterValue>;
				});
				if (filters.length === 1) {
					root.push(filters[0]);
				}
				else if (filters.length > 1) {
					root.push({
						operator: QueryParametersFilterSerializer._groupParameterOperator,
						children: filters
					} as FilterNode<FilterValue>);
				}
			}
		}
		return model;
	}

	private _validateSerialization(model: FilterNode<FilterValue>): boolean {
		if (model.operator !== QueryParametersFilterSerializer._groupModelOperator) {
			return false;
		}
		for (const child of model.children) {
			if (FilterNodeUtils.isGroup(child)) {
				const groupNode = child as FilterNode<FilterValue>;
				if (groupNode.operator !== QueryParametersFilterSerializer._groupParameterOperator) {
					return false;
				}
				if (groupNode.children.some(x => !this._checkFilter(x))) {
					return false;
				}
				const filters = groupNode.children as Filter<FilterValue>[];
				if (filters.some(x => x.id !== filters[0].id)) {
					return false;
				}
			}
			else if (!this._checkFilter(child)) {
				return false;
			}
		}
		return true;
	}

	private _checkFilter(node: FilterNode<FilterValue> | Filter<FilterValue>): boolean {
		if (FilterNodeUtils.isGroup(node)) {
			return false;
		}
		const filter = node as Filter<FilterValue>;
		return filter.operator === QueryParametersFilterSerializer._filterOperator;
	}
}
