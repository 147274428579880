import {Injectable, EventEmitter} from '@angular/core';
import {Node} from '../model/node';
import {RowItem} from '../types';

@Injectable()
export class ExpansionService {
	public key: string = 'expanded';
	public expandedChange = new EventEmitter<RowItem[]>();
	public refreshRows = new EventEmitter<void>();

	public getExpanded(tree: Node<RowItem>): RowItem[] {
		return this._getExpandedRows(tree);
	}

	public setExpanded(value: RowItem[], tree: Node<RowItem>) {
		this._setExpandedRows(tree, value);
		this.refreshRows.emit();
	}

	public toggleExpand(node: Node<RowItem>) {
		this.setExpand(node, !this.isExpanded(node));
	}

	public isExpanded(node: Node<RowItem>): boolean {
		if (!node.isGroup()) {
			return true;
		}
		const value = node.data.get(this.key);
		return value != null && value !== false;
	}

	public setExpand(node: Node<RowItem>, expanded: boolean) {
		let changed: boolean;

		if (node.isGroup() && this.isExpanded(node) !== expanded) {
			node.data.set(this.key, expanded);
			changed = true;
		}

		if (changed) {
			this.refreshRows.emit();
			this.expandedChange.emit(this.getExpanded(node.getRoot()));
		}
	}

	private _getExpandedRows(node: Node<RowItem>): RowItem[] {
		if (node.children.length === 0 || !this.isExpanded(node)) {
			return [];
		}
		if (node.children[0].isLeaf()) {
			return node.children.map(x => x.item);
		}
		return node.children.reduce((acc, x) => acc.concat(this._getExpandedRows(x)), []);
	}

	private _setExpandedRows(node: Node<RowItem>, rows: RowItem[]) {
		if (node.children.length === 0) {
			return false;
		}
		if (node.children[0].isLeaf()) {
			return node.children.some(x => rows.indexOf(x.item) !== -1);
		}
		return node.children.reduce((acc, x) => {
			if (this._setExpandedRows(x, rows)) {
				x.data.set(this.key, true);
				return true;
			}
			x.data.delete(this.key);
			return acc;
		}, false);
	}
}
