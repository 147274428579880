import {Directive, Self} from '@angular/core';
import {FormArrayName, FormArray, AbstractControl} from '@angular/forms';
import {AbstractFormControl} from './abstract-form-control';

@Directive({
	selector: '[formArrayName]',
	exportAs: 'knFormArray'
})
export class KnFormArray extends AbstractFormControl<FormArray> {
	public constructor(@Self() private readonly _formArrayName: FormArrayName) {
		super();
	}

	public get control(): FormArray {
		return this._formArrayName.control;
	}

	public get controls(): AbstractControl[] {
		return this._formArrayName.control.controls;
	}

	public at(index: number): AbstractControl {
		return this._formArrayName.control.at(index);
	}

	public get length(): number {
		return this._formArrayName.control.length;
	}
}
