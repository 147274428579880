import {Injectable, Optional, SkipSelf} from '@angular/core';
import {Observable, PartialObserver, Subject, Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {ChangeEntry} from './types';

@Injectable()
export class RestChangeNotifierService {
	private readonly _subject: Subject<ChangeEntry>;
	private _notifier: Observable<ChangeEntry>;

	public constructor(@SkipSelf() @Optional() parent?: RestChangeNotifierService) {
		if (parent != null) {
			this._subject = parent._subject;
			this._notifier = parent._notifier;
		}
		else {
			this._subject = new Subject<ChangeEntry>();
			this._notifier = this._subject.pipe(Rx.share());
		}
	}

	public emit(change: ChangeEntry) {
		this._subject && this._subject.next(change);
	}

	public subscribe(observerOrNext?: PartialObserver<ChangeEntry> | ((value: ChangeEntry) => void), error?: () => void, complete?: () => void): Subscription {
		return this._notifier.subscribe({
			next: observerOrNext as any,
			error,
			complete
		});
	}

	private _transformNotifier(transformer: (notifier: Observable<ChangeEntry>) => Observable<ChangeEntry>) {
		this._notifier = transformer(this._notifier);
	}

	public withDebounce(debounceTime: number): RestChangeNotifierService {
		const copy = new RestChangeNotifierService(this);
		copy._transformNotifier(notifier => notifier.pipe(Rx.debounceTime(debounceTime)));
		return copy;
	}
}
