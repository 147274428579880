import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DisplayedViewIndex} from './views/time-view.component';
import {KnBackgroundFocusable} from 'kn-directives';
import {DateTime} from '../types';

@Component({
	selector: 'kn-datetime',
	templateUrl: 'datetime.html'
})
export class KnDatetime implements OnInit {
	@Input() public dateTime: DateTime;
	@Output() public dateTimeChange = new EventEmitter<DateTime>();
	@Output() public accept = new EventEmitter<DateTime>();

	public viewIndex = 0;
	public timeViewIndex = DisplayedViewIndex.DayView;

	public viewDate: DateTime;
	public hoursInPeriod: number = 24;

	public constructor(private readonly _backgroundFocusable: KnBackgroundFocusable) { }

	public ngOnInit() {
		const today = new Date();
		if (this.viewDate == null) {
			this.viewDate = {
				year: this.dateTime != null ? this.dateTime.year : today.getFullYear(),
				month: this.dateTime != null ? this.dateTime.month : today.getMonth()
			};
		}
	}

	public prevMonth() {
		if (--this.viewDate.month < 0) {
			this.viewDate.year--;
			this.viewDate.month = 11;
		}
		this.viewDate = Object.assign({}, this.viewDate);
	}

	public nextMonth() {
		if (++this.viewDate.month > 11) {
			this.viewDate.year++;
			this.viewDate.month = 0;
		}
		this.viewDate = Object.assign({}, this.viewDate);
	}

	public acceptDate() {
		this._backgroundFocusable.focus();
		this.viewIndex = 1;
		this.timeViewIndex = DisplayedViewIndex.DayView;
	}

	public acceptTime() {
		this.dateTimeChange.next(this.dateTime);
	}
}
