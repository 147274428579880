import {Description, OptionDescription, FilterValue} from './types';
import * as Rx from 'rxjs/operators';
import {ContractDescription} from './contract.types';
import {Utils} from 'kn-utils';
import {I18n} from 'kn-shared';
import {ValueResolveUtils} from './internal-utils';

export type OptionExpander<T> = Pick<OptionDescription<T>, 'id'> & Partial<OptionDescription<T>>;
type Resolver<T> = { [P in keyof T]?: { (object: T): T[P] } };

export class DescriptionBuilder {
	public static getDefaultExpanders(): OptionDescription<FilterValue>[] {
		return [];
	}

	public static from(description: ContractDescription, expanders?: OptionExpander<FilterValue>[]): Description {
		if (!description) {
			description = {};
			DescriptionBuilder._initDefaults(description);
			return description as Description;
		}
		expanders = expanders || DescriptionBuilder.getDefaultExpanders();
		DescriptionBuilder._initDefaults(description);
		DescriptionBuilder._expandOptionsByExpanders(description, expanders);
		DescriptionBuilder._setOptionsDefaults(description);
		return description as Description;
	}

	private static _initDefaults(description: ContractDescription) {
		const i18n = I18n.get().getService();
		const defaults = {
			typeSettings: [
				{
					id: 'date',
					operators: [
						{ id: 'between', label: i18n.t('is between'), presenter: 'date-between' }
					]
				}, {
					id: 'enum',
					operators: [
						{ id: 'eq', label: i18n.t('is'), presenter: 'combobox' },
						{ id: 'ne', label: i18n.t('is not'), presenter: 'combobox' }
					]
				}, {
					id: 'string',
					operators: [
						{ id: 'eq', label: i18n.t('is'), presenter: 'string-input' },
						{ id: 'ne', label: i18n.t('is not'), presenter: 'string-input' },
						{ id: 'contains', label: i18n.t('contains'), presenter: 'string-input' },
						{ id: 'not-contains', label: i18n.t('not contains'), presenter: 'string-input' },
						{ id: 'starts-with', label: i18n.t('starts with'), presenter: 'string-input' },
						{ id: 'ends-with', label: i18n.t('ends with'), presenter: 'string-input' }
					]
				}, {
					id: 'number',
					operators: [
						{ id: 'eq', label: i18n.t('is equal to'), presenter: 'number-input' },
						{ id: 'ne', label: i18n.t('is not equal to'), presenter: 'number-input' },
						{ id: 'gt', label: i18n.t('is greater than'), presenter: 'number-input' },
						{ id: 'lt', label: i18n.t('is less than'), presenter: 'number-input' },
						{ id: 'ge', label: i18n.t('is greater than or equal to'), presenter: 'number-input' },
						{ id: 'le', label: i18n.t('is less than or equal to'), presenter: 'number-input' },
						{ id: 'between', label: i18n.t('is between'), presenter: 'number-between' }
					]
				}, {
					id: 'bool',
					operators: [
						{ id: 'eq', label: i18n.t('is'), presenter: 'checkbox' }
					]
				}, {
					id: 'identifier',
					operators: [
						{ id: 'eq', label: i18n.t('is'), presenter: 'combobox' },
						{ id: 'ne', label: i18n.t('is not'), presenter: 'combobox' }
					]
				}
			],
			changeOperatorValueAdaption: (filter, oldOperator, newOperator) => {
				if (newOperator === 'between' && !Array.isArray(filter.value)) {
					filter.value = [filter.value, filter.value];
				}
				else if (oldOperator === 'between' && Array.isArray(filter.value)) {
					switch (newOperator) {
						case 'eq':
						case 'ne':
						case 'gt':
						case 'ge':
							filter.value = filter.value[0];
							break;
						case 'lt':
						case 'le':
							filter.value = filter.value[1];
							break;
					}
				}
			},
			options: []
		} as Description;

		Utils.object.defaults(description, defaults);
	}

	private static _expandOptionsByExpanders(description: ContractDescription, expanders: OptionExpander<FilterValue>[]) {
		for (const expander of expanders) {
			const option = description.options.find(x => x.id === expander.id);
			if (option != null) {
				Utils.object.defaults(option, expander);
			}
		}
	}

	private static _setOptionsDefaults(description: ContractDescription) {
		const defaultsResolver = {
			label: x => x.id == null ? x.id : DescriptionBuilder._toProperCase(x.id),
			type: () => 'string',
			presenter: () => null,
			defaults: x => {
				switch (x.type) {
					case 'date':
						return {
							operator: 'between',
							value: () => {
								const startDate = new Date();
								const endDate = new Date();
								startDate.setHours(0, 0, 0, 0);
								endDate.setHours(23, 59, 0, 0);
								return [startDate, endDate];
							}
						};
					case 'enum':
						const value$ = ValueResolveUtils.resolveOptionParameter('options', x)
							.pipe(Rx.map(next => next.length > 0 ? next[0].value : null));
						return { operator: 'eq', value: value$ };
					case 'string':
						return { operator: 'contains', value: '' };
					case 'number':
					case 'identifier':
						return { operator: 'eq', value: 0 };
					case 'bool':
						return { operator: 'eq', value: false };
				}
				return null;
			},
			constraints: x => {
				let constraints = {};
				switch (x.type) {
					case 'date':
						constraints = {
							mindate: null,
							maxdate: null,
							datefilter: () => true
						};
						break;
					case 'string':
						constraints = {
							minlength: null,
							maxlength: null
						};
						break;
					case 'number':
						constraints = {
							step: null,
							max: null,
							min: null
						};
						break;
				}
				if (x.constraints == null) {
					x.constraints = {};
				}
				return Utils.object.defaults(x.constraints, constraints);
			}
		} as Resolver<OptionDescription<FilterValue>>;

		Utils.array.defaultsResolver(description.options, defaultsResolver);
	}

	private static _toProperCase(text: string) {
		return text.replace(/(^|[A-Z0-9]|[^a-z])[a-z]*/g, x => {
			let chunk = x.replace(/[^a-zA-Z0-9]/g, '');
			chunk = chunk.charAt(0).toUpperCase() + chunk.substr(1).toLowerCase();
			return chunk.length > 0 ? chunk + ' ' : '';
		}).trim();
	}
}
