import {Model, RowItem, Description, Column, Section, SectionModel, SectionDescription} from '../../types';
import {ValueResolveUtils, SectionUtils} from '../../internal-utils';
import {Utils} from 'kn-utils';

export class ColumnsBuilder<T extends RowItem> {
	public build(description: Description<T>, model: Model) {
		const columns = this._buildColumns(description, model);
		const section = this._buildSections(description, model);
		this._linkColumnsAndSections(columns, section);
		return columns;
	}

	public filterVisibleColumns(columns: Column<T>[]): Column<T>[] {
		const predicate = (column: Column<T>) => {
			if (!column.model.visible) {
				return false;
			}
			if (!SectionUtils.traverse(column.section, x => x.model.visible)) {
				return false;
			}
			if (ValueResolveUtils.resolveHidden(column)) {
				return false;
			}
			if (!SectionUtils.traverse(column.section, x => !ValueResolveUtils.resolveHidden<T>(x))) {
				return false;
			}
			return true;
		};

		return columns.filter(predicate);
	}

	private _buildColumns(description: Description<T>, model: Model) {
		return model.columns.map(columnModel => {
			const columnDescription = description.columns.find(x => x.id === columnModel.id);
			return {
				id: columnModel.id,
				model: columnModel,
				description: columnDescription,
				section: null
			} as Column<T>;
		});
	}

	private _buildSections(description: Description<T>, model: Model) {
		const root = {
			id: null,
			model: null,
			description: null,
			columns: [],
			parent: null,
			children: []
		} as Section<T>;
		return this._buildSubsections(description.sections, model.sections, root);
	}

	private _buildSubsections(sectionDescriptions: SectionDescription<T>[], sectionModels: SectionModel[], parent: Section<T>) {
		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < sectionModels.length; i++) {
			const sectionModel = sectionModels[i];
			const sectionDescription = sectionDescriptions.find(x => x.id === sectionModel.id);
			const section = {
				id: sectionModel.id,
				model: sectionModel,
				description: sectionDescription,
				columns: [],
				parent: parent,
				children: []
			} as Section<T>;
			this._buildSubsections(sectionDescription.children, sectionModel.children, section);
			parent.children.push(section);
		}
		return parent;
	}

	private _linkColumnsAndSections(columns: Column<T>[], section: Section<T>, path: string[] = []): Column<T>[] {
		const remainingColumns: Column<T>[] = [];
		for (const column of columns) {
			const currentPath = Utils.array.box(column.description.section);
			(Utils.equal(path, currentPath) ? section.columns : remainingColumns).push(column);
		}
		section.columns.forEach(x => x.section = section);
		return section.children.reduce(
				(acc, x) => this._linkColumnsAndSections(acc, x, path.concat([x.id])),
				remainingColumns);
	}
}
