import {Component, Input, OnChanges, SimpleChanges, OnDestroy, NgZone, ChangeDetectorRef, ElementRef, HostListener, ViewEncapsulation, ChangeDetectionStrategy} from '@angular/core';
import {NavbarLink} from './navbar.types';

/*
 * WARNING: This is incredible hackish component.
 * This component simply cannot be made with Angular in any sane way.
 * There is black magic.
 */

@Component({
	selector: 'kn-navbar',
	templateUrl: './navbar.html',
	styles: ['kn-navbar { display: block; }'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnNavbar implements OnChanges, OnDestroy {
	private _timerId: any;
	private _lastContainerWidth: number = null;

	@Input() public activeClass: string;
	@Input() public links: NavbarLink[];
	@Input() public linkMargin = 44;
	@Input() public charWidth = 8;
	@Input() public keepoutWidth = 150;

	public navbarLinks: NavbarLink[];
	public moreLinks: NavbarLink[];

	public constructor(
			private readonly _zone: NgZone,
			private readonly _cdr: ChangeDetectorRef,
			private readonly _element: ElementRef) { }

	@HostListener('window:resize.passive.outside')
	public onResize() {
		const containerWidth = this._element.nativeElement.offsetWidth;
		if (this._lastContainerWidth !== containerWidth) {
			this._zone.run(() => this._rearrange(containerWidth));
		}
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (['links', 'linkMargin', 'charWidth', 'keepoutWidth'].some(x => x in changes)) {
			this._timerId && clearTimeout(this._timerId);
			this._timerId = null;
			this._zone.runOutsideAngular(() => {
				// HOTFIX: must wait for DOM stabilize
				this._timerId = setTimeout(() => {
					const containerWidth = this._element.nativeElement.offsetWidth;
					this._zone.run(() => this._rearrange(containerWidth));
				});
			});
		}
	}

	public ngOnDestroy() {
		this._timerId && clearTimeout(this._timerId);
		this._timerId = null;
	}

	private _rearrange(containerWidth: number) {
		let remainingWidth = containerWidth - this.keepoutWidth;
		this._lastContainerWidth = containerWidth;
		this.navbarLinks = [];
		this.moreLinks = [];
		for (const link of (this.links || [])) {
			const linkWidth = this._estimateLinkWidth(link);
			if (linkWidth < remainingWidth) {
				remainingWidth -= linkWidth;
				this.navbarLinks.push(link);
			}
			else {
				remainingWidth = 0;
				this.moreLinks.push(link);
			}
		}
		this._cdr.markForCheck();
	}

	private _estimateLinkWidth(link: NavbarLink) {
		return this.linkMargin + this.charWidth * link.name.length;
	}
}
