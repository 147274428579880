import {AbstractChartFactory} from './abstract-chart';
import {BarChart, BaseChart, BaseChartEventsTypes, BarChartData, BarChartOptions, ResponsiveOptions } from 'chartist';

export class BarChartFactory extends AbstractChartFactory {
	public get name(): string {
		return 'bar';
	}

	public create(target: string | Element | null, data: BarChartData, options?: BarChartOptions, responsiveOptions?: ResponsiveOptions<BarChartOptions>): BaseChart<BaseChartEventsTypes> {
		return new BarChart(target, data, options, responsiveOptions);
	}
}
