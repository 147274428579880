import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	HostBinding,
	HostListener,
	Input,
	NgZone,
	ViewEncapsulation
} from '@angular/core';

@Component({
	selector: 'kn-sticky',
	template: '<ng-content></ng-content>',
	styles: [`
		kn-sticky {
			display: block;
		}
		kn-sticky.sticked > * {
			position: fixed;
			top: 0;
		}
	`],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KnSticky {
	private readonly _nativeElement: HTMLElement;
	private _treshold: number;

	@Input() public disabled: boolean = false;

	public constructor(private readonly _zone: NgZone, element: ElementRef) {
		this._nativeElement = element.nativeElement;
	}

	@HostBinding('class.sticked')
	public sticked: boolean = false;

	@HostBinding('style.height.px')
	public height: number;

	@HostListener('window:scroll.passive.outside', ['$event'])
	public scrollHandler(event: Event) {
		const treshold = this._treshold != null ? this._treshold : this._nativeElement.offsetTop;
		let sticked = !this.disabled;
		if (sticked) {
			const target = event.target as { scrollingElement?: HTMLElement, body?: HTMLElement };
			sticked = treshold < (target.scrollingElement || target.body).scrollTop;
		}
		if (this.sticked !== sticked) {
			this._zone.run(() => {
				if (sticked) {
					this.height = this._nativeElement.getBoundingClientRect().height;
					this._treshold = treshold;
				}
				else {
					this.height = undefined;
					this._treshold = undefined;
				}
				this.sticked = sticked;
			});
		}
	}
}
