import {ActionCommand} from '../../../abstract-grid-view.component';

export type InGridActionCallable<T, U> = (item: T) => U;

export class InGridAction<T> {
	public title: string;
	public icon: string;
	public permission: string;
	public visible: InGridActionCallable<T, boolean>;
	public command: InGridActionCallable<T, ActionCommand<T>>;
}
