import {CellContext, RowItem, CellValue, Renderer} from '../types';

export class RendererFactory {
	protected _targets: { [target: string]: (value: CellValue, context: CellContext<RowItem>, ...args: any[]) => any } = {};

	public static get mainTarget(): string {
		return 'main';
	}

	public static create<T>(...args: any[]): Renderer<T> {
		const factory = new this();
		return factory.create(args);
	}

	public create<T>(...args: any[]): Renderer<T> {
		const renderer = (value: CellValue, context: CellContext<T>, target: string) => {
			target = target || '';
			if (this._targets.hasOwnProperty(target)) {
				return this._targets[target](value, context, ...args);
			}
			return this._defaultRenderer<T>(value, context, ...args);
		};
		return renderer.bind(this);
	}

	public register<T>(target: string, targetRenderer: (value: CellValue, context: CellContext<T>, ...args: any[]) => any) {
		this._targets[target || ''] = targetRenderer;
	}

	protected _defaultRenderer<T>(value: CellValue, context: CellContext<T>, ...args: any[]): any {
		return value;
	}
}
