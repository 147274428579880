import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnDraggableModule} from '../draggable/draggable.module';
import {KnResizableContainerComponent} from './resizable-container.component';
import {KnResizableComponent} from './resizable.component';

export const KN_RESIZABLE_DIRECTIVES = [
	KnResizableContainerComponent,
	KnResizableComponent
];

@NgModule({
	imports: [CommonModule, KnDraggableModule],
	declarations: [KN_RESIZABLE_DIRECTIVES],
	exports: [KN_RESIZABLE_DIRECTIVES]
})
export class KnResizableModule { }
