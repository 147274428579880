import {Injector, ElementRef, Renderer2, Type} from '@angular/core';
import {RendererFactory} from './renderer-factory';
import {RenderAs} from './render-as';
import {ElementRenderBinderRef, ElementRenderer} from './binders/element-render-binder-ref';
import {CellContext, CellValue} from '../types';

export class ElementRendererFactory extends RendererFactory {
	public constructor() {
		super();
		this.register(RendererFactory.mainTarget, this._mainRenderer.bind(this));
	}

	protected _mainRenderer(value: string, context: CellContext<any>, ...args: any[]): RenderAs {
		return RenderAs.element((injector, elementRef) => this._renderHtml(injector, elementRef, ...args));
	}

	protected get _propertyName(): string {
		return 'textContent';
	}

	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, ...args: any[]): ElementRenderer<T> {
		return (element: ElementRef, value: CellValue, context: CellContext<any>, firstChange: boolean): string => {
			return value as string;
		};
	}

	private _renderHtml(injector: Injector, elementRef: ElementRef, ...args: any[]) {
		const renderer = injector.get(Renderer2 as Type<Renderer2>);
		return new ElementRenderBinderRef<any>(
			renderer,
			elementRef,
			this._propertyName,
			this._createRenderer(injector, elementRef, ...args)
		);
	}
}
