import {Injector, Injectable, StaticProvider, getPlatform} from '@angular/core';
import {AvgAggregator} from './avg-aggregator';
import {MaxAggregator} from './max-aggregator';
import {MinAggregator} from './min-aggregator';
import {SumAggregator} from './sum-aggregator';
import {PropertyAccessor} from './aggregator-base';
import {Aggregator} from '../types';

export const AGGREGATORS_PROVIDERS: any = [
	AvgAggregator,
	MaxAggregator,
	MinAggregator,
	SumAggregator
];

@Injectable()
export class Aggregators<T> {
	private static get _injector() {
		const providers = [
			...AGGREGATORS_PROVIDERS
		];
		return Injector.create({
			providers: providers.map(x => ({ provide: x, deps: [] } as StaticProvider)),
			parent: getPlatform().injector
		});
	}

	public avg: ((weightProperty?: PropertyAccessor<T>) => Aggregator<T>) = (weightProperty?: PropertyAccessor<T>) =>
		Aggregators._injector.get<AvgAggregator>(AvgAggregator)
			.create(weightProperty);

	public max: ((compareProperty?: PropertyAccessor<T>) => Aggregator<T>) = (compareProperty?: PropertyAccessor<T>) =>
		Aggregators._injector.get<MaxAggregator>(MaxAggregator)
			.create(compareProperty);

	public min: ((compareProperty?: PropertyAccessor<T>) => Aggregator<T>) = (compareProperty?: PropertyAccessor<T>) =>
		Aggregators._injector.get<MinAggregator>(MinAggregator)
			.create(compareProperty);

	public sum: (() => Aggregator<T>) = () =>
		Aggregators._injector.get<SumAggregator>(SumAggregator)
			.create<T>();
}
