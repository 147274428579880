import {Injectable} from '@angular/core';
import {PrintContext, ContextConfiguration} from '../types';
import {AbstractResolutionStrategy, ResolutionStrategy, ResolutionFunctor} from './abstract-resolution-strategy';
import {Uri, UriTemplate} from 'kn-http';

interface DependencyDescriptor {
	key: string;
	template: UriTemplate;
	dependencies: string[];
}

@Injectable()
export class DependencyResolveResolutionStrategy extends AbstractResolutionStrategy {
	public create(config: ContextConfiguration, avaibleKeys: string[]): ResolutionStrategy {
		if (config.dependencies == null) {
			return [];
		}

		const keys = Object.keys(config.dependencies).concat(avaibleKeys);
		const dependencies = this._iterateDependences(config.dependencies, (key, template) => {
			return {
				key: key,
				template: template,
				dependencies: this._getDependencies(keys, template)
			} as DependencyDescriptor;
		});

		const cascades = this._resolveCascades(dependencies, avaibleKeys);
		return this._emitResolutionSequence(cascades, config.baseUri);
	}

	private _getDependencies(keys: string[], template: UriTemplate): string[] {
		const isMatchedKey = (key: string, param: string) => {
			const whitelistedPostfixes = ['.', '['];
			return param === key
				|| whitelistedPostfixes.some(x => param.lastIndexOf(key + x) === 0);
		};
		return template.getParameterNames()
			.map(x => {
				const dependency = keys.find(key => isMatchedKey(key, x));
				if (dependency == null) {
					throw new Error(`Unavaible dependency: ${x}.`);
				}
				return dependency;
			})
			.reduce((array, x) => array.indexOf(x) !== -1 ? array : [...array, x], []);
	}

	private _isDependenciesMet(keys: string[], dependencies: string[]): boolean {
		return dependencies
			.reduce((result, dependence) => result && keys.indexOf(dependence) !== -1, true);
	}

	private _resolveCascades(dependencies: DependencyDescriptor[], avaibleKeys: string[]): DependencyDescriptor[][] {
		const resolvedKeysUntilNow: string[] = avaibleKeys.slice(0);
		const cascades: DependencyDescriptor[][] = [];
		while (dependencies.length) {
			const cascade: DependencyDescriptor[] = [];
			for (let i = dependencies.length - 1; i >= 0; i--) {
				if (this._isDependenciesMet(resolvedKeysUntilNow, dependencies[i].dependencies)) {
					cascade.push(dependencies.splice(i, 1)[0]);
				}
			}
			if (cascade.length === 0) {
				throw new Error('Dependencies cannot be resolved.');
			}
			cascade.forEach(x => resolvedKeysUntilNow.push(x.key));
			cascades.push(cascade);
		}
		return cascades;
	}

	private _emitResolutionSequence(cascades: DependencyDescriptor[][], baseUri: string): ResolutionStrategy {
		const sequence: ResolutionStrategy = [];
		for (const cascade of cascades) {
			const functor: ResolutionFunctor = (context: PrintContext) => {
				return cascade.reduce((receipt, desc) => {
					const uri = this._expandUri(desc.template, context, baseUri);
					return uri == null ? receipt : Object.assign(receipt, { [desc.key]: uri });
				}, {} as { [key: string]: Uri });
			};
			sequence.push(functor);
		}
		return sequence;
	}
}
