import {I18nService} from 'kn-shared';
import {InGridAction} from './in-grid-action.type';

export function defaultInGridActions(i18n: I18nService): InGridAction<any>[] {
	return [
		{
			title: i18n.t('Edit'),
			icon: 'edit',
			permission: 'edit',
			visible: () => true,
			command: x => ({ name: 'edit', item: x })
		}, {
			title: i18n.t('New as copy'),
			icon: 'content_copy',
			permission: 'copy',
			visible: () => true,
			command: x => ({ name: 'copy', item: x })
		}, {
			title: i18n.t('Remove'),
			icon: 'delete',
			permission: 'remove',
			visible: () => true,
			command: x => ({ name: 'delete', items: [x] })
		}
	];
}
