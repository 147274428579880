import {Inject, NgModule, Optional, ModuleWithProviders, InjectionToken} from '@angular/core';
import {Utils} from 'kn-utils';
import {forRootGuardFactory} from 'kn-shared';
import {PRINT_BASE_CONFIGURATION_TOKEN} from '../print-base-configuration.token';
import {PrintTemplatesConfig} from './print-templates.config';
import {PrintTemplatesResourceService} from './print-templates-resource.service';
import {PrintAssetsResourceService} from './print-assets-resource.service';
import {PrintContextCaptureService} from './print-context-capture.service';
import {PrintContextCaptureGuard} from './print-context-capture.guard';

export class PrintTemplatesOptions extends PrintTemplatesConfig { }

export const PRINT_TEMPLATES_FORROOT_GUARD = new InjectionToken<void>('PRINT_TEMPLATES_FORROOT_GUARD');

export function providePrintTemplatesConfig(config: PrintTemplatesOptions) {
	return Utils.object.defaults(config, {
		printTemplatesTable: 'PrintTemplates',
		printAssetsTable: 'PrintAssets'
	});
}

@NgModule({})
export class KnPrintTemplatesModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(PRINT_TEMPLATES_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(config?: Partial<PrintTemplatesOptions>): ModuleWithProviders<KnPrintTemplatesModule> {
		return {
			ngModule: KnPrintTemplatesModule,
			providers: [
				...forRootGuardFactory('KnPrintTemplatesModule', PRINT_TEMPLATES_FORROOT_GUARD, PrintTemplatesResourceService),
				{
					provide: PrintTemplatesOptions, useValue: config ? config : {}
				}, {
					provide: PrintTemplatesConfig,
					useFactory: providePrintTemplatesConfig,
					deps: [PrintTemplatesOptions]
				}, {
					provide: PRINT_BASE_CONFIGURATION_TOKEN, useValue: {}
				},
				PrintTemplatesResourceService,
				PrintAssetsResourceService,
				PrintContextCaptureService,
				PrintContextCaptureGuard
			]
		};
	}
}
