import {Injector} from '@angular/core';
import {UriContext} from 'kn-http';
import {BooleanOperator, DefaultFilterSerializer, Filter, FilterNode, FilterValue, FlatModelMerger as FilterFlatModelMerger, Model as FilterModel, ModelBuilder as FilterModelBuilder} from 'kn-query-filter';
import {Utils} from 'kn-utils';
import {Observable} from 'rxjs';
import {AbstractGridData} from './abstract-grid-data.service';
import {GridBonding} from './grid-bonding';

export class GridDataBonding<T> extends GridBonding<T> {
	private readonly _backgroundFilterModel: FilterModel;

	public constructor(
			injector: Injector,
			fetcher: (context: UriContext) => Observable<T[]>,
			gridData: AbstractGridData<T>,
			context?: UriContext) {
		super(injector, fetcher, gridData.datagridDescription, gridData.datagridModel,
				gridData.queryFilterDescription, gridData.queryFilterModel, context);
		this._backgroundFilterModel = FilterModelBuilder.from(
				gridData.queryBackgroundFilterModel, this.filter.description);
	}

	protected _prepareFilterModel(model: FilterModel): Observable<FilterModel> {
		return this.queryExpander.expandModel(model, this.filter.description, this._fetcher, this._fixedContext());
	}

	protected _serializeFilterModel(model: FilterModel): Observable<string> {
		const merger = new FilterFlatModelMerger(this.filter.description, true);
		if (model.operator === BooleanOperator.Or && model.children.length > 0 && !model.children[0].hasOwnProperty('children')) {
			model = { operator: BooleanOperator.And, children: [model] };
		}
		model = merger.merge(Utils.clone(this._backgroundFilterModel, true), model);
		return super._serializeFilterModel(model);
	}

	protected _fixedContext(): UriContext {
		const merger = new FilterFlatModelMerger(this.filter.description, true);
		const serializer = new DefaultFilterSerializer(this.filter.description);
		let fixedFilters: FilterModel = Utils.clone(this.filter.model, true);
		this._filterFixedFilters(fixedFilters);
		fixedFilters = merger.merge(Utils.clone(this._backgroundFilterModel, true), fixedFilters);
		return { query: { q: serializer.serialize(fixedFilters) || undefined } };
	}

	protected _filterFixedFilters(filter: FilterModel | FilterNode<any>) {
		filter.children = filter.children.filter(
			x => {
				if ((x as FilterNode<any>).children !== undefined) {
					const ch = x as FilterNode<any>;
					this._filterFixedFilters(ch);
					return ch.children.length > 0;
				}
				else {
					const f = x as Filter<any>;
					return f.disabled && (f.fixed || f.hidden);
				}
			}
		);
	}

	protected _cloneNodeOrValue(
			node: FilterNode<FilterValue> | Filter<FilterValue>,
			context: UriContext): FilterNode<FilterValue> | Filter<FilterValue> {
		if (node.hasOwnProperty('children')) {
			return {
				operator: (node as FilterNode<FilterValue>).operator,
				children: (node as FilterNode<FilterValue>).children
					.map(x => this._cloneModel(x as FilterModel, context)).filter(x => x)
			} as FilterNode<FilterValue>;
		}
		const value = node as any as Filter<FilterValue>;
		if (value.id.startsWith('$') || value.id.startsWith('@')) {
			if (context.clientQuery) {
				context.clientQuery.push(value);
			}
			else {
				context.clientQuery = [value];
			}
			return null;
		}
		return Object.assign({}, node);
	}
}
