import {Injectable, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';

// tempolary solution for <https://github.com/angular/angular/issues/18232>
// import {EventManagerPlugin} from '@angular/platform-browser';
import {EventManagerPlugin} from './event-manager-plugin';

export interface ParsedEvent {
	name: string;
	runOutside: boolean;
	addOptions: boolean | AddEventListenerOptions;
	removeOptions: boolean | EventListenerOptions;
}

@Injectable()
export class ExtendedDomEventsPlugin extends EventManagerPlugin {
	public constructor(@Inject(DOCUMENT) doc: any) {
		super(doc);
	}

	public supports(eventName: string) {
		return ExtendedDomEventsPlugin.parseEventName(eventName) != null;
	}

	public addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
		const parsedEvent = ExtendedDomEventsPlugin.parseEventName(eventName);

		const listener = () => {
			element.addEventListener(parsedEvent.name, handler as any, parsedEvent.addOptions as any);
			return () => {
				if (parsedEvent.removeOptions != null) {
					element.removeEventListener(parsedEvent.name, handler as any, parsedEvent.removeOptions);
				}
			};
		};

		if (parsedEvent.runOutside) {
			return this.manager.getZone().runOutsideAngular(listener);
		}

		return listener();
	}

	public static parseEventName(eventName: string): ParsedEvent {
		const parts = eventName.split('.');
		if (parts.length <= 1) {
			return null;
		}

		const parsedEvent = {
			name: parts[0],
			runOutside: false,
			addOptions: false,
			removeOptions: false
		};

		for (let i = 1; i < parts.length; i++) {
			switch (parts[i]) {
				case 'capture':
					if (typeof parsedEvent.addOptions === 'boolean') {
						parsedEvent.addOptions = true;
					}
					else {
						(parsedEvent.addOptions as AddEventListenerOptions).capture = true;
					}
					if (typeof parsedEvent.removeOptions === 'boolean') {
						parsedEvent.removeOptions = true;
					}
					break;

				case 'passive':
					if (typeof parsedEvent.addOptions === 'boolean') {
						parsedEvent.addOptions = {
							capture: parsedEvent.addOptions,
							passive: true
						} as any;
					}
					else {
						(parsedEvent.addOptions as AddEventListenerOptions).passive = true;
					}
					break;

				case 'once':
					if (typeof parsedEvent.addOptions === 'boolean') {
						parsedEvent.addOptions = {
							capture: parsedEvent.addOptions,
							once: true
						} as any;
					}
					else {
						(parsedEvent.addOptions as AddEventListenerOptions).once = true;
					}
					parsedEvent.removeOptions = null;
					break;

				case 'outside':
					parsedEvent.runOutside = true;
					break;

				default:
					return null;
			}
		}

		return parsedEvent;
	}
}
