import {Inject, Pipe, PipeTransform, LOCALE_ID} from '@angular/core';

@Pipe({ name: 'decimal' })
export class DecimalPipe implements PipeTransform {
	private static readonly _formatRegExp: RegExp = /^(\d+)?\.(?:(\d+)(?:-(\d+))?)?|(\d+)(?:-(\d+))?$/;
	private static readonly _formatersCache: Map<string, Intl.NumberFormat> = new Map<string, Intl.NumberFormat>();

	public constructor(@Inject(LOCALE_ID) private readonly _locale: string) { }

	public transform(value: number, format: string, useGrouping: boolean = true, locales?: string | string[]): string {
		if (value == null) {
			return null;
		}
		locales = locales || this._locale;
		return DecimalPipe._getOrCreateFormater(format, useGrouping, locales).format(value);
	}

	private static _getOrCreateFormater(format: string, useGrouping: boolean, locales: string | string[]): Intl.NumberFormat {
		const key = [format, useGrouping, locales].join(':');
		if (!DecimalPipe._formatersCache.has(key)) {
			const formater = DecimalPipe._createFormater(format, useGrouping, locales);
			DecimalPipe._formatersCache.set(key, formater);
		}
		return DecimalPipe._formatersCache.get(key);
	}

	private static _createFormater(format: string, useGrouping: boolean, locales: string | string[]): Intl.NumberFormat {
		const parts = format.match(DecimalPipe._formatRegExp);
		const intlOptions: Intl.NumberFormatOptions = { useGrouping };
		if (parts) {
			if (parts[1] != null) {
				intlOptions.minimumIntegerDigits = +parts[1];
			}
			if (parts[2] != null) {
				intlOptions.minimumFractionDigits = +parts[2];
			}
			if (parts[3] != null) {
				intlOptions.maximumFractionDigits = +parts[3];
			}
			if (parts[4] != null) {
				intlOptions.minimumSignificantDigits = +parts[4];
			}
			if (parts[5] != null) {
				intlOptions.maximumSignificantDigits = +parts[5];
			}
		}
		return new Intl.NumberFormat(locales, intlOptions);
	}
}
