import {Component, QueryList, OnDestroy, AfterViewInit, ContentChildren, Input} from '@angular/core';
import {Subscription, Subject} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {KnExpansion} from './expansion.component';

@Component({
	selector: 'kn-expansion-manager',
	template: '<ng-content></ng-content>'
})
export class KnExpansionManager implements OnDestroy, AfterViewInit {
	private readonly _subscribtions: Subscription[] = [];
	@ContentChildren(KnExpansion) private readonly _expansions: QueryList<KnExpansion>;
	private _prevExpansions: KnExpansion[] = [];
	private readonly _expandSubject: Subject<KnExpansion> = new Subject<KnExpansion>();

	@Input() public expandNew: boolean;
	@Input() public singleExpand: boolean;
	@Input() public scrollToExpanded: boolean;

	public ngAfterViewInit() {
		this._subscribtions.push(
			this._expandSubject.pipe(Rx.debounceTime(100)).subscribe(x => x.expand(this.scrollToExpanded))
		);
		this._prevExpansions = this._expansions.toArray().slice(0);
		this._prevExpansions.forEach(x => {
			this._detectExpand(x);
			x.scrollToExpanded = this.scrollToExpanded;
		});
		this._subscribtions.push(
			this._expansions.changes.subscribe((next: QueryList<KnExpansion>) => this._detectChanges(next.toArray()))
		);
	}

	private _detectChanges(newList: KnExpansion[]) {
		const added = newList.filter(x => this._prevExpansions.indexOf(x) < 0);
		const removed = this._prevExpansions.filter(x => newList.indexOf(x) < 0);
		this._prevExpansions = newList;
		if (added.length === 0 && removed.length === 0) {
			return;
		}

		added.forEach(x => {
			this._detectExpand(x);
			x.scrollToExpanded = this.scrollToExpanded;
		});

		if (this.expandNew && added && added.length) {
			this._expandSubject.next(added[0]);
		}

		if (this.singleExpand && removed && removed.some(x => x.expanded) && this._prevExpansions.length) {
			this._prevExpansions[this._prevExpansions.length - 1].expand(this.scrollToExpanded);
		}
	}

	private _detectExpand(expander: KnExpansion) {
		this._subscribtions.push(
			expander.expandedChange.subscribe((x: boolean) => this._expandedChanged(expander, x))
		);
	}

	private _expandedChanged(expander: KnExpansion, expanded: boolean) {
		if (this.singleExpand && expanded) {
			this._expansions.filter(next => next !== expander).forEach(
				next => next.collapse()
			);
		}
	}

	public ngOnDestroy() {
		this._subscribtions.forEach(x => x.unsubscribe());
	}
}
