import {Injectable, Inject} from '@angular/core';
import {I18N_CATALOG} from './i18n-catalog.token';
import {I18nCatalog} from './types';
import {Utils} from 'kn-utils';

@Injectable()
export class I18nService {
	public constructor(@Inject(I18N_CATALOG) private readonly _catalog: I18nCatalog) { }

	public t(msgid: string, interpolation?: { [key: string]: any }): string;
	public t(meaning: string, msgid: string, interpolation?: { [key: string]: any }): string;
	public t(meaningOrMsgid: string, msgidOrInterpolation: string | { [key: string]: any }, interpolation?: { [key: string]: any }): string {
		let meaning: string = null;
		let msgid: string;
		if (Utils.isString(msgidOrInterpolation)) {
			meaning = meaningOrMsgid && meaningOrMsgid.split('|', 1)[0];
			msgid = msgidOrInterpolation as string;
		}
		else {
			msgid = meaningOrMsgid;
		}
		interpolation = Object.assign({}, msgidOrInterpolation, interpolation);
		return this._translate(meaning, msgid, interpolation);
	}

	private _translate(meaning: string, msgid: string, interpolation?: { [key: string]: any }): string {
		let msgstr = this._catalog[`${msgid}[${meaning || ''}]`];
		if (msgstr == null) {
			msgstr = msgid;
		}
		if (interpolation == null || msgstr == null) {
			return msgstr;
		}
		return this._interpolate(this._icu(msgstr, interpolation), interpolation);
	}

	public join(parts: string[]) {
		switch (parts.length) {
			case 0:
				return '';
			case 1:
			case 2:
				return parts.join(' ' + this.t('and') + ' ');
			default:
				return parts.slice(0, -1).join(', ') + this.t('oxford-and', ', and') + ' ' + parts.slice(-1);
		}
	}

	private _icu(msgstr: string, interpolation: { [key: string]: any }) {
		return msgstr;
	}

	private _interpolate(msgstr: string, interpolation: { [key: string]: any }) {
		return msgstr.replace(/{{\s*([A-Za-z_]\w*)\s*}}/g, (match, p) => {
			return interpolation[p] != null ? `${interpolation[p]}` : match;
		});
	}
}
