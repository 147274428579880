import {Observable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Http, Response, Headers, RequestOptions, UriContext} from 'kn-http';
import {IndexableFetcher, DefaultIndexerResolver, Indexer, ChangeAction} from 'kn-rest';
import {NotSupportedError} from 'kn-shared';
import {FileStorage} from 'common-web/model';

export class FileEntityFetcher extends IndexableFetcher<FileStorage> {
	public constructor(
			http: Http,
			uriTemplate: string,
			table: string,
			identifierProperties: string | string[] = '+id') {
		super(http, uriTemplate, table, new DefaultIndexerResolver(identifierProperties));
	}

	protected _get(indexer: Indexer, context: UriContext): Observable<FileStorage> {
		if (indexer == null) {
			throw new NotSupportedError('Enumerable GET method is not supported');
		}

		const options = { responseType: 'blob' };
		return this._http.get(this._buildUri(indexer, context), options).pipe(
			Rx.map(x => {
				const item = {
					name: this._getFilenameFromResponse(x),
					data: x.body
				} as FileStorage;
				this._indexerResolver.setIndexer(item, indexer);
				return item;
			})
		);
	}

	protected _head(indexer: Indexer, context: UriContext): Observable<Response> {
		if (indexer == null) {
			throw new NotSupportedError('Enumerable GET method is not supported');
		}
		return super._head(indexer, context);
	}

	protected _post(item: FileStorage, context: UriContext): Observable<Response> {
		const options = this._buildOptions(item);
		return this._http.post(this._buildUri(null, context), item.data, options).pipe(
			Rx.tap(next => {
				const indexer = this._indexerResolver.getIndexerFromResponse(next);
				this._indexerResolver.setIndexer(item, indexer);
				this._emitChange(indexer, ChangeAction.Added);
			})
		);
	}

	protected _put(item: FileStorage, context: UriContext): Observable<Response> {
		const options = this._buildOptions(item);
		const indexer = this._indexerResolver.getIndexer(item);
		return this._http.put(this._buildUri(indexer, context, true), item.data, options)
			.pipe(Rx.tap(() => this._emitChange(indexer, ChangeAction.Modified)));
	}

	private _buildOptions(item: FileStorage) {
		return {
			headers: new Headers({ 'Content-Disposition': `attachment; filename="${item.name}"` })
		} as RequestOptions;
	}

	private _getFilenameFromResponse(response: Response) {
		const contentDisposition = response.headers.get('Content-Disposition');
		return contentDisposition.match(/^attachment; filename="(.+?)"$/)[1];
	}
}
