import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnCustomerLink} from './grid-foundation/renderers/components/customer-link.component';
import {KnAuthorizationModule} from 'kn-user';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		KnAuthorizationModule
	],
	declarations: [
		KnCustomerLink
	],
	exports: [
		KnCustomerLink,
		KnAuthorizationModule
	]
})
export class SharedModule { }
