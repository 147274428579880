import {PrintContext, ContextConfiguration} from '../types';
import {Uri, UriTemplate} from 'kn-http';
import {Utils} from 'kn-utils';

export type ResolutionFunctor = (context: PrintContext) => { [key: string]: Uri };
export type ResolutionStrategy = ResolutionFunctor[];

export abstract class AbstractResolutionStrategy {
	public abstract create(config: ContextConfiguration, avaibleKeys: string[]): ResolutionStrategy;

	protected _iterateDependences<T>(dependencies: { [key: string]: string }, iteratee: { (key: string, template: UriTemplate): T }): T[] {
		const result: T[] = [];
		for (const key in dependencies) {
			if (dependencies.hasOwnProperty(key)) {
				result.push(iteratee(key, new UriTemplate(dependencies[key])));
			}
		}
		return result;
	}

	protected _expandUri(template: UriTemplate, context: PrintContext, baseUri?: string) {
		const parameters: { [key: string]: string } = {};
		for (const parameterName of template.getParameterNames()) {
			const value = Utils.object.get(context, parameterName);
			if (value == null) {
				return null;
			}
			Object.assign(parameters, { [parameterName]: value });
		}
		const uri = template.expand(parameters);
		return baseUri != null ? new Uri(baseUri, uri) : uri;
	}
}
