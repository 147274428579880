import {Directive, Input, SkipSelf, Optional} from '@angular/core';
import {AbstractPolicy} from '../abstract-policy';
import {Policy} from '../policy';
import {AbstractAccessControl} from '../../access-control/abstract-access-control';
import {UserService} from '../../user.service';

export type KnAssignContext = { $implicit: any, knAssign: any };

@Directive({
	selector: '[knPolicy]',
	exportAs: 'policy'
})
export class KnPolicy extends AbstractPolicy {
	private readonly _user: UserService;
	private _policy: Policy;

	public constructor(
			@SkipSelf() @Optional() policy: KnPolicy,
			user: UserService) {
		super();
		this._user = policy ? user.fork(policy.scope) : user;
	}

	@Input()
	public set knPolicy(resource: string | string[]) {
		this._policy = this._user.getPolicy(resource);
	}

	public get isValid() {
		return this._policy != null;
	}

	public get resource() {
		return this._policy && this._policy.resource;
	}

	public get accessControl(): AbstractAccessControl {
		return this._policy && this._policy.accessControl;
	}

	public get parent() {
		return this._policy && this._policy.parent;
	}

	public get children() {
		return this._policy && this._policy.children;
	}
}
