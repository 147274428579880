import {Component, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {UriContext} from 'kn-http';
import {UserSetting, UserSettingsFactory} from 'kn-user-settings';
import {Model as GridModel} from 'kn-datagrid';
import {AbstractGridViewComponent} from 'common-web/grid';
import {DatabaseEditService} from './database-edit.service';
import {DatabasesGridData} from './databases-grid-data.service';
import {DatabasesViewConfig} from './databases-view.config';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import * as Model from 'common-web/model';

@Component({
	selector: 'kn-databases-view',
	templateUrl: 'databases-view.html'
})
export class DatabasesViewComponent extends AbstractGridViewComponent<Model.Database> {
	@UserSetting('DatabasesViewComponent.gridSetting@0.1.0') public gridSetting: GridModel;
	public view = 'databases';

	public constructor(
			injector: Injector,
			userSettingsFactory: UserSettingsFactory,
			private readonly _databasesResource: DatabasesResourceService,
			gridData: DatabasesGridData,
			databaseEditService: DatabaseEditService,
			private readonly _config: DatabasesViewConfig) {
		super(injector, gridData, databaseEditService);
		userSettingsFactory.linkComponent(this.constructor);
	}

	protected _fetcher(context: UriContext): Observable<Model.Database[]> {
		const q = this._config.databaseTypes
				.map(x => encodeURIComponent(`type=${x.value}`))
				.join('|');
		context = this._extendContextQuery(context, { q });
		return this._databasesResource.query(context);
	}

	protected _removeItem(item: Model.Database): Observable<any> {
		return this._store.remove(item.uid);
	}
}
