import {CanvasColorParser} from '../parsers/canvas-color-parser';

export class PdfkitCanvasGradientProxy implements CanvasGradient {
	public constructor(public pdfGradient: PDFKit.PDFGradient) { }

	public addColorStop(offset: number, color: string): void {
		const pdfColor = CanvasColorParser.parse(color);
		this.pdfGradient.stop(offset, pdfColor.color as any, pdfColor.opacity);
	}
}
