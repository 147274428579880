import {Node} from './model/node';

export enum RenderMode {
	Static,
	Virtual /* not implemented */
}

export enum SelectionMode {
	None,
	Single,
	Browse,
	Multiple
}

export enum Sorting {
	None,
	Ascending,
	Descending
}

export enum ModelChangeType {
	Sorting,
	Visibility,
	Groping,
	Reordering,
	Resizing
}

export type CellValue = any;
export type RowItem = { [key: string]: CellValue };
export type DataSource<T extends RowItem> = T[];

export type Aggregator<T extends RowItem> = {
	(values: any[], context: CellContext<T>): any
};

export type Renderer<T extends RowItem> = {
	(value: any, context: CellContext<T>, target: string): any
};

export type Accessor<T extends RowItem> = {
	(item: T, column: Column<T>): any
};

export type CellValueResolver<T extends RowItem, U> = {
	(item: T, column: Column<T>): U
} | U;

export type RowValueResolver<T extends RowItem, U> = {
	(item: T): U
} | U;

export type ColumnValueResolver<T extends RowItem, U> = {
	(column: Column<T>): U
} | U;

export type SectionValueResolver<T extends RowItem, U> = {
	(section: Section<T>): U
} | U;

export interface CellContext<T extends RowItem> {
	node: Node<T>;
	column: Column<T>;
}

export interface Column<T extends RowItem> {
	id: string;
	model: ColumnModel;
	description: ColumnDescription<T>;
	section: Section<T>;
}

export interface Section<T extends RowItem> {
	id: string;
	model: SectionModel;
	description: SectionDescription<T>;
	columns: Column<T>[];
	parent: Section<T>;
	children: Section<T>[];
}

export interface ModelChange {
	type: ModelChangeType;
	column: ColumnModel;
}

export interface ColumnModel {
	id: string;
	width: number;
	visible: boolean;
	sort: Sorting;
	group: boolean;
}

export interface SectionModel {
	id: string;
	visible: boolean;
	children: SectionModel[];
}

export interface Model {
	columns: ColumnModel[];
	sections: SectionModel[];
}

export interface RowsDescription<T extends RowItem> {
	height: number; /* not implemented */
	visible: RowValueResolver<T, boolean>;
	selectable: RowValueResolver<T, boolean>;
	classes: RowValueResolver<T, string[] | string>;
}

export interface ColumnDescription<T extends RowItem> {
	id: string;
	label: ColumnValueResolver<T, string>;
	name: ColumnValueResolver<T, string>;
	section: string[] | string;
	gravity: number; /* not implemented */
	hidden: ColumnValueResolver<T, boolean>;
	visibility: boolean;
	sortable: boolean;
	groupable: boolean;
	resizable: boolean;
	exportable: boolean;
	classes: CellValueResolver<T, string[] | string>;
	accessor: Accessor<T>;
	renderer: Renderer<T>;
	aggregator: Aggregator<T>;
}

export interface SectionDescription<T extends RowItem> {
	id: string;
	label: SectionValueResolver<T, string>;
	name: SectionValueResolver<T, string>;
	gravity: number; /* not implemented */
	monolithic: boolean; /* not implemented */
	hidden: SectionValueResolver<T, boolean>;
	visibility: boolean;
	header: boolean;
	menu: boolean;
	classes: SectionValueResolver<T, string[] | string>;
	children: SectionDescription<T>[];
}

export interface Description<T extends RowItem> {
	renderMode: RenderMode;
	selectionMode: SelectionMode;
	mutalSorting: boolean;
	rowsReordering: boolean;
	columnsReordering: boolean;
	rows: RowsDescription<T>;
	columns: ColumnDescription<T>[];
	sections: SectionDescription<T>[];
}
