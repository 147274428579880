import {Injectable} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {I18nService} from 'kn-shared';
import {RouteTitlePresenterConfig} from './route-title-presenter.config';

@Injectable()
export class RouteTitlePresenter {
	public constructor(
			private readonly _i18n: I18nService,
			private readonly _router: Router,
			private readonly _activatedRoute: ActivatedRoute,
			private readonly _titleService: Title,
			private readonly _config: RouteTitlePresenterConfig) { }

	public activate(): Subscription {
		const baseTitle = this._titleService.getTitle();
		return this._router.events
			.pipe(
				Rx.filter(next => next instanceof NavigationEnd),
				Rx.map(() => this._activatedRoute),
				Rx.map(next => {
					while (next.firstChild) {
						next = next.firstChild;
					}
					return next;
				}),
				Rx.filter(next => next.outlet === 'primary'),
				Rx.mergeMap(next => next.data),
				Rx.map(next => [this._i18n.t(next[this._config.field]), baseTitle]
					.filter(x => x != null))
			)
			.subscribe(next => this._titleService.setTitle(next.join(this._config.separator)));
	}
}
