import {Injectable, Injector} from '@angular/core';
import {of as observableOf, combineLatest as observableCombineLatest, merge as observableMerge} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Utils} from 'kn-utils';
import {SelectionMode, RenderMode, Sorting} from 'kn-datagrid';
import {JwtAuth} from 'kn-jwt-auth';
import {AbstractGridData, DataDescription, DataModel, OwnershipRendererFactory, HslTagsRendererFactory, KnInGridActions, defaultInGridActions} from 'common-web/grid';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import {UserRolesResourceService} from '../../services/users/user-roles-resource.service';
import {UsersViewConfig} from './users-view.config';
import * as Model from 'common-web/model';
import * as CommonModel from '../../model/common-database.types';

@Injectable()
export class UsersGridData extends AbstractGridData<Model.User> {
	private readonly _prefixOwnership: string;
	private _databasesUserRoles: { database: Model.Database, userRoles: CommonModel.UserRole[] }[];

	public constructor(
			injector: Injector,
			private readonly _databasesResource: DatabasesResourceService,
			private readonly _userRolesResource: UserRolesResourceService,
			auth: JwtAuth,
			private readonly _config: UsersViewConfig) {
		super(injector);
		this._prefixOwnership = auth.getAuth<{ user: Model.User }>().user.ownership;
		this._registerRenderer('ownership', new OwnershipRendererFactory());
		this._registerRenderer('hslTags', new HslTagsRendererFactory());
		this._registerResolve(this._databasesUserRolesResolve());
	}

	protected _createDescription(): DataDescription<Model.User> {
		return {
			gridRenderMode: RenderMode.Static,
			gridSelectionMode: SelectionMode.Multiple,
			gridMutalSorting: false,
			gridRowsReordering: true,
			gridColumnsReordering: true,
			rows: {
				gridSelectable: true,
				gridClasses: this._getOrEvaluateRowClasses.bind(this)
			},
			columns: [
				{
					id: '@selector'
				}, {
					id: '@tools',
					name: this._i18n.t('Tools'),
					gridLabel: '',
					gridResizable: false,
					gridSortable: false,
					gridClasses: ['center tools'],
					gridRenderer: this.renderers.component(KnInGridActions, KnInGridActions.mapping(this.context, defaultInGridActions(this._i18n))),
					gridExportable: false
				}, {
					id: 'id',
					label: this._i18n.t('ID'),
					gridSortable: false,
					filterType: 'number'
				}, {
					id: 'uid',
					label: this._i18n.t('User Name'),
					gridRenderer: this.renderers.email(),
					filterType: 'string'
				}, {
					id: 'disabled',
					label: this._i18n.t('Disabled'),
					gridClasses: ['center'],
					gridRenderer: this.renderers.bool(),
					filterType: 'bool'
				}, {
					id: 'disabledUntil',
					label: this._i18n.t('Disabled Until'),
					gridRenderer: this.renderers.date('dMyjjmm'),
					filterType: 'date'
				}, {
					id: 'fullName',
					label: this._i18n.t('Full Name'),
					gridClasses: ['strong'],
					filterType: 'string'
				}, {
					id: 'lastIp',
					label: this._i18n.t('Last IP'),
					filterType: 'string'
				}, {
					id: 'lastLogin',
					label: this._i18n.t('Last Login'),
					gridRenderer: this.renderers.date('dMyjjmm'),
					filterType: 'date',
					filterOptions: this._queryExpander.buildDateOptions('1d', '7d', '1m', '3m', '1y', '1D', '7D', '1M', '3M', '1Y')
				}, {
					id: 'ownership',
					label: this._i18n.t('Ownership'),
					gridRenderer: this.renderers.ownership(this._prefixOwnership),
					filterType: 'string'
				}, {
					id: '@userRoles',
					label: this._i18n.t('Roles'),
					gridAccessor: this._userRolesAccessor.bind(this),
					gridRenderer: this.renderers.hslTags('user-role-tag')
				}, {
					id: '@userDatabases',
					label: this._i18n.t('Databases'),
					gridAccessor: this._userDatabasesAccessor.bind(this),
					gridRenderer: this.renderers.hslTags('user-database-tag')
				}
			],
			sections: []
		};
	}

	protected _createModel(): DataModel {
		return {
			columns: [
				{
					id: '@selector',
					gridWidth: 50
				}, {
					id: '@tools',
					gridWidth: 95,
					gridVisible: false
				}, {
					id: 'fullName',
					gridSort: Sorting.Ascending,
					gridWidth: 250
				}, {
					id: 'uid',
					gridWidth: 250
				}, {
					id: 'lastIp',
					gridWidth: 150
				}, {
					id: 'lastLogin',
					gridWidth: 170
				}, {
					id: 'ownership',
					gridWidth: 200
				}, {
					id: '@userRoles',
					gridWidth: 200
				}, {
					id: '@userDatabases'
				}, {
					id: 'disabledUntil',
					gridVisible: false
				}, {
					id: 'disabled',
					gridVisible: false
				}, {
					id: 'id',
					gridVisible: false
				}
			]
		};
	}

	private _databasesUserRolesResolve() {
		const project = (database: Model.Database, userRoles: CommonModel.UserRole[]) => ({
			database, userRoles
		});
		const merger = (database: Model.Database) => {
			const database$ = observableOf(database);
			const userRoles$ = this._userRolesResource.query({
				[this._config.databaseUriKey]: database.uid,
				query: {
					only: ['userUid', 'role'],
					with: 'role.name'
				}
			}).pipe(Rx.catchError(() => []));
			return observableCombineLatest(database$, userRoles$)
				.pipe(Rx.map(next => project(next[0], next[1])));
		};
		return this._databasesResource.query({ query: { only: ['uid', 'ownership', 'name'] } })
			.pipe(
				Rx.map(next => next.map(merger))).pipe(
				Rx.switchMap(next => observableMerge(...next)),
				Rx.toArray(),
				Rx.tap(next => this._databasesUserRoles = next)
			);
	}

	private _userRolesAccessor(user: Model.User) {
		const tagValues = [] as { label: string, title: string[] }[];
		for (const { database, userRoles } of this._databasesUserRoles) {
			userRoles
				.filter(x => x.userUid === user.uid)
				.map(x => x.role.name)
				.forEach(roleName => {
					const tagValue = tagValues.find(x => x.label === roleName);
					if (tagValue == null) {
						tagValues.push({ label: roleName, title: [database.name] });
					}
					else {
						tagValue.title.push(database.name);
					}
				});
		}
		return Utils.array.sort(tagValues, 'label')
			.map(x => ({
				label: x.label,
				title: Utils.array.unique(x.title).sort().join(', ')
			}));
	}

	private _userDatabasesAccessor(user: Model.User) {
		return this._databasesUserRoles
			.filter(databasesUserRole =>
				databasesUserRole.userRoles.some(userRole => userRole.userUid === user.uid)
				&& databasesUserRole.database.ownership.startsWith(user.ownership))
			.map(x => x.database.name);
	}
}
