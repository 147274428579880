export class TimespanUtils {
	private static readonly _timespanRegExp = /^(-)?(?:(\d+)|(?:(\d+)\.)?([0-1]?[0-9]|2[0-3]):([0-5]?[0-9])(?::([0-5]?[0-9])(?:\.(\d{1,7}))?)?)$/;

	public static toString(timespan: number, format: string = null): string {
		const dividers = [
			24 * 60 * 60 * 1000,
			60 * 60 * 1000,
			60 * 1000,
			1000
		];

		const sign = timespan < 0 ? '-' : '';
		let remainder = Math.abs(timespan);
		const parts: number[] = [];
		for (const divider of dividers) {
			const part = Math.floor(remainder / divider);
			remainder -= part * divider;
			parts.push(part);
		}
		parts.push(remainder);

		const pad = (n: string, width: number) =>
			n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;

		const daysFunctor = (forced: boolean) =>
			(forced || parts[0] !== 0) ? '' + parts[0] + '.' : '';

		const hoursFunctor = (digits: number) =>
			pad('' + parts[1], digits) + ':';

		const minutesFunctor = (digits: number) =>
			pad('' + parts[2], digits);

		const secondsFunctor = (forced: boolean, digits: number) =>
			(forced || parts[3] !== 0) ? ':' + pad('' + parts[3], digits) : '';

		const fractionFunctor = (forced: boolean, full: boolean) => {
			if (forced || parts[4] !== 0) {
				const decimals = (parts[4] / 1000).toFixed(7).split('.')[1];
				return '.' + (full ? decimals : decimals.replace(/0*$/, ''));
			}
			return '';
		};

		switch (format) {
			case 'min':
				if (parts[1] === 0 && parts[2] === 0 && parts[3] === 0 && parts[4] === 0) {
					return sign + parts[0];
				}
				return sign + daysFunctor(false) + hoursFunctor(1) + minutesFunctor(1)
					+ secondsFunctor(parts[4] !== 0, 1) + fractionFunctor(false, false);
			case 'short':
				return sign + daysFunctor(false) + hoursFunctor(1) + minutesFunctor(2)
					+ secondsFunctor(parts[4] !== 0, 2) + fractionFunctor(false, false);
			case 'long':
				return sign + daysFunctor(true) + hoursFunctor(2) + minutesFunctor(2)
					+ secondsFunctor(true, 2) + fractionFunctor(true, true);
			case 'seconds':
				return '' + (timespan / 1000);
			case 'invariant':
				/* falls through */
			default:
				return sign + daysFunctor(false) + hoursFunctor(2) + minutesFunctor(2)
					+ secondsFunctor(true, 2) + fractionFunctor(false, true);
		}
	}

	public static parse(value: string): number {
		if (value == null || value.length === 0) {
			return null;
		}

		const matches = value.match(TimespanUtils._timespanRegExp);
		if (matches == null) {
			throw new Error('Passed timespan is not in valid format.');
		}

		const sign = matches[1] === '-' ? -1 : 1;
		const parts = [
			(+matches[2] || +matches[3] || 0) * 24 * 60 * 60 * 1000, // days
			(+matches[4] || 0) * 60 * 60 * 1000, // hours
			(+matches[5] || 0) * 60 * 1000, // minutes
			(+matches[6] || 0) * 1000, // seconds
			(+('0.' + matches[7]) || 0) * 1000 // miliseconds
		];
		return sign * parts.reduce((acc, x) => acc + x, 0);
	}

	// TODO
	public static toIso8601(date: number, format: string = null): string {
		throw new Error('Not implemented.');

	}

	// TODO
	public static fromIso8601(value: string): number {
		throw new Error('Not implemented.');
	}
}
