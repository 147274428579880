import {Injectable} from '@angular/core';
import * as Sqrl from 'squirrelly';
import {pipe} from '../utils/pipe';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererHelper, RendererContent, RendererBlock, RendererConfiguration} from '../../types';

@Injectable()
@PrintStatement('eval')
export class EvalHelper implements PrintRendererHelper {
	public exec(content: RendererContent, blocks: RendererBlock[], config: RendererConfiguration): string {
		const resultValue = this._eval(content, config)
		const resultBlock = blocks.find(x => x.name === 'result');
		return Array.isArray(resultValue)
			? resultBlock.exec(...resultValue)
			: resultBlock.exec(resultValue);
	}

	private _eval(content: RendererContent, config: RendererConfiguration): string {
		const body = content.exec();
		const func = new Function(
			'pipe',
			config.varName,
			config.useWith ? `with(${config.varName}){${body}}` : body
		);
		return func(pipe.bind(content, config), ...content.params);
	}
}
