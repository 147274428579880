import {Observable} from 'rxjs';
import {AbstractAutocompletition} from 'kn-forms';

export interface OperatorSettingDescription {
	id: string;
	label: string;
	presenter: string;
}

export interface TypeSettingDescription {
	id: string;
	operators: OperatorSettingDescription[];
}

export type Resolvable<T> = T | Promise<T> | Observable<T>;
export type OptionParameter<T, U> = { (option: OptionDescription<T>): U } | U;
export type ResolvableOptionParameter<T, U> = OptionParameter<T, Resolvable<U>>;

export interface FilterDefaults<T> {
	operator: string;
	value: ResolvableOptionParameter<T, T>;
	fixed?: boolean;
	disabled?: boolean;
}

export interface OptionDescription<T> {
	id: string;
	label: OptionParameter<T, string>;
	type: string;
	presenter: string;
	hidden?: boolean;
	defaults: OptionParameter<T, FilterDefaults<T>>;
	constraints: ResolvableOptionParameter<T, { [others: string]: any }>;
}

export interface DateOptionDescription extends OptionDescription<Date> {
	constraints: ResolvableOptionParameter<Date, {
		mindate: Date,
		maxdate: Date
		// TODO: datefilter: (date: Date) => boolean
	}>;
}

export interface EnumOptionDescription extends OptionDescription<string> {
	options: ResolvableOptionParameter<string, { label: string, value: string }[]>;
	constraints: ResolvableOptionParameter<string, {}>;
}

export interface StringOptionDescription extends OptionDescription<string> {
	autocompletition?: AbstractAutocompletition<string>;
	constraints: ResolvableOptionParameter<string, {
		minlength: number,
		maxlength: number
	}>;
}

export interface NumberOptionDescription extends OptionDescription<number> {
	constraints: ResolvableOptionParameter<number, {
		step: number,
		max: number,
		min: number
	}>;
}

export interface BoolOptionDescription extends OptionDescription<boolean> {
	constraints: ResolvableOptionParameter<boolean, {}>;
}

export interface ExpandOptionDescription extends OptionDescription<boolean> {
	options: ResolvableOptionParameter<string, {}>;
}

export type PolyOptionDescription = DateOptionDescription | EnumOptionDescription
	| StringOptionDescription | NumberOptionDescription | BoolOptionDescription | ExpandOptionDescription;

export interface Description {
	typeSettings: TypeSettingDescription[];
	changeOperatorValueAdaption: (filter: Filter<any>, oldOperator: string, newOperator: string) => void;
	options: PolyOptionDescription[];
}

export interface Filter<T> {
	id: string;
	operator: string;
	value: T;
	fixed?: boolean;
	disabled?: boolean;
	hidden?: boolean;
}

export type BooleanOperator =
	'and' |
	'or';

export const BooleanOperator = {
	And: 'and' as BooleanOperator,
	Or: 'or' as BooleanOperator
};

export interface FilterNode<T> {
	operator: BooleanOperator;
	children: (FilterNode<T> | Filter<T>)[];
}

export type FilterValue = any;

export type Model = FilterNode<FilterValue>;
