import {Component, ViewEncapsulation, Input, Output, EventEmitter, ChangeDetectorRef} from '@angular/core';
import {ModelChange, Sorting, Description, Model, Column, RowItem, ModelChangeType} from '../types';
import {ValueResolveUtils, SectionUtils} from '../internal-utils';

@Component({
	selector: 'thead[knTableHead]',
	templateUrl: 'table-head.html',
	styles: ['tr[knReordable] > th { position: relative; }'],
	encapsulation: ViewEncapsulation.None
})
export class KnTableHead {
	@Input() public columns: Column<RowItem>[];
	@Input() public visibleColumns: Column<RowItem>[];
	@Input() public description: Description<RowItem>;
	@Input() public model: Model;
	@Output() public modelEvent: EventEmitter<ModelChange> = new EventEmitter<ModelChange>(false);
	@Output() public reorderColumn = new EventEmitter<{ fromIndex: number, toIndex: number }>();
	@Output() public commitModel = new EventEmitter<void>();

	public constructor(private readonly _cdr: ChangeDetectorRef) { }

	public cycleSortBy(column: Column<RowItem>): void {
		if (this.description.mutalSorting && !column.model.group) {
			this.model.columns
				.filter(x => !x.group && x.id !== column.id)
				.forEach(x => x.sort = Sorting.None);
		}
		switch (column.model.sort) {
			case Sorting.Descending:
				column.model.sort = column.model.group ? Sorting.Ascending : Sorting.None;
				break;
			case Sorting.Ascending:
				column.model.sort = Sorting.Descending;
				break;
			default:
				column.model.sort = Sorting.Ascending;
				break;
		}
		this.modelEvent.emit({ type: ModelChangeType.Sorting, column: column.model });
	}

	public onColumnChange($event: ModelChange): void {
		this.modelEvent.emit($event);
		this._cdr.detectChanges();
	}

	public onModelEvent($event: ModelChange): void {
		this.modelEvent.emit($event);
		this._cdr.detectChanges();
	}

	public isResizable(column: Column<RowItem>): boolean {
		return column.description.resizable;
	}

	public isSortable(column: Column<RowItem>): boolean {
		return column.description.sortable;
	}

	public getLabel(column: Column<RowItem>): string {
		return ValueResolveUtils.resolveLabel(column);
	}

	public getColumnSortingClass(column: Column<RowItem>): string {
		switch (column.model.sort) {
			case Sorting.Ascending:
				return 'asc';
			case Sorting.Descending:
				return 'desc';
			default:
				return 'unsorted';
		}
	}

	public getCellClasses(column: Column<RowItem>) {
		return ValueResolveUtils.resolveCellClasses(column);
	}

	public getSectionsNames(column: Column<RowItem>) {
		return SectionUtils.toArray(column.section).map(x => x.description.label).join();
	}
}
