import {Injectable} from '@angular/core';
import {EntityStore as Store, AbstractGraphStore} from 'common-web/rest';
import {DatabaseModel} from './database-model';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';
import * as Model from 'common-web/model';

@Injectable()
export class DatabaseEditService extends AbstractGraphStore<DatabaseModel> {
	public constructor(
			databasesResource: DatabasesResourceService) {
		super();
		this._mappedStore = {
			key: 'database',
			store: new Store<Model.Database>(databasesResource, 'uid')
		};
	}
}
