import {Component, AfterContentInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import {Utils} from 'kn-utils';
import {I18nService} from 'kn-shared';
import {Database} from 'common-web/model';
import {DatabasesResourceService} from '../../services/databases/databases-resource.service';

@Component({
	selector: 'kn-database-selector',
	templateUrl: 'database-selector.html'
})
export class DatabaseSelectorComponent implements AfterContentInit, OnDestroy {
	private readonly _disposables: Function[] = [];

	public query: string = '';
	public marked: Database;
	public message: string = '';
	public databases: Database[] = [];
	public filteredDatabases: Database[] = [];

	@Output() public selected = new EventEmitter<Database>();
	@Output() public databasesChanged = new EventEmitter<Database[]>();

	public constructor(
			private readonly _i18n: I18nService,
			private readonly _databasesResource: DatabasesResourceService) {
	}

	public ngAfterContentInit() {
		const subscription = this._databasesResource.liveFeed().eagerFeed.subscribe(
			next => {
				this.databases = next;
				this.filterQuery();
				this.databasesChanged.emit(this.databases);
			},
			error => this.message = this._i18n.t('Failed to retrieve the list of databases...'));
		this._disposables.push(() => subscription.unsubscribe());
	}

	public ngOnDestroy() {
		this._disposables.forEach(x => x());
	}

	public filterQuery(query?: string) {
		this.query = query || '';
		if (this.query.length === 0) {
			this.filteredDatabases = this.databases;
		}
		else {
			this.filteredDatabases = this.databases
				.filter(d => this._isQueryMatched(d, this.query));
		}
		this.marked = undefined;
	}

	private _isQueryMatched(d: Database, query: string): boolean {
		const normalizedQuery = Utils.text.latinise(query.toLowerCase());
		if (d.name && Utils.text.latinise(d.name.toLowerCase()).indexOf(normalizedQuery) !== -1) {
			return true;
		}
		if (d.uid && Utils.text.latinise(d.uid.toLowerCase()).indexOf(normalizedQuery) !== -1) {
			return true;
		}
		if (d.description && Utils.text.latinise(d.description.toLowerCase()).indexOf(normalizedQuery) !== -1) {
			return true;
		}
		return false;
	}

	public select(database: Database) {
		this.selected.emit(database);
	}

	public processKey(event: KeyboardEvent) {
		if (this.filteredDatabases.length === 0) {
			this.marked = undefined;
			return;
		}
		const index = this.filteredDatabases.indexOf(this.marked);
		switch (event.key || (event as any).code) {
			case 'ArrowDown':
				if (index < 0) {
					this.marked = this.filteredDatabases[0];
				}
				else if (index < this.filteredDatabases.length - 1) {
					this.marked = this.filteredDatabases[index + 1];
				}
				break;
			case 'ArrowUp':
				if (index < 0) {
					this.marked = this.filteredDatabases[this.filteredDatabases.length - 1];
				}
				else if (index > 0) {
					this.marked = this.filteredDatabases[index - 1];
				}
				break;
			case 'Enter':
				if (this.marked) {
					this.select(this.marked);
				}
				else {
					this.select(this.filteredDatabases[0]);
				}
				break;
		}
	}
}
