import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnDirectivesModule} from 'kn-directives';
import {KnOptionsModule} from './components/options/options.module';
import {KnInputModule} from './components/input/input.module';
import {KnDateInputModule} from './components/dateinput/dateinput.module';
import {KnTextareaModule} from './components/textarea/textarea.module';
import {KnCheckboxModule} from './components/checkbox/checkbox.module';
import {KnRadioModule} from './components/radio/radio.module';
import {KnSelectModule} from './components/select/select.module';
import {KnDatepickerModule} from './components/datepicker/datepicker.module';
import {KnDateRangeModule} from './components/daterange/daterange.module';
import {KnControlMessages} from './control-messages.component';
import {KnFormGroup} from './directives/form-group.directive';
import {KnFormArray} from './directives/form-array.directive';

const KN_FORMS_MODULES = [
	KnOptionsModule,
	KnInputModule,
	KnDateInputModule,
	KnTextareaModule,
	KnCheckboxModule,
	KnRadioModule,
	KnSelectModule,
	KnDatepickerModule,
	KnDateRangeModule
];

const KN_FORMS_DIRECTIVES = [
	KnControlMessages,
	KnFormGroup,
	KnFormArray
];

@NgModule({
	imports: [
		KnDirectivesModule,
		CommonModule,
		...KN_FORMS_MODULES,
		KnDatepickerModule.forRoot()
	],
	exports: KN_FORMS_MODULES
})
export class KnFormsRootModule { }

@NgModule({
	imports: [KnDirectivesModule, CommonModule, KN_FORMS_MODULES],
	declarations: [KN_FORMS_DIRECTIVES],
	exports: [KN_FORMS_DIRECTIVES, KN_FORMS_MODULES]
})
export class KnFormsModule {
	public static forRoot(): ModuleWithProviders<KnFormsRootModule> {
		return { ngModule: KnFormsRootModule };
	}

	public static forChild(): ModuleWithProviders<KnFormsModule> {
		return { ngModule: KnFormsModule };
	}
}
