<kn-sticky>
	<div class="form-container heading">
		<a href="javascript:void(0)" class="form-back" (click)="navigateBack()"><i class="material-icons">arrow_back</i></a>
		<h1 i18n>Manage {{ name }}</h1>
	</div>
</kn-sticky>

<div class="form-container">
	<div class="form-layout">
		<kn-tabs deselectable class="boxlist backup" (change)="tabActivationChanged($event)">
			<h2 class="tab-group" i18n>Backup and restore</h2>
			<kn-tab>
				<ng-template #label>
					<i class="material-icons">cloud_download</i>
					<strong i18n>Backup</strong>
				</ng-template>
				<p i18n>Backup database for <strong>{{ name }}</strong> into file.</p>
				<div class="actions">
					<button type="button" class="form-button"
							(click)="backup()"
							i18n>Download backup file</button>
				</div>
			</kn-tab>
			<kn-tab (knFileDrop)="restore($event)">
				<ng-template #label>
					<i class="material-icons">cloud_upload</i>
					<strong i18n>Restore</strong>
				</ng-template>
				<p i18n>Restore database for <strong>{{ name }}</strong> from file.</p>
				<p class="note" i18n>...or drag it here.</p>
				<div class="actions">
					<button type="button" class="form-button"
							(click)="fileInput.click()"
							i18n>Restore backup file</button>
					<input type="file" class="form-button" #fileInput
							(change)="restore(fileInput.files)"
							hidden>
				</div>
			</kn-tab>
		</kn-tabs>

		<kn-tabs deselectable class="boxlist load-defaults"
				(change)="tabActivationChanged($event)"
				*ngIf="loaderDescriptors?.length">
			<h2 class="tab-group" i18n>Load defaults</h2>
			<kn-tab *ngFor="let loaderDescriptor of loaderDescriptors">
				<ng-template #label>
					<i class="material-icons">{{ loaderDescriptor.icon }}</i>
					<strong>{{ loaderDescriptor.name }}</strong>
				</ng-template>
				<p [innerHTML]="loaderDescriptor.description"></p>
				<p class="note" [innerHTML]="loaderDescriptor.note"></p>
				<div class="actions">
					<button type="button" class="form-button"
							(click)="loadDefaults(loaderDescriptor.loaders)"
							i18n>Load {{ loaderDescriptor.name | lowercase }}</button>
				</div>
			</kn-tab>
		</kn-tabs>

		<kn-tabs deselectable class="boxlist clone"
				(change)="tabActivationChanged($event)"
				*ngIf="clonerDescriptors?.length">
			<h2 class="tab-group" i18n>Clone</h2>
			<ng-template #databaseSelector>
				<div class="select-database">
					<strong i18n>Clone entries from database:</strong>
					<kn-radio-group [(value)]="selectedDatabase">
						<div class="form-input" *ngFor="let sourceDatabase of sourceDatabases">
							<kn-radio [value]="sourceDatabase">
								<span kn-label>{{ sourceDatabase.name }}</span>
							</kn-radio>
						</div>
					</kn-radio-group>
					<p *ngIf="!sourceDatabases?.length" i18n>There is not available any database to clone from.</p>
				</div>
			</ng-template>
			<kn-tab *ngFor="let clonerDescriptor of clonerDescriptors">
				<ng-template #label>
					<i class="material-icons">{{ clonerDescriptor.icon }}</i>
					<strong>{{ clonerDescriptor.name }}</strong>
				</ng-template>
				<p [innerHTML]="clonerDescriptor.description"></p>
				<p class="note" [innerHTML]="clonerDescriptor.note"></p>
				<ng-template [ngTemplateOutlet]="databaseSelector"></ng-template>
				<div class="actions">
					<button type="button" class="form-button"
							(click)="clone(clonerDescriptor.cloners, selectedDatabase)"
							[disabled]="!selectedDatabase"
							i18n>Clone {{ clonerDescriptor.name | lowercase }}</button>
				</div>
			</kn-tab>
		</kn-tabs>
	</div>
</div>
