export enum CssFontStyle {
	Normal,
	Italic,
	Oblique
}

export enum CssFontVariant {
	Normal,
	SmallCaps
}

export enum CssFontStretch {
	UltraCondensed,
	ExtraCondensed,
	Condensed,
	SemiCondensed,
	Normal,
	SemiExpanded,
	Expanded,
	ExtraExpanded,
	UltraExpanded
}

export interface CssFontFace {
	fontStyle: CssFontStyle;
	fontVariant: CssFontVariant;
	fontWeight: number;
	fontStretch: CssFontStretch;
	fontFamily: string[];
}

export interface CssFont extends CssFontFace {
	fontSize: number;
	lineHeight: number;
}
