import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {KnTabs} from './tabs.component';
import {KnTab} from './tab.component';

export const KN_TABS_DIRECTIVES = [
	KnTabs,
	KnTab
];

@NgModule({
	imports: [CommonModule],
	declarations: [KN_TABS_DIRECTIVES],
	exports: [KN_TABS_DIRECTIVES]
})
export class KnTabsModule { }
