import {Observable} from 'rxjs';
import {Uri} from '../uri/uri';
import {Http} from './http';
import {WebsocketSubject} from './websocket-subject';
import {Request} from './request';
import {Response} from './response';
import {WebsocketConfig} from './websocket-config';
import {RequestMethod, RequestOptions, WebsocketConfigOptions} from './types';

export abstract class ConcreteHttp extends Http {
	public request(request: Request): Observable<Response> {
		return this._executeRequest(request);
	}

	public get(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('get', uri, null, options);
	}

	public post(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('post', uri, body, options);
	}

	public put(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('put', uri, body, options);
	}

	public delete(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('delete', uri, null, options);
	}

	public patch(uri: string | Uri, body: any, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('patch', uri, body, options);
	}

	public head(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('head', uri, null, options);
	}

	public options(uri: string | Uri, options?: Partial<RequestOptions>): Observable<Response> {
		return this._makeRequest('options', uri, null, options);
	}

	public websocket(config: WebsocketConfig): WebsocketSubject;
	public websocket(uri: string | Uri, options: Partial<WebsocketConfigOptions>): WebsocketSubject;
	public websocket(uriOrConfig: string | Uri | WebsocketConfig, options?: Partial<WebsocketConfigOptions>): WebsocketSubject {
		let config = uriOrConfig as WebsocketConfig;
		if (!(uriOrConfig instanceof WebsocketConfig)) {
			config = this._createWebsocketConfig(uriOrConfig, options);
		}
		return this._establishWebsocket(config);
	}

	private _makeRequest(method: RequestMethod, uri: string | Uri, body: any, options: Partial<RequestOptions>): Observable<Response> {
		const request = this._createRequest(method, uri, body, options);
		return this._executeRequest(request);
	}

	protected _createRequest(method: RequestMethod, uri: string | Uri, body: any, options: Partial<RequestOptions>): Request {
		return new Request(method as any, uri, body, options || {});
	}

	protected _createWebsocketConfig(uri: string | Uri, options: Partial<WebsocketConfigOptions>): WebsocketConfig {
		return new WebsocketConfig(uri, options || {});
	}

	protected abstract _executeRequest(request: Request): Observable<Response>;

	protected abstract _establishWebsocket(config: WebsocketConfig): WebsocketSubject;
}
