import {Observable, Subscriber, Operator, Observer, TeardownLogic} from 'rxjs';

class ActiveMonitorSubscriber<T> extends Subscriber<T> {
	public constructor(destination: Subscriber<T>, private readonly _observer: Observer<T>) {
		super(destination);
		this.add(this._observer.error);
	}

	protected _next(value: T) {
		this._observer.next(value);
		super._next(value);
	}

	protected _error(err: any) {
		this._observer.error(err);
		super._error(err);
	}

	protected _complete() {
		this._observer.complete();
		super._complete();
	}
}

export class ActiveMonitor {
	private _active: boolean = false;
	private _counter: number = 0;
	private _timerId: any = 0;

	public constructor(private readonly _defer?: number, private readonly _overlap?: number) { }

	public get active(): boolean {
		return this._active;
	}

	public observe(untilFirstOnly: boolean = false): Observer<any> {
		const teardown = this.monitor();
		return {
			next: () => untilFirstOnly && teardown(),
			error: () => teardown(true),
			complete: () => teardown()
		};
	}

	public operator<T>(untilFirstOnly: boolean = false): Operator<T, T> {
		const observe = this.observe.bind(this, untilFirstOnly);
		return {
			call(subscriber: Subscriber<any>, source: Observable<any>): TeardownLogic {
				return source.subscribe(new ActiveMonitorSubscriber(subscriber, observe()));
			}
		};
	}

	public monitor() {
		let disposed = false;
		const teardown = (immediate: boolean = false) => {
			if (!disposed) {
				disposed = true;
				this._counter && this._counter--;
				this._update(immediate);
			}
		};
		this._counter++;
		this._update();
		return teardown;
	}

	private _update(immediate: boolean = false) {
		if (this._active === this._counter > 0) {
			return;
		}

		const action = () => {
			this._timerId = 0;
			this._active = this._counter > 0;
		};

		const delay = (this._counter > 0 ? this._defer : this._overlap) || 0;
		if (immediate || delay === 0) {
			this._timerId && clearTimeout(this._timerId);
			this._timerId = 0;
		}

		if (!this._timerId) {
			this._timerId = setTimeout(action, delay);
		}
	}
}
