import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {KnDirectivesModule} from 'kn-directives';
import {DatabaseSelectorComponent} from './database-selector.component';

@NgModule({
	imports: [CommonModule, FormsModule, KnDirectivesModule],
	declarations: [DatabaseSelectorComponent],
	exports: [DatabaseSelectorComponent]
})
export class KnDatabaseSelectorModule { }
