import {Renderer2, ElementRef} from '@angular/core';
import {RippleConfig} from './ripple.types';

export class RippleRenderer {
	public constructor(
			private readonly _renderer: Renderer2,
			private readonly _container: ElementRef) {
	}

	public fadeIn(x: number, y: number, config: RippleConfig): HTMLElement {
		const containerRect = this._container.nativeElement.getBoundingClientRect();

		if (config.centered) {
			x = containerRect.left + containerRect.width / 2;
			y = containerRect.top + containerRect.height / 2;
		}

		const radius = config.radius || this._calcDistanceToFurthestCorner(x, y, containerRect);
		const offsetX = x - containerRect.left;
		const offsetY = y - containerRect.top;
		const duration = config.animation.enterDuration;

		const ripple = this._renderer.createElement('div') as HTMLElement;
		ripple.classList.add('kn-ripple-element');

		ripple.style.left = `${offsetX - radius}px`;
		ripple.style.top = `${offsetY - radius}px`;
		ripple.style.height = `${radius * 2}px`;
		ripple.style.width = `${radius * 2}px`;
		ripple.style.backgroundColor = config.color || null;
		ripple.style.transitionDuration = `${duration}ms`;

		this._container.nativeElement.appendChild(ripple);
		this._enforceStyleRecalculation(ripple);
		ripple.style.transform = 'scale(1)';

		return ripple;
	}

	public fadeOut(ripple: HTMLElement, config: RippleConfig) {
		ripple.style.transitionDuration = `${config.animation.exitDuration}ms`;
		ripple.style.opacity = '0';
	}

	private _enforceStyleRecalculation(element: HTMLElement) {
		// See: https://gist.github.com/paulirish/5d52fb081b3570c81e3a
		window && window.getComputedStyle(element).getPropertyValue('opacity');
	}

	private _calcDistanceToFurthestCorner(x: number, y: number, rect: ClientRect) {
		const distX = Math.max(Math.abs(x - rect.left), Math.abs(x - rect.right));
		const distY = Math.max(Math.abs(y - rect.top), Math.abs(y - rect.bottom));
		return Math.sqrt(distX * distX + distY * distY);
	}
}
