import {EventManager} from '@angular/platform-browser';

export abstract class EventManagerPlugin {
	public constructor(doc: any) {
		/* intentionally empty */
	}

	public manager: EventManager;

	public abstract supports(eventName: string): boolean;

	public abstract addEventListener(element: HTMLElement, eventName: string, handler: Function): Function;

	public addGlobalEventListener(element: string, eventName: string, handler: Function): Function {
		switch (element) {
			case 'window':
				return this.addEventListener(window as any, eventName, handler);
			case 'document':
				return this.addEventListener(document as any, eventName, handler);
			case 'body':
				return this.addEventListener(document.body, eventName, handler);
		}
		throw new Error(`Unsupported event target ${element} for event ${eventName}`);
	}
}
