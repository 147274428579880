import {Injectable} from '@angular/core';
import {RestService, RestChangeNotifierService, Resource} from 'kn-rest';
import {ViewTemplatesConfig} from './view-templates.config';
import * as Model from 'common-web/model';

@Injectable()
export class ViewTemplatesResourceService extends Resource<Model.ViewTemplate> {
	public constructor(
			rest: RestService,
			notifier: RestChangeNotifierService,
			config: ViewTemplatesConfig) {
		super(rest, notifier, config.viewTemplatesTable);
	}
}
