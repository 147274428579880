import {Aggregator, CellContext} from '../types';
import {AggregatorBase} from './aggregator-base';

export class SumAggregator extends AggregatorBase  {
	public create<T>(): Aggregator<T> {
		return (values: any[], ctx: CellContext<T>) => {
			return values.map(p => p || 0).reduce((a, b) => a + b, 0);
		};
	}
}
