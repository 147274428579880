import {Injector, ElementRef} from '@angular/core';
import {Utils} from 'kn-utils';
import {ElementRenderer, CellContext} from 'kn-datagrid';
import {TagsRendererFactory} from './tags-renderer-factory';

export class HslTagsRendererFactory extends TagsRendererFactory {
	protected _createRenderer<T>(injector: Injector, elementRef: ElementRef, classes: string | string[] = [], saturation: number = 50, lightness: number = 30): ElementRenderer<T> {
		return (element: ElementRef, value: (string | { label: string, title?: string })[], context: CellContext<any>, firstChange: boolean): string => {
			const attrs = [this._classesToAttr(classes)];
			const tags = value.map(x => this._buildTag(x, attrs, saturation, lightness));
			return this._render(tags);
		};
	}

	private _buildTag(value: (string | { label: string, title?: string }), attrs: string[], saturation: number, lightness: number) {
		let tagValue = value as { label: string, title?: string };
		if (Utils.isString(value)) {
			tagValue = { label: value as string };
		}
		return {
			value: tagValue.label,
			attrs: attrs.concat([
				tagValue.title ? `title="${tagValue.title}"` : '',
				this._buildColorAttr(tagValue.label, saturation, lightness)
			])
		};
	}

	private _buildColorAttr(value: string, saturation: number, lightness: number) {
		const hue = Math.round(this._hash(value) % 360);
		return `style="background-color:hsl(${hue},${saturation}%,${lightness}%)"`;
	}

	private _hash(value: string) {
		let hash = 5381;
		for (let i = 0; i < value.length; i++) {
			// eslint-disable-next-line no-bitwise
			hash = ((hash << 5) + hash) + value.charCodeAt(i);
		}
		return hash;
	}
}
