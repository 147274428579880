import {CssLengthParser} from './css-length-parser';
import {CssFontStyle, CssFontVariant, CssFontStretch, CssFont} from '../css-font.type';

export class CssFontParser {
	private static readonly _matchers: { [key: string]: RegExp } = {
		style: /^(italic|oblique)$/,
		variant: /^small-caps$/,
		weight: /^(bold(er)?|lighter|[1-9]00)$/,
		stretch: /^((ultra|extra|semi)-)?(condensed|expanded)$/,
		size: /^((xx|x)-large|(xx|s)-small|small|large|medium|larger|smaller|(\+|-)?([0-9]*\.)?[0-9]+(\w+|%))(\/.+|$)/
	};

	public static parse(definition: string): CssFont {
		const font = {
			fontStyle: CssFontStyle.Normal,
			fontVariant: CssFontVariant.Normal,
			fontWeight: 400,
			fontStretch: CssFontStretch.Normal,
			fontSize: 16,
			lineHeight: 16 * 1.8,
			fontFamily: ['serif']
		} as CssFont;

		const chunks = definition.trim().split(/\s+/);
		let size: string;
		let fontFamily: string;

		for (let i = 0; i < chunks.length; i++) {
			const variation = CssFontParser._getVariation(chunks[i]);
			if (variation === 'size') {
				size = chunks[i];
				fontFamily = chunks.slice(i + 1).join(' ');
				break;
			}
			switch (variation) {
				case 'style':
					font.fontStyle = CssFontParser.parseFontStyle(chunks[i]);
					break;
				case 'variant':
					font.fontVariant = CssFontParser.parseFontVariant(chunks[i]);
					break;
				case 'weight':
					font.fontWeight = CssFontParser.parseFontWeight(chunks[i]);
					break;
				case 'stretch':
					font.fontStretch = CssFontParser.parseFontStretch(chunks[i]);
					break;
			}
		}

		if (size == null || fontFamily == null) {
			return font;
		}

		const sizeParts = size.split('/', 2);
		font.fontSize = CssFontParser.parseFontSize(sizeParts[0]);
		font.lineHeight = CssFontParser.parseLineHeight(font.fontSize, sizeParts[1]);
		font.fontFamily = CssFontParser.parseFontFamily(fontFamily);
		return font;
	}

	private static _getVariation(value: string): string {
		for (const key in CssFontParser._matchers) {
			if (CssFontParser._matchers.hasOwnProperty(key)) {
				if (CssFontParser._matchers[key].test(value)) {
					return key;
				}
			}
		}
		return null;
	}

	public static parseFontStyle(value: string): CssFontStyle {
		switch (value) {
			case 'italic':
				return CssFontStyle.Italic;
			case 'oblique':
				return CssFontStyle.Oblique;
			default:
				return CssFontStyle.Normal;
		}
	}

	public static parseFontVariant(value: string): CssFontVariant {
		switch (value) {
			case 'small-caps':
				return CssFontVariant.SmallCaps;
			default:
				return CssFontVariant.Normal;
		}
	}

	public static parseFontWeight(value: string): number {
		if (!isNaN(value as any) && Number.isInteger(+value)) {
			return +value;
		}
		switch (value) {
			case 'bold':
				return 700;
			case 'bolder':
				return 500;
			case 'lighter':
				return 300;
			default:
				return 400;
		}
	}

	public static parseFontStretch(value: string): CssFontStretch {
		switch (value) {
			case 'ultra-condensed':
				return CssFontStretch.UltraCondensed;
			case 'extra-condensed':
				return CssFontStretch.ExtraCondensed;
			case 'condensed':
				return CssFontStretch.Condensed;
			case 'semi-condensed':
				return CssFontStretch.SemiCondensed;
			case 'semi-expanded':
				return CssFontStretch.SemiExpanded;
			case 'expanded':
				return CssFontStretch.Expanded;
			case 'extra-expanded':
				return CssFontStretch.ExtraExpanded;
			case 'ultra-expanded':
				return CssFontStretch.UltraExpanded;
			default:
				return CssFontStretch.Normal;
		}
	}

	public static parseFontSize(value: string): number {
		switch (value) {
			case 'xx-small':
				return 9;
			case 'x-small':
				return 10;
			case 'small':
			case 'smaller':
				return 13;
			case 'medium':
				return 16;
			case 'large':
			case 'larger':
				return 18;
			case 'x-large':
				return 24;
			case 'xx-large':
				return 32;
		}
		if (value.charAt(value.length - 1) === '%') {
			return 16 * (+value.substr(0, value.length - 1) / 100);
		}
		return CssLengthParser.parse(value);
	}

	public static parseLineHeight(fontSize: number, value: string): number {
		if (value == null || value === 'normal') {
			return fontSize * 1.2;
		}
		if (!isNaN(parseFloat(value)) && isFinite(value as any)) {
			return fontSize * parseFloat(value);
		}
		if (value.charAt(value.length - 1) === '%') {
			return fontSize * (+value.substr(0, value.length - 1) / 100);
		}
		return CssLengthParser.parse(value);
	}

	public static parseFontFamily(value: string): string[] {
		return value.replace(/"/g, '').split(',').map(x => x.trim());
	}
}
