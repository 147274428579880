import {AbstractControl} from '@angular/forms';
import {Subject, Observable, Subscription, ConnectableObservable} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {AsyncValidator} from '../types';

class Deffered<T> {
	private _resolve: (value?: T | PromiseLike<T>) => void;
	private _reject: (reason?: any) => void;
	private readonly _promise: Promise<T>;

	public constructor() {
		this._promise = new Promise<T>((resolve, reject) => {
			this._resolve = resolve;
			this._reject = reject;
		});
	}

	public get promise() {
		return this._promise;
	}

	public resolve(value?: T | PromiseLike<T>) {
		this._resolve(value);
	}

	public reject(reason?: any) {
		this._reject(reason);
	}
}

type ValidationEntry<T> = { control: AbstractControl, value: T, deffered: Deffered<boolean>, connection?: Subscription };

export interface AsyncCheckValidatorConfig {
	debounceTime?: number;
}

export class AsyncCheckValidator<T> implements AsyncValidator {
	private readonly _validationQueue: ValidationEntry<T>[] = [];
	private readonly _validationStream$: Subject<ValidationEntry<T>> = new Subject<ValidationEntry<T>>();

	public constructor(
			checker: (value: T) => Observable<boolean>,
			config: AsyncCheckValidatorConfig = {}) {
		this._validationStream$
			.pipe(
				Rx.debounceTime(config.debounceTime == null ? 500 : config.debounceTime),
				Rx.mergeMap(entry => {
					const request$ = checker(entry.value).pipe(
						Rx.catchError(error => error),
						Rx.map(next => [entry, next]),
						Rx.publishReplay(1)
					) as ConnectableObservable<[ValidationEntry<T>, boolean | any]>;
					this._dispatchRequest(entry, request$.connect());
					return request$;
				})
			)
			.subscribe(next => this._dispatchResult(next[0], next[1]));
	}

	private _dispatchRequest(entry: ValidationEntry<T>, connection: Subscription) {
		const validationEntry = this._validationQueue.find(x => x.control === entry.control);
		if (validationEntry !== undefined) {
			validationEntry.connection = connection;
		}
	}

	private _dispatchResult(entry: ValidationEntry<T>, validity: boolean | any) {
		const index = this._validationQueue.findIndex(x => x.control === entry.control);
		if (index !== -1) {
			const deffered = this._validationQueue[index].deffered;
			if (typeof validity === 'boolean') {
				deffered.resolve(validity);
			}
			else {
				deffered.reject(validity);
			}
			const deleted = this._validationQueue.splice(index, 1)[0];
			if (this._validationQueue.length > 0 && this._validationQueue[0].connection == null) {
				this._validationStream$.next(this._validationQueue[0]);
			}
			if (deleted && deleted.connection) {
				deleted.connection.unsubscribe();
			}
		}
	}

	public async validate(control: AbstractControl) {
		if (control.value == null) {
			return true;
		}
		const index = this._validationQueue.findIndex(x => x.control === control);
		let validationEntry: ValidationEntry<T>;
		if (index !== -1) {
			validationEntry = this._validationQueue.splice(index, 1)[0];
			validationEntry.value = control.value;
			this._validationQueue.unshift(validationEntry);
		}
		else {
			validationEntry = {
				control: control,
				value: control.value,
				deffered: new Deffered<boolean>()
			} as ValidationEntry<T>;
			this._validationQueue.unshift(validationEntry);
			this._validationStream$.next(validationEntry);
		}
		return validationEntry.deffered.promise;
	}
}
