import {Injectable} from '@angular/core';
import {PrintStatement} from '../../metadata/metadata';
import {PrintRendererFilter} from '../../types';

@Injectable()
@PrintStatement('uppercase')
export class UpperCaseFilter implements PrintRendererFilter {
	public transform(value: string): string {
		return value == null ? null : `${value}`.toUpperCase();
	}
}
