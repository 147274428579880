import {BreakableType, BreakableAnnotation} from '../types';

export class BreakableAnnotationFactory {
	private static readonly _annotationRegExp = /^page-break (\d+): (\w+)$/;
	private _id = 0;

	public create(breakable: BreakableType) {
		switch (breakable) {
			case BreakableType.Always:
				return document.createComment('page-break ' + (this._id++) + ': always');
			case BreakableType.Avoid:
				return document.createComment('page-break ' + (this._id++) + ': avoid');
			default:
				return document.createComment('page-break ' + (this._id++) + ': auto');
		}
	}

	public static parse(comment: string): BreakableAnnotation {
		const matches = comment.match(BreakableAnnotationFactory._annotationRegExp);
		if (matches != null) {
			let breakable: BreakableType;
			switch (matches[2]) {
				case 'always':
					breakable = BreakableType.Always;
					break;
				case 'avoid':
					breakable = BreakableType.Avoid;
					break;
				default:
					breakable = BreakableType.Auto;
					break;
			}
			return {
				id: +matches[1],
				breakable: breakable
			};
		}
		return null;
	}

	public static test(comment: string): boolean {
		return BreakableAnnotationFactory._annotationRegExp.test(comment);
	}
}
