import {OnPropDecorate} from 'kn-utils';
import {PropertyAccessor} from '../property-accessor';
import {KeySpecifier} from '../types';
import {normalizeKey} from '../utils';

export class UserSettingProxyMetadata implements OnPropDecorate {
	public key: string;
	public version: string;
	public accessor: PropertyAccessor<any>;

	public constructor(
			key?: KeySpecifier,
			public discriminatorArg?: any
			) {
		Object.assign(this, normalizeKey(key));
	}

	public knOnPropDecorate(target: any, name: string) {
		this.key = this.key || name;
		Object.defineProperty(target, name, {
			get: () => this.accessor
		});
	}
}
