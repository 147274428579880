import {Component, ElementRef, OnDestroy, Input, OnInit, Inject, NgZone, ViewEncapsulation} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {merge, fromEvent, Observable} from 'rxjs';
import {FlexiblePosition, HorizontalConnectionPos, VerticalConnectionPos, ConnectedPosition } from './flexible-position';
import {RegistredPortal} from './portal-container.component';

@Component({
	selector: 'kn-attached-portal',
	template: '<ng-template [kn-portal-outlet]="portal.portal"><ng-content></ng-content></ng-template>',
	styleUrls: ['attached-portal.css'],
	encapsulation: ViewEncapsulation.None
})
export class KnAttachedPortal implements OnInit, OnDestroy {
	@Input() public portal: RegistredPortal;

	private readonly _defaultPositions: ConnectedPosition[] = [{
		originX: HorizontalConnectionPos.start,
		originY: VerticalConnectionPos.bottom,
		overlayX: HorizontalConnectionPos.start,
		overlayY: VerticalConnectionPos.top
	}, {
		originX: HorizontalConnectionPos.end,
		originY: VerticalConnectionPos.bottom,
		overlayX: HorizontalConnectionPos.end,
		overlayY: VerticalConnectionPos.top
	}, {
		originX: HorizontalConnectionPos.start,
		originY: VerticalConnectionPos.top,
		overlayX: HorizontalConnectionPos.start,
		overlayY: VerticalConnectionPos.bottom
	}, {
		originX: HorizontalConnectionPos.end,
		originY: VerticalConnectionPos.top,
		overlayX: HorizontalConnectionPos.end,
		overlayY: VerticalConnectionPos.bottom
	}];

	private _change: Observable<Event>;
	private _observer: MutationObserver;

	public constructor(
			private readonly _elementRef: ElementRef,
			@Inject(DOCUMENT) private readonly _document: Document,
			private readonly _ngZone: NgZone) {
		this._change = merge<Event>(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange'));
		this._observer = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
			if (mutations.some(next => next.type === 'childList')) {
				this._calcPosition();
			}
		});
	}

	public ngOnInit() {
		this.portal.positionStrategy = new FlexiblePosition(this.portal.portal.outletRef, this._elementRef.nativeElement, this._document, this._ngZone);
		this.portal.positionStrategy.minWidthOrigin = this.portal.portal.minWidthByOrigin;
		this._observer.observe(this._elementRef.nativeElement, {childList: true, subtree: true});
		this._change.subscribe(() => this._calcPosition());
	}

	public ngOnDestroy(): void {
		this._observer.disconnect();
	}

	private _calcPosition() {
		this.portal.positionStrategy.applyPosition(this.portal.portal.positions || this._defaultPositions);
	}
}
