import {Pipe, PipeTransform} from '@angular/core';
import {RowValueResolver} from '../types';
import {Node} from '../model/node';
import {RowsVisibility} from '../services/rows-visibility.service';
import {ExpansionService} from '../services/expansion.service';

@Pipe({ name: 'tabulateNodeTree' })
export class TabulateNodeTreePipe implements PipeTransform {
	public constructor(
			private readonly _rowsVisibility: RowsVisibility,
			private readonly _expansion: ExpansionService) {
	}

	public transform<T>(root: Node<T>, visible: RowValueResolver<T, boolean>): Node<T>[] {
		this._rowsVisibility.calculate(root, visible);
		return this._tabulate(root);
	}

	private _tabulate<T>(node: Node<T>): Node<T>[] {
		let nodeTree: Node<T>[] = [];
		for (const child of node.children) {
			if (this._isVisible(child)) {
				nodeTree.push(child);
				if (node.children.length > 0) {
					nodeTree = nodeTree.concat(this._tabulate(child));
				}
			}
		}
		return nodeTree;
	}

	private _isVisible<T>(node: Node<T>): boolean {
		if (!this._rowsVisibility.getForNode(node)) {
			return false;
		}
		return node.traverse(x => x.isRoot() || this._expansion.isExpanded(x.parent));
	}
}
