import {NgModule, Optional, ModuleWithProviders, InjectionToken, Inject} from '@angular/core';
import {TemporaryStorageService} from './temporary-storage.service';

export const KN_TEMPORARY_STORAGE_FORROOT_GUARD = new InjectionToken<void>('KN_TEMPORARY_STORAGE_FORROOT_GUARD');

@NgModule()
export class KnTemporaryStorageModule {
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	public constructor(@Optional() @Inject(KN_TEMPORARY_STORAGE_FORROOT_GUARD) guard: any) { /* intentionally empty */ }

	public static forRoot(): ModuleWithProviders<KnTemporaryStorageModule> {
		return {
			ngModule: KnTemporaryStorageModule,
			providers: [
				TemporaryStorageService
			]
		};
	}
}
