import {NgModule, ModuleWithProviders} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Utils} from 'kn-utils';
import {KnCommonModule} from 'kn-common';
import {KnComponentsModule} from 'kn-components';
import {KnDirectivesModule} from 'kn-directives';
import {KnFormsModule} from 'kn-forms';

import {DatabaseManageComponent} from './database-manage.component';
import {LOADER_DESCRIPTOR_FACTORIES_TOKEN} from './loader-descriptor-factories.token';
import {CLONER_DESCRIPTOR_FACTORIES_TOKEN} from './cloner-descriptor-factories.token';
import {ManageDatabaseConfig} from './database-manage.config';

import {routing} from './database-manage.routes';

export class ManageDatabaseOptions extends ManageDatabaseConfig { }

export function provideManageDatabaseConfig(config: ManageDatabaseOptions) {
	return Utils.object.defaults(config, {
		databaseUriKey: 'database',
		databaseType: 'database'
	});
}

@NgModule({
	imports: [
		CommonModule,
		KnCommonModule.forChild(),
		KnComponentsModule,
		KnDirectivesModule,
		KnFormsModule.forChild(),
		routing
	],
	declarations: [
		DatabaseManageComponent
	]
})
export class KnDatabaseManageModule {
	public static forChild(config?: Partial<ManageDatabaseOptions>): ModuleWithProviders<KnDatabaseManageModule> {
		return {
			ngModule: KnDatabaseManageModule,
			providers: [
				{
					provide: ManageDatabaseOptions, useValue: config ? config : {}
				}, {
					provide: ManageDatabaseConfig,
					useFactory: provideManageDatabaseConfig,
					deps: [ManageDatabaseOptions]
				}, {
					provide: LOADER_DESCRIPTOR_FACTORIES_TOKEN,
					useValue: []
				}, {
					provide: CLONER_DESCRIPTOR_FACTORIES_TOKEN,
					useValue: []
				}
			]
		};
	}
}
