export abstract class AbstractDraggable {
	private _dragging: { origin: number[], active: boolean } = null;

	public isDragging() {
		return this._dragging && this._dragging.active;
	}

	protected _getDeadband(): number {
		return 0;
	}

	protected _evaluateMove(offset: number[]): number[] {
		return offset;
	}

	protected abstract _applyMove(offset: number[]): void;

	protected _dragStartHandler(event: MouseEvent | TouchEvent) {
		this._dragging = {
			origin: this._getPageCoordinates(event),
			active: false
		};
		event.preventDefault();
	}

	protected _dragMoveHandler(event: MouseEvent | TouchEvent) {
		if (this._dragging != null) {
			let offset = this._getPageCoordinates(event);
			offset[0] -= this._dragging.origin[0];
			offset[1] -= this._dragging.origin[1];
			offset = this._evaluateMove(offset);
			if (this._getDeadband() < Math.sqrt(offset[0] * offset[0] + offset[1] * offset[1])) {
				this._dragging.active = true;
				this._startActiveDragging();
			}
			if (this.isDragging()) {
				this._applyMove(offset);
			}
		}
	}

	protected _dragEndHandler(event: MouseEvent | TouchEvent) {
		this._dragging != null && this._dragging.active && this._endActiveDragging();
		this._dragging = undefined;
	}

	protected _clickHandler(event: Event) {
		if (this.isDragging()) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	protected _getPageCoordinates(event: Event) {
		let x = (event as MouseEvent).pageX;
		let y = (event as MouseEvent).pageY;
		if (x == null && (event as TouchEvent).targetTouches != null) {
			x = (event as TouchEvent).targetTouches[0].pageX;
		}
		if (y == null && (event as TouchEvent).targetTouches != null) {
			y = (event as TouchEvent).targetTouches[0].pageY;
		}
		return [x, y];
	}

	protected _startActiveDragging() { /* intentionally empty */ }
	protected _endActiveDragging() { /* intentionally empty */ }
}
