<div class="filter-group" *ngIf="node.children.length !== 0">
	<ul>
		<ng-template ngFor let-item [ngForOf]="node.children" let-last="last">
			<li *ngIf="isGroup(item)" knFilterGroup
					[description]="description"
					[node]="item"
					(remove)="removeChild(item)"></li>
			<li *ngIf="!isGroup(item) && !item.hidden" knFilterItem
					[description]="description"
					[item]="item"
					(remove)="removeChild(item)"></li>
			<li *ngIf="!last" class="boolean-operator">
				<span>{{ getOperatorName(node.operator) }}</span>
			</li>
		</ng-template>
	</ul>

	<div class="new-filter-actions">
		<span class="coalescing-operator">
			<button (click)="toggleNewFilterCoalescing()">{{ getNewFilterCoalescingName() }}</button>
		</span>
		<span class="new-filter">
			<kn-select i18n-placeholder placeholder="Add new filter..." [class.pending]="loading" [(ngModel)]="selectedFilter" (ngModelChange)="add($event)" hasFilter>
				<ng-template #header let-item>{{ getLabel(item) }}</ng-template>
				<kn-option *ngFor="let option of description.options | filterOptions" [value]="option">{{ getLabel(option) }}</kn-option>
			</kn-select>
		</span>
	</div>
</div>
<div class="filter-placeholder" *ngIf="node.children.length === 0">
	<i class="material-icons">filter_list</i>
	<p i18n>No filter yet...</p>
	<kn-select i18n-placeholder placeholder="Add new filter!" [class.pending]="loading" [(ngModel)]="selectedFilter" (ngModelChange)="add($event)" hasFilter>
		<ng-template #header let-item>{{ getLabel(item) }}</ng-template>
		<kn-option *ngFor="let option of description.options | filterOptions" [value]="option">{{ getLabel(option) }}</kn-option>
	</kn-select>
</div>
