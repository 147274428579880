import {Uri} from './uri';
import {UriContext} from '../types';

export class UriTemplate {
	private static readonly _tokenizerRegExp = /\{([^\{\}]+)\}|([^\{\}]+)/g;
	private static readonly _modifierFragmenterRegExp = /([^:\*]*)(?::(\d+)|(\*))?/;
	private static readonly _operators = ['+', '#', '.', '/', ';', '?', '&'];

	public constructor(public template: string, public context?: UriContext) { }

	public expand(context?: UriContext): Uri {
		const mergedContext = Object.assign({}, this.context, context);
		const uriString = this.template.replace(UriTemplate._tokenizerRegExp,
				(_: string, expression: string, literal: string) => {
			if (!expression) {
				return this._encodeReserved(literal);
			}

			let operator: string;
			if (UriTemplate._operators.indexOf(expression.charAt(0)) !== -1) {
				operator = expression.charAt(0);
				expression = expression.substr(1);
			}

			const arraysOfValues = expression.split(/,/g)
				.map(x => x.match(UriTemplate._modifierFragmenterRegExp))
				.map(x => this._getValues(mergedContext, operator, x[1], x[2] || x[3]));
			const values: string[] = Array.prototype.concat.apply([], arraysOfValues);

			if (!operator || operator === '+') {
				return values.join(',');
			}

			let separator = ',';
			if (operator === '?') {
				separator = '&';
			}
			else if (operator !== '#') {
				separator = operator;
			}
			return (values.length !== 0 ? operator : '') + values.join(separator);
		});
		return new Uri(uriString);
	}

	public toUri(context?: UriContext): Uri {
		const mergedContext = Object.assign({}, this.context, context);
		return new Uri(new UriTemplate(this.template, mergedContext));
	}

	public getParameterNames(): string[] {
		return this.template.match(UriTemplate._tokenizerRegExp)
			.filter(x => x != null && x.charAt(0) === '{' && x.charAt(x.length - 1) === '}')
			.map(x => {
				const offset = UriTemplate._operators.indexOf(x.charAt(1)) !== -1 ? 2 : 1;
				return x.substr(offset, x.length - (1 + offset));
			})
			.reduce((array, x) => array.concat(x.split(/,/g)), [])
			.map(x => x.match(UriTemplate._modifierFragmenterRegExp)[1]);
	}

	private _encodeReserved(str: string) {
		return str.split(/(%[0-9A-Fa-f]{2})/g).map(part => {
			if (!/%[0-9A-Fa-f]/.test(part)) {
				part = encodeURI(part).replace(/%5B/g, '[').replace(/%5D/g, ']');
			}
			return part;
		}).join('');
	}

	private _encodeUnreserved(str: string) {
		return encodeURIComponent(str).replace(/[!'()*]/g,
			c => '%' + c.charCodeAt(0).toString(16).toUpperCase());
	}

	private _encodeValue(operator: string, value: string, key?: string) {
		if (key && value == null) {
			return this._encodeUnreserved(key);
		}
		value = (operator === '+' || operator === '#')
				? this._encodeReserved(value) : this._encodeUnreserved(value);
		return key ? this._encodeUnreserved(key) + '=' + value : value;
	}

	private _isKeyOperator(operator: string) {
		return operator === ';' || operator === '&' || operator === '?';
	}

	private _getValues(context: UriContext, operator: string, key: string, modifier: string) {
		if (context[key] == null || context[key] === '') {
			if (operator === ';') {
				if (context[key] != null) {
					return [this._encodeUnreserved(key)];
				}
			}
			else if (context[key] === null && (operator === '&' || operator === '?')) {
				return [this._encodeUnreserved(key)];
			}
			else if (context[key] === '' && (operator === '&' || operator === '?')) {
				return [this._encodeUnreserved(key) + '='];
			}
			else if (context[key] === '') {
				return [''];
			}
			return [];
		}

		if (typeof context[key] === 'string'
				|| typeof context[key] === 'number'
				|| typeof context[key] === 'boolean') {
			let value = '' + context[key];
			if (modifier && modifier !== '*') {
				value = value.substring(0, parseInt(modifier, 10));
			}
			key = this._isKeyOperator(operator) ? key : null;
			return [this._encodeValue(operator, value, key)];
		}

		if (modifier === '*') {
			return this._expandExplodeModifier(context, operator, key);
		}

		return this._expandValue(context, operator, key);
	}

	private _expandExplodeModifier(context: UriContext, operator: string, key: string) {
		if (Array.isArray(context[key])) {
			return (context[key] as string[])
				.filter(x => x != null)
				.map(x => {
					key = this._isKeyOperator(operator) ? key : null;
					return this._encodeValue(operator, x, key);
				});
		}
		else {
			const value: { [key: string]: string } = context[key];
			return Object.keys(value)
				.filter(x => value[x] !== undefined)
				.map(x => this._encodeValue(operator, value[x], x));
		}
	}

	private _expandValue(context: UriContext, operator: string, key: string) {
		let values: string;
		if (Array.isArray(context[key])) {
			values = (context[key] as string[])
				.filter(x => x != null)
				.map(x => this._encodeValue(operator, x))
				.join(',');
		}
		else {
			const value: { [key: string]: string } = context[key];
			values = Object.keys(value)
				.filter(x => value[x] != null)
				.map(x => this._encodeUnreserved(x) + ','
						+ this._encodeValue(operator, value[x].toString()))
				.join(',');
		}

		if (this._isKeyOperator(operator)) {
			return [this._encodeUnreserved(key) + '=' + values];
		}
		return values.length !== 0 ? [values] : [];
	}
}
