import {Observable, Subject} from 'rxjs';
import {Utils} from 'kn-utils';
import {Response, UriContext} from 'kn-http';
import {NotSupportedError} from 'kn-shared';
import {Indexer} from '../types';

export abstract class AbstractFetcher<T extends { [key: string]: any }> {
	protected constructor(public table: string) { }

	public abstract query(context?: UriContext): Observable<T[]>;
	public abstract get(indexer: Indexer, context?: UriContext): Observable<T>;
	public abstract head(indexer: Indexer, context?: UriContext): Observable<Response>;
	public abstract save(item: T, context?: UriContext): Observable<Response>;

	public remove(context: UriContext): Observable<Response>;
	public remove(indexer: Indexer, context?: UriContext): Observable<Response>;
	public remove(indexerOrContext: Indexer | UriContext, context?: UriContext): Observable<Response> {
		let indexer = null as Indexer;
		if (Utils.isObject(indexerOrContext)) {
			context = Object.assign({}, indexerOrContext as UriContext, context);
		}
		else {
			indexer = indexerOrContext as Indexer;
		}
		return this._remove(indexer, context);
	}

	protected abstract _remove(indexer: Indexer, context: UriContext): Observable<Response>;

	public websocket(context?: UriContext): Subject<T | T[]> {
		throw new NotSupportedError('WebSocket is not supported');
	}
}
