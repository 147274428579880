import {Injectable, Optional, Inject} from '@angular/core';
import {UriContext} from 'kn-http';
import {AbstractFetcher} from './fetchers/abstract-fetcher';
import {AbstractConcreteFetcher} from './fetchers/abstract-concrete-fetcher';
import {FetcherContextWrapper} from './fetcher-context-wrapper';
import {UriContextService} from './uri-context.service';
import {RestChangeNotifierService} from './change-notification/rest-changes-notifier.service';
import {FETCHERS_TOKEN} from './fetchers.token';

@Injectable()
export class RestService {
	private readonly _map = new Map<string, AbstractConcreteFetcher<any>>();

	public constructor(
			private readonly _uriContext: UriContextService,
			private readonly _changeNotifier: RestChangeNotifierService,
			@Inject(FETCHERS_TOKEN) @Optional() fetchers?: AbstractConcreteFetcher<any>[]) {
		fetchers.forEach(x => this.register(x));
	}

	public get context(): UriContext {
		return this._uriContext.context;
	}

	public for<T>(table: string): AbstractFetcher<T> {
		const fetcher = this._map.get(table);
		return fetcher && new FetcherContextWrapper<T>(fetcher, this._uriContext);
	}

	public register<T>(fetcher: AbstractConcreteFetcher<T>) {
		fetcher.registerOnChange(change => this._changeNotifier.emit(change));
		this._map.set(fetcher.table, fetcher);
	}
}
