import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import * as Rx from 'rxjs/operators';
import {Response, UriContext} from 'kn-http';
import {AbstractResource, ChangeEntry} from 'kn-rest';
import {AbstractDependentEntitiesStore} from './abstract-dependent-entities-store';
import {EntityUtils} from '../../entity-utils';
import {AncestorMapper} from './types';
import * as Model from 'common-web/model';

export class DependentEntitiesStore<T extends Model.EntityBase> extends AbstractDependentEntitiesStore<T> {
	public constructor(
			private readonly _resource: AbstractResource<T>,
			ancestorMapper: AncestorMapper<T> | string,
			context?: UriContext) {
		super(ancestorMapper, context);
	}

	public get changes(): Observable<ChangeEntry> {
		return EntityUtils.mergeChanges(this._resource);
	}

	protected _query(context: UriContext) {
		return this._resource.query(context)
			.pipe(Rx.catchError((err: Response) =>
				err.status === 403 ? observableOf([]) : observableThrowError(err)));
	}

	protected _save(items: T[], context: UriContext) {
		const managedReferences = Object.keys(this._resource.getReferences());
		const supplements = items.map(x => EntityUtils.exceptReferences(x, managedReferences));
		return this._resource.query(context).pipe(
			Rx.switchMap(x => {
				const diff = EntityUtils.calculateDiff(x, items);
				return EntityUtils.saveDiff(this._resource, diff);
			}),
			Rx.toArray(),
			Rx.catchError((err: Response) =>
				err.status === 403 ? observableOf([]) : observableThrowError(err)),
			Rx.finalize(() => items.forEach((x, i) => Object.assign(x, supplements[i])))
		);
	}

	protected _remove(context: UriContext) {
		return this._resource.remove(context).pipe(
			Rx.catchError((err: Response) =>
				err.status === 403 ? observableOf(err) : observableThrowError(err))
			);
	}
}
