import {ElementRef} from '@angular/core';

type ContainedFocusMember = {
	element: ElementRef,
	isContainer: boolean,
	eventsRemover: { (): void }
};

export class ContainedFocus {
	private _focused: boolean = false;
	private _members: ContainedFocusMember[] = [];

	public constructor(
			public onFocus: (event: FocusEvent) => void,
			public onBlur: (event: FocusEvent) => void) {
	}

	public register(element: ElementRef, isContainer: boolean = false) {
		const handleFocusListener = this._handleFocus.bind(this);
		const handleBlurListener = this._handleBlur.bind(this);
		element.nativeElement.addEventListener('focus', handleFocusListener, isContainer);
		element.nativeElement.addEventListener('blur', handleBlurListener, isContainer);
		const eventsRemover = () => {
			element.nativeElement.removeEventListener('focus', handleFocusListener, isContainer);
			element.nativeElement.removeEventListener('blur', handleBlurListener, isContainer);
		};
		this._members.push({
			element: element,
			isContainer: isContainer,
			eventsRemover: eventsRemover
		});
	}

	public unregister(element: ElementRef) {
		const index = this._members.findIndex(x => x.element.nativeElement === element.nativeElement);
		if (index !== -1) {
			this._members.splice(index, 1)[0].eventsRemover();
		}
	}

	public unregisterAll() {
		this._members.forEach(x => x.eventsRemover());
		this._members = [];
	}

	public get focused() {
		return this._focused;
	}

	private _handleFocus(event: FocusEvent) {
		if (this._focused) {
			return;
		}
		this._focused = true;
		this.onFocus(event);
	}

	private _handleBlur(event: FocusEvent) {
		if (!this._focused) {
			return;
		}
		for (const member of this._members) {
			if (!member.isContainer) {
				if (member.element.nativeElement === event.relatedTarget) {
					return;
				}
			}
			else {
				if (member.element.nativeElement.contains(event.relatedTarget)) {
					return;
				}
			}
		}
		this._focused = false;
		this.onBlur(event);
	}
}
